import sortOrders from "../constants/sortOrders";

export const queryStringToObject = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params
}

export const fetchQueryString = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams
}

export const makeInitialQueryObject = (injectParams) => {
    let params = {
        PageNumber: 1,
        PageSize: 20,
        Sort: "lastModifiedOn",
        SortOrder: sortOrders.DESC,
    }
    if (injectParams) {
        params = { ...params, ...injectParams }
    }
    return params
}

export const updateUrl = ({ queryString, pageTitle }) => {
    window.history.replaceState(null, pageTitle, `?${queryString}`)
}