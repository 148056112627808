export const prepareForm = ({
    formValues,
    serverValues
}) => ({
    ...(formValues?.id && {
        id: formValues?.id,
        ...serverValues,
        vehicleClass: serverValues?.vehicleClass?.id,
    }),
})

export const preparePayload = (values, id) => ({
    ...values,
    ...(id && { id }),
    seatingCapacity: parseInt(values.seatingCapacity),
})