import { HStack, useColorMode } from '@chakra-ui/react'
import React from 'react'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import { useDashboardStats } from '../../../config/query/dashboardQuery'

const SummaryBoxes = ({query}) => {
    const { colorMode } = useColorMode()
    const dashboardStatsQuery = useDashboardStats(query)
    return (
        <HStack spacing={3} align="center">
            <LabelValuePair
                label="Total Users"
                value={dashboardStatsQuery?.data?.totalUsers}
                flexDir='column'
                containerProps={{ textAlign: "center" }}
                headingProps={{ fontSize: "13px", color: getColor(colorKeys.secondaryText, colorMode), fontWeight: "normal" }}
                valueProps={{ fontSize: "16px", fontWeight: "bold", mt: "2", color: getColor(colorKeys.dimText, colorMode) }}
                colon={false}
                isLoading={dashboardStatsQuery.isLoading}
            />
            <LabelValuePair
                label="Drivers"
                value={dashboardStatsQuery?.data?.drivers}
                flexDir='column'
                containerProps={{ textAlign: "center" }}
                headingProps={{ fontSize: "13px", color: getColor(colorKeys.secondaryText, colorMode), fontWeight: "normal" }}
                valueProps={{ fontSize: "16px", fontWeight: "bold", mt: "2", color: getColor(colorKeys.dimText, colorMode) }}
                colon={false}
                isLoading={dashboardStatsQuery.isLoading}
            />
            <LabelValuePair
                label="Coroporate"
                value={dashboardStatsQuery?.data?.corporates}
                flexDir='column'
                containerProps={{ textAlign: "center" }}
                headingProps={{ fontSize: "13px", color: getColor(colorKeys.secondaryText, colorMode), fontWeight: "normal" }}
                valueProps={{ fontSize: "16px", fontWeight: "bold", mt: "2", color: getColor(colorKeys.dimText, colorMode) }}
                colon={false}
                isLoading={dashboardStatsQuery.isLoading}
            />
        </HStack>

    )
}

export default SummaryBoxes