import React, { useEffect } from 'react'
import { Box, Card, CardBody, useColorMode } from '@chakra-ui/react'
import TableHeaderOptions from '../../../components/BasicUI/CustomTable/TableHeaderOptions'
import CustomTabs from '../../../components/BasicUI/CustomTabs'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import { useQueryClient } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router'
import BookingsReports from './Bookings'
import Transactions from './Transactions'
import DriverReports from './Drivers'
import DriverLogs from './DriverLogs'

const Reports = () => {
    const { colorMode } = useColorMode()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const [isRefreshing, setIsRefreshing] = React.useState(false)
    const { pathname } = useLocation()

    const tabs = [
        {
            index: 0,
            head: "Booking Details",
            key: "booking-details",
            body: <BookingsReports isRefreshing={isRefreshing} setIsRefreshing={setIsRefreshing} />,
        },
        {
            index: 1,
            head: "Transactions",
            key: "transactions",
            body: <Transactions isRefreshing={isRefreshing} setIsRefreshing={setIsRefreshing} />,
        },
        {
            index: 2,
            head: "Drivers",
            key: "drivers",
            body: <DriverReports isRefreshing={isRefreshing} setIsRefreshing={setIsRefreshing} />,
            hideRefresh: true
        },
        {
            index: 3,
            head: "Driver Logs",
            key: "driver-logs",
            body: <DriverLogs isRefreshing={isRefreshing} setIsRefreshing={setIsRefreshing} />,
        },
    ]

    const [selectedTab, setSelectedTab] = React.useState(tabs.find(tab => pathname.includes(tab.key)) || tabs[0])

    const onTabChange = (index) => {
        navigate(`/admin/reports/${tabs[index].key}`)
        setSelectedTab(tabs[index])
        setIsRefreshing(false)
    }

    useEffect(() => {
        setSelectedTab(tabs.find(tab => pathname.includes(tab.key)) || tabs[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    return (
        <Box>
            <TableHeaderOptions
                title={`Reports`}
                actionProps={{ minW: 200 }}
                containerProps={{ mt: 3 }}
            />

            <Card mt={4}>
                <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
                    <CustomTabs
                        tabPanelProps={{
                            p: 0
                        }}
                        containerProps={{
                            index: selectedTab?.index,
                            onChange: onTabChange,
                            isLazy: true
                        }}
                        tabs={tabs}
                        tabEndAction={selectedTab?.hideRefresh ? null : {
                            label: "Refresh",
                            action: () => {
                                setIsRefreshing(true)
                                queryClient.refetchQueries(["types", "pricings"])
                            },
                            isLoading: isRefreshing
                        }}
                    />
                </CardBody>
            </Card>

        </Box>

    )
}

export default Reports