import React, { useEffect, useState } from "react";
import { Box, HStack, Icon, IconButton, useColorMode } from "@chakra-ui/react";
import moment from "moment";
import CustomTable from "../../../../components/BasicUI/CustomTable";
import { makeInitialQueryObject } from "../../../../config/helpers/queryHelper";
import ShiftForm from "./ShiftForm";
import { useShifts, useDeleteShift } from "../../../../config/query/shiftQuery";
import DeletePopover from "../../../../components/BasicUI/Popovers/DeletePopover";
import APP_ICONS from "../../../../config/constants/icons";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import useDebounce from "../../../../config/hooks/useDebounce";
import { APP_MODULES } from "../../../../config/constants";

const Shifts = ({
  selectedRow,
  setSelectedRow,
  disclosure,
  setIsRefreshing,
  isRefreshing,
}) => {

  const { colorMode } = useColorMode();
  const [query, setQuery] = useState(makeInitialQueryObject());
  const debouncedQuery = useDebounce(query);

  const shiftQuery = useShifts(debouncedQuery);
  const deleteShiftQuery = useDeleteShift();

  const onEditModal = (item) => {
    setSelectedRow(item);
    disclosure.onOpen();
  };

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  const handleDelete = (id) => {
    deleteShiftQuery
      .mutateAsync(id)
      .catch((error) => {
        console.warn(error);
      });
  };

  useEffect(() => {
    if (isRefreshing) shiftQuery.refetch().then(() => setIsRefreshing(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing]);

  return (
    <Box>
      <CustomTable
        permissionKey={APP_MODULES.Shift}
        tableFor={"shifts"}
        tableWrapperProps={{ padding: 0, h: "calc(100vh - 315px)" }}
        hideFilterBar={true}
        containerProps={{ mt: 4, h: "calc(100vh - 266px)" }}
        head={[
          {
            title: "Name",
            extractor: "name",
            align: "left",
          },
          {
            title: "Time from",
            extractor: "timeFrom",
            align: "left",
            component: (item) => moment(item.timeFrom, "HH:mm:ss.SSSSSSS").format("hh:mm A")
          },
          {
            title: "Time to",
            extractor: "timeTo",
            align: "left",
            component: (item) => moment(item.timeTo, "HH:mm:ss.SSSSSSS").format("hh:mm A")
          },
          {
            title: "Action",
            extractor: "action",
            align: "center",
            component: (item) => (
              <HStack justify={"center"}>
                <IconButton
                  onClick={() => onEditModal(item)}
                  variant={"ghost"}
                  size="sm"
                  icon={
                    <Icon
                      as={APP_ICONS.EDIT}
                      color={getColor(colorKeys.success, colorMode)}
                    />
                  }
                >
                  {"Edit"}
                </IconButton>
                <DeletePopover
                  // type={tableFor}
                  popoverProps={{ placement: "bottom-start" }}
                  // key={actionIndex}
                  onConfirm={() => handleDelete(item.id)}
                >
                  <IconButton
                    variant={"ghost"}
                    size="sm"
                    icon={
                      <Icon
                        as={APP_ICONS.BIN}
                        color={getColor(colorKeys.danger, colorMode)}
                      />
                    }
                  >
                    {"delete"}
                  </IconButton>
                </DeletePopover>
              </HStack>
            ),
          },
        ]}
        data={shiftQuery?.data?.data}
        loading={shiftQuery?.isLoading}
        totalResults={shiftQuery?.data?.meta?.totalCount}
        totalPages={shiftQuery?.data?.meta?.totalPages}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => shiftQuery.refetch()}
        isRefreshing={shiftQuery?.isFetching}
      />

      <ShiftForm data={selectedRow} disclosure={disclosure} />
    </Box>
  );
};

export default Shifts;
