import { Card, chakra, Flex, Button, Box, Text, useColorMode, Image, Icon } from '@chakra-ui/react'
import React from 'react'
import { getColor, colorKeys } from "../../../config/constants/appColors"
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import FormInput from "../../../components/Forms/FormInput"
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { addUser } from '../../../config/redux/slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useLogin } from '../../../config/query/authQuery'
import IMAGES from '../../../config/constants/images'
import APP_ICONS from '../../../config/constants/icons'
import { EMAIL_REGEX } from '../../../config/constants/regexConstants'
import FormCheckbox from '../../../components/Forms/FormCheckbox'

const Login = () => {
    const tenant = useSelector((state) => state.tenant?.tenant)
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()

    const loginQuery = useLogin()

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm()

    function onSubmit(values) {
        loginQuery.mutateAsync(values)
            .then((response) => {
                let user = { ...response }
                let token = user?.token
                delete user?.message
                delete user?.token
                dispatch(addUser({ user, token }))
                // localStorage.setItem("tenant", values.tenant)
                // navigate("/admin")
            })
            .catch((error) => console.warn(error))
    }

    return (
        <Flex height={"100vh"} justify="center" align="center" bg={getColor(colorKeys.white, colorMode)} background={`url(${tenant?.backgroundImageUrl})`} backgroundRepeat={"no-repeat"} backgroundSize={"cover"} backgroundPosition={"center"}>
            <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
                <Card w="full" maxW="480px">
                    <Box w="full" maxW="480px">

                        <Flex px="5" justify={"space-between"} bg={getColor(colorKeys.dimmerBlue, colorMode)}>
                            <Box>
                                <Text fontSize="16px" color={getColor(colorKeys.white, colorMode)} mt={5}>
                                    Welcome Back!
                                </Text>
                                <Text fontSize="13px" color={getColor(colorKeys.white, colorMode)} >
                                    Sign in to continue to {tenant?.name}
                                </Text>
                            </Box>

                            <Box background={`url(${IMAGES.AUTH_MIN})`} w="173px" h="112px" />
                        </Flex>

                        <Box
                            pos="absolute"
                            top="75px"
                            left="25px"
                            w="90px"
                            m="auto"
                            bg={getColor(colorKeys.white, colorMode)}
                            rounded="full"
                        >
                            <Image objectFit={"contain"} w="full" h="full" src={tenant?.logoUrl} alt="logo" />
                        </Box>

                        <Box p="10">

                            <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">

                                <FormInput
                                    id={"email"}
                                    label={"Email"}
                                    placeholder="Enter email"
                                    required={true}
                                    errors={errors}
                                    control={control}
                                    inputProps={{ fontSize: "15px" }}
                                    rules={{
                                        pattern: {
                                            value: EMAIL_REGEX,
                                            message: "Invalid email address"
                                        }
                                    }}
                                />

                                <FormInput
                                    id={"password"}
                                    label={"Password"}
                                    type="password"
                                    secure={true}
                                    placeholder="Enter Password"
                                    required={true}
                                    errors={errors}
                                    control={control}
                                    inputProps={{ fontSize: "15px" }}
                                    containerStyles={{ mt: 5 }}
                                />

                                {/* <FormSearchSelect
                                    id="tenant"
                                    control={control}
                                    errors={errors}
                                    label={"Tenant"}
                                    placeholder={"Select Tenant"}
                                    options={makeSelectList(tenants.data?.data, "id", "name", false)}
                                    containerStyles={{ mt: 5 }}
                                /> */}

                                <FormCheckbox
                                    control={control}
                                    id={"rememberMe"}
                                    options={["Remember Me"]}
                                    optionLabelProps={{ fontSize: "14px" }}
                                    containerProps={{ mt: 5 }}
                                />

                                <PrimaryButton
                                    isLoading={loginQuery.isLoading}
                                    mt={5}
                                    type="submit"
                                    p="18px 33px"
                                    bg={getColor(colorKeys.spanishYellow, colorMode)}
                                    w="full"
                                    rounded="md"
                                    fontWeight={"bold"}
                                    color="white"
                                >
                                    Login
                                </PrimaryButton>


                            </chakra.form>

                            <Box fontSize="15px" color={getColor(colorKeys.primaryText, colorMode)} textAlign="center">
                                <Flex textAlign={"center"} justify="center">
                                    <Link to="/auth/forgot-password">
                                        <Button display={"flex"} align="center" variant="link" type="button" color={getColor(colorKeys.secondaryText, colorMode)} leftIcon={<Icon as={APP_ICONS.LOCK} />}>
                                            Forgot your password?
                                        </Button>
                                    </Link>
                                </Flex>
                            </Box>
                        </Box>

                    </Box>


                </Card>

            </Flex>
        </Flex>
    )
}

export default Login