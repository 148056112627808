export const chartStyle = {
    width: "100%",
    maxWidth: "400px",
    height: "200px",
    pointerEvents: "none",
    minHeight: "fit-content"
};

export const getPieConfig = (data = []) => {
    return {
        appendPadding: 10,
        data,
        angleField: "value",
        colorField: "type",
        color: ["#47A7DD", "#2B3D4F", "#F46A6A", "#34C38F"],
        radius: 1,
        innerRadius: 0.7,
        label: {
            type: "inner",
            offset: "-50%",
            content: "{value}%",
            style: {
                textAlign: "center",
                fontSize: 12,
            },
        },
        legend: false,
        statistic: {
            title: true,
            content: false,
        },
    }
}

export const getPieChartData = (data) => {
    const chartData = [
        {
            type: "Completed",
            value: data?.Completed?.percentage || 0
        },
        {
            type: "Canceled",
            value: data?.Cancelled?.percentage || 0
        },
        {
            type: "No shows",
            value: data?.NoShow?.percentage || 0
        },
        {
            type: "Incidents",
            value: data?.Incident?.percentage || 0
        },
    ]
    const preparedData = chartData?.filter((item) => item.value > 0);
    return preparedData
}