import {
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Post } from "../api";
import API_CONSTANTS from "../constants/api";
import { CONTENT_TYPE, MILISECONDS } from "../constants";
import appendQueryParams from "../helpers/appendQueryParams";

export const useCountries = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["countries"],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.PREFERENCES.countries,
                token,
            })
            return data
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,
        keepPreviousData: true,
    })
}

export const useStates = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["states", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PREFERENCES.states}?${appendQueryParams(params)}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,
        keepPreviousData: true,
    })
}

export const useCities = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["cities", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PREFERENCES.cities}?${appendQueryParams(params)}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,
        keepPreviousData: true,
    })
}

export const useLanguages = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["languages"],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.PREFERENCES.languages,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,
        keepPreviousData: true,
    })
}

export const useFiles = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: [API_CONSTANTS.PREFERENCES.files, params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PREFERENCES.files}?${appendQueryParams(params)}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        keepPreviousData: true,
    })
}

export const useUpdatePrivacy = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: `${API_CONSTANTS.PREFERENCES.updatePrivacyPolicy}`,
                token,
                body,
                toastError: true,
                toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA,
            })
            return res
        },
        onSuccess: () => queryClient.invalidateQueries([API_CONSTANTS.PREFERENCES.files])
    })
}

export const useUpdateTerms = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: `${API_CONSTANTS.PREFERENCES.updateTerms}`,
                token,
                body,
                toastError: true,
                toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA,
            })
            return res
        },
        onSuccess: () => queryClient.invalidateQueries([API_CONSTANTS.PREFERENCES.files])
    })
}

export const useGetTenantDetails = () => {
    return useMutation({
        mutationFn: async () => {
            const res = await Get({
                path: API_CONSTANTS.PREFERENCES.tenantDetails,
                toastError: true,
                toastMessage: false,
            })
            return res
        },
    })
}