import React, { useState } from 'react'
import CustomTable from '../../../components/BasicUI/CustomTable'
import TableInfoPopover from '../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import { formatDateTime } from '../../../config/helpers/dateHelper'
import { Box, Text, useDisclosure } from '@chakra-ui/react'
import TableHeaderOptions from '../../../components/BasicUI/CustomTable/TableHeaderOptions'
import APP_ICONS from '../../../config/constants/icons'
import { useNavigate } from 'react-router'
import { useDeleteDriver, useDrivers } from '../../../config/query/driverQuery'
import { getFilters } from '../../../config/helpers/filterHelper'
import { STATUS } from '../../../config/constants/enums'
import DriverForm from './DriverForm'
import { makeInitialQueryObject, queryStringToObject, updateUrl } from '../../../config/helpers/queryHelper'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'
import { formatAmount, getDeviceName } from '../../../config/helpers/stringHelper'
import useDebounce from '../../../config/hooks/useDebounce'
import appendQueryParams from '../../../config/helpers/appendQueryParams'
import { APP_MODULES } from '../../../config/constants'

const Drivers = () => {
  const navigate = useNavigate()
  const formDisclosure = useDisclosure()
  const [selectedDriver, setSelectedDriver] = useState(null)
  const [query, setQuery] = useState(makeInitialQueryObject(queryStringToObject()))
  const debouncedQuery = useDebounce(query)

  const driversQuery = useDrivers(debouncedQuery)
  const deleteDriverQuery = useDeleteDriver()

  const onView = (item) => navigate(`/admin/drivers/${item.id}`)

  const onEditModal = (item) => {
    setSelectedDriver(item)
    formDisclosure.onOpen()
  }

  const onQueryChange = (updatedQuery) => {
    let temp = {}
    setQuery((prev) => {
      temp = { ...prev, ...updatedQuery }
      return temp
    })
    updateUrl({ pageTitle: "Drivers", queryString: appendQueryParams(temp) })
  }

  const handleDelete = (id) => {
    deleteDriverQuery.mutateAsync(id)
      .then(() => driversQuery.refetch())
      .catch((error) => console.warn(error))
  }

  return (
    <Box>
      <TableHeaderOptions
        title={"Drivers"}
        actionText={"Add Driver"}
        action={() => {
          setSelectedDriver(null)
          formDisclosure.onOpen()
        }}
        icon={APP_ICONS.ADD}
        permissionKey={APP_MODULES.Driver}
      />
      <CustomTable
        permissionKey={APP_MODULES.Driver}
        tableFor="driver"
        searchPlaceholder="Search drivers"
        filters={getFilters({
          Status: Object.keys(STATUS).map((item) => ({
            key: item,
            value: STATUS[item],
          }))
        })}
        head={[
          {
            title: "Driver Name",
            extractor: "name",
            align: "left",
            isSortable: true,
            component: (item) => (
              <TableInfoPopover
                data={item}
                onEdit={(item) => onEditModal(item)}
                triggerOnClick={() => onView(item)}
                fallBackText={"N/A"}
              >
                <LabelValuePair flexDir='column' label="Description" value={item.description} />

                <LabelValuePair flexDir='column' label="Allow Incoming Requests" value={item.allowIncomingRequests ? "Enabled" : "Disabled"} />
                <LabelValuePair
                  flexDir='column'
                  label="Connected Account Status"
                  value={item.connectedAccountStatus?.name}
                  valueComponent={<StatusBadge maxW='fit-content' value={item.connectedAccountStatus} activeStatus={0} />}
                />
                <LabelValuePair
                  flexDir='column'
                  label="Stripe Status"
                  value={item.stripeStatus?.name}
                  valueComponent={<StatusBadge maxW='fit-content' value={item.stripeStatus} activeStatus={1} />}
                />

                <LabelValuePair
                  flexDir='column'
                  label="Status"
                  value={true}
                  valueComponent={
                    <StatusBadge maxW='fit-content' value={item.status} />
                  }
                />
                <LabelValuePair flexDir='column' label="Created" value={formatDateTime(item.createdOn)} />
                <LabelValuePair flexDir='column' label="Last Updated" value={formatDateTime(item.lastModifiedOn)} />
              </TableInfoPopover>
            )
          },
          { title: "Phone Number", extractor: "phone", isSortable: true },
          { title: "Licence Plate", extractor: "licensePlate", isSortable: true },
          { title: "Service Type", extractor: "vehicleType", isSortable: true },
          { title: "Email", extractor: "email", isSortable: true },
          { title: "Cash Balance", extractor: "creditBalance", component: (item) => <Text fontSize="14px">{formatAmount(item.creditBalance)}</Text> },
          { title: "Stripe Connect", extractor: "stripeAccountId", fallBackText: "-" },
          { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status} />, isSortable: true },
          { title: "Device", align: "center", extractor: "deviceName", component: (item) => getDeviceName({ deviceName: item.deviceName, deviceId: item.deviceId }) },
          { title: "App Version", align: "center", extractor: "appVersion" },
          { title: "Last Login", align: "center", extractor: "lastLogin", isSortable: true, component: (item) => formatDateTime(item?.lastLogin) },
          { title: "Last Active", align: "center", extractor: "lastActive", isSortable: true, component: (item) => formatDateTime(item?.lastActive) },
          { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
          { title: "Actions", extractor: "actions", align: "center" }
        ]}
        data={driversQuery.data?.data}
        loading={driversQuery?.isLoading}
        totalResults={driversQuery?.data?.meta?.totalCount}
        totalPages={driversQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={driversQuery.refetch}
        isRefreshing={driversQuery?.isFetching}

        onDelete={handleDelete}
        onEdit={onEditModal}
        onView={onView}
      />

      <DriverForm disclosure={formDisclosure} data={selectedDriver} onSuccess={() => setSelectedDriver(null)} />
    </Box>

  )
}

export default Drivers