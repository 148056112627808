export const ALLOWED_FILE_TYPES = {
    EXAM_UPLOAD: [".docx", ".doc", ".pdf"],
    IMAGE: [".jpg", ".jpeg", ".png"],
    VIDEO: [".mp4", ".webm", ".ogg"],
    AUDIO: [".mp3", ".wav", ".ogg"],
    ANY: [".jpg", ".jpeg", ".png", ".docx", ".doc", ".pdf"]
}

export const FILE_TYPES = {
    EXAM_UPLOAD: "EXAM_UPLOAD",
    IMAGE: "IMAGE",
    VIDEO: "VIDEO",
    AUDIO: "AUDIO",
    ANY: "ANY"
}