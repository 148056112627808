import {
    useQuery,
    useMutation,
    useQueryClient
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS, { DEFAULT_SEARCH_KEY } from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { CONTENT_TYPE } from "../constants";

export const useCorporates = (params) => {
    const token = useSelector((state) => state.user?.token)
    params = appendQueryParams({ ...params, [DEFAULT_SEARCH_KEY]: API_CONSTANTS.CORPORATES.searchableKeys }, false)
    return useQuery({
        queryKey: ["corporates", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.CORPORATES.base}?${params}`,
                token,
            })
            return data
        },
        keepPreviousData: true,
    })
}

export const useCorporateOptions = (query) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["corporate-lookup", query],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.CORPORATES.lookup.replace(":query", query || ""),
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        enabled: Boolean(query)
    })
}

export const useCorporate = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["corporate", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.CORPORATES.base}/${id}`,
                token,
                toastError: true,
            })
            return data
        },
        enabled: Boolean(id)
    })
}

export const useCorporateCustomers = ({ params, id }) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["corporate-customers", id, params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.CORPORATES.customersList.replace(":id", id)}?${appendQueryParams(params)}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        enabled: Boolean(id)
    })
}

export const useCorporateFilter = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (options) => {
            const data = await Get({
                path: `${API_CONSTANTS.CORPORATES.base}?${appendQueryParams(options)}`,
                token,
                toastError: true,
            })
            return data
        },
        mutationKey: ['corporates-filter'],

    })
}

export const useCreateCorporate = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.CORPORATES.dataKeys)
            const res = await Post({
                path: API_CONSTANTS.CORPORATES.create,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["corporate-lookup"])
            queryClient.invalidateQueries(["corporates"])
        }
    })
}

export const useUpdateCorporateImage = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Patch({
                path: API_CONSTANTS.CORPORATES.updateImage,
                token,
                body,
                toastError: true,
                // toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA,
            })
            return res
        },
    })
}

export const useUpdateCorporate = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.CORPORATES.dataKeys)
            const res = await Put({
                path: `${API_CONSTANTS.CORPORATES.base}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["corporate-lookup"])
            queryClient.invalidateQueries(["corporates"])
        }
    })
}

export const useDeleteCorporate = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.CORPORATES.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["corporate-lookup"])
            queryClient.invalidateQueries(["corporates"])
        }
    })
}

export const useUpdateCorporateStatus = () => {
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationKey: ["corporate-status"],
        mutationFn: async (body) => {
            const response = await Patch({
                path: `${API_CONSTANTS.CORPORATES.updateStatus.replace(
                    ":id",
                    body.id
                )}?status=${body.status}`,
                token,
                toastError: true,
                toastMessage: false,
            });
            return response;
        },
    });
};

export const useCorporateBalance = (id) => {
    const token = useSelector((state) => state.user.token);
    return useQuery({
        queryKey: ["corporateBalance", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.CORPORATES.getBalance.replace(
                    ":id",
                    id
                )}`,
                token,
            });
            return data;
        },
        keepPreviousData: true,
        enabled: Boolean(id),
    });
};

export const useAdjustBalance = () => {
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: API_CONSTANTS.CORPORATES.adjustBalance,
                token,
                body,
                toastError: true,
                toastMessage: true,
            });
            return res;
        },
    });
};

export const useDirectInvoiceStatus = () => {
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationFn: async ({ id, body }) => {
            const data = await Patch({
                path: API_CONSTANTS.CORPORATES.directInvoiceStatus.replace(":id", id),
                token,
                body,
                toastError: true,
                toastMessage: true
            });
            return data;
        },
    });
}

export const useResendEmail = () => {
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationFn: async (id) => {
            const data = await Get({
                path: API_CONSTANTS.CORPORATES.resendEmail.replace(":id", id),
                token,
                toastError: true,
                toastMessage: true
            });
            return data;
        },
    });
}

export const useCorporateSetupIntent = () => {
    const token = useSelector((state) => state.user?.token);
    return useMutation({
        mutationFn: async (id) => {
            const data = await Get({
                path: `${API_CONSTANTS.CORPORATES.setupIntent.replace(":id", id)}`,
                toastError: true,
                token,
            });
            return data;
        },
    });
};

export const useCorporateCards = (id) => {
    const token = useSelector((state) => state.user?.token);
    return useQuery({
        queryKey: ["corporateCards", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.CORPORATES.cards.replace(":id", id)}`,
                toastError: false,
                token,
            });
            return data;
        },
        enabled: Boolean(id),
    });
};

export const useDeleteCorporateCard = () => {
    const token = useSelector((state) => state.user?.token);
    return useMutation({
        mutationFn: async (body) => {
            const data = await Delete({
                path: `${API_CONSTANTS.CORPORATES.deleteCard
                    .replace(":id", body.corporateId)
                    .replace(":cardId", body.cardId)}`,
                toastError: true,
                toastMessage: true,
                token,
            });
            return data;
        },
    });
};

export const useActiveCorporateCustomer = () => {
    const token = useSelector((state) => state.user?.token);
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({ customerId, corporateId }) => {
            const data = await Patch({
                path: API_CONSTANTS.CORPORATES.activateCustomer.replace(":customerId", customerId).replace(":corporateId", corporateId),
                toastError: true,
                toastMessage: true,
                token,
            });
            return data;
        },
        onSuccess: () => queryClient.invalidateQueries(["corporate-customers"])
    });
}

export const useBlockCorporateCustomer = () => {
    const token = useSelector((state) => state.user?.token);
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({ customerId, corporateId }) => {
            const data = await Patch({
                path: API_CONSTANTS.CORPORATES.blockCustomer.replace(":customerId", customerId).replace(":corporateId", corporateId),
                toastError: true,
                toastMessage: true,
                token,
            });
            return data;
        },
        onSuccess: () => queryClient.invalidateQueries(["corporate-customers"])
    });
}

export const useUpdateCorporatePay = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationFn: async ({ customerId, corporateId, isCorporatePay }) => {
            const response = await Patch({
                path: API_CONSTANTS.CORPORATES.updateCorporatePay.replace(":corporateId", corporateId).replace(":customerId", customerId).replace(":value", isCorporatePay),
                token,
                toastError: true,
                toastMessage: true,
            });
            return response;
        },
        onSuccess: () => queryClient.invalidateQueries(["corporate-customers"])
    });
};

export const useDeleteCorporateCustomer = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationFn: async ({ customerId, corporateId }) => {
            const response = await Delete({
                path: API_CONSTANTS.CORPORATES.deleteCustomer.replace(":corporateId", corporateId).replace(":customerId", customerId),
                token,
                toastError: true,
                toastMessage: true,
            });
            return response;
        },
        onSuccess: () => queryClient.invalidateQueries(["corporate-customers"])
    });
};

