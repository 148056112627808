import { Box } from '@chakra-ui/react'
import React from 'react'

const CustomBottomDrawer = ({ id, h, w, right, isOpen, children }) => {
    const height = isOpen ? h : 0
    return (
        <Box
            zIndex={isOpen ? "1" : "-1"}
            id={id}
            h={height}
            w={w}
            shadow="xl"
            bottom={"36px"}
            right={right}
            pos="fixed"
            transition={"all 0.5s ease"}
        >
            {children}
        </Box>
    )
}

export default CustomBottomDrawer