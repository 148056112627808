import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  VStack,
  useColorMode,
  chakra,
  Image,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import LabelValuePair from "../../../components/BasicUI/LabelValuePair";
import { getColor, colorKeys } from "../../../config/constants/appColors";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import UpdateCreditForm from "./UpdateCreditForm";
import {
  useCustomer,
  usePrimaryCard,
} from "../../../config/query/customerQuery";
import { useParams } from "react-router";
import CustomerForm from "./CustomerForm";
import CollapsableBox from "../../../components/BasicUI/DataBoxes/CollapsibleBox";
import { breakWithCaps, formatAmount } from "../../../config/helpers/stringHelper";
import CustomerBookings from "./CustomerBookings";
import { useSelector } from "react-redux";
import { getPermission } from "../../../config/helpers/permissionHelper";
import { APP_MODULES } from "../../../config/constants";
import CustomerTransactions from "./CustomerTransactions";
import { Link } from "react-router-dom";

const CustomerDetails = () => {
  const { colorMode } = useColorMode();
  const transactionDisclosure = useDisclosure();
  const creditFormDisclosure = useDisclosure();
  const customerFormDisclosure = useDisclosure();
  const { customerId } = useParams();
  const customerQuery = useCustomer(customerId);
  const { data: primaryCard } = usePrimaryCard(customerId);

  const permissions = useSelector((state) => state?.user?.permissions)
  const customerPermissions = getPermission({ permissionKey: APP_MODULES.Customer, permissions })

  return (
    <Box>
      <VStack spacing={3}>
        <Card w="full">
          <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
            <SimpleGrid columns={{ base: 1, md: 2 }} w="full" alignItems={"center"}>
              <Flex align="center">
                <Avatar
                  size="xl"
                  src={customerQuery.data?.profilePictureUrl}
                />
                <Box ml={3} maxW="450px">
                  <Text fontWeight={"500"} fontSize="16px">
                    {customerQuery.data?.name}
                  </Text>
                  <Text
                    fontSize={"13px"}
                    color={getColor(colorKeys.secondaryText, colorMode)}
                    as="a"
                    href={`mailto:${customerQuery.data?.email}`}
                    _hover={{ textDecor: "underline" }}
                  >
                    {customerQuery.data?.email}
                  </Text>
                  <LabelValuePair
                    headingProps={{ fontSize: "13px", fontWeight: "semibold" }}
                    valueProps={{ fontSize: "13px", as: Link, to: `/admin/corporates/${customerQuery.data?.corporate?.id}`, _hover: { textDecor: "underline" } }}
                    label={"Corporate"}
                    value={customerQuery.data?.corporate?.name}
                  />
                  <LabelValuePair
                    headingProps={{ fontSize: "13px", fontWeight: "semibold" }}
                    valueProps={{ fontSize: "13px" }}
                    label={"Corporate Pay"}
                    value={customerQuery.data?.corporate?.name}
                    valueComponent={<chakra.span fontSize={"13px"}>{customerQuery.data?.isCorporatePay ? "Enabled" : "Disabled"}</chakra.span>}
                  />
                  <LabelValuePair
                    headingProps={{ fontSize: "13px", fontWeight: "semibold" }}
                    valueProps={{ fontSize: "13px" }}
                    label={"Corporate Payment Method"}
                    value={breakWithCaps(customerQuery.data?.corporate?.corporatePaymentType?.name)}
                  />
                  {primaryCard?.data?.lastFourDigits && <LabelValuePair
                    headingProps={{ fontSize: "13px", fontWeight: "semibold" }}
                    valueProps={{ fontSize: "13px" }}
                    label={"Customer Primary Card"}
                    value={primaryCard?.data?.lastFourDigits}
                    valueComponent={<HStack spacing={3}>
                      <Tooltip
                        label={primaryCard?.data?.brand}
                        placement="top"
                      >
                        <Image
                          w="32px"
                          border={`1px solid #CED4DA`}
                          borderRadius={"3px"}
                          src={primaryCard?.data?.logoUrl}
                        />
                      </Tooltip>
                      <Text
                        fontSize="13px"
                        color={getColor(colorKeys.secondaryText, colorMode)}
                      >
                        **** **** ****{" "}
                        {primaryCard?.data?.lastFourDigits}
                      </Text>
                    </HStack>}
                  />}
                  {customerQuery.data?.note && (
                    <Box>
                      <Text fontSize="13px">
                        <chakra.span fontWeight="bold">Note:</chakra.span>
                      </Text>
                      <CollapsableBox>
                        <Text
                          fontSize="13px"
                          color={getColor(colorKeys.dark, colorMode)}
                        >
                          {customerQuery.data?.note}
                        </Text>
                      </CollapsableBox>
                    </Box>
                  )}
                </Box>
              </Flex>
              <Flex flexDir={"column"} alignItems="self-end">
                <LabelValuePair
                  label={"Total Rides"}
                  value={customerQuery.data?.totalRides}
                  headingProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.secondaryText, colorMode),
                    fontWeight: "normal",
                  }}
                  valueProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.dimText, colorMode),
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                />
                <LabelValuePair
                  label={"Total Spending"}
                  value={formatAmount(customerQuery.data?.totalSpending)}
                  headingProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.secondaryText, colorMode),
                    fontWeight: "normal",
                  }}
                  valueProps={{
                    fontSize: "15px",
                    color: getColor(colorKeys.dimText, colorMode),
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                />
                <HStack spacing={3} mt={3}>
                  <PrimaryButton
                    size={"sm"}
                    onClick={transactionDisclosure.onOpen}
                  >
                    Transactions
                  </PrimaryButton>
                  <PrimaryButton
                    size={"sm"}
                    onClick={customerFormDisclosure.onOpen}
                  >
                    Update Profile
                  </PrimaryButton>
                  {customerPermissions?.additionalActions?.includes("UpdateCreditBalance") && <PrimaryButton
                    size={"sm"}
                    isDisabled={!customerQuery.data?.stripeCustomerId}
                    onClick={creditFormDisclosure.onOpen}
                    title={!customerQuery.data?.stripeCustomerId && "Disabled as stripe customer id is not available"}
                    pointerEvents="all"
                  >
                    Update Credit Balance
                  </PrimaryButton>}
                </HStack>
              </Flex>
            </SimpleGrid>
          </CardBody>
        </Card>
        <CustomerBookings />
      </VStack>

      {customerPermissions?.additionalActions?.includes("UpdateCreditBalance") && <UpdateCreditForm
        data={customerQuery.data}
        disclosure={creditFormDisclosure}
      />}
      <CustomerForm
        disclosure={customerFormDisclosure}
        data={customerQuery.data}
      />
      <CustomerTransactions
        isOpen={transactionDisclosure.isOpen}
        onClose={transactionDisclosure.onClose}
      />
    </Box>
  );
};

export default CustomerDetails;
