import { Avatar, AvatarBadge, Box, Icon, IconButton, SkeletonCircle, useColorMode } from '@chakra-ui/react'
import React from 'react'
import FormFileUpload from '../../../../components/Forms/FormFileUpload'
import { colorKeys, getColor } from '../../../../config/constants/appColors'
import APP_ICONS from '../../../../config/constants/icons'

const ImageInformation = ({ isLoading, fields, fileData, setFileData }) => {
    const { colorMode } = useColorMode()
    
    const onChangeImage = (file) => {
        setFileData({
            binary: file,
            url: URL.createObjectURL(file)
        })
    }
    return (
        <Box m="auto">
            {isLoading ? <SkeletonCircle size='130' /> : (
                <Avatar
                    size="2xl"
                    name={fields?.name}
                    src={fileData?.url}

                >
                    <AvatarBadge boxSize='8'>
                        <FormFileUpload
                            id={"profilePicture"}
                            onChange={onChangeImage}
                            component={<IconButton
                                variant={"ghost"}
                                icon={<Icon as={APP_ICONS.EDIT} boxSize="7" color={getColor(colorKeys.primary, colorMode)} />}
                            />}
                            componentProps={{ variant: "ghost" }}
                            fileType={"IMAGE"}
                        />
                    </AvatarBadge>
                </Avatar>
            )}
        </Box>

    )
}

export default ImageInformation