export const STATUS = {
  0: "Inactive",
  1: "Active",
};

export const STATUSES = {
  Inactive: 0,
  Active: 1,
};

export const ADDRESS_TYPE = {
  0: "Home",
  1: "Work",
  2: "Store",
  3: "Other",
};

export const ADMIN_STATUS = {
  0: "Inactive",
  1: "Active",
};

export const USER_TYPES = {
  0: "Customer",
  1: "Driver",
  2: "Corporate",
};

export const SEND_TO_ALL = {
  AllCorporates: "All Corporates",
  AllCustomers: "All Customers",
  AllDrivers: "All Drivers",
};

export const SEND_TO_ALLS = {
  AllCorporates: "AllCorporates",
  AllCustomers: "AllCustomers",
  AllDrivers: "AllDrivers",
};

export const AVAILABLITY_STATUS = {
  0: "Offline",
  1: "Online",
};

export const CHAT_MESSAGE_TYPE = {
  Message: 0,
  Info: 1,
};

export const CHAT_STATUS = {
  0: "Open",
  1: "Closed",
};

export const CORPORATE_CUSTOMER_STATUS = {
  0: "Pending",
  1: "Active",
  2: "Blocked",
};

export const CORPORATE_CUSTOMER_STATUSES = {
  Pending: 0,
  Active: 1,
  Blocked: 2,
};

export const CORPORATE_STATUS = {
  0: "Inactive",
  1: "Active",
};

export const CORPORATE_STATUSES = {
  Inactive: 0,
  Active: 1,
};

export const CUSTOMER_STATUS = {
  0: "Inactive",
  1: "Active",
};

export const CUSTOMER_TYPE = {
  0: "Individual",
  1: "Corporate",
};

export const CUSTOMER_TYPES = {
  Individual: 0,
  Corporate: 1,
};

export const CustomerType = {
  INDIVIDUAL: 0,
  CORPORATE: 1,
};

export const DISCOUNT_TYPE = {
  0: "Percentage",
  1: "Flat",
};

export const DRIVER_STATUS = {
  0: "Inactive",
  1: "Active",
};

export const GENDERS = {
  0: "Male",
  1: "Female",
  2: "Prefer Not To Respond",
};

export const NOTIFICATION_STATUS = {
  0: "Unseen",
  1: "Seen",
};

export const OVERALL_STATUS = {
  0: "Good",
  1: "Poor",
  2: "Excellent",
};

export const PAYMENT_TYPE = {
  0: "Cash",
  1: "Card",
  2: "DirectInvoice",
  3: "Comp",
  4: "Corporate Card"
};

export const PAYMENT_TYPES = {
  Cash: 0,
  Card: 1,
  DirectInvoice: 2,
  Comp: 3,
  CorporateCard: 4
}


export const EXTERNAL_WALLET_TYPE = {
  0: "ApplePay",
  1: "GooglePay",
};

export const CUSTOMER_PAYMENT_TYPES = {
  0: "Cash",
  1: "Card",
  3: "Comp",
};

export const PRICING_STATUS = {
  0: "Inactive",
  1: "Active",
};

export const PROMO_CODE_TYPE = {
  0: "Individual",
  1: "Corporate",
};

export const PROMO_CODE_TYPES = {
  INDIVIDUAL: 0,
  CORPORATE: 1,
};

export const BOOKINGS_STATUS = {
  0: "Active",
  1: "Scheduled",
  2: "Finished",
};

export const BOOKINGS_STATUSES = {
  Active: 0,
  Scheduled: 1,
  Finished: 2,
};

//will be used in booking listing
export const RIDE_STATUS = {
  0: "Cancelled",
  1: "Dispatching",
  2: "Accepted",
  3: "Driver On The Way",
  4: "Arrived and Waiting",
  5: "P.O.B",
  6: "Dropped Off",
  7: "Completed",
  10: "Parking Lot"
};

//will be used in active booking listing
export const ACTIVE_RIDE_STATUS = {
  1: "Dispatching",
  2: "Accepted",
  3: "Driver On The Way",
  4: "Arrived and Waiting",
  5: "P.O.B",
  6: "Dropped Off",
  10: "Parking Lot"
};

export const RIDE_STATUSES = {
  Cancelled: 0,
  WaitingForAccept: 1,
  Accepted: 2,
  DriverOnTheWay: 3,
  Arrived: 4,
  InTransit: 5,
  Reached: 6,
  Completed: 7,
  NoShows: 8,
  Incidents: 9,
  ParkingLot: 10
};

export const RIDE_TYPE = {
  0: "Now",
  1: "Schedule",
};

export const RideType = {
  NOW: 0,
  SCHEDULED: 1,
};

export const VEHICLE_CLASS = {
  0: "Standard",
  1: "Premium",
};

export const ZONE_STATUS = {
  0: "Inactive",
  1: "Active",
};

export const STRIPE_STATUS = {
  0: "Pending",
  1: "Active",
  2: "Inactive",
};

export const ACCESS_TYPES = {
  0: "Public",
  1: "Private",
};

export const ACTIONS = {
  EDIT: "Edit",
  DELETE: "Delete",
  VIEW: "View",
};

export const DAYS_OF_WEEK = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednessday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const CURRENCY = {
  usd: "$",
};

export const BALANCE_ADJUST_TYPE = {
  ADD: 0,
  DEDUCT: 1,
};

export const CANCEL_BOOKING_TYPE = {
  0: "NoShow",
  1: "Cancel",
  2: "Other",
};

export const CANCEL_BOOKING_REASON = {
  NoShows: 0,
  Cancel: 1,
  Other: 2,
};

export const WEEK_DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday"
}

export const REPEAT_TYPE = {
  0: "Days of Week",
  1: "Specific Date",
  2: "Yearly",
}

export const REPEAT_TYPES = {
  "DaysOfWeek": 0,
  "SpecificDate": 1,
  "Yearly": 2,
}

export const RIDES_DRIVER_STATUS = {
  DISPATCHING: 1,
  ACCEPTED: 2,
  DRIVER_ON_THE_WAY: 3,
  ARRIVED_AND_WAITING: 4,
  POB: 5,
  DROPPED_OFF: 6,
  AVAILABLE: 7,
  UNAVAILABLE: 8,
  PARKING_LOT: 10,
  COMPLETED: 11,
  CANCELLED: 12,
  OFFLINE: 13,
}

export const RIDES_DRIVER_STATUSES = {
  1: "Dispatching",
  2: "Accepted",
  3: "Driver On The Way",
  4: "Arrived & Waiting",
  5: "POB",
  6: "Dropped Off",
  7: "Available",
  8: "Unavailable",
  10: "Parking Lot",
  11: "Completed",
  12: "Cancelled",
  13: "Offline"
}

export const View_MODE = {
  0: "Hourly",
  1: "Daily",
  2: "Weekly",
  3: "Monthly",
}

export const View_MODES = {
  Hourly: 0,
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
}

export const CHAT_TYPES = {
  Support: 0,
  ReportIssue: 1,
  CustomerDriver: 2,
  Custom: 3,
}

export const PARTICIPANT_TYPES = {
  Admin: "Admin",
  Corporate: "Corporate",
  Driver: "Driver",
  Customer: "Customer"
}

export const DELIVER_STATUSES = {
  SENT: 0,
  DELIVERED: 1,
  READ: 2,
};
export const DELIVER_STATUS = {
  0: "Sent",
  1: "Delivered",
  2: "Read",
};

export const COLOR_MODES = {
  LIGHT: "light",
  DARK: "dark",
}

export const INVOICE_PAY_PERIOD = {
  0: "Weekly",
  1: "Monthly",
}

export const PAYMENT_STATUS = {
  0: "Pending",
  1: "Paid",
}

export const TIME_SWITCH = {
  LOCAL: "Local Time",
  BOOKING_ZONE: "Booking Zone Time",
}

export const TIME_SWITCHES = {
  LOCAL: "LOCAL",
  BOOKING_ZONE: "BOOKING_ZONE",
}

export const BOOKING_ACTIONS = {
  Ratings: "Ratings",
  DispatchLogs: "DispatchLogs",
  TransactionLogs: "TransactionLogs",
  TrackBooking: "TrackBooking",
  CostBreakdown: "CostBreakdown",
  CancelBooking: "CancelBooking",
  CompleteBooking: "CompleteBooking",
}

export const RIDE_CANCELLATION_CHARGE_TYPE = {
  NoShowCharge: 0,
  CancellationCharge: 1,
  NoCharge: 2
}

export const CONFIG_FILE_TYPES = {
  PrivacyPolicy: "PrivacyPolicy",
  TermsAndConditions: "TermsAndConditions",
}