import React from "react";
import { Button, Icon, useColorMode } from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";

const ExternalLink = ({ href, label, fallbackText = "N/A", ...rest }) => {
  const { colorMode } = useColorMode();

  const linkProps = {
    as: "p",
  };
  if (href) {
    linkProps.href = href;
    linkProps.as = "a";
    linkProps.rightIcon = href && <Icon as={APP_ICONS.OPEN} />;
    linkProps.pointerEvents = href ? "auto" : "none";
  }
  return (
    <Button
      variant={"link"}
      pointerEvents={href ? "auto" : "none"}
      size="sm"
      fontWeight={"normal"}
      target="_blank"
      _hover={{
        color: getColor(colorKeys.lightBlue, colorMode),
      }}
      fontSize="14px"
      color={getColor(colorKeys.primaryText, colorMode)}
      maxWidth="275px"
      whiteSpace="break-spaces"
      display="block"
      {...linkProps}
      {...rest}
    >
      {label || href || fallbackText}
    </Button>
  );
};

export default ExternalLink;
