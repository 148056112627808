import { Card, CardBody, Heading, useColorMode } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import PieChart from "../../../components/Charts/PieChart";
import { useFinishedBookings } from "../../../config/query/dashboardQuery";
import { prepareFinishedBookings } from "../../../config/helpers/formHelpers/bookingHelper";

const FinishedBookings = ({ query }) => {
  const { colorMode } = useColorMode();
  const finishedBookings = useFinishedBookings(query)

  const result = useMemo(() => {
    return prepareFinishedBookings(finishedBookings.data?.data)
  }, [finishedBookings.data?.data])

  return (
    <Card h="full" bg={getColor(colorKeys.tableBackground, colorMode)}>
      <CardBody minH="400px">
        <Heading as="h2" size={"md"} fontSize="15px" fontWeight={"600"} mb={4}>
          Finished Bookings
        </Heading>
        <PieChart data={result} />
      </CardBody>
    </Card>
  );
};

export default FinishedBookings;
