import { formatDateForSever } from "../dateHelper"
import generateString from "../generateString"

export const prepareForm = ({
    formValues,
    serverValues
}) => ({
    ...(formValues?.id && { id: formValues?.id, ...serverValues }),
    cityId: serverValues?.city?.id,
    languageId: serverValues?.language?.id,
})

export const preparePayload = (values, id) => ({
    ...values,
    ...(id && { id }),
    ...(!id && { id: generateString(10) }),
    validUpto: formatDateForSever(values.validUpto)
})