import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS from "../constants/api";
import { CONTENT_TYPE } from "../constants";

export const useInbox = (params) => {
  const token = useSelector((state) => state.user.token);

  return useInfiniteQuery({
    queryKey: ["inbox", params],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await Get({
        path: `${API_CONSTANTS.SUPPORT.inbox}?${appendQueryParams({
          ...params,
          PageNumber: Number(pageParam),
        })}`,
        token,
      });
      return data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.meta?.hasNextPage;
    },
    keepPreviousData: true,
  });
};

export const useIssuesInbox = (params) => {
  const token = useSelector((state) => state.user.token);
  return useInfiniteQuery({
    queryKey: ["issues-inbox", params],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await Get({
        path: `${API_CONSTANTS.SUPPORT.issuesInbox}?${appendQueryParams({
          ...params,
          PageNumber: Number(pageParam),
        })}`,
        token,
        toastError: false,
        toastMessage: false,
      });
      return data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.meta?.hasNextPage;
    },
    keepPreviousData: true,
  });
};

export const useGeneralInbox = (params) => {
  const token = useSelector((state) => state.user.token);
  return useInfiniteQuery({
    queryKey: ["general-inbox", params],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await Get({
        path: `${API_CONSTANTS.SUPPORT.generalInbox}?${appendQueryParams({
          ...params,
          PageNumber: Number(pageParam),
        }, false)}`,
        token,
        toastError: false,
        toastMessage: false,
      });
      return data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.meta?.hasNextPage;
    },
    keepPreviousData: false,
  });
};

export const useGeneralInboxById = (id) => {
  const token = useSelector((state) => state.user.token);
  return useQuery({
    queryKey: ["general-inbox", id],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.SUPPORT.generalInbox}/${id}`,
        token,
        toastError: false,
        toastMessage: false,
      });
      return data;
    },
    enabled: !!id,
  });
};

export const useSendMessage = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.SUPPORT.send,
        token,
        body,
        toastError: true,
        contentType: CONTENT_TYPE.FORM_DATA,
      });
      return data;
    },
  });
};

export const useSupportMessages = (params) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.SUPPORT.listMessages}?${appendQueryParams(
          params
        )}`,
        token,
        toastError: true,
      });
      return data;
    },
    queryKey: ["support-messages", params],
  });
};

export const useSupportChatList = (params) => {
  const token = useSelector((state) => state.user.token);
  return useInfiniteQuery({
    queryKey: ["messages", params],
    queryFn: async ({ pageParam }) => {
      const data = await Get({
        path: `${API_CONSTANTS.SUPPORT.listMessages}?${appendQueryParams({
          ...params,
          ...(pageParam && { BeforeCreatedAt: pageParam }),
        })}`,
        token,
        toastError: true,
        toastMessage: false,
      });
      return data;
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.meta?.hasNextPage ? lastPage?.data?.at(lastPage?.data?.length - 1)?.createdAt : false;
      return lastPage?.data?.at(lastPage?.data?.length - 1)?.createdAt;
    },
    meta: {
      PageNumber: 1,
    },
    keepPreviousData: true,
    enabled: !!params.ChatId,
  });
};

export const useJoinChat = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (chatId) => {
      const data = await Patch({
        path: API_CONSTANTS.SUPPORT.join.replace(":chatId", chatId),
        token,
      });
      return data;
    },
  });
};

export const useLeaveChat = () => {
  const token = useSelector((state) => state.user?.token)
  return useMutation({
    mutationFn: async (chatId) => {
      const data = await Patch({
        path: API_CONSTANTS.SUPPORT.leave.replace(":chatId", chatId),
        token,
        toastError: true,
        toastMessage: true,
      })
      return data
    },
  })
}

export const useDeleteChat = () => {
  const token = useSelector((state) => state.user?.token)
  return useMutation({
    mutationFn: async (chatId) => {
      const data = await Delete({
        path: API_CONSTANTS.SUPPORT.delete.replace(":chatId", chatId),
        token,
        toastError: true,
        toastMessage: true,
      })
      return data
    },
  })
}

export const useCreateChat = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.SUPPORT.base,
        token,
        body,
        toastError: true,
      });
      return data;
    },
  });
};

export const useUpdateChat = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Put({
        path: API_CONSTANTS.SUPPORT.base,
        token,
        body,
        toastError: true,
      });
      return data;
    },
  });
};

export const useChatParticipants = ({ chatId, enabled }) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryFn: async () => {
      const data = await Get({
        path: API_CONSTANTS.SUPPORT.listParticipants.replace(":chatId", chatId),
        token,
        toastError: true,
      });
      return data;
    },
    enabled,
    queryKey: ["chat-participants", chatId],
  });
};

export const useRemoveParticipants = (chatId) => {
  const queryClient = useQueryClient();
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.SUPPORT.removeParticipants.replace(
          ":chatId",
          chatId
        ),
        token,
        body,
        toastError: true,
      });
      return data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries(["chat-participants", chatId]),
  });
};

export const useAddParticipants = (chatId) => {
  const queryClient = useQueryClient();
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.SUPPORT.addParticipants.replace(":chatId", chatId),
        token,
        body,
        toastError: true,
      });
      return data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries(["chat-participants", chatId]),
  });
};

export const useUpadetChatImage = () => {
  const token = useSelector((state) => state.user?.token)
  return useMutation({
    mutationFn: async (body) => {
      const data = await Patch({
        path: API_CONSTANTS.SUPPORT.updateChatImage,
        token,
        body,
        toastError: true,
        contentType: CONTENT_TYPE.FORM_DATA
      })
      return data
    },
  })
}

export const useReadLastMessage = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async ({ id, messageId }) => {
      const data = await Patch({
        path: `${API_CONSTANTS.SUPPORT.readLastMessage.replace(
          ":chatId",
          id
        )}?messageId=${messageId}`,
        token,
      });
      return data;
    },
  });
};
export const useAcknowledgeLastMessage = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async ({ id, messageId }) => {
      const data = await Patch({
        path: `${API_CONSTANTS.SUPPORT.acknowledgeLastMessage.replace(
          ":chatId",
          id
        )}?messageId=${messageId}`,
        token,
      });
      return data;
    },
  });
};