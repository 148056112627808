import React from "react";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Text,
  Icon,
  chakra,
} from "@chakra-ui/react";
import APP_ICONS from "../../../config/constants/icons";
import { formatMessageTime } from "../../../config/helpers/dateHelper";

const SecondPersonMessage = ({ message }) => {
  return (
    <Flex flexDir={"column"} align={"start"} w="full" id={message?._id}>
      <HStack ml="10px">
        <Avatar src={message?.user?.image} mt="8px" size="xs" />
        <Text fontSize="11px">{message?.user?.name}</Text>
      </HStack>
      <Flex mt="-8px" justify={"start"} w="full">
        {message?.thumbnailImageUrl ? (
          <Flex
            justify="center"
            align="center"
            shadow="md"
            ml="40px !important"
            h={"150px"}
            maxW="80%"
            rounded="md"
            bgImage={`url(${message?.thumbnailImageUrl})`}
            bgSize={"cover"}
            w="150px"
          >
            <IconButton
              as="a"
              download={true}
              href={message?.imageUrl}
              icon={<Icon as={APP_ICONS.DownArrow} boxSize="6" />}
              rounded="full"
            />
          </Flex>
        ) : (
          <Box
            bg="#47a7dd"
            color="white"
            shadow="md"
            maxW="80%"
            w="fit-content"
            p="8px 14px"
            borderRadius="2px 8px 8px 8px"
            ml="40px !important"
            fontSize={"13px"}
          >
            {message?.text} <br />
            <chakra.span fontSize="11px">
              {formatMessageTime(message?.createdAt)}
            </chakra.span>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default SecondPersonMessage;
