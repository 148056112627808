import { Card, CardBody, Input, VStack, useColorMode, useDisclosure } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import BookingCard from './BookingCard'
import { RIDES_DRIVER_STATUS } from '../../../config/constants/enums'

const MapBookings = ({ list, setSelected }) => {
    const { colorMode } = useColorMode()
    const bookingFormDisclosure = useDisclosure()


    const handleSelectBooking = (item) => {
        if (!item.driverLocation && !item?.stops?.at(0)?.location?.lat) return
        setSelected(item)
        bookingFormDisclosure.onOpen()
    }

    const [query, setQuery] = useState({
        searchQuery: "",
        vehicleTypeId: null,
        cityId: null,
    })

    const bookingsList = useMemo(() => {
        const searchTerm = query.searchQuery?.toLowerCase()
        if (!query.searchQuery) {
            return list
        }
        return list?.filter((item) =>
            item?.passengerName?.toLowerCase()?.includes(searchTerm) ||
            item.driverName?.toLowerCase()?.includes(searchTerm) ||
            String(item.rideId)?.includes(searchTerm) ||
            String(item.driverId)?.includes(searchTerm) ||
            String(item.passengerId)?.includes(searchTerm)
        )
    }, [list, query.searchQuery])

    return (
        <Card>
            <CardBody px="3" bg={getColor(colorKeys.tableBackground, colorMode)}>
                <VStack spacing={5} align="stretch">
                    <Input
                        placeholder="Search passenger, driver, ride id etc..."
                        value={query?.searchQuery}
                        size="sm"
                        onChange={(e) => setQuery({ ...query, searchQuery: e.target.value })}
                    />
                    <VStack>
                        {bookingsList?.map((item) => {
                            if (item?.status?.id === RIDES_DRIVER_STATUS.OFFLINE) return null
                            return <BookingCard key={item.id} data={item} onClick={() => handleSelectBooking(item)} />
                        })}
                    </VStack>
                </VStack>
            </CardBody>
        </Card>
    )
}

export default MapBookings