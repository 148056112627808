import React from "react";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import APP_ICONS from "../../../config/constants/icons";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import MessageStatus from "../ChatLayout/MessageStatus";
import { formatMessageTime } from "../../../config/helpers/dateHelper";

const FirstPersonMessage = ({ message }) => {
  const { colorMode } = useColorMode();
  return (
    <Flex justify={"end"} w="full" id={message?._id}>
      {message?.thumbnailImageUrl ? (
        <Flex
          mr="10px !important"
          justify="center"
          align="center"
          shadow="md"
          ml="40px !important"
          h={"150px"}
          maxW="80%"
          rounded="md"
          bgImage={`url(${message?.thumbnailImageUrl})`}
          bgSize={"cover"}
          w="150px"
        >
          {message?.status && (
            <IconButton
              mr="-20px"
              as="a"
              download={true}
              href={message?.imageUrl}
              icon={<Icon as={APP_ICONS.DownArrow} boxSize="6" />}
              rounded="full"
            />
          )}
          <Flex
            justify={"end"}
            w="fit-content"
            pos="relative"
            bottom="-60px"
            left="48px"
            rounded={"full"}
            p="2px"
            bg={getColor(colorKeys.white, colorMode)}
          >
            <Icon
              color={getColor(colorKeys.dark, colorMode)}
              as={message?.status ? APP_ICONS.DOUBLE_CHECK : APP_ICONS.CLOCK}
            />
          </Flex>
        </Flex>
      ) : (
        <Box
          bg="#eaeaea"
          color="#000"
          shadow="md"
          maxW="80%"
          w="fit-content"
          p="8px 14px"
          borderRadius="10px 10px 0px 10px"
          mr="10px !important"
          fontSize={"13px"}
        >
          <Text>{message?.text}</Text>
          <Text textAlign={"end"} fontSize="11px">
            {formatMessageTime(message?.createdAt)}
          </Text>
          <Flex justify={"end"} w="full">
            <MessageStatus status={message?.deliveryStatus?.id} />
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default FirstPersonMessage;
