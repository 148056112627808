import { formatDateForSever } from "../dateHelper"

export const prepareForm = ({
    formValues,
    serverValues
}) => ({
    seatingCapacity: 3,
    ...(formValues?.id && {
        id: formValues?.id,
        ...serverValues,
        vehicleTypeId: serverValues?.vehicleType?.id,
        colorId: serverValues?.color?.id,
        zoneIds: serverValues?.zones?.map((item) => item.id),
        modelId: serverValues?.model?.id,
        make: serverValues?.make?.id,
        insuranceEffectiveDate: serverValues?.insuranceEffectiveDate ? new Date(serverValues?.insuranceEffectiveDate) : undefined,
        insuranceExpiryDate: serverValues?.insuranceExpiryDate ? new Date(serverValues?.insuranceExpiryDate) : undefined,
        defaultVehicleId: serverValues?.vehicleTypes?.find((item) => item.isDefault)?.vehicleTypeId,
        vehicleTypes: serverValues?.vehicleTypes?.map((item) => item.vehicleTypeId),
    }),
})

export const preparePayload = (values, id) => {
    delete values?.color
    delete values?.message
    delete values?.model
    delete values?.vehicleType
    delete values?.zone

    let payload = {
        ...values,
        ...(id && { id }),
        seatingCapacity: parseInt(values.seatingCapacity),
        insuranceEffectiveDate: formatDateForSever(values.insuranceEffectiveDate),
        insuranceExpiryDate: formatDateForSever(values.insuranceExpiryDate),
    }

    payload.vehicleTypes = values.vehicleTypes.map((item) => ({
        vehicleTypeId: item,
        isDefault: Number(values.defaultVehicleId) === item
    }))

    return payload
}