import React, { useState, useMemo } from 'react'
import { InfoWindow } from '@react-google-maps/api'
import { Link } from 'react-router-dom'
import { Box, Flex, Icon, IconButton, Select, useColorMode, useDisclosure } from '@chakra-ui/react'
import SimpleMap from '../../../components/Maps/SimpleMap'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import APP_ICONS from '../../../config/constants/icons'
import BookingFormModal from '../Bookings/BookingForm/BookingFormModal'
import MapMarker from './MapMarker'
import { useBooking } from '../../../config/query/bookingsQuery'
import { RIDE_STATUSES, RIDES_DRIVER_STATUS } from '../../../config/constants/enums'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import { useZones } from '../../../config/query/zoneQuery'
import { breakWithCaps, removeUndefinedValues } from '../../../config/helpers/stringHelper'

const RideMapSection = ({ drivers, selected, setSelected, driversHashMap, ridesHashMap }) => {
    const { colorMode } = useColorMode()
    const bookingFormDisclosure = useDisclosure()
    const bookingQuery = useBooking(selected?.rideId)
    const zones = useZones()
    const [selectedZone, setSelectedZone] = useState(null)

    const handleZoneChange = (e) => {
        const id = e.target.value
        if (id === "all") {
            setSelectedZone(null)
        }
        else {
            const zone = zones?.data?.find((item) => item.id === Number(id))
            setSelectedZone(zone)
        }
    }

    const calculatedCenter = useMemo(() => {
        if (selectedZone) {
            const center = selectedZone?.coordinates?.at(0)
            return center
        }
        if (zones.data?.length) {
            const center = zones?.data?.at(0)?.coordinates?.at(0)
            return center
        }
        if (drivers?.length) {
            const center = drivers?.at(0)?.driverLocation
            return center
        }
        return undefined
    }, [selectedZone, drivers, zones.data])

    const selectedRide = useMemo(() => {
        if (!selected) return undefined
        if (driversHashMap && ridesHashMap) {
            const ride = driversHashMap[selected.driverId] || ridesHashMap[selected.rideId]
            return ride
        }
        return null
    }, [selected, driversHashMap, ridesHashMap])
    // })

    const isEditable = useMemo(() => {
        if (!selectedRide) return false
        const { status } = selectedRide
        if (status === RIDE_STATUSES.WaitingForAccept || status === RIDE_STATUSES.Arrived || status === RIDE_STATUSES.DriverOnTheWay || status === RIDE_STATUSES.Accepted || status === RIDE_STATUSES.ParkingLot) {
            return true
        }
    }, [selectedRide])

    return (
        <>
            <SimpleMap
                center={calculatedCenter}
                containerProps={{ h: { base: "calc(100vh - 100px)", lg: "100%" } }}
                zoom={10}
                fullscreenControlOptions={{ position: 10 }}
            >
                <Box maxW="250px" pos="absolute" right={"10px"} top="5px">
                    <Select onChange={handleZoneChange} w="250px" size="sm" shadow="none !important" bg={getColor(colorKeys.white, colorMode)}>
                        {zones?.data?.map((item) =>
                            <option value={item?.id}>{item?.name}</option>
                        )}
                    </Select>
                </Box>
                {(window.google) && drivers?.map((item, index) => {
                    if (item?.status?.id === RIDES_DRIVER_STATUS.OFFLINE) return null
                    return <MapMarker
                        key={index}
                        setSelected={setSelected}
                        item={item}
                    />
                })}
                {selectedRide && (
                    <InfoWindow
                        position={selectedRide?.driverLocation ? selectedRide.driverLocation : selectedRide.stops?.at(0)?.location}
                        onCloseClick={() => setSelected(null)}
                        options={{
                            pixelOffset: new window.google.maps.Size(0, -30),
                        }}

                    >
                        <Flex minW="300px" justify={"space-between"} align={"center"}>
                            <Box minW="250px">
                                <LabelValuePair valueProps={{ color: getColor(colorKeys.dark, colorMode) }} headingProps={{ color: getColor(colorKeys.dark, colorMode) }} label="Booking#" value={selectedRide?.rideId} />
                                <LabelValuePair valueProps={{ color: getColor(colorKeys.dark, colorMode), as: Link, to: `/admin/customers/${selectedRide?.passengerId}`, _hover: { textDecor: "underline" } }} headingProps={{ color: getColor(colorKeys.dark, colorMode) }} label="Passenger" value={selectedRide?.passengerName} />
                                <LabelValuePair valueProps={{ color: getColor(colorKeys.dark, colorMode), as: Link, to: `/admin/drivers/${selectedRide?.driverId}`, _hover: { textDecor: "underline" } }} headingProps={{ color: getColor(colorKeys.dark, colorMode) }} label="Driver" value={selectedRide?.driverName} />
                                <LabelValuePair valueProps={{ color: getColor(colorKeys.dark, colorMode) }} headingProps={{ color: getColor(colorKeys.dark, colorMode) }} label="Phone" value={selectedRide?.driverPhoneNo} />
                                <LabelValuePair valueProps={{ color: getColor(colorKeys.dark, colorMode) }} headingProps={{ color: getColor(colorKeys.dark, colorMode) }} label="Status" value={breakWithCaps(selectedRide?.status?.name)} />
                                <LabelValuePair valueProps={{ color: getColor(colorKeys.dark, colorMode) }} headingProps={{ color: getColor(colorKeys.dark, colorMode) }} label="Vehicle" value={removeUndefinedValues([selectedRide?.make, selectedRide?.model, selectedRide?.year], " - ")} />
                                <LabelValuePair valueProps={{ color: getColor(colorKeys.dark, colorMode) }} headingProps={{ color: getColor(colorKeys.dark, colorMode) }} label="License Plate" value={selectedRide?.licensePlate} />
                                <LabelValuePair valueProps={{ color: getColor(colorKeys.dark, colorMode) }} headingProps={{ color: getColor(colorKeys.dark, colorMode) }} label="Dispatching to" value={selectedRide?.dispatchingDriverInfo?.name} />
                            </Box>
                            {selectedRide?.rideId && <IconButton bg="#EDF2F7" color="#1A202C" size="sm" icon={<Icon as={APP_ICONS.EYE} />} as={Link} to={`/admin/bookings/${selectedRide?.rideId}`} />}
                            {isEditable ? <IconButton bg="#EDF2F7" color="#1A202C" size="sm" icon={<Icon as={APP_ICONS.EDIT} />} onClick={bookingFormDisclosure.onOpen} /> : null}
                        </Flex>
                    </InfoWindow>
                )}
            </SimpleMap>
            <BookingFormModal disclosure={bookingFormDisclosure} data={bookingQuery.data} />
        </>
    )
}

export default RideMapSection