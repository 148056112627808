export const prepareForm = ({
    formValues,
    serverValues
}) => ({
    ...(formValues?.id && {
        id: formValues?.id,
        ...serverValues,
        status: serverValues?.status?.id,
        vehicleTypeId: serverValues?.vehicleType?.id,
    }),
    ...(!formValues?.id && {
        otherFares: [],
    }),
})

export const preparePayload = (values, id) => {
    Object.keys(values).forEach((key) => {
        if (key === "otherFares") return
        if (!isNaN(values[key])) {
            values[key] = Number(values[key])
        }
    })

    values = {
        ...values,
        ...(id && { id }),
        otherFares: values?.otherFares?.filter((fare) => (fare.fareType && fare.fareRate)).map((fare) => ({
            id: fare?.id,
            fareType: fare?.fareType,
            fareRate: Number(fare?.fareRate),
        }))
    }

    return values
}


//BUG ALERT IN THIS FILE, OTHER FARES ARE NOT CONVERTING TO NUMBER WHILE PREPARING PAYLOAD