import { DEFAULT_COUNTRY_CODE } from "../../constants/settings"
import { formatPhoneNumber } from "../stringHelper"

export const prepareForm = ({
    formValues,
    serverValues
}) => ({
    countryCode: serverValues?.countryCode || DEFAULT_COUNTRY_CODE,
    ...(formValues?.id && { id: formValues?.id, ...serverValues, }),
    cityId: serverValues?.city?.id,
    languageId: serverValues?.language?.id,
})

export const preparePayload = (values, id) => ({
    ...values,
    ...(id && { id }),
    localNumber: formatPhoneNumber(values?.countryCode, values?.localNumber)
})