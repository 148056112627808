import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useColorMode,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";

const NotificationUsers = ({ users }) => {
  const { colorMode } = useColorMode();
  return (
    <TableContainer mt={5}>
      <Table variant="simple">
        <Thead bg={getColor(colorKeys.tableStripedRowBackground, colorMode)}>
          <Tr>
            <Th textTransform="none" w="20%">
              S.No
            </Th>
            <Th textTransform="none">Name</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users?.map((item, index) => (
            <Tr key={item.id}>
              <Td
                fontSize="14px"
                color={getColor(colorKeys.primaryText, colorMode)}
              >
                {index + 1}
              </Td>
              <Td
                fontSize="14px"
                color={getColor(colorKeys.primaryText, colorMode)}
              >
                {item?.name}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default NotificationUsers;
