export const CACHE_KEYS = {
    BOOKINGS: {
        index: ['bookings'],
        base: (params) => ['bookings', params],
        single: (id) => ['booking', id],
    },
    MAKES: {
        index: ['makes'],
        base: (params) => ['makes', params],
        single: (id) => ['make', id],
        lookup: ['makes-lookup']
    }
}