import { Flex, VStack, useColorMode, Box, Image, Wrap, WrapItem, Tooltip, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor, getRideStatColor } from '../../../config/constants/appColors'
import { removeUndefinedValues } from '../../../config/helpers/stringHelper'
import IMAGES from '../../../config/constants/images'
import { RIDES_DRIVER_STATUSES } from '../../../config/constants/enums'


const BookingCard = ({ data, onClick }) => {
    const { colorMode } = useColorMode()
    return (
        <Flex role="group" onClick={onClick} cursor={"pointer"} w="full" p="10px" rounded="lg" _hover={{shadow:"0px 0px 18px 9px rgba(0,0,0,0.1)"}} shadow="0px 0px 18px 0px rgba(0,0,0,0.1)" flexDir={"column"}>
            <Flex align={{ base: "start", xl: "center" }} justify={"space-between"}>

                <Text fontSize={"12px"} fontWeight={"bold"}>{data.rideId && `Ride ID: ${data.rideId || "N/A"}`}</Text>

                <Box
                    rounded="full"
                    p="3px 10px"
                    color={"#fff"}
                    bg={getRideStatColor(data.status?.id)}
                    fontSize={"11px"}
                    minW="85px"
                    textAlign={"center"}
                >
                    {RIDES_DRIVER_STATUSES[data.status?.id]}
                </Box>
            </Flex>

            <Wrap align={"center"} mt={2}>
                {data?.driverId && <WrapItem>
                    <Tooltip label={"Driver Details"}>
                        <Flex cursor={"pointer"} align={"center"}>
                            <Image _groupHover={{ filter: `grayscale(0%)` }} filter={`grayscale(100%)`} mb={1} src={IMAGES.DRIVER} w="27px" h="27px" rounded="full" mr="10px" />
                            <Box>
                                <Text fontSize={"12px"} fontWeight={"bold"}>{data.driverName}</Text>
                                <Text fontSize={"12px"} color={getColor(colorKeys.gray, colorMode)}>{data.driverPhone}</Text>
                            </Box>
                        </Flex>
                    </Tooltip>
                </WrapItem>}

                {data?.driverId && <WrapItem>
                    <Tooltip label={"Car Details"}>
                        <Flex cursor={"pointer"} align={"center"}>
                            <Image _groupHover={{ filter: `grayscale(0%)` }} filter={`grayscale(100%)`} mb={1} src={IMAGES.CAR} w="27px" h="27px" rounded="full" mr="10px" />
                            <Box>
                                <Text fontSize={"12px"} fontWeight={"bold"}>{removeUndefinedValues([data?.make, data?.model, data?.year], " - ")}</Text>
                                <Text fontSize={"12px"} color={getColor(colorKeys.gray, colorMode)}>{data?.licensePlate}</Text>
                            </Box>
                        </Flex>
                    </Tooltip>
                </WrapItem>}

                {data?.passengerName && <WrapItem>
                    <Tooltip label={"Passenger Details"}>
                        <Flex cursor={"pointer"} align={"center"}>
                            <Image _groupHover={{ filter: `grayscale(0%)` }} filter={`grayscale(100%)`} mb={1} src={IMAGES.PASSENGER} w="27px" h="27px" rounded="full" mr="10px" />
                            <Box>
                                <Text fontSize={"12px"} fontWeight={"bold"}>{data?.passengerName}</Text>
                                <Text fontSize={"12px"} color={getColor(colorKeys.gray, colorMode)}>{data?.passengerPhone}</Text>
                            </Box>
                        </Flex>
                    </Tooltip>
                </WrapItem>}
            </Wrap>

            {data?.rideType?.name && <Text fontSize="11px" fontWeight={"bold"}>{data.rideId && `${data?.rideType?.name} Ride`}</Text>}

            <VStack align={"stretch"} mt={2}>
                {data.stops?.at(0)?.shortAddress && <HStack align="start">
                    <Text lineHeight={"1"} minW="43px" fontSize={"11px"} color={"gray"}>Pickup</Text>
                    <Text lineHeight={"1"} fontSize={"12px"}>{data.stops?.at(0)?.shortAddress}</Text>
                </HStack>}
                {data.stops?.at(data.stops?.length - 1)?.shortAddress && <HStack align="start">
                    <Text lineHeight={"1"} minW="43px" fontSize={"11px"} color={"gray"}>Dropoff</Text>
                    <Text lineHeight={"1"} fontSize={"12px"}>{data.stops?.at(data.stops?.length - 1)?.shortAddress}</Text>
                </HStack>}
            </VStack>
        </Flex>
    )
}

export default BookingCard