import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import RootMessageDrawer from "./RootMessageDrawer";
import { CHAT_BOX_SPACING, CHAT_BOX_WIDTH } from "../../../config/constants/ui";
import ConversationDrawer from "./ConversationDrawer";
import useChatWindows from "../../../config/hooks/useChatWindows";
import { useDispatch, useSelector } from "react-redux";
import { updateOpenedChats } from "../../../config/redux/slices/chatSlice";

const BottomChatDrawer = () => {
  const dispatch = useDispatch();
  const firstChatDisclosure = useDisclosure();
  const secondChatDisclosure = useDisclosure();
  const thirdChatDisclosure = useDisclosure();
  const chatWindows = useChatWindows();

  // const [openedChats, setOpenedChats] = useState([]);
  const openedChats = useSelector((state) => state.chat.openedChats);
  const setOpenedChats = (data) => dispatch(updateOpenedChats(data));

  const getDisclosure = (index) => {
    switch (index) {
      case 0:
        return firstChatDisclosure;
      case 1:
        return secondChatDisclosure;
      case 2:
        return thirdChatDisclosure;
      default:
        return firstChatDisclosure;
    }
  };

  const handleOpenChat = (item, cb) => {
    cb && cb(item);
    const isAlreadyOpened = openedChats.some(
      (chat) => chat.chatId === item.chatId
    );

    if (isAlreadyOpened) return;

    // Check if the openedChats length is greater than screen can handle
    if (openedChats.length >= chatWindows) {
      // Replace the first element with the new index
      setOpenedChats([...openedChats.slice(1), item]);
      getDisclosure(2).onOpen();
    } else {
      // Add the index to the openedChats array
      setOpenedChats([...openedChats, item]);

      getDisclosure(openedChats.length).onOpen();
    }
  };

  const onLeaveChat = (id, index) => {
    //remember first, second and third disclosures's isOpen state and then close the current one and apply the correct isOpen state to the other two
    const secondIsOpen = secondChatDisclosure.isOpen;
    const thirdIsOpen = thirdChatDisclosure.isOpen;

    if (index === 0) {
      if (secondIsOpen) firstChatDisclosure.onOpen();
      else firstChatDisclosure.onClose();

      if (thirdIsOpen) secondChatDisclosure.onOpen();
      else secondChatDisclosure.onClose();

      thirdChatDisclosure.onClose();
    }

    if (index === 1) {
      if (thirdIsOpen) secondChatDisclosure.onOpen();
      else secondChatDisclosure.onClose();

      thirdChatDisclosure.onClose();
    }

    if (index === 2) {
      thirdChatDisclosure.onClose();
    }

    setOpenedChats(openedChats.filter((item) => item.chatId !== id));
  };

  return (
    <React.Fragment>
      {openedChats?.map((item, index) => {
        const rightSpacing =
          CHAT_BOX_SPACING +
          index * CHAT_BOX_WIDTH +
          CHAT_BOX_WIDTH +
          (index + 1) * CHAT_BOX_SPACING;
        return (
          <ConversationDrawer
            chat={item}
            getDisclosure={getDisclosure}
            index={index}
            key={item.chatId}
            rightSpacing={rightSpacing}
            onLeaveChat={onLeaveChat}
          />
        );
      })}
      <RootMessageDrawer handleOpenChat={handleOpenChat} />
    </React.Fragment>
  );
};

export default BottomChatDrawer;
