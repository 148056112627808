import { Button, VStack, useDisclosure } from "@chakra-ui/react";
import React from "react";
import FormModal from "../../../../components/Forms/FormModal";
import { useForm } from "react-hook-form";
import FormInput from "../../../../components/Forms/FormInput";
import {
  useCreateCity,
  useUpdateCity,
} from "../../../../config/query/cityQuery";
import FormSearchSelect from "../../../../components/Forms/FormSearchSelect";
import { useStatesLookup } from "../../../../config/query/stateQuery";
import { makeSelectList } from "../../../../config/helpers/selectListHelper";
import StateForm from "../State/StateForm";

const CityForm = ({ disclosure, data }) => {
  const stateFormDisclosure = useDisclosure()

  const states = useStatesLookup()
  const createCityQuery = useCreateCity();
  const updateCityQuery = useUpdateCity();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm({
    values: {
      name: data?.name,
      stateId: data?.state?.id
    },
  });

  const onSubmit = (values) => {
    const payload = {
      id: data?.id,
      ...values,
    };

    let formMutate = data?.id
      ? updateCityQuery.mutateAsync
      : createCityQuery.mutateAsync;
    formMutate(payload)
      .then(() => {
        disclosure.onClose();
        resetForm();
      })
      .catch((error) => console.warn(error));
  };

  return (
    <FormModal
      title={data?.id ? "Edit City" : "Add City"}
      disclosure={disclosure}
      isSubmitting={createCityQuery.isLoading || updateCityQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"40rem"}
      saveButtonText={"Save"}
      reset={resetForm}
      onCloseFn={() => resetForm()}
    >
      <VStack spacing={5} align="stretch">
        <FormInput
          label={"Name"}
          control={control}
          errors={errors}
          id="name"
          required={true}
          placeholder="Enter name"
          inputProps={{ size: "sm" }}
        />

        <FormSearchSelect
          id="stateId"
          label="State"
          placeholder={'Select State'}
          required={false}
          errors={errors}
          control={control}
          options={makeSelectList(states.data?.data)}
          inputProps={{ size: "sm" }}
          labelExtention={<Button size="xs" onClick={stateFormDisclosure.onOpen}>Add New</Button>}
        />
      </VStack>
      <StateForm data={{}} disclosure={stateFormDisclosure} />
    </FormModal>
  );
};

export default CityForm;
