import React, { useState, useEffect } from "react";
import {
  Avatar,
  AvatarBadge,
  Box,
  Icon,
  IconButton,
  SimpleGrid,
  SkeletonCircle,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import FormModal from "../../../components/Forms/FormModal";
import FormInput from "../../../components/Forms/FormInput";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";
import FormPhoneInput from "../../../components/Forms/FormPhoneInput";
import {
  EMAIL_REGEX,
} from "../../../config/constants/regexConstants";
import FormSearchSelect from "../../../components/Forms/FormSearchSelect";
import FormFileUpload from "../../../components/Forms/FormFileUpload";
import {
  useCorporate,
  useCreateCorporate,
  useUpdateCorporate,
  useUpdateCorporateImage,
} from "../../../config/query/corporateQuery";
import {
  useCities,
  useLanguages,
} from "../../../config/query/preferencesQuery";
import { makeSelectList } from "../../../config/helpers/selectListHelper";
import FormFieldsSkeleton from "../../../components/Skeletons/FormFieldsSkeleton";
import {
  prepareForm,
  preparePayload,
} from "../../../config/helpers/formHelpers/corporateHelper";

const CorporateForm = ({ disclosure, data, onSuccess }) => {
  const queryClient = useQueryClient();
  const { colorMode } = useColorMode();

  const corprateQuery = useCorporate(data?.id);
  const createCorporateQuery = useCreateCorporate();
  const updateCorporateImage = useUpdateCorporateImage();
  const updateCorporateQuery = useUpdateCorporate();
  const cities = useCities();

  const languages = useLanguages();

  const [fileData, setFileData] = useState({
    binary: null,
    url: null,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset: resetForm,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm({
    values: prepareForm({
      formValues: data,
      serverValues: corprateQuery.data,
    }),
  });

  const { name, countryCode, localNumber } = watch();

  const onSubmit = (values) => {
    let formMutate = data?.id
      ? updateCorporateQuery.mutateAsync
      : createCorporateQuery.mutateAsync;
    formMutate(preparePayload(values, data?.id))
      .then((res) => {
        disclosure.onClose();
        if (fileData?.binary) {
          updateCorporateImage
            .mutateAsync({
              CorporateId: res?.id,
              CorporateImage: fileData?.binary,
            })
            .then(() => {
              queryClient.invalidateQueries(['corporate', res?.id])
              onSuccess && onSuccess()
            });
        } else {
          onSuccess && onSuccess();
        }
        queryClient.invalidateQueries(["corporates"]);
        queryClient.invalidateQueries(["corporate"]);
      })
      .catch((error) => console.warn(error));
  };

  useEffect(() => {
    if (data?.id) {
      setFileData({
        ...fileData,
        url: corprateQuery.data?.profilePictureUrl,
      });
    } else {
      resetForm();
      setFileData({
        binary: null,
        url: null,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id, corprateQuery.data?.profilePictureUrl]);

  const onChangeImage = (file) => {
    setFileData({
      binary: file,
      url: URL.createObjectURL(file),
    });
  };

  return (
    <FormModal
      title={data?.id ? "Edit Corporate Client" : "Add Corporate Client"}
      disclosure={disclosure}
      isSubmitting={
        createCorporateQuery.isLoading || updateCorporateQuery.isLoading
      }
      onSubmit={handleSubmit(onSubmit)}
      maxW={"75rem"}
      reset={resetForm}
      onCloseFn={() => {
        resetForm();
        setFileData({
          binary: null,
          url: null,
        });
      }}
    >
      <VStack spacing={5} align="stretch">
        <Box m="auto">
          {corprateQuery.isFetching ? (
            <SkeletonCircle size="130" />
          ) : (
            <Avatar size="2xl" name={name} src={fileData?.url}>
              <AvatarBadge boxSize="8">
                <FormFileUpload
                  id={"profilePicture"}
                  onChange={onChangeImage}
                  component={
                    <IconButton
                      variant={"ghost"}
                      icon={
                        <Icon
                          as={APP_ICONS.EDIT}
                          boxSize="7"
                          color={getColor(colorKeys.primary, colorMode)}
                        />
                      }
                    />
                  }
                  componentProps={{ variant: "ghost" }}
                  fileType={"IMAGE"}
                />
              </AvatarBadge>
            </Avatar>
          )}
        </Box>

        <Box>
          <Text
            fontSize="13px"
            fontWeight={"bold"}
            my={3}
            w="full"
            textTransform={"uppercase"}
          >
            Contact Info
          </Text>

          <FormFieldsSkeleton count={7} loading={corprateQuery.isFetching} />

          {!corprateQuery.isFetching && (
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
              <FormInput
                label={"Company Name"}
                control={control}
                errors={errors}
                id="companyName"
                required={true}
                placeholder="Enter company name"
                inputProps={{ size: "sm" }}
              />

              <FormInput
                label={"Admin Name"}
                control={control}
                errors={errors}
                id="name"
                required={true}
                placeholder="Enter admin name"
                inputProps={{ size: "sm" }}
              />

              <FormPhoneInput
                id={"localNumber"}
                label="Phone Number"
                type="tel"
                placeholder="Enter your phone number"
                required={true}
                errors={errors}
                control={control}
                setCountryCodeValue={(value) => {
                  setValue("countryCode", value);
                }}
                countryCodeValue={countryCode}
                phoneNumberValue={localNumber}
                setPhoneNumberValue={(value) => {
                  setValue("localNumber", value);
                }}
                setError={setError}
                clearErrors={clearErrors}
                inputProps={{ size: "sm" }}
              />

              <FormInput
                id={"email"}
                label="Email"
                placeholder="someone@example.com"
                required={true}
                errors={errors}
                control={control}
                rules={{
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Invalid email address",
                  },
                }}
                inputProps={{ size: "sm" }}
              />

              <FormSearchSelect
                id="cityId"
                label="City"
                placeholder={"Select City"}
                required={true}
                errors={errors}
                control={control}
                options={makeSelectList(cities.data?.data)}
                inputProps={{ size: "sm" }}
              />

              <FormSearchSelect
                id="languageId"
                label="Language"
                placeholder={"Select Language"}
                required={true}
                errors={errors}
                control={control}
                options={makeSelectList(languages.data?.data)}
                inputProps={{ size: "sm" }}
              />

              <FormInput
                label={"Corporate Code"}
                control={control}
                errors={errors}
                id="corporateCode"
                required={true}
                placeholder="Enter corporate code"
                inputProps={{ size: "sm" }}
              />
            </SimpleGrid>
          )}
        </Box>
      </VStack>
    </FormModal>
  );
};

export default CorporateForm;
