import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";

const ParticipantItem = ({ data, onRemoveParticipant, type }) => {
  const { colorMode } = useColorMode();
  const [isLoading, setIsLoading] = useState(false);

  const handleRemove = (id, type) => {
    onRemoveParticipant(id, type);
    setIsLoading(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [isLoading]);

  return (
    <Box
      _odd={{
        bg: getColor(colorKeys.tableStripedRowBackground, colorMode),
      }}
      _hover={{
        bg: getColor(colorKeys.tableRowHoverBackground, colorMode),
      }}
      role="group"
    >
      <Flex
        minH="42px"
        py="2"
        px="2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Text fontSize="sm" fontWeight={"bold"}>
            {data.label}
          </Text>
          {data.phone && (
            <Text fontSize="sm" color={getColor(colorKeys.dimText, colorMode)}>
              {data.phone}
            </Text>
          )}
        </Box>
        <IconButton
          key={`${data.id}-${type}`}
          isLoading={isLoading}
          _groupHover={{ display: "flex" }}
          display={"none"}
          onClick={() => handleRemove(data.id || data.value, type)}
          colorScheme="red"
          size="xs"
          icon={<Icon as={APP_ICONS.CLOSE} />}
        />
      </Flex>
    </Box>
  );
};

export default ParticipantItem;
