import React, { useRef } from "react";
import { Divider, VStack } from "@chakra-ui/react";
import ChatItem from "../ChatLayout/ChatItem";
import CircularLoading from "../../Skeletons/CircularLoading";
import { INBOX_DRAWER_HEIGHT } from "../../../config/constants/ui";
import NoResults from "../../SVGComponents/NoResults";
import useChatInbox from "../../../config/containers/chats/useChatInbox";
import { CHAT_TYPES } from "../../../config/constants/enums";
import ChatListSkeleton from "../../Skeletons/ChatListSkeleton";

const InboxList = ({ handleOpenChat, query }) => {
  const messageListRef = useRef();

  const { chats, handleScroll, isFetchingNextPage, cb, isLoading } =
    useChatInbox({
      messageListRef,
      chatType: CHAT_TYPES.Support,
      query,
    });

  if (isLoading) {
    return <ChatListSkeleton isLoading={isLoading} />;
  }

  return (
    <VStack
      ref={messageListRef}
      onScroll={handleScroll}
      h={INBOX_DRAWER_HEIGHT}
      align="stretch"
      spacing="0"
      overflowY="auto"
      divider={<Divider />}
    >
      {chats?.map((item, index) => (
        <ChatItem
          chatItem={item}
          key={index}
          onClick={() => handleOpenChat(item, cb)}
        />
      ))}
      {chats?.length === 0 && !isFetchingNextPage && <NoResults />}
      <CircularLoading loading={isFetchingNextPage} />
    </VStack>
  );
};

export default InboxList;
