import React, { useState } from "react";
import CustomTable from "../../../components/BasicUI/CustomTable";
import Chronology from "../../../components/BasicUI/DataBoxes/Chronology";
import TableHeaderOptions from "../../../components/BasicUI/CustomTable/TableHeaderOptions";
import { Box, Text, VStack, useColorMode } from "@chakra-ui/react";
import { getFilters } from "../../../config/helpers/filterHelper";
import { makeInitialQueryObject } from "../../../config/helpers/queryHelper";
import { formatPhoneNumberForDisplay } from "../../../config/helpers/stringHelper";
import useDebounce from "../../../config/hooks/useDebounce";
import { APP_MODULES } from "../../../config/constants";
import { useRatings } from "../../../config/query/ratingsQuery";
import StarRating from "../../../components/BasicUI/DataBoxes/StarRating";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { useDriverOptions } from "../../../config/query/driverQuery";
import { useCustomerOptions } from "../../../config/query/customerQuery";

const Reviews = () => {
  const { colorMode } = useColorMode();
  const [query, setQuery] = useState(makeInitialQueryObject());
  const debouncedQuery = useDebounce(query);

  const ratingsQuery = useRatings(debouncedQuery);
  const driverOptions = useDriverOptions(query?.DriverIdQuery);
  const customerOptions = useCustomerOptions(query?.CustomerIdQuery);

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  return (
    <Box>
      <TableHeaderOptions title={"Reviews"} />
      <CustomTable
        permissionKey={APP_MODULES.Reviews}
        tableFor="reviews"
        searchPlaceholder="Search reviews"
        filters={getFilters({
          CustomerId: customerOptions?.data?.data?.map((item) => ({
            key: item.id,
            value: item.name,
          })),
          DriverId: driverOptions?.data?.data?.map((item) => ({
            key: item.id,
            value: item.name,
          })),
        })}
        head={[
          { title: "Booking #", extractor: "bookingId", isSortable: true },
          {
            title: "Customer",
            extractor: "customerName",
            isSortable: true,
            component: (item) => (
              <VStack align="stretch" spacing={0.5}>
                <Text>{item?.customerName}</Text>
                <Text>{formatPhoneNumberForDisplay(item?.customerPhone)}</Text>
              </VStack>
            ),
          },
          {
            title: "Driver",
            extractor: "driverName",
            isSortable: true,
            component: (item) => (
              <VStack align="stretch" spacing={0.5}>
                <Text>{item?.driverName}</Text>
                <Text>{formatPhoneNumberForDisplay(item?.driverPhone)}</Text>
              </VStack>
            ),
          },
          {
            title: "Customer Rating",
            extractor: "customerRating",
            isSortable: true,
            align: "center",
            component: (item) => (
              <Box>
                <StarRating
                  activeColor={getColor(colorKeys.spanishYellow, colorMode)}
                  size={3.5}
                  value={item?.customerRating}
                  count={5}
                />
                {item.customerRatingDescription && (
                  <Text fontSize={"14px"}>
                    {item.customerRatingDescription}
                  </Text>
                )}
              </Box>
            ),
          },
          {
            title: "Driver Rating",
            extractor: "driverRating",
            isSortable: true,
            align: "center",
            component: (item) => (
              <Box>
                <StarRating
                  activeColor={getColor(colorKeys.spanishYellow, colorMode)}
                  size={3.5}
                  value={item?.driverRating}
                  count={5}
                />
                {item.driverRatingDescription && (
                  <Text fontSize={"14px"}>{item.driverRatingDescription}</Text>
                )}
              </Box>
            ),
          },
          {
            title: "Last Modified",
            align: "center",
            extractor: "lastModifiedOn",
            isSortable: true,
            component: (item) => <Chronology data={item} />,
          },
        ]}
        data={ratingsQuery?.data?.data}
        loading={ratingsQuery?.isLoading}
        totalResults={ratingsQuery?.data?.meta?.totalCount}
        totalPages={ratingsQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => ratingsQuery.refetch()}
        isRefreshing={ratingsQuery?.isFetching}
      />
    </Box>
  );
};

export default Reviews;
