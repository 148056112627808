import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Box, Text, Tooltip } from '@chakra-ui/react'
import CustomTable from '../../../components/BasicUI/CustomTable'
import TableInfoPopover from '../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import { formatDateTime } from '../../../config/helpers/dateHelper'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'
import { makeInitialQueryObject } from '../../../config/helpers/queryHelper'
import { useDeleteCustomer } from '../../../config/query/customerQuery'
import { formatAmount } from '../../../config/helpers/stringHelper'
import { useActiveCorporateCustomer, useBlockCorporateCustomer, useCorporateCustomers, useDeleteCorporateCustomer, useUpdateCorporatePay } from '../../../config/query/corporateQuery'
import useDebounce from '../../../config/hooks/useDebounce'
import { APP_MODULES } from '../../../config/constants'
import MenuSelect from '../../../components/Forms/MenuSelect'
import { getDisabledCorporateUserStatus, makeSelectList } from '../../../config/helpers/selectListHelper'
import { CORPORATE_CUSTOMER_STATUS, CORPORATE_CUSTOMER_STATUSES } from '../../../config/constants/enums'
import StatusSwitch from '../../../components/BasicUI/DataBoxes/StatusSwitch'

const CorporateUsers = () => {
    const { corporateId } = useParams()
    const navigate = useNavigate()
    const [query, setQuery] = useState(makeInitialQueryObject())
    const debouncedQuery = useDebounce(query)

    const customersQuery = useCorporateCustomers({ id: corporateId, params: debouncedQuery })
    const deleteCustomerQuery = useDeleteCorporateCustomer()
    const activateCustomer = useActiveCorporateCustomer()
    const blockCustomer = useBlockCorporateCustomer()
    const corporatePay = useUpdateCorporatePay()

    const onView = (item) => navigate(`/admin/customers/${item.customerId}`)

    const onQueryChange = (updatedQuery) => {
        setQuery((prev) => ({ ...prev, ...updatedQuery }))
    }


    const handleStatusChange = (status, customerId) => {
        if (status?.value === CORPORATE_CUSTOMER_STATUSES.Active) activateCustomer.mutateAsync({ customerId, corporateId });
        else blockCustomer.mutateAsync({ customerId, corporateId });
    };

    const handleDelete = (id, item) => {
        deleteCustomerQuery.mutateAsync({ customerId: item?.customerId, corporateId })
            .then(customersQuery.refetch)
            .catch((error) => console.warn(error))
    }

    const onUpdateCorporatePay = (value, customerId) => {
        corporatePay.mutate({ customerId, corporateId, isCorporatePay: value })
    }

    return (
        <Box>
            <CustomTable
                permissionKey={APP_MODULES.Customer}
                hideFilterBar={true}
                tableFor="customer"
                tableWrapperProps={{
                    padding: 0,
                    h: "calc(100vh - 460px)"
                }}
                containerProps={{
                    h: "calc(100vh - 410px)"
                }}
                searchPlaceholder="Search customers"
                head={[
                    {
                        title: "Name",
                        extractor: "name",
                        align: "left",
                        isSortable: true,
                        component: (item) => (
                            <TableInfoPopover
                                data={item}
                                fallBackText={"N/A"}
                                triggerText={item.name}
                                triggerOnClick={() => onView(item)}
                            >
                                <LabelValuePair label="Description" value={item.description} />
                                <LabelValuePair
                                    label="Status"
                                    value={true}
                                    valueComponent={
                                        <StatusBadge value={item.status} />
                                    }
                                />
                                <LabelValuePair label="Created" value={formatDateTime(item.createdOn)} />
                                <LabelValuePair label="Last Updated" value={formatDateTime(item.lastModifiedOn)} />
                            </TableInfoPopover>
                        )
                    },
                    { title: "Phone Number", extractor: "phone", isSortable: true },
                    { title: "Email", extractor: "email", isSortable: true },
                    { title: "City", extractor: "city", isSortable: true },
                    { title: "Credit Balance", extractor: "creditBalance", isSortable: true, align: "center", component: (item) => <Text fontSize="14px">{formatAmount(item.creditBalance)}</Text> },
                    {
                        title: "Corporate Pay",
                        extractor: "isCorporatePay",
                        isSortable: true,
                        align: "center",
                        component: (item) => {
                            const isDisabled = item.status?.id === CORPORATE_CUSTOMER_STATUSES.Pending || item.status?.id === CORPORATE_CUSTOMER_STATUSES.Blocked
                            return (
                                <Tooltip label={isDisabled ? "Corporate pay is only available for active customers" : "Updates will apply to new rides"} hasArrow>
                                    <div>
                                        <StatusSwitch
                                            key={item.id}
                                            value={item.isCorporatePay}
                                            onChange={(e) => onUpdateCorporatePay(e.target.checked, item.customerId)}
                                            isDisabled={isDisabled}
                                        />
                                    </div>
                                </Tooltip>
                            )
                        },
                    },
                    {
                        title: "Status",
                        extractor: "status",
                        align: "center",
                        isSortable: true,
                        component: (item) =>
                            <MenuSelect
                                value={CORPORATE_CUSTOMER_STATUS[item.status?.id]}
                                onChange={(status) =>
                                    handleStatusChange(status, item.customerId)
                                }
                                options={makeSelectList(CORPORATE_CUSTOMER_STATUS)}
                                disabledOptions={getDisabledCorporateUserStatus(item.status?.id)}
                                hiddenOptions={[CORPORATE_CUSTOMER_STATUSES.Pending]}
                            />
                    },
                    { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
                    {
                        title: "Actions",
                        extractor: "actions",
                        align: "center"
                    }
                ]}
                data={customersQuery?.data?.data}
                loading={customersQuery?.isLoading}
                totalResults={customersQuery?.data?.meta?.totalCount}
                totalPages={customersQuery?.data?.meta?.totalPages}
                pageNo={query?.PageNumber}
                pageSize={query?.PageSize}
                onQueryChange={onQueryChange}
                query={query}
                onRefresh={() => customersQuery.refetch()}
                isRefreshing={customersQuery?.isFetching}

                onDelete={handleDelete}
                onView={onView}
            />

        </Box>
    )
}

export default CorporateUsers