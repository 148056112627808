import { SimpleGrid, VStack } from "@chakra-ui/react";
import React from "react";
import FormModal from "../../../../components/Forms/FormModal";
import { useForm } from "react-hook-form";
import FormInput from "../../../../components/Forms/FormInput";
import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateMake,
  useUpdateMake,
} from "../../../../config/query/makeQuery";

const MakeForm = ({ disclosure, data }) => {
  const queryClient = useQueryClient();
  const createMakeQuery = useCreateMake();
  const updateMakeQuery = useUpdateMake();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm({
    values: {
      name: data?.name,
    },
  });

  const onSubmit = (values) => {
    const payload = {
      id: data?.id,
      ...values,
    };

    let formMutate = data?.id
      ? updateMakeQuery.mutateAsync
      : createMakeQuery.mutateAsync;
    formMutate(payload)
      .then(() => {
        disclosure.onClose();
        resetForm();
        queryClient.invalidateQueries(["makes"]);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return (
    <FormModal
      title={data?.id ? "Edit Make" : "Add Make"}
      disclosure={disclosure}
      isSubmitting={createMakeQuery.isLoading || updateMakeQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"50rem"}
      saveButtonText={"Save"}
      reset={resetForm}
      onCloseFn={() => resetForm()}
    >
      <VStack spacing={5} align="stretch">
        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={5}>
          <FormInput
            label={"Name"}
            control={control}
            errors={errors}
            id="name"
            required={true}
            placeholder="Enter name"
            inputProps={{ size: "sm" }}
          />
        </SimpleGrid>
      </VStack>
    </FormModal>
  );
};

export default MakeForm;
