import {
  Box,
  Divider,
  Flex,
  Skeleton,
  SkeletonCircle,
  VStack,
} from "@chakra-ui/react";
import React from "react";

const ChatListSkeleton = ({ isLoading, count = 12 }) => {
  if (!isLoading) return null;
  return (
    <VStack
      divider={<Divider />}
      spacing={0}
      h="calc(100vh - 175px)"
      overflow="auto"
    >
      {new Array(count).fill(null)?.map((_, index) => (
        <Flex
          key={index}
          p="8px"
          rounded="sm"
          w="full"
          cursor={"pointer"}
          role="group"
          bg={"transparent"}
        >
          <SkeletonCircle w="45px" h="39px" />
          <Box w="full" ml={2}>
            <Flex flexDir={"column"} justify={"center"} w="full">
              <Skeleton mt={2} h="13px" w="160px" />
              <Skeleton mt={1} h="10px" w="100px" />
            </Flex>
          </Box>
        </Flex>
      ))}
    </VStack>
  );
};

export default ChatListSkeleton;
