import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { formatDateTime } from '../../../../config/helpers/dateHelper'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import StatusBadge from '../../../../components/BasicUI/Badges/StatusBadge'
import { usePricings } from '../../../../config/query/pricingQuery'
import DefaultRateForm from './DefaultRateForm'
import { makeInitialQueryObject } from '../../../../config/helpers/queryHelper'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import useDebounce from '../../../../config/hooks/useDebounce'
import { APP_MODULES } from '../../../../config/constants'

const DefaultRates = ({ selectedRow, setSelectedRow, disclosure, setIsRefreshing, isRefreshing }) => {

    const [query, setQuery] = useState(makeInitialQueryObject())
    const debouncedQuery = useDebounce(query)

    const onEditModal = (item) => {
        setSelectedRow(item)
        disclosure?.onOpen()
    }

    const pricingQuery = usePricings(debouncedQuery)

    const onQueryChange = (updatedQuery) => {
        setQuery((prev) => ({ ...prev, ...updatedQuery }))
    }

    useEffect(() => {
        if (isRefreshing) pricingQuery.refetch().then(() => setIsRefreshing(false))
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshing])

    return (
        <Box>
            <CustomTable
                permissionKey={APP_MODULES.Pricing}
                tableFor={"default rate"}
                fixedHeight={false}
                tableWrapperProps={{ padding: 0, h: "auto" }}
                hideFilterBar={true}
                containerProps={{ mt: 4 }}
                head={[
                    { title: "Name", extractor: "name" },
                    { title: "Created Date & Time", extractor: "date", component: (item) => formatDateTime(item.date) },
                    { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status} /> },
                    { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
                    { title: "Action", extractor: "action", align: "center", component: (item) => <PrimaryButton size="xs" rounded="lg" onClick={() => onEditModal(item)}>View Details</PrimaryButton> },
                ]}
                data={pricingQuery.data?.data}
                loading={pricingQuery?.isLoading}
                totalResults={pricingQuery?.data?.totalCount}
                totalPages={pricingQuery?.data?.totalPages}
                pageNo={query?.PageNumber}
                pageSize={query?.PageSize}
                onQueryChange={onQueryChange}
                query={query}
                onRefresh={() => pricingQuery.refetch()}
                isRefreshing={pricingQuery?.isFetching}
            />
            <DefaultRateForm disclosure={disclosure} data={selectedRow} />
        </Box>
    )
}

export default DefaultRates