import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import FormModal from "../../Forms/FormModal";
import ParticipantBox from "../ParticipantBox";
import CustomTabs from "../../BasicUI/CustomTabs";
import { CHAT_TYPES, PARTICIPANT_TYPES } from "../../../config/constants/enums";
import {
  useAddParticipants,
  useChatParticipants,
  useRemoveParticipants,
} from "../../../config/query/supportQuery";
import { getParticipants } from "../../../config/helpers/chatHelpers";
import FormComboBox from "../../Forms/FormComboBox";
import useDebounce from "../../../config/hooks/useDebounce";
import { useDriverOptions } from "../../../config/query/driverQuery";
import { useSubAdminOptions } from "../../../config/query/subAdminQuery";
import { useSelector } from "react-redux";

const MembersList = ({ disclosure, chatId, chatType }) => {
  const [participantQuery, setParticipantQuery] = useState("");
  const debouncedQuery = useDebounce(participantQuery);

  const user = useSelector((state) => state.user?.user);
  const addParticipantsQuery = useAddParticipants(chatId);
  const removeParticipantsQuery = useRemoveParticipants(chatId);
  const driverQuery = useDriverOptions(debouncedQuery);
  const adminQuery = useSubAdminOptions(debouncedQuery);

  const chatParticipants = useChatParticipants({
    chatId,
    enabled: chatType === CHAT_TYPES.Custom,
  });

  const participantsArray = useMemo(() => {
    return getParticipants(chatParticipants.data?.data);
  }, [chatParticipants.data?.data]);

  const {
    control,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    values: {
      driverParticipants: participantsArray?.drivers,
      adminParticipants: participantsArray?.admins,
    },
  });

  const onRemoveParticipant = (id, type) => {
    removeParticipantsQuery
      .mutateAsync([{ id, type }])
      .catch((error) => console.warn(error));
  };

  const onAddNew = (currentItem, type) => {
    addParticipantsQuery.mutateAsync([
      { id: currentItem.value, type: type || PARTICIPANT_TYPES.Driver },
    ]);
  };

  return (
    <FormModal
      title={"Members"}
      disclosure={disclosure}
      maxW={"30rem"}
      reset={resetForm}
      onCloseFn={resetForm}
      hideFooter={true}
    >
      <CustomTabs
        containerProps={{ isFitted: true, p: 0, h: "35rem" }}
        tabItemProps={{ fontSize: "sm" }}
        tabPanelProps={{ p: 0, pb: 0, pt: 2 }}
        tabs={[
          {
            head: "Drivers",
            key: "drivers",
            body: (
              <FormComboBox
                allowClear={false}
                id="driverParticipants"
                placeholder="Search drivers by name"
                required={true}
                valueBoxComponent={(value) => (
                  <ParticipantBox
                    value={value}
                    onRemoveParticipant={onRemoveParticipant}
                    type={PARTICIPANT_TYPES.Driver}
                  />
                )}
                control={control}
                hideLabel={true}
                errors={errors}
                inputProps={{ size: "sm" }}
                searchFn={(query) => setParticipantQuery(query)}
                isLoading={participantQuery && driverQuery.isLoading}
                options={
                  driverQuery.data?.data?.map((item) => ({
                    value: item.id,
                    label: item.name,
                    phone: item.phone,
                  })) || []
                }
                onChangeEffect={(value) => onAddNew(value, PARTICIPANT_TYPES.Driver)}
              />
            ),
          },
          {
            head: "Admins",
            key: "admins",
            body: (
              <FormComboBox
                allowClear={false}
                id="adminParticipants"
                placeholder="Search admin by name"
                required={true}
                valueBoxComponent={(value) => (
                  <ParticipantBox
                    value={value}
                    onRemoveParticipant={onRemoveParticipant}
                    type={PARTICIPANT_TYPES.Admin}
                    hiddenHash={user?.id}
                  />
                )}
                control={control}
                hideLabel={true}
                errors={errors}
                inputProps={{ size: "sm" }}
                searchFn={(query) => setParticipantQuery(query)}
                isLoading={participantQuery && adminQuery.isLoading}
                options={
                  adminQuery.data?.data?.map((item) => ({
                    value: item.id,
                    label: item.name,
                    phone: item.phone || "N/A",
                  })) || []
                }
                onChangeEffect={(value) => onAddNew(value, PARTICIPANT_TYPES.Admin)}
              />
            ),
          },
        ]}
      />
    </FormModal>
  );
};

export default MembersList;
