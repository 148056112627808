
import React from 'react'
import {
    Table,
    TableCaption,
    TableContainer,
    Box,
} from "@chakra-ui/react"
import { getColor, colorKeys } from '../../../config/constants/appColors'
import NoResults from '../../SVGComponents/NoResults';
import FilterBar from './FilterBar';
import TableHead from './TableHead';
import TableBody from './TableBody';
import TablePages from './TablePages';
import TablePermissionDenied from './TablePermissionDenied';
import useTable from './useTable';

const CustomTable = ({
    //data manipulation
    head,
    data,
    loading,
    searchKey = "AdvancedSearch.Keyword",
    pageSize: limit,
    pageNo,
    query,
    onQueryChange,
    totalPages,
    totalResults,
    onRefresh,
    filters = [],
    caption,
    isRefreshing = false,
    searchPlaceholder,
    tableFor,
    searchHint,
    permissionKey,
    filtersLoading,
    filterBarRightAddons,
    customFilters,

    //styles manipulations
    fixedHeight = true,
    rowHeight = "36px",
    size = "md",
    containerProps,
    tableProps,
    tableHeadProps,
    tbodyProps,
    hideFilterBar = false,
    tableWrapperProps,
    hideSearch = false,
    tableHeadTextProps,
    columnsPadding = "3",
    searchBarProps,
    colorRows,
    theadRowProps,
    filterBarProps,
    editActionProps,
    viewActionProps,

    //actions
    onDelete,
    onEdit,
    onView,
    viewUrl,
}) => {

    const {
        colorMode,
        currentPage,
        handlePageChange,
        handleSort,
        modulePermissions,
        pages,
        pagesCount,
        setCurrentPage,
    } = useTable({
        limit,
        pageNo,
        totalPages,
        totalResults,
        onQueryChange,
        permissionKey,
    })

    if (permissionKey) {
        if (!(modulePermissions?.view || modulePermissions === true)) {
            return <TablePermissionDenied containerProps={containerProps} />
        }
    }

    return (
        <TableContainer h={fixedHeight ? "calc(100vh - 140px)" : "auto"} {...containerProps}  >
            {!hideFilterBar && (
                <FilterBar
                    filterBarProps={filterBarProps}
                    filters={filters}
                    onRefresh={onRefresh}
                    onQueryChange={onQueryChange}
                    query={query}
                    searchKey={searchKey}
                    searchPlaceholder={searchPlaceholder}
                    hideSearch={hideSearch}
                    searchBarProps={searchBarProps}
                    isRefreshing={isRefreshing}
                    limit={limit}
                    setCurrentPage={setCurrentPage}
                    filtersLoading={filtersLoading}
                    searchHint={searchHint}
                    filterBarRightAddons={filterBarRightAddons}
                    customFilters={customFilters}
                />
            )}


            <Box h="calc(100vh - 285px)" overflowY={"auto"} bg={getColor(colorKeys.tableBackground, colorMode)} p="0 20px 15px" {...tableWrapperProps} style={{ position: "relative", zIndex: 0 }}>
                <Table variant='simple' size={size} opacity={isRefreshing ? '0.5' : '1'} {...tableProps}>
                    {caption && <TableCaption>{caption}</TableCaption>}
                    <TableHead
                        colorRows={colorRows}
                        head={head}
                        handleSort={handleSort}
                        tableHeadProps={tableHeadProps}
                        theadRowProps={theadRowProps}
                        columnsPadding={columnsPadding}
                        query={query}
                        tableHeadTextProps={tableHeadTextProps}
                    />
                    <TableBody
                        colorRows={colorRows}
                        data={data}
                        loading={loading}
                        rowHeight={rowHeight}
                        columnsPadding={columnsPadding}
                        tableFor={tableFor}
                        tbodyProps={tbodyProps}
                        editActionProps={editActionProps}
                        viewActionProps={viewActionProps}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onView={onView}
                        viewUrl={viewUrl}
                        head={head}
                        tableHeadTextProps={tableHeadTextProps}
                    />
                </Table>

                {(!loading && !isRefreshing && data?.length === 0) &&
                    <Box w="500px" mx="auto" my={10}>
                        <NoResults />
                    </Box>
                }

            </Box>

            <TablePages
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                pages={pages}
                pagesCount={pagesCount}
                totalResults={totalResults}
                limit={limit}
                onQueryChange={onQueryChange}
                setCurrentPage={setCurrentPage}
                pageNo={pageNo}
                totalPages={totalPages}
                query={query}

            />
        </TableContainer>
    )
}

export default React.memo(CustomTable)


