import { usePagination } from "@ajna/pagination"
import { useColorMode } from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { getPermission } from "../../../config/helpers/permissionHelper"

const useTable = ({ limit, pageNo, totalPages, totalResults, onQueryChange, permissionKey }) => {
    const permissions = useSelector((state) => state.user?.permissions)
    const isGlobal = useSelector((state) => state.user?.user?.isGlobal)
    const { colorMode } = useColorMode()

    limit = Number(limit)
    pageNo = Number(pageNo)

    const {
        pages,
        pagesCount,
        currentPage,
        setCurrentPage,
    } = usePagination({
        pagesCount: totalPages,
        total: totalResults,
        limits: {
            outer: 4,
            inner: 4,
        },
        initialState: {
            pageSize: limit,
            currentPage: pageNo,
        },
    });

    const handlePageChange = (nextPage) => {
        setCurrentPage(nextPage);
        onQueryChange({ PageNumber: nextPage, PageSize: limit })
    };

    const handleSort = (key, order) => {
        onQueryChange({ Sort: key, SortOrder: order, PageNumber: 1, PageSize: limit })
        setCurrentPage(1)
    }

    const modulePermissions = getPermission({ isGlobal, permissionKey, permissions })

    return {
        pages,
        pagesCount,
        currentPage,
        setCurrentPage,
        handlePageChange,
        handleSort,
        modulePermissions,
        colorMode
    }
}

export default useTable