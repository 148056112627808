import { useCallback, useEffect, useRef, useState } from "react";
import { useColorMode } from "@chakra-ui/react";
import { DEFAULT_DRAW_POLYGON_COLOR, DEFAULT_MAP_ZOOM, getGoogleMapOptions, getPolylineOptions, getStylers } from "./mapConfig";
import { identifyParentZoneOfPoint, isPointInsideMainPolygon } from "../../config/helpers/mapHelper";
import { colorKeys, getColor } from "../../config/constants/appColors";

export const useDrawPolygon = ({ polylineCoords, setPolylineCoords, disabled, strictBorders }) => {
    const zoomRef = useRef(DEFAULT_MAP_ZOOM)

    const polylineRef = useRef(null);
    const listenersRef = useRef([]);

    const { colorMode } = useColorMode();
    const [map, setMap] = useState(null);

    const onLoadMap = useCallback((map) => setMap(map), []);

    // Call setPath with new edited path
    const onEdit = useCallback((e) => {
        const point = polylineCoords?.at(0) ? new window.google.maps.LatLng(polylineCoords?.at(0)) : e.latLng
        const parentZone = identifyParentZoneOfPoint(point, strictBorders)
        if (parentZone) {
            const bounds = new window.google.maps.LatLngBounds();
            parentZone?.forEach((coord) => bounds.extend(coord))
            if (polylineRef.current) {
                const nextPath = polylineRef.current
                    .getPath()
                    .getArray()
                    .map((latLng) => latLng.toJSON());
                if (isPointInsideMainPolygon(e.latLng, parentZone)) {
                    setPolylineCoords(nextPath);
                    polylineRef.current.setPath(nextPath)
                }
                else {
                    polylineRef.current.setPath(polylineCoords)
                }
            }
        }
        else {
            polylineRef.current.setPath(polylineCoords)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPolylineCoords]);

    // Bind refs to current Polyline and listeners
    const onLoad = useCallback(
        (polyline) => {
            polylineRef.current = polyline;
            const path = polyline.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach((lis) => lis.remove());
        polylineRef.current = null;
    }, []);

    //handling map click
    const handleMapClick = (event) => {
        const { latLng } = event;
        const lat = latLng.lat();
        const lng = latLng.lng();
        const newCoords = [...polylineCoords, { lat, lng }];
        setPolylineCoords(newCoords)
    };

    useEffect(() => {
        if (polylineCoords && polylineCoords.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            polylineCoords.forEach((coord) => {
                bounds.extend(coord)
            })
            //map && map.fitBounds(bounds)
        }
    }, [polylineCoords, map])

    //initalizing options
    const polylineOptions = getPolylineOptions(DEFAULT_DRAW_POLYGON_COLOR, disabled)
    const drawablePolygonOptions = getPolylineOptions(getColor(colorKeys.lightBlue, colorMode), disabled)//current drawing one. should be draggable
    const otherPolygonOptions = getPolylineOptions(getColor(colorKeys.siderBackground, colorMode), disabled)
    const googleMapOptions = getGoogleMapOptions({ disabled, styles: getStylers(colorMode), maxZoom: 23, zoom: zoomRef.current })

    return {
        onLoadMap,
        onEdit,
        onLoad,
        onUnmount,
        handleMapClick,
        zoomRef,
        map,
        polylineRef,
        polylineOptions,
        googleMapOptions,
        drawablePolygonOptions,
        otherPolygonOptions
    }
}