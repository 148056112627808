import {
  Avatar,
  AvatarBadge,
  Box,
  Divider,
  Icon,
  IconButton,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormModal from "../../../../components/Forms/FormModal";
import FormInput from "../../../../components/Forms/FormInput";
import FormTextarea from "../../../../components/Forms/FormTextarea";
import FormComboBox from "../../../../components/Forms/FormComboBox";
import useDebounce from "../../../../config/hooks/useDebounce";
import { useDriverOptions } from "../../../../config/query/driverQuery";
import CustomTabs from "../../../../components/BasicUI/CustomTabs";
import { PARTICIPANT_TYPES } from "../../../../config/constants/enums";
import ParticipantBox from "../../../../components/Chat/ParticipantBox";
import { prepareChatPayload } from "../../../../config/helpers/chatHelpers";
import {
  useCreateChat,
  useUpadetChatImage,
} from "../../../../config/query/supportQuery";
import { useSubAdminOptions } from "../../../../config/query/subAdminQuery";
import FormFileUpload from "../../../../components/Forms/FormFileUpload";
import APP_ICONS from "../../../../config/constants/icons";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import { useDispatch } from "react-redux";
import { addOne } from "../../../../config/redux/slices/chatSlice";

const ChatForm = ({ disclosure, data, onSuccess }) => {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const [participantQuery, setParticipantQuery] = useState("");
  const debouncedQuery = useDebounce(participantQuery);

  const [fileData, setFileData] = useState({
    binary: null,
    url: null,
  });

  const updateChatImageQuery = useUpadetChatImage();
  const chatQuery = useCreateChat();
  const driverQuery = useDriverOptions(debouncedQuery);
  const adminQuery = useSubAdminOptions(debouncedQuery);

  const {
    handleSubmit,
    control,
    setValue,
    reset: resetForm,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    values: {},
  });

  const onChangeImage = (file) => {
    setFileData({
      binary: file,
      url: URL.createObjectURL(file),
    });
  };

  const onSubmit = (values) => {
    let payload = prepareChatPayload(values);
    chatQuery
      .mutateAsync(payload)
      .then((res) => {
        if (fileData?.binary) {
          updateChatImageQuery
            .mutateAsync({
              ChatId: res?.chatId,
              ChatImage: fileData?.binary,
            })
            .catch((error) => console.warn(error));
        }
        onSuccess && onSuccess();
        disclosure.onClose();
        dispatch(
          addOne({
            chatId: res?.chatId,
            chatTitle: values.title,
            imageUrl: fileData?.url,
          })
        );
      })
      .catch((error) => console.warn(error));
  };

  const onRemoveParticipant = (id, type) => {
    if (type === PARTICIPANT_TYPES.Driver) {
      const driverParticipants = getValues("driverParticipants") || [];
      const newDriverParticipants = driverParticipants.filter(
        (item) => item.value !== id
      );
      setValue("driverParticipants", newDriverParticipants);
    } else if (type === PARTICIPANT_TYPES.Corporate) {
      const corporateParticipants = getValues("corporateParticipants") || [];
      const newCorporateParticipants = corporateParticipants.filter(
        (item) => item.value !== id
      );
      setValue("corporateParticipants", newCorporateParticipants);
    }
  };

  useEffect(() => {
    if (!disclosure.isOpen) {
      resetForm();
      setFileData({
        binary: null,
        url: null,
      });
    }
  }, [disclosure.isOpen, resetForm]);

  return (
    <FormModal
      title={"Add Group"}
      disclosure={disclosure}
      isSubmitting={chatQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"30rem"}
      reset={resetForm}
      onCloseFn={resetForm}
    >
      <VStack spacing={5} divider={<Divider />} align={"stretch"}>
        <VStack align={"stretch"}>
          <Box m="auto">
            <Avatar
              size="2xl"
              name={data?.title || watch("title")}
              src={fileData?.url}
            >
              <AvatarBadge boxSize="8">
                <FormFileUpload
                  id={"profilePicture"}
                  onChange={onChangeImage}
                  component={
                    <IconButton
                      variant={"ghost"}
                      icon={
                        <Icon
                          as={APP_ICONS.EDIT}
                          boxSize="7"
                          color={getColor(colorKeys.primary, colorMode)}
                        />
                      }
                    />
                  }
                  componentProps={{ variant: "ghost" }}
                  fileType={"IMAGE"}
                />
              </AvatarBadge>
            </Avatar>
          </Box>

          <FormInput
            label={"Group Title"}
            control={control}
            errors={errors}
            id="title"
            required={true}
            placeholder="Enter group title"
            inputProps={{ size: "sm" }}
          />
          <FormTextarea
            id="description"
            control={control}
            errors={errors}
            label={"Description"}
            placeholder="Enter short description"
            inputProps={{ size: "sm" }}
          />
        </VStack>

        <Box>
          <Text fontSize={"13px"} mb={"2"}>
            Participants
          </Text>
          <CustomTabs
            containerProps={{ isFitted: true, p: 0 }}
            tabItemProps={{ fontSize: "sm" }}
            tabPanelProps={{ p: 0, pb: 0, pt: 2 }}
            tabs={[
              {
                head: "Drivers",
                key: "drivers",
                body: (
                  <FormComboBox
                    allowClear={false}
                    id="driverParticipants"
                    placeholder="Search drivers by name"
                    valueBoxComponent={(value) => (
                      <ParticipantBox
                        value={value}
                        onRemoveParticipant={onRemoveParticipant}
                        type={PARTICIPANT_TYPES.Driver}
                        containerProps={{
                          h: "calc(100vh - 700px)",
                          minH: "230px",
                        }}
                      />
                    )}
                    control={control}
                    hideLabel={true}
                    errors={errors}
                    inputProps={{ size: "sm" }}
                    searchFn={(query) => setParticipantQuery(query)}
                    isLoading={participantQuery && driverQuery.isLoading}
                    options={
                      driverQuery.data?.data?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      })) || []
                    }
                  />
                ),
              },
              {
                head: "Admins",
                key: "admins",
                body: (
                  <FormComboBox
                    allowClear={false}
                    id="adminParticipants"
                    placeholder="Search admin by name"
                    valueBoxComponent={(value) => (
                      <ParticipantBox
                        value={value}
                        onRemoveParticipant={onRemoveParticipant}
                        type={PARTICIPANT_TYPES.Admin}
                        containerProps={{
                          h: "calc(100vh - 700px)",
                          minH: "230px",
                        }}
                      />
                    )}
                    control={control}
                    hideLabel={true}
                    errors={errors}
                    inputProps={{ size: "sm" }}
                    searchFn={(query) => setParticipantQuery(query)}
                    isLoading={participantQuery && adminQuery.isLoading}
                    options={
                      adminQuery.data?.data?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      })) || []
                    }
                  />
                ),
              },
            ]}
          />
        </Box>
      </VStack>
    </FormModal>
  );
};

export default ChatForm;
