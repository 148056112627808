import React, { useMemo } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, SimpleGrid, useColorMode } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import BookingInfo from "./BookingInfo";
import PassengerInfo from "./PassengerInfo";
import TipPromo from "./TipPromo";
import LocationInfo from "./LocationInfo";
import { useUpdateBooking } from "../../../../config/query/bookingsQuery";
import { useNavigate } from "react-router";
import { getEnabledFields, preparePayload } from "../../../../config/helpers/formHelpers/bookingHelper";
import { formatDateTimeToBookingZone } from "../../../../config/helpers/dateHelper";
import { colorKeys, getColor } from "../../../../config/constants/appColors";

const BookingFormModal = ({ disclosure, data, onSuccess }) => {

    const { colorMode } = useColorMode()

    const updateBookingQuery = useUpdateBooking();
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        setValue,
        reset: resetForm,
        formState: { errors },
        watch,
    } = useForm({
        values: {
            pickup: {
                name: data?.rideStops?.at(0)?.addressName,
                label: data?.rideStops?.at(0)?.address,
                latLng: data?.rideStops?.at(0)?.location,
            },
            destination: {
                name: data?.rideStops?.at(data?.rideStops?.length - 1)?.addressName,
                label: data?.rideStops?.at(data?.rideStops?.length - 1)?.address,
                latLng: data?.rideStops?.at(data?.rideStops?.length - 1)?.location,
            },
            stops: data?.rideStops?.slice(1, data?.rideStops?.length - 1).map((item) => ({
                name: item?.addressName,
                label: item?.address,
                latLng: item?.location,
            })),
            rideType: data?.rideType?.id,
            // rideTime: formatDateTimeForInput(data?.rideTime),
            rideTime: formatDateTimeToBookingZone(data?.rideTime, data?.timeZoneOffSet),
            dispatchType: data?.driverId, //Added driver Id here cause dispatch type is true when driver is assigned
            paymentMethod: data?.paymentMethod?.id,
            externalWalletType: data?.externalWalletType,
            externalWalletTypeId: data?.externalWalletType?.id,
            promoCodeId: data?.promoCode?.id,
            promoCode: data?.promoCode?.name,
            dispatcherNote: data?.dispatcherNote,
            internalNote: data?.internalNote,
            vehicleTypeId: data?.vehicleType?.id,
            localNumber: data?.customerPhone,
            customerType: data?.customerType?.id,
            creditCardId: typeof data?.externalWalletType?.id !== "undefined" ? data?.externalWalletType?.id : data?.card?.id,
            corporateId: data?.corporate?.id,
            promoCodeData: data?.promoCode,
            driverId: data?.driverId,
            tempRideTime: data?.rideTime,
        }
    });

    const { rideType, rideTime, pickup, destination, stops, creditCardId } = watch()

    const enabledFields = useMemo(() => {
        return getEnabledFields({ status: data?.status?.id })
    }, [data?.status?.id])

    const didInfoChange = () => {
        return !Boolean(data?.rideType?.id === rideType
            || data?.rideTime === rideTime
            || data?.rideStops?.at(0)?.location?.lat === pickup?.latLng?.lat
            || data?.rideStops?.at(0)?.location?.lng === pickup?.latLng?.lng
            || data?.rideStops?.at(data?.rideStops?.length - 1)?.location?.lat === destination?.latLng?.lat
            || data?.rideStops?.at(data?.rideStops?.length - 1)?.location?.lng === destination?.latLng?.lng
            || data?.rideStops?.slice(1, data?.rideStops?.length - 1).every((item, index) => {
                return item?.location?.lat === stops?.at(index)?.latLng?.lat
                    && item?.location?.lng === stops?.at(index)?.latLng?.lng
            }))
    }

    const onSubmit = (values) => {
        const payload = preparePayload({ values, enabledFields })
        updateBookingQuery
            .mutateAsync({ body: payload, id: data?.id })
            .then(() => {
                resetForm();
                onSuccess && onSuccess()
                navigate(`/admin/bookings`);
            })
            .catch((error) => console.warn(`Error while updating booking: ${error}`));
    };


    const resetFormValues = () => {
        resetForm({
            stops: [],
            rideType: 0,
            customerType: 0,
            corporateId: 0,
        });
    };

    const infoChanged = didInfoChange()

    return (
        <Modal isOpen={disclosure?.isOpen} motionPreset="none" onClose={disclosure?.onClose} size="full">
            <ModalOverlay />
            <ModalContent bg={getColor(colorKeys.tableBackground, colorMode)}>

                <ModalHeader>
                    Edit Booking
                </ModalHeader>

                <ModalCloseButton />
                <ModalBody bg={getColor(colorKeys.tableBackground, colorMode)}>
                    <SimpleGrid
                        spacing={5}
                        columns={{ base: 1, md: 2, lg: 4 }}
                        as="form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <BookingInfo
                            control={control}
                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                            infoChanged={infoChanged}
                            enabledFields={enabledFields}
                            isEdit={true}
                        />
                        <PassengerInfo
                            control={control}
                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                            enabledFields={enabledFields}
                            data={data}
                            isEdit={true}
                        />
                        <TipPromo
                            control={control}
                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                            enabledFields={enabledFields}
                            data={data}
                        />
                        <LocationInfo
                            control={control}
                            errors={errors}
                            watch={watch}
                            isSubmitting={updateBookingQuery.isLoading}
                            onClearForm={resetFormValues}
                            isDisabled={enabledFields?.everyField ? false : !enabledFields?.fields?.destination}
                            formButtonText="Update"
                        />
                    </SimpleGrid>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default BookingFormModal;
