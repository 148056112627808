import React, { useEffect } from 'react'
import { Card, chakra, Flex, Box, Text, useColorMode, Image } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup'
import { getColor, colorKeys } from "../../../config/constants/appColors"
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import FormInput from "../../../components/Forms/FormInput"
import { useSetPassword, useVerifySetPassword } from '../../../config/query/authQuery'
import IMAGES from '../../../config/constants/images'
import COLOR_MODES from '../../../config/constants/colorModes'
import WhiteLogo from '../../../components/SVGComponents/WhiteLogo'
import { queryStringToObject } from '../../../config/helpers/queryHelper'
import { useSelector } from 'react-redux'

const SetPassword = () => {
    const tenant = useSelector((state) => state.tenant?.tenant)
    const navigate = useNavigate()
    const queryObject = queryStringToObject()
    const { colorMode } = useColorMode()

    const setPasswordQuery = useSetPassword()
    const verifySetPasswordQuery = useVerifySetPassword()

    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required("Password is required")
            .min(4, "Password length should be at least 4 characters")
            .max(12, "Password cannot exceed more than 12 characters"),
        confirmPassword: Yup.string()
            .required("Confirm Password is required")
            .min(4, "Password length should be at least 4 characters")
            .max(12, "Password cannot exceed more than 12 characters")
            .oneOf([Yup.ref("password")], "Passwords do not match")
    });

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formSchema)
    })

    function onSubmit(values) {
        const payload = {
            tenant: queryObject?.tenant,
            body: {
                userId: values?.userId,
                code: values?.code,
                password: values.password,
            }
        }
        setPasswordQuery
            .mutateAsync(payload)
            .then(() => navigate("/admin"))
            .catch((error) => console.warn(error))
    }

    useEffect(() => {
        const payload = {
            tenant: queryObject?.tenant,
            body: {
                userId: queryObject?.userId,
                code: queryObject?.code
            }
        }
        verifySetPasswordQuery
            .mutateAsync(payload)
            .then((res) => {
                setValue("userId", res.data?.userId)
                setValue("code", res.data?.code)
            })
            .catch(() => navigate("/auth/login"))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Flex height={"100vh"} justify="center" align="center" bg={getColor(colorKeys.white, colorMode)} background={`url(${tenant?.backgroundImageUrl})`} backgroundRepeat={"no-repeat"} backgroundSize={"cover"} backgroundPosition={"center"}>
            <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
                <Card w="full" maxW="480px">
                    {verifySetPasswordQuery.isLoading
                        ? <Box w="full" maxW="480px" p="10">
                            <Text fontWeight={"bold"} textAlign={"center"} fontSize="20px" color={getColor(colorKeys.black, colorMode)}>
                                Verifying Link...
                            </Text>
                        </Box>
                        : <Box w="full" maxW="480px">

                            <Flex px="5" justify={"space-between"} bg={getColor(colorKeys.dimmerBlue, colorMode)}>
                                <Box>
                                    <Text fontSize="16px" color={getColor(colorKeys.white, colorMode)} mt={5}>
                                        Welcome!
                                    </Text>
                                    <Text fontSize="13px" color={getColor(colorKeys.white, colorMode)} >
                                        Create a password for your account
                                    </Text>
                                </Box>

                                <Box background={`url(${IMAGES.AUTH_MIN})`} w="173px" h="112px" />
                            </Flex>

                            <Box
                                pos="absolute"
                                top="75px"
                                left="25px"
                                w="90px"
                                m="auto"
                                bg={getColor(colorKeys.white, colorMode)}
                                rounded="full"
                            >
                                {colorMode === COLOR_MODES.LIGHT ?
                                    <Image w="full" h="full" src={tenant?.logoUrl} alt="logo" />
                                    : <WhiteLogo containerStyles={{ margin: "auto" }} />}
                            </Box>

                            <Box p="10">

                                <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">

                                    <FormInput
                                        id={"password"}
                                        label={"New Password"}
                                        type="password"
                                        secure={true}
                                        placeholder="Create your password"
                                        required={true}
                                        errors={errors}
                                        control={control}
                                        inputProps={{ fontSize: "15px", autocomplete: "new-password" }}
                                        containerStyles={{ mt: 5 }}
                                    />

                                    <FormInput
                                        id={"confirmPassword"}
                                        label={"Confirm Password"}
                                        type="password"
                                        secure={true}
                                        placeholder="Confirm your password"
                                        required={true}
                                        errors={errors}
                                        control={control}
                                        inputProps={{ fontSize: "15px", autocomplete: "new-password" }}
                                        containerStyles={{ mt: 5 }}
                                    />


                                    <PrimaryButton
                                        isLoading={setPasswordQuery.isLoading}
                                        mt={5}
                                        type="submit"
                                        p="18px 33px"
                                        bg={getColor(colorKeys.spanishYellow, colorMode)}
                                        w="full"
                                        rounded="md"
                                        fontWeight={"bold"}
                                        color="white"
                                    >
                                        Set Password
                                    </PrimaryButton>


                                </chakra.form>

                            </Box>

                        </Box>}
                </Card>

            </Flex>
        </Flex>
    )
}

export default SetPassword