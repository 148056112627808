import {
    Flex,
    Icon,
    Text,
    useClipboard,
    useColorMode,
    useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import APP_ICONS from "../../../config/constants/icons";
import { colorKeys, getColor } from "../../../config/constants/appColors";

const CopyText = ({ text, containerProps }) => {
    const copyDisclosure = useDisclosure();
    const { colorMode } = useColorMode();
    const { onCopy, hasCopied } = useClipboard(text);


    useEffect(() => {
        if (hasCopied && !copyDisclosure.isOpen) {
            copyDisclosure.onOpen();
            setTimeout(() => {
                copyDisclosure.onClose();
            }, 1000);
        }
    }, [hasCopied, copyDisclosure])
    return (
        <Flex align={"center"} justify={"space-between"} gap={2} {...containerProps}>
            <Text>{text}</Text>
            <Icon
                onClick={onCopy}
                boxSize={5}
                variant="Bold"
                color={copyDisclosure.isOpen ? getColor(colorKeys.success, colorMode) : getColor(colorKeys.primaryText, colorMode)}
                as={copyDisclosure.isOpen ? APP_ICONS.CHECK : APP_ICONS.COPY}
                cursor={"pointer"}
            />
        </Flex>
    );
};

export default CopyText;
