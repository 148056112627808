import React from 'react'
import CustomTable from '../../../components/BasicUI/CustomTable'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import { formatDateTime } from '../../../config/helpers/dateHelper'
import TableHeaderOptions from '../../../components/BasicUI/CustomTable/TableHeaderOptions'
import { Box, useDisclosure } from '@chakra-ui/react'
import APP_ICONS from '../../../config/constants/icons'
import TenantForm from './TenantForm'
import { useTenants } from '../../../config/query/tenantQuery'
import { APP_MODULES } from '../../../config/constants'

const Tenants = () => {
  const formDisclosure = useDisclosure()
  const tenantQuery = useTenants()

  return (
    <Box>
      <TableHeaderOptions
        title={"Tenants"}
        actionText={"Create New Tenant"}
        action={() => {
          formDisclosure.onOpen()
        }}
        icon={APP_ICONS.ADD}
        permissionKey={APP_MODULES.Tenants}
      />
      <CustomTable
        permissionKey={APP_MODULES.Tenants}
        tableFor={"tenant"}
        searchPlaceholder="Search tenant"
        hideSearch={true}
        head={[
          { title: "Name", extractor: "name", align: "left", isSortable: false },
          { title: "Admin Email", extractor: "adminEmail", isSortable: false },
          { title: "Valid Upto", extractor: "validUpto", component: (item) => formatDateTime(item.validUpto), isSortable: false },
          { title: "Status", extractor: "isActive", component: (item) => <StatusBadge value={{ id: Number(item.isActive), name: item.isActive ? "Active" : "Inactive" }} />, isSortable: false },
        ]}
        data={tenantQuery?.data}
        loading={tenantQuery?.isLoading}
        onRefresh={() => tenantQuery.refetch()}
        isRefreshing={tenantQuery?.isFetching}
      />

      <TenantForm disclosure={formDisclosure} />
    </Box>
  )
}

export default Tenants