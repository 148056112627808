import React, { useState, useEffect } from "react";
import { useColorMode } from "@chakra-ui/color-mode";
import { Box, Center, Text, Flex } from "@chakra-ui/react";
import { Chart } from "react-google-charts";
import { formatTimeByViewMode } from "../../config/helpers/dateHelper";
import { colorKeys, getColor } from "../../config/constants/appColors";
import { COLOR_MODES } from "../../config/constants/enums";

const MultiLineChart = ({ data = [], viewMode = 0 }) => {
    const { colorMode } = useColorMode()
    const [loading, setLoading] = useState(false)

    const rowsData = data?.map((item) => {
        return [
            formatTimeByViewMode({ time: item.time, viewMode }),
            item.profit,
            item.bookings
        ];
    })
    const chartData = [
        ["x", "Profit", "Bookings"],
        ...rowsData
    ];

    const options = {
        hAxis: {
            title: "Time",
            format: 'HH:mm',
        },
        vAxis: {
            title: "Value",
        },
        series: {
            1: { curveType: "function" },
        },
    };


    useEffect(() => {
        if (!loading) setLoading(true)
        const timeout = setTimeout(() => {
            setLoading(false)
        }, 1000)
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorMode])

    if (data?.length === 0) return (
        <Box px="2">
            <Center flexDir={"column"} h="300px" bg={getColor(colorKeys.lightestGray, colorMode)}>
                <Text fontWeight={"bold"} fontSize="16px" color={getColor(colorKeys.primaryText, colorMode)}>
                    No Data
                </Text>
                <Text fontSize="13px" maxW="220px" textAlign={"center"} color={getColor(colorKeys.dimText, colorMode)}>
                    Try changing the date range or view mode
                </Text>
            </Center>
        </Box>
    )

    return (
        <Box mt="-54px">
            {loading
                ? <Flex
                    justify="center"
                    align="center"
                    width="100%"
                    height="400px"
                >
                    <Text>Loading...</Text>
                </Flex>
                : <Chart
                    chartType="LineChart"
                    width="100%"
                    height="400px"
                    data={chartData}
                    options={options}
                    className={colorMode === COLOR_MODES.LIGHT ? "earning-chart" : "earning-chart-dark"}
                />
            }
        </Box>
    );
};

export default MultiLineChart;