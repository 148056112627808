import {
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Switch,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import APP_ICONS from "../../../../config/constants/icons";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import { Link, useNavigate } from "react-router-dom";
import {
  useZones,
  useUpdateZoneStatus,
} from "../../../../config/query/zoneQuery";
import ZoneListSkeleton from "../../../../components/Skeletons/ZoneListSkeleton";
import PolygonBox from "../../../../components/Maps/PolygonBox";
import TableHeaderOptions from "../../../../components/BasicUI/CustomTable/TableHeaderOptions";
import sortOrders from "../../../../config/constants/sortOrders";
import { getPolylineOptions } from "../../../../components/Maps/mapConfig";

const Zone = () => {
  const { colorMode } = useColorMode();
  const [selectedZone, setSelectedZone] = useState(null);
  const navigate = useNavigate();

  const zoneQuery = useZones({
    Sort: "lastModifiedOn",
    SortOrder: sortOrders.DESC,
  });
  const zoneStatusUpdate = useUpdateZoneStatus();

  const handleStatusChange = (status, id) => {
    zoneStatusUpdate.mutate({ status: Number(status), id });
  };

  const onView = (item) => {
    navigate(`/admin/zones/${item}`);
  };


  const handleSelectZone = (item) => {
    if (item.id === selectedZone?.id)
      return setSelectedZone(null)
    setSelectedZone(item)
  }

  return (
    <VStack spacing={5} w="full">
      <TableHeaderOptions title={"Zones"} />

      <Grid
        templateColumns="repeat(4, 1fr)"
        gap={6}
        h="calc(100vh - 160px)"
        w="full"
      >
        <GridItem colSpan={{ base: 4, xl: 1 }}>
          <Card h="full">
            <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
              <VStack spacing={3} h="calc(100vh - 200px)" overflow={"auto"}>
                <Button
                  rounded={"sm"}
                  w="full"
                  size="sm"
                  leftIcon={<Icon as={APP_ICONS.ADD} />}
                  as={Link}
                  to={"/admin/zones/create"}
                  pos="sticky"
                  top="0"
                  zIndex="1"
                  minH="35px"
                >
                  Create New
                </Button>

                {zoneQuery.isFetching ? (
                  <ZoneListSkeleton />
                ) : (
                  zoneQuery?.data?.map((item, index) => (
                    <Flex
                      w="full"
                      align="center"
                      justify={"space-between"}
                      key={index}
                      cursor={"pointer"}
                      p="5px"
                      rounded={"sm"}
                      bg={
                        selectedZone?.id === item?.id
                          ? getColor(colorKeys.highlightedText, colorMode)
                          : getColor(colorKeys.tableRow, colorMode)
                      }
                    >
                      <Text w="70%" onClick={() => handleSelectZone(item)}>
                        {item.name}
                      </Text>
                      <HStack spacing={2}>
                        <Switch
                          key={item.id}
                          defaultChecked={item.status?.id}
                          onChange={(e) =>
                            handleStatusChange(e.target.checked, item.id)
                          }
                          mr={"10px"}
                        />

                        <IconButton
                          size="xs"
                          icon={
                            <Icon
                              as={APP_ICONS.EDIT}
                              onClick={() => onView(item?.id)}
                            />
                          }
                        >
                          Edit
                        </IconButton>
                      </HStack>
                    </Flex>
                  ))
                )}
              </VStack>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={{ base: 4, xl: 3 }}>
          <PolygonBox
            polyLineDefaultColor={"#FF0000"}
            disabled={true}
            polylineCoords={selectedZone?.coordinates || []}
            center={selectedZone?.coordinates?.[0] || { lat: 0, lng: 0 }}
            setPolylineCoords={() =>
              selectedZone?.coordinates?.[0] || { lat: 0, lng: 0 }
            }
            containerProps={{ height: "calc(100vh - 165px)" }}
            polygonsArray={!selectedZone ? zoneQuery.data?.map((zone) => zone.coordinates) : []}
            polygonOptions={getPolylineOptions(getColor(colorKeys.lightBlue, colorMode), true)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
};

export default Zone;
