import { Box, Icon, IconButton, InputLeftAddon, InputRightAddon, SimpleGrid, Text, VStack, useColorMode } from '@chakra-ui/react'
import React from 'react'
import FormModal from '../../../../components/Forms/FormModal'
import { useForm } from 'react-hook-form'
import FormInput from '../../../../components/Forms/FormInput'
import { colorKeys, getColor } from '../../../../config/constants/appColors'
import FormButton from '../../../../components/Forms/FormButton'
import APP_ICONS from '../../../../config/constants/icons'
import { useCreatePricing, usePricing, useUpdatePricing } from '../../../../config/query/pricingQuery'
import { useQueryClient } from '@tanstack/react-query'
import FormEditableInput from '../../../../components/Forms/FormInput/FormEditableInput'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'
import { STATUS } from '../../../../config/constants/enums'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import { prepareForm, preparePayload } from '../../../../config/helpers/formHelpers/defaultRateHelper'

const DefaultRateForm = ({ disclosure, data }) => {

    const { colorMode } = useColorMode()
    const queryClient = useQueryClient()

    const pricingQuery = usePricing(data?.id)
    const createPricingQuery = useCreatePricing()
    const updatePricingQuery = useUpdatePricing()

    const {
        handleSubmit,
        control,
        setValue,
        reset: resetForm,
        getValues: getFormValues,
        watch,
        formState: { errors },
    } = useForm({
        values: prepareForm({
            formValues: data,
            serverValues: pricingQuery.data
        }),
    })

    const { otherFares } = watch()

    const onSubmit = (values) => {
        let formMutate = data?.id ? updatePricingQuery.mutateAsync : createPricingQuery.mutateAsync
        formMutate(preparePayload(values, data?.id))
            .then(() => {
                disclosure.onClose()
                queryClient.invalidateQueries(["pricings"])
                queryClient.invalidateQueries(["pricing"])
                resetForm()
            })
            .catch(err => {
                console.warn("Error in create pricing:", err)
            })
    }

    const addOtherFare = () => {
        const newOtherFares = [...otherFares, {
            tempId: Date.now(),
            fareType: "Field" + (otherFares.length + 1),
            fareRate: 0
        }]
        setValue("otherFares", newOtherFares)
    }

    const removeOtherFare = (index) => {
        // if (otherFares[index]?.id) return 
        const newOtherFares = [...otherFares]
        newOtherFares.splice(index, 1)
        setValue("otherFares", newOtherFares)
    }

    return (
        <FormModal
            title={data ? "Edit Default Rate" : "Add Default Rate"}
            disclosure={disclosure}
            isSubmitting={createPricingQuery.isLoading || updatePricingQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"75rem"}
            onCloseFn={() => {
                resetForm()
            }}
        >

            <FormFieldsSkeleton columns={{ base: 1, md: 3 }} count={5} mb={5} loading={pricingQuery.isFetching} />
            {!pricingQuery.isFetching && <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} mb={5}>

                <FormInput
                    label={"Name"}
                    control={control}
                    errors={errors}
                    id="name"
                    required={true}
                    placeholder="Enter default rate name"
                    inputProps={{ size: "sm" }}
                />

                <FormSearchSelect
                    id="status"
                    label="Status"
                    placeholder={'Select status'}
                    required={true}
                    errors={errors}
                    control={control}
                    options={makeSelectList(STATUS)}
                    inputProps={{ size: "sm" }}
                />

                <FormInput
                    label={"Tech Fee"}
                    type={"number"}
                    control={control}
                    errors={errors}
                    id="techFee"
                    required={true}
                    placeholder="0.00"
                    inputProps={{ size: "sm" }}
                    rules={{
                        validate: (value) => {
                            if (value && value > 999) {
                                return "Maximum value should be 999"
                            }
                        }
                    }}
                    size='sm'
                    leftAddon={<InputLeftAddon children={"$"} />}
                />

            </SimpleGrid>

            }

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                <Box p="20px" border={`1px solid ${getColor(colorKeys.lightGray, colorMode)}`}>
                    <Text fontSize="13px" fontWeight={"bold"} mb={1}>Starting Rate</Text>
                    <Text fontSize="12px" mb={3}>Set starting base fare for a ride </Text>
                    <FormFieldsSkeleton columns={1} count={2} loading={pricingQuery.isFetching} />
                    {!pricingQuery.isFetching && <VStack spacing={5}>
                        <FormInput
                            label={"Now Rides"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="nowRidesStartingRate"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />
                        <FormInput
                            label={"Scheduled Rides"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="scheduledRidesStartingRate"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />
                    </VStack>}
                </Box>
                <Box p="20px" border={`1px solid ${getColor(colorKeys.lightGray, colorMode)}`}>
                    <Text fontSize="13px" fontWeight={"bold"} mb={1}>Minimum Fare</Text>
                    <Text fontSize="12px" mb={3}>Set minimum fare for a ride</Text>
                    <FormFieldsSkeleton columns={1} count={2} loading={pricingQuery.isFetching} />
                    {!pricingQuery.isFetching && <VStack spacing={5}>
                        <FormInput
                            label={"Now Rides"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="nowRidesMinimumFare"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />
                        <FormInput
                            label={"Scheduled Rides"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="scheduledRidesMinimumFare"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />
                    </VStack>}
                </Box>
                <Box p="20px" border={`1px solid ${getColor(colorKeys.lightGray, colorMode)}`}>
                    <Text fontSize="13px" fontWeight={"bold"} mb={1}>Rate Per Minute</Text>
                    <Text fontSize="12px" mb={3}>Set rate per minute for a ride </Text>
                    <FormFieldsSkeleton columns={1} count={2} loading={pricingQuery.isFetching} />
                    {!pricingQuery.isFetching && <VStack spacing={5}>
                        <FormInput
                            label={"Now Rides"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="nowRidesRatePerMinute"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />
                        <FormInput
                            label={"Scheduled Rides"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="scheduledRidesRatePerMinute"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />
                    </VStack>}
                </Box>
            </SimpleGrid>

            <VStack spacing={5} >

                <Box w="full">
                    <Text fontSize="13px" fontWeight={"bold"} my={3} w="full">Distances Fares</Text>
                    <FormFieldsSkeleton columns={{ base: 1, md: 3 }} count={2} loading={pricingQuery.isFetching} />
                    {!pricingQuery.isFetching && <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} w="full">

                        <FormInput
                            label={"Per Km Rate"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="perKmRate"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />

                        <FormInput
                            label={"Per Stop Rate"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="perStopRate"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />

                    </SimpleGrid>}

                </Box>



                <Box w="full">
                    <Text fontSize="13px" fontWeight={"bold"} my={3} w="full">Cancellation Penalties</Text>
                    <FormFieldsSkeleton columns={{ base: 1, md: 3 }} count={2} loading={pricingQuery.isFetching} />
                    {!pricingQuery.isFetching && <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} w="full">

                        <FormInput
                            label={"Cancellation - On Demand (Now)"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="cancellationOnDemandRate"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />

                        <FormInput
                            label={"Cancellation - In Advance (Scheduled)"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="cancellationInAdvanceRate"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />

                        <FormInput
                            label={"Cancellation - No Show"}
                            type={"number"}
                            control={control}
                            errors={errors}
                            id="cancellationNoShowRate"
                            required={true}
                            placeholder="0.00"
                            inputProps={{ size: "sm" }}
                            rules={{
                                validate: (value) => {
                                    if (value && value > 999) {
                                        return "Maximum value should be 999"
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                            feedbackMessage="If the customer does not show up for the ride, this amount will be charged to the customer."
                        />

                    </SimpleGrid>}

                </Box>

                <Box w="full">
                    <Text fontSize="13px" fontWeight={"bold"} my={3} w="full">Other Fares</Text>
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} w="full" alignItems="end">

                        {!pricingQuery.isFetching && otherFares?.map((fare, index) => {
                            return (
                                <Box key={index}>
                                    <FormEditableInput
                                        control={control}
                                        errors={errors}
                                        id={`otherFares[${index}][fareType]`}
                                        required={true}
                                        inputProps={{ size: "sm" }}
                                    />
                                    <FormInput
                                        hideLabel={true}
                                        label={getFormValues(`otherFares[${index}][fareType]`, watch)}
                                        type={"number"}
                                        control={control}
                                        id={`otherFares[${index}][fareRate]`}
                                        placeholder="0.00"
                                        required={true}
                                        inputProps={{ size: "sm" }}
                                        rightAddon={
                                            <InputRightAddon
                                                children={
                                                    <IconButton
                                                        variant={"unstyled"}
                                                        size="sm"
                                                        icon={<Icon boxSize={3} as={APP_ICONS.CLOSE} />}
                                                        color={getColor(colorKeys.grayText, colorMode)}
                                                        onClick={() => removeOtherFare(index)}
                                                    />

                                                }
                                            />
                                        }
                                        size='sm'
                                        leftAddon={<InputLeftAddon children={"$"} />}
                                    />
                                    <Text mt={1} color="#FC8181" fontSize={"14px"}>
                                        {errors?.otherFares?.[index]?.fareRate?.message}
                                        {errors?.otherFares?.[index]?.fareType?.message && "Label is required!"}
                                    </Text>
                                </Box>
                            )
                        })}

                        <FormButton
                            size="sm"
                            leftIcon={<Icon boxSize="5" as={APP_ICONS.ADD} />}
                            color={getColor(colorKeys.primaryButtonFill, colorMode)}
                            bg={getColor(colorKeys.white, colorMode)}
                            border={`1px solid ${getColor(colorKeys.primaryButtonFill, colorMode)}`}
                            onClick={addOtherFare}
                        >
                            Add Custom Fee
                        </FormButton>
                    </SimpleGrid>
                </Box>

            </VStack>

        </FormModal>
    )
}

export default DefaultRateForm