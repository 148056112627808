import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  chakra,
  Icon,
  IconButton,
  Tooltip,
  Box,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import FormInput from "../../../../components/Forms/FormInput";
import FormSwitch from "../../../../components/Forms/FormSwitch";
import FormSearchSelect from "../../../../components/Forms/FormSearchSelect";
import { EMAIL_REGEX } from "../../../../config/constants/regexConstants";
import APP_ICONS from "../../../../config/constants/icons";
import AddNewUser from "./AddNewUser";
import { usePassengerInfo } from "./bookingFormContainer";

const PassengerInfo = ({ control, errors, watch, setValue, enabledFields, data, isEdit }) => {

  const {
    formDisclosure,
    driverDisclosure,
    handleAddUser,
    availableDriversData,
    onUpdateDriverQuery,
    onUpdateCorporateQuery,
    corporates,
    colorMode,
    fields,
    corporateQuery,
    pickup,
    vehicleTypeId,
    rideType,
    availableDrivers,
    onUpdateCustomerQuery,
    customerLookup,
    isLoadingCustomer
  } = usePassengerInfo({ watch, setValue, isEdit })

  return (
    <Card>
      <CardHeader
        p="15px 18px 0px"
        bg={getColor(colorKeys.tableBackground, colorMode)}
      >
        <Heading size="md">Passenger Info</Heading>
      </CardHeader>
      <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
        <VStack spacing={5} align="stretch" divider={<Divider />}>
          <VStack spacing={5} align="stretch">

            <FormSearchSelect
              id="localNumber"
              label={"Customer"}
              control={control}
              errors={errors}
              required={true}
              placeholder="Search customer"
              inputProps={{ size: "sm", isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.localNumber }}
              searchFn={(query) => onUpdateCustomerQuery({ Search: query })}
              isLoading={customerLookup.isLoading}
              disableStaticSearch={true}
              labelExtention={<Button size="xs" onClick={formDisclosure.onOpen}>Add New</Button>}
              options={
                customerLookup.data?.data?.map((item) => ({
                  value: item.phone,
                  label: item.name,
                })) || []
              }
            />

            <Divider />

            <Text fontSize={"12px"}>This data will be auto filled according to selected customer</Text>

            {isLoadingCustomer
              ? <Flex justify={"center"}>
                <Spinner size="md" />
              </Flex>
              : null}

            <Flex justify={"center"}>
              <Text fontSize="14px" pt="3px">
                Individual
              </Text>
              <FormSwitch
                control={control}
                errors={errors}
                id="customerType"
                containerProps={{
                  mx: 3,
                  w: "fit-content",
                }}
                switchProps={{
                  // isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.customerType
                  isDisabled: true
                }}
              />
              <Text fontSize="14px" pt="4px">
                Corporate
              </Text>
            </Flex>

            {fields?.customerType && (
              <FormSearchSelect
                id="corporateId"
                label={"Corporate"}
                control={control}
                errors={errors}
                required={fields?.customerType}
                placeholder="Select corporate"
                inputProps={{
                  size: "sm",
                  //  isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.corporateId ,
                  isReadOnly: true
                }}
                searchFn={onUpdateCorporateQuery}
                isLoading={corporates.isFetching && corporateQuery.length > 0}
                options={corporates.data?.data?.map((item) => ({
                  value: item.id,
                  label: item.name,
                  paymentType: item.corporatePaymentType?.id
                })) || []}
                onChangeEffect={(data) => setValue("corporatePaymentType", data?.paymentType)}
              />
            )}

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormInput
                id="firstName"
                label={"First Name"}
                control={control}
                errors={errors}
                placeholder="Enter first name"
                inputProps={{ size: "sm", isReadOnly: true, isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.firstName }}
              />

              <FormInput
                id="lastName"
                label={"Last Name"}
                control={control}
                errors={errors}
                placeholder="Enter last name"
                inputProps={{ size: "sm", isReadOnly: true, isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.lastName }}
              />
            </SimpleGrid>

            <FormInput
              id="email"
              label={"Email"}
              control={control}
              errors={errors}
              placeholder="Enter email"
              inputProps={{ size: "sm", isReadOnly: true, isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.email }}
              type={"email"}
              rules={{
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Please enter valid email",
                },
              }}
            />
          </VStack>

          {(pickup?.latLng && vehicleTypeId && rideType !== undefined) && (

            <VStack spacing={5} align="stretch">
              {typeof pickup === "undefined" && (
                <chakra.p fontSize="14px">
                  Pickup location is required for assign driver.
                </chakra.p>
              )}
              <Flex>
                <Text fontSize="14px" pt="3px">
                  Auto Dispatch
                </Text>
                <Tooltip
                  isDisabled={pickup}
                  label="Pickup location is required for assign driver."
                  placement="top"
                >
                  <Box>
                    <FormSwitch
                      control={control}
                      errors={errors}
                      id="dispatchType"
                      containerProps={{
                        mx: 3,
                        w: "fit-content",
                        isDisabled: !pickup,
                      }}
                      switchProps={{
                        isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.driverId
                      }}
                    />
                  </Box>
                </Tooltip>
                <Text fontSize="14px" pt="4px">
                  Re-Dispatch
                </Text>
              </Flex>


              {!data
                ? fields?.dispatchType && (
                  <FormSearchSelect
                    id="driverId"
                    label={"Driver"}
                    control={control}
                    errors={errors}
                    required={fields?.dispatchType}
                    placeholder="Search driver"
                    inputProps={{ size: "sm", isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.driverId }}
                    searchFn={(query) => onUpdateDriverQuery({ search: query })}
                    isLoading={availableDrivers.isLoading}
                    options={
                      availableDriversData.map((item) => ({
                        value: item.id,
                        label: item.name,
                      })) || []
                    }
                  />
                ) : (
                  <>
                    {((fields?.dispatchType && !data?.driverId) || (driverDisclosure.isOpen && data?.driverId)) && (
                      <FormSearchSelect
                        id="driverId"
                        label={"Driver"}
                        control={control}
                        errors={errors}
                        required={fields?.dispatchType}
                        placeholder="Search driver"
                        inputProps={{ size: "sm", isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.driverId }}
                        searchFn={(query) => onUpdateDriverQuery({ search: query })}
                        labelExtention={data?.driverId && data?.driverId === watch("driverId") && <Button size="xs" children="Close" onClick={driverDisclosure.onClose} />}
                        isLoading={availableDrivers.isLoading}
                        options={
                          availableDriversData.map((item) => ({
                            value: item.id,
                            label: item.name,
                          })) || []
                        }
                      />
                    )}

                    {((data?.driverId && fields?.dispatchType && data?.driverId === watch("driverId") && !driverDisclosure.isOpen)) ? (
                      <Flex rounded={"md"} align="center" justify={"space-between"} bg={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} px="2" py="1">
                        <VStack align={"stretch"}>
                          <Heading fontSize={"16px"}>Driver:</Heading>
                          <Box>
                            <Text>{data?.driverName}</Text>
                            <Text>{data?.driverPhoneNo}</Text>
                          </Box>
                        </VStack>
                        {enabledFields?.everyField ? true : enabledFields?.fields?.driverId && <IconButton icon={<Icon as={APP_ICONS.EDIT} />} onClick={driverDisclosure.onToggle} />}
                      </Flex>
                    ) : null}
                  </>
                )}
            </VStack>
          )}
        </VStack>

        <AddNewUser
          disclosure={formDisclosure}
          // localNumber={localNumber}
          onUserData={handleAddUser}
        />
      </CardBody>
    </Card>
  );
};

export default PassengerInfo;