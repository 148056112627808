import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    VStack,
    SimpleGrid,
    Box,
    Avatar,
    Heading,
    useColorMode,
    Text,
    HStack,
} from '@chakra-ui/react'
import React from 'react'
import StarRating from '../../../../components/BasicUI/DataBoxes/StarRating';
import { colorKeys, getColor } from '../../../../config/constants/appColors';
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair';

const BookingRatings = ({ data, disclosure }) => {
    const { colorMode } = useColorMode()

    return (
        <Modal isOpen={disclosure?.isOpen} onClose={disclosure?.onClose} isLazy>
            <ModalOverlay />
            <ModalContent maxW="55rem">
                <ModalHeader>Ratings</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <SimpleGrid columns={2} spacing={5}>

                        <Box border={`1px solid ${getColor(colorKeys.tableFilterBorder, colorMode)}`}>
                            <Box mb={2} bg={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} p="5px 15px">
                                <Text fontSize="xl" fontWeight="bold">Driver</Text>
                            </Box>
                            <VStack spacing="4" align={"stretch"} p="4px 12px">
                                <HStack spacing={3}>
                                    <Avatar src={data?.driverPictureURL} size="lg" name={data?.driverName} />
                                    <VStack spacing={0} align={"stretch"}>
                                        <Heading fontSize="22px">{data?.driverName || "N/A"}</Heading>
                                    </VStack>
                                </HStack>
                                <LabelValuePair
                                    label={"Ratings"}
                                    value={true}
                                    valueComponent={data?.driverRating
                                        ? <StarRating
                                            count={5}
                                            value={data?.driverRating}
                                            activeColor={getColor(colorKeys.spanishYellow, colorMode)}
                                            inactiveColor="transparent"
                                            containerProps={{ justifyContent: "left" }}
                                        />
                                        : "N/A"}
                                    flexDir='column'
                                    valueProps={{ textAlign: "center" }}
                                    headingProps={{ mb: 2 }}
                                />

                            </VStack>
                        </Box>
                        <Box border={`1px solid ${getColor(colorKeys.tableFilterBorder, colorMode)}`}>
                            <Box mb={2} bg={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} p="5px 15px">
                                <Text fontSize="xl" fontWeight="bold">Customer</Text>
                            </Box>
                            <VStack spacing="4" align={"stretch"} p="4px 12px">
                                <HStack spacing={3}>
                                    <Avatar size="lg" src={data?.customerPictureURL} name={data?.customerName} />
                                    <VStack spacing={0} align={"stretch"}>
                                        <Heading fontSize="22px">{data?.customerName || "N/A"}</Heading>
                                    </VStack>
                                </HStack>

                                <LabelValuePair
                                    label={"Ratings"}
                                    value={true}
                                    valueComponent={data?.customerRating
                                        ? <StarRating
                                            count={5}
                                            value={data?.customerRating}
                                            activeColor={getColor(colorKeys.spanishYellow, colorMode)}
                                            inactiveColor="transparent"
                                            containerProps={{ justifyContent: "left" }}
                                        />
                                        : "N/A"}
                                    flexDir='column'
                                    valueProps={{ textAlign: "center" }}
                                    headingProps={{ mb: 2 }}
                                />

                                <LabelValuePair
                                    label={"Customer Comment"}
                                    value={data?.customerRatingDescr || "N/A"}
                                    flexDir='column'
                                    headingProps={{ mb: 2 }}
                                />

                            </VStack>
                        </Box>
                    </SimpleGrid>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" mr={3} onClick={disclosure?.onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default BookingRatings