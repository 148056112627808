import { Avatar, Box, Card, CardBody, Flex, HStack, SimpleGrid, Text, VStack, useColorMode, Checkbox, useDisclosure } from '@chakra-ui/react'
import { useParams } from 'react-router'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import { getColor, colorKeys } from '../../../config/constants/appColors'
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import CustomTabs from '../../../components/BasicUI/CustomTabs'
import { useCorporate, useDirectInvoiceStatus, useResendEmail } from '../../../config/query/corporateQuery'
import CorporateForm from './CorporateForm'
import CorporateBookings from './CorporateBookings'
import CorporateUsers from './CorporateUsers'
import { formatAmount, formatPhoneNumberForDisplay } from '../../../config/helpers/stringHelper'
import UpdateCreditForm from './UpdateCreditForm'
import CorporateCards from './CorporateCards'
import CorporateInvoices from './CorporateInvoices'
import PayPeriodForm from './PayPeriodForm'
import { getPermission } from '../../../config/helpers/permissionHelper'
import { APP_MODULES } from '../../../config/constants'
import { useSelector } from 'react-redux'

const CorporateDetails = () => {
    const creditFormDisclosure = useDisclosure();
    const { colorMode } = useColorMode()
    const { corporateId } = useParams()
    const corporateFormDisclosure = useDisclosure()
    const payPeriodDisclosure = useDisclosure();

    const corporateQuery = useCorporate(corporateId)
    const directInvoiceQuery = useDirectInvoiceStatus()
    const resendEmailQuery = useResendEmail()

    const permissions = useSelector((state) => state?.user?.permissions)
    const corporatePermissions = getPermission({ permissionKey: APP_MODULES.Corporate, permissions })

    const onUpdateDirectInvoice = (e) => {
        const payload = {
            id: corporateId, body:
            {
                isEnabled: e.target.checked
            }
        }

        directInvoiceQuery
            .mutateAsync(payload)
            .then(() => corporateQuery.refetch())
            .catch((error) => console.warn(error))

    }

    const onResendEmail = () => {
        resendEmailQuery
            .mutateAsync(corporateId)
            .catch((error) => console.warn(error))
    }

    return (
        <Box>
            <VStack spacing={3}>
                <Card w="full" bg={getColor(colorKeys.tableBackground, colorMode)}>
                    <CardBody>
                        <SimpleGrid columns={{ base: 1, md: 2 }} w="full" alignItems={"center"}>
                            <Flex align="center">
                                <Avatar size="xl" src={corporateQuery.data?.profilePictureUrl} />
                                <VStack spacing={0} align={"stretch"} ml={3} maxW="450px">
                                    <Text fontWeight={"bold"} fontSize="16px">{corporateQuery.data?.name}</Text>
                                    {corporateQuery.data?.phone && <Text fontSize="14px">{formatPhoneNumberForDisplay(corporateQuery.data?.phone)}</Text>}
                                    {corporateQuery.data?.corporateCode && <Text fontSize="14px">Corporate Code: {corporateQuery.data?.corporateCode}</Text>}
                                    {directInvoiceQuery.isLoading || corporateQuery.isLoading
                                        ? <Text fontSize={"13px"} color={getColor(colorKeys.secondaryText, colorMode)}>Loading...</Text>
                                        : <Checkbox isChecked={corporateQuery.data?.directInvoice} onChange={onUpdateDirectInvoice}>
                                            <Text fontSize={"13px"} color={getColor(colorKeys.secondaryText, colorMode)}>
                                                Enable Direct Invoice
                                            </Text>
                                        </Checkbox>
                                    }
                                </VStack>
                            </Flex>

                            <Flex flexDir={"column"} alignItems="self-end">
                                <LabelValuePair
                                    label={"Total Rides"}
                                    value={corporateQuery.data?.totalRides}
                                    headingProps={{
                                        fontSize: "15px",
                                        color: getColor(colorKeys.secondaryText, colorMode),
                                        fontWeight: "normal"
                                    }}
                                    isLoading={corporateQuery.isLoading}
                                    loadingProps={{ w: "100px", h: "25px", mt: 2 }}
                                    valueProps={{
                                        fontSize: "15px",
                                        color: getColor(colorKeys.dimText, colorMode),
                                        fontWeight: "bold",
                                        textAlign: "center"
                                    }}
                                />
                                <LabelValuePair
                                    label={"Total Spendings"}
                                    value={formatAmount(corporateQuery.data?.totalSpending)}
                                    headingProps={{
                                        fontSize: "15px",
                                        color: getColor(colorKeys.secondaryText, colorMode),
                                        fontWeight: "normal"
                                    }}
                                    isLoading={corporateQuery.isLoading}
                                    loadingProps={{ w: "100px", h: "25px", mt: 2 }}
                                    valueProps={{
                                        fontSize: "15px",
                                        color: getColor(colorKeys.dimText, colorMode),
                                        fontWeight: "bold",
                                        textAlign: "center"
                                    }}
                                />
                                <LabelValuePair
                                    label={"Total Users"}
                                    value={corporateQuery.data?.totalUsers}
                                    headingProps={{
                                        fontSize: "15px",
                                        color: getColor(colorKeys.secondaryText, colorMode),
                                        fontWeight: "normal"
                                    }}
                                    isLoading={corporateQuery.isLoading}
                                    loadingProps={{ w: "100px", h: "25px", mt: 2 }}
                                    valueProps={{
                                        fontSize: "15px",
                                        color: getColor(colorKeys.dimText, colorMode),
                                        fontWeight: "bold",
                                        textAlign: "center"
                                    }}
                                />
                                <HStack spacing={3} mt={2}>
                                    <PrimaryButton fontSize="12px" size={"sm"} onClick={corporateFormDisclosure.onOpen}>Update Profile</PrimaryButton>
                                    <PrimaryButton fontSize="12px" size={"sm"} onClick={payPeriodDisclosure.onOpen}>Update Pay Period</PrimaryButton>
                                    {corporatePermissions?.additionalActions?.includes("UpdateCreditBalance") && <PrimaryButton
                                        fontSize="12px"
                                        size={"sm"}
                                        isDisabled={!corporateQuery.data?.stripeCustomerId}
                                        onClick={creditFormDisclosure.onOpen}
                                        title={!corporateQuery.data?.stripeCustomerId && "Disabled as stripe corporate id is not available"}
                                    >
                                        Update Credit Balance
                                    </PrimaryButton>}
                                    {corporatePermissions?.additionalActions?.includes("ResendVerificationEmail") ? (
                                        <PrimaryButton
                                            fontSize="12px"
                                            size={"sm"}
                                            onClick={onResendEmail}
                                            isLoading={resendEmailQuery.isLoading}
                                        >
                                            Resend Verification Email
                                        </PrimaryButton>
                                    ) : null}
                                </HStack>
                            </Flex>
                        </SimpleGrid>
                    </CardBody>
                </Card>
                <Box w="full">
                    <Card w="full" bg={getColor(colorKeys.tableBackground, colorMode)}>
                        <CardBody>
                            <CustomTabs
                                tabPanelProps={{ p: 0 }}
                                tabs={[
                                    {
                                        head: "Bookings",
                                        body: <CorporateBookings />
                                    },
                                    {
                                        head: "Users",
                                        body: <CorporateUsers />
                                    },
                                    {
                                        head: "Cards",
                                        body: <CorporateCards data={corporateQuery.data} />
                                    },
                                    {
                                        head: "Invoices",
                                        body: <CorporateInvoices />
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Box>

            </VStack >

            {corporatePermissions?.additionalActions?.includes("UpdateCreditBalance") && <UpdateCreditForm
                data={corporateQuery.data}
                disclosure={creditFormDisclosure}
            />}
            <PayPeriodForm disclosure={payPeriodDisclosure} />
            <CorporateForm disclosure={corporateFormDisclosure} data={corporateQuery.data} onSuccess={corporateQuery.refetch} />
        </Box >
    )
}

export default CorporateDetails   