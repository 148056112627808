import { Badge, Box, Flex, HStack, Icon, IconButton, Text, Tooltip, Wrap, WrapItem, useColorMode, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import CustomTable from '../../../../../components/BasicUI/CustomTable'
import APP_ICONS from '../../../../../config/constants/icons'
import { colorKeys, getColor } from '../../../../../config/constants/appColors'
import DeletePopover from '../../../../../components/BasicUI/Popovers/DeletePopover'
import { useDeleteZoneSurcharge, useZoneSurcharge } from '../../../../../config/query/zoneSurchargeQuery'
import ZoneSurchargeForm from './ZoneSurchargeForm'
import PrimaryButton from '../../../../../components/BasicUI/Buttons/PrimaryButton'
import { APP_MODULES } from '../../../../../config/constants'
import { formatDayName } from '../../../../../config/helpers/dateHelper'
import { REPEAT_TYPES } from '../../../../../config/constants/enums'
import moment from 'moment'
import { breakWithCaps } from '../../../../../config/helpers/stringHelper'

const ZoneSurcharge = ({ zoneId }) => {
    const { colorMode } = useColorMode()
    const disclosure = useDisclosure()
    const [selectedItem, setSelectedItem] = useState(null)

    const zoneSurchargeQuery = useZoneSurcharge(zoneId)
    const deleteZoneSurchargeQuery = useDeleteZoneSurcharge()

    const onAddNew = () => {
        setSelectedItem(null)
        disclosure.onOpen()
    }

    const onEditModal = (item) => {
        setSelectedItem(item)
        disclosure.onOpen()
    }

    const handleDelete = (id) => {
        deleteZoneSurchargeQuery
            .mutateAsync(id)
            .catch((error) => console.warn(error))
    }

    return (
        <Box>
            <Flex w="full" justify={"space-between"}>
                <Box fontSize="18px" fontWeight="bold">Surcharge</Box>
                <PrimaryButton
                    onClick={onAddNew}
                    leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
                    size="sm"
                >
                    Add New Surcharge
                </PrimaryButton>
            </Flex>
            <CustomTable
                permissionKey={APP_MODULES.Pricing}
                tableFor={"Add Zone Surcharge"}
                tableWrapperProps={{ padding: 0, h: "calc(100vh - 315px)" }}
                hideFilterBar={true}
                containerProps={{ mt: 4, h: "calc(100vh - 266px)" }}
                head={[
                    {
                        title: "Name",
                        extractor: "name",
                        align: "left",
                    },
                    {
                        title: "Surcharge",
                        extractor: "surcharge",
                        align: "center",
                        component: (item) => `${item?.surcharge}%`
                    },
                    {
                        title: "Repeat Type",
                        extractor: "repeatType",
                        align: "center",
                        component: (item) => <Text fontSize="14px">{breakWithCaps(item?.repeatType?.name)}</Text>,
                    },
                    {
                        title: "Applicable on",
                        extractor: "repeatType",
                        align: "center",
                        component: (item) => <Wrap spacing={1} justify={"center"}>
                            {item?.repeatType?.id === REPEAT_TYPES.DaysOfWeek
                                ? item?.daysOfWeek?.map((item, index) => (
                                    <WrapItem>
                                        <Tooltip label={item?.name} key={index}>
                                            <Badge fontSize="10px" variant="solid">
                                                {formatDayName(item?.name)}
                                            </Badge>
                                        </Tooltip>
                                    </WrapItem>
                                )) : <WrapItem>
                                    {item?.date}
                                </WrapItem>
                            }
                        </Wrap>,
                    },
                    {
                        title: "Time",
                        align: "center",
                        extractor: "lastModifiedOn",
                        component: (item) => <Wrap spacing={1} justify={"center"}>
                            <WrapItem>{moment(item.startTime, "HH:mm:ss.SSSSSSS").format("hh:mm A")}</WrapItem>
                            <WrapItem>{moment(item.endTime, "HH:mm:ss.SSSSSSS").format("hh:mm A")}</WrapItem>
                        </Wrap>
                    },
                    {
                        title: "Actions",
                        extractor: "actions",
                        align: "center",
                        component: (item) => (
                            <HStack justify={"center"}>
                                <IconButton
                                    onClick={() => onEditModal(item)}
                                    variant={"ghost"}
                                    size="sm"
                                    icon={
                                        <Icon
                                            as={APP_ICONS.EDIT}
                                            color={getColor(colorKeys.success, colorMode)}
                                        />
                                    }
                                >
                                    {"Edit"}
                                </IconButton>
                                <DeletePopover
                                    popoverProps={{ placement: "bottom-start" }}
                                    onConfirm={() => handleDelete(item.id)}
                                >
                                    <IconButton
                                        variant={"ghost"}
                                        size="sm"
                                        icon={
                                            <Icon
                                                as={APP_ICONS.BIN}
                                                color={getColor(colorKeys.danger, colorMode)}
                                            />
                                        }
                                    >
                                        {"delete"}
                                    </IconButton>
                                </DeletePopover>
                            </HStack>
                        ),
                    },
                ]}
                data={zoneSurchargeQuery?.data}
                loading={zoneSurchargeQuery?.isLoading}
                onRefresh={() => zoneSurchargeQuery.refetch()}
                isRefreshing={zoneSurchargeQuery?.isFetching}
            />

            <ZoneSurchargeForm data={selectedItem} disclosure={disclosure} zoneId={zoneId} />
        </Box>
    )
}

export default ZoneSurcharge