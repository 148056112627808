import React, { useState } from "react";
import { useParams } from "react-router";
import { formatAmount } from "../../../config/helpers/stringHelper";
import { Box, Text, useColorMode, useDisclosure } from "@chakra-ui/react";
import Chronology from "../../../components/BasicUI/DataBoxes/Chronology";
import StatusBadge from "../../../components/BasicUI/Badges/StatusBadge";
import { APP_MODULES } from "../../../config/constants";
import {
  useDirectInvoices,
  useGeneratePDF,
} from "../../../config/query/invoiceQuery";
import useDebounce from "../../../config/hooks/useDebounce";
import { makeInitialQueryObject } from "../../../config/helpers/queryHelper";
import CustomTable from "../../../components/BasicUI/CustomTable";
import BreakText from "../../../components/BasicUI/DataBoxes/BreakText";
import MenuSelect from "../../../components/Forms/MenuSelect";
import PaymentStatusForm from "./PaymentStatusForm";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { formatDate } from "../../../config/helpers/dateHelper";

const CorporateInvoices = () => {
  const { colorMode } = useColorMode();
  const { corporateId } = useParams();
  const [query, setQuery] = useState(makeInitialQueryObject());
  const debouncedQuery = useDebounce(query);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const payStatusDisclosure = useDisclosure();

  const generateInoice = useGeneratePDF();
  const directInvoiceQuery = useDirectInvoices({
    corporateId,
    params: debouncedQuery,
  });

  const onGenratePDF = (item) => {
    generateInoice
      .mutateAsync(item.id)
      .then((res) => window.open(res?.data))
      .catch((error) => console.warn(error));
  };

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  const handleAction = ({ action, item }) => {
    switch (action?.value) {
      case "generate_pdf":
        onGenratePDF(item);
        break;
      case "pay_status":
        setSelectedInvoice(item);
        payStatusDisclosure.onOpen();
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <CustomTable
        tableFor={"booking"}
        permissionKey={APP_MODULES.Corporate}
        tableWrapperProps={{
          padding: 0,
          h: "calc(100vh - 460px)",
        }}
        containerProps={{
          h: "calc(100vh - 410px)",
        }}
        head={[
          {
            title: "Start Period",
            extractor: "startPeriod",
            align: "left",
            isSortable: true,
          },
          {
            title: "End Period",
            extractor: "endPeriod",
            align: "left",
            isSortable: true,
          },
          {
            title: "Amount",
            extractor: "amount",
            isSortable: true,
            align: "center",
            component: (item) => (
              <Text fontSize="14px">{formatAmount(item?.amount)}</Text>
            ),
          },
          {
            title: "Paid On",
            extractor: "paidOn",
            align: "left",
            isSortable: true,
            component: (item) =>
              item?.paidOn ? formatDate(item?.paidOn) : "N/A",
          },
          {
            title: "Due Date",
            extractor: "dueDate",
            align: "left",
            isSortable: true,
          },
          {
            title: "Comments",
            extractor: "comments",
            align: "left",
            isSortable: true,
            component: (item) => <BreakText value={item?.comments} />,
          },
          {
            title: "Status",
            extractor: "status",
            component: (item) => (
              <StatusBadge activeStatus={1} value={item.paymentStatus} />
            ),
            isSortable: true,
            align: "center",
          },
          {
            title: "Last Modified",
            align: "center",
            extractor: "lastModifiedOn",
            isSortable: true,
            component: (item) => <Chronology data={item} />,
          },
          {
            title: "Actions",
            align: "center",
            component: (item) => (
              <MenuSelect
                value={
                  // <Icon
                  //   color={getColor(colorKeys.primaryText, colorMode)}
                  //   as={APP_ICONS.MORE_OPTIONS}
                  //   boxSize="5"
                  // />
                  "Options"
                }
                buttonProps={{
                  color: getColor(colorKeys.primaryText, colorMode),
                }}
                onChange={(action) => handleAction({ action, item })}
                options={[
                  { label: "Generate PDF", value: "generate_pdf" },
                  { label: "Update Payment Status", value: "pay_status" },
                ]}
              />
            ),
          },
        ]}
        data={directInvoiceQuery?.data?.data}
        loading={directInvoiceQuery?.isLoading}
        totalResults={directInvoiceQuery?.data?.meta?.totalCount}
        totalPages={directInvoiceQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={directInvoiceQuery.refetch}
        isRefreshing={directInvoiceQuery?.isFetching}
      />
      <PaymentStatusForm
        disclosure={payStatusDisclosure}
        data={selectedInvoice}
      />
    </Box>
  );
};

export default CorporateInvoices;
