import React from 'react'
import FormModal from '../../../../components/Forms/FormModal'
import { Box, Flex, Grid, GridItem, InputRightAddon, SimpleGrid, VStack, useColorMode } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { usePricingOptions, useUpdateZoneRate, useZoneRate } from '../../../../config/query/pricingQuery'
import FormInput from '../../../../components/Forms/FormInput'
import { useVehicleTypeOptions } from '../../../../config/query/transportQuery'
import FormCheckbox from '../../../../components/Forms/FormCheckbox'
import { DAYS_OF_WEEK } from '../../../../config/constants/enums'
import { formatTimeForInput } from '../../../../config/helpers/dateHelper'
import CircularLoading from '../../../../components/Skeletons/CircularLoading'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import { colorKeys, getColor } from '../../../../config/constants/appColors'
import { PERCENTAGE_REGEX } from '../../../../config/constants/regexConstants'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'
import ZoneSurcharge from './ZoneSurcharge'

const ZoneWiseRateForm = ({ disclosure, data }) => {
    const { colorMode } = useColorMode()
    const queryClient = useQueryClient()

    const vehicleTypeOptions = useVehicleTypeOptions()
    const pricingOptions = usePricingOptions()
    const zoneRateQuery = useZoneRate(data?.zoneId)
    const updateZoneRateQuery = useUpdateZoneRate()

    const findExistingRide = (vehicleTypeId) => {
        return zoneRateQuery?.data?.zoneAvailableRides
            ?.find((item) => {
                return item.vehicleTypeId === vehicleTypeId
            })
    }

    const findExistingPeakTime = (dayId) => {
        return zoneRateQuery?.data?.zonePeakTimings
            ?.find((item) => {
                return Number(item.dayId) === Number(dayId)
            })
    }

    const {
        handleSubmit,
        control,
        reset: resetForm,
        watch,
        formState: { errors },
    } = useForm({
        values: {
            zoneAvailableRides: vehicleTypeOptions.data?.data?.map((item) => {
                const existingRate = findExistingRide(item.id)
                return {
                    id: existingRate?.id,
                    vehicleTypeId: item.id,
                    name: item.name,
                    pricingId: existingRate?.pricingId || 0,
                    enabled: existingRate?.id !== undefined
                }
            }),
            zonePeakTimings: Object.keys(DAYS_OF_WEEK).map((item) => {
                const existingPeakTime = findExistingPeakTime(item)
                return {
                    id: existingPeakTime?.id,
                    dayId: item,
                    dayName: DAYS_OF_WEEK[item],
                    startTime: existingPeakTime?.startTime ? formatTimeForInput(existingPeakTime?.startTime) : "00:00",
                    endTime: existingPeakTime?.endTime ? formatTimeForInput(existingPeakTime?.endTime) : "00:00",
                    enabled: existingPeakTime?.id !== undefined
                }
            }),
            zoneTax: zoneRateQuery?.data?.zoneTax,
            zonePeakTimeSurcharge: zoneRateQuery?.data?.zonePeakTimeSurcharge
        }
    })

    const fields = watch()

    const onSubmit = (values) => {
        const body = {
            zoneId: data?.zoneId,
            zoneTax: Number(values?.zoneTax),
            zonePeakTimeSurcharge: Number(values?.zonePeakTimeSurcharge),
            zoneAvailableRides: values?.zoneAvailableRides?.filter((item) => (item.enabled)).map((item) => ({
                vehicleTypeId: item.vehicleTypeId,
                pricingId: Number(item.pricingId)
            })),
            zonePeakTimings: values?.zonePeakTimings?.filter((item) => (item.enabled)).map((item) => ({
                dayId: item.dayId,
                startTime: item.startTime,
                endTime: item.endTime
            }))
        }
        updateZoneRateQuery.mutateAsync(body).then(() => {
            queryClient.invalidateQueries(["zoneRates"])
            resetForm()
            disclosure.onClose()
        })
    }

    return (
        <FormModal
            title={`${data?.zoneName} - Zone Rate`}
            disclosure={disclosure}
            isSubmitting={updateZoneRateQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"100rem"}
            onCloseFn={() => resetForm()}
        >
            <VStack align="stretch" spacing={5}>
                <FormFieldsSkeleton count={1} loading={zoneRateQuery.isFetching} />
                {!zoneRateQuery.isFetching && <SimpleGrid spacing={5} columns={{ base: 1, md: 3 }}>
                    <FormInput
                        label={"Zone Tax %"}
                        control={control}
                        errors={errors}
                        id="zoneTax"
                        required={true}
                        placeholder="Enter zone tax"
                        inputProps={{ size: "sm" }}
                        type={"number"}
                        rules={{
                            pattern: {
                                value: PERCENTAGE_REGEX,
                                message: "Zone rate must be a number between 0 and 100"
                            },
                        }}
                        size="sm"
                        rightAddon={<InputRightAddon children="%" />}
                    />
                </SimpleGrid>}

                <Grid templateColumns="repeat(20, 1fr)" gap={6}>
                    <GridItem border={`1px solid ${getColor(colorKeys.tableFilterBorder, colorMode)}`} p="5" colSpan={{ base: 20, md: 7 }}>
                        <VStack align="stretch" spacing={5}>
                            <Flex w="full" justify={"space-between"}>
                                <Box fontSize="14px" fontWeight="bold">Available Rides</Box>
                                <Box fontSize="14px" fontWeight="bold">Default Rates</Box>
                            </Flex>

                            <CircularLoading loading={zoneRateQuery.isFetching} />
                            {!zoneRateQuery.isFetching && fields?.zoneAvailableRides?.map((item, index) =>
                                <Flex flexDir={{ base: "column", lg: "row" }} key={index} w="full">
                                    <FormCheckbox
                                        hideLabel={true}
                                        control={control}
                                        id={`zoneAvailableRides[${index}].enabled`}
                                        options={[{ label: item?.name, value: item?.vehicleTypeId }]}
                                        optionLabelProps={{ fontSize: "14px" }}
                                        defaultChecked={item?.enabled}
                                        containerProps={{ m: "auto" }}
                                    />

                                    <FormSearchSelect
                                        control={control}
                                        errors={errors}
                                        options={makeSelectList(pricingOptions.data?.data)}
                                        hideLabel={true}
                                        id={`zoneAvailableRides[${index}].pricingId`}
                                        placeholder="Enter rate"
                                        inputProps={{ size: "sm", isDisabled: !item?.enabled }}
                                        rules={{
                                            pattern: {
                                                value: PERCENTAGE_REGEX,
                                                message: "Zone rate must be a number between 0 and 100"
                                            },
                                        }}
                                        customError={errors?.zoneAvailableRides?.[index]?.pricingId?.message}
                                    />
                                </Flex>
                            )}
                        </VStack>
                    </GridItem>
                    <GridItem border={`1px solid ${getColor(colorKeys.tableFilterBorder, colorMode)}`} p="5" colSpan={{ base: 20, md: 13 }}>
                        <VStack align="stretch" spacing={5}>
                            <ZoneSurcharge zoneId={data?.zoneId} />
                        </VStack>
                    </GridItem>
                </Grid>

            </VStack>
        </FormModal>
    )
}

export default ZoneWiseRateForm