import { Button, Flex, FormLabel, Grid, GridItem, VStack, useColorMode } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import FormModal from '../../../../components/Forms/FormModal'
import { useForm } from 'react-hook-form'
import FormInput from '../../../../components/Forms/FormInput'
import IMAGES from '../../../../config/constants/images'
import { colorKeys, getColor } from '../../../../config/constants/appColors'
import FormFileUpload from '../../../../components/Forms/FormFileUpload'
import { useCreateVehicleType, useUpdateVehicleType, useUpdateVehicleTypeImage, useVehicleType } from '../../../../config/query/transportQuery'
import { VEHICLE_CLASS } from '../../../../config/constants/enums'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import { prepareForm, preparePayload } from '../../../../config/helpers/formHelpers/vehicleTypeHelper'

const VehicleTypeForm = ({ disclosure, data, onSuccess }) => {

    const { colorMode } = useColorMode()

    const vehicleTypeQuery = useVehicleType(data?.id)
    const createVehileType = useCreateVehicleType()
    const updateVehicleTypeImage = useUpdateVehicleTypeImage()
    const updateVehicleType = useUpdateVehicleType()

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: { errors },
    } = useForm({
        values: prepareForm({
            formValues: data,
            serverValues: vehicleTypeQuery.data
        })
    })

    const [fileData, setFileData] = useState({
        binary: null,
        url: null,
    })


    const onSubmit = (values) => {
        let formMutate = data?.id ? updateVehicleType.mutateAsync : createVehileType.mutateAsync
        formMutate(preparePayload(values, data?.id))
            .then(res => {
                disclosure.onClose()
                if (fileData?.binary) {
                    updateVehicleTypeImage.mutateAsync({
                        Id: res?.id,
                        VehicleImage: fileData?.binary
                    }).then(() => onSuccess && onSuccess())
                }
                else {
                    onSuccess && onSuccess()
                }
                resetForm()
            })
            .catch(err => {
                console.warn("Error while creating vehicle class:", err)
            })
    }


    useEffect(() => {
        if (data?.id && disclosure?.isOpen) {
            setFileData({
                ...fileData,
                url: vehicleTypeQuery.data?.imageUrl
            })
        } else {
            resetForm()
            setFileData({
                binary: null,
                url: null,
            })
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id, vehicleTypeQuery.data?.imageUrl, disclosure?.isOpen])

    const onChangeImage = (file) => {
        setFileData({
            binary: file,
            url: URL.createObjectURL(file)
        })
    }

    return (
        <FormModal
            title={data ? "Edit Service Type" : "Add Service Type"}
            disclosure={disclosure}
            isSubmitting={createVehileType.isLoading || updateVehicleType.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"50rem"}
            onCloseFn={() => {
                resetForm()
                setFileData({
                    binary: null,
                    url: null,
                })
            }}
        >
            <Grid templateColumns="repeat(2, 1fr)" gap={5}>
                <GridItem colSpan={{ base: 3, md: 1 }}>
                    <FormFieldsSkeleton columns={1} count={3} loading={vehicleTypeQuery.isFetching} />
                    {!vehicleTypeQuery.isFetching &&
                        <VStack spacing={5}>

                            <FormInput
                                label={"Vehicle Name"}
                                control={control}
                                errors={errors}
                                id="name"
                                required={true}
                                placeholder="Enter vehicle name"
                                inputProps={{ size: "sm" }}
                            />

                            <FormSearchSelect
                                id="vehicleClass"
                                label={"Vehicle Class"}
                                placeholder={'Select vehicle class'}
                                required={true}
                                errors={errors}
                                control={control}
                                options={makeSelectList(VEHICLE_CLASS)}
                                inputProps={{ size: "sm" }}
                            />

                            <FormInput
                                label={"Seating Capacity"}
                                type={"number"}
                                control={control}
                                errors={errors}
                                id="seatingCapacity"
                                placeholder="Enter seating capacity"
                                inputProps={{ size: "sm" }}
                                required={true}
                                rules={{
                                    valueAsNumber: "Please enter a valid number",
                                    min: {
                                        value: 1,
                                        message: "Seating capacity must be greater than 0",
                                    },
                                    max: {
                                        value: 100,
                                        message: "Seating capacity must be less than 100"
                                    },
                                    validate: {
                                        positive: (value) => Number.isInteger(Number(value)) || "Seating capacity must be a whole number",
                                    },
                                }}
                            />
                        </VStack>
                    }
                </GridItem>

                <GridItem rowSpan={2}>
                    <FormLabel fontSize="13px">Image:</FormLabel>
                    <Flex justify={"center"} align={"end"} bg={`url(${fileData?.url || IMAGES.DUMMY_IMAGE})`} backgroundPosition={"center"} backgroundSize={"cover"} w="full" h="calc(100% - 50px)">
                        <FormFileUpload
                            id={"profilePicture"}
                            onChange={onChangeImage}
                            component={<Button
                                mb="3"
                                bg={getColor(colorKeys.white, colorMode)}
                                size="sm"
                                color={getColor(colorKeys.lightBlue, colorMode)}
                                fontWeight={"normal"}
                                border={`1px solid ${getColor(colorKeys.lightBlue, colorMode)}`}
                            >
                                Browse Image
                            </Button>}
                            componentProps={{ variant: "ghost" }}
                            fileType={"IMAGE"}
                        />

                    </Flex>
                </GridItem>
            </Grid>

        </FormModal>
    )
}

export default VehicleTypeForm