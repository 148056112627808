import React, { useState } from 'react'
import { Box, SimpleGrid, Text, Flex, useColorMode, Card, CardBody, Avatar, VStack, Select, Grid, GridItem } from "@chakra-ui/react"
import moment from 'moment'
import { useSelector } from 'react-redux'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import APP_ICONS from '../../../config/constants/icons'
import { useZoneOptions } from '../../../config/query/zoneQuery'
import { makeSelectList } from '../../../config/helpers/selectListHelper'
import { useDashboardStats } from '../../../config/query/dashboardQuery'
import SummaryBox from './SummaryBox'
import LatestBookings from './LatestBookings'
import FinishedBookings from './FinishedBookings'
import OverallRatings from './OverallRatings'
import EarningChart from './EarningChart'
import UserCounts from './UserCounts'
import { formatAmount } from '../../../config/helpers/stringHelper'
import useDebounce from '../../../config/hooks/useDebounce'
import { View_MODE, View_MODES } from '../../../config/constants/enums'
import CustomDateInput from '../../../components/Forms/CustomDateInput'

const Dashboard = () => {
  const { colorMode } = useColorMode()
  const user = useSelector((state) => state.user?.user)
  const tenant = useSelector((state) => state.tenant?.tenant)

  const [query, setQuery] = useState({
    FromDateTime: moment().subtract(1, "day").toISOString(),
    TillDateTime: moment().toISOString(),
    ZoneId: null,
    ViewMode: View_MODES.Hourly
  })
  const debouncedQuery = useDebounce(query)

  const zoneOptionsQuery = useZoneOptions()
  const dashboardStatsQuery = useDashboardStats(debouncedQuery)


  const getValue = (parent, child, fallback) => {
    if (parent && child) {
      return dashboardStatsQuery?.data?.[parent]?.[child] || fallback
    }
  }

  const summaryData = [
    {
      icon: APP_ICONS.COPY,
      title: "Profit",
      value: formatAmount(getValue("profit", "revenue")),
      dailyChange: `${getValue("profit", "dailyChange", 0)?.toFixed(2)}%`,
      dailyChangeValue: getValue("profit", "dailyChange", 0),
      dailyChangeLabel: "Daily Charge",
      bottomValue: `${formatAmount(getValue("profit", "amount"))} Revenue`,
    },
    {
      icon: APP_ICONS.ARCHIVE,
      title: "Booking Request",
      value: `${getValue("bookingsRequested", "totalRequests", 0)}`,
      dailyChange: `${getValue("bookingsRequested", "dailyChange", 0)?.toFixed(2)}%`,
      dailyChangeValue: getValue("bookingsRequested", "dailyChange", 0),
      dailyChangeLabel: "Daily Charge",
      bottomValue: `${getValue("bookingsRequested", "acceptance", 0)?.toFixed(2)}% Acceptance`,
    },
    {
      icon: APP_ICONS.CATEGORIES_TAG,
      title: "Complete Booking",
      value: `${getValue("completedBookings", "totalCompletedBookings", 0)}`,
      dailyChange: `${getValue("completedBookings", "dailyChange", 0)?.toFixed(2)}%`,
      dailyChangeValue: getValue("completedBookings", "dailyChange", 0),
      dailyChangeLabel: "Daily Change",
      bottomValue: `${getValue("completedBookings", "satisfaction", 0)?.toFixed(2)}% Satisfaction`,
    },
    {
      icon: APP_ICONS.PERSON_PLUS,
      title: "New Customers",
      value: `${getValue("newCustomers", "totalNewCustomers", 0)}`,
      dailyChange: `${getValue("newCustomers", "dailyChange", 0)?.toFixed(2)}%`,
      dailyChangeValue: getValue("newCustomers", "dailyChange", 0),
      dailyChangeLabel: "Daily Charge",
      bottomValue: `${getValue("newCustomers", "totalCustomers", 0)} customer count`,
    },

  ]

  const onQueryChange = ({ name, value }) => {
    setQuery({ ...query, [name]: value })
  }

  return (
    <Box>
      <Box w="full">

        <Card mb={5} bg={getColor(colorKeys.tableBackground, colorMode)}>
          <CardBody>
            <SimpleGrid spacing={5} columns={{ base: 1, md: 2, lg: 2, xl: 4 }} justifyContent={"space-between"}>
              <Flex align="center">
                <Avatar src={user?.profilePictureUrl || user?.profilePicture} name={user?.name} size="md" w="53px" h="53px" />
                <Box ml="3">
                  <Text fontSize="13px" color={getColor(colorKeys.secondaryText, colorMode)}>Welcome to {tenant?.name} dashboard</Text>
                  <Text fontWeight={"bold"} fontSize="16px">{user?.name}</Text>
                  <Text fontSize={"13px"} color={getColor(colorKeys.primaryText, colorMode)}>Admin</Text>
                </Box>
              </Flex>

              <UserCounts />

              <VStack>
                <Flex w="full" align="center">
                  <Text minW="60px" fontSize="13px" color={getColor(colorKeys.secondaryText, colorMode)}>From:</Text>
                  <CustomDateInput
                    containerProps={{ ml: 2, maxW: "210px" }}
                    groupProps={{ size: "sm" }}
                    value={query.FromDateTime}
                    maxDate={query.TillDateTime ? new Date(moment(query.TillDateTime).format("YYYY-MM-DD")) : new Date()}
                    onChange={(e) => onQueryChange({ name: "FromDateTime", value: moment(e).toISOString() })}
                    inputProps={{ size: "sm" }}
                    placeholder={"From Date"}
                  />
                </Flex>
                <Flex w="full" align="center">
                  <Text minW="60px" fontSize="13px" color={getColor(colorKeys.secondaryText, colorMode)}>To:</Text>
                  <CustomDateInput
                    containerProps={{ ml: 2, maxW: "210px" }}
                    groupProps={{ size: "sm" }}
                    value={query.TillDateTime}
                    maxDate={new Date()}
                    minDate={query.FromDateTime ? new Date(moment(query.FromDateTime).format("YYYY-MM-DD")) : new Date()}
                    onChange={(e) => onQueryChange({ name: "TillDateTime", value: moment(e).toISOString() })}
                    inputProps={{ size: "sm" }}
                    placeholder={"To Date"}
                  />
                </Flex>
              </VStack>

              <VStack>
                <Flex w="full" align="center">
                  <Text fontSize="13px" color={getColor(colorKeys.secondaryText, colorMode)}>Zone:</Text>
                  <Select
                    ml={2}
                    maxW="210px"
                    size="sm"
                    value={query.date}
                    onChange={(e) => {
                      if (e.target.value === "All") {
                        onQueryChange({ name: "ZoneId", value: undefined })
                      }
                      else {
                        onQueryChange({ name: "ZoneId", value: e.target.value })
                      }
                    }}
                  >
                    <option value={null}>All</option>
                    {makeSelectList(zoneOptionsQuery.data)?.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                  </Select>
                </Flex>
                <Flex w="full" align="center">
                  <Text fontSize="13px" color={getColor(colorKeys.secondaryText, colorMode)}>Filter By:</Text>
                  <Select
                    ml={2}
                    maxW="210px"
                    size="sm"
                    value={query.date}
                    onChange={(e) => {
                      onQueryChange({ name: "ViewMode", value: e.target.value })
                    }}
                  >
                    {makeSelectList(View_MODE)?.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                  </Select>
                </Flex>
              </VStack>

            </SimpleGrid>
          </CardBody>
        </Card>
      </Box>


      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={5}>
        {summaryData?.map((item, i) =>
          <SummaryBox
            key={i}
            item={item}
            isLoading={dashboardStatsQuery.isLoading || dashboardStatsQuery.isFetching || dashboardStatsQuery.isRefetching}
          />)
        }
      </SimpleGrid>

      <Box mt={5}>
        <Grid templateColumns={{ base: "repeat(8, 1fr)", xl: "repeat(16, 1fr)" }} gap={6}>

          <GridItem h="auto" colSpan={{ base: 8, xl: 8 }}>
            <EarningChart query={debouncedQuery} />
          </GridItem>

          <GridItem h="auto" colSpan={{ base: 8, md: 4, xl: 4 }}>
            <OverallRatings query={debouncedQuery} />
          </GridItem>

          <GridItem h="auto" colSpan={{ base: 8, md: 4, xl: 4 }}>
            <FinishedBookings query={debouncedQuery} />
          </GridItem>

        </Grid>
      </Box>

      <LatestBookings />

    </Box>
  )
}

export default Dashboard