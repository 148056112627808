import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { getPageTitle, singularize } from '../../../config/helpers/stringHelper'
import { useGetTenantDetails } from '../../../config/query/preferencesQuery'
import { updateTenant } from '../../../config/redux/slices/tenantSlice'
import { changeFavicon } from '../../../config/helpers/brandingUtil'


const AuthLayout = () => {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.user?.token)
    const tenant = useSelector((state) => state?.tenant?.tenant)
    const { pathname } = useLocation()
    const splitPath = pathname.split("/")
    const tenantDetails = useGetTenantDetails()

    useEffect(() => {
        tenantDetails
            .mutateAsync()
            .then(res => {
                localStorage.setItem("tenant", res.id)
                localStorage.setItem("favIconUrl", res.favIconUrl)
                changeFavicon(res.favIconUrl)
                dispatch(updateTenant(res))
            })
            .catch(err => console.warn(err))
    }, [])

    useEffect(() => {
        let title = splitPath[splitPath.length - 1]
        if (!isNaN(splitPath[splitPath.length - 1])) {
            title = `${singularize(splitPath[splitPath.length - 2].replace("-", " "))} Details`
        }
        document.title = getPageTitle(title, tenant?.name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    if (token && !pathname.includes("impersonate")) {
        return <Navigate to="/admin" replace={true} />
    }

    return <Outlet />

}

export default React.memo(AuthLayout)