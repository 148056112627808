import React, { useState } from "react";
import CustomTable from "../../../components/BasicUI/CustomTable";
import TableInfoPopover from "../../../components/BasicUI/Popovers/TableInfoPopover";
import LabelValuePair from "../../../components/BasicUI/LabelValuePair";
import StatusBadge from "../../../components/BasicUI/Badges/StatusBadge";
import { formatDate, formatDateTime } from "../../../config/helpers/dateHelper";
import { Box, useDisclosure } from "@chakra-ui/react";
import TableHeaderOptions from "../../../components/BasicUI/CustomTable/TableHeaderOptions";
import APP_ICONS from "../../../config/constants/icons";
import PromoCodeForm from "./PromoCodeForm";
import {
  useDeletePromoCode,
  usePromoCodes,
  useUpdatePromoCodeStatus,
} from "../../../config/query/promoCodeQuery";
import BreakText from "../../../components/BasicUI/DataBoxes/BreakText";
import { makeInitialQueryObject } from "../../../config/helpers/queryHelper";
import Chronology from "../../../components/BasicUI/DataBoxes/Chronology";
import PromoCodeDetails from "./PromoCodeDetails";
import StatusSwitch from "../../../components/BasicUI/DataBoxes/StatusSwitch";
import { formatAmount } from "../../../config/helpers/stringHelper";
import { APP_MODULES } from "../../../config/constants";
import CopyText from "../../../components/BasicUI/DataBoxes/CopyText";

const PromoCodes = () => {
  const [selectedPromoCode, setSelectedPromoCode] = useState(null);
  const formDisclosure = useDisclosure();
  const viewDisclosure = useDisclosure();

  const [query, setQuery] = useState(makeInitialQueryObject());

  const promoCodeQuery = usePromoCodes(query);
  const deletePromoCodeQuery = useDeletePromoCode();
  const promoCodeStatusQuery = useUpdatePromoCodeStatus();

  const onEditModal = (item) => {
    setSelectedPromoCode(item);
    formDisclosure.onOpen();
  };

  const onView = (item) => {
    setSelectedPromoCode(item);
    viewDisclosure.onOpen();
  };

  const handleDelete = (id) => {
    deletePromoCodeQuery
      .mutateAsync(id)
      .then(() => promoCodeQuery.refetch())
      .catch((error) => console.warn(error));
  };

  const handleStatusChange = (status, id) => {
    promoCodeStatusQuery.mutate({ status: Number(status), id });
  };

  return (
    <Box>
      <TableHeaderOptions
        title={"Promo Codes"}
        actionText={"Add Promo Code"}
        action={() => {
          setSelectedPromoCode(null);
          formDisclosure.onOpen();
        }}
        icon={APP_ICONS.ADD}
        permissionKey={APP_MODULES.PromoCode}
      />
      <CustomTable
        permissionKey={APP_MODULES.PromoCode}
        tableFor={"promo code"}
        searchPlaceholder="Search promo codes"
        head={[
          {
            title: "Title",
            extractor: "title",
            align: "left",
            isSortable: true,
            component: (item) => (
              <TableInfoPopover
                data={item}
                triggerText={item.title}
                titleKey="title"
                triggerOnClick={() => onView(item)}
              >
                <LabelValuePair
                  label="Status"
                  value={true}
                  valueComponent={<StatusBadge value={item.status} />}
                />
                <LabelValuePair
                  label="Created"
                  value={formatDateTime(item.createdOn)}
                />
                <LabelValuePair
                  label="Last Updated"
                  value={formatDateTime(item.lastModifiedOn)}
                />
              </TableInfoPopover>
            ),
          },
          {
            title: "Code",
            extractor: "code",
            align: "center",
            isSortable: true,
            component: (item) => <CopyText text={item.code} />
          },
          {
            title: "Discount",
            extractor: "discountPercentage",
            align: "center",
            isSortable: true,
            component: (item) => (
              <BreakText
                value={
                  item.discountPercentage
                    ? `${item.discountPercentage}%`
                    : formatAmount(item.discountAmount)
                }
              />
            ),
          },
          {
            title: "No of Consumers",
            extractor: "numberOfUsers",
            align: "center",
            isSortable: true,
            fallBackText: "0",
          },
          {
            title: "Expiry Date",
            extractor: "expiryDate",
            align: "center",
            isSortable: true,
            component: (item) => formatDate(item.expiryDate),
          },
          {
            title: "Is Expired",
            extractor: "expiryStatus",
            component: (item) => (
              <StatusBadge value={item.expiryStatus} activeStatus={0} />
            ),
            isSortable: true,
          },
          {
            title: "Status",
            extractor: "status",
            isSortable: true,
            align: "center",
            component: (item) => (
              <StatusSwitch
                key={item.id}
                value={item.status?.id}
                onChange={(e) => handleStatusChange(e.target.checked, item.id)}
              />
            ),
          },
          {
            title: "Last Modified",
            align: "center",
            extractor: "lastModifiedOn",
            isSortable: true,
            component: (item) => <Chronology data={item} />,
          },
          { title: "Actions", extractor: "actions", align: "center" },
        ]}
        data={promoCodeQuery?.data?.data}
        loading={promoCodeQuery?.isLoading}
        totalResults={promoCodeQuery?.data?.meta?.totalCount}
        totalPages={promoCodeQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={(updatedQuery) =>
          setQuery({ ...query, ...updatedQuery })
        }
        query={query}
        onRefresh={() => promoCodeQuery.refetch()}
        isRefreshing={promoCodeQuery?.isFetching}
        isDeleting={deletePromoCodeQuery?.isLoading}
        onDelete={handleDelete}
        onEdit={(item) => onEditModal(item)}
        onView={(item) => onView(item)}
      />
      <PromoCodeForm data={selectedPromoCode} disclosure={formDisclosure} />
      <PromoCodeDetails
        id={selectedPromoCode?.id}
        disclosure={viewDisclosure}
      />
    </Box>
  );
};

export default PromoCodes;
