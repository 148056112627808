import { Button, Collapse, useDisclosure } from '@chakra-ui/react'
import React from 'react'

const CollapsableBox = ({ children, maxLength = 200 }) => {
    const { isOpen, onToggle } = useDisclosure()
    return (
        <>
            <Collapse startingHeight={20} in={isOpen}>
                {children}
            </Collapse>
            {children.length > maxLength && (
                <Button variant={"link"} size='sm' onClick={onToggle} mt='1'>
                    Show {isOpen ? 'Less' : 'More'}
                </Button>
            )}
        </>
    )
}

export default CollapsableBox

