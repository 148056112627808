import { Divider, VStack } from "@chakra-ui/react";
import React, { useRef } from "react";
import useChatInbox from "../../../../config/containers/chats/useChatInbox";
import { CHAT_TYPES } from "../../../../config/constants/enums";
import ChatItem from "../ChatItem";
import CircularLoading from "../../../../components/Skeletons/CircularLoading";
import NoResults from "../../../../components/SVGComponents/NoResults";
import ChatForm from "../ChatForm";
import ChatListSkeleton from "../../../../components/Skeletons/ChatListSkeleton";

const GroupsInbox = ({ query, chatFormDisclosure }) => {
  const messageListRef = useRef(null);

  const { chats, isFetchingNextPage, handleScroll, refreshInbox, isLoading } =
    useChatInbox({
      messageListRef,
      chatType: CHAT_TYPES.Custom,
      query,
    });

  if (isLoading) {
    return <ChatListSkeleton isLoading={isLoading} />;
  }

  return (
    <VStack
      ref={messageListRef}
      onScroll={handleScroll}
      divider={<Divider />}
      spacing={0}
      h="calc(100vh - 175px)"
      overflow="auto"
    >
      {chats?.map((item, index) => (
        <ChatItem chatItem={item} key={index} to="/admin/chats/groups" />
      ))}
      {chats?.length === 0 && !isFetchingNextPage && <NoResults />}
      <CircularLoading loading={isFetchingNextPage} />

      <ChatForm disclosure={chatFormDisclosure} onSuccess={refreshInbox} />
    </VStack>
  );
};

export default GroupsInbox;
