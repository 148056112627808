import React, { useEffect, useState } from "react";
import { Box, HStack, Icon, IconButton, useColorMode } from "@chakra-ui/react";
import CustomTable from "../../../../components/BasicUI/CustomTable";
import { makeInitialQueryObject } from "../../../../config/helpers/queryHelper";
import MakeForm from "./MakeForm";
import {
  useMakes,
  useDeleteMake,
} from "../../../../config/query/makeQuery";
import APP_ICONS from "../../../../config/constants/icons";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import DeletePopover from "../../../../components/BasicUI/Popovers/DeletePopover";
import Chronology from "../../../../components/BasicUI/DataBoxes/Chronology";
import useDebounce from "../../../../config/hooks/useDebounce";
import { APP_MODULES } from "../../../../config/constants";

const Makes = ({
  selectedRow,
  setSelectedRow,
  disclosure,
  setIsRefreshing,
  isRefreshing,
}) => {
  const { colorMode } = useColorMode();
  const [query, setQuery] = useState(makeInitialQueryObject());
  const debouncedQuery = useDebounce(query);

  const makeQuery = useMakes(debouncedQuery);
  const deleteMakeQuery = useDeleteMake();

  const onEditModal = (item) => {
    setSelectedRow(item);
    disclosure.onOpen();
  };

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  const handleDelete = (id) => {
    deleteMakeQuery
      .mutateAsync(id)
      .then(() => makeQuery.refetch())
      .catch((error) => console.warn(error));
  };

  useEffect(() => {
    if (isRefreshing) makeQuery.refetch().then(() => setIsRefreshing(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing]);

  return (
    <Box>
      <CustomTable
        permissionKey={APP_MODULES.VehicleManagement}
        tableFor={"Add Make"}
        tableWrapperProps={{ padding: 0, h: "calc(100vh - 315px)" }}
        hideFilterBar={true}
        containerProps={{ mt: 4, h: "calc(100vh - 266px)" }}
        head={[
          {
            title: "Name",
            extractor: "name",
            align: "left",
          },
          { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
          {
            title: "Actions",
            extractor: "actions",
            align: "center",
            component: (item) => (
              <HStack justify={"center"}>
                <IconButton
                  onClick={() => onEditModal(item)}
                  variant={"ghost"}
                  size="sm"
                  icon={
                    <Icon
                      as={APP_ICONS.EDIT}
                      color={getColor(colorKeys.success, colorMode)}
                    />
                  }
                >
                  {"Edit"}
                </IconButton>
                <DeletePopover
                  // type={tableFor}
                  popoverProps={{ placement: "bottom-start" }}
                  // key={actionIndex}
                  onConfirm={() => handleDelete(item.id)}
                >
                  <IconButton
                    variant={"ghost"}
                    size="sm"
                    icon={
                      <Icon
                        as={APP_ICONS.BIN}
                        color={getColor(colorKeys.danger, colorMode)}
                      />
                    }
                  >
                    {"delete"}
                  </IconButton>
                </DeletePopover>
              </HStack>
            ),
          },
        ]}
        data={makeQuery?.data?.data}
        loading={makeQuery?.isLoading}
        totalResults={makeQuery?.data?.meta?.totalCount}
        totalPages={makeQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => makeQuery.refetch()}
        isRefreshing={makeQuery?.isFetching}
      />

      <MakeForm data={selectedRow} disclosure={disclosure} />
    </Box>
  );
};

export default Makes;
