export const TIME_ZONE_ARRAY = [
    { label: "GMT-01:00 - Cape Verde", value: -1 },
    { label: "GMT-02:00 - South Georgia", value: -2 },
    { label: "GMT-03:00 - Buenos Aires", value: -3 },
    { label: "GMT-04:00 - Santiago", value: -4 },
    { label: "GMT-05:00 - New York", value: -5 },
    { label: "GMT-06:00 - Mexico City", value: -6 },
    { label: "GMT-07:00 - Denver", value: -7 },
    { label: "GMT-08:00 - Los Angeles", value: -8 },
    { label: "GMT-09:00 - Alaska", value: -9 },
    { label: "GMT-10:00 - Hawaii", value: -10 },
    { label: "GMT-11:00 - American Samoa", value: -11 },
    { label: "GMT-12:00 - Baker Island", value: -12 },
    { label: "GMT+00:00 - London", value: +0 },
    { label: "GMT+01:00 - Berlin", value: +1 },
    { label: "GMT+02:00 - Cairo", value: +2 },
    { label: "GMT+03:00 - Moscow", value: +3 },
    { label: "GMT+04:00 - Dubai", value: +4 },
    { label: "GMT+05:00 - Karachi", value: +5 },
    { label: "GMT+06:00 - Dhaka", value: +6 },
    { label: "GMT+07:00 - Bangkok", value: +7 },
    { label: "GMT+08:00 - Beijing", value: +8 },
    { label: "GMT+09:00 - Tokyo", value: +9 },
    { label: "GMT+10:00 - Sydney", value: +10 },
    { label: "GMT+11:00 - Solomon Is.", value: +11 },
    { label: "GMT+12:00 - Auckland", value: +1 }
]

export const TIME_ZONES = {
    "-12": "AoE",    // Anywhere on Earth
    "-11": "NUT",    // Niue Time
    "-10": "HST",    // Hawaii Standard Time
    "-9": "AKST",    // Alaska Standard Time
    "-8": "PST",     // Pacific Standard Time
    "-7": "MST",     // Mountain Standard Time
    "-6": "CST",     // Central Standard Time
    "-5": "EST",     // Eastern Standard Time
    "-4": "AST",     // Atlantic Standard Time
    "-3": "ART",     // Argentina Time
    "-2": "GST",     // South Georgia Time
    "-1": "CVT",     // Cape Verde Time
    "0": "GMT",      // Greenwich Mean Time
    "1": "CET",      // Central European Time
    "2": "EET",      // Eastern European Time
    "3": "MSK",      // Moscow Time
    "4": "GST",      // Gulf Standard Time
    "5": "PKT",      // Pakistan Standard Time
    "6": "BST",      // Bangladesh Standard Time
    "7": "WIB",      // Western Indonesian Time
    "8": "CST",      // China Standard Time
    "9": "JST",      // Japan Standard Time
    "10": "AEST",    // Australian Eastern Standard Time
    "11": "AEDT",    // Australian Eastern Daylight Time
    "12": "NZST",    // New Zealand Standard Time
    "13": "NZDT",    // New Zealand Daylight Time
    "14": "LINT"     // Line Islands Time
};