import {
    useQuery,
    useMutation,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Patch, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS, { DEFAULT_SEARCH_KEY } from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { CONTENT_TYPE } from "../constants";

export const useSubAdmins = (params) => {
    const token = useSelector((state) => state.user?.token)
    params = appendQueryParams({ ...params, [DEFAULT_SEARCH_KEY]: API_CONSTANTS.SUB_ADMINS.searchableKeys }, false)
    return useQuery({
        queryKey: ["sub-admins", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.SUB_ADMINS.base}?${params}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useSubAdminOptions = (query) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["sub-admin-lookup", query],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.SUB_ADMINS.lookup.replace(":query", query || ""),
                token,
                toastError: true,
            })
            return data
        },
        enabled: Boolean(query)
    })
}

export const useSubAdmin = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["sub-admins", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.SUB_ADMINS.base}/${id}`,
                token,
                toastError: true,
            })
            return data
        },
        enabled: Boolean(id),
    })
}

export const useCreateSubAdmin = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Post({
                path: API_CONSTANTS.SUB_ADMINS.base,
                token,
                body: prepareData(body, API_CONSTANTS.SUB_ADMINS.dataKeys),
                toastError: true,
                toastMessage: true,
            })
            return data
        },
        mutationKey: ['sub-admins'],
    })
}

export const useUpdateSubAdmin = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Put({
                path: API_CONSTANTS.SUB_ADMINS.base,
                token,
                body: prepareData(body, API_CONSTANTS.SUB_ADMINS.dataKeys),
                toastError: true,
                toastMessage: true,
            })
            return data
        },
        mutationKey: ['sub-admins'],
    })
}

export const useUpadetAdminImage = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Patch({
                path: API_CONSTANTS.SUB_ADMINS.updateImage,
                token,
                body,
                toastError: true,
                contentType: CONTENT_TYPE.FORM_DATA
            })
            return data
        },
        mutationKey: ['sub-admins'],
    })
}

export const useUpdateAdminStatus = () => {
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationKey: ["admin-status"],
        mutationFn: async (body) => {
            const response = await Patch({
                path: `${API_CONSTANTS.SUB_ADMINS.updateStatus.replace(
                    ":id",
                    body.id
                )}?status=${body.status}`,
                token,
                toastError: true,
                toastMessage: false,
            });
            return response;
        },
    });
};

export const useResendSubAdminEmail = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const data = await Get({
                path: API_CONSTANTS.SUB_ADMINS.resendEmail.replace(":id", id),
                token,
                toastError: true,
                toastMessage: true,
            })
            return data
        },
        mutationKey: ['sub-admins'],
    })
}