import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Post } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS, { DEFAULT_SEARCH_KEY } from "../constants/api";

export const useBookingReports = (params) => {
    const token = useSelector((state) => state.user?.token)
    params = appendQueryParams({
        ...params, [DEFAULT_SEARCH_KEY]: API_CONSTANTS.REPORTS.searchableKeys
    }, false);
    return useQuery({
        queryKey: ["booking-reports", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.REPORTS.base}?${params}`,
                token,
            })
            return data
        },
    })
}

export const useExportReport = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Post({
                path: API_CONSTANTS.REPORTS.downloadReport,
                token,
                body
            })
            return data
        },
    })
}

export const useDriverLogs = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["driver-logs", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.REPORTS.driverLogs}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
    })
}

export const useExportDriverLogs = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (params) => {
            const data = await Post({
                path: `${API_CONSTANTS.REPORTS.downloadDriverLogs}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
    })
}