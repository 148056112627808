import { Box, Card, CardBody, CardHeader, Flex, HStack, Heading, Icon, IconButton, SimpleGrid, Text, VStack, useColorMode, useDisclosure } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { colorKeys, getColor, rideStatColors } from '../../../config/constants/appColors'
import CustomSelect from '../../../components/Forms/CustomSelect'
import { makeSelectList } from '../../../config/helpers/selectListHelper'
import { useCities } from '../../../config/query/preferencesQuery'
import { useVehicleTypeOptions } from '../../../config/query/transportQuery'
import RideStatsBox from '../../../components/BasicUI/DataBoxes/RideStatsBox'
import APP_ICONS from '../../../config/constants/icons'
import { getStatusCounts } from '../../../config/helpers/formHelpers/bookingHelper'
import { RIDES_DRIVER_STATUS } from '../../../config/constants/enums'

const RideStatsSection = ({ query, onQueryChange, rawData, filteredData }) => {
    const { colorMode } = useColorMode()
    const expandDisclosure = useDisclosure({ defaultIsOpen: true })

    const cities = useCities()
    const vehicleTypes = useVehicleTypeOptions()

    const counts = useMemo(() => {
        return getStatusCounts(filteredData)
    }, [filteredData])

    return (
        <Card>
            <CardHeader p="10px 15px" bg={getColor(colorKeys.tableBackground, colorMode)}>
                <Flex justify="space-between" align="center">
                    <Heading as={"h2"} size={"md"}>Ride Stats</Heading>
                    <IconButton
                        onClick={expandDisclosure.onToggle}
                        icon={<Icon boxSize="5" as={expandDisclosure.isOpen ? APP_ICONS.UpChevron : APP_ICONS.DownChevron} />}
                        variant={"solid"}
                        size="xs"
                        colorScheme={"blue"}
                        aria-label={"Expand"}
                        bg={getColor(colorKeys.siderBackground, colorMode)}
                    />
                </Flex>
            </CardHeader>
            <CardBody pt={0} bg={getColor(colorKeys.tableBackground, colorMode)} p={expandDisclosure.isOpen ? "1.25rem" : "0"} transition={".3s ease"}>
                <VStack overflow={"hidden"} spacing={5} align="stretch" height={expandDisclosure.isOpen ? "auto" : "0px"} transition={".3s ease"}>

                    <SimpleGrid columns={2} spacingX={5}>
                        <CustomSelect
                            options={makeSelectList(vehicleTypes.data?.data)}
                            placeholder={"All Cars"}
                            onChange={(value) => onQueryChange({ VehicleTypeId: value })}
                            value={query?.VehicleTypeId}
                            containerStyles={{maxW: "100%" }}
                        />
                        <CustomSelect
                            options={makeSelectList(cities.data?.data)}
                            placeholder={"All Cities"}
                            onChange={(value) => onQueryChange({ CityId: value })}
                            value={query?.CityId}
                            containerStyles={{maxW: "100%" }}
                        />
                    </SimpleGrid>

                    <HStack flexDir={{ base: "row", lg: "column", xl: "row" }} spacing={4}>
                        <Box textAlign={"center"}>
                            <Heading as={"h2"}>{counts.total || "0"}</Heading>
                            <Text color={getColor(colorKeys.dimText, colorMode)} as={"h2"}>Total Drivers</Text>
                        </Box>

                        <SimpleGrid px={{ base: "5", lg: "1", xl: "5" }} columns={2} spacing={1} w="full">
                            <RideStatsBox
                                label={"Available"}
                                iconColor={rideStatColors.AVAILABLE}
                                count={counts[RIDES_DRIVER_STATUS.AVAILABLE] || "0"}
                                value={query?.statuses && query?.statuses.includes(RIDES_DRIVER_STATUS.AVAILABLE)}
                                onChange={(value) =>
                                    onQueryChange({
                                        statuses: value
                                            ? [...query?.statuses, RIDES_DRIVER_STATUS.AVAILABLE]
                                            : query?.statuses?.filter((item) => item !== RIDES_DRIVER_STATUS.AVAILABLE)
                                    })
                                }
                            />

                            <RideStatsBox
                                label={"Dispatching"}
                                iconColor={rideStatColors.DISPATCHING}
                                count={counts[RIDES_DRIVER_STATUS.DISPATCHING] || "0"}
                                value={query?.statuses && query?.statuses.includes(RIDES_DRIVER_STATUS.DISPATCHING)}
                                onChange={(value) =>
                                    onQueryChange({
                                        statuses: value
                                            ? [...query?.statuses, RIDES_DRIVER_STATUS.DISPATCHING]
                                            : query?.statuses?.filter((item) => item !== RIDES_DRIVER_STATUS.DISPATCHING)
                                    })
                                }
                            />
                            <RideStatsBox
                                label={"On the way"}
                                iconColor={rideStatColors.ON_THE_WAY}
                                count={counts[RIDES_DRIVER_STATUS.DRIVER_ON_THE_WAY] || "0"}
                                value={query?.statuses && query?.statuses.includes(RIDES_DRIVER_STATUS.DRIVER_ON_THE_WAY)}
                                onChange={(value) =>
                                    onQueryChange({
                                        statuses: value
                                            ? [...query?.statuses, RIDES_DRIVER_STATUS.DRIVER_ON_THE_WAY]
                                            : query?.statuses?.filter((item) => item !== RIDES_DRIVER_STATUS.DRIVER_ON_THE_WAY)
                                    })
                                }
                            />

                            <RideStatsBox
                                label={"Arrived & waiting"}
                                iconColor={rideStatColors.ARRIVED_AND_WAITING}
                                count={counts[RIDES_DRIVER_STATUS.ARRIVED_AND_WAITING] || "0"}
                                value={query?.statuses && query?.statuses.includes(RIDES_DRIVER_STATUS.ARRIVED_AND_WAITING)}
                                onChange={(value) =>
                                    onQueryChange({
                                        statuses: value
                                            ? [...query?.statuses, RIDES_DRIVER_STATUS.ARRIVED_AND_WAITING]
                                            : query?.statuses?.filter((item) => item !== RIDES_DRIVER_STATUS.ARRIVED_AND_WAITING)
                                    })
                                }
                            />
                            <RideStatsBox
                                label={"P.O.B"}
                                iconColor={rideStatColors.POB}
                                count={counts[RIDES_DRIVER_STATUS.POB] || "0"}
                                value={query?.statuses && query?.statuses.includes(RIDES_DRIVER_STATUS.POB)}
                                onChange={(value) =>
                                    onQueryChange({
                                        statuses: value
                                            ? [...query?.statuses, RIDES_DRIVER_STATUS.POB]
                                            : query?.statuses?.filter((item) => item !== RIDES_DRIVER_STATUS.POB)
                                    })
                                }
                            />

                            <RideStatsBox
                                label={"Dropped Off"}
                                iconColor={rideStatColors.DROPPED_OFF}
                                count={counts[RIDES_DRIVER_STATUS.DROPPED_OFF] || "0"}
                                value={query?.statuses && query?.statuses.includes(RIDES_DRIVER_STATUS.DROPPED_OFF)}
                                onChange={(value) =>
                                    onQueryChange({
                                        statuses: value
                                            ? [...query?.statuses, RIDES_DRIVER_STATUS.DROPPED_OFF]
                                            : query?.statuses?.filter((item) => item !== RIDES_DRIVER_STATUS.DROPPED_OFF)
                                    })
                                }
                            />
                            <RideStatsBox
                                label={"Parking Lot"}
                                iconColor={rideStatColors.PARKING_LOT}
                                count={counts[RIDES_DRIVER_STATUS.PARKING_LOT] || "0"}
                                value={query?.statuses && query?.statuses.includes(RIDES_DRIVER_STATUS.PARKING_LOT)}
                                onChange={(value) =>
                                    onQueryChange({
                                        statuses: value
                                            ? [...query?.statuses, RIDES_DRIVER_STATUS.PARKING_LOT]
                                            : query?.statuses?.filter((item) => item !== RIDES_DRIVER_STATUS.PARKING_LOT)
                                    })
                                }
                            />

                            <RideStatsBox
                                label={"Unavailable"}
                                iconColor={rideStatColors.UNAVAILABLE}
                                count={counts[RIDES_DRIVER_STATUS.UNAVAILABLE] || "0"}
                                value={query?.statuses && query?.statuses.includes(RIDES_DRIVER_STATUS.UNAVAILABLE)}
                                onChange={(value) =>
                                    onQueryChange({
                                        statuses: value
                                            ? [...query?.statuses, RIDES_DRIVER_STATUS.UNAVAILABLE]
                                            : query?.statuses?.filter((item) => item !== RIDES_DRIVER_STATUS.UNAVAILABLE)
                                    })
                                }
                            />

                        </SimpleGrid>

                    </HStack>

                </VStack>

            </CardBody>
        </Card >
    )
}

export default RideStatsSection