import React from 'react'
import { Box, HStack, Icon, Image, chakra, useColorMode } from '@chakra-ui/react'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import APP_ICONS from '../../../config/constants/icons'
import { COLOR_MODES } from '../../../config/constants/enums'

const VehicleCard = ({ isActive, onClick, vehicle, isDisabled }) => {
    const { colorMode } = useColorMode()
    return (
        <Box
            maxW="full"
            my="2"
            mx="1"
            borderWidth="1px"
            borderRightWidth="3px"
            borderTopWidth="3px"
            borderTopColor={isActive ? getColor(colorKeys.dimBlue, colorMode) : ""}
            borderRightColor={isActive ? getColor(colorKeys.dimBlue, colorMode) : ""}
            borderRadius="lg"
            overflow="hidden"
            onClick={onClick && onClick}
            cursor={isDisabled ? "not-allowed" : "pointer"}
            opacity={isDisabled ? "0.5" : "1"}
        >
            <Box m="4">
                <chakra.p>{vehicle?.vehicleType}</chakra.p>
                <chakra.p
                    color={getColor(colorKeys.dimBlue, colorMode)}
                    fontSize="13px"
                >
                    The Closest Driver is approximately 4 mins away
                </chakra.p>
                <HStack spacing={3}>
                    <Image
                        filter={colorMode === COLOR_MODES.DARK && 'brightness(0) invert(1)'}
                        objectFit={"contain"}
                        w="100%"
                        src={vehicle?.imageURL}
                    />
                </HStack>
                <HStack justify="space-between">
                    <HStack spacing={1}>
                        <Icon as={APP_ICONS.USERS} />
                        <chakra.span>{vehicle?.seatingCapacity}</chakra.span>
                        <Icon as={APP_ICONS.DRIVERS} />
                        <chakra.span>{vehicle?.seatingCapacity}</chakra.span>
                    </HStack>
                    <HStack spacing={0}>
                        <Icon as={APP_ICONS.DOLLAR_SIGN} />
                        <chakra.span>{vehicle?.fare}</chakra.span>
                    </HStack>
                </HStack>
            </Box>
        </Box>
    )
}

export default VehicleCard