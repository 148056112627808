import { Skeleton, VStack } from '@chakra-ui/react'
import React from 'react'

const ZoneListSkeleton = () => {
    return (
        <VStack spacing={3} w="full">
            {Array(10).fill("").map((item, index) => (
                <Skeleton height="35px" width="100%" key={index} />
            ))}
        </VStack>
    )
}

export default ZoneListSkeleton