import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { makeInitialQueryObject } from '../../../../config/helpers/queryHelper'
import { useBookingReports } from '../../../../config/query/reportsQuery'
import useDebounce from '../../../../config/hooks/useDebounce'
import { formatDateTime, humanizeNoOfMinutes } from '../../../../config/helpers/dateHelper'
import BreakText from '../../../../components/BasicUI/DataBoxes/BreakText'
import { breakCamelCase, capitalizeFirstLetter, formatAmount, formatDistance } from '../../../../config/helpers/stringHelper'
import { APP_MODULES } from '../../../../config/constants'
import { getFilters } from '../../../../config/helpers/filterHelper'
import { useDriverOptions } from '../../../../config/query/driverQuery'
import { useCustomerOptions } from '../../../../config/query/customerQuery'
import { useCorporateOptions } from '../../../../config/query/corporateQuery'
import { BOOKINGS_STATUS, RIDE_STATUS } from '../../../../config/constants/enums'
import API_CONSTANTS from '../../../../config/constants/api'
import { Link } from 'react-router-dom'
import { Text } from '@chakra-ui/react'

const BookingsReports = ({ setIsRefreshing, isRefreshing }) => {

    const [query, setQuery] = useState(makeInitialQueryObject({ Sort: undefined, PageSize: 15 }))
    const debouncedQuery = useDebounce(query)

    const bookingReportsQuery = useBookingReports(debouncedQuery)
    const driverOptions = useDriverOptions(debouncedQuery?.DriverIdQuery);
    const customerOptions = useCustomerOptions(debouncedQuery?.CustomerIdQuery);
    const corporateOptions = useCorporateOptions(debouncedQuery?.CorporateIdQuery);

    const onQueryChange = (updatedQuery) => {
        setQuery((prev) => ({ ...prev, ...updatedQuery }))
    }

    useEffect(() => {
        if (isRefreshing)
            bookingReportsQuery
                .refetch()
                .then(() => setIsRefreshing(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshing])

    const filters = {
        CorporateId: corporateOptions?.data?.data?.map((item) => ({
            key: item.id,
            value: item.name,
        })),
        CustomerId: customerOptions?.data?.data?.map((item) => ({
            key: item.id,
            value: item.name,
        })),
        DriverId: driverOptions?.data?.data?.map((item) => ({
            key: item.id,
            value: item.name,
        })),
        BookingStatus: Object.keys(BOOKINGS_STATUS).map((item) => ({
            key: item,
            value: BOOKINGS_STATUS[item],
        })),
        RideStatus: Object.keys(RIDE_STATUS).map((item) => ({
            key: item,
            value: RIDE_STATUS[item],
        })),
    }

    return (
        <CustomTable
            fixedHeight={true}
            searchHint={`Search by ${API_CONSTANTS.REPORTS.searchableKeys.map((item) => capitalizeFirstLetter(breakCamelCase(item))).join(", ")}`}
            permissionKey={APP_MODULES.Reports}
            tableWrapperProps={{ padding: 0, h: "auto" }}
            containerProps={{ w: "full", h: "auto" }}
            filters={getFilters(filters)}
            tableHeadTextProps={{ paddingRight: "30px" }}
            head={[
                { title: "Booking#", extractor: "id", component: (item) => <Text _hover={{ textDecor: "underline" }} as={Link} to={`/admin/bookings/${item.id}`}>{item.id}</Text> },
                { title: "Ride Status", extractor: "rideStatus.id", component: (item) => item.rideStatus?.name },
                { title: "Booking Status", extractor: "bookingStatus.id", component: (item) => item.bookingStatus?.name },
                { title: "Ride Type", extractor: "rideType.id", component: (item) => item.rideType?.name },
                { title: "Customer Chat ID", extractor: "customerSupportChatId" },
                { title: "Driver Chat ID", extractor: "driverSupportChatId" },
                { title: "Driver Name", extractor: "driverName" },
                { title: "Driver Phone", extractor: "driverPhone" },
                { title: "Passenger Name", extractor: "passengerName" },
                { title: "Passenger Phone", extractor: "passengerPhone" },
                { title: "Customer Type", extractor: "customerType.id", component: (item) => item.customerType?.name },
                { title: "Corporate Name", extractor: "corporateName" },
                { title: "Created On", extractor: "createdOn", component: (item) => formatDateTime(item.createdOn) },
                { title: "Pickup Arrival", extractor: "pickupArrival", component: (item) => formatDateTime(item.pickupArrival) },
                { title: "Dispatch Time", extractor: "dispatchTime", component: (item) => formatDateTime(item.dispatchTime) },
                { title: "Pickup Departure", extractor: "pickupDeparture", component: (item) => formatDateTime(item.pickupDeparture) },
                { title: "Destination Arrival", extractor: "destinationArrival", component: (item) => formatDateTime(item.destinationArrival) },
                { title: "Completed Time", extractor: "completedTime", component: (item) => formatDateTime(item.completedTime) },
                { title: "Requested Car Type", extractor: "requestedCarType" },
                { title: "Actual Car Type", extractor: "actualCarType" },
                { title: "Pickup Address", extractor: "pickupAddress", component: (item) => <BreakText value={item.pickupAddress} /> },
                { title: "Original Destination", extractor: "originalDestination", component: (item) => <BreakText value={item.originalDestination} /> },
                { title: "Actual Destination", extractor: "actualDestination", component: (item) => <BreakText value={item.actualDestination} /> },
                { title: "Estd. Distance", extractor: "estDistance", component: (item) => formatDistance(item.estDistance) },
                { title: "Estd. Duration", extractor: "estDuration", component: (item) => humanizeNoOfMinutes(item.estDuration) },
                { title: "Actual Distance", extractor: "actualDistance", component: (item) => formatDistance(item.actualDistance) },
                { title: "Estd. Base Fare", extractor: "estimatedBaseFare", component: (item) => formatAmount(item.estimatedBaseFare) },
                { title: "Actual Base Fare", extractor: "actualBaseFare", component: (item) => formatAmount(item.actualBaseFare) },
                { title: "Surcharge", extractor: "surcharge", component: (item) => `${item.surcharge}%` },
                { title: "Airport Fee", extractor: "isAirportFee", component: (item) => item.isAirportFee ? "Yes" : "No" },
                { title: "Toll Fee", extractor: "tollFee", component: (item) => formatAmount(item.tollFee) },
                { title: "Fleet Services", extractor: "fleetServices", component: (item) => formatAmount(item.fleetServices) },
                { title: "Tech Fee", extractor: "techFee", component: (item) => formatAmount(item.techFee) },
                { title: "Tax %", extractor: "taxPercentage", component: (item) => `${item.taxPercentage}%` },
                { title: "Tax Amount", extractor: "taxAmount", component: (item) => formatAmount(item.taxAmount) },
                { title: "Tip", extractor: "tip", component: (item) => formatAmount(item.tip) },
                { title: "Total", extractor: "total", component: (item) => formatAmount(item.total) },
                { title: "Discount", extractor: "discount", component: (item) => formatAmount(item.discount) },
                { title: "Payment Method", extractor: "paymentMethod.id", component: (item) => item.paymentMethod?.name },
                { title: "Transaction Id", extractor: "transactionId" },
                { title: "Promo Code", extractor: "promoCode" },
                { title: "Pay With Wallet", extractor: "payWithWallet", component: (item) => item.payWithWallet ? "Yes" : "No" },
                { title: "Send Receipt", extractor: "sendReceipt", component: (item) => item.sendReceipt ? "Yes" : "No" },
            ]}
            data={bookingReportsQuery.data?.data}
            loading={bookingReportsQuery?.isLoading}
            totalResults={bookingReportsQuery?.data?.meta?.totalCount}
            totalPages={bookingReportsQuery?.data?.meta?.totalPages}
            pageNo={query?.PageNumber}
            pageSize={query?.PageSize}
            onQueryChange={onQueryChange}
            query={query}
            isRefreshing={bookingReportsQuery?.isFetching}
            filtersLoading={{
                CorporateId: corporateOptions.isFetching,
                CustomerId: customerOptions.isFetching,
                DriverId: driverOptions.isFetching,
            }}
        />
    )
}

export default BookingsReports