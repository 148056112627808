import React from "react";
import CustomTabs from "../../../../components/BasicUI/CustomTabs";
import Mail from "./Mail";
import SMS from "./SMS";

const Integration = () => {
  return (
    <CustomTabs
      containerProps={{ mt: 5, p: 0 }}
      tabItemProps={{ fontSize: "sm" }}
      tabPanelProps={{ p: 0 }}
      tabs={[
        {
          head: "Mail",
          key: "mail",
          body: <Mail />,
        },
        {
          head: "SMS",
          key: "sms",
          body: <SMS />,
        },
      ]}
    />
  );
};

export default Integration;
