import React, { useState } from "react";
import APP_ICONS from "../../../config/constants/icons";
import {
  Flex,
  Heading,
  Input,
  VStack,
  Icon,
  InputLeftElement,
  InputGroup,
  useDisclosure,
  useColorMode,
} from "@chakra-ui/react";
import CustomTabs from "../../../components/BasicUI/CustomTabs";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import InboxList from "./InboxList";
import IssuesInbox from "./IssuesInbox";
import GroupsInbox from "./GroupsInbox";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { useDispatch } from "react-redux";
import { clearTotalUnreadCount } from "../../../config/redux/slices/chatSlice";

const ChatList = () => {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const chatFormDisclosure = useDisclosure();
  const [query, setQuery] = useState("");
  const { pathname } = useLocation();

  const tabs = [
    {
      index: 0,
      head: "Support",
      key: "support",
      body: <InboxList query={query} />,
    },
    {
      index: 1,
      head: "Disputes",
      key: "disputes",
      body: <IssuesInbox query={query} />,
    },
    {
      index: 2,
      head: "Groups",
      key: "groups",
      body: (
        <GroupsInbox query={query} chatFormDisclosure={chatFormDisclosure} />
      ),
      newChat: true,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(
    tabs.find((tab) => pathname.includes(tab.key)) || tabs[0]
  );

  const onTabChange = (index) => {
    setQuery("");
    navigate(`/admin/chats/${tabs[index].key}`);
    setSelectedTab(tabs[index]);
  };

  useEffect(() => {
    setSelectedTab(tabs.find((tab) => pathname.includes(tab.key)) || tabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    dispatch(clearTotalUnreadCount());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack
      pos="fixed"
      left={{ base: "0", lg: "255px" }}
      px="3"
      top="70px"
      w={{ base: "320px", lg: "370px" }}
      h="calc(100vh - 70px)"
      align="stretch"
      spacing={2}
      bg={getColor(colorKeys.tableBackground, colorMode)}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Heading
          as="h2"
          fontSize={26}
          fontWeight="bold"
          color={getColor(colorKeys.primaryText, colorMode)}
        >
          Chats
        </Heading>
        {selectedTab?.newChat && (
          <PrimaryButton
            onClick={chatFormDisclosure.onOpen}
            size="sm"
            variant="solid"
          >
            New Group
          </PrimaryButton>
        )}
      </Flex>
      <InputGroup size="sm" variant="filled">
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={APP_ICONS.SEARCH} />}
        />
        <Input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          type="search"
          variant="filled"
          placeholder="Search chats..."
        />
      </InputGroup>

      <CustomTabs
        containerProps={{
          isFitted: true,
          p: 0,
          index: selectedTab?.index,
          onChange: onTabChange,
          isLazy: true,
        }}
        tabItemProps={{ fontSize: "sm" }}
        tabPanelProps={{ p: 0 }}
        tabs={tabs}
      />
    </VStack>
  );
};

export default ChatList;
