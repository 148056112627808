import { Avatar, Badge, Box, Flex, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { Link, useParams } from "react-router-dom";
import { formatDateTimeFromNow } from "../../../config/helpers/dateHelper";
import { trimLargeString } from "../../../config/helpers/stringHelper";

const ChatItem = ({ chatItem, to = "/admin/chats", title }) => {
  const { colorMode } = useColorMode();
  const { chatId } = useParams();
  return (
    <Flex
      as={Link}
      to={`${to}/${chatItem?.chatId}`}
      p="8px"
      rounded="sm"
      w="full"
      cursor={"pointer"}
      role="group"
      _hover={{ bg: getColor(colorKeys.lightGrayBackgroundFill, colorMode) }}
      bg={
        Number(chatId) === chatItem?.chatId
          ? getColor(colorKeys.lightGrayBackgroundFill, colorMode)
          : "transparent"
      }
    >
      <Avatar size="sm" src={chatItem?.chatImage} name={chatItem?.chatTitle} />
      <Box w="full" ml={2}>
        <Flex justify={"space-between"} w="full">
          <Box>
            <Text fontSize={"13px"} fontWeight={"bold"}>
              {title || chatItem?.chatTitle || "N/A"}
            </Text>
            <Box>
              {chatItem?.thumbnailImageUrl ? (
                <Text fontSize={"13px"}>Image</Text>
              ) : (
                <Text fontSize={"13px"} maxW="full">
                  {chatItem?.text
                    ? trimLargeString(chatItem?.text, 40)
                    : "No messages yet"}
                </Text>
              )}
            </Box>
          </Box>
          <Flex flexDir="column" align="end">
            <Text fontSize={"12px"}>
              {formatDateTimeFromNow(chatItem?.createdAt)}
            </Text>
            {chatItem?.unReadCount ? (
              <Badge
                mt="2px"
                w="20px"
                h="20px"
                rounded="full"
                colorScheme="blue"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {chatItem?.unReadCount}
              </Badge>
            ) : null}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ChatItem;
