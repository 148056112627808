import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import NoResults from "../../SVGComponents/NoResults";
import ParticipantItem from "./ParticipantItem";

const checkParticipant = (adminId, itemId) => {
  const admin = adminId?.split("_")?.at(1);
  return Number(admin) === Number(itemId)
}

const ParticipantBox = ({
  value = [],
  onRemoveParticipant,
  type,
  containerProps,
  hiddenHash,
}) => {
  return (
    <VStack
      h="calc(100vh - 450px)"
      overflowY={"auto"}
      align="stretch"
      spacing={0}
      {...containerProps}
    >
      {value?.map((item, index) => {
        if (checkParticipant(hiddenHash, item?.value)) return null;
        return (
          <ParticipantItem
            key={index}
            data={item}
            type={type}
            onRemoveParticipant={onRemoveParticipant}
          />
        );
      })}
      <Box m="auto" py="5">
        {value?.length === 0 && <NoResults />}
      </Box>
    </VStack>
  );
};

export default ParticipantBox;
