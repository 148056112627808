import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";

const TablePermissionDenied = ({ containerProps }) => {
  return (
    <Flex
      justify="center"
      alignItems="center"
      h={containerProps?.h ? containerProps?.h : "calc(100vh - 140px)"}
      flexDir={"column"}
    >
      <Heading fontSize="23px" fontWeight="bold">
        Permission Denied
      </Heading>
      <Text>You are not authorized to view this resource.</Text>
    </Flex>
  );
};

export default TablePermissionDenied;
