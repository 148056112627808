import React, { useState, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import FormModal from '../../../../components/Forms/FormModal'
import CustomTabs from '../../../../components/BasicUI/CustomTabs'
import CustomerInfo from './CustomerInfo'
import CustomerCards from './CustomerCards'

const CustomerForm = ({ disclosure, data }) => {
    const queryClient = useQueryClient()
    const [tabIndex, setTabIndex] = useState(0)
    const [customerData, setCustomerData] = useState(data)

    const onCustomerFormSuccess = (response) => {
        queryClient.invalidateQueries(["customers", "customer"])
        setCustomerData(response)
        if (!data?.id) setTabIndex(1)
        else disclosure.onClose()
    }

    const tabs = [
        {
            head: "Personal Information",
            body: <CustomerInfo
                disclosure={disclosure}
                data={customerData}
                onSuccess={onCustomerFormSuccess}
            />,
        },
        {
            head: "Customer Cards",
            body: <CustomerCards
                disclosure={disclosure}
                data={customerData}
            />,
            isDisabled: !customerData?.id
        }
    ]

    useEffect(() => {
        if (disclosure.isOpen && data?.id) {
            setCustomerData(data)
        }
        else {
            setTabIndex(0)
            setCustomerData(null)
        }
    }, [disclosure.isOpen, data])

    return (
        <FormModal
            title={customerData ? "Edit Customer" : "Add Customer"}
            disclosure={disclosure}
            maxW={"75rem"}
            onCloseFn={() => {
                //reset both forms here
            }}
            hideFooter={true}

        >
            <CustomTabs
                tabs={tabs}
                tabPanelProps={{ px: 0 }}
                containerProps={{
                    index: tabIndex,
                    onChange: (index) => setTabIndex(index)
                }}
            />
        </FormModal>
    )
}

export default CustomerForm