import { Avatar, Flex, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, useColorMode, Button, Text, HStack, AvatarBadge } from '@chakra-ui/react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import React, { useEffect } from 'react'
import { getColor, colorKeys } from '../../../config/constants/appColors'
import APP_ICONS from '../../../config/constants/icons'
import { useSelector, useDispatch } from 'react-redux'
import { resetUser } from '../../../config/redux/slices/userSlice'
import NetworkBar from '../NetworkBar'
import { getPageTitle, singularize } from '../../../config/helpers/stringHelper'
import PathGuide from './PathGuide'
import ColorModeSwitch from '../Buttons/ColorModeSwitch'
import { getPermission } from '../../../config/helpers/permissionHelper'
import { APP_MODULES } from '../../../config/constants'
import ScreenSearch from './ScreenSearch'

const Header = ({ disclosure, desktopDisclosure }) => {
    const dispatch = useDispatch()
    const permissions = useSelector((state) => state.user?.permissions)
    const isGlobal = useSelector((state) => state.user?.user?.isGlobal)
    const user = useSelector((state) => state.user?.user)
    const tenant = useSelector((state) => state?.tenant?.tenant)
    const totalUnreadCount = useSelector((state) => state.chat?.totalUnreadCount);
    const { pathname } = useLocation()
    const splitPath = pathname.split('/')
    const navigate = useNavigate()

    const { colorMode } = useColorMode()

    useEffect(() => {
        let title = splitPath[splitPath.length - 1]
        if (!isNaN(splitPath[splitPath.length - 1])) {
            title = `${singularize(splitPath[splitPath.length - 2].replace("-", " "))} Details`
        }
        document.title = getPageTitle(title,tenant?.name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    return (
        <Flex
            as="header"
            align="center"
            justify="space-between"
            w="full"
            px="4"
            minH="70px"
            bg={getColor(colorKeys.layoutHeaderBackground, colorMode)}
            h="14"
            pos="sticky"
            top="0"
            zIndex={99}
        >
            {/* mobile sider toggle button */}
            <IconButton
                aria-label="Menu"
                display={{
                    base: "inline-flex",
                    lg: "none",
                }}
                onClick={disclosure.onOpen}
                icon={<Icon as={APP_ICONS.MENU} />}
                size="sm"
            />

            {/* desktop sider toggle button */}
            <Flex align="center">
                <IconButton
                    transitionDelay={"1s"}
                    transition={"ease-in-out"}
                    aria-label="Menu"
                    display={{
                        base: "none",
                        lg: "inline-flex",
                    }}
                    onClick={() => desktopDisclosure.onToggle()}
                    icon={<Icon as={APP_ICONS.DRAWER} />}
                    size="sm"
                    mr={5}
                    transform={desktopDisclosure.isOpen ? "rotate(180deg)" : "rotate(0deg)"}
                />
                <PathGuide />
            </Flex>
            <NetworkBar />

            <HStack spacing={2} align={"center"}>

                <ScreenSearch />

                {getPermission({
                    permissions,
                    permissionKey: APP_MODULES.ChatAndMessaging,
                    isGlobal
                })
                    ? <IconButton
                        size="sm"
                        variant={totalUnreadCount ? 'solid' : "ghost"}
                        w="40px"
                        h="40px"
                        as={Link}
                        to="/admin/chats"
                        icon={<Avatar
                            bg="transparent"
                            icon={<Icon color={getColor(colorKeys.primaryText, colorMode)} boxSize="5" as={APP_ICONS.CHAT_ALT} />}
                        >
                            {totalUnreadCount ? <AvatarBadge alignItems={"center"} placement='top-end' boxSize='1.40em' bg='red' >
                                <Text fontSize="xs" color="white" fontWeight="bold">{totalUnreadCount}</Text>
                            </AvatarBadge> : null}
                        </Avatar>}
                    /> : null}

                <ColorModeSwitch />

                <Menu>
                    <MenuButton ml={1} as={Button} variant={"unstyled"} >
                        <Flex align="center">
                            <Avatar
                                size="xs"
                                name={user?.name}
                                src={user?.profilePictureUrl || user?.profilePicture}
                                cursor="pointer"
                                className="top-nav-avatar"
                            />
                            <Text ml={2} fontSize="sm" fontWeight="medium">{user?.name}</Text>
                            <Icon mt={1} boxSize="2" as={APP_ICONS.DownChevron} ml={1} />
                        </Flex>
                    </MenuButton>
                    <MenuList>
                        {[
                            { name: "Profile", icon: APP_ICONS.PROFILE, action: () => navigate("/admin/profile") },
                            { name: "Change Password", icon: APP_ICONS.LOCK_OUTLINE, action: () => navigate("/admin/change-password") },
                            { name: "Log Out", icon: APP_ICONS.LOGOUT, action: () => dispatch(resetUser()) },
                        ].map((item, index) =>
                            <MenuItem fontSize={"14px"} icon={
                                <Icon as={item.icon} boxSize="4" />
                            } key={index} onClick={item.action}>{item.name}</MenuItem>
                        )}
                    </MenuList>
                </Menu>
            </HStack>
        </Flex >
    )
}

export default React.memo(Header)