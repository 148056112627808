import { Box, Flex, HStack, SimpleGrid, Text, VStack, chakra, IconButton, Icon, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import FormModal from '../../../components/Forms/FormModal'
import { useForm } from 'react-hook-form'
import FormInput from '../../../components/Forms/FormInput'
import FormTextarea from '../../../components/Forms/FormTextarea'
import APP_ICONS from '../../../config/constants/icons'
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import { useAdjustBalance, useDriverBalance } from '../../../config/query/driverQuery'
import { BALANCE_ADJUST_TYPE } from '../../../config/constants/enums'
import { formatAmount } from '../../../config/helpers/stringHelper'

const UpdateCreditForm = ({ disclosure, data }) => {

    const confirmDisclosure = useDisclosure()
    const [balanceAdjustType, setBalanceAdjustType] = useState(0);
    const customerBalanceQuery = useDriverBalance(data?.id)
    const adjustBalanceQuery = useAdjustBalance()

    const {
        handleSubmit,
        control,
        reset: resetForm,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useForm()

    const onIncrease = () => {
        const prevAmount = Number(getValues("amount")) || 0
        if (prevAmount > 9999) return;
        const amount = prevAmount + 1;
        setValue("amount", amount);
    }

    const onDecrease = () => {
        const prevAmount = Number(getValues("amount")) || 0
        if (prevAmount < 2) return;
        const amount = prevAmount - 1;
        setValue("amount", amount);
    }

    const onSubmit = (values) => {
        adjustBalanceQuery
            .mutateAsync({
                amount: Number(values.amount),
                currency: 0,
                stripeAccountId: data?.stripeAccountId,
                balanceAdjustmentType: balanceAdjustType,
            })
            .then(() => {
                customerBalanceQuery.refetch()
                confirmDisclosure.onClose();
                disclosure.onClose();
                resetForm({
                    amount: 0,
                    note: "",
                })
            });
    }

    return (
        <FormModal
            title={"Update Credit"}
            disclosure={disclosure}
            isSubmitting={adjustBalanceQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            size="md"
            hideFooter={true}
            onCloseFn={() => {
                confirmDisclosure.onClose()
                resetForm({
                    amount: 0,
                    note: "",
                })
            }}
        >
            {confirmDisclosure.isOpen ? (
                <Flex pb={4} justify={'center'} align={"center"}>
                    <Box>
                        <Text>
                            {balanceAdjustType === BALANCE_ADJUST_TYPE.DEDUCT
                                ? "Are you sure you want to deduct this driver's credit?"
                                : "Are you sure you want to add this driver's credit?"}
                        </Text>
                        <HStack spacing={5} mt={2} w="full" justify={"center"}>
                            <PrimaryButton w="120px" onClick={() => confirmDisclosure.onClose()}>No</PrimaryButton>
                            <PrimaryButton w="120px" type="submit" isLoading={adjustBalanceQuery.isLoading}>
                                Yes
                            </PrimaryButton>
                        </HStack>
                    </Box>
                </Flex>
            ) : (
                <>
                    <Text textAlign={"center"}>Credit Balance: <chakra.span fontWeight={"bold"}>
                        {formatAmount(customerBalanceQuery?.data?.data)}
                    </chakra.span>
                    </Text>
                    <VStack spacing={5} my={2}>

                        <FormInput
                            leftAddon={
                                <IconButton
                                    onClick={onDecrease}
                                    icon={<Icon boxSize={3} as={APP_ICONS.SUBTRACT} />}
                                    roundedEnd="none"
                                    size="sm"
                                >
                                    -
                                </IconButton>
                            }
                            rightAddon={
                                <IconButton
                                    onClick={onIncrease}
                                    icon={<Icon boxSize={5} as={APP_ICONS.ADD} />}
                                    roundedStart="none"
                                    size="sm"
                                >
                                    +
                                </IconButton>
                            }
                            hideLabel={true}
                            control={control}
                            errors={errors}
                            id="amount"
                            required={true}
                            placeholder="Enter amount"
                            inputProps={{ size: "sm", maxW: "120px" }}
                            containerStyles={{ width: "fit-content" }}
                            messageContainerProps={{ placeContent: "center" }}
                            iconProps={{ display: "none" }}
                            groupProps={{ placeContent: "center" }}
                            label={"Amount"}
                            feedbackMessage={"Please make sure your platform account has sufficient Reserve funds in case of a negative transaction"}
                            rules={{
                                required: "Please enter amount",
                                pattern: {
                                    value: /^[+-]?\d+(\.\d+)?$/,
                                    message: "Please enter a valid amount",
                                },
                                min: {
                                    value: -9999,
                                    message: "Please enter a valid amount",
                                },
                                max: {
                                    value: 9999,
                                    message: "Please enter a valid amount",
                                }
                            }}
                        />

                        <FormTextarea
                            id={"note"}
                            label="Note"
                            placeholder="Write anything you want to note about this customer"
                            errors={errors}
                            control={control}
                        />

                        <SimpleGrid columns={2} spacing={5} w="full">
                            <PrimaryButton
                                onClick={() => {
                                    trigger().then((isValid) => {
                                        if (isValid) {
                                            setBalanceAdjustType(BALANCE_ADJUST_TYPE.ADD);
                                            confirmDisclosure.onOpen();
                                        }
                                    })

                                }}
                            >Add Credit</PrimaryButton>
                            <PrimaryButton onClick={() => {
                                trigger().then((isValid) => {
                                    if (isValid) {
                                        setBalanceAdjustType(BALANCE_ADJUST_TYPE.DEDUCT);
                                        confirmDisclosure.onOpen();
                                    }
                                })
                            }}>Deduct Credit</PrimaryButton>
                        </SimpleGrid>

                    </VStack>
                </>
            )}

        </FormModal >
    )
}

export default UpdateCreditForm