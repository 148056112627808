import { SimpleGrid, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import FormModal from "../../../../components/Forms/FormModal";
import { useForm } from "react-hook-form";
import FormInput from "../../../../components/Forms/FormInput";
import {
  useCreateShift,
  useUpdateShift,
} from "../../../../config/query/shiftQuery";
import moment from "moment";
import { preparePayload } from "../../../../config/helpers/formHelpers/shiftHelper";

const ShiftForm = ({ disclosure, data }) => {
  const createShiftQuery = useCreateShift();
  const updateShiftQuery = useUpdateShift();

  const {
    handleSubmit,
    reset: resetForm,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    values: {
      name: data?.name,
      timeFrom: "",
      timeTo: "",
    },
  });

  const onSubmit = (values) => {
    let formMutate = data?.id
      ? updateShiftQuery.mutateAsync
      : createShiftQuery.mutateAsync;
    formMutate(preparePayload(values, data?.id))
      .then(() => {
        disclosure.onClose();
        resetForm();
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  useEffect(() => {
    if (data?.id && disclosure?.isOpen) {
      setValue("name", data?.name);
      setValue("timeFrom", moment(data.timeFrom, "HH:mm:ss.SSSSSSS").format("HH:mm"))
      setValue("timeTo", moment(data.timeTo, "HH:mm:ss.SSSSSSS").format("HH:mm"));
    }
  }, [data, setValue, disclosure?.isOpen]);

  return (
    <FormModal
      title={data?.id ? "Edit Shift" : "Add Shift"}
      disclosure={disclosure}
      isSubmitting={createShiftQuery.isLoading || updateShiftQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"50rem"}
      saveButtonText={"Save"}
      reset={resetForm}
      onCloseFn={() => resetForm()}
    >
      <VStack spacing={5} align="stretch">
        <FormInput
          label={"Name"}
          control={control}
          errors={errors}
          id="name"
          required={true}
          placeholder="Enter name"
          inputProps={{ size: "sm" }}
        />

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
          <FormInput
            label={"Time From"}
            control={control}
            errors={errors}
            id="timeFrom"
            required={true}
            type="time"
            inputProps={{ size: "sm" }}
          />

          <FormInput
            label={"Time To"}
            control={control}
            errors={errors}
            id="timeTo"
            required={true}
            type="time"
            inputProps={{ size: "sm" }}
          />
        </SimpleGrid>
      </VStack>
    </FormModal>
  );
};

export default ShiftForm;
