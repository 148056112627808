import React, { useEffect } from "react";
import { Box, VStack, useDisclosure } from "@chakra-ui/react";
import { Navigate, useParams } from "react-router";
import { useBooking } from "../../../../config/query/bookingsQuery";
import BookingData from "./BookingData";
import TripData from "./TripData";
import BookingActions from "./BookingActions";
import { useSignalREffectAlt } from "../../../../config/sockets/SignalRContext";
import { SOCKET_SERVER } from "../../../../config/constants/chat";

const BookingDetails = () => {

    const { bookingId } = useParams();
    const bookingQuery = useBooking(bookingId);
    const timeZoneDisclosure = useDisclosure({ defaultIsOpen: false })

    const onRideUpdate = (data) => {
        if (Number(data?.rideId) === Number(bookingId) && data?.status?.id !== bookingQuery.data?.data?.status?.id) {
            bookingQuery.refetch()
        }
    }

    useSignalREffectAlt(SOCKET_SERVER.LiveRideUpdate, onRideUpdate);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    if (!bookingId) return <Navigate to="/admin/bookings" />;

    return (
        <Box>
            <VStack spacing={5}>
                <Box width={"full"}>
                    <BookingActions bookingQuery={bookingQuery} timeZoneDisclosure={timeZoneDisclosure} />
                    <BookingData
                        data={bookingQuery.data}
                        isLoading={bookingQuery.isLoading}
                        bookingId={bookingId}
                        timeZoneDisclosure={timeZoneDisclosure}
                    />
                </Box>
                <TripData data={bookingQuery.data} />
            </VStack >

        </Box >
    );
};

export default BookingDetails;
