import React, { useState, useEffect, useRef, useContext } from 'react'
import { Box, Button, Grid, GridItem, Icon, useDisclosure } from '@chakra-ui/react'
import RideMapSection from './RideMapSection'
import { useRidesDrivers } from '../../../config/query/mapQuery'
import APP_ICONS from '../../../config/constants/icons'
import StatsDrawer from './StatsDrawer'
import { useForceUpdate } from '../../../config/hooks/useForceUpdate'
import { SignalRContext, useSignalREffectAlt } from '../../../config/sockets/SignalRContext'
import { SOCKET_SERVER } from '../../../config/constants/chat'
import { BOOKINGS_STATUSES } from '../../../config/constants/enums'

const LiveMap = () => {
    const hubConnection = useContext(SignalRContext)
    const filtersDisclosure = useDisclosure()
    const forceUpdate = useForceUpdate()
    const driversHashMap = useRef({})
    const ridesHashMap = useRef({})
    const dirversArray = useRef([])

    const [query, setQuery] = useState({
        statuses: []
    })

    const [selectedEntity, setSelectedEntity] = useState(null)

    const ridesDriversQuery = useRidesDrivers()

    const onQueryChange = (updatedQuery) => setQuery((prevQuery) => ({ ...prevQuery, ...updatedQuery }))

    const getRides = () => {
        ridesDriversQuery
            .mutateAsync()
            .then((res) => {
                if (res?.data) {
                    res.data.forEach((driver) => {
                        if (driver.driverId) {
                            driversHashMap.current[driver.driverId] = driver
                        }
                        else {
                            ridesHashMap.current[driver.rideId] = driver
                        }
                    })
                    dirversArray.current = Object.values(ridesHashMap.current).concat(Object.values(driversHashMap.current));
                    hubConnection.invoke(SOCKET_SERVER.JoinAdminMapRoom)
                    forceUpdate()
                }
            })
            .catch((err) => console.warn(err))
    }

    useEffect(() => {
        getRides()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onLocationUpdate = (data) => {
        if (data) {
            const driver = driversHashMap.current[data.driverId]
            if (driver) {
                driver.driverLocation = { lat: data?.location?.lat, lng: data?.location?.lng }
            }
            dirversArray.current = Object.values(ridesHashMap.current).concat(Object.values(driversHashMap.current));
        }
    }

    const onRideUpdate = (data) => {
        if (data) {
            if (data?.rideId && data?.driverId) {
                //removing driver from drivers hash map
                //as ride is recieved on this driver
                delete driversHashMap.current[data?.driverId]

                //checking if ride is cancelled, if yes then remove from
                //rides hashmap else adding or replacing in the rides map
                if (data?.bookingStatus?.id === BOOKINGS_STATUSES.Finished) {
                    delete ridesHashMap.current[data?.rideId]
                }
                else {
                    ridesHashMap.current[data?.rideId] = { ...data }
                }
            } else if (data?.rideId && !data?.driverId) {
                //ride recieved but no driver id so 
                //adding or replacing in the rides map
                if (ridesHashMap.current[data?.rideId]) {
                    delete ridesHashMap.current[data?.rideId]
                }
                ridesHashMap.current[data?.rideId] = { ...data }
            } else if (data?.driverId && !data?.rideId) {
                //driver recieved but no ride id so adding or replacing
                //in drivers hashmap
                if (driversHashMap.current[data?.driverId]) {
                    delete driversHashMap.current[data?.driverId]
                }
                driversHashMap.current[data?.driverId] = { ...data }
            }

            if (data?.bookingStatus?.id === 2) {
                delete ridesHashMap.current[data?.rideId]
            }

            dirversArray.current = Object.values(ridesHashMap.current).concat(Object.values(driversHashMap.current));
        }
    }

    useSignalREffectAlt(SOCKET_SERVER.LiveRideUpdate, onRideUpdate);
    useSignalREffectAlt(SOCKET_SERVER.LiveLocationUpdate, onLocationUpdate);

    useEffect(() => {
        //rerender component every 10 seconds
        const interval = setInterval(() => {
            forceUpdate()
        }, 5000)
        return () => clearInterval(interval)
    })

    const getCalculatedData = () => {
        //filtering data with whole query to show booking list
        let filteredData = dirversArray.current
        if (query?.VehicleTypeId) {
            filteredData = filteredData.filter((item) => item.vehicleTypeId === query.VehicleTypeId)
        }
        if (query?.CityId) {
            filteredData = filteredData.filter((item) => item.cityId === query.CityId)
        }
        if (query?.StatusId) {
            filteredData = filteredData.filter((item) => item.status.id === query.StatusId)
        }
        if (query?.BoongStatusId) {
            filteredData = filteredData.filter((item) => item.boongStatus.id === query.BoongStatusId)
        }
        if (query?.statuses?.length) {
            filteredData = filteredData.filter((item) => query?.statuses?.includes(item?.status?.id))
        }

        return filteredData
    }

    const getFilteredData = () => {
        //filtering data based on Vehicle Type Id and City Id to show counts
        let filteredData = dirversArray.current
        if (query?.VehicleTypeId) {
            filteredData = filteredData.filter((item) => item.vehicleTypeId === query.VehicleTypeId)
        }
        if (query?.CityId) {
            filteredData = filteredData.filter((item) => item.cityId === query.CityId)
        }
        return filteredData
    }


    useEffect(() => {
        if (hubConnection?.state === "Connected") {
            getRides()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubConnection?.state])

    return (
        <Box>
            <Button
                leftIcon={<Icon as={APP_ICONS.FILTER} />}
                onClick={filtersDisclosure.onToggle}
                size="sm"
                pos="absolute"
                top="91px"
                zIndex={9}
                right="182px"
                children="Filters & Stats"
                rounded={"none"}
                display={{ base: "flex", lg: "none" }}
            />
            <Grid h="calc(100vh - 100px)" templateColumns='repeat(20, 1fr)' gap={2} pos="relative" zIndex={1}>
                <GridItem w='100%' h={{ base: 'calc(100vh - 100px)', lg: 'full' }} bg='#fbfbfb' colSpan={{ base: 20, lg: 13 }}>
                    <RideMapSection
                        drivers={getCalculatedData() || []}
                        selected={selectedEntity}
                        setSelected={setSelectedEntity}
                        driversHashMap={driversHashMap.current || {}}
                        ridesHashMap={ridesHashMap.current || {}}
                    />
                </GridItem>
                <GridItem w='100%' h='full' colSpan={{ base: 20, lg: 7 }}>
                    <StatsDrawer
                        onQueryChange={onQueryChange}
                        query={query}
                        disclosure={filtersDisclosure}
                        data={getCalculatedData() || []}
                        rawData={dirversArray.current || []}
                        filteredData={getFilteredData() || []}
                        selected={selectedEntity}
                        setSelected={setSelectedEntity}
                    />
                </GridItem>
            </Grid>
        </Box >
    )
}

export default LiveMap