import {
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import CustomSelect from "../../Forms/CustomSelect";
import APP_ICONS from "../../../config/constants/icons";

const FilterBar = ({
  searchPlaceholder,
  hideSearch,
  filterBarProps,
  searchKey,
  limit,
  searchBarProps,
  filters = [],
  onQueryChange,
  setCurrentPage,
  isRefreshing,
  onRefresh,
  query,
  filtersLoading,
  searchHint,
  filterBarRightAddons,
  customFilters
}) => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      flexWrap={"wrap"}
      h="50px"
      my={2}
      justify="space-between"
      align="center"
      px="5px"
    >
      <HStack maxW={"full"} spacing={3} {...filterBarProps}>
        {!hideSearch && (
          <InputGroup maxW={{ base: "120px", xl: "220px" }}>
            <Input
              type="search"
              placeholder={searchPlaceholder ? searchPlaceholder : "Search"}
              rounded="sm"
              size="sm"
              maxW={{ base: "120px", xl: "220px" }}
              bg={getColor(colorKeys.tableBackground, colorMode)}
              onChange={(e) => {
                onQueryChange({
                  [searchKey]: e.target.value,
                  PageNumber: 1,
                  PageSize: limit,
                });
                setCurrentPage(1);
              }}
              {...searchBarProps}
            />
            {searchHint && (
              <InputRightElement>
                <Tooltip label={searchHint} aria-label="search hint">
                  <Icon
                    mb={"8px"}
                    boxSize={5}
                    color={getColor(colorKeys.dimText, colorMode)}
                  />
                </Tooltip>
              </InputRightElement>
            )}
          </InputGroup>
        )}
        {filters?.map((item, index) => {
          return (
            <CustomSelect
              key={index}
              options={item.options?.map((i) => ({
                value: i.key,
                label: i.value,
              }))}
              placeholder={item.title}
              onInputChange={(e) => onQueryChange({ [item.searchKey]: e })}
              onChange={(value) => {
                onQueryChange({ [item.key]: value, PageNumber: 1 });
                setCurrentPage(1);
              }}
              value={query[item.key]}
              isLoading={filtersLoading?.[item.key] === true}
            />
          );
        })}
        {customFilters}
      </HStack>
      <HStack spacing={3}>
        {filterBarRightAddons}
        {onRefresh && (
          <Button
            minW="100px"
            size="sm"
            leftIcon={<Icon as={APP_ICONS.REFRESH} fontSize={"20px"} />}
            isLoading={isRefreshing}
            onClick={onRefresh}
          >
            Refresh
          </Button>
        )}
      </HStack>
    </Flex>
  );
};

export default FilterBar;
