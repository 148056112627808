export const customStripeTheme = {
    theme: 'stripe',
    variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '0.125rem',
        spacingGridRow:"20px"
    },
    rules: {
        '.Input': {
            fontSize: "14px",
            padding: "7px 40px 7px 12px"
        },
        '.Input:focus': {
            boxShadow: '0 0 0 1px #3182ce',
            border: "1px solid",
            borderColor: "#3182ce",
            fontSize: "14px",
        },
        '.Input--invalid': {
            boxShadow: "0 0 0 1px #E53E3E",
            borderColor: "#E53E3E",
        },
        '.Error': {
            marginTop: "8px"
        },
        '.Label': {
            fontSize: "13px",
            fontWeight: "500",
        },
        '.p-GridCell--sm12': {
            marginTop:"100px"
        }
    }
}