import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardBody,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import TableHeaderOptions from "../../../components/BasicUI/CustomTable/TableHeaderOptions";
import CustomTabs from "../../../components/BasicUI/CustomTabs";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import Shifts from "./Shift";
import APP_ICONS from "../../../config/constants/icons";
import Configuration from "./Configuration";
import { APP_MODULES } from "../../../config/constants";
import City from "./City";
import States from "./State";
import Countries from "./Countries";
import Integration from "./Integrations";
import Color from "./Color";
// import Files from "./Files";

const Settings = () => {
  const { colorMode } = useColorMode();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const shiftDisclosure = useDisclosure();
  const cityDisclosure = useDisclosure();
  const stateDisclosure = useDisclosure();
  const countryDisclosure = useDisclosure();

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const { pathname } = useLocation();

  const tabs = [
    {
      index: 0,
      addButtonText: "Add New Shift",
      head: "Shifts",
      key: "shifts",
      body: (
        <Shifts
          disclosure={shiftDisclosure}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      ),
      permissionKey: APP_MODULES.Shift,
      disclosure: shiftDisclosure,
    },
    {
      index: 1,
      addButtonText: false,
      head: "Configurations",
      key: "configurations",
      permissionKey: APP_MODULES.SystemConfigurations,
      body: <Configuration />,
    },
    {
      index: 2,
      addButtonText: false,
      head: "Integrations",
      key: "integrations",
      permissionKey: APP_MODULES.SystemIntegrations,
      body: <Integration />,
    },
    {
      index: 3,
      addButtonText: "Add City",
      head: "City",
      key: "cities",
      permissionKey: APP_MODULES.City,
      body: <City
        disclosure={cityDisclosure}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />,
      disclosure: cityDisclosure,
    },
    {
      index: 4,
      addButtonText: "Add State",
      head: "State",
      key: "state",
      permissionKey: APP_MODULES.State,
      body: <States
        disclosure={stateDisclosure}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />,
      disclosure: stateDisclosure,
    },
    {
      index: 5,
      addButtonText: "Add Country",
      head: "Country",
      key: "country",
      permissionKey: APP_MODULES.Country,
      body: <Countries
        disclosure={countryDisclosure}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />,
      disclosure: countryDisclosure,
    },
    {
      index: 6,
      addButtonText: "Add Color",
      head: "Color",
      key: "color",
      permissionKey: APP_MODULES.City,
      body: <Color
        disclosure={stateDisclosure}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />,
      disclosure: stateDisclosure,
    },
    // {
    //   index: 6,
    //   head: "Files",
    //   key: "files",
    //   permissionKey: APP_MODULES.SystemConfigurations,
    //   body: <Files
    //     disclosure={countryDisclosure}
    //     selectedRow={selectedRow}
    //     setSelectedRow={setSelectedRow}
    //     isRefreshing={isRefreshing}
    //     setIsRefreshing={setIsRefreshing}
    //   />,
    //   disclosure: countryDisclosure,
    // },
  ];

  const [selectedTab, setSelectedTab] = React.useState(
    tabs.find((tab) => pathname.includes(tab.key)) || tabs[0]
  );

  const onTabChange = (index) => {
    navigate(`/admin/settings/${tabs[index].key}`);
    setSelectedTab(tabs[index]);
    setSelectedRow(null);
    setIsRefreshing(false);
  };

  useEffect(() => {
    setSelectedTab(tabs.find((tab) => pathname.includes(tab.key)) || tabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box>
      <TableHeaderOptions
        title={"Settings"}
        actionText={selectedTab?.addButtonText}
        icon={APP_ICONS.ADD}
        action={() => {
          setSelectedRow(null);
          selectedTab?.disclosure?.onOpen();
        }}
        actionProps={{ minW: 200 }}
        containerProps={{ mt: 3 }}
        permissionKey={selectedTab?.permissionKey}
      />

      <Card mt={4}>
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
          <CustomTabs
            tabPanelProps={{
              p: 0,
            }}
            containerProps={{
              index: selectedTab?.index,
              onChange: onTabChange,
              isLazy: true,
            }}
            tabs={tabs}
            {...(selectedTab?.index === 0 && ({
              tabEndAction: {
                label: "Refresh",
                action: () => {
                  setIsRefreshing(true);
                  queryClient.refetchQueries(["shifts"]);
                },
                isLoading: isRefreshing,
              }
            }))}
          />
        </CardBody>
      </Card>
    </Box>
  );
};

export default Settings;
