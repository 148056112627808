import { Badge } from '@chakra-ui/react'
import React from 'react'
import { getRideStatusColor } from '../../../config/constants/appColors'
import { RIDE_STATUS } from '../../../config/constants/enums'

const StatusBadge = ({ value, activeStatus = 1, isLoading, loadingText = "Loading...", boolean, bookingStatus, ...rest }) => {
    if (bookingStatus) {
        return <Badge textAlign={"center"} minW="61px" bg={getRideStatusColor(value)} color="#fff" {...rest}>
            {isLoading ? loadingText : RIDE_STATUS[value] || value || "N/A"}
        </Badge>
    }
    if (boolean) {
        return <Badge textAlign={"center"} minW="61px" colorScheme={value ? "green" : "red"} {...rest}>
            {isLoading ? loadingText : (value ? "Yes" : "No")}
        </Badge>
    }
    return (
        <Badge textAlign={"center"} minW="61px" colorScheme={value?.id === activeStatus ? "green" : "red"} {...rest}>
            {isLoading ? loadingText : (value?.name || value || "N/A")}
        </Badge>
    )
}

export default React.memo(StatusBadge)