import moment from "moment"

export const preparePayload = (values, id) => {
    values.timeFrom = moment(values.timeFrom, "HH:mm").format("HH:mm")
    values.timeTo = moment(values.timeTo, "HH:mm").format("HH:mm")
    const payload = {
        ...values,
        id,
    };

    return payload
}