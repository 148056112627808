import { saveAs } from "file-saver"

export const ALLOWED_FILE_TYPES_META = {
    IMAGE: ["image/png", "image/jpeg", "image/jpg"],
    VIDEO: ["video/mp4", "video/mov", "video/avi", "video/wmv", "video/flv", "video/mkv", "video/webm"],
}

export const getFileExtention = (file, withDot = true) => {
    let ext = file.name.split('.').pop()
    ext = ext.toLowerCase();
    if (withDot) {
        ext = "." + ext;
    }
    return ext;
}

export const saveFile = ({ content, fileName, contentType, contentFormat }) => {
    return saveAs(`data:${contentType};${contentFormat},${content}`, `Ride Management Software Tenant Admin - ${fileName}`);
}