import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CHAT_BOX_HEIGHT,
  CHAT_DRAWER_HEIGHT,
} from "../../../config/constants/ui";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";
import { CHAT_BOX_WIDTH } from "../../../config/constants/ui";
import CustomBottomDrawer from "../../BasicUI/DataBoxes/CustomBottomDrawer";
import CustomTabs from "../../BasicUI/CustomTabs";
import InboxList from "../Inboxes/InboxList";
import IssuesInbox from "../Inboxes/IssuesInbox";
import GroupsInbox from "../Inboxes/GroupsInbox";
import ChatForm from "../../../views/admin/Chats/ChatForm";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { clearTotalUnreadCount } from "../../../config/redux/slices/chatSlice";

const RootMessageDrawer = ({ handleOpenChat }) => {
  const dispatch = useDispatch();
  const chatFormDisclosure = useDisclosure();
  const queryClient = useQueryClient();
  const [query, setQuery] = useState("");
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [shake, setShake] = useState(false);
  const totalUnreadCount = useSelector((state) => state.chat.totalUnreadCount);

  const { colorMode } = useColorMode();

  const onAddNew = (e) => {
    e.stopPropagation();
    chatFormDisclosure.onToggle();
  };

  const handleOpenDrawer = () => {
    onOpen();
    dispatch(clearTotalUnreadCount());
  };

  useEffect(() => {
    let timeout = null;
    if (totalUnreadCount > 0 && !isOpen) {
      setShake(true);
      timeout = setTimeout(() => {
        setShake(false);
      }, 300);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [totalUnreadCount, isOpen]);

  return (
    <React.Fragment>
      <Button
        variant={"unstyled"}
        id="chat-box-abs"
        h={CHAT_BOX_HEIGHT}
        w={CHAT_BOX_WIDTH}
        bg={
          totalUnreadCount
            ? getColor(colorKeys.primaryButtonFill, colorMode)
            : getColor(colorKeys.dark, colorMode)
        }
        className={shake && "shake"}
        pos="fixed"
        bottom="0"
        right="45px"
        transition={"all .3s ease"}
        zIndex={!isOpen && 9}
        onClick={handleOpenDrawer}
        color={getColor(colorKeys.alwaysWhite, colorMode)}
        rounded="none"
      >
        <Flex px="4" align={"center"} justify={"space-between"}>
          <Box fontSize="13px">Messages</Box>
          <Flex>
            {totalUnreadCount ? (
              <Badge
                mt="2px"
                w="20px"
                h="20px"
                rounded="full"
                bg="red"
                color="white"
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr={1}
                variant={"solid"}
              >
                {totalUnreadCount}
              </Badge>
            ) : null}
            <Icon boxSize="6" as={APP_ICONS.UpChevron} />
          </Flex>
        </Flex>
      </Button>
      <CustomBottomDrawer
        isOpen={isOpen}
        h={CHAT_DRAWER_HEIGHT}
        w={CHAT_BOX_WIDTH}
        right={"45px"}
      >
        <Box rounded={"none"} bg={getColor(colorKeys.white, colorMode)}>
          <Box
            cursor={"pointer"}
            onClick={onClose}
            px="2"
            py="0"
            rounded={"none"}
            textAlign={"center"}
            minH={CHAT_BOX_HEIGHT}
            bg={getColor(colorKeys.primaryButtonFill, colorMode)}
            color={getColor(colorKeys.alwaysWhite, colorMode)}
            borderBottomWidth="1px"
          >
            <Flex
              h={CHAT_BOX_HEIGHT}
              rounded={"none"}
              align={"center"}
              justify={"space-between"}
            >
              <Box pl="2" fontSize="13px">
                Messages
              </Box>
              <HStack mr={2}>
                <Icon onClick={onAddNew} boxSize="6" as={APP_ICONS.ADD} />
                <Icon as={APP_ICONS.CLOSE} boxSize="4" />
              </HStack>
            </Flex>
          </Box>
          <Box p="0">
            <CustomTabs
              containerProps={{
                isFitted: true,
                p: 0,
                onChange: () => setQuery(""),
              }}
              tabItemProps={{ fontSize: "sm" }}
              tabPanelProps={{ p: 0 }}
              tabs={[
                {
                  head: "Support",
                  key: "support",
                  body: (
                    <InboxList handleOpenChat={handleOpenChat} query={query} />
                  ),
                },
                {
                  head: "Disputes",
                  key: "disputes",
                  body: (
                    <IssuesInbox
                      handleOpenChat={handleOpenChat}
                      query={query}
                    />
                  ),
                },
                {
                  head: "Groups",
                  key: "groups",
                  body: (
                    <GroupsInbox
                      handleOpenChat={handleOpenChat}
                      query={query}
                    />
                  ),
                },
              ]}
              middleComponent={
                <InputGroup size="sm" variant="filled">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={APP_ICONS.SEARCH} />}
                  />
                  <Input
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                    type="search"
                    variant="filled"
                    placeholder="Search chats..."
                  />
                </InputGroup>
              }
            />
          </Box>
        </Box>
      </CustomBottomDrawer>
      <ChatForm
        disclosure={chatFormDisclosure}
        onSuccess={() => queryClient.invalidateQueries(["general-inbox"])}
      />
    </React.Fragment>
  );
};

export default RootMessageDrawer;
