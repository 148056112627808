import React from 'react'
import { getMapMarker } from '../../../config/helpers/mapHelper'
import { Marker } from '@react-google-maps/api'

const MapMarker = ({ setSelected, item }) => {
    return (
        <Marker
            onClick={() => setSelected(item)}
            position={item.driverLocation ? item.driverLocation : item?.stops?.at(0)?.location}
            options={{
                icon: {
                    url: getMapMarker(item?.status?.id),
                },
            }}
            clickable={true}
        />
    )
}

export default MapMarker