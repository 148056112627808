import { useEffect, useState } from "react";

const useChatWindows = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    if (screenWidth < 1080) return 0;
    if (screenWidth < 1460) return 1;
    if (screenWidth < 1850) return 2;
    if (screenWidth < 2250) return 3;
    if (screenWidth >= 2250) return 4;
    return 3
}

export default useChatWindows