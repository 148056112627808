import React from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

const ConfirmModal = ({
  disclosure,
  title,
  subtitle,
  onConfirm,
  confirmText,
  confirmButtonProps,
  cancelText = "Cancel",
  isLoading,
}) => {
  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isCentered={true}
      isOpen={disclosure?.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={disclosure?.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{subtitle}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={disclosure?.onClose}>
              {cancelText}
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="red"
              onClick={onConfirm}
              ml={3}
              {...confirmButtonProps}
            >
              {confirmText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmModal;
