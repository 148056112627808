import React from "react";
import { SimpleGrid, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import FormModal from "../../../components/Forms/FormModal";
import FormSearchSelect from "../../../components/Forms/FormSearchSelect";
import { makeSelectList } from "../../../config/helpers/selectListHelper";
import { INVOICE_PAY_PERIOD } from "../../../config/constants/enums";
import { useParams } from "react-router";
import { useUpdatePayPeriod } from "../../../config/query/invoiceQuery";

const PayPeriodForm = ({ disclosure }) => {
  const queryClient = useQueryClient();
  const { corporateId } = useParams();

  const updatePayPeriod = useUpdatePayPeriod();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm({
   
  });

  const onSubmit = (values) => {
    const payload = {
      corporateId: Number(corporateId),
      payPeriod: values.payPeriod,
    };

    updatePayPeriod
      .mutateAsync(payload)
      .then(() => {
        disclosure.onClose();
        resetForm();
        queryClient.invalidateQueries(["direct-invoices"]);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return (
    <FormModal
      title={"Update Pay Period"}
      disclosure={disclosure}
      isSubmitting={updatePayPeriod.isLoading || updatePayPeriod.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"30rem"}
      saveButtonText={"Save"}
      reset={resetForm}
      onCloseFn={() => resetForm()}
    >
      <VStack spacing={5} align="stretch">
        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={5}>
          <FormSearchSelect
            id="payPeriod"
            control={control}
            errors={errors}
            label={"Pay Period"}
            placeholder={"Select Pay Period"}
            options={makeSelectList(INVOICE_PAY_PERIOD)}
            containerStyles={{ mt: 5 }}
            inputProps={{ size: "sm" }}
          />
        </SimpleGrid>
      </VStack>
    </FormModal>
  );
};

export default PayPeriodForm;
