import { APP_MODULES, APP_PERMISSION_ACTIONS } from "../../constants";
import { BOOKINGS_STATUSES, BOOKING_ACTIONS, PAYMENT_TYPE, PAYMENT_TYPES, RIDES_DRIVER_STATUS, RIDE_STATUSES } from "../../constants/enums";
import { formatDateTimeWithoutConvertingToUtc } from "../dateHelper";
import { getPermission } from "../permissionHelper";
import { breakWithCaps, formatAmount } from "../stringHelper";

export const prepareForm = ({ serverValues }) => ({
    pickup: {
        label: serverValues?.rideStops?.at(0)?.address,
        latLng: serverValues?.rideStops?.at(0)?.location,
    },
    destination: {
        label: serverValues?.rideStops?.at(serverValues?.rideStops?.length - 1)?.address,
        latLng: serverValues?.rideStops?.at(serverValues?.rideStops?.length - 1)?.location,
    },
    stops: serverValues?.rideStops?.slice(1, serverValues?.rideStops?.length - 1).map((item) => ({
        label: item?.address,
        latLng: item?.location,
    })),
    rideType: serverValues?.rideType?.id,
    rideTime: serverValues?.rideTime,
    dispatchType: serverValues?.dispatchType?.id,
    paymentMethod: serverValues?.paymentMethod?.id,
    promoCodeId: serverValues?.promoCode?.id,
    promoCode: serverValues?.promoCode?.name,
    dispatcherNote: serverValues?.dispatcherNote,
    internalNote: serverValues?.internalNote,
    vehicleTypeId: serverValues?.vehicleType?.id,
    localNumber: serverValues?.customerPhone,
    customerType: serverValues?.customerType?.id,
    creditCardId: serverValues?.card?.id,
    externalWalletTypeId: serverValues?.externalWalletType?.id,
    corporateId: serverValues?.corporate?.id,
});

export const preparePayload = ({ values }) => {
    const dataKeys = [
        "rideId",
        "rideTime",
        "dispatchType",
        "driverId",
        "vehicleTypeId",
        "dispatcherNote",
        "internalNote",
        "promoCodeId",
        "paymentMethod",
        "payWithWallet",
        "bookingStops",
        "creditCardId",
        "externalWalletType"
    ]

    //if no values return empty object
    if (!values) return {}

    //make bookingStops array
    const stopsValues = values.stops.map((item) => ({
        name: item.name,
        address: item.label,
        location: item.latLng,
    }));
    values.bookingStops = [
        {
            name: values.pickup.name,
            address: values.pickup.label,
            location: values.pickup.latLng,
        },
        ...stopsValues,
        {
            name: values.destination.name,
            address: values.destination.label,
            location: values.destination.latLng,
        },
    ]



    //delete anykey with undefined type value
    let payload = Object.keys(values).reduce((acc, key) => {
        // if (enabledFields.everyField || enabledFields.fields[key]) {
        if (typeof values[key] !== 'undefined') {
            acc[key] = values[key];
        }
        // }
        return acc
    }, {})

    //make payload according to dataKeys array
    Object.keys(payload).forEach((key) => {
        if (!dataKeys.includes(key)) {
            delete payload[key]
        }
    })

    //Conversions for server
    payload.promoCodeId = values.promoCodeData?.id || null
    payload.quotedFare = Number(values.quotedFare)
    payload.rideTime = formatDateTimeWithoutConvertingToUtc(values.rideTime)
    // payload.dispatchType = payload.driverId ? 1 : 0
    payload.creditCardId = typeof payload?.externalWalletType?.id !== "undefined" ? null : payload?.creditCardId
    payload.dispatchType = payload.dispatchType ? 1 : 0

    delete payload.externalWalletType

    return payload
}

//if this function returns true show apply button or else show applied
export const getPromoCodeStatus = ({ promoCode, promoCodeData }) => {
    if (!promoCode) {
        return false
    }
    if (promoCode === promoCodeData?.name) {
        return false //means applied
    }
    else if (!promoCodeData) {
        return true //means apply
    }
    else {
        return true
    }
}

export const getEnabledFields = ({ status }) => {
    if (status === RIDE_STATUSES.WaitingForAccept) {
        return {
            everyField: false,
            fields: {
                internalNote: true,
                dispatcherNote: true,
                bookingStops: true,
            }
        }
    }
    else if (status === RIDE_STATUSES.ParkingLot || status === RIDE_STATUSES.DriverOnTheWay || status === RIDE_STATUSES.Arrived || status === RIDE_STATUSES.Accepted || status === RIDE_STATUSES.InTransit) {
        return {
            everyField: false,
            fields: {
                dispatchType: true,
                driverId: true,
                bookingStops: true,
                rideTime: true,
                vehicleTypeId: true,
                internalNote: true,
                dispatcherNote: true,
                promoCode: true,
                promoCodeId: true,
                paymentMethod: true
            }
        }
    }

    return {
        everyField: false,
        fields: {}
    }
}

export const getStatusCounts = (data) => {
    const statusCounts = data.reduce((counts, item) => {
        const status = item?.status?.id;
        //only adding to total counts if
        //driver is not offlines
        if (RIDES_DRIVER_STATUS.OFFLINE !== status) {
            counts.total ? counts.total++ : counts.total = 1;
        }
        //setting 0 if count for the current
        //status does not exist
        if (!counts[status]) {
            counts[status] = 0;
        }
        //adding 1 to the current status count
        counts[status]++;
        return counts;
    }, {});
    return statusCounts;
}

export const getRideTotal = (extras) => {
    let result = Number(extras?.baseFare)
        + Number(extras?.techFee)
        + Number(extras?.corporateAmount)
        + Number(extras?.otherFares)
        + Number(extras?.tollFee)
        + Number(extras?.tip)
        - Number(extras?.discount)

    if (!isNaN(extras?.tax)) {
        result += extras?.tax
    }
    return result
}

export const renderUpdateBooking = (status) => {
    if (status === RIDE_STATUSES.WaitingForAccept) return true
    if (status === RIDE_STATUSES.DriverOnTheWay || status === RIDE_STATUSES.Arrived) return true
    if (status === RIDE_STATUSES.Accepted) return true
    if (status === RIDE_STATUSES.ParkingLot) return true
    if (status === RIDE_STATUSES.InTransit) return true
    return false
}

export const renderCompleteBooking = (bookingStatus, status) => {
    return bookingStatus === BOOKINGS_STATUSES.Active && status === RIDE_STATUSES.InTransit
}

export const renderBookingAction = (action, bookingPermissions) => {
    return bookingPermissions === true || bookingPermissions?.additionalActions?.includes(action)
}

export const getBookingActions = ({
    transactionsDisclosure,
    ratingsDisclosure,
    dispatchLogsDisclosure,
    costBreakdownDisclosure,
    handleTrackBooking,
    bookingFormDisclosure,
    completeDisclosure,
    trackLoading,
    bookingData = {},
    permissions,
    isGlobal
}) => {
    const bookingPermissions = getPermission({ isGlobal, permissionKey: APP_MODULES.Booking, permissions })
    return [
        {
            name: "Transactions",
            action: transactionsDisclosure.onOpen,
            render: renderBookingAction(BOOKING_ACTIONS.TransactionLogs, bookingPermissions)
        },
        {
            name: "Ratings",
            action: ratingsDisclosure.onOpen,
            render: renderBookingAction(BOOKING_ACTIONS.Ratings, bookingPermissions)
        },
        {
            name: "Dispatch Logs",
            action: dispatchLogsDisclosure.onOpen,
            render: renderBookingAction(BOOKING_ACTIONS.DispatchLogs, bookingPermissions)
        },
        {
            name: "Cost Breakdown",
            action: costBreakdownDisclosure.onOpen,
            render: renderBookingAction(BOOKING_ACTIONS.CostBreakdown, bookingPermissions) && bookingData?.bookingStatus?.id === BOOKINGS_STATUSES.Finished
        },
        {
            name: "Track Booking",
            isLoading: trackLoading,
            action: handleTrackBooking,
            render: renderBookingAction(BOOKING_ACTIONS.TrackBooking, bookingPermissions) && bookingData?.bookingStatus?.id === BOOKINGS_STATUSES.Active
        },
        {
            name: "Update Booking",
            action: bookingFormDisclosure.onOpen,
            render: renderUpdateBooking(bookingData.status?.id) && (bookingPermissions?.[APP_PERMISSION_ACTIONS.UPDATE] || bookingPermissions === true)
            // render: true
        },
        {
            name: "Complete Booking",
            action: completeDisclosure.onOpen,
            render: renderCompleteBooking(bookingData?.bookingStatus?.id, bookingData?.status?.id) || bookingPermissions === true
        },
    ]
}

export const prepareFinishedBookings = (data) => {
    const result = {};
    data?.forEach(item => {
        result[item.title] = {
            count: item.count,
        };
    });
    let total = 0;
    Object.keys(result).forEach(key => {
        total += result[key].count
    })
    Object.keys(result).forEach(key => {
        let percentage = (result[key].count / total) * 100
        result[key].percentage = Number(percentage.toFixed(0))
    })
    return result
}

export const getPaymentMethods = ({ corporatePaymentType, customerType, isCorporatePay = false }) => {

    // Customer Type 0 means individual and 1 means corporate
    // Corporate Payment Type 0 means card and 1 means direct invoice
    // isCorporatePay is Just a boolean from customer details api

    // making payment method array
    const paymentMethods = Object.keys(PAYMENT_TYPE).filter((key) => {
        // if customer type is individual, removing direct invoice and corporate cards
        if (!Boolean(customerType) && Number(key) === PAYMENT_TYPES.DirectInvoice) return false
        if (!Boolean(customerType) && Number(key) === PAYMENT_TYPES.CorporateCard) return false

        //if isCorporatePay is false, removing corporate payment methods
        if (!isCorporatePay && Number(key) === PAYMENT_TYPES.DirectInvoice) return false
        if (!isCorporatePay && Number(key) === PAYMENT_TYPES.CorporateCard) return false

        //if corporate payment type is card, removing direct invoice
        if (corporatePaymentType === 0 && Number(key) === PAYMENT_TYPES.DirectInvoice) return false

        //if corporate payment type is direct invoice, removing corporate card
        if (corporatePaymentType === 1 && Number(key) === PAYMENT_TYPES.CorporateCard) return false
        return key
    }).map((key) => {
        return {
            value: Number(key),
            label: breakWithCaps(PAYMENT_TYPE[key]),
        }
    })

    return paymentMethods
}

export const getCostBreakdownValueProps = (fares, key) => {
    let value = formatAmount(fares[key]);
    let valueProps = { textAlign: "center" };
    if (Number(fares[key]) < 0) {
        value = `(${formatAmount(Math.abs(fares[key]))})`;
        valueProps = { ...valueProps, color: "red.500" };
    }
    return { valueProps, value }
}