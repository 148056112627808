import { useEffect, useState } from "react";
import { Icon, Text } from "@chakra-ui/react";
import CustomTable from "../../../../components/BasicUI/CustomTable";
import { makeInitialQueryObject } from "../../../../config/helpers/queryHelper";
import {
  useDriverLogs,
  useExportDriverLogs,
} from "../../../../config/query/reportsQuery";
import useDebounce from "../../../../config/hooks/useDebounce";
import { APP_MODULES } from "../../../../config/constants";
import { getFilters } from "../../../../config/helpers/filterHelper";
import { useDriverOptions } from "../../../../config/query/driverQuery";
import PrimaryButton from "../../../../components/BasicUI/Buttons/PrimaryButton";
import CustomDateInput from "../../../../components/Forms/CustomDateInput";
import moment from "moment";
import { formatDateTime } from "../../../../config/helpers/dateHelper";
import { saveFile } from "../../../../config/constants/fileHelper";
import APP_ICONS from "../../../../config/constants/icons";
import { breakWithCaps } from "../../../../config/helpers/stringHelper";
import { Link } from "react-router-dom";

const DriverLogs = ({ setIsRefreshing, isRefreshing }) => {
  const [query, setQuery] = useState(
    makeInitialQueryObject({ Sort: undefined, PageSize: 15 })
  );
  const debouncedQuery = useDebounce(query);

  const driverLogsQuery = useDriverLogs(debouncedQuery);
  const driverLogsExportQuery = useExportDriverLogs();
  const driverOptions = useDriverOptions(debouncedQuery?.DriverIdQuery);

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  const onExport = () => {
    const payload = { ...query };
    delete payload.PageNumber;
    delete payload.PageSize;
    driverLogsExportQuery
      .mutateAsync(payload)
      .then((res) => {
        saveFile({
          content: res?.data,
          fileName: `Driver Logs ${formatDateTime(new Date())}.xlsx`,
          contentFormat: "base64",
          contentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      })
      .catch((err) => console.warn(err));
  };

  useEffect(() => {
    if (isRefreshing)
      driverLogsQuery.refetch().then(() => setIsRefreshing(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing]);

  const filters = {
    DriverId: driverOptions?.data?.data?.map((item) => ({
      key: item.id,
      value: item.name,
    })),
  };

  return (
    <CustomTable
      fixedHeight={true}
      searchKey="Keyword"
      permissionKey={APP_MODULES.Reports}
      tableWrapperProps={{ padding: 0, h: "calc(100vh - 370px)" }}
      containerProps={{ w: "full", h: "calc(100vh - 240px)" }}
      filters={getFilters(filters)}
      tableHeadTextProps={{ paddingRight: "30px" }}
      head={[
        {
          title: "Driver Name",
          extractor: "driverName",
          component: (item) => (
            <Text
              _hover={{ textDecor: "underline" }}
              as={Link}
              to={`/admin/drivers?AdvancedSearch.Keyword=${item.driverName}`}
            >
              {item.driverName}
            </Text>
          ),
        },
        { title: "National ID", extractor: "nationalId" },
        { title: "Phone", extractor: "phone" },
        {
          title: "date",
          extractor: "date",
          component: (item) => formatDateTime(item?.date),
        },
        {
          title: "Action",
          extractor: "action",
          component: (item) => breakWithCaps(item.action?.name),
        },
        {
          title: "Booking#",
          extractor: "rideId",
          component: (item) =>
            item.rideId ? (
              <Text
                _hover={{ textDecor: "underline" }}
                as={Link}
                to={`/admin/bookings/${item.rideId}`}
              >
                {item.rideId}
              </Text>
            ) : (
              "N/A"
            ),
        },
        { title: "Vehicle Type", extractor: "vehicleType" },
        { title: "License Plate", extractor: "licensePlate" },
      ]}
      data={driverLogsQuery.data?.data}
      loading={driverLogsQuery?.isLoading}
      totalResults={driverLogsQuery?.data?.meta?.totalCount}
      totalPages={driverLogsQuery?.data?.meta?.totalPages}
      pageNo={query?.PageNumber}
      pageSize={query?.PageSize}
      onQueryChange={onQueryChange}
      query={query}
      filterBarRightAddons={
        <PrimaryButton
          size="sm"
          onClick={onExport}
          isLoading={driverLogsExportQuery.isLoading}
          isDisabled={!query?.DriverId || !query?.FromDate || !query?.ToDate}
          title={
            (!query?.DriverId || !query?.FromDate || !query?.ToDate) &&
            "Please select driver, from date and to date to download the report"
          }
        >
          <Icon boxSize={5} as={APP_ICONS.SAVE} />
        </PrimaryButton>
      }
      customFilters={
        <>
          <CustomDateInput
            containerProps={{ maxW: "210px" }}
            groupProps={{ size: "sm" }}
            hideLabel={true}
            value={query.FromDate}
            maxDate={
              query.ToDate
                ? new Date(moment(query.ToDate).format("YYYY-MM-DD"))
                : new Date()
            }
            onChange={(e) =>
              onQueryChange({
                FromDate: e ? moment(e).format("YYYY-MM-DD") : "",
              })
            }
            inputProps={{ size: "sm", fontSize: "12px" }}
            placeholder={"From Date"}
          />
          <CustomDateInput
            containerProps={{ maxW: "210px" }}
            groupProps={{ size: "sm" }}
            hideLabel={true}
            value={query.ToDate}
            maxDate={new Date()}
            minDate={
              query.FromDate
                ? new Date(
                    moment(query.FromDate)
                      .subtract(1, "day")
                      .format("YYYY-MM-DD")
                  )
                : new Date()
            }
            onChange={(e) =>
              onQueryChange({
                ToDate: e ? moment(e).format("YYYY-MM-DD") : "",
              })
            }
            inputProps={{ size: "sm", fontSize: "12px" }}
            placeholder={"To Date"}
          />
        </>
      }
      isRefreshing={driverLogsQuery?.isFetching}
      filtersLoading={{
        DriverId: driverOptions.isFetching,
      }}
    />
  );
};

export default DriverLogs;
