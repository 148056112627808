import React from 'react'
import FirstPersonMessage from '../Messages/FirstPersonMessage'
import SecondPersonMessage from '../Messages/SecondPersonMessage'
import InfoMessage from '../Messages/InfoMessage'
import { useSelector } from 'react-redux'
import { CHAT_MESSAGE_TYPE } from '../../../config/constants/enums'

const MessageItem = ({ message }) => {
    const user = useSelector((state) => state.user?.user)
    if (message?.messageType?.id === CHAT_MESSAGE_TYPE.Info) {
        return <InfoMessage message={message} />
    }
    if (message?.user?._id === user?.id) {
        return <FirstPersonMessage text={message?.text} message={message} />
    }
    return <SecondPersonMessage text={message?.text} message={message} />
}

export default MessageItem