import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import APP_ICONS from "../../../../config/constants/icons";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import PrimaryButton from "../../../../components/BasicUI/Buttons/PrimaryButton";
import { useExportReport } from "../../../../config/query/reportsQuery";
import { formatDateTime } from "../../../../config/helpers/dateHelper";
import { saveFile } from "../../../../config/constants/fileHelper";
import { useZoneOptions } from "../../../../config/query/zoneQuery";
import CustomSelect from "../../../../components/Forms/CustomSelect";
import { makeSelectList } from "../../../../config/helpers/selectListHelper";
import CustomDateInput from "../../../../components/Forms/CustomDateInput";

const DriverReports = () => {
  const { colorMode } = useColorMode();

  const [query, setQuery] = useState({});

  const onQueryChange = ({ name, value }) => {
    setQuery({ ...query, [name]: value });
  };

  const exportReport = useExportReport();
  const zoneOptions = useZoneOptions();

  const onExport = () => {
    exportReport
      .mutateAsync({
        ...query,
        fromDate: moment(query.fromDate).format("YYYY-MM-DD"),
        toDate: moment(query.toDate).format("YYYY-MM-DD"),
      })
      .then((res) => {
        saveFile({
          content: res?.data,
          fileName: `Driver Report ${formatDateTime(new Date())}.xlsx`,
          contentFormat: "base64",
          contentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      })
      .catch((error) => console.warn(error));
  };

  return (
    <Box h="calc(100vh - 300px)">
      <SimpleGrid
        mt={5}
        mx="auto"
        columns={{ base: 1, md: 3 }}
        spacing={5}
        w="max-content"
      >
        <CustomSelect
          options={makeSelectList(zoneOptions.data)}
          placeholder={"Select Zone"}
          onChange={(value) => onQueryChange({ name: "ZoneId", value })}
          value={query?.ZoneId}
        />
        <CustomDateInput
          containerProps={{ ml: 2, maxW: "210px" }}
          groupProps={{ size: "sm" }}
          value={query?.fromDate}
          maxDate={
            query.toDate
              ? new Date(moment(query.toDate).format("YYYY-MM-DD"))
              : new Date()
          }
          onChange={(e) => onQueryChange({ name: "fromDate", value: e })}
          inputProps={{ size: "sm", _placeholder: { fontSize: 12 } }}
          placeholder={"From Date"}
          labelProps={{ display: "none" }}
        />
        <CustomDateInput
          containerProps={{ ml: 2, maxW: "210px" }}
          groupProps={{ size: "sm" }}
          value={query?.toDate}
          maxDate={new Date()}
          minDate={
            query.fromDate
              ? new Date(moment(query.fromDate).subtract(1,"day").format("YYYY-MM-DD"))
              : new Date()
          }
          onChange={(e) => onQueryChange({ name: "toDate", value: e })}
          inputProps={{ size: "sm", _placeholder: { fontSize: 12 } }}
          placeholder={"To Date"}
          labelProps={{ display: "none" }}
        />
      </SimpleGrid>
      <Flex mt={20} align="center" justify={"center"}>
        <VStack>
          <Icon
            boxSize={"120px"}
            color={getColor(colorKeys.lightGray, colorMode)}
            as={APP_ICONS.SAVE}
          />
          <Text color={getColor(colorKeys.gray, colorMode)}>
            Download driver reports in excel file.
          </Text>
          <PrimaryButton
            onClick={onExport}
            isLoading={exportReport.isLoading}
            isDisabled={!query?.ZoneId || !query?.fromDate || !query?.toDate}
          >
            Download .xlsx File
          </PrimaryButton>
        </VStack>
      </Flex>
    </Box>
  );
};

export default DriverReports;
