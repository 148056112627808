import React from "react";
import moment from "moment";
import { InputRightAddon, SimpleGrid, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormModal from "../../../../../components/Forms/FormModal";
import FormInput from "../../../../../components/Forms/FormInput";
import { useCreateZoneSurcharge, useUpdateZoneSurcharge } from "../../../../../config/query/zoneSurchargeQuery";
import FormDateInput from "../../../../../components/Forms/FormDateInput";
import FormSearchSelect from "../../../../../components/Forms/FormSearchSelect";
import { REPEAT_TYPE, REPEAT_TYPES, WEEK_DAYS } from "../../../../../config/constants/enums";
import { makeSelectList } from "../../../../../config/helpers/selectListHelper";
import { prepareForm, preparePayload } from "../../../../../config/helpers/formHelpers/zoneSurchargeHelper";

const ZoneSurchargeForm = ({ disclosure, data, zoneId }) => {
    const createZoneSurchargeQuery = useCreateZoneSurcharge();
    const updateZoneSurchargeQuery = useUpdateZoneSurcharge();

    const {
        handleSubmit,
        reset: resetForm,
        control,
        watch,
        formState: { errors },
    } = useForm({
        values: prepareForm(data),
    });

    const onSubmit = (values) => {
        let formMutate = data?.id ? updateZoneSurchargeQuery.mutateAsync : createZoneSurchargeQuery.mutateAsync;
        formMutate(preparePayload(values, data?.id, zoneId))
            .then(() => {
                disclosure.onClose();
                resetForm();
            })
            .catch((error) => console.warn(error));
    };

    return (
        <FormModal
            title={data?.id ? "Edit Zone Surcharge" : "Add Zone Surcharge"}
            disclosure={disclosure}
            isSubmitting={createZoneSurchargeQuery.isLoading || updateZoneSurchargeQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"75rem"}
            saveButtonText={"Save"}
            reset={resetForm}
            onCloseFn={() => resetForm()}
        >
            <VStack spacing={5} align="stretch">
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

                    <FormSearchSelect
                        id="repeatType"
                        options={makeSelectList(REPEAT_TYPE)}
                        label="Repeat Type"
                        control={control}
                        errors={errors}
                        required={true}
                        placeholder="Select repeat type"
                        inputProps={{ size: "sm" }}
                    />

                    <FormInput
                        label={"Surcharge"}
                        control={control}
                        errors={errors}
                        id="surcharge"
                        required={true}
                        placeholder="Enter surcharge"
                        inputProps={{ size: "sm" }}
                        type={"number"}
                        rules={{
                            pattern: {
                                value: /^[0-9]*$/,
                                message: "Please enter valid surcharge"
                            },
                            max: {
                                value: 100,
                                message: "Surcharge must be less than 100"
                            },
                            min: {
                                value: 1,
                                message: "Surcharge must be greater than 0"
                            },
                        }}
                        rightAddon={<InputRightAddon children={"%"} />}
                        size="sm"
                    />

                    <FormInput
                        label={"Name"}
                        control={control}
                        errors={errors}
                        id="name"
                        required={true}
                        placeholder="Enter name"
                        inputProps={{ size: "sm" }}
                    />


                    {watch("repeatType") === REPEAT_TYPES.DaysOfWeek ? (
                        <FormSearchSelect
                            id="daysOfWeek"
                            options={makeSelectList(WEEK_DAYS)}
                            label="Weekdays"
                            control={control}
                            errors={errors}
                            multiple={true}
                            required={true}
                            placeholder="Select week days"
                            inputProps={{ size: "sm" }}
                        />
                    ) : (
                        <FormDateInput
                            label={"Date"}
                            control={control}
                            errors={errors}
                            id={"date"}
                            required={true}
                            placeholder={"Select date"}
                            inputProps={{ size: "sm" }}
                            maxDate={moment().add(1, 'years').format("YYYY-MM-DD")}
                            minDate={moment().format("YYYY-MM-DD")}
                            rules={{
                                validate: (value) => {
                                    if (value < new Date().toISOString().slice(0, 10)) {
                                        return "Expiry date must be greater than today"
                                    }
                                    //check if date is greater than one year from today usind moment
                                    if (moment(value) > moment().add(1, 'years')) {
                                        return "Expiry date must be less than one year from today"
                                    }
                                }
                            }}
                        />
                    )}

                    <FormInput
                        label={"Start Time"}
                        control={control}
                        errors={errors}
                        id="startTime"
                        required={true}
                        type="time"
                        inputProps={{ size: "sm" }}
                        rules={{
                            validate: (value) => {
                                if (value > watch("endTime")) {
                                    return "Start time must be less than end time"
                                }
                            }
                        }}
                    />

                    <FormInput
                        label={"End Time"}
                        control={control}
                        errors={errors}
                        id="endTime"
                        required={true}
                        type="time"
                        inputProps={{ size: "sm" }}
                        rules={{
                            validate: (value) => {
                                if (value < watch("startTime")) {
                                    return "End time must be greater than start time"
                                }
                            }
                        }}
                    />

                </SimpleGrid>
            </VStack>
        </FormModal>
    );
};

export default ZoneSurchargeForm;
