import { useQueryClient } from "@tanstack/react-query";
import { useCreateRoles, usePermissions, useUpdateRole } from "../../../config/query/rolesQuery";
import { useForm } from "react-hook-form";
import { pereparePermissionsValue } from "../../../config/helpers/permissionHelper";
import { useEffect } from "react";
import { preparePayload, watchRoleForm } from "../../../config/helpers/formHelpers/rolesHelper";
import { Flex } from "@chakra-ui/react";

export const useRoleForm = ({ data, disclosure }) => {
    const queryClient = useQueryClient();

    const permissionsQuery = usePermissions();
    const useCreateRolesQuery = useCreateRoles();
    const updateRoleQuery = useUpdateRole();

    const {
        handleSubmit,
        control,
        watch,
        reset: resetForm,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        values: {
            permissions: data?.permissions && pereparePermissionsValue(data?.permissions)?.permissions,
            additionalActions: data?.permissions && pereparePermissionsValue(data?.permissions)?.additionalActions,
            name: data?.name,
            description: data?.description,
            roles: data?.roles?.map((item) => item.id),
        }
    });

    const onSubmit = (values) => {
        let formMutate = data?.id
            ? updateRoleQuery.mutateAsync
            : useCreateRolesQuery.mutateAsync;

        formMutate(preparePayload(values, data?.id))
            .then(() => {
                resetForm();
                disclosure.onClose();
                queryClient.invalidateQueries(["roles"]);
            })
            .catch((error) => console.warn(error));
    };

    const permissions = watch("permissions")

    useEffect(() => {
        const { unsubscribe } = watch((data, { name, type }) => {
            watchRoleForm({ type, data, name, getValues, setValue })
        });
        return () => unsubscribe();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch, permissions])

    return {
        handleSubmit,
        control,
        watch,
        resetForm,
        errors,
        setValue,
        getValues,
        onSubmit,
        permissionsQuery,
        isSubmitting: useCreateRolesQuery.isLoading || updateRoleQuery.isLoading
    }
}

export const getCheckboxProps = (control) => {
    return {
        labelProps: {
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "2px",
        },
        control,
        optionLabelProps: {
            textTransform: "capitalize",
            fontSize: "13px",
        },
        stackDir: "row",
        stackProps: {
            spacing: 5,
            as: Flex,
            gap: 1,
            flexDir: "column",
        },
    };
}