import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, DrawerContent, DrawerOverlay, VStack, useColorMode } from '@chakra-ui/react';
import NavItem from './NavItem';
import { getColor, colorKeys } from "../../../config/constants/appColors"
import { mainMenu } from '../../../config/constants/menus';
import { getPermission } from '../../../config/helpers/permissionHelper';
import Brand from './Brand';

const Sider = ({ disclosure, desktopDisclosure }) => {
    const { pathname } = useLocation()
    const permissions = useSelector((state) => state.user?.permissions)
    const isGlobal = useSelector((state) => state.user?.user?.isGlobal)
    const { colorMode } = useColorMode()

    useEffect(() => {
        disclosure.onClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const SidebarContent = (props) => (
        <Box
            as="nav"
            pos="fixed"
            top="0"
            left="0"
            zIndex="sticky"
            h="full"
            pb="10"
            overflowX="hidden"
            overflowY="auto"
            bg={getColor(colorKeys.siderBackground, colorMode)}
            transition=".3s ease"
            transitionDelay=".2s" // Add the transition delay value here
            w={desktopDisclosure.isOpen ? "70px" : "255px"}
            {...props}
        >
            <Brand />

            <Box borderTop={"1px solid #6c6c6c"}>
                <VStack
                    spacing={1}
                    mt={4}
                    direction="column"
                    as="nav"
                    fontSize="sm"
                    color="primaryText"
                    aria-label="Main Navigation"
                >
                    {mainMenu.map((item, index) => {
                        const isPermitted = getPermission({ permissions, permissionKey: item.permission, isGlobal })
                        if (!isPermitted) return null
                        return (
                            <NavItem
                                key={index}
                                icon={item.icon}
                                title={item.title}
                                link={item.link}
                                subsider={item.subsider}
                                isOpen={!desktopDisclosure.isOpen}
                            />
                        )
                    })}
                </VStack>
            </Box>
        </Box>
    );
    return (
        <React.Fragment>
            <SidebarContent
                display={{
                    base: "none",
                    lg: "unset",
                }}
            />

            <Drawer
                isOpen={disclosure.isOpen}
                onClose={disclosure.onClose}
                placement="left"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <SidebarContent w="full" borderRight="none" />
                </DrawerContent>
            </Drawer>
        </React.Fragment>
    )
}

export default React.memo(Sider)



