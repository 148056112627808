import { Box, Flex, FormLabel, Input, Popover, PopoverBody, chakra, PopoverContent, PopoverTrigger, VStack, useColorMode, useDisclosure, InputGroup, IconButton, Icon, InputRightElement, Wrap, WrapItem, Divider, useOutsideClick, Text } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import { accessValue } from '../../../config/helpers/stringHelper'
import APP_ICONS from '../../../config/constants/icons'
import { useController } from 'react-hook-form'
import { getWidthBySelector } from '../../../config/helpers/styleHelpers'

const FormComboBox = ({ id, placeholder, control, options, required, rules, valueBoxComponent, onChangeEffect, allowClear = true, comparisionArray, comparisionKey = "value", isLoading, searchFn, closeOnSelect, label, size = "sm", hideLabel, containerProps, labelProps, labelContainerProps, groupProps, messageContainerProps, iconProps, inputProps, selectedContainerProps }) => {
    const { colorMode } = useColorMode()
    const disclosure = useDisclosure()
    const initialFocusRef = useRef()

    useOutsideClick({
        ref: initialFocusRef,
        handler: disclosure.onClose,
    })


    if (required) {
        required = `${label} is required`
    }

    const {
        field,
        formState: { errors }
    } = useController({
        name: id,
        control,
        defaultValue: [],
        rules: {
            required: required,
            ...rules
        },
    });

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

    const onSelect = (item) => {
        let values = field.value || []
        field.onChange([...values, item])
        onChangeEffect && onChangeEffect(item)
        closeOnSelect && disclosure.onClose()
    }

    return (
        <Box
            role='none'
            w="full"
            isInvalid={errors[id] || accessValue(errors, `${id}.message`)}
            {...containerProps}
        >
            {!hideLabel && (
                <FormLabel htmlFor={id} fontSize={"13px"} {...labelProps}>
                    <Flex  {...labelContainerProps}>
                        {label}
                        {required ? <chakra.span color={getColor(colorKeys.danger, colorMode)}>*</chakra.span> : null}
                    </Flex>
                </FormLabel>
            )}

            <VStack align={"stretch"} spacing={0}>
                <Popover placement='bottom' isOpen={disclosure.isOpen} initialFocusRef={initialFocusRef} >
                    <PopoverTrigger>
                        <Box>
                            <InputGroup mb={2} className={`combox-box-gc ${id}-group`} size={size} {...groupProps}>
                                <Input
                                    ref={initialFocusRef}
                                    onChange={(e) => {
                                        disclosure.onOpen()
                                        searchFn && searchFn(e.target.value)
                                    }}
                                    onClick={disclosure.onToggle}
                                    size={size}
                                    placeholder={placeholder}
                                    id={id}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault()
                                        }
                                    }}
                                    {...inputProps}
                                />
                                <InputRightElement h="full">
                                    {field.value?.length > 0
                                        ? (allowClear ? <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                field.onChange([])
                                                onChangeEffect && onChangeEffect([])
                                                disclosure.onClose()
                                            }}
                                            variant={"ghost"}
                                            rounded={"none"}
                                            size={size}
                                            icon={<Icon as={APP_ICONS.CLOSE} />}
                                        /> : null)
                                        : <Icon
                                            onClick={disclosure.onToggle}
                                            as={disclosure.isOpen ? APP_ICONS.UpChevron : APP_ICONS.DownChevron}
                                            boxSize={5}
                                            {...iconProps}
                                        />}
                                </InputRightElement>
                            </InputGroup>
                            {!disclosure.isOpen ? <Text color={getColor(colorKeys.danger, colorMode)} fontSize={"13px"} {...messageContainerProps}>
                                {errors[id]?.message}
                            </Text> : null}
                        </Box>
                    </PopoverTrigger>
                    <PopoverContent minW={getWidthBySelector(`.${id}-group`)} maxW="full">
                        <PopoverBody p="0" shadow="lg" border={`1px solid ${getColor(colorKeys.lighterBackgroundFill, colorMode)}`}>
                            {(!isLoading && options?.length === 0) &&
                                <Box fontSize="14px" py="2" px="4" textAlign="center">No options, Type something...</Box>
                            }
                            <VStack align="stretch" spacing={0} divider={<Divider />}>
                                {isLoading && (
                                    <Box fontSize="14px" py="2" px="4" textAlign="center">Loading...</Box>
                                )}
                                {options?.map((item, index) => {
                                    if (comparisionArray) {
                                        if (comparisionArray.find((selectedItem) => selectedItem[comparisionKey] === item.value)) {
                                            return null
                                        }
                                    }
                                    else if (field.value?.find((selectedItem) => selectedItem.value === item.value)) {
                                        return null
                                    }

                                    return (
                                        <Box
                                            onTouchEnd={() => {
                                                isIOS && onSelect(item)
                                            }}
                                            onClick={() => {
                                                !isIOS && onSelect(item)
                                            }}
                                            key={index} fontSize="14px" cursor={"pointer"} py="2" px="4" _hover={{ bg: getColor(colorKeys.lighterBackgroundFill, colorMode) }}>
                                            {item.label}
                                        </Box>
                                    )
                                })}
                            </VStack>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
                {(!valueBoxComponent && field.value?.length > 0) ? (
                    <Wrap spacing={2} {...selectedContainerProps}>
                        {field.value?.map((item, index) => {
                            return (
                                <WrapItem key={index}>
                                    <Flex align="center" bg={getColor(colorKeys.lightBackgroundFill, colorMode)}>
                                        <Box fontSize="14px" py="1" px="2">
                                            {item?.label}
                                        </Box>
                                        <IconButton
                                            onClick={() => {
                                                let values = field.value || []
                                                let newValues = values.filter((selectedItem) => selectedItem.value !== item.value)
                                                field.onChange(newValues)
                                                onChangeEffect && onChangeEffect(newValues)
                                            }}
                                            variant={"ghost"}
                                            rounded={"none"}
                                            size={size}
                                            h="29px"
                                            icon={<Icon as={APP_ICONS.CLOSE} />}
                                        />
                                    </Flex>
                                </WrapItem>
                            )
                        })}
                    </Wrap>
                ) : valueBoxComponent && valueBoxComponent(field.value)}
            </VStack>

        </Box >
    )
}

export default FormComboBox