import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react'
import CustomTabs from '../../../components/BasicUI/CustomTabs'
import Information from './Information'
import NotificationUsers from './NotificationUsers'

const NotificationDetails = ({ disclosure, data }) => {
    const tabs = [
        {
            head: "Information",
            body: <Information data={data} />
        },
    ]

    if (data?.corporates?.length > 0) {
        tabs.push({
            head: "Corporates",
            body: <NotificationUsers users={data?.corporates} />
        })
    }

    if (data?.drivers?.length > 0) {
        tabs.push({
            head: "Drivers",
            body: <NotificationUsers users={data?.drivers} />
        })
    }

    if (data?.customers?.length > 0) {
        tabs.push({
            head: "Customers",
            body: <NotificationUsers users={data?.customers} />
        })
    }
    return (
        <Modal motionPreset='none' isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent maxW={"50rem"}>
                <ModalHeader>{data?.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody >

                    <CustomTabs
                        tabPanelProps={{ p: 0 }}
                        tabs={tabs}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={disclosure.onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default NotificationDetails