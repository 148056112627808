import React, { createContext, useContext, useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { onInitSignalR } from '../helpers/socketHelper';
import { useSelector } from 'react-redux';
import { useNetwork } from '../helpers/apiHelper';

export const SignalRContext = createContext();

export const SignalRProvider = ({ children, serverUrl, accessToken }) => {
    const [hubConnection, setHubConnection] = useState(null);
    const isOnline = useNetwork()
    const openedChats = useSelector((state) => state.chat.openedChats)

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            if (hubConnection?.state === signalR.HubConnectionState.Disconnected) {
                hubConnection?.start()
            }
        }
    }

    useEffect(() => {
        const newHubConnection = new signalR.HubConnectionBuilder()
            .withUrl(serverUrl, {
                accessTokenFactory: () => accessToken,
                transport: signalR.HttpTransportType.WebSockets
            })
            .build();
        setHubConnection(newHubConnection);
    }, [serverUrl, accessToken]);

    useEffect(() => {
        if (hubConnection && isOnline) {
            hubConnection
                .start()
                .then(() => {
                    onInitSignalR(hubConnection, openedChats)
                    console.info('Starting websockets...');
                })
                .catch((error) => console.warn('Error starting SignalR connection: ', error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubConnection, serverUrl, accessToken, isOnline]);

    //use effect to handle reconnection on visibility change
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubConnection])

    return (
        <SignalRContext.Provider value={hubConnection}>
            {children}
        </SignalRContext.Provider>
    );
};


export const useSignalREffect = (callback) => {
    const hubConnection = useContext(SignalRContext);

    useEffect(() => {
        if (hubConnection) {
            if (hubConnection.state === signalR.HubConnectionState.Connected) {
                if (callback && typeof callback === 'function') {
                    callback(hubConnection);
                }
            } else {
                hubConnection.onclose(() => {
                    // Handle reconnection or error scenarios here
                    console.info('Closing websockets...');
                });
            }
        }
    }, [hubConnection, callback]);
};

export const useSignalREffectAlt = (eventName, callback) => {
    const hubConnection = useContext(SignalRContext);

    useEffect(() => {
        const handleEvent = (data) => {
            if (callback && typeof callback === 'function') {
                callback(data);
            }
        };

        if (hubConnection) {
            hubConnection.on(eventName, handleEvent);
        }

        return () => {
            if (hubConnection) {
                hubConnection.off(eventName, handleEvent);
            }
        };
    }, [hubConnection, eventName, callback]);
};

export const useSignalRInvoke = (methodName) => {
    const hubConnection = useContext(SignalRContext);

    const invoke = async (...args) => {
        try {
            if (hubConnection) {
                const result = await hubConnection.invoke(methodName, ...args);
                return result;
            }
        } catch (error) {
            console.warn(`Error invoking SignalR method ${methodName}: `, error);
        }
    };

    return invoke;
};
