import React from "react";
import {
  Button,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { RIDE_CANCELLATION_CHARGE_TYPE } from "../../../config/constants/enums";
import ConfirmPopover from "./ConfirmPopover";

const CancelBookingPopover = ({
  label = "Custom Popover",
  heading = "Heading",
  children,
  triggerProps,
  placement = "bottom",
  disclosure,
  isSubmitting,
  onSubmit,
  setValue,
  chargeType,
}) => {
  const { isOpen, onToggle, onClose } = disclosure;

  return (
    <Box className="custom-popover">
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement={placement}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button onClick={onToggle} {...triggerProps}>
            {label}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">{heading}</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody >{children}</PopoverBody>
          <PopoverFooter display="flex" flexWrap={"wrap"} >
            <Wrap size="sm" flexWrap={"wrap"}>
              <WrapItem>
                <ConfirmPopover
                  message="Are you sure you cant to update this? This action is irreversible."
                  onConfirm={(e) => {
                    setValue('chargeType', RIDE_CANCELLATION_CHARGE_TYPE.CancellationCharge)
                    onSubmit(e)
                  }}
                >
                  <Button
                    size="sm"
                    bg={'#4756b5'}
                    color={"#fff"}
                    isLoading={isSubmitting && chargeType === RIDE_CANCELLATION_CHARGE_TYPE.CancellationCharge}
                    colorScheme="blue"
                    isDisabled={isSubmitting}
                  >
                    Cancellation Charge
                  </Button>
                </ConfirmPopover>
              </WrapItem>
              <WrapItem>
                <ConfirmPopover
                  message="Are you sure you cant to update this? This action is irreversible."
                  onConfirm={(e) => {
                    setValue('chargeType', RIDE_CANCELLATION_CHARGE_TYPE.NoShowCharge)
                    onSubmit(e)
                  }}
                >
                  <Button
                    size="sm"
                    bg={"#35a2b7"}
                    color={"#fff"}
                    isLoading={isSubmitting && chargeType === RIDE_CANCELLATION_CHARGE_TYPE.NoShowCharge}
                    isDisabled={isSubmitting}
                    colorScheme="blue"
                  >
                    No Show Charge
                  </Button>
                </ConfirmPopover>
              </WrapItem>
              <WrapItem>
                <ConfirmPopover
                  message="Are you sure you cant to update this? This action is irreversible."
                  onConfirm={(e) => {
                    setValue('chargeType', RIDE_CANCELLATION_CHARGE_TYPE.NoCharge)
                    onSubmit(e)
                  }}
                >
                  <Button
                    bg={'#48ba78'}
                    color={"#fff"}
                    colorScheme="blue"
                    mr={3}
                    size="sm"
                    type="submit"
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting && chargeType === RIDE_CANCELLATION_CHARGE_TYPE.NoCharge}
                  >
                    No Charge
                  </Button>
                </ConfirmPopover>
              </WrapItem>
            </Wrap>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default CancelBookingPopover;
