import { useState } from 'react';
import { Text, Box, VStack } from "@chakra-ui/react"
import CustomTable from '../../../components/BasicUI/CustomTable'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import { formatDateTimeToZone } from '../../../config/helpers/dateHelper';
import BreakText from '../../../components/BasicUI/DataBoxes/BreakText'
import { useNavigate, useParams } from 'react-router'
import { makeInitialQueryObject } from '../../../config/helpers/queryHelper'
import { useBookings } from '../../../config/query/bookingsQuery'
import { formatAmount } from "../../../config/helpers/stringHelper";
import { RIDE_TYPE } from "../../../config/constants/enums";
import Chronology from "../../../components/BasicUI/DataBoxes/Chronology";
import useDebounce from '../../../config/hooks/useDebounce'
import { APP_MODULES, DEFAULT_DATE_TIME_WITH_ZONE } from '../../../config/constants'
import BookingTablePopover from '../../../components/BasicUI/Popovers/BookingTablePopover'

const CorporateBookings = () => {
  const navigate = useNavigate()
  const { corporateId } = useParams()
  const [query, setQuery] = useState(makeInitialQueryObject({ CorporateId: corporateId }))
  const debouncedQuery = useDebounce(query)

  const bookingsQuery = useBookings(debouncedQuery)

  const onView = (item) => {
    navigate(`/admin/bookings/${item.id}`)
  }

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }))
  }

  return (
    <CustomTable
      tableFor={"booking"}
      permissionKey={APP_MODULES.Booking}
      hideFilterBar={true}
      tableWrapperProps={{
        padding: 0,
        h: "calc(100vh - 460px)"
      }}
      containerProps={{
        h: "calc(100vh - 410px)"
      }}
      head={[
        {
          title: "Booking",
          extractor: "id",
          align: "left",
          isSortable: true,
          component: (item) => <BookingTablePopover data={item} />
        },
        { title: "Fare", extractor: "fare", isSortable: true, align: "center", component: (item) => <Text fontSize="14px">{formatAmount(item?.fare)}</Text> },
        {
          title: "Ride Type",
          extractor: "rideTime",
          align: "left",
          isSortable: true,
          component: (item) => <Box>
            {(!item.rideType && !item.rideTime) && "N/A"}
            <Text fontSize="14px">{item.rideType ? RIDE_TYPE[item.rideType?.id] : ""}</Text>
            <Text fontSize="14px">{formatDateTimeToZone(
              item.rideTime,
              item.timeZoneOffSet,
              DEFAULT_DATE_TIME_WITH_ZONE
            )}</Text>
          </Box>
        },
        {
          title: "Pickup / Dropoff Time",
          extractor: "pickup",
          align: "left",
          component: (item) => <Box>
            {(!item.pickup && !item.dropoff) && "N/A"}
            <Text fontSize="14px">{formatDateTimeToZone(
              item.pickup,
              item.timeZoneOffSet,
              DEFAULT_DATE_TIME_WITH_ZONE
            )}</Text>
            <Text fontSize="14px">{formatDateTimeToZone(
              item.dropoff,
              item.timeZoneOffSet,
              DEFAULT_DATE_TIME_WITH_ZONE
            )}</Text>
          </Box>
        },
        { title: "Pickup Location", extractor: "pickupAddress", align: "left", isSortable: true, component: (item) => <BreakText value={item.pickupAddress} /> },
        { title: "Destination", extractor: "dropoffAddress", align: "left", isSortable: true, component: (item) => <BreakText value={item.dropoffAddress} /> },
        { title: "Flight Number", extractor: "flightNumber", align: "left" },
        { title: "Gate Number", extractor: "gateNumber", align: "left" },
        {
          title: "Passenger", extractor: "passengerName", align: "left", isSortable: true,
          component: (item) => <VStack align="stretch" spacing={2}>
            <Text fontSize="14px"> {(!item.passengerName && !item.passengerPhone) && "N/A"}</Text>
            <Text fontSize="14px">{item.passengerName ? item.passengerName : ""}</Text>
            <Text fontSize="14px">{item.passengerPhone ? item.passengerPhone : ""}</Text>
          </VStack>
        },
        {
          title: "Driver", extractor: "driverName", align: "left", isSortable: true,
          component: (item) => <VStack align="stretch" spacing={2}>
            <Text fontSize="14px">{(!item.driverName && !item.driverPhone) && "N/A"}</Text>
            <Text fontSize="14px">{item.driverName ? item.driverName : ""}</Text>
            <Text fontSize="14px">{item.driverPhone ? item.driverPhone : ""}</Text>
          </VStack>
        },
        { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status?.id} bookingStatus={true} />, isSortable: true, align: "center" },
        { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
      ]}

      data={bookingsQuery?.data?.data}
      loading={bookingsQuery?.isLoading}
      totalResults={bookingsQuery?.data?.meta?.totalCount}
      totalPages={bookingsQuery?.data?.meta?.totalPages}
      pageNo={query?.PageNumber}
      pageSize={query?.PageSize}
      onQueryChange={onQueryChange}
      query={query}
      onRefresh={bookingsQuery.refetch}
      isRefreshing={bookingsQuery?.isFetching}
      theadRowProps={{
        className: "active-bookings-table-header"
      }}
      colorRows={true}
      onView={onView}
    />
  )
}

export default CorporateBookings