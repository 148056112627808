import React, { useState } from "react";
import {
  Box,
  Image,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import CustomTable from "../../../components/BasicUI/CustomTable";
import TableInfoPopover from "../../../components/BasicUI/Popovers/TableInfoPopover";
import TableHeaderOptions from "../../../components/BasicUI/CustomTable/TableHeaderOptions";
import APP_ICONS from "../../../config/constants/icons";
import NotificationForm from "./NotificationForm";
import { useNotifications } from "../../../config/query/notificationQuery";
import IMAGES from "../../../config/constants/images";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import ExternalLink from "../../../components/BasicUI/DataBoxes/ExternalLink";
import BreakText from "../../../components/BasicUI/DataBoxes/BreakText";
import { makeInitialQueryObject, updateUrl, queryStringToObject } from "../../../config/helpers/queryHelper";
import NotificationDetails from "./NotificationDetails";
import useDebounce from "../../../config/hooks/useDebounce";
import appendQueryParams from "../../../config/helpers/appendQueryParams";
import { APP_MODULES } from "../../../config/constants";
import { getSentToItem } from "../../../config/helpers/formHelpers/notificationHelper";

const Notifications = () => {
  const { colorMode } = useColorMode();
  const [selectedNotification, setSelectedNotification] = useState(null);
  const formDisclosure = useDisclosure();
  const notificationDetailsDisclosure = useDisclosure();

  const [query, setQuery] = useState(makeInitialQueryObject(queryStringToObject()));
  const debouncedQuery = useDebounce(query);

  const notificationQuery = useNotifications(debouncedQuery);

  const onQueryChange = (updatedQuery) => {
    let temp = {}
    setQuery((prev) => {
      temp = { ...prev, ...updatedQuery }
      return temp
    })
    updateUrl({ pageTitle: "Notifications", queryString: appendQueryParams(temp) })
  }

  return (
    <Box>
      <TableHeaderOptions
        title={"Notifications"}
        actionText={"Add Notification"}
        action={() => {
          setSelectedNotification(null);
          formDisclosure.onOpen();
        }}
        icon={APP_ICONS.ADD}
        permissionKey={APP_MODULES.Notifications}
      />
      <CustomTable
        permissionKey={APP_MODULES.Notifications}
        tableFor={"notification"}
        searchPlaceholder="Search notifications"
        containerProps={{
          mt: 5,
          h: "calc(100vh - 160px)",
        }}
        tableWrapperProps={{
          h: "calc(100vh - 240px)",
        }}
        hideFilterBar={true}
        head={[
          {
            title: "Title",
            extractor: "title",
            isSortable: true,
            component: (item) => <BreakText value={item.title} />,
          },
          {
            title: "Image",
            extractor: "imageUrl",
            component: (item) => item.imageUrl ? (
              <TableInfoPopover title={"Image"} triggerText={"View Image"}>
                {!item.imageUrl ? (
                  <Box fontSize={"14px"} m="auto">No Image</Box>
                ) : (
                  <Image
                    w="full"
                    objectFit={"contain"}
                    h="250px"
                    src={item.imageUrl}
                    alt="Notification Image"
                    fallbackSrc={IMAGES.DUMMY_IMAGE}
                  />
                )}
              </TableInfoPopover>
            ) : "No Image",
          },
          {
            title: "URL",
            extractor: "url",
            aling: "center",
            component: (item) => (
              <ExternalLink
                href={item.url}
                label={item?.url ? "Open Link" : "N/A"}
              />
            ),
          },
          {
            title: "Sent To",
            extractor: "userType",
            component: (item) => (
              <Text
                fontSize="14px"
                color={getColor(colorKeys.primaryText, colorMode)}
              >
                {getSentToItem(item)}
              </Text>
            ),
          },
          { title: "Actions", extractor: "actions", align: "center" },
        ]}
        data={notificationQuery?.data?.data}
        loading={notificationQuery?.isLoading}
        totalResults={notificationQuery?.data?.meta?.totalCount}
        totalPages={notificationQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => notificationQuery.refetch()}
        isRefreshing={notificationQuery?.isFetching}
        onView={(item) => {
          setSelectedNotification(item);
          notificationDetailsDisclosure.onOpen();
        }}
      />

      <NotificationForm
        disclosure={formDisclosure}
        data={selectedNotification}
      />
      <NotificationDetails
        disclosure={notificationDetailsDisclosure}
        data={selectedNotification}
      />
    </Box>
  );
};

export default Notifications;
