import { VStack } from "@chakra-ui/react";
import React from "react";
import FormModal from "../../../../components/Forms/FormModal";
import { useForm } from "react-hook-form";
import FormInput from "../../../../components/Forms/FormInput";

import { useCreateColor, useUpdateColor } from "../../../../config/query/colorQuery";
const ColorForm = ({ disclosure, data }) => {

  const createcolorQuery = useCreateColor();
  const updatecolorQuery = useUpdateColor();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm({
    values: {
      name: data?.name
    },
  });

  const onSubmit = (values) => {
    const payload = {
      id: data?.id,
      ...values,
    };

    let formMutate = data?.id
      ? updatecolorQuery.mutateAsync
      : createcolorQuery.mutateAsync;
    formMutate(payload)
      .then(() => {
        disclosure.onClose();
        resetForm();
      })
      .catch((error) => console.warn(error));
  };

  return (
    <FormModal
      title={data?.id ? "Edit Color" : "Add Color"}
      disclosure={disclosure}
      isSubmitting={createcolorQuery.isLoading || updatecolorQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"40rem"}
      saveButtonText={"Save"}
      reset={resetForm}
      onCloseFn={() => resetForm()}
    >
      <VStack spacing={5} align="stretch">
        <FormInput
          label={"Name"}
          control={control}
          errors={errors}
          id="name"
          required={true}
          placeholder="Enter color name"
          inputProps={{ size: "lg" }}
        />
      </VStack>
    </FormModal>
  );
};

export default ColorForm;
