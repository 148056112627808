import {  PinInput, PinInputField, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import { Controller } from 'react-hook-form'

const FormPinInput = ({ control, id, rules, size = "lg", fieldProps, count = 6, errors }) => {
    return (
        <Controller
            control={control}
            name={id}
            rules={rules}
            render={({ field: { onChange, onBlur, value, ref, ...rest } }) => (
                <>
                    <SimpleGrid columns={count} placeItems={"center"}>
                        <PinInput
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            ref={ref}
                            id={id}
                            otp
                            autocomplete="one-time-code"
                            size={size}
                            placeholder=''
                            {...rest}
                        >
                            {new Array(count).fill("").map((_, index) => (
                                <PinInputField w="90%" key={index} {...fieldProps} />
                            ))}
                        </PinInput>
                    </SimpleGrid>
                    <Text mt={1} color="#FC8181" fontSize={"14px"}>
                        {errors[id] && errors[id].message}
                    </Text>
                </>
            )}
        />
    )
}

export default FormPinInput