import {
    useQuery,
    useMutation,
    useQueryClient
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS, { DEFAULT_SEARCH_KEY } from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { CONTENT_TYPE } from "../constants";

export const useDrivers = (params) => {
    const token = useSelector((state) => state.user?.token)
    params = appendQueryParams({ ...params, [DEFAULT_SEARCH_KEY]: API_CONSTANTS.DRIVERS.searchableKeys }, false)
    return useQuery({
        queryKey: ["drivers", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DRIVERS.base}?${params}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useDriverOptions = (query) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["driver-lookup", query],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.DRIVERS.lookup.replace(":query", query || ""),
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        enabled: Boolean(query)
    })
}

export const useDriver = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["drivers", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DRIVERS.base}/${id}`,
                token,
                toastError: false,
                toastMessage: false
            })
            return data
        },
        enabled: !!id,
    })
}

export const useCreateDriver = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.DRIVERS.dataKeys)
            const res = await Post({
                path: API_CONSTANTS.DRIVERS.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => queryClient.invalidateQueries(["drivers"])
    })
}

export const useUpdateDriverImage = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Patch({
                path: API_CONSTANTS.DRIVERS.base,
                token,
                body,
                toastError: true,
                // toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA,
            })
            return res
        },
    })
}

export const useUpdateDriverDocuments = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async ({ id, body }) => {
            const res = await Post({
                path: API_CONSTANTS.DRIVERS.updateInspectionDocuments.replace(":id", id),
                token,
                body,
                toastError: true,
                toastMessage: false,
                contentType: CONTENT_TYPE.FORM_DATA,
            })
            return res
        },
    })
}

export const useUpdateDriver = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.DRIVERS.dataKeys)
            const res = await Put({
                path: API_CONSTANTS.DRIVERS.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
    })
}

export const useDeleteDriver = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const res = await Delete({
                path: `${API_CONSTANTS.DRIVERS.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return res
        },
    })
}

export const useDriverBalance = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["driver-balance", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DRIVERS.balance.replace(":driverId", id)}`,
                token,
                toastError: false,
                toastMessage: false
            })
            return data
        },
        enabled: !!id,
    })
}

export const useAdjustBalance = () => {
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: API_CONSTANTS.DRIVERS.adjustBalance,
                token,
                body,
                toastError: true,
                toastMessage: true,
            });
            return res;
        },
    });
};

export const useDriverDispatchLogs = (id, params = {}) => {
    const token = useSelector((state) => state.user?.token)
    params = appendQueryParams({ ...params, [DEFAULT_SEARCH_KEY]: ['rideId', 'performer', 'name', 'rideStatus'] }, false)
    return useQuery({
        queryKey: [API_CONSTANTS.DRIVERS.dispatchLogs.replace(":id", id), params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DRIVERS.dispatchLogs.replace(":id", id)}?${params}`,
                token,
                toastError: true,
            })
            return data
        }
    })
}