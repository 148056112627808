import {
  HStack,
  Icon,
  IconButton,
  Input,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import APP_ICONS from "../../../config/constants/icons";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import FormFileUpload from "../../Forms/FormFileUpload";

const ChatFooter = ({
  inputMessage,
  setInputMessage,
  handleSendMessage,
  handleSendImage,
}) => {
  const { colorMode } = useColorMode();
  return (
    <HStack
      px="2"
      spacing={1}
      w="100%"
      rounded="md"
      mt="5"
      bg={getColor(colorKeys.lightGrayBackgroundFill, colorMode)}
    >
      <Input
        placeholder="Type Something..."
        border="0 !important"
        boxShadow={"none !important"}
        bg={getColor(colorKeys.lightGrayBackgroundFill, colorMode)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSendMessage();
          }
        }}
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        fontSize={"13px"}
      />

      <FormFileUpload
        id={"profilePicture"}
        onChange={handleSendImage}
        component={
          <Tooltip label={"Send Picture"} aria-label="A tooltip">
            <IconButton
              bg={getColor(colorKeys.primaryButtonFill, colorMode)}
              color="white"
              rounded="full"
              borderRadius="none"
              isDisabled={inputMessage.trim().length > 0}
              size="sm"
              icon={<Icon mt="1px" as={APP_ICONS.IMAGE_SEND} />}
              variant="unstyled"
              _hover={{
                bg: getColor(colorKeys.white, colorMode),
                color: getColor(colorKeys.primaryButtonFill, colorMode),
                border: `1px solid ${getColor(
                  colorKeys.primaryButtonFill,
                  colorMode
                )}`,
              }}
            />
          </Tooltip>
        }
        componentProps={{ variant: "ghost" }}
        fileType={"IMAGE"}
      />
      <Tooltip label={"Send Message"} aria-label="A tooltip">
        <IconButton
          size="sm"
          icon={<Icon as={APP_ICONS.SEND} />}
          bg={getColor(colorKeys.primaryButtonFill, colorMode)}
          color="white"
          rounded="full"
          borderRadius="none"
          _hover={{
            bg: getColor(colorKeys.white, colorMode),
            color: getColor(colorKeys.primaryButtonFill, colorMode),
            border: `1px solid ${getColor(
              colorKeys.primaryButtonFill,
              colorMode
            )}`,
          }}
          disabled={inputMessage.trim().length <= 0}
          onClick={handleSendMessage}
        >
          Send
        </IconButton>
      </Tooltip>
    </HStack>
  );
};

export default ChatFooter;
