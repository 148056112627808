import {
    useQuery,
    useMutation,
    useQueryClient
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS, { DEFAULT_SEARCH_KEY } from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { MILISECONDS } from "../constants";

export const usePricings = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["pricings", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PRICING.base}?${appendQueryParams(params)}`,
                token,
                toastMessage: false
            })
            return data
        },
        keepPreviousData: true
    })
}

export const usePricing = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["pricing", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PRICING.base}/${id}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        enabled: Boolean(id)
    })
}

export const usePricingOptions = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["pricing-options"],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.PRICING.lookup,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,
        keepPreviousData: true
    })
}

export const useCreatePricing = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.PRICING.dataKeys)
            const { data } = await Post({
                path: API_CONSTANTS.PRICING.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return data
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["pricings"])
            queryClient.invalidateQueries(["pricing"])
            queryClient.invalidateQueries(["pricing-options"])
        }
    })
}

export const useUpdatePricing = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.PRICING.dataKeys)
            const res = await Put({
                path: `${API_CONSTANTS.PRICING.base}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["pricings"])
            queryClient.invalidateQueries(["pricing"])
            queryClient.invalidateQueries(["pricing-options"])
        }
    })
}

export const useDeletePricing = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.PRICING.base}/${id}`,
                token,
                toastMessage: true,
            })
            return data
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["pricings"])
            queryClient.invalidateQueries(["pricing-options"])
        }
    })
}

export const useZoneRates = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["zoneRates", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PRICING.zoneRateList}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true,
    })
}

export const useZoneRate = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["zoneRates", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PRICING.zoneRate}/${id}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        enabled: Boolean(id)
    })
}

export const useUpdateZoneRate = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Put({
                path: `${API_CONSTANTS.PRICING.zoneRate}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
    })
}

export const useCorporateRate = (params) => {
    const token = useSelector((state) => state.user?.token)
    params = appendQueryParams({ ...params, [DEFAULT_SEARCH_KEY]: API_CONSTANTS.PRICING.coporateRateSearchableKeys }, false)
    return useQuery({
        queryKey: ["corporateRates", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PRICING.coporateRate}?${params}`,
                token,
            })
            return data
        },
        keepPreviousData: true,
    })
}

export const useUpdateCorporateRate = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Put({
                path: `${API_CONSTANTS.PRICING.coporateRate}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
    })
}
