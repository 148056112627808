import React from "react";
import { Box, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { getCheckboxProps } from "./roleContainer";
import { APP_PERMISSION_ACTIONS } from "../../../config/constants";
import { breakWithCaps } from "../../../config/helpers/stringHelper";
import FormCheckbox from "../../../components/Forms/FormCheckbox";

const PermissionsGroup = ({ permissions, control, errors }) => {
  const checkboxProps = getCheckboxProps(control);
  return (
    <Wrap
      justify={"space-between"}
      columns={{ base: 1, md: 2, lg: 3 }}
      spacing={5}
      align="stretch"
    >
      {permissions?.map((item) => {
        let modulePermissions = Object.keys(APP_PERMISSION_ACTIONS)
          .filter((action) => {
            if (
              item[action.toLowerCase()] !== null &&
              item[action.toLowerCase()] !== false
            ) {
              return true;
            }
            return false
          })
          .map((action) => ({
            label: action.toLowerCase(),
            value: APP_PERMISSION_ACTIONS[action],
          }));

        const additionalPermissions = item.additionalActions?.map((item) => {
          return {
            label: breakWithCaps(item),
            value: item,
          };
        });

        if (additionalPermissions?.length === 1) {
          additionalPermissions.push({
            label: "Extra",
            value: "Extra",
          });
        }

        if (
          modulePermissions.length === 0 &&
          additionalPermissions.length === 0
        )
          return null;

        return (
          <WrapItem minW="200px" key={item.module.id}>
            <Box>
              <Text fontSize={"14px"} fontWeight={"bolder"}>
                {breakWithCaps(item.module.name)}
              </Text>

              <FormCheckbox
                key={`${item.module.id}-permissions`}
                id={`permissions[${item.module.name}]`}
                label={item.module.name}
                hideLabel={true}
                errors={errors}
                strictToArray={true}
                options={modulePermissions}
                {...checkboxProps}
              />

              <FormCheckbox
                key={`${item.module.id}-additionalActions`}
                id={`additionalActions[${item.module.name}]`}
                label={item.module.name}
                hideLabel={true}
                errors={errors}
                options={additionalPermissions}
                containerProps={{ mt: 1 }}
                hiddenKeys={["Extra"]}
                {...checkboxProps}
              />
            </Box>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

export default PermissionsGroup;
