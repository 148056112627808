import {
  Avatar,
  AvatarBadge,
  Box,
  Icon,
  IconButton,
  SimpleGrid,
  SkeletonCircle,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FormModal from "../../../components/Forms/FormModal";
import { useForm } from "react-hook-form";
import FormInput from "../../../components/Forms/FormInput";
import { EMAIL_REGEX } from "../../../config/constants/regexConstants";
import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateSubAdmin,
  useSubAdmin,
  useUpadetAdminImage,
  useUpdateSubAdmin,
} from "../../../config/query/subAdminQuery";
import FormFileUpload from "../../../components/Forms/FormFileUpload";
import APP_ICONS from "../../../config/constants/icons";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import FormFieldsSkeleton from "../../../components/Skeletons/FormFieldsSkeleton";
import FormSearchSelect from "../../../components/Forms/FormSearchSelect";
import {
  prepareForm,
  preparePayload,
} from "../../../config/helpers/formHelpers/subAdminHelper";
import { makeSelectList } from "../../../config/helpers/selectListHelper";
import { useRoles, useAssignRoles } from "../../../config/query/rolesQuery";
import FormPhoneInput from "../../../components/Forms/FormPhoneInput";
import { useSelector } from "react-redux";
import { trimRole } from "../../../config/helpers/stringHelper";

const SubAdminForm = ({ disclosure, data }) => {
  const user = useSelector((state) => state.user?.user);
  const queryClient = useQueryClient();
  const { colorMode } = useColorMode();

  const rolesQuery = useRoles({
    PageNumber: 1,
    PageSize: 20,
  });

  const [fileData, setFileData] = useState({
    binary: null,
    url: null,
  });

  const subAdminQuery = useSubAdmin(data?.id);
  const createSubAdminQuery = useCreateSubAdmin();
  const updateSubAdminImage = useUpadetAdminImage();
  const updateSubAdminQuery = useUpdateSubAdmin();
  const useAssignRolesQuery = useAssignRoles();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
    setError
  } = useForm({
    values: prepareForm({
      formValues: data,
      serverValues: subAdminQuery.data,
    }),
  });

  const { countryCode, localNumber } = watch()

  const onSubmit = (values) => {
    let formMutate = data?.id
      ? updateSubAdminQuery.mutateAsync
      : createSubAdminQuery.mutateAsync;
    formMutate(preparePayload(values, data?.id))
      .then((res) => {
        disclosure.onClose();
        if (fileData?.binary) {
          updateSubAdminImage
            .mutateAsync({
              AdminId: res?.id,
              AdminImage: fileData?.binary,
            })
            .then(() => {
              queryClient.invalidateQueries(["sub-admins", res?.id]);
            })
            .catch((error) => console.warn(error))
        }
        const prevRoles = JSON.stringify(subAdminQuery.data?.roles?.map((role) => role?.id))
        const currentRoles = JSON.stringify(values?.roleIds)
        if (prevRoles !== currentRoles) {
          useAssignRolesQuery
            .mutateAsync({
              adminId: data?.id || res?.adminId,
              roleIds: values?.roleIds,
            })
            .then(() => {
              resetForm();
              queryClient.invalidateQueries(["sub-admins"]);
              return;
            })
            .catch((error) => console.warn(error))
        }
        resetForm();
        queryClient.invalidateQueries(["sub-admins"]);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const onChangeImage = (file) => {
    setFileData({
      binary: file,
      url: URL.createObjectURL(file),
    });
  };

  useEffect(() => {
    if (data?.id && disclosure.isOpen) {
      setFileData({
        ...fileData,
        url: subAdminQuery.data?.profilePictureURL,
      });
    } else {
      resetForm();
      setFileData({
        binary: null,
        url: null,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id, subAdminQuery.data?.profilePictureURL, disclosure.isOpen]);

  return (
    <FormModal
      title={data?.id ? "Edit Sub Admin" : "Add Sub Admin"}
      disclosure={disclosure}
      isSubmitting={
        createSubAdminQuery.isLoading || updateSubAdminQuery.isLoading
      }
      onSubmit={handleSubmit(onSubmit)}
      maxW={"75rem"}
      saveButtonText={data?.id ? "Update Sub Admin" : "Add New Sub Admin"}
      reset={resetForm}
      onCloseFn={() => {
        resetForm();
        setFileData({
          binary: null,
          url: null,
        });
      }}
      saveButtonProps={{
        isDisabled: trimRole(user?.id) === data?.id,
        title: trimRole(user?.id) === data?.id ? "You cannot update your own user from here, Please update it from profile." : "",
      }}
    >
      <VStack spacing={5} align="stretch">
        <Box m="auto">
          {subAdminQuery.isFetching ? (
            <SkeletonCircle size="130" />
          ) : (
            <Avatar
              size="2xl"
              name={subAdminQuery.data?.name}
              src={fileData?.url}
            >
              <AvatarBadge boxSize="8">
                <FormFileUpload
                  id={"profilePicture"}
                  onChange={onChangeImage}
                  component={
                    <IconButton
                      variant={"ghost"}
                      icon={
                        <Icon
                          as={APP_ICONS.EDIT}
                          boxSize="7"
                          color={getColor(colorKeys.primary, colorMode)}
                        />
                      }
                    />
                  }
                  componentProps={{ variant: "ghost" }}
                  fileType={"IMAGE"}
                />
              </AvatarBadge>
            </Avatar>
          )}
        </Box>

        <FormFieldsSkeleton
          count={data?.id ? 4 : 4}
          columns={{ base: 1, md: 2 }}
          loading={subAdminQuery.isFetching}
        />
        {!subAdminQuery.isFetching && (
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
            <FormInput
              label={"Name"}
              control={control}
              errors={errors}
              id="name"
              required={true}
              placeholder="Enter name"
              inputProps={{ size: "sm" }}
            />

            <FormPhoneInput
              id={"localNumber"}
              label="Phone Number"
              type="tel"
              placeholder="Enter your phone number"
              required={true}
              errors={errors}
              control={control}
              setCountryCodeValue={(value) => {
                setValue("countryCode", value)
              }}
              countryCodeValue={countryCode}
              phoneNumberValue={localNumber}
              setPhoneNumberValue={(value) => {
                setValue("localNumber", value)
              }}
              setError={setError}
              clearErrors={clearErrors}
              inputProps={{ size: "sm" }}
              size="sm"
            />

            <FormInput
              id={"email"}
              label="Email"
              placeholder="someone@example.com"
              required={true}
              errors={errors}
              control={control}
              rules={{
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid email address",
                },
              }}
              inputProps={{ size: "sm" }}
            />

            <FormSearchSelect
              id="roleIds"
              label={"Roles"}
              placeholder={"Select roles"}
              required={true}
              errors={errors}
              control={control}
              options={makeSelectList(rolesQuery?.data?.data)}
              inputProps={{ size: "sm" }}
              multiple={true}
            />
          </SimpleGrid>
        )}

      </VStack>
    </FormModal>
  );
};

export default SubAdminForm;
