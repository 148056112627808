import {
    useQuery,
    useMutation
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS from "../constants/api";
import { MILISECONDS } from "../constants";

export const useModels = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["models", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.MODELS.base}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true,
    })
}

export const useModel = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["model", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.MODELS.base}/${id}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        enabled: Boolean(id)
    })
}

export const useCreateModel = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: API_CONSTANTS.MODELS.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
    })
}

export const useUpdateModel = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Put({
                path: `${API_CONSTANTS.MODELS.base}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
    })
}

export const useDeleteModel = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.MODELS.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}

export const useModelLookup = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["model-lookup", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.MODELS.lookup}?${appendQueryParams(params)}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,
    })
}