import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Post, Get, Delete, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import appendQueryParams from "../helpers/appendQueryParams";

export const useRoles = (params) => {
  const token = useSelector((state) => state.user.token);
  return useQuery({
    queryKey: ["roles", params],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.ROLES.list}?${appendQueryParams(params)}`,
        token,
      });
      return data;
    },
    keepPreviousData: true,
  });
};

export const useCreateRoles = () => {
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async (body) => {
      const res = await Post({
        path: API_CONSTANTS.ROLES.addRole,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return res;
    },
  });
};

export const useUpdateRole = () => {
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async (body) => {
      const res = await Put({
        path: API_CONSTANTS.ROLES.list,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return res;
    },
  });
};

export const useDeleteRole = () => {
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async (id) => {
      const { data } = await Delete({
        path: `${API_CONSTANTS.ROLES.list}/${id}`,
        token,
        toastMessage: true,
        toastError: true,
      });
      return data;
    },
  });
};

export const useAssignRoles = () => {
  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async (body) => {
      const res = await Put({
        path: API_CONSTANTS.ROLES.assignRoles,
        token,
        body,
        toastError: true,
        toastMessage: false,
      });
      return res;
    },
    onSuccess: () => queryClient.invalidateQueries(["sub-admins"])
  });
};

export const usePermissions = () => {
  const token = useSelector((state) => state.user.token);
  return useQuery({
      queryKey: ["permissions"],
      queryFn: async () => {
          const data = await Get({
              path: API_CONSTANTS.ROLES.permissions,
              token,
          });
          return data;
      },
      keepPreviousData: true,
  });
}