export const changeFavicon = (faviconUrl) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (!favicon) {
        // If favicon element doesn't exist, create it
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = faviconUrl;
        document.head.appendChild(newFavicon);
    } else {
        // If favicon element exists, just update the href
        favicon.href = faviconUrl;
    }
};