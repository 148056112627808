import React, { useEffect, useState } from 'react'
import { Box, Divider, HStack, Icon, InputLeftAddon, SimpleGrid, Text, Tooltip, VStack, useDisclosure } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import FormModal from '../../../components/Forms/FormModal'
import FormInput from '../../../components/Forms/FormInput'
import { useCreatePromoCode, usePromoCode, useUpdatePromoCode } from '../../../config/query/promoCodeQuery'
import FormSearchSelect from '../../../components/Forms/FormSearchSelect'
import { useCorporateOptions } from '../../../config/query/corporateQuery'
import { ACCESS_TYPES, CUSTOMER_PAYMENT_TYPES, STATUS } from '../../../config/constants/enums'
import { makeSelectList } from '../../../config/helpers/selectListHelper'
import { useZoneOptions } from '../../../config/query/zoneQuery'
import { useVehicleTypeOptions } from '../../../config/query/transportQuery'
import FormSwitch from '../../../components/Forms/FormSwitch'
import { MAX_CASH_DISCOUNT_LIMIT, MAX_PROMO_CODE_REDEEM_LIMIT } from '../../../config/constants/settings'
import { prepareForm, preparePayload, prepareUpdatePayload } from '../../../config/helpers/formHelpers/promoCodeHelper'
import FormDateInput from '../../../components/Forms/FormDateInput'
import useDebounce from '../../../config/hooks/useDebounce'
import { useCustomerOptions } from '../../../config/query/customerQuery'
import FormComboBox from '../../../components/Forms/FormComboBox'
import FormFieldsSkeleton from '../../../components/Skeletons/FormFieldsSkeleton'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'

const PromoCodeForm = ({ disclosure, data }) => {

    const customersDisclosure = useDisclosure({ defaultIsOpen: true })

    const [queries, setQueries] = useState({
        corporate: "",
        vehicleType: {
            Keyword: ""
        },
        customer: ""
    })
    const debouncedQuery = useDebounce(queries)

    const promoCodeQuery = usePromoCode(data?.id)
    const corporateQuery = useCorporateOptions(debouncedQuery?.corporate)
    const customerQuery = useCustomerOptions(debouncedQuery?.customer)

    const createPromoCodeQuery = useCreatePromoCode()
    const updatePromoCodeQuery = useUpdatePromoCode()
    const zones = useZoneOptions()
    const vehicleTypes = useVehicleTypeOptions()

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: { errors },
        watch,
        clearErrors,
        setValue
    } = useForm({
        values: prepareForm({
            formValues: data,
            serverValues: promoCodeQuery.data
        })
    })

    const fields = watch()

    const onSubmit = (values) => {
        const formMutate = data?.id ? updatePromoCodeQuery.mutateAsync : createPromoCodeQuery.mutateAsync
        const payload = data?.id ? prepareUpdatePayload(values) : preparePayload(values)
        formMutate(payload)
            .then(() => {
                disclosure.onClose()
                resetForm()
            })
            .catch((err) => {
                console.warn(err)
            })
    }

    watch((data, { name, type }) => {
        if (type === "change") {
            if (name === "promoCodeType") {
                if (data[name] === false) {
                    setValue("corporateIds", [])
                }
                clearErrors()
            }
        }
    })

    useEffect(() => {
        if (!customersDisclosure.isOpen) {
            setValue("customerIds", [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customersDisclosure.isOpen])

    return (
        <FormModal
            title={data?.id ? "Edit Promo Code" : "Add Promo Code"}
            disclosure={disclosure}
            isSubmitting={createPromoCodeQuery.isLoading || updatePromoCodeQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"75rem"}
            saveButtonText={data?.id ? "Update Promo Code" : "Add Promo Code"}
        >
            {promoCodeQuery.isFetching
                ? <FormFieldsSkeleton count={12} loading={promoCodeQuery.isFetching} />
                : <VStack spacing={5} align="stretch">
                    <Box>
                        <HStack spacing="4" w="full" justify={"center"} align={"center"} my={3}>
                            <Text fontSize="13px" fontWeight={"500"} textAlign={"center"} textTransform={"uppercase"}>Individual</Text>
                            <Box>
                                <FormSwitch
                                    control={control}
                                    errors={errors}
                                    id="promoCodeType"
                                    switchProps={{ isDisabled: data?.id }}
                                />
                            </Box>
                            <Text fontSize="13px" fontWeight={"500"} textAlign={"center"} textTransform={"uppercase"}>Corporate</Text>
                        </HStack>
                    </Box>

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                        {(!fields?.promoCodeType && customersDisclosure.isOpen) ? (
                            <>
                                {data?.id
                                    ? <LabelValuePair
                                        containerProps={{ mt: 2 }}
                                        restricted={true}
                                        label={"Customers"}
                                        valueComponent={fields?.customers?.map((item, index) => (
                                            <span key={index}>{item.name || "N/A"}{index !== fields?.customers?.length - 1 ? ', ' : ''}</span>
                                        ))}
                                        value={Boolean(fields?.customers?.length)}
                                    />
                                    : <FormComboBox
                                        id="customerIds"
                                        label={"Customers"}
                                        placeholder="Search customers by phone number"
                                        control={control}
                                        errors={errors}
                                        inputProps={{ size: "sm", isDisabled: data?.id }}
                                        searchFn={(query) => {
                                            setQueries((prev) => ({
                                                ...prev,
                                                customer: query
                                            }))
                                        }}
                                        isLoading={queries?.customer && customerQuery.isLoading}
                                        options={customerQuery.data?.data?.map((item) => ({ value: item.id, label: item.name })) || []}
                                    />
                                }
                            </>
                        ) : null}

                        {fields?.promoCodeType ? (
                            <>
                                {data?.id
                                    ? <LabelValuePair
                                        containerProps={{ mt: 2 }}
                                        restricted={true}
                                        label={"Corporates"}
                                        valueComponent={fields?.corporates?.map((item, index) => (
                                            <span key={index}>{item.name || "N/A"}{index !== fields?.corporates?.length - 1 ? ', ' : ''}</span>
                                        ))}
                                        value={Boolean(fields?.corporates?.length)}
                                    />
                                    : <FormComboBox
                                        id="corporateIds"
                                        label={"Corporates"}
                                        placeholder="Search corporates by name"
                                        required={fields?.promoCodeType}
                                        control={control}
                                        errors={errors}
                                        inputProps={{ size: "sm", isDisabled: data?.id }}
                                        searchFn={(query) => {
                                            setQueries((prev) => ({
                                                ...prev,
                                                corporate: query
                                            }))
                                        }}
                                        isLoading={queries?.corporate && corporateQuery.isLoading}
                                        options={corporateQuery.data?.data?.map((item) => ({ value: item.id, label: item.name })) || []}
                                    />
                                }
                            </>
                        ) : null}

                    </SimpleGrid>

                    <Divider />

                    <FormInput
                        label={"Title"}
                        control={control}
                        errors={errors}
                        id="title"
                        required={true}
                        placeholder="Enter title"
                        inputProps={{ size: "sm", isDisabled: data?.id }}
                    />

                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

                        <FormInput
                            label={"Discount (%)"}
                            control={control}
                            errors={errors}
                            id="discountPercentage"
                            required={!fields.discountAmount && true}
                            placeholder="Enter discount in percentage"
                            inputProps={{ size: "sm", isDisabled: fields.discountAmount || data?.id }}
                            type={"number"}
                            rules={{
                                validate: (value) => {
                                    if (!(data?.id || fields.discountAmount)) {
                                        if (value < 1 || value > 100) {
                                            return "Discount must be between 1 and 100"
                                        }
                                    }
                                }
                            }}
                        />

                        <FormInput
                            label={"Max Discount Limit"}
                            control={control}
                            errors={errors}
                            id="maxDiscountLimit"
                            required={!fields.discountAmount && true}
                            placeholder="Enter max discount limit"
                            inputProps={{ size: "sm", isDisabled: fields.discountAmount || data?.id }}
                            type={"number"}
                        />

                        <FormInput
                            label={"Discount Amount"}
                            control={control}
                            errors={errors}
                            id="discountAmount"
                            required={!fields.discountPercentage && true}
                            placeholder="Enter discount amount"
                            type="number"
                            inputProps={{ size: "sm", isDisabled: fields.discountPercentage || data?.id }}
                            rules={{
                                validate: (value) => {
                                    if (!(data?.id || fields.discountPercentage)) {
                                        if (value < 1) {
                                            return "Discount must be greater than 0"
                                        }
                                        if (value > MAX_CASH_DISCOUNT_LIMIT) {
                                            return `Discount must be less than ${MAX_CASH_DISCOUNT_LIMIT}`
                                        }
                                        if (value && isNaN(value)) {
                                            return "Discount must be a number"
                                        }
                                    }
                                }
                            }}
                            size='sm'
                            leftAddon={<InputLeftAddon children={"$"} />}
                        />

                        <FormInput
                            label={"Promo Code"}
                            control={control}
                            errors={errors}
                            id="code"
                            required={true}
                            placeholder="Enter promo code"
                            inputProps={{ size: "sm", isDisabled: data?.id }}
                            rules={{
                                validate: (value) => {
                                    if (value && value.length > 15) {
                                        return "Promo code must be lesser than 15 characters"
                                    }
                                }
                            }}
                        />

                        <FormDateInput
                            label={"Expiry Date"}
                            control={control}
                            errors={errors}
                            id={"expiryDate"}
                            required={true}
                            placeholder={"Select effective date"}
                            inputProps={{ size: "sm" }}
                            maxDate={new Date(moment().add(1, 'years').format("YYYY-MM-DD"))}
                            minDate={new Date(moment().format("YYYY-MM-DD"))}
                            rules={{
                                validate: (value) => {
                                    if (value < new Date().toISOString().slice(0, 10)) {
                                        return "Expiry date must be greater than today"
                                    }
                                    //check if date is greater than one year from today usind moment
                                    if (moment(value) > moment().add(1, 'years')) {
                                        return "Expiry date must be less than one year from today"
                                    }
                                }
                            }}
                        />

                        <FormSearchSelect
                            id="vehicleTypes"
                            label={"Ride Type"}
                            control={control}
                            required={true}
                            errors={errors}
                            placeholder="Select ride type"
                            inputProps={{ size: "sm" }}
                            multiple={true}
                            options={makeSelectList(vehicleTypes.data?.data)}
                        />

                        <FormSearchSelect
                            id="zoneIds"
                            label="Zone"
                            placeholder={'Select zone'}
                            required={true}
                            multiple={true}
                            errors={errors}
                            control={control}
                            options={makeSelectList(zones.data)}
                            inputProps={{ size: "sm" }}
                        />

                        <FormSearchSelect
                            id="access"
                            options={makeSelectList(ACCESS_TYPES)}
                            label={"Access (Public/Private)"}
                            control={control}
                            required={true}
                            errors={errors}
                            placeholder="Select access"
                            inputProps={{ size: "sm" }}
                        />

                        <FormSearchSelect
                            id="paymentMethods"
                            options={makeSelectList(CUSTOMER_PAYMENT_TYPES)}
                            label={"Payment Method"}
                            multiple={true}
                            control={control}
                            required={true}
                            errors={errors}
                            placeholder="Select payment method"
                            inputProps={{ size: "sm", isDisabled: data?.id }}
                        />

                        <FormInput
                            label={"Release Quantity"}
                            control={control}
                            errors={errors}
                            id="releasedQuantity"
                            type={"number"}
                            placeholder="Enter release quantity"
                            inputProps={{ size: "sm" }}
                            labelExtention={
                                <Tooltip label="This field represents the total number of promo code quantity that can be availed by customers" fontSize="sm">
                                    <Icon w={4} h={4} />
                                </Tooltip>
                            }
                        />

                        <FormInput
                            label={"No. of Uses"}
                            control={control}
                            errors={errors}
                            id="redemptionLimit"
                            type={"number"}
                            required={true}
                            placeholder="Enter no. of uses"
                            inputProps={{ size: "sm", isDisabled: data?.id }}
                            rules={{
                                validate: (value) => {
                                    if (value < 1) {
                                        return "No. of uses must be greater than 0"
                                    }
                                    if (value > MAX_PROMO_CODE_REDEEM_LIMIT) {
                                        return `No. of uses must be less than ${MAX_PROMO_CODE_REDEEM_LIMIT}`
                                    }
                                }
                            }}
                            labelExtention={
                                <Tooltip label="This field represents the number of times a promo code can be used per customer" fontSize="sm">
                                    <Icon w={4} h={4} />
                                </Tooltip>
                            }
                        />

                        <FormSearchSelect
                            id="status"
                            options={makeSelectList(STATUS)}
                            label={"Status"}
                            control={control}
                            required={true}
                            errors={errors}
                            placeholder="Select status"
                            inputProps={{ size: "sm", isDisabled: data?.id }}
                        />

                    </SimpleGrid>


                </VStack>
            }

        </FormModal>
    )
}

export default PromoCodeForm