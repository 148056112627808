import React from "react";
import { SimpleGrid, Flex, useColorMode, Box, Center, Text } from "@chakra-ui/react";
import { Pie } from "@ant-design/plots";
import PieStatusBox from "../BasicUI/DataBoxes/PieStatusBox";
import { colorKeys, getColor, pieChartStatColors } from "../../config/constants/appColors";
import { chartStyle, getPieChartData, getPieConfig } from "./chartConfig";

const PieChart = ({ data }) => {
  const { colorMode } = useColorMode()
  const preparedData = getPieChartData(data)
  const config = getPieConfig(preparedData)

  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <div style={chartStyle} >
        {preparedData?.length === 0 && (
          <Box mt={3} px="2">
            <Center flexDir={"column"} h="300px" bg={getColor(colorKeys.lightestGray, colorMode)}>
              <Text fontWeight={"bold"} fontSize="16px" color={getColor(colorKeys.primaryText, colorMode)}>
                No Data
              </Text>
              <Text fontSize="13px" maxW="220px" textAlign={"center"} color={getColor(colorKeys.dimText, colorMode)}>
                Try changing the date range or view mode
              </Text>
            </Center>
          </Box>
        )}
        <Pie {...config} />
        <Flex justify={"center"} align={"center"}>
          <SimpleGrid
            columns={2}
            spacing={2}
            w={"100%"}
            maxWidth={"280px"}
            padding="10px 0px 10px 30px"
          >
            <PieStatusBox
              label={"Completed"}
              iconColor={pieChartStatColors.COMPLETED}
              count={data?.Completed?.count || 0}
            />
            <PieStatusBox
              label={"Canceled"}
              iconColor={pieChartStatColors.CANCELED}
              count={data?.Cancelled?.count || 0}
            />
            <PieStatusBox
              label={"No shows"}
              iconColor={pieChartStatColors.NO_SHOWS}
              count={data?.NoShow?.count || 0}
            />
            <PieStatusBox
              label={"Incidents"}
              iconColor={pieChartStatColors.INCIDENTS}
              count={data?.Incident?.count || 0}
            />
          </SimpleGrid>
        </Flex>
      </div>
    </Flex>
  );
};

export default PieChart;
