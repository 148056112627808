import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, Text } from '@chakra-ui/react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import CoporatePricingForm from './CoporatePricingForm'
import { makeInitialQueryObject } from '../../../../config/helpers/queryHelper'
import { useCorporateRate } from '../../../../config/query/pricingQuery'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import useDebounce from '../../../../config/hooks/useDebounce'
import { APP_MODULES } from '../../../../config/constants'
import { formatPercentage } from '../../../../config/helpers/stringHelper'

const CorporatePricing = ({ selectedRow, setSelectedRow, disclosure, setIsRefreshing, isRefreshing }) => {
    const navigate = useNavigate()

    const [query, setQuery] = useState(makeInitialQueryObject({ Sort: "" }))
    const debouncedQuery = useDebounce(query)

    const corporateRateQuery = useCorporateRate(debouncedQuery)

    const onEditModal = (item) => {
        setSelectedRow(item)
        disclosure.onOpen()
    }

    const onQueryChange = (updatedQuery) => {
        setQuery((prev) => ({ ...prev, ...updatedQuery }))
    }

    useEffect(() => {
        if (isRefreshing) corporateRateQuery.refetch().then(() => setIsRefreshing(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshing])

    return (
        <Box>
            <CustomTable
                permissionKey={APP_MODULES.Pricing}
                tableFor={"corporate pricing"}
                tableWrapperProps={{ padding: 0, h: "calc(100vh - 400px)" }}
                containerProps={{ mt: 4, h: "calc(100vh - 285px)" }}
                head={[
                    {
                        title: "Company Name",
                        extractor: "corporate.name",
                        align: "left",
                        component: (item) => <Text fontSize={"14px"}>{item.corporate?.name}</Text>
                    },
                    {
                        title: "Rate %",
                        extractor: "corporateRate",
                        align: "center",
                        component: (item) => <Text fontSize={"14px"} textAlign={"center"}>{formatPercentage(item.corporateRate)}</Text>
                    },
                    {
                        title: "Actions",
                        extractor: "actions",
                        align: "center",
                        component: (item) => <PrimaryButton size="xs" rounded="lg" onClick={() => onEditModal(item)}>View Details</PrimaryButton>
                    }
                ]}
                data={corporateRateQuery?.data?.data}
                loading={corporateRateQuery?.isLoading}
                totalResults={corporateRateQuery?.data?.meta?.totalCount}
                totalPages={corporateRateQuery?.data?.meta?.totalPages}
                pageNo={query?.PageNumber}
                pageSize={query?.PageSize}
                onQueryChange={onQueryChange}
                query={query}
                isRefreshing={corporateRateQuery?.isFetching}

                onEdit={(item) => onEditModal(item)}
                onView={(item) => navigate(`/admin/corporate/${item.id}`)}
            />

            <CoporatePricingForm data={selectedRow} disclosure={disclosure} />
        </Box>
    )
}

export default CorporatePricing