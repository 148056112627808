export const getWidthById = (id) => {
    if(!id) return null
    const element = document.getElementById(id)
    if (!element) return null
    return element?.getBoundingClientRect()?.width
}

export const getWidthBySelector = (selector) => {
    if(!selector) return null
    const element = document.querySelector(selector)
    if (!element) return null
    return element?.getBoundingClientRect()?.width
}