import React, { useState, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Box, useColorMode } from '@chakra-ui/react';
import { getDefaultCoordinates } from '../../config/helpers/locationHelper';
import { getStylers } from './mapConfig';

const SimpleMap = ({ containerProps, children, center = getDefaultCoordinates(), zoom = 12, fullscreenControlOptions }, ref) => {
    const { colorMode } = useColorMode();

    const [mapCenter, setMapCenter] = useState(center)

    const mapStyles = {
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    };

    const handleCenterChanged = () => {
        if (window.google) {
            const center = window.google.maps.Map.prototype.getCenter()
            if (center) {
                setMapCenter({
                    lat: center.lat(),
                    lng: center.lng(),
                })
            }
        }
    }

    useEffect(() => {
        if (!center) return
        if (window.google) {
            if (center.lat === mapCenter.lat && center.lng === mapCenter.lng) return
            setMapCenter(center)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [center])

    return (
        <Box w="full" h="calc(100vh - 250px)" ref={ref} {...containerProps}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={zoom}
                center={mapCenter}
                onCenterChanged={handleCenterChanged}
                options={{
                    draggableCursor: 'pointer',
                    draggingCursor: 'pointer',
                    controlSize: 24,
                    streetViewControl: false,
                    mapTypeControl: true,
                    fullscreenControl: true,
                    scrollwheel: true,
                    zoomControl: true,
                    scaleControl: true,
                    rotateControl: true,
                    disableDoubleClickZoom: true,
                    gestureHandling: 'greedy',
                    clickableIcons: false,
                    fullscreenControlOptions: { ...fullscreenControlOptions },
                    styles: getStylers(colorMode),
                }}
            >
                {children}
            </GoogleMap>
        </Box>
    );
}

export default React.memo(React.forwardRef(SimpleMap))