import React, { useEffect, useState } from 'react'
import { Badge, Box, Tooltip, Wrap, WrapItem } from '@chakra-ui/react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { useDeleteVehicle, useVehicles } from '../../../../config/query/transportQuery'
import { makeInitialQueryObject, updateUrl, queryStringToObject } from '../../../../config/helpers/queryHelper'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import VehicleForm from './VehicleForm'
import { removeUndefinedValues } from '../../../../config/helpers/stringHelper'
import TableInfoPopover from '../../../../components/BasicUI/Popovers/TableInfoPopover'
import CapacityBox from '../../../../components/BasicUI/DataBoxes/CapacityBox'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'
import { formatDate } from '../../../../config/helpers/dateHelper'
import useDebounce from '../../../../config/hooks/useDebounce'
import appendQueryParams from '../../../../config/helpers/appendQueryParams'
import { APP_MODULES } from '../../../../config/constants'

const Vehicles = ({ selectedRow, setSelectedRow, disclosure, setIsRefreshing, isRefreshing }) => {

    const [query, setQuery] = useState(makeInitialQueryObject(queryStringToObject()))
    const debouncedQuery = useDebounce(query)

    const onEditModal = (item) => {
        setSelectedRow(item)
        disclosure.onOpen()
    }

    const vehiclesQuery = useVehicles(debouncedQuery)
    const deleteVehicleQuery = useDeleteVehicle()

    const onQueryChange = (updatedQuery) => {
        let temp = {}
        setQuery((prev) => {
            temp = { ...prev, ...updatedQuery }
            return temp
        })
        updateUrl({ pageTitle: "Vehicles", queryString: appendQueryParams(temp) })
    }

    const onDelete = (id) => {
        deleteVehicleQuery
            .mutateAsync(id)
            .then(() => vehiclesQuery.refetch())
            .catch((err) => console.warn(err))
    }

    useEffect(() => {
        if (isRefreshing) vehiclesQuery.refetch().then(() => setIsRefreshing(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshing])

    const onFormSuccess = () => {
        vehiclesQuery.refetch()
        setSelectedRow(null)
    }

    return (
        <Box>

            <CustomTable
                permissionKey={APP_MODULES.VehicleManagement}
                tableFor={"vehicle"}
                hideFilterBar={true}
                tableWrapperProps={{ padding: 0, h: "calc(100vh - 325px)" }}
                containerProps={{ mt: 4, h: "calc(100vh - 266px)" }}
                head={[
                    {
                        title: "Vehicle Name",
                        extractor: "name",
                        component: (item) =>
                            <TableInfoPopover
                                title={removeUndefinedValues([item.make?.name, item.model?.name, item.year, item.color?.name], " - ")}
                                triggerText={removeUndefinedValues([item.make?.name, item.model?.name, item.year, item.color?.name], " - ")}>
                                <LabelValuePair
                                    label="Color"
                                    value={item.color?.name}
                                    flexDir='column'
                                />
                                <LabelValuePair
                                    label="Make"
                                    value={item.make?.name}
                                    flexDir='column'
                                />
                                <LabelValuePair
                                    label="Model"
                                    value={item.model?.name}
                                    flexDir='column'
                                />
                                <LabelValuePair
                                    label="Capacity"
                                    value={`Has ${item.seatingCapacity} person capacity & ${item.hasLuggageSpace ? 'luggage space' : 'no luggage space'}`}
                                    flexDir='column'
                                />
                                <LabelValuePair
                                    label="Year"
                                    value={item.year}
                                    flexDir='column'
                                />
                            </TableInfoPopover>
                    },
                    {
                        title: "Service Type",
                        extractor: "vehicleType",
                        align: "center",
                        component: (item) => <Wrap justify={"center"}>
                            {item.vehicleTypes?.map((item, index) => (
                                <WrapItem key={index}>
                                    <Tooltip label={item?.isDefault ? "Primary Service Type" : "Secondary Service Type"}>
                                        <Badge colorScheme={item?.isDefault ? "green" : "gray"}>
                                            {item?.vehicleTypeName}
                                        </Badge>
                                    </Tooltip>
                                </WrapItem>
                            ))}
                        </Wrap>
                    },
                    { title: "Owner Name", extractor: "ownerName", align: "center", isSortable: true },
                    { title: "License Plate", extractor: "licensePlate", align: "center", isSortable: true },
                    {
                        title: "Insurance Policy No",
                        extractor: "insurancePolicyNo",
                        align: "center",
                        isSortable: true,
                        component: (item) =>
                            <TableInfoPopover title={"Vehicle Insurance"} triggerText={item.insurancePolicyNo} triggerProps={{ m: "auto", textAlign: "center" }} >
                                <LabelValuePair
                                    label="Effective Date"
                                    value={formatDate(item.insuranceEffectiveDate)}
                                    flexDir='column'
                                />
                                <LabelValuePair
                                    label="Expiry Date"
                                    value={formatDate(item.insuranceExpiryDate)}
                                    flexDir='column'
                                />
                            </TableInfoPopover>
                    },
                    { title: "Capacity", extractor: "seatingCapacity", align: "center", isSortable: true, component: (item) => <CapacityBox hasLuggageSpace={item.hasLuggageSpace} personCount={item.seatingCapacity} /> },
                    { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
                    { title: "Action", extractor: "actions", align: "center" },
                ]}
                data={vehiclesQuery.data?.data}
                loading={vehiclesQuery?.isLoading}
                totalResults={vehiclesQuery?.data?.meta?.totalCount}
                totalPages={vehiclesQuery?.data?.meta?.totalPages}
                pageNo={query?.PageNumber}
                pageSize={query?.PageSize}
                onQueryChange={onQueryChange}
                query={query}
                onEdit={onEditModal}
                onDelete={onDelete}
            />
            <VehicleForm data={selectedRow} disclosure={disclosure} onSuccess={onFormSuccess} />
        </Box>
    )
}

export default Vehicles