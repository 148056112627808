import { Button, VStack, useDisclosure } from "@chakra-ui/react";
import React from "react";
import FormModal from "../../../../components/Forms/FormModal";
import { useForm } from "react-hook-form";
import FormInput from "../../../../components/Forms/FormInput";
import {
  useCreateState,
  useUpdateState,
} from "../../../../config/query/stateQuery";
import FormSearchSelect from "../../../../components/Forms/FormSearchSelect";
import { makeSelectList } from "../../../../config/helpers/selectListHelper";
import { useCountriesLookup } from "../../../../config/query/countryQuery";
import CountryForm from "../Countries/CountryForm";

const StateForm = ({ disclosure, data }) => {
  const countryFormDisclosure = useDisclosure()

  const countries = useCountriesLookup()
  const createStateQuery = useCreateState();
  const updateStateQuery = useUpdateState();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm({
    values: {
      name: data?.name,
      countryId: data?.country?.id,
    },
  });

  const onSubmit = (values) => {
    const payload = {
      id: data?.id,
      ...values,
    };

    let formMutate = data?.id
      ? updateStateQuery.mutateAsync
      : createStateQuery.mutateAsync;
    formMutate(payload)
      .then(() => {
        disclosure.onClose();
        resetForm();
      })
      .catch((error) => console.warn(error));
  };

  return (
    <FormModal
      title={data?.id ? "Edit State" : "Add State"}
      disclosure={disclosure}
      isSubmitting={createStateQuery.isLoading || updateStateQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"35rem"}
      saveButtonText={"Save"}
      reset={resetForm}
      onCloseFn={() => resetForm()}
    >
      <VStack spacing={5} align="stretch">
        <FormInput
          label={"Name"}
          control={control}
          errors={errors}
          id="name"
          required={true}
          placeholder="Enter name"
          inputProps={{ size: "sm" }}
        />

        <FormSearchSelect
          id="countryId"
          label="Country"
          placeholder={'Select Country'}
          required={false}
          errors={errors}
          control={control}
          options={makeSelectList(countries.data?.data)}
          inputProps={{ size: "sm" }}
          labelExtention={<Button size="xs" onClick={countryFormDisclosure.onOpen}>Add New</Button>}
        />
      </VStack>
      <CountryForm data={{}} disclosure={countryFormDisclosure} />
    </FormModal>
  );
};

export default StateForm;
