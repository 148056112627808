import React, { useState } from "react";
import CustomTable from "../../../components/BasicUI/CustomTable";
import TableInfoPopover from "../../../components/BasicUI/Popovers/TableInfoPopover";
import LabelValuePair from "../../../components/BasicUI/LabelValuePair";
import StatusBadge from "../../../components/BasicUI/Badges/StatusBadge";
import Chronology from "../../../components/BasicUI/DataBoxes/Chronology";
import { formatDateTime } from "../../../config/helpers/dateHelper";
import TableHeaderOptions from "../../../components/BasicUI/CustomTable/TableHeaderOptions";
import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";
import APP_ICONS from "../../../config/constants/icons";
import CorporateForm from "./CorporateForm";
import {
  useCorporates,
  useDeleteCorporate,
  useResendEmail,
  useUpdateCorporateStatus
} from "../../../config/query/corporateQuery";
import { getFilters } from "../../../config/helpers/filterHelper";
import { STATUS } from "../../../config/constants/enums";
import { useNavigate } from "react-router";
import { makeInitialQueryObject } from "../../../config/helpers/queryHelper";
import StatusSwitch from "../../../components/BasicUI/DataBoxes/StatusSwitch";
import { formatAmount } from "../../../config/helpers/stringHelper";
import useDebounce from "../../../config/hooks/useDebounce";
import { APP_MODULES } from "../../../config/constants";
import CopyText from "../../../components/BasicUI/DataBoxes/CopyText";
import PhoneBox from "../../../components/BasicUI/DataBoxes/PhoneBox";
import EmailBox from "../../../components/BasicUI/DataBoxes/EmailBox";
import { useSelector } from "react-redux";
import { getPermission } from "../../../config/helpers/permissionHelper";

const Corporate = () => {
  const navigate = useNavigate();
  const [selectedCorporate, setSelectedCorporate] = React.useState(null);
  const formDisclosure = useDisclosure();

  const permissions = useSelector((state) => state?.user?.permissions)
  const corporatePermissions = getPermission({ permissionKey: APP_MODULES.Corporate, permissions })

  const [query, setQuery] = useState(makeInitialQueryObject());
  const debouncedQuery = useDebounce(query)

  const corporateQuery = useCorporates(debouncedQuery);
  const deleteCorporateQuery = useDeleteCorporate();
  const corporateStatus = useUpdateCorporateStatus()
  const resendEmailQuery = useResendEmail()

  const onEditModal = (item) => {
    setSelectedCorporate(item);
    formDisclosure.onOpen();
  };

  const handleStatusChange = (status, id) => {
    corporateStatus.mutate({ status: Number(status), id });
  };

  const handleDelete = (id) => {
    deleteCorporateQuery
      .mutateAsync(id)
      .then(() => corporateQuery.refetch())
      .catch((error) => console.warn(error));
  };

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }))
  };

  const onResendEmail = (corporateId) => {
    resendEmailQuery
      .mutateAsync(corporateId)
      .catch((error) => console.warn(error))
  }

  return (
    <Box>
      <TableHeaderOptions
        title={"Corporate Clients"}
        actionText={"Add Corporate"}
        action={() => {
          setSelectedCorporate(null);
          formDisclosure.onOpen();
        }}
        icon={APP_ICONS.ADD}
        permissionKey={APP_MODULES.Corporate}
      />
      <CustomTable
        permissionKey={APP_MODULES.Corporate}
        tableFor="corporate"
        searchPlaceholder="Search corporate"
        filters={getFilters({
          Status: Object.keys(STATUS).map((item) => ({
            key: item,
            value: STATUS[item],
          })),
        })}
        head={[
          {
            title: "Company Name",
            extractor: "companyName",
            align: "left",
            isSortable: true,
            component: (item) => (
              <TableInfoPopover
                data={item}
                titleKey="companyName"
                onEdit={(item) => onEditModal(item)}
                triggerOnClick={() => onEditModal(item)}
                moreActions={corporatePermissions?.additionalActions?.includes("ResendVerificationEmail") &&
                  <Button
                    size="sm"
                    isLoading={resendEmailQuery.isLoading}
                    onClick={() => onResendEmail(item.id)}
                  >
                    Resend Email
                  </Button>
                }
              >
                <LabelValuePair label="Phone"
                  value={item.phone}
                  valueComponent={<PhoneBox value={item.phone} />}
                />
                <LabelValuePair label="Email"
                  value={item.email}
                  valueComponent={<EmailBox value={item.email} />}
                />
                <LabelValuePair label="Description" value={item.description} />
                <LabelValuePair
                  label="Corporate Code"
                  value={item.corporateCode}
                  valueComponent={<CopyText text={item.corporateCode} />}
                />
                <LabelValuePair
                  label="Status"
                  value={true}
                  valueComponent={<StatusBadge value={item.status} />}
                />
                <LabelValuePair
                  label="Created"
                  value={formatDateTime(item.createdOn)}
                />
                <LabelValuePair
                  label="Last Updated"
                  value={formatDateTime(item.lastModifiedOn)}
                />
              </TableInfoPopover>
            ),
          },
          { title: "Admin Name", extractor: "name", isSortable: true },
          { title: "Balance", extractor: "balance", align: "center", component: (item) => <Text fontSize="14px">{formatAmount(item.balance)}</Text> },
          {
            title: "Verification Date",
            extractor: "registrationDate",
            component: (item) => formatDateTime(item.registrationDate),
          },
          {
            title: "Direct Invoice",
            extractor: "directInvoiceEnabled",
            isSortable: true,
            align: "center",
            component: (item) => <StatusBadge boolean={true} value={item.directInvoiceEnabled} />
          },
          {
            title: "Status",
            extractor: "status",
            align: "center",
            isSortable: true,
            component: (item) => (
              <StatusSwitch
                key={item.id}
                value={item.status?.id}
                onChange={(e) => handleStatusChange(e.target.checked, item.id)}
              />
            ),
          },
          {
            title: "Last Modified",
            align: "center",
            extractor: "lastModifiedOn",
            isSortable: true,
            component: (item) => <Chronology data={item} />,
          },
          { title: "Actions", extractor: "actions", align: "center" },
        ]}
        data={corporateQuery?.data?.data}
        loading={corporateQuery?.isLoading}
        totalResults={corporateQuery?.data?.meta?.totalCount}
        totalPages={corporateQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => corporateQuery.refetch()}
        isRefreshing={corporateQuery?.isFetching}
        onDelete={handleDelete}
        onEdit={(item) => onEditModal(item)}
        onView={(item) => navigate(`/admin/corporate/${item.id}`)}
      />

      <CorporateForm disclosure={formDisclosure} data={selectedCorporate} />
    </Box>
  );
};

export default Corporate;
