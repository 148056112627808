import { APP_MODULES } from "../constants";

export const preparePermissionsMap = (data) => {
    const result = {};
    if (!data) return result;
    data.forEach(item => {
        const { module, ...permissions } = item;
        const modulePermissions = {};

        for (const permission in permissions) {
            if (permissions[permission] !== null) {
                modulePermissions[permission] = permissions[permission];
            }
        }

        if (Object.values(modulePermissions).some(value => value === true)) {
            result[module?.id] = modulePermissions;
        }
    });
    return result
}

export const pereparePermissionsValue = (data) => {
    if (!data) return { permissions: {}, additionalActions: {} }
    const result = data.reduce((acc, item) => {
        const moduleName = item.module.name;

        // Create permissions object only for true values
        acc.permissions[moduleName] = Object.entries(item)
            .filter(([key, value]) => key !== 'module' && key !== 'additionalActions' && value === true)
            .map(([key]) => key);

        // Create additionalActions object only for true values
        acc.additionalActions[moduleName] = item.additionalActions?.filter(action => action !== 'Extra');

        return acc;

    }, { permissions: {}, additionalActions: {} });

    return result
}

export const preparePermissionsArray = (permissions, additionalActions) => {
    const resultArray = [];
    for (const key in permissions) {
        if (Object.prototype.hasOwnProperty.call(permissions, key)) {
            const item = {
                module: APP_MODULES[key],
                view: false,
                create: false,
                update: false,
                delete: false,
            };
            if (additionalActions[key] && additionalActions[key].length > 0) {
                item.additionalActions = additionalActions[key] || []
                if (item.additionalActions.Extra) {
                    delete item.additionalActions.Extra
                }
            }
            if (permissions[key]?.length > 0) {
                permissions[key]?.forEach(action => {
                    item[action] = true;
                });
            }
            if (typeof APP_MODULES[key] !== "undefined") {
                resultArray.push(item);
            }
        }
    }
    return resultArray
}

export const getPermission = ({ permissions, permissionKey }) => {
    if (permissions?.[permissionKey] !== undefined) {
        return permissions[permissionKey];
    } else {
        return false; // Permission not found for the given key
    }
}