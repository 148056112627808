import {
  Box,
  Card,
  CardBody,
  Heading,
  useColorMode,
  Grid,
  GridItem,
  chakra,
  HStack,
  Badge,
  Text,
  Button,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import MultiLineChart from "../../../components/Charts/MultiLineChart";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";
import { useDashboardEarnings, useDashboardGraph } from "../../../config/query/dashboardQuery";
import { formatDateForSever } from "../../../config/helpers/dateHelper";
import { Link } from "react-router-dom";
import { formatAmount, formatPercentage } from "../../../config/helpers/stringHelper";

const EarningChart = ({ query }) => {
  const { colorMode } = useColorMode();
  const graphQuery = useDashboardGraph({
    ...query,
    FromDate: query?.FromDateTime ? formatDateForSever(query?.FromDateTime) : undefined,
    ToDate: query?.TillDateTime ? formatDateForSever(query?.TillDateTime) : undefined
  })
  const earningQuery = useDashboardEarnings(query);

  return (
    <Card bg={getColor(colorKeys.tableBackground, colorMode)}>
      <CardBody minH="400px">
        <Heading as="h2" size={"md"} fontSize="15px" fontWeight={"600"} mb={4}>
          Earning
        </Heading>
        <Grid templateColumns="repeat(12, 1fr)">
          <GridItem w="100%" h="full" colSpan={{ base: 5, md: 5, "2xl": 3 }}>
            <Box>
              <chakra.p
                color={getColor(colorKeys.secondaryText)}
                fontSize="13px"
                pt={"8px"}
                pb={4}
              >
                This Month
              </chakra.p>

              <HStack spacing={1} align={"center"}>
                <Text fontSize="22px" fontWeight={"500"}>
                  {formatAmount(earningQuery.data?.earningThisMonth)}
                </Text>
              </HStack>
              <HStack spacing="2" align={"center"} w="full">
                <Badge colorScheme={"green"} fontSize="12px" fontWeight={"500"}>
                  {formatPercentage(earningQuery.data?.fromPreviousPeriod)}
                </Badge>
                <Text fontSize={"13px"}>From previous period</Text>
              </HStack>
              <Button
                mt={5}
                bg={getColor(colorKeys.primaryButtonFill, colorMode)}
                _hover={{ bg: getColor(colorKeys.primaryButtonFillHover, colorMode) }}
                w="100px"
                h="25px"
                rounded="md"
                fontSize={"12px"}
                color="white"
                fontWeight="300"
                rightIcon={<Icon as={APP_ICONS.RightChevron} pb={"2px"} />}
                as={Link}
                to="/admin/reports/booking-details"
              >
                View Detail
              </Button>
              <chakra.p
                color={getColor(colorKeys.secondaryText)}
                fontSize="13px"
                pt={4}
                pb={2}
              >
                Last Month
              </chakra.p>
              <Text fontSize="16px" fontWeight={"500"}>
                {formatAmount(earningQuery.data?.earningThisMonth)}
              </Text>
            </Box>
          </GridItem>
          <GridItem w="100%" h="full" colSpan={{ base: 5, md: 7, "2xl": 9 }}>
            <MultiLineChart data={graphQuery.data?.data} viewMode={query?.ViewMode} />
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default EarningChart;
