import {
    useQuery,
    useMutation
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Patch, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { CONTENT_TYPE, MILISECONDS } from "../constants";

export const useProfile = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ['profile'],
        queryFn: async () => {
            const res = await Get({
                path: API_CONSTANTS.PROFILE.getProfile,
                token,
                toastError: false,
                toastMessage: false,
            })
            return res
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,
    })
}

export const useUpdateProfile = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.PROFILE.dataKeys)
            const res = await Put({
                path: API_CONSTANTS.PROFILE.updateProfile,
                token,
                body,
                toastError: true,
                toastMessage: true,
            })
            return res
        },
    })
}

export const useUpdateProfileImage = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Patch({
                path: API_CONSTANTS.PROFILE.updateImage,
                token,
                body,
                toastError: true,
                toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA,
            })
            return res
        },
    })
}

export const useChangePassword = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: API_CONSTANTS.PROFILE.changePassword,
                token,
                body,
                toastError: true,
                toastMessage: true,
            })
            return res
        },
    })
}