import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS from "../constants/api";
import sortOrders from "../constants/sortOrders";

export const useTransactions = (params) => {
    const token = useSelector((state) => state.user?.token)
    params = {
        ...params,
        Sort: "lastModifiedOn",
        SortOrder: sortOrders.DESC,
    }
    return useQuery({
        queryKey: ["transactions", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.TRANSACTIONS.base}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true,
    })
}