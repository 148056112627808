import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    VStack,
    Flex,
    Box,
    useColorMode,
    HStack,
    Heading,
    IconButton,
    Icon,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { formatDateTimeWithSeconds } from '../../../../config/helpers/dateHelper';
import { colorKeys, getColor } from '../../../../config/constants/appColors';
import CircularLoading from '../../../../components/Skeletons/CircularLoading';
import APP_ICONS from '../../../../config/constants/icons';
import { useTransactions } from '../../../../config/query/transactionQuery';
import { formatAmount } from '../../../../config/helpers/stringHelper';

const Transactions = ({ isOpen, onClose }) => {
    const { colorMode } = useColorMode();
    const { bookingId } = useParams();
    const transactionsQuery = useTransactions({ RideId: bookingId })

    useEffect(() => {
        if (isOpen) {
            transactionsQuery.refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} onClose={onClose} isLazy motionPreset='none'>
            <ModalOverlay />
            <ModalContent maxW="70rem">
                <Flex justify={"space-between"} p="10px 20px">
                    <Heading fontSize="20px" p="2">Transactions Logs</Heading>
                    <HStack>
                        <Button isLoading={transactionsQuery.isFetching} size="sm" onClick={transactionsQuery.refetch} leftIcon={<Icon as={APP_ICONS.REFRESH} />}>Refresh</Button>
                        <IconButton size="sm" onClick={onClose} icon={<Icon as={APP_ICONS.CLOSE} />} />
                    </HStack>
                </Flex>
                <ModalBody>
                    <CircularLoading loading={transactionsQuery.isLoading} />
                    <VStack spacing={0} align="stretch" h="calc(100vh - 320px)" overflow={"auto"}>
                        <Flex style={{ width: "auto", overflow: "unset" }} minW="830px" overflowX={"auto"} pos="sticky" top={0} justify={"space-between"} py="2" px="2" _odd={{ backgroundColor: getColor(colorKeys.tableStripedRowBackground, colorMode) }}>
                            <Flex
                                overflowWrap="anywhere"
                                flexWrap="wrap"
                                display={"flex !important"}
                                w={"125px"} fontSize="14px" fontWeight={"bold"}>TRN#</Flex>
                            <Box w="80px" fontSize="14px" fontWeight={"bold"}>Type</Box>
                            <Box w="80px" fontSize="14px" fontWeight={"bold"}>Amount</Box>
                            <Box w="120px" fontSize="14px" fontWeight={"bold"}>Description</Box>
                            <Box w="120px" fontSize="14px" fontWeight={"bold"}>Status</Box>
                            <Box w="80px" fontSize="14px" fontWeight={"bold"}>Role</Box>
                            <Box w="200px" fontSize="14px" fontWeight={"bold"}>Date Time</Box>
                        </Flex>
                        {!transactionsQuery.isLoading && transactionsQuery.data?.data?.map((item, index) =>
                            <Flex style={{ width: "auto", overflow: "unset" }} minW="830px" overflowX={"auto"} key={index} justify={"space-between"} flexWrap={"wrap"} py="2" px="2" _odd={{ backgroundColor: getColor(colorKeys.tableStripedRowBackground, colorMode) }}>
                                <Flex
                                    overflowWrap="anywhere"
                                    flexWrap="wrap"
                                    w={"125px"}
                                    fontSize="14px"
                                >
                                    {item?.transactionId || "N/A"}
                                </Flex>
                                <Box w="80px" fontSize="14px">{item?.transactionType?.name || "N/A"}</Box>
                                <Box w="80px" fontSize="14px">{formatAmount(item?.transactionAmount)}</Box>
                                <Box w="120px">
                                    {!item?.description && !item?.rideId && <Box fontSize="14px">N/A</Box>}
                                    {item?.description && <Box fontSize="14px">{item?.description}</Box>}
                                    {item?.rideId && <Box as="a" target='_blank' _hover={{ textDecor: "underline" }} href={`/admin/bookings/${item?.rideId}`} fontSize="14px">{item?.rideId}</Box>}
                                </Box>
                                <Box w="120px" fontSize="14px">{item?.status || "N/A"}</Box>
                                <Box w="80px" fontSize="14px">{item?.role || "N/A"}</Box>
                                <Box w="200px" fontSize="14px">{formatDateTimeWithSeconds(item?.createdOn)}</Box>
                            </Flex>
                        )}
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default Transactions