import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardBody,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router";
import TableHeaderOptions from "../../../components/BasicUI/CustomTable/TableHeaderOptions";
import CustomTabs from "../../../components/BasicUI/CustomTabs";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import VehicleTypes from "./VehicleTypes";
import Vehicles from "./Vehicles";
import APP_ICONS from "../../../config/constants/icons";
import Models from "./Model";
import Makes from "./Make";
import { APP_MODULES } from "../../../config/constants";

const Transport = () => {
  const { colorMode } = useColorMode();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const vehicleTypeDisclosure = useDisclosure();
  const defaultRateDisclosure = useDisclosure();
  const makeDisclosure = useDisclosure();
  const modelDisclosure = useDisclosure();

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const { pathname } = useLocation();

  const tabs = [
    {
      index: 0,
      addButtonText: "Add New Vehicle",
      head: "Vehicles",
      key: "vehicles",
      body: (
        <Vehicles
          disclosure={defaultRateDisclosure}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      ),
      disclosure: defaultRateDisclosure,
    },
    {
      index: 1,
      addButtonText: "Add New Service Type",
      head: "Service Types",
      key: "service-types",
      body: (
        <VehicleTypes
          disclosure={vehicleTypeDisclosure}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      ),
      disclosure: vehicleTypeDisclosure,
    },

    {
      index: 2,
      addButtonText: "Add New Model",
      head: "Models",
      key: "model",
      body: (
        <Models
          disclosure={modelDisclosure}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      ),
      disclosure: modelDisclosure,
    },
    {
      index: 3,
      addButtonText: "Add New Make",
      head: "Makes",
      key: "make",
      body: (
        <Makes
          disclosure={makeDisclosure}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      ),
      disclosure: makeDisclosure,
    },
  ];

  const [selectedTab, setSelectedTab] = React.useState(
    tabs.find((tab) => pathname.includes(tab.key)) || tabs[0]
  );

  const onTabChange = (index) => {
    navigate(`/admin/vehicle-management/${tabs[index].key}`);
    setSelectedTab(tabs[index]);
    setSelectedRow(null);
    setIsRefreshing(false);
  };

  useEffect(() => {
    setSelectedTab(tabs.find((tab) => pathname.includes(tab.key)) || tabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box>
      <TableHeaderOptions
        title={"Vehicle Management"}
        actionText={selectedTab?.addButtonText}
        icon={APP_ICONS.ADD}
        action={() => {
          setSelectedRow(null);
          selectedTab?.disclosure?.onOpen();
        }}
        actionProps={{ minW: 200 }}
        containerProps={{ mt: 3 }}
        permissionKey={APP_MODULES.VehicleManagement}

      />

      <Card mt={4}>
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
          <CustomTabs
            tabPanelProps={{
              p: 0,
            }}
            containerProps={{
              index: selectedTab?.index,
              onChange: onTabChange,
              isLazy: true,
            }}
            tabs={tabs}
            tabEndAction={{
              label: "Refresh",
              action: () => {
                setIsRefreshing(true);
                queryClient.refetchQueries(["types", "pricings"]);
              },
              isLoading: isRefreshing,
            }}
          />
        </CardBody>
      </Card>
    </Box>
  );
};

export default Transport;
