import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Put } from "../api";
import API_CONSTANTS from "../constants/api";

export const useMailConfig = () => {
    const token = useSelector((state) => state.user?.token);
    return useMutation({
        mutationKey: ["mail-config"],
        mutationFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.SYSTEM_CONFIG.mail,
                token,
                toastError: true,
            });
            return data;
        },
    });
}

export const useUpdateMailConfig = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token);
    return useMutation({
        mutationFn: async (body) => {
            const res = await Put({
                path: API_CONSTANTS.SYSTEM_CONFIG.mail,
                token,
                body,
                toastMessage: true,
            });
            return res;
        },
        onSuccess: () => queryClient.invalidateQueries(['mailConfig'])
    });
}

export const useSMSConfig = () => {
    const token = useSelector((state) => state.user?.token);
    return useMutation({
        mutationKey: ["sms-config"],
        mutationFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.SYSTEM_CONFIG.sms,
                token,
                toastError: true,
            });
            return data;
        },
    });
}

export const useUpdateSMSConfig = () => {
    const token = useSelector((state) => state.user?.token);
    return useMutation({
        mutationFn: async (body) => {
            const res = await Put({
                path: API_CONSTANTS.SYSTEM_CONFIG.sms,
                token,
                body,
                toastMessage: true,
            });
            return res;
        },
    });
}