export const scrollToBottom = (ref) => {
    if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
    }
};

export const scrollToId = (ref, id, { subtract = 0 }) => {
    if (ref.current && id) {
        if (subtract) {
            ref.current.scrollTop = document.getElementById(id)?.offsetTop - subtract;
            return
        }
        ref.current.scrollTop = document.getElementById(id)?.offsetTop;
    }
};
