import React from "react";
import {
  Button,
  IconButton,
  Icon,
  useDisclosure,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  Avatar,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import APP_ICONS from "../../../config/constants/icons";
import MembersList from "./MembersList";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  useDeleteChat,
  useLeaveChat,
} from "../../../config/query/supportQuery";
import ConfirmModal from "../../BasicUI/CustomModals/ConfirmModal";
import GroupInfo from "../ChatDrawers/GroupInfo";
import { removeOne } from "../../../config/redux/slices/chatSlice";

const ChatHeader = ({ chat, chatQuery, leaveChatRoom }) => {
  const dispatch = useDispatch();
  const membersDisclosure = useDisclosure();
  const groupDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const leaveDisclosure = useDisclosure();

  const { chatId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const leaveChatQuery = useLeaveChat();
  const deleteChatQuery = useDeleteChat();

  const onLeaveChat = () => {
    leaveChatQuery
      .mutateAsync(chatId)
      .then(() => {
        navigate(navigate(pathname.split("/").splice(0, 4).join("/")), {
          replace: true,
        });
        dispatch(removeOne(chatId));
        leaveChatRoom && leaveChatRoom();
        leaveDisclosure.onClose();
      })
      .catch((error) => console.warn(error));
  };

  //TODO: add delete chat api here
  const onDeleteChat = () => {
    deleteChatQuery
      .mutateAsync(chatId)
      .then(() => {
        navigate(navigate(pathname.split("/").splice(0, 4).join("/")), {
          replace: true,
        });
        dispatch(removeOne(chatId));
        leaveChatRoom && leaveChatRoom();
        deleteDisclosure.onClose();
      })
      .catch((error) => console.warn(error));
  };

  return (
    <Flex justify={"space-between"} pb="1rem" align={"center"}>
      <HStack>
        <Avatar name={chat?.chatTitle} src={chat?.chatImage} />
        <Text fontWeight={"bold"}>{chat?.chatTitle || "N/A"}</Text>
      </HStack>

      {pathname.includes("group") ? (
        <Menu>
          <MenuButton ml={1} as={Button} variant={"unstyled"}>
            <IconButton
              size="sm"
              icon={
                <Icon as={APP_ICONS.MORE_OPTIONS} transform={"rotate(90deg)"} />
              }
            />
          </MenuButton>
          <MenuList>
            {[
              {
                name: "Members",
                action: membersDisclosure.onToggle,
              },
              {
                name: "Update Group",
                action: groupDisclosure.onToggle,
              },
              {
                name: "Leave Chat",
                action: leaveDisclosure.onToggle,
              },
              {
                name: "Delete Group",
                action: deleteDisclosure.onToggle,
              },
            ].map((item, index) => (
              <MenuItem fontSize={"14px"} key={index} onClick={item.action}>
                {item.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      ) : null}

      <MembersList
        disclosure={membersDisclosure}
        chatId={chatId}
        chatType={chatQuery.data?.data?.chatType?.id}
      />
      <GroupInfo
        chatId={chatId}
        data={{
          title: chat?.name,
          description: chat?.description,
        }}
        disclosure={groupDisclosure}
        onSuccess={chatQuery.refetch}
      />
      <ConfirmModal
        title={"Delete Group?"}
        subtitle={"Are you sure you want to delete this group?"}
        confirmText={"Delete"}
        disclosure={deleteDisclosure}
        onConfirm={onDeleteChat}
        isLoading={deleteChatQuery.isLoading}
      />
      <ConfirmModal
        title={"Leave Group?"}
        subtitle={"Are you sure you want to leave this group?"}
        confirmText={"Leave"}
        disclosure={leaveDisclosure}
        onConfirm={onLeaveChat}
        isLoading={leaveChatQuery.isLoading}
      />
    </Flex>
  );
};

export default ChatHeader;
