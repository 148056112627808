import React from 'react'
import { HStack, Icon, Text, Tooltip } from '@chakra-ui/react'
import APP_ICONS from '../../../config/constants/icons'

const CapacityBox = ({ personCount, hasLuggageSpace }) => {
    let tooltip = `Has ${personCount} person capacity & ${hasLuggageSpace ? 'luggage space' : 'no luggage space'}`
    return (
        <Tooltip label={tooltip} aria-label="toolip">
            <HStack spacing={2} align="center" justify="center">
                <HStack spacing={1} align="center">
                    <Icon as={APP_ICONS.PEOPLE} boxSize="5" />
                    <Text fontSize="14px" fontWeight="bold"> x {personCount}</Text>
                </HStack>
                {hasLuggageSpace && (
                    <>
                        <Text fontSize="14px" fontWeight="bold">/</Text>
                        <Icon as={APP_ICONS.LUGGAGE} />
                    </>
                )}
            </HStack>
        </Tooltip>
    )
}

export default CapacityBox