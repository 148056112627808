import React, { useRef } from "react";
import { Divider, VStack } from "@chakra-ui/react";
import useChatInbox from "../../../../config/containers/chats/useChatInbox";
import { CHAT_TYPES } from "../../../../config/constants/enums";
import ChatItem from "../ChatItem";
import CircularLoading from "../../../../components/Skeletons/CircularLoading";
import NoResults from "../../../../components/SVGComponents/NoResults";
import ChatListSkeleton from "../../../../components/Skeletons/ChatListSkeleton";

const IssuesInbox = ({ query }) => {
  const messageListRef = useRef(null);

  const { chats, isFetchingNextPage, handleScroll, isLoading } = useChatInbox({
    messageListRef,
    chatType: CHAT_TYPES.ReportIssue,
    query,
  });

  if (isLoading) {
    return <ChatListSkeleton isLoading={isLoading} />;
  }

  return (
    <VStack
      ref={messageListRef}
      onScroll={handleScroll}
      divider={<Divider />}
      spacing={0}
      h="calc(100vh - 175px)"
      overflow="auto"
    >
      {chats?.map((item, index) => (
        <ChatItem chatItem={item} key={index} to="/admin/chats/disputes" title={`${item?.chatTitle} (#${item?.rideId?.toString()?.slice(-4)})`} />
      ))}
      {chats?.length === 0 && !isFetchingNextPage && <NoResults />}
      <CircularLoading loading={isFetchingNextPage} />
    </VStack>
  );
};

export default IssuesInbox;
