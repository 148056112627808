import { Box, VStack, useColorMode } from "@chakra-ui/react";
import React from "react";
import CircularLoading from "../../Skeletons/CircularLoading";
import MessageItem from "../ChatLayout/MessageItem";
import { colorKeys, getColor } from "../../../config/constants/appColors";

const Messages = ({ messages, isFetchingNextPage, handleScroll }, ref) => {
  const { colorMode } = useColorMode();
  return (
    <VStack
      mt={1}
      id="chat-messages-list"
      h="calc(100vh - 275px)"
      onScroll={handleScroll}
      mb="1"
      ref={ref}
      align="stretch"
      overflowY="auto"
    >
      <CircularLoading
        loading={isFetchingNextPage}
        containerProps={{ minH: "150px" }}
      />
      {!isFetchingNextPage && messages?.map((item, index) => (
        <MessageItem message={item} key={index} />
      ))}
      {messages?.length === 0 && !isFetchingNextPage && (
        <Box
          pt="10"
          textAlign="center"
          fontSize="13px"
          color={getColor(colorKeys.gray, colorMode)}
        >
          No messages yet
        </Box>
      )}
    </VStack>
  );
};

export default React.forwardRef(Messages);
