import { APP_MODULES } from ".";
import APP_ICONS from "./icons";

export const mainMenu = [
  {
    icon: APP_ICONS.DASHBOARD,
    title: "Dashboard",
    link: "/admin/dashboard",
    permission: APP_MODULES.Dashboard
  },
  {
    icon: APP_ICONS.MAP,
    title: "Map",
    link: "/admin/map",
    permission: APP_MODULES.Map
  },
  {
    icon: APP_ICONS.BOOKINGS,
    title: "Bookings",
    link: "/admin/bookings",
    permission: APP_MODULES.Booking
  },
  {
    icon: APP_ICONS.BOOKINGS,
    title: "Active Bookings",
    link: "/admin/active-bookings",
    permission: APP_MODULES.Booking
  },
  {
    icon: APP_ICONS.CORPORATE_SETTINGS,
    title: "Corporate Management",
    link: "/admin/corporate",
    permission: APP_MODULES.Corporate
  },
  {
    icon: APP_ICONS.USER_SETTINGS,
    title: "Customer Management",
    link: "/admin/customers",
    permission: APP_MODULES.Customer
  },
  {
    icon: APP_ICONS.DRIVERS,
    title: "Driver Management",
    link: "/admin/drivers",
    permission: APP_MODULES.Driver
  },
  {
    icon: APP_ICONS.VEHICLES,
    title: "Vehicle Management",
    link: "/admin/vehicle-management",
    permission: APP_MODULES.VehicleManagement
  },
  {
    icon: APP_ICONS.ZONES,
    title: "Zones",
    link: "/admin/zones",
    permission: APP_MODULES.Zone
  },
  {
    icon: APP_ICONS.DOLLAR_SIGN,
    title: "Pricing",
    link: "/admin/pricing",
    permission: APP_MODULES.Pricing
  },
  {
    icon: APP_ICONS.VERIFIED,
    title: "Promo Code",
    link: "/admin/promo-codes",
    permission: APP_MODULES.PromoCode
  },
  {
    icon: APP_ICONS.BELL,
    title: "Notifications",
    link: "/admin/notifications",
    permission: APP_MODULES.Notifications
  },
  {
    icon: APP_ICONS.ADMIN_USER,
    title: "Sub Admin",
    link: "/admin/sub-admin",
    permission: APP_MODULES.SubAdmin
  },
  {
    icon: APP_ICONS.ROLE,
    title: "Roles",
    link: "/admin/roles",
    permission: APP_MODULES.Roles
  },
  {
    icon: APP_ICONS.STAR,
    title: "Reviews",
    link: "/admin/reviews",
    permission: APP_MODULES.Reviews
  },
  {
    icon: APP_ICONS.EARNING_REPORTS,
    title: "Reports",
    link: "/admin/reports",
    permission: APP_MODULES.Reports
  },
  {
    icon: APP_ICONS.PROFILE,
    title: "Profile",
    link: "/admin/profile",
    permission: APP_MODULES.Profile
  },
  {
    icon: APP_ICONS.SETTINGS,
    title: "Settings",
    link: "/admin/settings",
    permission: APP_MODULES.Dashboard
  },
];
