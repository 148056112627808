import React, { useMemo, useState } from 'react'
import { Box, Flex, FormLabel, HStack, Icon, SimpleGrid, VStack, useColorMode } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import FormModal from '../../../components/Forms/FormModal'
import FormInput from '../../../components/Forms/FormInput'
import FormTextarea from '../../../components/Forms/FormTextarea'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import FormCheckbox from '../../../components/Forms/FormCheckbox'
import FormFileUpload from '../../../components/Forms/FormFileUpload'
import APP_ICONS from '../../../config/constants/icons'
import { useCreateNotification } from '../../../config/query/notificationQuery'
import { makeSelectList } from '../../../config/helpers/selectListHelper'
import { SEND_TO_ALL, SEND_TO_ALLS } from '../../../config/constants/enums'
import { preparePayload } from '../../../config/helpers/formHelpers/notificationHelper'
import { URL_REGEX } from '../../../config/constants/regexConstants'
import FormComboBox from '../../../components/Forms/FormComboBox'
import useDebounce from '../../../config/hooks/useDebounce'
import { useCorporateOptions } from '../../../config/query/corporateQuery'
import { useCustomerOptions } from '../../../config/query/customerQuery'
import { useDriverOptions } from '../../../config/query/driverQuery'

const NotificationForm = ({ disclosure }) => {
    const queryClient = useQueryClient()
    const { colorMode } = useColorMode()
    const [fileData, setFileData] = useState(null)
    const [queries, setQueries] = useState({
        corporate: "",
        driver: "",
        customer: ""
    })
    const debouncedQuery = useDebounce(queries)

    const createNotificationQuery = useCreateNotification()
    const driverQuery = useDriverOptions(debouncedQuery?.driver)
    const corporateQuery = useCorporateOptions(debouncedQuery?.corporate)
    const customerQuery = useCustomerOptions(debouncedQuery?.customer)

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset: resetForm,
        watch,
    } = useForm()

    const sendTo = watch("SendTo")
    const corporateIds = watch("CorporateIds")
    const customerIds = watch("CustomerIds")
    const driverIds = watch("DriverIds")

    const onSubmit = (values) => {
        // preparePayload(values, fileData)
        // return;
        createNotificationQuery
            .mutateAsync(preparePayload(values, fileData))
            .then(() => {
                disclosure.onClose()
                queryClient.invalidateQueries(["notifications"])
                setFileData(null)
                resetForm()
            })
            .catch((error) => console.warn(error))
    }

    const onChangeImage = (file) => {
        setFileData(file)
    }


    const imgSrc = useMemo(() => {
        if (!fileData) return ''
        return URL.createObjectURL(fileData)
    }, [fileData])


    return (
        <FormModal
            title={"Add Notification"}
            disclosure={disclosure}
            isSubmitting={createNotificationQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"55rem"}
        >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mb={5}>

                <VStack spacing={2} align={"flex-start"}>
                    <FormInput
                        label={"Title"}
                        control={control}
                        errors={errors}
                        id="Title"
                        required={true}
                        placeholder="Enter title"
                        inputProps={{ size: "sm" }}
                        rules={{
                            minLength: {
                                value: 3,
                                message: "Title must be atleast 3 characters long"
                            },
                            maxLength: {
                                value: 100,
                                message: "Title must be less than 100 characters long"
                            }
                        }}
                    />

                    <FormInput
                        label={"URL"}
                        control={control}
                        errors={errors}
                        id="Url"
                        placeholder="https://example.com"
                        inputProps={{ size: "sm" }}
                        rules={{
                            pattern: {
                                value: URL_REGEX,
                                message: "Please enter a valid url"
                            }
                        }}
                    />

                    <FormCheckbox
                        id="SendTo"
                        label={"Send To"}
                        control={control}
                        errors={errors}
                        options={makeSelectList(SEND_TO_ALL, null, null, false)}
                        stackProps={{ as: HStack, flexWrap: "wrap" }}
                        optionLabelProps={{ fontSize: "13px" }}
                        labelProps={{ fontSize: "13px" }}
                        containerProps={{ mb: 1 }}
                    />

                    <FormComboBox
                        id="CorporateIds"
                        label={"Corporates"}
                        placeholder="Search corporates"
                        control={control}
                        errors={errors}
                        required={!(sendTo?.length || customerIds?.length || driverIds?.length)}
                        inputProps={{ size: "sm", isDisabled: sendTo?.includes(SEND_TO_ALLS.AllCorporates) }}
                        searchFn={(query) => {
                            setQueries((prev) => ({
                                ...prev,
                                corporate: query
                            }))
                        }}
                        isLoading={queries?.corporate && corporateQuery.isLoading}
                        options={corporateQuery.data?.data?.map((item) => ({ value: item.id, label: item.name })) || []}
                        selectedContainerProps={{ mb: 5 }}
                    />

                    <FormComboBox
                        id="CustomerIds"
                        label={"Customers"}
                        required={!(sendTo?.length || corporateIds?.length || driverIds?.length)}
                        placeholder="Search customers by phone number"
                        control={control}
                        errors={errors}
                        inputProps={{ size: "sm", isDisabled: sendTo?.includes(SEND_TO_ALLS.AllCustomers) }}
                        searchFn={(query) => {
                            setQueries((prev) => ({
                                ...prev,
                                customer: query
                            }))
                        }}
                        isLoading={queries?.customer && customerQuery.isLoading}
                        options={customerQuery.data?.data?.map((item) => ({ value: item.id, label: item.name })) || []}
                    />

                    <FormComboBox
                        id="DriverIds"
                        label={"Drivers"}
                        placeholder="Search drivers"
                        control={control}
                        required={!(sendTo?.length || customerIds?.length || corporateIds?.length)}
                        errors={errors}
                        inputProps={{ size: "sm", isDisabled: sendTo?.includes(SEND_TO_ALLS.AllDrivers) }}
                        searchFn={(query) => {
                            setQueries((prev) => ({
                                ...prev,
                                driver: query
                            }))
                        }}
                        isLoading={queries?.driver && driverQuery.isLoading}
                        options={driverQuery.data?.data?.map((item) => ({ value: item.id, label: item.name })) || []}
                    />

                </VStack>


                <VStack spacing={2} align={"flex-start"}>
                    <Box w="full">
                        <FormLabel fontSize="13px">Image:</FormLabel>
                        <FormFileUpload
                            id={"profilePicture"}
                            onChange={onChangeImage}
                            component={<Flex minH={"155px"} align={"center"} justify={"center"} >
                                {!fileData && <Icon boxSize={"10"} as={APP_ICONS.IMAGE_ADD} color="#a0c7dd" />}
                            </Flex>}
                            fileType={"IMAGE"}
                            componentProps={{
                                border: "1px solid #a0c7dd",
                                variant: "ghost",
                                bg: fileData ? `url(${imgSrc})` : "",
                                bgSize: "cover",
                                bgRepeat: "no-repeat",
                                bgPos: "center",
                                w: "full",
                                _hover: {
                                    bg: fileData ? `url(${imgSrc})` : getColor(colorKeys.lighterBlue, colorMode),
                                    bgSize: "cover",
                                    bgRepeat: "no-repeat",
                                    bgPos: "center",
                                }
                            }}
                        />

                    </Box>

                    <FormTextarea
                        id={"Body"}
                        label="Note"
                        placeholder="Write anything you want to note about this customer"
                        errors={errors}
                        control={control}
                        textAreaProps={{ rows: 9 }}
                        required={true}
                    />

                </VStack>


            </SimpleGrid>



        </FormModal>
    )
}

export default NotificationForm