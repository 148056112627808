import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import { useState } from "react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import CustomTable from "../../../components/BasicUI/CustomTable";
import { formatDateTime } from "../../../config/helpers/dateHelper";
import { updateUrl } from "../../../config/helpers/queryHelper";
import useDebounce from "../../../config/hooks/useDebounce";
import { useParams } from "react-router";
import appendQueryParams from "../../../config/helpers/appendQueryParams";
import { APP_MODULES } from "../../../config/constants";
import { useDriverDispatchLogs } from "../../../config/query/driverQuery";
import { Link } from "react-router-dom";
import DateRangeInput from "../../../components/Filters/DateRangeInput";
import { openCoordinatesOnGoogleMaps } from "../../../config/helpers/urlHelper";
import { getLatLng } from "../../../config/helpers/stringHelper";
import sortOrders from "../../../config/constants/sortOrders";

const DriverDispatchLogs = ({ driverName }) => {
  const { driverId } = useParams();
  const { colorMode } = useColorMode();

  const [query, setQuery] = useState({
    PageNumber: 1,
    PageSize: 20,
    Sort: "date",
    SortOrder: sortOrders.DESC,
  });
  const debouncedQuery = useDebounce(query);

  const bookingsQuery = useDriverDispatchLogs(driverId, debouncedQuery);

  const onQueryChange = (updatedQuery) => {
    let temp = {};
    setQuery((prev) => {
      temp = { ...prev, ...updatedQuery };
      return temp;
    });
    updateUrl({
      pageTitle: "Driver Dispatch Logs",
      queryString: appendQueryParams(temp),
    });
  };

  return (
    <Box w="full">
      <Card w="full" mt={3}>
        <CardHeader pb={0} bg={getColor(colorKeys.tableBackground, colorMode)}>
          <Flex justifyContent="space-between">
            <Heading
              color={getColor(colorKeys.dark, colorMode)}
              fontSize="18px"
            >
              {driverName
                ? `${driverName}'s Dispatch Logs`
                : "Driver's Dispatch Logs"}
            </Heading>
          </Flex>
        </CardHeader>

        <CardBody pt={3} bg={getColor(colorKeys.tableBackground, colorMode)}>
          <CustomTable
            permissionKey={APP_MODULES.Driver}
            tableFor="driver dispatch logs"
            tableWrapperProps={{ padding: 0, h: "calc(100vh - 410px)" }}
            containerProps={{ h: "calc(100vh - 275px)" }}
            customFilters={
              <DateRangeInput query={query} onQueryChange={onQueryChange} />
            }
            head={[
              {
                title: "Booking",
                extractor: "rideId",
                align: "left",
                isSortable: true,
                component: (item) =>
                  item.rideId ? (
                    <Text
                      _hover={{ textDecor: "underline" }}
                      as={Link}
                      to={`/admin/bookings/${item.rideId}`}
                    >
                      {item.rideId}
                    </Text>
                  ) : (
                    "N/A"
                  ),
              },
              {
                title: "Performer",
                extractor: "performer",
                align: "left",
                isSortable: true,
              },
              {
                title: "Performer Name",
                extractor: "name",
                align: "left",
                isSortable: true,
              },
              {
                title: "Ride Status",
                extractor: "rideStatus",
                align: "left",
                isSortable: true,
              },
              {
                title: "Driver Location",
                extractor: "driverLocation",
                align: "left",
                component: (item) => {
                  const latLng = getLatLng(item.driverLocation);
                  if (!latLng.lat && !latLng.lng) return "-";
                  return (
                    <Tooltip label="Open in google maps">
                      <a
                        rel="noreferrer"
                        className="hover-underline"
                        target="_blank"
                        href={openCoordinatesOnGoogleMaps(latLng)}
                      >
                        {item.driverLocation}
                      </a>
                    </Tooltip>
                  );
                },
              },
              {
                title: "Vehicle To Pickup Location Estimate",
                extractor: "vehicleToPickupLocationEstimate",
                align: "left",
              },
              {
                title: "Date Time",
                extractor: "date",
                align: "left",
                component: (item) => formatDateTime(item?.date),
                isSortable: true,
              },
            ]}
            data={bookingsQuery?.data?.data}
            loading={bookingsQuery?.isLoading}
            totalResults={bookingsQuery?.data?.meta?.totalCount}
            totalPages={bookingsQuery?.data?.meta?.totalPages}
            pageNo={query?.PageNumber}
            pageSize={query?.PageSize}
            onQueryChange={onQueryChange}
            query={query}
            onRefresh={bookingsQuery.refetch}
            isRefreshing={bookingsQuery?.isFetching}
          />
        </CardBody>
      </Card>
    </Box>
  );
};

export default DriverDispatchLogs;
