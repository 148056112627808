import React from "react";
import { Flex, Image, useColorMode } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import WhiteLogo from "../../SVGComponents/WhiteLogo";

const Brand = () => {
  const { colorMode } = useColorMode();
  const user = useSelector((state) => state.user?.user);
  const logoUrl = user?.tenant?.logoUrl;
  return (
    <Flex
      justify="center"
      h={"70px"}
      align="center"
      bg={getColor(colorKeys.siderBackground, colorMode)}
    >
      <Link to="/admin/dashboard">
        {logoUrl ? <Image h="50px" src={logoUrl} /> : <WhiteLogo />}
      </Link>
    </Flex>
  );
};

export default React.memo(Brand);
