import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";

export const useDashboardStats = (params = {}) => {
    const token = useSelector((state) => state.user?.token)
    params = prepareData(params, API_CONSTANTS.DASHBOARD.filterKeys)
    return useQuery({
        queryKey: ["dashboard-stats", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DASHBOARD.stats}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useDashboardEarnings = (params = {}) => {
    const token = useSelector((state) => state.user?.token)
    params = prepareData(params, API_CONSTANTS.DASHBOARD.filterKeys)
    return useQuery({
        queryKey: ["dashboard-earnings", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DASHBOARD.earnings}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useDashboardRatings = (params = {}) => {
    const token = useSelector((state) => state.user?.token)
    params = prepareData(params, API_CONSTANTS.DASHBOARD.filterKeys)
    return useQuery({
        queryKey: ["dashboard-ratings", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DASHBOARD.ratings}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useDashboardBookings = (params = {}) => {
    const token = useSelector((state) => state.user?.token)
    params = prepareData(params, API_CONSTANTS.DASHBOARD.filterKeys)
    return useQuery({
        queryKey: ["dashboard-bookings", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DASHBOARD.bookings}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useDashboardGraph = (params = {}) => {
    const token = useSelector((state) => state.user?.token)
    params = prepareData(params, API_CONSTANTS.DASHBOARD.graphFilterKeys)
    return useQuery({
        queryKey: ["dashboard-graph", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DASHBOARD.graph}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useFinishedBookings = (params) => {
    const token = useSelector((state) => state.user?.token)
    params = prepareData(params, API_CONSTANTS.DASHBOARD.filterKeys)
    return useQuery({
        queryKey: ["finished-bookings", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.DASHBOARD.finishedBookings}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}