import { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import {
    useGeneralInbox,
    useReadLastMessage,
    useAcknowledgeLastMessage,
} from "../../query/supportQuery";
import { makeInitialQueryObject } from "../../helpers/queryHelper";
import { SOCKET_SERVER } from "../../constants/chat";
import { useSignalREffectAlt } from "../../sockets/SignalRContext";
import useDebounce from "../../hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { increaseTotalUnreadCount, updateChats } from "../../redux/slices/chatSlice";
import { useLocation, useParams } from "react-router";
import { isChatOpen } from "../../helpers/chatHelpers";

const useChatInbox = ({ messageListRef, chatType, query }) => {
    const { chatId } = useParams();
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    const openedChats = useSelector(state => state.chat.openedChats)
    const chats = useSelector(state => state.chat.chats)
    const user = useSelector(state => state.user.user)
    const setChats = (data) => dispatch(updateChats(data))

    const [data, setData] = useState(makeInitialQueryObject({
        Sort: "createdAt",
        ChatType: chatType,
        "AdvancedSearch.Keyword": query,
        "AdvancedSearch.Fields": ["ChatTitle"],
    }))
    const debouncedQuery = useDebounce(data)
    const readLastMessageQuery = useReadLastMessage();
    const acknowledgeLastMessageQuery = useAcknowledgeLastMessage();

    const {
        data: inboxData,
        hasNextPage,
        fetchNextPage,
        refetch: refreshInbox,
        remove: removeInbox,
        isFetchingNextPage,
        isLoading,
    } = useGeneralInbox(debouncedQuery);


    const handleFetchNextPage = () => {
        if (hasNextPage) {
            fetchNextPage({
                pageParam:
                    Number(
                        inboxData?.pages?.at(inboxData?.pages?.length - 1)?.meta
                            ?.currentPage + 1
                    ) || 1,
            });
        }
    };

    const handleScroll = () => {
        if (messageListRef.current) {
            const bottom =
                messageListRef.current.scrollHeight -
                messageListRef.current.scrollTop ===
                messageListRef.current.clientHeight;
            if (bottom && !isFetchingNextPage) {
                handleFetchNextPage();
            }
        }
    };

    useEffect(() => {
        if (inboxData?.pages?.length > 0) {
            const flatMessages = inboxData?.pages.flatMap((page) => page?.data);
            setChats([...flatMessages]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inboxData?.pages?.length, debouncedQuery, chatType]);

    useEffect(() => {
        setData((prev) => ({
            ...prev,
            "AdvancedSearch.Keyword": query,
        }));
    }, [query]);

    useEffect(() => {
        return () => removeInbox()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getChatOpenStatus = (id) => {
        if (pathname.includes("chats")) {
            return Number(id) === Number(chatId)
        }
        else {
            return openedChats.some((item) => item.chatId === data?.chatId)
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleUpdateInboxManually = useCallback(
        _.debounce((message) => {
            if (message.chatType?.id !== chatType) return;

            const chatId = Number(message?.chatId);
            const isChatOpen = getChatOpenStatus(chatId);
            const existingChatIndex = chats.findIndex((val) => Number(val?.chatId) === chatId);

            if (existingChatIndex !== -1) {
                const updatedChats = chats.filter((val) => Number(val?.chatId) !== chatId);
                const chatObj = chats[existingChatIndex];
                message.unReadCount = (!isChatOpen && message?.user?._id !== user?.id) ? (Number(chatObj?.unReadCount) || 0) + 1 : 0;
                setChats([message, ...updatedChats]);
            } else {
                message.unReadCount = !isChatOpen ? 1 : 0;
                setChats([message, ...chats]);
            }
        }, 2000), // Adjust debounce duration as needed
        [chatId]
    );


    const cb = (item) => {
        let UpdatedChatCounts = chats.map((val) => {
            let data = { ...val }
            if (Number(data.chatId) === Number(item.chatId)) {
                data.unReadCount = 0
                return data
            }
            return data
        })
        setChats(UpdatedChatCounts)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLastMessage = useCallback(
        _.debounce((specificChat) => {
            if (specificChat?.user?._id === user?.id) return
            if (!isChatOpen({ pathname, openedChats })) {
                dispatch(increaseTotalUnreadCount())
            }
            const { _id, chatId: messageChatId } = specificChat;
            let formMutate = getChatOpenStatus(messageChatId) ? readLastMessageQuery.mutateAsync : acknowledgeLastMessageQuery.mutateAsync
            const payload = {
                id: messageChatId,
                messageId: _id,
            }
            formMutate(payload)
                .catch((err) => console.warn(err));
        }, 2000), // Adjust debounce duration as needed
        [acknowledgeLastMessageQuery, readLastMessageQuery]
    );

    useSignalREffectAlt(SOCKET_SERVER.InboxMessage, (message) => {
        handleUpdateInboxManually(message)
        if (message && message.chatId) {
            handleLastMessage(message);
        }
    });

    return {
        handleScroll,
        chats,
        isFetchingNextPage,
        refreshInbox,
        isLoading,
        cb,
    }

};

export default useChatInbox;