import React from 'react'
import { Card, CardBody, CardHeader, Heading, VStack, useColorMode } from '@chakra-ui/react'
import { Marker } from '@react-google-maps/api'
import { colorKeys, getColor } from '../../../../config/constants/appColors'
import SimpleMap from '../../../../components/Maps/SimpleMap'
import { getDefaultCoordinates } from '../../../../config/helpers/locationHelper'
import FormButton from '../../../../components/Forms/FormButton'

const LocationInfo = ({ watch, onSubmit, isSubmitting, formButtonText = "Create" }) => {
    const { colorMode } = useColorMode()
    const { pickup, destination, stops } = watch()

    return (
        <Card>
            <CardHeader p="15px 18px 0px" bg={getColor(colorKeys.tableBackground, colorMode)}>
                <Heading size="md">Location Info</Heading>
            </CardHeader>
            <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
                <VStack spacing={5} align="stretch">
                    <SimpleMap
                        containerProps={{ h: "500px" }}
                        center={pickup?.latLng || destination?.latLng || stops?.at(0)?.latLng || getDefaultCoordinates()}
                    >
                        {pickup && <Marker
                            position={pickup?.latLng}
                            options={{
                                label: {
                                    text: "1",
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                },
                            }}
                            icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 7,
                                strokeColor: getColor(colorKeys.pickup, colorMode),
                                fillOpacity: 1,
                                fillColor: getColor(colorKeys.pickup, colorMode),
                            }}
                        />}
                        {destination && <Marker
                            position={destination?.latLng}
                            options={{
                                label: {
                                    text: String(stops?.length + 2),
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                },
                            }}
                            icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 7,
                                strokeColor: getColor(colorKeys.dropoff, colorMode),
                                fillOpacity: 1,
                                fillColor: getColor(colorKeys.dropoff, colorMode),
                            }}
                        />}
                        {stops && Array.isArray(stops) && stops?.map((item, index) =>
                            <Marker
                                key={item?.value}
                                position={item?.latLng}
                                options={{
                                    label: {
                                        text: String(index + 2),
                                        color: "#fff",
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                    },
                                }}
                                icon={{
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 7,
                                    strokeColor: getColor(colorKeys.stop, colorMode),
                                    fillOpacity: 1,
                                    fillColor: getColor(colorKeys.stop, colorMode),
                                }}
                            />
                        )}
                    </SimpleMap>

                    <FormButton
                        type='submit'
                        colorScheme='blue'
                        isLoading={isSubmitting}
                        onClick={onSubmit}
                        w="full"
                        containerStyles={{
                            w: "full"
                        }}
                    >
                        {formButtonText}
                    </FormButton>

                </VStack>
            </CardBody>
        </Card>
    )
}

export default LocationInfo