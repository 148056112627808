import {
  BsCheckCircle,
  BsCurrencyDollar,
  BsPlus,
  BsFillCaretDownFill,
  BsCheck2All,
  BsFileText,
  BsGear,
  BsListUl,
  BsTag,
  BsTrash,
  BsArrowUpShort,
  BsArrowDownShort,
  BsCaretDown,
  BsCaretDownFill,
  BsCaretUp,
  BsCaretUpFill,
  BsGraphUp,
  BsGrid,
  BsCalendar4Week,
  BsCarFront,
  BsStar,
  BsStarFill,
  BsStarHalf,
  BsPersonGear,
  BsMap,
  BsBell,
  BsFillSendFill,
  BsClock,
  BsArrowsAngleExpand,
  BsArrowsAngleContract,
  BsKey,
  BsImage,
  BsThreeDots,
  BsCheck2,
  BsChatLeftTextFill,
  BsChatLeftText,
  BsCloudUploadFill
} from "react-icons/bs";
import { HiOutlineBriefcase } from "react-icons/hi";
import {
  AiOutlineCalendar,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineInfoCircle,
  AiOutlineMail,
  AiOutlineMenuFold,
  AiOutlineMinus,
  AiOutlineUser,
  AiOutlineClose,
  AiFillFileText
} from "react-icons/ai";
import {
  FiFilter,
  FiUserPlus,
  FiMapPin,
  FiMenu,
  FiUsers,
  FiMoon,
  FiSun,
  FiLogOut,
  FiLock,
  FiSearch,
  FiExternalLink,
  FiList,
} from "react-icons/fi";
import { RiSteeringFill } from "react-icons/ri";
import {
  BiArchiveIn,
  BiBell,
  BiCar,
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
  BiChevronUp,
  BiCopyAlt,
  BiImageAdd,
  BiRadioCircleMarked,
  BiRefresh,
} from "react-icons/bi";
import {
  MdChecklist,
  MdContentPaste,
  MdOndemandVideo,
  MdPeopleAlt,
  MdOutlineAddLocationAlt,
  MdVerified,
  MdOutlineVerified,
  MdOutlineCancel,
  MdOutlineSaveAlt,
} from "react-icons/md";
import {
  FaEnvelope,
  FaHandHoldingUsd,
  FaLock,
  FaLuggageCart,
  FaPhoneAlt,
  FaRegCommentAlt,
  FaRegHandshake,
  FaRegMoneyBillAlt,
  FaRegUserCircle,
} from "react-icons/fa";
import {
  GrGroup,
  GrSupport,
  GrUserAdmin,
  GrUserSettings,
} from "react-icons/gr";

const APP_ICONS = {
  DOUBLE_CHECK: BsCheck2All,
  //NAVIGATION ICONS
  DASHBOARD: BsGrid,
  CANCEL: MdOutlineCancel,
  BOOKINGS: BsCalendar4Week,
  PROFILE: FaRegUserCircle,
  ANALYTICS: BsGraphUp,
  MAP: FiMapPin,
  CUE: MdChecklist,
  DRIVERS: RiSteeringFill,
  CUSTOMERS: MdPeopleAlt,
  PARTNERS: FaRegHandshake,
  WITHDRAWAL_REQUESTS: FaHandHoldingUsd,
  REPORTS: MdContentPaste,
  SETTINGS: BsGear,
  USER_SETTINGS: BsPersonGear,
  ROLE: BsKey,
  // CORPORATE_SETTINGS: BsBuildingGear,
  CORPORATE_SETTINGS: GrUserSettings,
  ZONES: BsMap,
  EARNING_REPORTS: FaRegMoneyBillAlt,
  ADMIN_USER: GrUserAdmin,
  VERIFIED: MdOutlineVerified,
  BELL: BsBell,
  BELL2: BiBell,
  VEHICLES: BiCar,
  SUPPORT: GrSupport,
  TENANTS: GrGroup,

  PEOPLE: MdPeopleAlt,
  LUGGAGE: FaLuggageCart,

  MENU: FiMenu,
  EXAMS: BsFileText,
  EXAMS_LIST: BsListUl,
  COURSES: HiOutlineBriefcase,
  CATEGORIES_TAG: BsTag,
  ARCHIVE: BiArchiveIn,
  LECTURES: MdOndemandVideo,
  USERS: FiUsers,
  EMAILS: AiOutlineMail,
  BIN: BsTrash,
  SINGLE_USER: AiOutlineUser,
  FILTER: FiFilter,
  COPY: BiCopyAlt,
  STAR: BsStar,
  STAR_FILL: BsStarFill,
  STAR_HALF: BsStarHalf,
  IMAGE_ADD: BiImageAdd,
  CHAT_FILL: BsChatLeftTextFill,
  CHAT: BsChatLeftText,
  CHAT_ALT: FaRegCommentAlt,
  CALL: FaPhoneAlt,
  EMAIL: FaEnvelope,

  DRAWER: AiOutlineMenuFold,

  WARNING: AiOutlineInfoCircle,

  //actions
  EDIT: AiOutlineEdit,
  REFRESH: BiRefresh,
  ADD: BsPlus,
  SUBTRACT: AiOutlineMinus,
  SEND: BsFillSendFill,
  IMAGE_SEND: BsImage,
  CLOSE: AiOutlineClose,
  EXPAND: BsArrowsAngleExpand,
  COLLAPSE: BsArrowsAngleContract,
  MORE_OPTIONS: BsThreeDots,
  SEARCH: FiSearch,
  SAVE: MdOutlineSaveAlt,

  //Navigation Icons
  LeftChevron: BiChevronLeft,
  RightChevron: BiChevronRight,
  UpChevron: BiChevronUp,
  DownChevron: BiChevronDown,
  UpArrow: BsArrowUpShort,
  DownArrow: BsArrowDownShort,
  CaretDown: BsCaretDown,
  CaretDownFill: BsCaretDownFill,
  CaretUp: BsCaretUp,
  CaretUpFill: BsCaretUpFill,
  OPEN: FiExternalLink,

  DropDownIcon: BsFillCaretDownFill,

  //Color Modes
  LighMode: FiSun,
  DarkMode: FiMoon,

  //Misc
  DOLLAR_SIGN: BsCurrencyDollar,
  BOOKING_REQUEST: MdOutlineAddLocationAlt,
  CHECK_CIRCLED: BsCheckCircle,
  CHECK: BsCheck2,
  CLOCK: BsClock,
  PERSON_PLUS: FiUserPlus,
  LOCK: FaLock,
  LOCK_OUTLINE: FiLock,
  EYE_OFF: AiOutlineEyeInvisible,
  EYE: AiOutlineEye,
  CALENDAR: AiOutlineCalendar,
  PICKUP: BsCarFront,
  DROP_OFF: MdVerified,
  STOP: BiRadioCircleMarked,
  LOGOUT: FiLogOut,
  FILES: AiFillFileText,
  UPLOAD: BsCloudUploadFill,

  LOGS: FiList
  //Dashboard
};

export default APP_ICONS;
