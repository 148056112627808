import { Box, Flex, Text, useColorMode } from '@chakra-ui/react'
import React from 'react'
import StarRating from '../../../components/BasicUI/DataBoxes/StarRating'
import { colorKeys, getColor } from '../../../config/constants/appColors'

const RatingBox = ({ item, count = 5 }) => {
    const { colorMode } = useColorMode()
    return (
        <Flex align="center" justify="space-between" w="full">
            <Box ml={2}>
                <StarRating
                    count={5}
                    value={count}
                    activeColor={getColor(colorKeys.spanishYellow, colorMode)}
                    inactiveColor="transparent"
                    forceRenderStars={true}
                />
            </Box>
            <Text fontSize="18px">{item || 0}</Text>
        </Flex>
    )
}

export default RatingBox