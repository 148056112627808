import { RIDES_DRIVER_STATUS } from "../constants/enums"
import { MAP_MARKERS } from "../constants/images"

export const getMapMarker = (status = 0) => {
    switch (status) {
        case RIDES_DRIVER_STATUS.ACCEPTED:
            return MAP_MARKERS.ACCEPTED

        case RIDES_DRIVER_STATUS.CANCELLED:
            return MAP_MARKERS.CANCELLED

        case RIDES_DRIVER_STATUS.DISPATCHING:
            return MAP_MARKERS.DISPATCHING;

        case RIDES_DRIVER_STATUS.AVAILABLE:
            return MAP_MARKERS.AVAILABLE

        case RIDES_DRIVER_STATUS.DRIVER_ON_THE_WAY:
            return MAP_MARKERS.ON_THE_WAY

        case RIDES_DRIVER_STATUS.ARRIVED_AND_WAITING:
            return MAP_MARKERS.ARRIVED_AND_WAITING

        case RIDES_DRIVER_STATUS.POB:
            return MAP_MARKERS.POB

        case RIDES_DRIVER_STATUS.DROPPED_OFF:
            return MAP_MARKERS.DROPPED_OFF

        case RIDES_DRIVER_STATUS.COMPLETED:
            return MAP_MARKERS.COMPLETED

        case RIDES_DRIVER_STATUS.PARKING_LOT:
            return MAP_MARKERS.PARKING_LOT

        case RIDES_DRIVER_STATUS.UNAVAILABLE:
            return MAP_MARKERS.UNAVAILABLE

        default:
            return MAP_MARKERS.UNAVAILABLE
    }
}

export const isPointInsideMainPolygon = (point, mainPolygon1) => {
    try {
        //test it with google map lib
        // const mainPolygon = new window.google.maps.Polygon({ paths: mainPolygon1 });
        // const test = window.google.maps.geometry.poly.containsLocation(point, mainPolygon);
        // // return test

        //test it with our own algo
        var isInside = false;
        var path = mainPolygon1;
        var pathLength = path.length;

        for (var i = 0, j = pathLength - 1; i < pathLength; j = i++) {
            var xi = path[i].lat;
            var yi = path[i].lng;
            var xj = path[j].lat;
            var yj = path[j].lng;
            var intersect = ((yi > point.lng()) !== (yj > point.lng())) &&
                (point.lat() < (xj - xi) * (point.lng() - yi) / (yj - yi) + xi);
            if (intersect) isInside = !isInside;
        }
        return isInside;
    } catch (error) {
        console.warn(error)
        return false
    }
}

export const identifyParentZoneOfPoint = (point, zones) => {
    let parentZone = null
    zones.forEach(zone => {
        if (isPointInsideMainPolygon(point, zone)) {
            parentZone = zone
        }
    })
    return parentZone
}