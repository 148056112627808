import { Box, Flex, Icon, IconButton, Image, useColorMode, chakra } from '@chakra-ui/react'
import React from 'react'
import DeletePopover from '../BasicUI/Popovers/DeletePopover'
import APP_ICONS from '../../config/constants/icons'
import { colorKeys, getColor } from '../../config/constants/appColors'

const CardBox = ({ data, onDelete }) => {
    const { colorMode } = useColorMode()
    return (
        <Box className="debit-card" bg={getColor(colorKeys.lightBackgroundFill, colorMode)}>
            {data?.isPrimary && (
                <Box className='primary-badge'>PRIMARY</Box>
            )}
            <Flex align="center" justify="space-between">
                <Image src={data?.logoUrl} className="master-card-logo" />
                {!data?.isPrimary && (
                    <DeletePopover onConfirm={onDelete} type='card'>
                        <IconButton size="sm" colorScheme='red' icon={<Icon as={APP_ICONS.BIN} />} />
                    </DeletePopover>
                )}
            </Flex>
            <Box className="card-number-block">
                {new Array(3).fill(null).map((item, index) =>
                    <chakra.input
                        fontSize="20px"
                        bg="transparent"
                        color={getColor(colorKeys.primaryText, colorMode)}
                        key={index}
                        type="text"
                        readOnly={true}
                        value={"****"}
                        className="number-block"
                    />
                )}
                <chakra.input
                    fontSize="20px"
                    bg="transparent"
                    color={getColor(colorKeys.primaryText, colorMode)}
                    type="text"
                    readOnly={true}
                    value={data?.lastFourDigits}
                    className="number-block"
                />
            </Box>
        </Box>
    )
}

export default CardBox