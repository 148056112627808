import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, } from "@chakra-ui/react";
import { useBookingConfigurations, useUpdateBookingConfiguration } from "../../../../config/query/bookingsQuery";
import ConfigCard from "./ConfigCard";

const Configuration = () => {

  const [configs, setConfigs] = useState()

  const configQuery = useBookingConfigurations()
  const updateConfigQuery = useUpdateBookingConfiguration()

  const handleChange = (e) => {
    setConfigs((prev) => ({ ...prev, [e.target.name]: Number(e.target.value) }))
  }

  useEffect(() => {
    configQuery
      .mutateAsync()
      .then((res) => {
        setConfigs(res)
      })
      .catch(err => console.warn(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSave = async () => {
    updateConfigQuery
      .mutateAsync(configs)
      .then(res => console.info(res))
      .catch(err => console.warn(err))
  }

  return (
    <Box>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} mt={6}>

        <ConfigCard
          info={"The time in seconds a ride request will be available to the driver then disappear."}
          title={"Driver Acceptance Time Limit"}
          id="driverAcceptanceTimeLimit"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isDriverAcceptanceTimeLimitEditable}
        />
        <ConfigCard
          info={"The radius in kilometers to search for nearby drivers for a ride request."}
          title={"Driver Search Radius"}
          id="driverSearchRadius"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isDriverSearchRadiusEditable}
        />
        <ConfigCard
          info={"The time in minutes the driver must wait after reaching the pickup point before the ‘No Show’ button is activated"}
          title={"Driver Waiting Time Limit"}
          id="driverWaitingTimeLimit"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isDriverWaitingTimeLimitEditable}
        />
        <ConfigCard
          info={"The time in mins after which a reminder is sent to the driver for scheduled rides."}
          title={"First Schedule Ride Notification Interval"}
          id="firstScheduleRideNotificationInterval"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isFirstScheduleRideNotificationIntervalEditable}
        />
        <ConfigCard
          info={"The time in mins after which a reminder is sent to the driver for scheduled rides."}
          title={"Second Schedule Ride Notification Interval"}
          id="secondScheduleRideNotificationInterval"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isSecondScheduleRideNotificationIntervalEditable}
        />
        <ConfigCard
          info={"The time in mins after which a reminder is sent to the driver for scheduled rides."}
          title={"Third Schedule Ride Notification Interval"}
          id="thirdScheduleRideNotificationInterval"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isThirdScheduleRideNotificationIntervalEditable}
        />
        <ConfigCard
          info={"Pre Booking Time Buffer"}
          title={"Pre Booking Time Buffer"}
          id="preBookingTimeBuffer"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isPreBookingTimeBufferEditable}
        />
        <ConfigCard
          info={"Post Booking Time Buffer"}
          title={"Post Booking Time Buffer"}
          id="postBookingTimeBuffer"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isPostBookingTimeBufferEditable}
        />
        <ConfigCard
          info={"Customer Pre Booking Before Buffer"}
          title={"Customer Pre Booking Before Buffer"}
          id="customerPreBookingBeforeBuffer"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isCustomerPreBookingBeforeBufferEditable}
        />
        <ConfigCard
          info={"Customer Pre Booking After Buffer"}
          title={"Customer Pre Booking After Buffer"}
          id="customerPreBookingAfterBuffer"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={configs?.isCustomerPreBookingAfterBufferEditable}
        />

      </SimpleGrid>
    </Box>
  );
};

export default Configuration;
