import { Card, chakra, Flex, Box, Text, useColorMode, Image } from '@chakra-ui/react'
import React from 'react'
import { getColor, colorKeys } from "../../../config/constants/appColors"
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import FormInput from "../../../components/Forms/FormInput"
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useForgotPassword } from '../../../config/query/authQuery'
import IMAGES from '../../../config/constants/images'
import COLOR_MODES from '../../../config/constants/colorModes'
import WhiteLogo from '../../../components/SVGComponents/WhiteLogo'
import { EMAIL_REGEX } from '../../../config/constants/regexConstants'
import { addTempEmail } from '../../../config/redux/slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'

const ForgotPassword = () => {
    const tenant = useSelector((state) => state.tenant?.tenant)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()

    const forgotPasswordQuery = useForgotPassword()

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm()

    function onSubmit(values) {
        forgotPasswordQuery.mutateAsync(values)
            .then((response) => {
                dispatch(addTempEmail(response.data))
                navigate(`/auth/verify`)
            })
            .catch((error) => {
                console.warn(error, "error")
            })
    }

    return (
        <Flex height={"100vh"} justify="center" align="center" bg={getColor(colorKeys.white, colorMode)} background={`url(${tenant?.backgroundImageUrl})`} backgroundRepeat={"no-repeat"} backgroundSize={"cover"} backgroundPosition={"center"}>
            <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
                <Card w="full" maxW="480px">
                    <Box w="full" maxW="480px">

                        <Flex px="5" justify={"space-between"} bg={getColor(colorKeys.dimmerBlue, colorMode)}>
                            <Box>
                                <Text fontSize="16px" color={getColor(colorKeys.white, colorMode)} mt={5}>
                                    Forgot Password
                                </Text>
                                <Text fontSize="13px" color={getColor(colorKeys.white, colorMode)} >
                                    Enter your email to reset password
                                </Text>
                            </Box>

                            <Box background={`url(${IMAGES.AUTH_MIN})`} w="173px" h="112px" />
                        </Flex>

                        <Box
                            pos="absolute"
                            top="75px"
                            left="25px"
                            w="90px"
                            m="auto"
                            bg={getColor(colorKeys.white, colorMode)}
                            rounded="full"
                        >
                            {colorMode === COLOR_MODES.LIGHT ?
                                <Image w="full" h="full" src={tenant?.logoUrl} alt="logo" />
                                : <WhiteLogo containerStyles={{ margin: "auto" }} />}
                        </Box>

                        <Box p="10">

                            <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">

                                <FormInput
                                    id={"email"}
                                    label={"Email"}
                                    placeholder="Enter email"
                                    required={true}
                                    errors={errors}
                                    control={control}
                                    inputProps={{ fontSize: "15px" }}
                                    rules={{
                                        required: "Email is required",
                                        pattern: {
                                            value: EMAIL_REGEX,
                                            message: "Invalid email address",
                                        },
                                    }}
                                />

                                <PrimaryButton
                                    isLoading={forgotPasswordQuery.isLoading}
                                    mt={5}
                                    type="submit"
                                    p="18px 33px"
                                    bg={getColor(colorKeys.spanishYellow, colorMode)}
                                    w="full"
                                    rounded="md"
                                    fontWeight={"bold"}
                                    color="white"
                                >
                                    Send
                                </PrimaryButton>


                            </chakra.form>

                        </Box>

                    </Box>


                </Card>

            </Flex>
        </Flex>
    )
}

export default ForgotPassword