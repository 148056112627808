import { Link, useRouteError } from "react-router-dom";
import { Box, Flex, HStack, Heading, Image, Text } from "@chakra-ui/react"
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import IMAGES from "../../constants/images";

const ErrorPage = () => {
    const error = useRouteError();
    console.warn("Unhandled Error Occurred! ", error);
    return (
        <Flex id="error-page" h="100vh" justify="center" align="center">
            <Box>
                <Image src={IMAGES.ERROR} />
            </Box>
            <Box>
                <Heading>Oops!</Heading>
                <Text>Sorry, an unexpected error has occurred.</Text>
                <Text>{error.statusText || error.message}</Text>
                <HStack spacing={5} mt={5}>
                    <PrimaryButton >
                        <Link to="/admin">
                            Back To Homepage
                        </Link>
                    </PrimaryButton>

                    <PrimaryButton onClick={() => window.location.reload()}>
                        Reload Page
                    </PrimaryButton>
                </HStack>
            </Box>
        </Flex>
    );
}

export default ErrorPage