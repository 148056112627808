import React, { useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Icon,
  Flex,
  Heading,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import CompanyBreakdown from "./CompanyBreakdown";
import CustomerBreakdown from "./CustomerBreakdown";
import DriverBreakdown from "./DriverBreakdown";
import { useParams } from "react-router";
import CustomTabs from "../../../../../components/BasicUI/CustomTabs";
import { useCostBreakdown } from "../../../../../config/query/bookingsQuery";
import APP_ICONS from "../../../../../config/constants/icons";

const CostBreakdown = ({ disclosure }) => {
  const { bookingId } = useParams();
  const { isFetching, refetch, data } = useCostBreakdown(bookingId);

  useEffect(() => {
    if (disclosure?.isOpen) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disclosure?.isOpen]);

  return (
    <Modal isOpen={disclosure?.isOpen} onClose={disclosure?.onClose} isLazy>
      <ModalOverlay />
      <ModalContent maxW={"550px"}>
        <Flex justify={"space-between"} p="10px 20px">
          <Heading fontSize="20px" p="2">
            Cost Breakdown
          </Heading>
          <HStack>
            <Button
              isLoading={isFetching}
              size="sm"
              onClick={refetch}
              leftIcon={<Icon as={APP_ICONS.REFRESH} />}
            >
              Refresh
            </Button>
            <IconButton
              size="sm"
              onClick={disclosure?.onClose}
              icon={<Icon as={APP_ICONS.CLOSE} />}
            />
          </HStack>
        </Flex>
        <ModalBody>
          <CustomTabs
            containerProps={{ isFitted: true }}
            tabPanelProps={{ p: 0 }}
            tabs={[
              {
                head: "Company",
                body: <CompanyBreakdown data={data?.companyCostBreakdown} />,
              },
              {
                head: "Customer",
                body: <CustomerBreakdown data={data?.customerCostBreakdown} />,
              },
              {
                head: "Driver",
                body: <DriverBreakdown data={data?.driverCostBreakdown} />,
              },
              // {
              //   head: "Corporate",
              //   body: (
              //     <CorporateBreakdown data={data?.corporateCostBreakdown} />
              //   ),
              // },
            ]}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" mr={3} onClick={disclosure?.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CostBreakdown;
