import moment from "moment/moment";
import momentTz from "moment-timezone";
import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATETIME_FORMAT_WITH_SECONDS, DEFAULT_DATE_FORMAT } from "../constants";
import { View_MODES } from "../constants/enums";
import { TIME_ZONES } from "../constants/date";

export const formatDate = (date) => {
  return moment(date).utc().local().format(DEFAULT_DATE_FORMAT);
};

export const formatDateTimeWithoutConvertingToUtc = (date) => {
  // Check if date is a valid Moment object
  if (moment.isMoment(date)) {
    // Use the local format string for the server's locale
    return date.format('YYYY-MM-DD HH:mm:ss');  // Adjust format as needed
  } else {
    // Try parsing the date string if not a Moment object
    const parsedDate = moment(date);
    if (parsedDate.isValid()) {
      // Use local format after parsing
      return parsedDate.format('YYYY-MM-DD HH:mm:ss');  // Adjust format as needed
    } else {
      return ''
    }
  }
};

export const formatTime = (time, embedDate) => {
  if (embedDate) {
    const embedded = moment().format("YYYY-MM-DD") + " " + time;
    return moment(embedded).utc().local().format("hh:mm A");
  }
  return moment(time).utc().local().format("hh:mm A");
};

export const formatDateTime = (dateTime, format = DEFAULT_DATETIME_FORMAT) => {
  if (dateTime)
    return moment(dateTime).utc().local().format(format);
  else return "N/A";
};

export const formatDateTimeWithSeconds = (dateTime) => {
  if (dateTime)
    return moment(dateTime).utc().local().format(DEFAULT_DATETIME_FORMAT_WITH_SECONDS);
  else return "N/A";
};

export const formatDateTimeFromNow = (dateTime, removeAgo, justNowText = "Just Now") => {
  const now = moment(dateTime).utc().local().fromNow(removeAgo);
  if (now === "a few seconds ago") return justNowText
  return now
};

export const formatDateTimeForMessage = (dateTime) => {
  if (!dateTime) return moment().utc().toISOString();
  return moment(dateTime).utc().toISOString();
};

export const formatMessageTime = (dateTime) => {
  //if date is today, show time only
  if (moment(dateTime).utc().local().isSame(moment().utc().local(), "day")) {
    return moment(dateTime).utc().local().format("hh:mm A");
  }
  //if date is yesterday, show Yesterday at time
  else if (
    moment(dateTime)
      .utc()
      .local()
      .isSame(moment().utc().local().subtract(1, "days"), "day")
  )
    return "Yesterday at " + moment(dateTime).utc().local().format("hh:mm A");
  //just show date and time
  else return moment(dateTime).utc().local().format("DD MMM yyyy hh:mm A");
};

export const formatDateForInput = (date, convertToLocal = true) => {
  if (convertToLocal) {
    return moment(date).utc().local().format("YYYY-MM-DD");
  }
  return moment(date).format("YYYY-MM-DD");
};

export const formatTimeForInput = (time) => {
  return moment(time).utc().local().format("HH:mm:ss.SSS");
};

export const formatTimeInputDate = (time) => {
  const myNewValue = moment().format("YYYY-MM-DD") + " " + time;
  const formatTime = moment(myNewValue).utc().local().format("HH:mm");
  return formatTime;
};

export const formatDateTimeForInput = (datetime) => {
  return moment(datetime).format("YYYY-MM-DD HH:mm")
}

export const formatDateForSever = (date, converToUtc = true) => {
  if (converToUtc) {
    return moment(date).utc().format("YYYY-MM-DD");
  }
  return moment(date).format("YYYY-MM-DD");
};

export const formatTimeForServer = (time, embedDate) => {
  if (embedDate) {
    const embedded = moment().format("YYYY-MM-DD") + " " + time;
    const formattedTime = moment(embedded).utc().format("HH:mm a")
    return formattedTime
  }
  return moment(time).utc().format("HH:mm:ss.SSS");
};

export const formatDateTimeForServer = (dateTime, format = "YYYY-MM-DD HH:mm:ss.SSS") => {
  return moment(dateTime).utc().format(format);
};

export const truncateDate = (dateTime) => {
  return moment(dateTime).format("HH:mm");
}

export const truncateTime = (dateTime) => {
  return moment(dateTime).format("YYYY-MM-DD");
}

export const formatDayName = (dayName) => {
  return moment().day(dayName).format("ddd");
}

export const formatTimeByViewMode = ({ viewMode, time }) => {
  switch (Number(viewMode)) {
    case View_MODES.Hourly:
      return moment(time).format("hh:mm A")
    case View_MODES.Daily:
      return moment(time).format("DD/MMM/YYYY")
    case View_MODES.Weekly:
      return moment(time).format("DD/MMM/YYYY")
    case View_MODES.Monthly:
      return moment(time).format("MMM/YYYY")
    default:
      return moment(time).format("hh:mm A")
  }
}

export const formatDateTimeToZone = (dateTime, zone = 0, format = DEFAULT_DATETIME_FORMAT) => {
  if (!dateTime) return "N/A"
  const zoneTime = momentTz(dateTime).utcOffset(zone / 60).format(format)
  if (zoneTime === "Invalid date") return "N/A"
  return `${zoneTime} ${TIME_ZONES[zone / 60]})`
}


export const formatDateTimeToBookingZone = (dateTime, zone = 0, format = "YYYY-MM-DD HH:mm") => {
  if (!dateTime) {
    dateTime = formatDateTime(new Date());
  }
  const zoneTime = momentTz(dateTime).utcOffset(zone / 60).format(format);
  if (zoneTime === "Invalid date") return dateTime
  return zoneTime
}

export const guessTimeZone = () => {
  const timeZoneName = momentTz.tz.guess()
  const timeZoneOffset = momentTz.tz(timeZoneName).utcOffset() / 60
  return timeZoneOffset
}

export const humanizeNoOfMinutes = (minutes) => {
  return moment.duration(minutes, "minutes").humanize()
}