export const SOCKET_SERVER = {
    JoinChatRoom: 'JoinChatRoom',
    LeaveChatRoom: 'LeaveChatRoom',
    ChatMessage: 'ChatMessage',
    LiveLocationUpdate: "LiveLocationUpdate",
    JoinAdminMapRoom: "JoinAdminMapRoom",
    InboxMessage: "InboxMessage",
    JoinAdminInboxRoom: "JoinAdminInboxRoom",
    LiveRideUpdate: "LiveRideUpdate",
    ChatUpdate: "ChatUpdate"
};