import React from "react";
import { useController } from "react-hook-form";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  chakra,
  useColorMode,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { accessValue } from "../../../config/helpers/stringHelper";
import dayjs from "dayjs";
import { DatePicker, ConfigProvider } from "antd";
const FormDatePicker = ({
  id,
  control,
  placeholder,
  label,
  required,
  minDate,
  maxDate,
  size = "sm",
  rules,
  feedbackMessage,
  hideLabel,
  isDisabled,
  inputProps,
  groupProps,
  containerProps,
  labelContainerProps,
  labelProps,
  messageContainerProps,
}) => {
  const { colorMode } = useColorMode();

  if (required) {
    required = `${label} is required`;
  }

  const {
    field,
    formState: { errors },
  } = useController({
    name: id,
    control,
    rules: {
      required: required,
      ...rules,
    },
  });

  const onChange = (date, dateString) => {
    field.onChange(dateString);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: getColor(colorKeys.secondaryText, colorMode)
        },
      }}
    >
      <FormControl
        isInvalid={errors[id] || accessValue(errors, `${id}.message`)}
        _focusWithin={{
          zIndex: 9999,
          transition: "all 0.3s ease"
        }}
        {...containerProps}
      >
        {!hideLabel && (
          <FormLabel htmlFor={id} fontSize={"13px"} {...labelProps}>
            <Flex {...labelContainerProps}>
              {label}
              {required && (
                <chakra.span color={getColor(colorKeys.danger, colorMode)}>
                  *
                </chakra.span>
              )}
            </Flex>
          </FormLabel>
        )}
        <InputGroup className="date-picker-gc" size={size} {...groupProps}>
          <DatePicker
            minDate={dayjs(minDate)}
            maxDate={dayjs(maxDate)}
            // placement="topRight"
            id={id}
            value={field.value ? dayjs(field.value) : null}
            onChange={onChange}
            placeholder={placeholder}
            disabled={isDisabled}
            style={{ ...inputProps }}
            popupClassName="popup-date-picker"
            //  panelRender={({children}) => <div style={{background:"red", height:"150px", width:"150px", position:"relative", zIndex:9999999999}}>{children}</div>}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        </InputGroup>
        <FormErrorMessage {...messageContainerProps}>
          {errors[id]?.message}
        </FormErrorMessage>
        <FormHelperText {...messageContainerProps}>
          {feedbackMessage}
        </FormHelperText>
      </FormControl>
    </ConfigProvider>
  );
};

export default FormDatePicker;


