import { AbsoluteCenter, Box, Divider, Text, useColorMode } from "@chakra-ui/react"
import React from "react"
import { colorKeys, getColor } from "../../../config/constants/appColors"

const InfoMessage = ({ message }) => {
    const { colorMode } = useColorMode()
    return (
        <Box position='relative' py='5' px="2">
            <Divider />
            <AbsoluteCenter px='4' bg={getColor(colorKeys.white, colorMode)} minW="180px">
                <Text textAlign={"center"} fontSize={"13px"} color={getColor(colorKeys.secondaryText, colorMode)}>
                    {message?.text}
                </Text>
            </AbsoluteCenter>
        </Box>
    )
}

export default InfoMessage