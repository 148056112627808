import React from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Text,
  VStack,
  InputLeftAddon,
} from "@chakra-ui/react";
import FormInput from "../../../../components/Forms/FormInput";
import LocationSearchSelect from "../../../../components/Maps/LocationSearchSelect";
import APP_ICONS from "../../../../config/constants/icons";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import FormSwitch from "../../../../components/Forms/FormSwitch";
import { useBookingInfo } from "./bookingFormContainer";

const BookingInfo = ({ control, errors, watch, setValue, infoChanged, enabledFields, isEdit }) => {

  const {
    addStop,
    removeStop,
    renderVehicleList,
    seasons,
    colorMode,
    stops,
    rideType,
  } = useBookingInfo({
    enabledFields,
    infoChanged,
    setValue,
    watch
  })
  
  const stop = watch('stops');

  return (
    <Card>
      <CardHeader
        p="15px 18px 0px"
        bg={getColor(colorKeys.tableBackground, colorMode)}
      >
        <Heading size="md">Booking Info</Heading>
      </CardHeader>
      <CardBody bg={getColor(colorKeys.tableBackground, colorMode)} py="5">
        <VStack spacing={5} align="stretch">
          <Flex>
            <Text fontSize="14px" pt="3px">
              Now
            </Text>
            <FormSwitch
              control={control}
              errors={errors}
              id="rideType"
              containerProps={{
                mx: 3,
                w: "fit-content",
              }}
              switchProps={{ isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.rideType }}
            />
            <Text fontSize="14px" pt="4px">
              Scheduled
            </Text>
          </Flex>

          {rideType && (
            <FormInput
              id="rideTime"
              label={"Starting Date & Time"}
              control={control}
              errors={errors}
              required={rideType}
              type={"datetime-local"}
              inputProps={{
                size: "sm",
                // min: isEdit ? new Date(moment(tempRideTime).utc(true).local()).toISOString().slice(0, 16) : new Date().toISOString().slice(0, 16),
                isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.rideTime
              }}
            />
          )}

          <LocationSearchSelect
            control={control}
            errors={errors}
            id={"pickup"}
            label={"Pickup Location"}
            placeholder={"Enter pickup"}
            required={true}
            inputProps={{ size: "sm", isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.bookingStops }}
            size="sm"
            leftAddon={
              <InputLeftAddon placeContent={"center"} w="10%" children="1" />
            }
          />

          {stops?.map((item, index) => {
            return (
              <HStack spacing={2} align="end" key={index}>
                <LocationSearchSelect
                  key={item.tempId}
                  control={control}
                  errors={errors}
                  id={`stops[${index}]`}
                  additionalObj={{ tempId: item.tempId }}
                  label={"Stop Location"}
                  placeholder={"Enter stop location"}
                  required={true}
                  inputProps={{ size: "sm", isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.bookingStops }}
                  size="sm"
                  leftAddon={
                    <InputLeftAddon
                      placeContent={"center"}
                      w="10%"
                      children={String(index + 2)}
                    />
                  }
                />
                <IconButton
                  onClick={() => removeStop(item.tempId, item)}
                  size="sm"
                  icon={<Icon as={APP_ICONS.CLOSE} />}
                />
              </HStack>
            );
          })}

          <Button
            size="sm"
            zIndex={0}
            onClick={addStop}
            isDisabled={(stops?.length >= 3 || enabledFields?.everyField ? false : !enabledFields?.fields?.bookingStops) || stop[stop.length - 1]?.latitute===null ? true : false}
            leftIcon={<Icon as={APP_ICONS.ADD} boxSize={"5"} />}
            id="add-stop"
          >
            Add More Stops
          </Button>

          <LocationSearchSelect
            control={control}
            errors={errors}
            id={"destination"}
            label={"Destination"}
            placeholder={"Enter destination"}
            required={true}
            inputProps={{ size: "sm", isDisabled: enabledFields?.everyField ? false : !enabledFields?.fields?.bookingStops }}
            size="sm"
            leftAddon={
              <InputLeftAddon
                placeContent={"center"}
                w="10%"
                children={stops?.length + 2}
              />
            }
          />

          <Box
            maxW="full"
            overflowY={enabledFields?.everyField ? "scroll" : !enabledFields?.fields?.vehicleTypeId ? "unset" : "scroll"}
            h="350px"
            display={seasons.length > 0 ? "" : "none"}
          >
            <FormInput
              id="vehicleTypeId"
              label={"Service Type"}
              control={control}
              errors={errors}
              required={true}
              display="none"
              inputProps={{ size: "sm", display: "none" }}
            />
            {renderVehicleList(seasons)}
          </Box>
        </VStack> 
      </CardBody>
    </Card>
  );
};

export default BookingInfo;
