import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import TableHeaderOptions from '../../../components/BasicUI/CustomTable/TableHeaderOptions';
import CustomTable from '../../../components/BasicUI/CustomTable';
import { APP_MODULES, DEFAULT_DATE_TIME_WITH_ZONE } from '../../../config/constants';
import { SignalRContext, useSignalREffectAlt } from '../../../config/sockets/SignalRContext';
import { SOCKET_SERVER } from '../../../config/constants/chat';
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge';
import BreakText from '../../../components/BasicUI/DataBoxes/BreakText';
import { ACTIVE_RIDE_STATUS, BOOKINGS_STATUSES, RIDE_STATUS, RIDE_TYPE } from '../../../config/constants/enums';
import { useForceUpdate } from '../../../config/hooks/useForceUpdate';
import { useRides } from '../../../config/query/mapQuery';
import { getFilters } from '../../../config/helpers/filterHelper';
import { formatAmount } from '../../../config/helpers/stringHelper';
import { formatDateTimeToZone } from '../../../config/helpers/dateHelper';
import { Link } from 'react-router-dom';

const ActiveBookings = () => {
    const hubConnection = useContext(SignalRContext)

    const ridesQuery = useRides()
    const forceUpdate = useForceUpdate()
    const ridesHashMap = useRef({})
    const ridesArray = useRef([])

    const [query, setQuery] = useState({
        statuses: []
    })

    const onQueryChange = (updatedQuery) => setQuery((prevQuery) => ({ ...prevQuery, ...updatedQuery }))

    const getRides = () => {
        ridesQuery
            .mutateAsync()
            .then((res) => {
                if (res?.data) {
                    res.data.forEach((ride) => {
                        if (ride.rideId) {
                            ridesHashMap.current[ride.rideId] = ride
                        }
                    })
                    ridesArray.current = Object.values(ridesHashMap.current)
                    if (hubConnection?.state === "Connected")
                        hubConnection.invoke(SOCKET_SERVER.JoinAdminMapRoom)
                    forceUpdate()
                }
            })
            .catch((err) => console.warn(err))
    }

    const getCalculatedData = () => {
        let filteredData = ridesArray.current
        if (query?.VehicleTypeId) {
            filteredData = filteredData.filter((item) => item.vehicleTypeId === query.VehicleTypeId)
        }
        if (query?.RideStatus) {
            filteredData = filteredData.filter((item) => item.status?.id === Number(query.RideStatus))
        }
        if (query?.statuses?.length) {
            filteredData = filteredData.filter((item) => query?.statuses?.includes(item?.status?.id))
        }
        if (query?.Keyword) {
            filteredData = filteredData.filter((item) => item?.rideId?.toString().includes(query.Keyword)
                || item?.passengerName?.toLowerCase().includes(query.Keyword.toLowerCase())
                || item?.passengerPhone?.toLowerCase().includes(query.Keyword.toLowerCase())
                || item?.driverName?.toLowerCase().includes(query.Keyword.toLowerCase())
                || item?.driverPhone?.toLowerCase().includes(query.Keyword.toLowerCase())
                || item?.pickupAddress?.toLowerCase().includes(query.Keyword.toLowerCase())
                || item?.dropoffAddress?.toLowerCase().includes(query.Keyword.toLowerCase())
                || item?.stops?.at(0)?.shortAddress?.toLowerCase().includes(query.Keyword.toLowerCase())
                || item?.stops?.at(item?.stops?.length - 1)?.shortAddress?.toLowerCase().includes(query.Keyword.toLowerCase())
                || item?.status?.name?.toLowerCase().includes(query.Keyword.toLowerCase()?.replace(" ", ""))
            )
        }
        return filteredData
    }


    const onRideUpdate = (data) => {
        if (data && data?.rideId) {
            if (data?.bookingStatus?.id === BOOKINGS_STATUSES.Finished) {
                delete ridesHashMap.current[data?.rideId]
            }
            else {
                ridesHashMap.current[data?.rideId] = { ...data }
            }
        }
        ridesArray.current = Object.values(ridesHashMap.current)
    }

    useSignalREffectAlt(SOCKET_SERVER.LiveRideUpdate, onRideUpdate);

    useEffect(() => {
        //rerender component every 10 seconds
        const interval = setInterval(() => {
            forceUpdate()
        }, 5000)
        return () => clearInterval(interval)
    })

    useEffect(() => {
        getRides()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hubConnection?.state === "Connected") {
            getRides()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubConnection?.state])

    const filters = {
        RideStatus: Object.keys(ACTIVE_RIDE_STATUS).map((item) => ({
            key: item,
            value: RIDE_STATUS[item],
        })),
    }

    return (
        <Box>
            <TableHeaderOptions title={"Active Bookings"} />
            <CustomTable
                tableFor="booking"
                searchPlaceholder="Search bookings"
                searchKey='Keyword'
                permissionKey={APP_MODULES.Booking}
                fixedHeight={false}
                filters={getFilters(filters)}
                head={[
                    {
                        title: "Booking #",
                        extractor: "rideId",
                        align: "left",
                        component: (item) => <Text fontSize="14px" as={Link} to={`/admin/active-bookings/${item?.rideId}`}>{item?.rideId}</Text>
                    },
                    {
                        title: "Fare",
                        extractor: "totalChargeOnCustomer",
                        hint: "Incl. all charges on customer",
                        align: "center",
                        component: (item) => <Text fontSize="14px">{formatAmount(item?.totalChargeOnCustomer)}</Text>
                    },
                    {
                        title: "Ride Type",
                        extractor: "rideTime",
                        align: "left",
                        component: (item) => <Box>
                            {(!item.rideType && !item.rideTime) && "N/A"}
                            <Text fontSize="14px">{item.rideType ? RIDE_TYPE[item.rideType?.id] : ""}</Text>
                            <Text fontSize="14px">{formatDateTimeToZone(
                                item.rideTime,
                                item.timeZoneOffSet,
                                DEFAULT_DATE_TIME_WITH_ZONE
                            )}</Text>
                        </Box>
                    },
                    {
                        title: "Pickup / Dropoff Time",
                        extractor: "pickup",
                        align: "left",
                        component: (item) => <Box>
                            {(!item.pickup && !item.dropoff) && "N/A"}
                            {item.pickup &&
                                <Text fontSize="14px">{formatDateTimeToZone(
                                    item.pickup,
                                    item.timeZoneOffSet,
                                    DEFAULT_DATE_TIME_WITH_ZONE
                                )}
                                </Text>
                            }
                            {item.dropoff &&
                                <Text fontSize="14px">{formatDateTimeToZone(
                                    item.dropoff,
                                    item.timeZoneOffSet,
                                    DEFAULT_DATE_TIME_WITH_ZONE
                                )}
                                </Text>
                            }
                        </Box>
                    },
                    { title: "Pickup Location", extractor: "pickupAddress", align: "left", component: (item) => <BreakText value={item.stops?.at(0)?.shortAddress} /> },
                    { title: "Destination", extractor: "dropoffAddress", align: "left", component: (item) => <BreakText value={item.stops?.at(item.stops?.length - 1)?.shortAddress} /> },
                    { title: "Flight Number", extractor: "flightNumber", align: "left" },
                    { title: "Gate Number", extractor: "gateNumber", align: "left" },
                    {
                        title: "Passenger", extractor: "passengerName", align: "left",
                        component: (item) => <VStack align="stretch" spacing={2}>
                            <Text fontSize="14px"> {(!item.passengerName && !item.passengerPhone) && "N/A"}</Text>
                            <Text fontSize="14px" _hover={{ textDecor: "underline" }} as={Link} to={`/admin/customers/?AdvancedSearch.Keyword=${item.passengerName}`}>{item.passengerName ? item.passengerName : ""}</Text>
                            <Text fontSize="14px">{item.passengerPhone ? item.passengerPhone : ""}</Text>
                        </VStack>
                    },
                    {
                        title: "Driver", extractor: "driverName", align: "left",
                        component: (item) => <VStack align="stretch" spacing={2}>
                            <Text fontSize="14px">{(!item.driverName && !item.driverPhone) && "N/A"}</Text>
                            <Text fontSize="14px" _hover={{ textDecor: "underline" }} as={Link} to={`/admin/drivers/?AdvancedSearch.Keyword=${item.driverName}`}>{item.driverName ? item.driverName : ""}</Text>
                            <Text fontSize="14px">{item.driverPhone ? item.driverPhone : ""}</Text>
                        </VStack>
                    },
                    {
                        title: "Dispatching To",
                        extractor: "dispatchingTo",
                        align: "left",
                        component: (item) => item?.dispatchingDriverInfo?.name ? <Text fontSize="14px" _hover={{ textDecor: "underline" }} as={Link} to={`/admin/drivers/?AdvancedSearch.Keyword=${item?.dispatchingDriverInfo?.name}`}>{item?.dispatchingDriverInfo?.name}</Text> : "-"
                    },
                    { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status?.id} bookingStatus={true} />, align: "center" },
                ]}
                theadRowProps={{
                    className: "active-bookings-table-header"
                }}
                colorRows={true}
                data={getCalculatedData()}
                isRefreshing={ridesQuery?.isLoading}
                onQueryChange={onQueryChange}
                query={query}
                onRefresh={getRides}
            />
        </Box>
    )
}

export default ActiveBookings