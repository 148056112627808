import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get } from "../api";
import API_CONSTANTS from "../constants/api";
import appendQueryParams from "../helpers/appendQueryParams";

export const useRidesDrivers = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["rides-drivers"],
        mutationFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.MAP.ridesDrivers,
                token,
                toastMessage: false
            })
            return data
        },
    })
}

export const useRides = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["rides"],
        mutationFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.MAP.rides,
                token,
                toastMessage: false
            })
            return data
        },
    })

}

export const useDriverCount = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["map-driver-count", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.MAP.driverCount}?${appendQueryParams(params)}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
    })
}