import { Box, Image, VStack, useColorMode } from "@chakra-ui/react";
import React from "react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import LabelValuePair from "../../../components/BasicUI/LabelValuePair";
import { getSentTo } from "../../../config/helpers/formHelpers/notificationHelper";
import { formatDateTime } from "../../../config/helpers/dateHelper";

const Information = ({ data }) => {
  const { colorMode } = useColorMode();
  return (
    <VStack spacing={2} align="stretch">
      {data?.imageUrl && (
        <Box
          w="full"
          h="250px"
          border={`1px solid ${getColor(
            colorKeys.tableFilterBorder,
            colorMode
          )}`}
        >
          <Image
            h="full"
            w="full"
            objectFit={"contain"}
            m="auto"
            src={data?.imageUrl}
          />
        </Box>
      )}
      <LabelValuePair label="Sent To" value={getSentTo(data)} />
      <LabelValuePair label="Note" value={data?.description} />
      <LabelValuePair
        label="Created At"
        value={formatDateTime(data?.createdOn)}
      />
    </VStack>
  );
};

export default Information;
