import { Box, Card, Flex, HStack, Heading, Icon, IconButton, Input, Text, Tooltip, useColorMode, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import APP_ICONS from '../../../../config/constants/icons'
import { colorKeys, getColor } from '../../../../config/constants/appColors'

const ConfigCard = ({ onChange, title, data, id, onSave, info, type, isEditable }) => {
    const { colorMode } = useColorMode()
    const disclosure = useDisclosure()

    const handleSave = () => {
        onSave().then(() => disclosure.onClose())
    }
    return (
        <Card p={"15px"} bg={getColor(colorKeys.lightGray, colorMode)}>
            <Flex
                align={"center"}
                justify={"space-between"}
                maxW="500px"
                mb={4}
            >
                <Heading fontSize={"14px"} style={{ lineBreak: "auto" }} textTransform={"capitalize"}>
                    {title}
                </Heading>
                <HStack spacing={2}>
                    {isEditable && <Tooltip
                        label={disclosure.isOpen ? "Close" : "Edit"}
                        aria-label="A tooltip"
                    >
                        <IconButton
                            ml={1}
                            size="sm"
                            icon={
                                <Icon
                                    boxSize={"0.8em"}
                                    as={disclosure.isOpen ? APP_ICONS.CLOSE : APP_ICONS.EDIT}
                                />
                            }
                            onClick={disclosure.onToggle}
                        />
                    </Tooltip>}
                    {disclosure.isOpen && (
                        <Tooltip label={"Save"} aria-label="A tooltip">
                            <IconButton
                                ml={1}
                                size="sm"
                                icon={<Icon as={APP_ICONS.DOUBLE_CHECK} />}
                                onClick={handleSave}
                            />
                        </Tooltip>
                    )}
                </HStack>
            </Flex>

            <Box>
                <Input
                    mb={3}
                    mt={1}
                    size="sm"
                    onChange={onChange}
                    value={data ? data[id] : ""}
                    name={id}
                    isDisabled={!disclosure?.isOpen}
                    type={type}
                />
                <Text fontSize={"12px"} color={getColor(colorKeys.gray, colorMode)}>{info}</Text>
            </Box>

        </Card>
    )
}

export default ConfigCard