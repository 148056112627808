export const prepareForm = ({ id, serverValues }) => ({
  name: "",
  status: "",
  coordinates: [],
  ...(id && {
    id,
    name: serverValues?.name,
    status: String(serverValues?.status?.id),
    coordinates: serverValues?.coordinates,
    connectedAccountId: serverValues?.connectedAccountId,
    isWalletEnabled: !!serverValues?.isWalletEnabled,
    isCashEnabled: !!serverValues?.isCashEnabled,
    // timeZoneOffSet: serverValues?.timeZoneOffSet,
  }),
});

export const preparePayload = (values) => ({
  ...values,
  coordinates: [...values.coordinates, values.coordinates[0]],
  isWalletEnabled: !!values.isWalletEnabled,
  isCashEnabled: !!values.isCashEnabled,
  status: Number(values.status),
  id: Number(values.id),
});
