import {
  Box,
  Button,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import FormFieldsSkeleton from "../../../../components/Skeletons/FormFieldsSkeleton";
import FormInput from "../../../../components/Forms/FormInput";
import FormPhoneInput from "../../../../components/Forms/FormPhoneInput";
import FormSearchSelect from "../../../../components/Forms/FormSearchSelect";
import { makeSelectList } from "../../../../config/helpers/selectListHelper";
import { DRIVER_STATUS, GENDERS } from "../../../../config/constants/enums";
import {
  useCities,
  useCountries,
  useStates,
} from "../../../../config/query/preferencesQuery";
import { EMAIL_REGEX } from "../../../../config/constants/regexConstants";
import moment from "moment/moment";
import { useShiftLookup } from "../../../../config/query/shiftQuery";
import ShiftForm from "../../Settings/Shift/ShiftForm";
import FormDatePicker from "../../../../components/Forms/FormDatePicker";

const ContactInformation = ({
  isLoading,
  errors,
  control,
  setValue,
  fields,
  query,
  setError,
  clearErrors,
  localNumber,
  countryCode,
}) => {
  const shiftFormDisclosure = useDisclosure();
  const countries = useCountries();
  const states = useStates(query?.stateQuery);
  const cities = useCities(query?.cityQuery);
  const shifts = useShiftLookup();
  return (
    <Box>
      <Text
        fontSize="15px"
        fontWeight={"bold"}
        my={3}
        w="full"
        textAlign={"center"}
        textTransform={"uppercase"}
      >
        Contact Info
      </Text>
      <FormFieldsSkeleton count={6} loading={isLoading} />
      {!isLoading && (
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
          <FormInput
            label={"First Name"}
            control={control}
            errors={errors}
            id="firstName"
            required={true}
            placeholder="Enter first name"
            inputProps={{ size: "sm" }}
          />

          <FormInput
            label={"Last Name"}
            control={control}
            errors={errors}
            id="lastName"
            required={true}
            placeholder="Enter last name"
            inputProps={{ size: "sm" }}
          />

          <FormPhoneInput
            id={"localNumber"}
            label="Phone Number"
            type="tel"
            placeholder="Enter your phone number"
            required={true}
            errors={errors}
            control={control}
            setCountryCodeValue={(value) => {
              setValue("countryCode", value);
            }}
            countryCodeValue={countryCode}
            phoneNumberValue={localNumber}
            setPhoneNumberValue={(value) => {
              setValue("localNumber", value);
            }}
            setError={setError}
            clearErrors={clearErrors}
            inputProps={{ size: "sm" }}
            size="sm"
          />

          <FormInput
            id={"email"}
            label="Email"
            placeholder="someone@example.com"
            required={true}
            errors={errors}
            control={control}
            rules={{
              pattern: {
                value: EMAIL_REGEX,
                message: "Invalid email address",
              },
            }}
            inputProps={{ size: "sm" }}
          />

          <FormInput
            label={"Home Address"}
            control={control}
            errors={errors}
            id="address"
            required={true}
            placeholder="Enter home address"
            inputProps={{ size: "sm" }}
          />

          <FormSearchSelect
            id="countryId"
            label="Country"
            placeholder={"Select Country"}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(countries.data?.data)}
            inputProps={{ size: "sm" }}
          />

          <FormSearchSelect
            id="stateId"
            label="State"
            placeholder={"Select State"}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(states.data?.data)}
            inputProps={{ size: "sm", isDisabled: !fields?.countryId }}
          />

          <FormSearchSelect
            id="cityId"
            label="City"
            placeholder={"Select City"}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(cities.data?.data)}
            inputProps={{ size: "sm", isDisabled: !fields?.stateId }}
          />

          <FormInput
            label={"Zip Code"}
            control={control}
            errors={errors}
            id="zipCode"
            required={true}
            placeholder="Enter zip code"
            inputProps={{ size: "sm" }}
          />

          <FormSearchSelect
            id="operatingCityId"
            label="City Driver Wants to Drive in"
            placeholder={"Select City"}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(cities.data?.data)}
            inputProps={{ size: "sm" }}
          />

          <FormDatePicker
            label={"Date of Birth"}
            control={control}
            errors={errors}
            id="dob"
            required={true}
            placeholder={"Select date of birth"}
            inputProps={{
              width: "inherit",
              borderColor: "inherit",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "inherit",
            }}
            maxDate={
              new Date(moment().subtract(18, "years").format("YYYY-MM-DD"))
            }
            minDate={
              new Date(moment().subtract(100, "years").format("YYYY-MM-DD"))
            }
            rules={{
              validate: {
                minAge: (value) => {
                  if (value && moment().diff(value, "years") < 18) {
                    return "Driver must be at least 18 years old";
                  }
                  return true;
                },
              },
            }}
          />

          <FormInput
            label={"Driver License Number"}
            control={control}
            errors={errors}
            id="licenseNo"
            required={true}
            placeholder="Enter driver license number"
            inputProps={{ size: "sm" }}
          />

          <FormDatePicker
            label={"Driver License Expiry"}
            control={control}
            errors={errors}
            id="licenseExpiry"
            required={true}
            placeholder={"Select license expiry"}
            maxDate={new Date(moment().add(100, "years").format("YYYY-MM-DD"))}
            minDate={new Date()}
            inputProps={{
              width: "inherit",
              borderColor: "inherit",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "inherit"
            }}
            rules={{
              validate: {
                minExpiryDate: (value) => {
                  if (value && moment().diff(value, "days") > 0) {
                    return "Driver license expiry date should be greater than today";
                  }
                  return true;
                },
              },
            }}
          />

          <FormSearchSelect
            id="gender"
            label="Gender"
            placeholder={"Select Gender"}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(GENDERS)}
            inputProps={{ size: "sm" }}
          />

          <FormSearchSelect
            id="status"
            label="Driver Status"
            placeholder={"Select Driver Status"}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(DRIVER_STATUS)}
            inputProps={{ size: "sm" }}
          />

          <FormSearchSelect
            id="shiftId"
            label="Shift"
            placeholder={"Select Shift"}
            required={false}
            errors={errors}
            control={control}
            options={makeSelectList(shifts.data?.data)}
            inputProps={{ size: "sm" }}
            labelExtention={
              <Button size="xs" onClick={shiftFormDisclosure.onOpen}>
                Add New
              </Button>
            }
          />

          <FormInput
            label={"Driver Share (%)"}
            type={"number"}
            control={control}
            errors={errors}
            id="driverShare"
            required={true}
            placeholder="Enter percentage"
            inputProps={{ size: "sm" }}
            rules={{
              validate: (value) => {
                if (value && value > 100) {
                  return "Admin share cannot be greater than 100";
                }
                if (value && value < 60) {
                  return "Admin share cannot be less than 60";
                }
              },
            }}
          />
        </SimpleGrid>
      )}

      <ShiftForm data={{}} disclosure={shiftFormDisclosure} />
    </Box>
  );
};

export default ContactInformation;
