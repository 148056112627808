import React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerOverlay,
    DrawerContent,
    VStack,
    Button,
} from '@chakra-ui/react'
import RideStatsSection from './RideStatsSection'
import MapBookings from './MapBookings'

const StatsDrawer = ({ query, onQueryChange, disclosure, selected, setSelected, data, rawData, filteredData }) => {

    const onSelectBooking = (item) => {
        setSelected(item)
        disclosure?.onClose()
    }

    return (
        <>
            <VStack spacing={5} align="stretch" h="calc(100vh - 125px)" overflowY="scroll" display={{ base: "none", lg: "flex" }}>
                <RideStatsSection
                    query={query}
                    onQueryChange={onQueryChange}
                    data={data}
                    rawData={rawData}
                    filteredData={filteredData}
                />

                <MapBookings
                    list={data}
                    selected={selected}
                    setSelected={setSelected}
                />
            </VStack>
            <Drawer
                isOpen={disclosure?.isOpen}
                placement='right'
                onClose={disclosure?.onClose}
                size="xl"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerBody p="10px 12px">
                        <VStack spacing={5} align="stretch" h="calc(100vh - 125px)" overflowY="scroll" display={{ base: "flex", lg: "flex" }}>
                            <RideStatsSection
                                query={query}
                                onQueryChange={onQueryChange}
                                data={data}
                                rawData={rawData}
                                filteredData={filteredData}
                            />

                            <MapBookings
                                list={data}
                                selected={selected}
                                setSelected={onSelectBooking}
                            />
                        </VStack>


                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={disclosure?.onClose}>
                            Cancel
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default StatsDrawer