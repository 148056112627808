import { ButtonGroup, IconButton } from '@chakra-ui/button';
import { useColorMode } from '@chakra-ui/color-mode';
import { Editable, EditableInput, EditablePreview, useEditableControls } from '@chakra-ui/editable';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Tooltip } from '@chakra-ui/tooltip';
import React from 'react'
import { Controller } from 'react-hook-form';
import { colorKeys, getColor } from '../../../config/constants/appColors';
import { Flex, Icon } from '@chakra-ui/react';
import APP_ICONS from '../../../config/constants/icons';

const FormEditableInput = ({ control, id, rules = {}, required, inputProps }) => {
    const { colorMode } = useColorMode()

    function EditableControls() {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
        } = useEditableControls();

        return isEditing ? (
            <ButtonGroup mb="1" justifyContent="end" size="sm" w="full" spacing={2} mt={2}>
                <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
                <IconButton
                    icon={<CloseIcon boxSize={3} />}
                    {...getCancelButtonProps()}
                />
            </ButtonGroup>
        ) : null;
    }

    const EditableIcon = () => {
        const {
            isEditing,
            getEditButtonProps,
        } = useEditableControls();

        return !isEditing ? (
            <Icon as={APP_ICONS.EDIT} {...getEditButtonProps()} />
        ) : null;
    }

    return (
        <Controller
            control={control}
            name={id}
            rules={{
                required: required,
                ...rules
            }}
            render={({ field: { onChange, onBlur, value, ref, ...rest } }) => (
                <Editable
                    defaultValue={value}
                    isPreviewFocusable={true}
                    selectAllOnFocus={false}
                    value={value}
                >
                    <Tooltip label="Click to edit" shouldWrapChildren={true}>
                        <Flex
                            _hover={{
                                background: getColor(colorKeys.lightGray, colorMode),
                                transition: "all 0.2s ease-in-out",
                                py: 1,
                                px: 1,
                            }}
                            align="center"
                        >
                            <EditablePreview
                                minW={"60px"}
                                minH="30px"
                                placeholder='Label'
                                bg={value === "" ? getColor(colorKeys.lightGray, colorMode) : "initial"}
                            />
                            <EditableIcon />
                        </Flex>
                    </Tooltip>
                    <Input
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        id={id}
                        as={EditableInput}
                        {...inputProps}
                        {...rest}
                    />
                    <EditableControls />
                </Editable>
            )}
        />
    )
}

export default FormEditableInput