import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Textarea,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import {
  CHAT_BOX_HEIGHT,
  CHAT_BOX_WIDTH,
  CHAT_DRAWER_HEIGHT,
} from "../../../config/constants/ui";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";
import MessageItem from "../ChatLayout/MessageItem";
import CircularLoading from "../../Skeletons/CircularLoading";
import CustomBottomDrawer from "../../BasicUI/DataBoxes/CustomBottomDrawer";
import FormFileUpload from "../../Forms/FormFileUpload";
import useChat from "../../../config/containers/chats/useChat";

const ConversationDrawer = ({
  getDisclosure,
  index,
  rightSpacing,
  chat,
  onLeaveChat,
}) => {
  const { colorMode } = useColorMode();
  const messageListRef = useRef();

  const {
    handleScroll,
    handleSendImage,
    handleSendMessage,
    setInputMessage,
    inputMessage,
    isFetchingNextPage,
    isLoading,
    messages,
  } = useChat({ messageListRef, chatId: chat?.chatId });

  return (
    <React.Fragment>
      <Button
        zIndex={getDisclosure(index).isOpen ? "unset" : 9}
        variant={"unstyled"}
        id="chat-box-abs-converstaion"
        h={CHAT_BOX_HEIGHT}
        w={CHAT_BOX_WIDTH}
        bg={getColor(colorKeys.primaryButtonFill, colorMode)}
        rounded="none"
        pos="fixed"
        bottom="0"
        right={`${rightSpacing}px`}
        onClick={getDisclosure(index).onOpen}
        color={getColor(colorKeys.alwaysWhite, colorMode)}
      >
        <Flex
          h={CHAT_BOX_HEIGHT}
          maxH={CHAT_BOX_HEIGHT}
          align={"center"}
          justify={"space-between"}
        >
          <Box ml="4" fontSize="13px">
            {chat?.chatTitle}
          </Box>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onLeaveChat(chat?.chatId, index);
            }}
            mr="2"
            size="sm"
            display="flex"
            align="center"
            variant="unstyled"
            icon={<Icon boxSize="4" as={APP_ICONS.CLOSE} />}
          />
        </Flex>
      </Button>
      <CustomBottomDrawer
        id={`bottom-chat-drawer-${index}`}
        bg={getColor(colorKeys.white, colorMode)}
        onClose={getDisclosure(index).onClose}
        isOpen={getDisclosure(index).isOpen}
        h={CHAT_DRAWER_HEIGHT}
        w={CHAT_BOX_WIDTH}
        right={`${rightSpacing}px`}
      >
        <Box roundedTop={"none"} bg={getColor(colorKeys.white, colorMode)}>
          <Box
            px="2"
            py="0"
            roundedTop={"none"}
            textAlign={"center"}
            minH={CHAT_BOX_HEIGHT}
            fontSize="md"
            bg={getColor(colorKeys.primaryButtonFill, colorMode)}
            color={getColor(colorKeys.alwaysWhite, colorMode)}
            borderBottomWidth="1px"
            onClick={getDisclosure(index).onClose}
            cursor={"pointer"}
          >
            <Flex
              h={CHAT_BOX_HEIGHT}
              py="1"
              align={"center"}
              justify={"space-between"}
            >
              <Box pl="2" fontSize="13px">
                {chat?.chatTitle}
              </Box>
              <HStack spacing={0}>
                <IconButton
                  size="sm"
                  icon={
                    <Icon mt="2px" as={APP_ICONS.DownChevron} boxSize="7" />
                  }
                  onClick={getDisclosure(index).onClose}
                  variant="unstyled"
                />
                <IconButton
                  size="sm"
                  icon={<Icon mt={"6px"} as={APP_ICONS.CLOSE} boxSize="4" />}
                  onClick={() => onLeaveChat(chat?.chatId, index)}
                  variant="unstyled"
                />
              </HStack>
            </Flex>
          </Box>
          <Box p="0">
            <Flex
              h={CHAT_DRAWER_HEIGHT}
              flexDir={"column"}
              justify={"space-between"}
            >
              <VStack
                onScroll={handleScroll}
                mb="1"
                ref={messageListRef}
                align="stretch"
                overflowY="auto"
              >
                <CircularLoading
                  loading={isFetchingNextPage || isLoading}
                  containerProps={{ minH: "150px" }}
                />
                {messages?.map((item, index) => (
                  <MessageItem message={item} key={index} />
                ))}
                {messages?.length === 0 && !isLoading && (
                  <Box
                    pt="10"
                    textAlign="center"
                    fontSize="13px"
                    color={getColor(colorKeys.gray, colorMode)}
                  >
                    No messages yet
                  </Box>
                )}
              </VStack>
              <Box>
                <Divider />
                <Flex align="center">
                  <Textarea
                    onKeyUp={(e) => {
                      if (e.key === "Enter") handleSendMessage();
                    }}
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    resize={"none"}
                    outline={"none !important"}
                    border="0 !important"
                    boxShadow={"none !important"}
                    placeholder="Type a message..."
                    rows={2}
                    fontSize="12px"
                  />
                  <FormFileUpload
                    id={"profilePicture"}
                    onChange={handleSendImage}
                    component={
                      <IconButton
                        isDisabled={inputMessage.trim().length > 0}
                        mr="1"
                        size="sm"
                        icon={<Icon as={APP_ICONS.IMAGE_SEND} boxSize="4" />}
                        variant="unstyled"
                      />
                    }
                    componentProps={{ variant: "ghost" }}
                    fileType={"IMAGE"}
                  />

                  <IconButton
                    isDisabled={inputMessage.trim().length <= 0}
                    mr="1"
                    size="sm"
                    icon={<Icon as={APP_ICONS.SEND} boxSize="4" />}
                    variant="unstyled"
                    onClick={handleSendMessage}
                  />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
      </CustomBottomDrawer>
    </React.Fragment>
  );
};

export default ConversationDrawer;
