import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  QueryClientProvider,
} from "@tanstack/react-query";
import { persistor, store } from './config/redux/store';
import reportWebVitals from './reportWebVitals';
import { createStandaloneToast } from '@chakra-ui/toast'
import App from './App';
import { theme } from './config/helpers/themeHelper';
import { queryClient } from './config/query';
import "./config/helpers/sentryHelper"
import "@fontsource/poppins";
import './index.css';

const { ToastContainer } = createStandaloneToast()

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ToastContainer />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </ChakraProvider>
);

reportWebVitals();
