import { Box, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import {
  formatDateTime,
  formatDateTimeToZone,
  guessTimeZone,
} from "../../../config/helpers/dateHelper";
import { DEFAULT_DATE_TIME_WITH_ZONE } from "../../../config/constants";
import { TIME_ZONES } from "../../../config/constants/date";

const DualTimeBox = ({ data, timeZoneOffSet }) => {
  return (
    <Box>
      <Tooltip label="Local Time">
        <Text>{`${formatDateTime(data, DEFAULT_DATE_TIME_WITH_ZONE)} ${
          TIME_ZONES[guessTimeZone()]
        })`}</Text>
      </Tooltip>
      <Tooltip label="Booking Zone Time">
        <Text>
          {formatDateTimeToZone(
            data,
            timeZoneOffSet,
            DEFAULT_DATE_TIME_WITH_ZONE
          )}
        </Text>
      </Tooltip>
    </Box>
  );
};

export default DualTimeBox;
