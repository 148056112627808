import { PROMO_CODE_TYPES } from "../../constants/enums"
import { prepareData } from "../apiHelper"
import { formatDateForInput, formatDateTimeForServer, truncateTime } from "../dateHelper"

export const prepareForm = ({
    formValues,
    serverValues
}) => ({
    ...(formValues?.id && {
        id: formValues?.id,
        ...serverValues,
        zoneIds: serverValues?.zones?.map((item) => item.id),
        access: serverValues?.access?.id,
        vehicleTypes: serverValues?.vehicleTypes?.map((item) => item.id),
    }),
    expiryDate: formatDateForInput(serverValues?.expiryDate),
    promoCodeType: serverValues?.promoCodeType?.id,
    paymentMethods: serverValues?.paymentMethods?.map((item) => item.id),
    status: serverValues?.status?.id,
})

export const preparePayload = (values) => {
    const payload = {
        ...values,
        redemptionLimit: Number(values.redemptionLimit),
        releasedQuantity: values.releasedQuantity ? Number(values.releasedQuantity) : null,
        maxDiscountLimit: Number(values.maxDiscountLimit),
        ...(values.discountPercentage && { discountAmount: null, discountPercentage: Number(values.discountPercentage) }),
        ...(values.discountAmount && { discountPercentage: null, discountAmount: Number(values.discountAmount) }),
        promoCodeType: values.promoCodeType ? PROMO_CODE_TYPES.CORPORATE : PROMO_CODE_TYPES.INDIVIDUAL,
        customerIds: values.customerIds?.map((item) => item.value),
        corporateIds: values.corporateIds?.map((item) => item.value),
        expiryDate: truncateTime(values.expiryDate),
    }
    if (payload.promoCodeType === PROMO_CODE_TYPES.CORPORATE) {
        delete payload.customerIds
    }
    if (payload.promoCodeType === PROMO_CODE_TYPES.INDIVIDUAL) {
        delete payload.corporateIds
    }
    return payload
}

export const prepareUpdatePayload = (values) => {
    const dataKeys = ['id', 'expiryDate', 'zoneIds', 'access', 'releasedQuantity']
    const payload = prepareData(values, dataKeys)
    payload.access = Number(payload.access)
    payload.releasedQuantity = payload.releasedQuantity ? Number(payload.releasedQuantity) : null
    payload.expiryDate = formatDateTimeForServer(values.expiryDate, "YYYY-MM-DD")
    payload.expiryDate = truncateTime(values.expiryDate)
    return payload
}