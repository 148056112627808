import React from "react";
import { SimpleGrid, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import FormModal from "../../../components/Forms/FormModal";
import FormSearchSelect from "../../../components/Forms/FormSearchSelect";
import { makeSelectList } from "../../../config/helpers/selectListHelper";
import { PAYMENT_STATUS } from "../../../config/constants/enums";
import { useParams } from "react-router";
import { useUpdatePaymentStatus } from "../../../config/query/invoiceQuery";
import { formatTimeForServer } from "../../../config/helpers/dateHelper";
import moment from "moment";
import FormTextarea from "../../../components/Forms/FormTextarea";

const PaymentStatusForm = ({ disclosure, data }) => {
  const queryClient = useQueryClient();
  const { corporateId } = useParams();

  const updatePayPeriod = useUpdatePaymentStatus();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm({});

  const onSubmit = (values) => {
    const payload = {
      corporateId: Number(corporateId),
      payPeriod: values.payPeriod,
      directInvoiceId: data?.id,
      paymentStatus: values.paymentStatus,
      paidOn: formatTimeForServer(moment()),
      comments: values.comments,
    };

    updatePayPeriod
      .mutateAsync(payload)
      .then(() => {
        disclosure.onClose();
        resetForm();
        queryClient.invalidateQueries(["direct-invoices"]);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return (
    <FormModal
      title={"Update Payment Status"}
      disclosure={disclosure}
      isSubmitting={updatePayPeriod.isLoading || updatePayPeriod.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"30rem"}
      saveButtonText={"Save"}
      reset={resetForm}
      onCloseFn={() => resetForm()}
    >
      <VStack spacing={5} align="stretch">
        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={5}>
          <FormSearchSelect
            id="paymentStatus"
            control={control}
            errors={errors}
            label={"Payment Status"}
            placeholder={"Select Payment Status"}
            options={makeSelectList(PAYMENT_STATUS)}
            containerStyles={{ mt: 5 }}
            inputProps={{ size: "sm" }}
          />

          <FormTextarea
            id="comments"
            control={control}
            errors={errors}
            label={"Comments"}
            placeholder="Enter comments"
            inputProps={{ size: "sm" }}
          />
        </SimpleGrid>
      </VStack>
    </FormModal>
  );
};

export default PaymentStatusForm;
