import { createSlice } from '@reduxjs/toolkit';
import { prepareNewChatObject } from '../../helpers/chatHelpers';

const initialState = {
    openedChats: [],
    chats: [],
    totalUnreadCount: 0,
}

export const chatSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        updateOpenedChats: (state, { payload }) => {
            state.openedChats = payload
        },
        updateChats: (state, { payload }) => {
            state.chats = payload
        },
        addOpenedChats: (state, { payload }) => {
            state.openedChats = [...state.openedChats, payload]
        },
        addChats: (state, { payload }) => {
            state.chats = [...state.chats, payload]
        },
        increaseTotalUnreadCount: (state) => {
            state.totalUnreadCount += 1
        },
        decreaseTotalUnreadCount: (state) => {
            state.totalUnreadCount -= 1
        },
        clearTotalUnreadCount: (state) => {
            state.totalUnreadCount = 0
        },
        removeOne: (state, { payload }) => {
            state.chats = state.chats.filter(chat => Number(chat.chatId) !== Number(payload))
        },
        addOne: (state, { payload }) => {
            const newMessage = prepareNewChatObject(payload)
            state.chats = [newMessage, ...state.chats]
        },
    },
})

export const { updateOpenedChats, updateChats, addOpenedChats, addChats, increaseTotalUnreadCount, decreaseTotalUnreadCount, clearTotalUnreadCount, removeOne, addOne } = chatSlice.actions

export default chatSlice.reducer