import { Box, Divider, VStack, useColorMode } from "@chakra-ui/react";
import React from "react";
import LabelValueRow from "../../../../../components/BasicUI/DataBoxes/LabelValueRow";
import { formatAmount } from "../../../../../config/helpers/stringHelper";
import { colorKeys, getColor } from "../../../../../config/constants/appColors";
import { getCostBreakdownValueProps } from "../../../../../config/helpers/formHelpers/bookingHelper";

const CompanyBreakdown = ({ data }) => {
  const { colorMode } = useColorMode();
  const fares = data?.fares;
  return (
    <Box>
      <VStack spacing={0} align="stretch">
        {Object.keys(fares).map((key) => {
          const { valueProps, value } = getCostBreakdownValueProps(fares, key);
          return (
            <LabelValueRow
              key={key}
              label={key}
              value={value}
              valueProps={valueProps}
              labelProps={{ textTransform: "capitalize", pl: "5" }}
            />
          );
        })}
      </VStack>

      <Divider h="1px" my={1} />

      <Box bg={getColor(colorKeys.highlightBg, colorMode)} mt={2}>
        <LabelValueRow
          containerProps={{ _odd: {} }}
          label="Total"
          value={
            data?.totalFare < 0
              ? `(${formatAmount(data?.totalFare)})`
              : formatAmount(data?.totalFare)
          }
          valueProps={{
            textAlign: "center",
            color: data?.totalFare < 0 ? "red" : "",
          }}
          labelProps={{ pl: "5" }}
        />
      </Box>
    </Box>
  );
};

export default CompanyBreakdown;
