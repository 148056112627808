import { useForm } from "react-hook-form";
import {
  Box,
  Card,
  CardBody,
  chakra,
  useColorMode,
  Grid,
  GridItem,
  Heading,
  Flex,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import FormButton from "../../../../components/Forms/FormButton";
import FormInput from "../../../../components/Forms/FormInput";
import FormSearchSelect from "../../../../components/Forms/FormSearchSelect";
import { ZONE_STATUS } from "../../../../config/constants/enums";
import {
  useCreateZone,
  useZones,
  useZone,
  useUpdateZone,
  useParentZones,
} from "../../../../config/query/zoneQuery";
import { useNavigate, useParams } from "react-router";
import FormMap from "../../../../components/Forms/FormMap";
import {
  prepareForm,
  preparePayload,
} from "../../../../config/helpers/formHelpers/zoneHelper";
import { useQueryClient } from "@tanstack/react-query";
import LocationSearchSelect from "../../../../components/Maps/LocationSearchSelect";
import FormSwitch from "../../../../components/Forms/FormSwitch";
// import { makeSelectList } from "../../../../config/helpers/selectListHelper";
// import { TIME_ZONE_ARRAY } from "../../../../config/constants/date";

const ZoneForm = () => {
  const { colorMode } = useColorMode();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const zoneQuery = useZones();
  const createZoneQuery = useCreateZone();
  const useZoneQuery = useZone(id);
  const updateZoneQuery = useUpdateZone();
  const parentZones = useParentZones()

  const {
    handleSubmit,
    control,
    reset: resetForm,
    formState: { errors },
    watch,
  } = useForm({
    values: prepareForm({
      id: id,
      serverValues: useZoneQuery?.data?.data,
    }),
  });

  const onSubmit = (values) => {
    let formMutate = id
      ? updateZoneQuery.mutateAsync
      : createZoneQuery.mutateAsync;

    formMutate(preparePayload(values))
      .then(() => {
        queryClient.invalidateQueries(["zone"]);
        queryClient.invalidateQueries(["zones"])
        navigate("/admin/zones");
      })
      .catch((error) => console.warn(error));
  };

  const searchLocation = watch("searchLocation");
  const coordinates = watch("coordinates");

  const resetFormValues = () => {
    resetForm(
      prepareForm({
        id: id,
        serverValues: { ...useZoneQuery?.data?.data, searchLocation: "", coordinates: [] },
      })
    );
  };

  return (
    <Card>
      <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
        <chakra.form onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(12, 1fr)" gap={8}>
            <GridItem
              w="100%"
              h="full"
              colSpan={{ base: 12, xl: 4 }}
              alignItems={
                errors["name"]?.message && errors["status"]?.message
                  ? "center"
                  : "end"
              }
            >
              <Heading fontSize={"26px"} mt={3}>{id ? "Update" : "Create"} Zone</Heading>
              <Box mt={10}>
                <FormInput
                  id={"name"}
                  label="Zone Name"
                  placeholder="Enter zone name"
                  required={true}
                  errors={errors}
                  control={control}
                  inputProps={{
                    isDisabled: createZoneQuery.isLoading,
                    size: "sm",
                  }}
                />

                <FormInput
                  id={"connectedAccountId"}
                  label="Connected Account ID"
                  placeholder="Enter connected account id provided by rms"
                  errors={errors}
                  control={control}
                  inputProps={{
                    isDisabled: createZoneQuery.isLoading,
                    size: "sm",
                  }}
                  containerStyles={{ mt: "20px" }}
                />

                <FormSearchSelect
                  id="status"
                  options={Object.keys(ZONE_STATUS).map((key) => ({
                    value: key,
                    label: ZONE_STATUS[key],
                  }))}
                  label={"Status"}
                  required={true}
                  control={control}
                  errors={errors}
                  placeholder="Select Status"
                  inputProps={{
                    isDisabled: createZoneQuery.isLoading,
                    size: "sm",
                  }}
                  labelProps={{ marginTop: "20px" }}
                />
                {parentZones.data?.isWalletEnabled && (
                  <FormSwitch
                    control={control}
                    errors={errors}
                    id={"isWalletEnabled"}
                    label={"Enable Apple/Google Pay"}
                    inputProps={{ size: "sm" }}
                    containerProps={{ mt: "20px" }}
                  />
                )}

                {parentZones.data?.isCashEnabled && (
                  <FormSwitch
                    control={control}
                    errors={errors}
                    id={"isCashEnabled"}
                    label={"Enable Cash"}
                    inputProps={{ size: "sm" }}
                    containerProps={{ mt: "20px" }}
                  />
                )}


                <Flex flexDir={{ base: "column", lg: "row" }} gap={5} mr="auto" align="end" mt="45px">
                  <FormButton
                    type="button"
                    onClick={resetFormValues}
                    bg={getColor(colorKeys.gray, colorMode)}
                    _hover={{ bg: getColor(colorKeys.gray, colorMode) }}
                    isDisabled={createZoneQuery.isLoading}
                    size="md"
                    w="full"
                    fontSize="14"
                  >
                    Clear
                  </FormButton>

                  <FormButton
                    isLoading={
                      updateZoneQuery.isLoading || createZoneQuery.isLoading
                    }
                    type="submit"
                    size="md"
                    w="full"
                    fontSize="14"
                  >
                    {id ? "Update" : "Create"} Zone
                  </FormButton>
                </Flex>

                <VStack mt={5} align="start">
                  <Text fontWeight={"600"}>Zone Form Guide</Text>
                  <HStack>
                    <Box w="30px" h="30px" border={`2px solid ${getColor(colorKeys.siderBackground, colorMode)}`}>
                      <Box w="100%" h="100%" bg={getColor(colorKeys.siderBackground, colorMode)} opacity={0.5} />
                    </Box>
                    <Text fontSize="14px">All created zones</Text>
                  </HStack>
                  <HStack>
                    <Box w="30px" h="30px" border={`2px solid ${getColor(colorKeys.danger, colorMode)}`}>
                      <Box w="100%" h="100%" bg={getColor(colorKeys.danger, colorMode)} opacity={0.5} />
                    </Box>
                    <Text fontSize="14px">Area you are allowed to create a zone in</Text>
                  </HStack>
                  <HStack>
                    <Box w="30px" h="30px" border={`2px solid ${getColor(colorKeys.lightBlue, colorMode)}`}>
                      <Box w="100%" h="100%" bg={getColor(colorKeys.lightBlue, colorMode)} opacity={0.5} />
                    </Box>
                    <Text fontSize="14px">Currently being created or edited zone</Text>
                  </HStack>
                </VStack>
              </Box>
            </GridItem>
            <GridItem w="100%" h="full" colSpan={{ base: 12, xl: 8 }}>
              <Box>
                <FormMap
                  currentScreen={id ? "ZONE_UPDATE" : "ZONE_CREATE"}
                  strictBorders={parentZones.data?.coordinates}
                  control={control}
                  errors={errors}
                  id={"coordinates"}
                  label={"Coordinates"}
                  required={true}
                  containerProps={{ h: "calc(100vh - 220px)" }}
                  formControlProps={{ mt: 2 }}
                  labelExtention={
                    !id ? <Box w="450px" maxW="60%" mb={2}>
                      <LocationSearchSelect
                        control={control}
                        errors={errors}
                        id={"searchLocation"}
                        label={"Search Location"}
                        placeholder={"Enter location"}
                        inputProps={{ size: "sm", isDisabled: coordinates?.length > 0, w: "100% !important" }}
                        containerStyles={{ w: "full", title: "Please clear coordinates to search location" }}
                      />
                    </Box> : null
                  }
                  center={searchLocation?.latLng}
                  rules={{
                    validate: (value) => {
                      if (value.length === 0) {
                        return "Please select coordinates";
                      }
                    },
                  }}
                  polygonsArray={
                    zoneQuery.data
                      ?.filter((item) => item.id !== Number(id))
                      ?.map((zone) => zone.coordinates) || []
                  }
                />
              </Box>
            </GridItem>
          </Grid>
        </chakra.form>
      </CardBody>
    </Card>
  );
};

export default ZoneForm;
