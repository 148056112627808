import { useState } from 'react';
import CustomTable from '../../../components/BasicUI/CustomTable'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import { formatDateTimeToZone } from '../../../config/helpers/dateHelper';
import APP_ICONS from '../../../config/constants/icons'
import { Box, Text, VStack } from '@chakra-ui/react'
import TableHeaderOptions from '../../../components/BasicUI/CustomTable/TableHeaderOptions'
import { useBookings, useDeleteBooking } from '../../../config/query/bookingsQuery'
import { getFilters } from '../../../config/helpers/filterHelper'
import { BOOKINGS_STATUS, CANCEL_BOOKING_TYPE, RIDE_STATUS, RIDE_TYPE } from '../../../config/constants/enums'
import BreakText from '../../../components/BasicUI/DataBoxes/BreakText'
import { useNavigate } from 'react-router'
import { makeInitialQueryObject, updateUrl, queryStringToObject } from '../../../config/helpers/queryHelper'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'
import { formatAmount } from '../../../config/helpers/stringHelper'
import useDebounce from '../../../config/hooks/useDebounce'
import appendQueryParams from '../../../config/helpers/appendQueryParams'
import { APP_MODULES, DEFAULT_DATE_TIME_WITH_ZONE } from '../../../config/constants'
import BookingTablePopover from '../../../components/BasicUI/Popovers/BookingTablePopover'
import { Link } from 'react-router-dom';

const Bookings = () => {
  const navigate = useNavigate()

  const [query, setQuery] = useState(makeInitialQueryObject({ ...queryStringToObject() }))
  const debouncedQuery = useDebounce(query)

  const bookingsQuery = useBookings(debouncedQuery)
  const deleteBookingQuery = useDeleteBooking()

  const onDelete = (id) => {
    deleteBookingQuery
      .mutateAsync(id)
      .catch((error) => console.warn(error))
  }

  const onView = (item) => {
    navigate(`/admin/bookings/${item.id}`)
  }

  const onQueryChange = (updatedQuery) => {
    let temp = {}
    setQuery((prev) => {
      temp = { ...prev, ...updatedQuery }
      return temp
    })
    updateUrl({ pageTitle: "Bookings", queryString: appendQueryParams(temp) })
  }

  const filters = {
    BookingStatus: Object.keys(BOOKINGS_STATUS).map((item) => ({
      key: item,
      value: BOOKINGS_STATUS[item],
    })),
    RideStatus: Object.keys(RIDE_STATUS).map((item) => ({
      key: item,
      value: RIDE_STATUS[item],
    })),
    ...(Number(query?.RideStatus) === 0 && {
      CancellationReasonType: Object.keys(CANCEL_BOOKING_TYPE).map((item) => ({
        key: item,
        value: CANCEL_BOOKING_TYPE[item],
      }))
    }),
  }

  return (
    <Box>
      <TableHeaderOptions
        title={"Bookings"}
        actionText={"Create New Booking"}
        action={() => {
          navigate("/admin/bookings/create")
        }}
        icon={APP_ICONS.ADD}
        permissionKey={APP_MODULES.Booking}
      />

      <CustomTable
        tableFor={"booking"}
        searchPlaceholder="Search bookings"
        permissionKey={APP_MODULES.Booking}
        filters={getFilters(filters)}
        head={[
          {
            title: "Booking #",
            extractor: "id",
            align: "left",
            isSortable: true,
            component: (item) => <BookingTablePopover data={item} />
          },
          {
            title: "Fare",
            extractor: "totalChargeOnCustomer",
            isSortable: true,
            align: "center",
            hint: "Incl. all charges on customer",
            component: (item) => <Text fontSize="14px">{formatAmount(item?.totalChargeOnCustomer)}</Text>
          },
          {
            title: "Ride Type",
            extractor: "rideTime",
            align: "left",
            isSortable: true,
            component: (item) => <Box>
              {(!item.rideType && !item.rideTime) && "N/A"}
              <Text fontSize="14px">{item.rideType ? RIDE_TYPE[item.rideType?.id] : ""}</Text>
              {item.rideTime &&
                <Text fontSize="14px">{formatDateTimeToZone(
                  item.rideTime,
                  item.timeZoneOffSet,
                  DEFAULT_DATE_TIME_WITH_ZONE
                )}
                </Text>
              }
            </Box>
          },
          {
            title: "Pickup / Dropoff Time",
            extractor: "pickup",
            align: "left",
            component: (item) => <Box>
              {(!item.pickup && !item.dropoff) && "N/A"}
              {item.pickup &&
                <Text fontSize="14px">{formatDateTimeToZone(
                  item.pickup,
                  item.timeZoneOffSet,
                  DEFAULT_DATE_TIME_WITH_ZONE
                )}
                </Text>
              }
              {item.dropoff &&
                <Text fontSize="14px">{formatDateTimeToZone(
                  item.dropoff,
                  item.timeZoneOffSet,
                  DEFAULT_DATE_TIME_WITH_ZONE
                )}
                </Text>
              }
            </Box>
          },
          { title: "Pickup Location", extractor: "pickupAddress", align: "left", isSortable: true, component: (item) => <BreakText value={item.pickupAddress} /> },
          { title: "Destination", extractor: "dropoffAddress", align: "left", isSortable: true, component: (item) => <BreakText value={item.dropoffAddress} /> },
          { title: "Flight Number", extractor: "flightNumber", align: "left" },
          { title: "Gate Number", extractor: "gateNumber", align: "left" },
          {
            title: "Passenger", extractor: "passengerName", align: "left", isSortable: true,
            component: (item) => <VStack align="stretch" spacing={2}>
              <Text fontSize="14px"> {(!item.passengerName && !item.passengerPhone) && "N/A"}</Text>
              <Text fontSize="14px" _hover={{ textDecor: "underline" }} as={Link} to={`/admin/customers/?AdvancedSearch.Keyword=${item.passengerName}`}>{item.passengerName ? item.passengerName : ""}</Text>
              <Text fontSize="14px">{item.passengerPhone ? item.passengerPhone : ""}</Text>
            </VStack>
          },
          {
            title: "Driver", extractor: "driverName", align: "left", isSortable: true,
            component: (item) => <VStack align="stretch" spacing={2}>
              <Text fontSize="14px">{(!item.driverName && !item.driverPhone) && "N/A"}</Text>
              <Text fontSize="14px" _hover={{ textDecor: "underline" }} as={Link} to={`/admin/drivers/?AdvancedSearch.Keyword=${item.driverName}`}>{item.driverName ? item.driverName : ""}</Text>
              <Text fontSize="14px">{item.driverPhone ? item.driverPhone : ""}</Text>
            </VStack>
          },
          { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status?.id} bookingStatus={true} />, isSortable: true, align: "center" },
          { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
          { title: "Actions", extractor: "actions", align: "center" }
        ]}
        data={bookingsQuery?.data?.data}
        loading={bookingsQuery?.isLoading}
        totalResults={bookingsQuery?.data?.meta?.totalCount}
        totalPages={bookingsQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={bookingsQuery.refetch}
        isRefreshing={bookingsQuery?.isFetching}
        theadRowProps={{
          className: "active-bookings-table-header"
        }}
        colorRows={true}
        onView={onView}
        onDelete={onDelete}
        viewActionProps={{ as: "a" }}
        viewUrl="/admin/bookings/"
      />

    </Box >
  )
}

export default Bookings