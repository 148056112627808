import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import CustomTable from '../../../components/BasicUI/CustomTable'
import TableInfoPopover from '../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'
import { formatDateTime } from '../../../config/helpers/dateHelper'
import { Box, Text, useDisclosure } from '@chakra-ui/react'
import TableHeaderOptions from '../../../components/BasicUI/CustomTable/TableHeaderOptions'
import APP_ICONS from '../../../config/constants/icons'
import CustomerForm from './CustomerForm'
import { useCustomers, useDeleteCustomer, useUpdateCustomerStatus } from '../../../config/query/customerQuery'
import { getFilters } from '../../../config/helpers/filterHelper'
import { STATUS } from '../../../config/constants/enums'
import StatusSwitch from '../../../components/BasicUI/DataBoxes/StatusSwitch'
import { makeInitialQueryObject, queryStringToObject, updateUrl } from '../../../config/helpers/queryHelper'
import { formatAmount, getDeviceName } from '../../../config/helpers/stringHelper'
import useDebounce from '../../../config/hooks/useDebounce'
import appendQueryParams from '../../../config/helpers/appendQueryParams'
import { APP_MODULES } from '../../../config/constants'
import CopyText from '../../../components/BasicUI/DataBoxes/CopyText'

const Customers = () => {
    const navigate = useNavigate()
    const formDisclosure = useDisclosure()
    const [selectedCustomer, setSelectedCustomer] = React.useState(null)
    const [query, setQuery] = useState(makeInitialQueryObject(queryStringToObject()))
    const debouncedQuery = useDebounce(query)

    const customersQuery = useCustomers(debouncedQuery)
    const customerStatus = useUpdateCustomerStatus()
    const deleteCustomerQuery = useDeleteCustomer()

    const onEditModal = (item) => {
        setSelectedCustomer(item)
        formDisclosure.onOpen()
    }

    const onView = (item) => navigate(`/admin/customers/${item.id}`)

    const onQueryChange = (updatedQuery) => {
        let temp = {}
        setQuery((prev) => {
            temp = { ...prev, ...updatedQuery }
            return temp
        })
        updateUrl({ pageTitle: "Customers", queryString: appendQueryParams(temp) })
    }

    const handleStatusChange = (status, id) => {
        customerStatus.mutate({ status: Number(status), id })
    }

    const handleDelete = (id) => {
        deleteCustomerQuery.mutateAsync(id)
            .then(() => {
                customersQuery.refetch()
            })
            .catch((error) => {
                console.warn(error)
            })
    }

    // useEffect(() => {
    //     updateUrl({ pageTitle: "Customers", queryString: appendQueryParams(debouncedQuery) })
    // }, [debouncedQuery])

    return (
        <Box>
            <TableHeaderOptions
                title={"Customers"}
                actionText={"Add New Customer"}
                action={() => {
                    setSelectedCustomer(null)
                    formDisclosure.onOpen()
                }}
                icon={APP_ICONS.ADD}
                permissionKey={APP_MODULES.Customer}
            />
            <CustomTable
                permissionKey={APP_MODULES.Customer}
                tableFor="customer"
                searchPlaceholder="Search customers"
                filters={getFilters({
                    Status: Object.keys(STATUS).map((item) => ({
                        key: item,
                        value: STATUS[item],
                    }))
                })}
                head={[
                    {
                        title: "Name",
                        extractor: "name",
                        align: "left",
                        isSortable: true,
                        component: (item) => (
                            <TableInfoPopover
                                data={item}
                                onEdit={(item) => onEditModal(item)}
                                fallBackText={"N/A"}
                                triggerText={item.name}
                                triggerOnClick={() => onView(item)}
                            >
                                <LabelValuePair label="Description" value={item.description} />
                                <LabelValuePair
                                    label="Status"
                                    value={true}
                                    valueComponent={
                                        <StatusBadge value={item.status} />
                                    }
                                />
                                <LabelValuePair label="Created" value={formatDateTime(item.createdOn)} />
                                <LabelValuePair label="Last Updated" value={formatDateTime(item.lastModifiedOn)} />
                            </TableInfoPopover>
                        )
                    },
                    {
                        title: "Phone Number",
                        extractor: "phone",
                        isSortable: true,
                        component: (item) =>
                            <CopyText
                                text={item.phone}
                                containerProps={{
                                    w: "140px",
                                    justify: "space-between"
                                }}
                            />
                    },
                    { title: "Email", extractor: "email", isSortable: true },
                    { title: "City", extractor: "city", isSortable: true },
                    { title: "Credit Balance", extractor: "creditBalance", isSortable: true, align: "center", component: (item) => <Text fontSize="14px">{formatAmount(item.creditBalance)}</Text> },
                    { title: "Status", extractor: "status", align: "center", isSortable: true, component: (item) => <StatusSwitch key={item.id} value={item.status?.id} onChange={(e) => handleStatusChange(e.target.checked, item.id)} /> },
                    { title: "Device", align: "center", extractor: "deviceName", component: (item) => getDeviceName({ deviceName: item.deviceName, deviceId: item.deviceId }) },
                    { title: "App Version", align: "center", extractor: "appVersion" },
                    { title: "Last Login", align: "center", extractor: "lastLogin", isSortable: true, component: (item) => formatDateTime(item?.lastLogin) },
                    { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
                    {
                        title: "Actions",
                        extractor: "actions",
                        align: "center"
                    }
                ]}
                data={customersQuery?.data?.data}
                loading={customersQuery?.isLoading}
                totalResults={customersQuery?.data?.meta?.totalCount}
                totalPages={customersQuery?.data?.meta?.totalPages}
                pageNo={query?.PageNumber}
                pageSize={query?.PageSize}
                onQueryChange={onQueryChange}
                query={query}
                onRefresh={() => customersQuery.refetch()}
                isRefreshing={customersQuery?.isFetching}

                onDelete={handleDelete}
                onEdit={(item) => onEditModal(item)}
                onView={onView}
            />

            <CustomerForm disclosure={formDisclosure} data={selectedCustomer} onSuccess={customersQuery.refetch} />
        </Box>

    )
}

export default Customers