import React from 'react'
// import { SignalRProvider } from './config/sockets/SignalRContext'
import AppRouter from './config/routes'
import { useSelector } from 'react-redux';
import { SignalRProvider } from './config/sockets/SignalRContext';

const App = () => {
    const signalRConnectionInfo = useSelector((state) => state.user?.user?.signalRConnectionInfo);
    if (signalRConnectionInfo?.url) {
        return <SignalRProvider serverUrl={signalRConnectionInfo?.url} accessToken={signalRConnectionInfo?.accessToken}>
            <AppRouter />
        </SignalRProvider>
    }
    return <AppRouter />
}

export default App