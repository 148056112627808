import { Box, Card, CardBody, CardHeader, Heading, Text, VStack, useColorMode } from '@chakra-ui/react'
import { useState } from 'react';
import { colorKeys, getColor } from '../../../config/constants/appColors'
import CustomTable from '../../../components/BasicUI/CustomTable'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import { formatDateTimeToZone } from '../../../config/helpers/dateHelper';
import { formatAmount } from '../../../config/helpers/stringHelper'
import { BOOKINGS_STATUS, CANCEL_BOOKING_TYPE, RIDE_STATUS, RIDE_TYPE } from '../../../config/constants/enums'
import BreakText from '../../../components/BasicUI/DataBoxes/BreakText'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'
import { makeInitialQueryObject, queryStringToObject, updateUrl } from '../../../config/helpers/queryHelper'
import useDebounce from '../../../config/hooks/useDebounce'
import { useBookings } from '../../../config/query/bookingsQuery'
import { useNavigate, useParams } from 'react-router'
import appendQueryParams from '../../../config/helpers/appendQueryParams'
import { APP_MODULES, DEFAULT_DATE_TIME_WITH_ZONE } from '../../../config/constants'
import BookingTablePopover from '../../../components/BasicUI/Popovers/BookingTablePopover'
import { getFilters } from '../../../config/helpers/filterHelper';

const DriverBookings = ({ driverName }) => {
    const navigate = useNavigate()
    const { driverId } = useParams()
    const { colorMode } = useColorMode()

    const [query, setQuery] = useState(makeInitialQueryObject({ DriverId: driverId, ...queryStringToObject() }))
    const debouncedQuery = useDebounce(query)

    const bookingsQuery = useBookings(debouncedQuery)

    const onView = (item) => {
        navigate(`/admin/bookings/${item.id}`)
    }

    const onQueryChange = (updatedQuery) => {
        let temp = {}
        setQuery((prev) => {
            temp = { ...prev, ...updatedQuery }
            return temp
        })
        updateUrl({ pageTitle: "Driver Bookings", queryString: appendQueryParams(temp) })
    }
    const filters = {
        BookingStatus: Object.keys(BOOKINGS_STATUS).map((item) => ({
            key: item,
            value: BOOKINGS_STATUS[item],
        })),
        RideStatus: Object.keys(RIDE_STATUS).map((item) => ({
            key: item,
            value: RIDE_STATUS[item],
        })),
        ...(Number(query?.RideStatus) === 0 && {
            CancellationReasonType: Object.keys(CANCEL_BOOKING_TYPE).map((item) => ({
                key: item,
                value: CANCEL_BOOKING_TYPE[item],
            }))
        }),
    }

    return (
        <Box w="full">

            <Card w="full" mt={3}>
                <CardHeader pb={0} bg={getColor(colorKeys.tableBackground, colorMode)}>
                    <Heading color={getColor(colorKeys.dark, colorMode)} fontSize="18px">
                        {driverName ? `${driverName}'s Bookings` : "Driver's Bookings"}
                    </Heading>
                </CardHeader>

                <CardBody pt={3} bg={getColor(colorKeys.tableBackground, colorMode)}>
                    <CustomTable
                        permissionKey={APP_MODULES.Booking}
                        filters={getFilters(filters)}
                        tableFor="booking"
                        tableWrapperProps={{
                            padding: 0,
                            h: "calc(100vh - 410px)"
                        }}
                        containerProps={{
                            h: "calc(100vh - 275px)"
                        }}
                        head={[
                            {
                                title: "Booking",
                                extractor: "id",
                                align: "left",
                                isSortable: true,
                                component: (item) => <BookingTablePopover data={item} />
                            },
                            { title: "Fare", extractor: "fare", isSortable: true, align: "center", component: (item) => <Text fontSize="14px">{formatAmount(item?.fare)}</Text> },
                            { title: "Driver Share", extractor: "driverShare", isSortable: true, align: "center", component: (item) => <Text fontSize="14px">{formatAmount(item?.driverAmount)}</Text> },
                            {
                                title: "Ride Type",
                                extractor: "rideTime",
                                align: "left",
                                isSortable: true,
                                component: (item) => <Box>
                                    {(!item.rideType && !item.rideTime) && "N/A"}
                                    <Text fontSize="14px">{item.rideType ? RIDE_TYPE[item.rideType?.id] : ""}</Text>
                                    <Text fontSize="14px">{formatDateTimeToZone(
                                        item.rideTime,
                                        item.timeZoneOffSet,
                                        DEFAULT_DATE_TIME_WITH_ZONE
                                    )}</Text>
                                </Box>
                            },
                            {
                                title: "Pickup / Dropoff Time",
                                extractor: "pickup",
                                align: "left",
                                component: (item) => <Box>
                                    {(!item.pickup && !item.dropoff) && "N/A"}
                                    <Text fontSize="14px">{formatDateTimeToZone(
                                        item.pickup,
                                        item.timeZoneOffSet,
                                        DEFAULT_DATE_TIME_WITH_ZONE
                                    )}</Text>
                                    <Text fontSize="14px">{formatDateTimeToZone(
                                        item.dropoff,
                                        item.timeZoneOffSet,
                                        DEFAULT_DATE_TIME_WITH_ZONE
                                    )}</Text>
                                </Box>
                            },
                            { title: "Pickup Location", extractor: "pickupAddress", align: "left", isSortable: true, component: (item) => <BreakText value={item.pickupAddress} /> },
                            { title: "Destination", extractor: "dropoffAddress", align: "left", isSortable: true, component: (item) => <BreakText value={item.dropoffAddress} /> },
                            { title: "Flight Number", extractor: "flightNumber", align: "left" },
                            { title: "Gate Number", extractor: "gateNumber", align: "left" },
                            {
                                title: "Passenger", extractor: "passengerName", align: "left", isSortable: true,
                                component: (item) => <VStack align="stretch" spacing={2}>
                                    <Text fontSize="14px"> {(!item.passengerName && !item.passengerPhone) && "N/A"}</Text>
                                    <Text fontSize="14px">{item.passengerName ? item.passengerName : ""}</Text>
                                    <Text fontSize="14px">{item.passengerPhone ? item.passengerPhone : ""}</Text>
                                </VStack>
                            },
                            {
                                title: "Driver", extractor: "driverName", align: "left", isSortable: true,
                                component: (item) => <VStack align="stretch" spacing={2}>
                                    <Text fontSize="14px">{(!item.driverName && !item.driverPhone) && "N/A"}</Text>
                                    <Text fontSize="14px">{item.driverName ? item.driverName : ""}</Text>
                                    <Text fontSize="14px">{item.driverPhone ? item.driverPhone : ""}</Text>
                                </VStack>
                            },
                            { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status?.id} bookingStatus={true} />, isSortable: true, align: "center" },
                            { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
                        ]}
                        data={bookingsQuery?.data?.data}
                        loading={bookingsQuery?.isLoading}
                        totalResults={bookingsQuery?.data?.meta?.totalCount}
                        totalPages={bookingsQuery?.data?.meta?.totalPages}
                        pageNo={query?.PageNumber}
                        pageSize={query?.PageSize}
                        onQueryChange={onQueryChange}
                        query={query}
                        onRefresh={bookingsQuery.refetch}
                        isRefreshing={bookingsQuery?.isFetching}
                        theadRowProps={{
                            className: "active-bookings-table-header"
                        }}
                        colorRows={true}
                        onView={onView}
                    />
                </CardBody>
            </Card>
        </Box>

    )
}

export default DriverBookings