export const CONTENT_TYPE = {
  JSON: "application/json",
  FORM_DATA: "multipart/form-data",
};

export const MILISECONDS = {
  SECOND: 1000,
  MINUTE: 1000 * 60,
  HOUR: 1000 * 60 * 60,
  MAX: 1000 * 60 * 60 * 24 * 7,
};

export const DEFAULT_UPLOAD_SIZE = 5
export const DEFAULT_DEBOUNCE_TIME = 250;
export const DEFAULT_DATETIME_FORMAT = "DD-MMM-YYYY hh:mm A";
export const DEFAULT_DATETIME_FORMAT_WITH_SECONDS = "DD-MMM-YYYY hh:mm:ss A";
export const DEFAULT_DATE_FORMAT = "DD-MMM-YYYY";
export const DEFAULT_DATE_TIME_WITH_ZONE = "YYYY-MM-DD hh:mm:ss A (UTCZ"

export const APP_PERMISSIONS = {
  DASHBOARD: "DASHBOARD",
  MAP: "MAP",
  CUSTOMER: "CUSTOMER",
  DRIVER: "DRIVER",
  TRANSPORT: "TRANSPORT",
  CORPORATE: "CORPORATE",
  BOOKING: "BOOKING",
  ZONE: "ZONE",
  REPORTS: "REPORTS",
  PRICING: "PRICING",
  SUBADMIN: "SUBADMIN",
  PROFILE: "PROFILE",
  PROMOCODE: "PROMOCODE",
  NOTIFICATIONS: "NOTIFICATIONS",
  TENANTS: "TENANTS",
  ROLES: "ROLES",
  SHIFT: "SHIFT",
  SUPPORT: "SUPPORT",
  COUNTRY: "COUNTRY",
  STATE: "STATE",
  CITY: "CITY",
  SYSTEMCONFIGURATIONS: "SYSTEMCONFIGURATIONS",
  SYSTEMINTEGRATIONS: "SYSTEMINTEGRATIONS",
  REVIEWS: "REVIEWS"
}

export const APP_PERMISSION_ACTIONS = {
  VIEW: "view",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
}

export const NON_ACTIONS_MODULES = {
  0: "Dashboard",
  1: "Map",
  8: "Reports",
  17: "Chat & Messaging",
  23: "Reviews"
}

export const APP_MODULES = {
  Dashboard: 0,
  Map: 1,
  Customer: 2,
  Driver: 3,
  VehicleManagement: 4,
  Corporate: 5,
  Booking: 6,
  Zone: 7,
  Reports: 8,
  Pricing: 9,
  SubAdmin: 10,
  Profile: 11,
  PromoCode: 12,
  Notifications: 13,
  Tenants: 14,
  Roles: 15,
  Shift: 16,
  ChatAndMessaging: 17,
  Country: 18,
  State: 19,
  City: 20,
  SystemConfigurations: 21,
  SystemIntegrations: 22,
  Reviews: 23
}

export const permissionOptions = [
  {
    "module": {
      "id": 0,
      "name": "Dashboard"
    },
    "dependentPermissions": {
      "Dashboard.View": [
        "Booking.View",
        "Zone.View"
      ]
    },
    "additionalActions": null
  },
  {
    "module": {
      "id": 1,
      "name": "Map"
    },
    "dependentPermissions": {
      "Map.View": [
        "VehicleManagement.View",
        "City.View",
        "Booking.View"
      ]
    },
    "additionalActions": null
  },
  {
    "module": {
      "id": 2,
      "name": "Customer"
    },
    "dependentPermissions": {
      "Customer.View": [
        "Booking.View"
      ],
      "Customer.Create": [
        "City.View"
      ],
      "Customer.Update": [
        "City.View"
      ]
    },
    "additionalActions": [
      "UpdateCreditBalance"
    ]
  },
  {
    "module": {
      "id": 3,
      "name": "Driver"
    },
    "dependentPermissions": {
      "Driver.View": [
        "Booking.View"
      ],
      "Driver.Create": [
        "Country.View",
        "State.View",
        "City.View",
        "Shift.View",
        "Zone.View",
        "VehicleManagement.View",
        "VehicleManagement.Create",
        "VehicleManagement.Update"
      ],
      "Driver.Update": [
        "Country.View",
        "State.View",
        "City.View",
        "Shift.View",
        "Zone.View",
        "VehicleManagement.View",
        "VehicleManagement.Create",
        "VehicleManagement.Update"
      ]
    },
    "additionalActions": [
      "UpdateCreditBalance"
    ]
  },
  {
    "module": {
      "id": 4,
      "name": "VehicleManagement"
    },
    "dependentPermissions": {
      "VehicleManagement.Create": [
        "Zone.View"
      ],
      "VehicleManagement.Update": [
        "Zone.View"
      ]
    },
    "additionalActions": null
  },
  {
    "module": {
      "id": 5,
      "name": "Corporate"
    },
    "dependentPermissions": {
      "Corporate.View": [
        "Booking.View"
      ],
      "Corporate.Create": [
        "City.View"
      ],
      "Corporate.Update": [
        "City.View"
      ]
    },
    "additionalActions": [
      "UpdateCreditBalance"
    ]
  },
  {
    "module": {
      "id": 6,
      "name": "Booking"
    },
    "dependentPermissions": {
      "Booking.Create": [
        "Corporate.View",
        "Customer.View",
        "PromoCode.View"
      ],
      "Booking.Update": [
        "Corporate.View",
        "Customer.View",
        "PromoCode.View"
      ]
    },
    "additionalActions": [
      "Ratings",
      "DispatchLogs",
      "TrackBooking",
      "CostBreakdown",
      "CancelBooking"
    ]
  },
  {
    "module": {
      "id": 7,
      "name": "Zone"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 8,
      "name": "Reports"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 9,
      "name": "Pricing"
    },
    "dependentPermissions": {
      "Pricing.View": [
        "Zone.View",
        "Corporate.View"
      ],
      "Pricing.Create": [
        "Zone.View",
        "Corporate.View"
      ],
      "Pricing.Update": [
        "Zone.View",
        "Corporate.View"
      ]
    },
    "additionalActions": null
  },
  {
    "module": {
      "id": 10,
      "name": "SubAdmin"
    },
    "dependentPermissions": {
      "SubAdmin.Create": [
        "Roles.View"
      ],
      "SubAdmin.Update": [
        "Roles.View"
      ]
    },
    "additionalActions": null
  },
  {
    "module": {
      "id": 11,
      "name": "Profile"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 12,
      "name": "PromoCode"
    },
    "dependentPermissions": {
      "PromoCode.Create": [
        "Zone.View",
        "VehicleManagement.View",
        "Customer.View"
      ],
      "PromoCode.Update": [
        "Zone.View",
        "VehicleManagement.View",
        "Customer.View"
      ]
    },
    "additionalActions": null
  },
  {
    "module": {
      "id": 13,
      "name": "Notifications"
    },
    "dependentPermissions": {
      "Notifications.Create": [
        "Customer.View",
        "Driver.View",
        "Corporate.View"
      ]
    },
    "additionalActions": null
  },
  {
    "module": {
      "id": 14,
      "name": "Tenants"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 15,
      "name": "Roles"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 16,
      "name": "Shift"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 17,
      "name": "ChatAndMessaging"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 18,
      "name": "Country"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 19,
      "name": "State"
    },
    "dependentPermissions": {
      "State.Create": [
        "Country.View"
      ],
      "State.Update": [
        "Country.View"
      ]
    },
    "additionalActions": null
  },
  {
    "module": {
      "id": 20,
      "name": "City"
    },
    "dependentPermissions": {
      "City.Create": [
        "State.View"
      ],
      "City.Update": [
        "State.View"
      ]
    },
    "additionalActions": null
  },
  {
    "module": {
      "id": 21,
      "name": "SystemConfigurations"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 22,
      "name": "SystemIntegrations"
    },
    "dependentPermissions": null,
    "additionalActions": null
  },
  {
    "module": {
      "id": 23,
      "name": "Reviews"
    },
    "dependentPermissions": {
      "Reviews.View": [
        "Customer.View",
        "Driver.View"
      ]
    },
    "additionalActions": null
  }
]