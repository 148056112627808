import Config from "../../config";

export const BASE_URL =
  localStorage.getItem("temp-base-url") || Config.env().BASE_URL;
export const TENANT = localStorage.getItem("tenant");
export const DEFAULT_SEARCH_KEY = "AdvancedSearch.Fields";

const API_CONSTANTS = {
  //General
  UPLOADS: {
    post: "/files/upload",
    get: (fileName, isPublic = false, expiryMinutes = "") =>
      `/files/get?fileName=${isPublic ? "[public]" : "[private]"
      }${fileName}&expiryMinutes=${expiryMinutes}`,
  },
  AUTH: {
    login: "/admin/tokens",
    forgotPassword: "/admin/forgot-password",
    verifyOtp: "/admin/verify-token",
    resetPassword: "/admin/reset-password",
    updatePassword: "/admin/change-password",
    setPassword: "/admin/set-password",
    permissions: "/admin/permissions",
    verifySetPassword: "/admin/verify-set-password",
    profile: "/admin/profile",
  },

  //Admin
  USERS: {
    base: "/admin/users", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,statuses}
    dataKeys: [
      "id",
      "userName",
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "imageUrl",
    ],
  },
  DRIVERS: {
    base: "/admin/driver",
    licencePlates: "/admin/driver/license-plates",
    vehicleById: "/admin/driver/vehicle/:id",
    updateInspectionDocuments: "/admin/driver/:id/post-documents",
    balance: "/admin/driver/:driverId/balance",
    adjustBalance: "/admin/driver/adjust-balance",
    lookup: "/admin/driver/lookup?search=:query",
    dispatchLogs: "/admin/driver/:id/dispatch-logs",
    dataKeys: [
      "id",
      "firstName",
      "lastName",
      "phone",
      "email",
      "address",
      "cityId",
      "zipCode",
      "operatingCityId",
      "dob",
      "licenseNo",
      "licenseExpiry",
      "gender",
      "stripeStatus",
      "status",
      "shiftId",
      "note",
      "vehicleStatus",
      "vehicleId",
      "vehicleRequest",
      "localNumber",
      "countryCode",
      "driverShare",
    ],
    searchableKeys: ["name", "email", "phone", "licensePlate"],
  },

  //Admin
  CUSTOMERS: {
    base: "/admin/customer",
    create: "/admin/customer",
    updateImage: "/admin/customer/customer-image",
    getBalance: "/admin/customer/:customerId/balance",
    getPrimaryCard: "/admin/customer/:customerId/get-primary",
    adjustBalance: "/admin/customer/adjust-balance",
    updateStatus: "/admin/customer/:id/update-status",
    searchUserByPhone: "/admin/customer/lookup/:phoneNumber",
    searchUserByPhoneAndCorporateId: "/admin/customer/corporate/:id/lookup",
    getCustomerById: "/admin/customer/:id",
    detailsLookup: "/admin/customer/detail-lookup",
    //cards
    cards: "/admin/customer/:id/list",
    setupIntent: "/admin/customer/:id/setup-intent",
    primaryCard: "/admin/customer/:id/get-primary",
    deleteCard: "/admin/customer/:id/card/:cardId",

    dataKeys: [
      "id",
      "firstName",
      "lastName",
      "localNumber",
      "email",
      "cityId",
      "languageId",
      "cardToken",
      "note",
      "countryCode",
    ],
    searchableKeys: ["name", "email", "phone", "city"],
  },
  SUB_ADMINS: {
    base: "/admin",
    lookup: "/admin/lookup/:query",
    updateImage: "/admin/admin-image",
    updateStatus: "/admin/:id/update-status",
    resendEmail: "/admin/:id/resend-verification-email",
    dataKeys: ["id", "name", "localNumber", "email", "countryCode"],
    searchableKeys: ["name", "email", "phone"],
  },
  CORPORATES: {
    base: "/admin/corporate",
    lookup: "/admin/corporate/lookup/:query",
    create: "/admin/corporate",
    updateImage: "/admin/corporate/corporate-image",
    updateStatus: "/admin/corporate/:id/update-status",
    customersList: "/admin/corporate/:id/customers",
    updateCorporatePay: "/admin/corporate/:corporateId/customer/:customerId/update-corporate-pay?isCorporatePay=:value",
    getBalance: "/admin/corporate/:id/balance",
    adjustBalance: "/admin/corporate/adjust-balance",
    directInvoiceStatus: "/admin/corporate/:id/direct-invoice-status",
    resendEmail: "/admin/corporate/:id/resend-verification-email",

    //cards
    cards: "/admin/corporate/:id/list",
    setupIntent: "/admin/corporate/:id/setup-intent",
    primaryCard: "/admin/corporate/:id/get-primary",
    deleteCard: "/admin/corporate/:id/card/:cardId",

    //corporate customers
    activateCustomer:
      "/admin/corporate/:corporateId/customer/:customerId/activate",
    blockCustomer: "/admin/corporate/:corporateId/customer/:customerId/block",
    deleteCustomer: "/admin/corporate/:corporateId/customer/:customerId",

    dataKeys: [
      "id",
      "companyName",
      "name",
      "localNumber",
      "email",
      "cityId",
      "corporateCode",
      "languageId",
      "countryCode",
    ],
    searchableKeys: ["companyName", "email", "phone", "name"],
  },
  TENANTS: {
    base: "/admin/tenants",
    publicLookup: "/root/tenants/lookup",
    dataKeys: ["id", "name", "adminEmail", "issuer"],
  },
  BOOKINGS: {
    base: "/admin/booking",
    searchVehicleType: "/admin/booking/search",
    availableDrivers: "/admin/booking/available-drivers",
    applyPromoCode: "/admin/promocode/apply",
    trackBooking: "/admin/booking/:id/tracking-link",
    costBreakdown: "/admin/booking/:id/cost-breakdown",
    dispatchLogs: "/admin/booking/dispatch-logs",
    cancelBooking: "/admin/booking/:id/cancel",
    configurations: "/admin/booking/configuration",
    completeBooking: "/admin/booking/:bookingId/complete",
    getEstimatedFare: "/admin/booking/:bookingId/estimated-fare",
    dataKeys: [
      "rideType",
      "rideTime",
      "customerType",
      "firstName",
      "lastName",
      "phone",
      "corporateId",
      "paymentMethod",
      "dispatcherNote",
      "internalNote",
      "bookingStops",
      "quotedFare"
    ],
    searchableKeys: [
      "driverName",
      "passengerName",
      "id",
      "driverPhone",
      "passengerPhone",
      "pickupAddress",
      "dropoffAddress",
    ],
  },
  PROMO_CODES: {
    base: "/admin/promocode",
    updateStatus: "/admin/promocode/:id/update-status",
    searchableKeys: ["title", "code"],
  },
  ZONES: {
    base: "/admin/zone",
    lookup: "/admin/zone/lookup",
    parentZones: "/admin/zone/parent-zones",
    updateStatus: "/admin/zone/:id/update-status",
  },
  ZONE_SURCHARGE: {
    getByZoneId: "/admin/pricing/zone/:zoneId/zone-surcharge",
    save: "/admin/pricing/zone-surcharge",
    delete: "/admin/pricing/zone-surcharge/:id",
  },
  PRICING: {
    base: "/admin/pricing",
    lookup: "/admin/pricing/lookup",
    zoneRateList: "/admin/pricing/zone-wise-rate",
    zoneRate: "/admin/pricing/zone-rate",
    coporateRate: "/admin/pricing/corporate-rate",
    coporateRateSearchableKeys: ["corporateRate", "corporate.name"],
    dataKeys: [
      "id",
      "vehicleTypeId",
      "techFee",
      "nowRidesStartingRate",
      "scheduledRidesStartingRate",
      "nowRidesMinimumFare",
      "scheduledRidesMinimumFare",
      "nowRidesRatePerMinute",
      "scheduledRidesRatePerMinute",
      "perKmRate",
      "perStopRate",
      "cancellationOnDemandRate",
      "cancellationInAdvanceRate",
      "cancellationNoShowRate",
      "status",
      "otherFares",
      "name",
    ],
  },
  PREFERENCES: {
    countries: "/admin/preference/countries",
    states: "/admin/preference/states",
    cities: "/admin/preference/cities",
    languages: "/admin/preference/languages",
    shifts: "/admin/shift",
    files: "/admin/preference/privacy-and-terms",
    updatePrivacyPolicy: "/admin/preference/pivacy-policy",
    updateTerms: "/admin/preference/terms-and-conditions",
    tenantDetails: "/admin/tenant-by-url",
  },
  SHIFTS: {
    base: "/admin/shift",
    lookup: "/admin/shift/lookup",
  },
  CITY: {
    base: "/admin/city",
    lookup: "/admin/city/lookup",
  },
  STATE: {
    base: "/admin/state",
    lookup: "/admin/state/lookup",
  },
  COUNTRY: {
    base: "/admin/country",
    lookup: "/admin/country/lookup",
  },
  COLOR: {
    lookup: "/admin/transport/color/lookup",
    base: "/admin/transport/color"
  },
  MODELS: {
    base: "/admin/transport/model",
    lookup: "/admin/transport/model/lookup",
  },
  MAKES: {
    base: "/admin/transport/make",
    lookup: "/admin/transport/make/lookup",
  },
  SUPPORT: {
    list: "/common/chat/ids",
    join: "/common/chat/:chatId/join",
    leave: "/common/chat/:chatId/left",
    delete: "/common/chat/:chatId",
    listMessages: "/common/chat/list",
    send: "/common/chat/send",
    inbox: "/common/chat/support/inbox",
    issuesInbox: "/common/chat/report-issue/inbox",
    generalInbox: "/common/chat/inbox",
    readChat: "/common/chat/:chatId/read-last-message?messageId=:messageId",
    base: "/common/chat",
    updateChatImage: "/common/chat/chat-image",
    listParticipants: "/common/chat/:chatId/available-participants",
    removeParticipants: "/common/chat/:chatId/remove-participants",
    addParticipants: "/common/chat/:chatId/participants",
    acknowledgeLastMessage: "/common/chat/:chatId/acknowledge-last-message",
    readLastMessage: "/common/chat/:chatId/read-last-message",
  },
  PROFILE: {
    getProfile: "/admin/profile",
    updateProfile: "/admin/profile",
    updateImage: "/admin/admin-image",
    changePassword: "/admin/change-password",
    dataKeys: [
      "email",
      "localNumber",
      "name",
      "countryCode",
      "profilePictureUrl",
    ],
  },
  TRANSPORT: {
    types: "/admin/transport/vehicle-types",
    saveType: "/admin/transport/vehicle-type",
    typesLookup: "/admin/transport/vehicle-types/lookup",
    vehicleTypeById: "/admin/transport/:id",
    updateVehicleTypeImage: "/admin/transport/vehicle-type/image",
    updateVehicleTypeStatus: "/admin/transport/vehicle-type/:id/update-status",
    deleteVehicleType: "/admin/transport/:id",
    vehicles: "/admin/transport/vehicle",
    licencePlates: "/admin/transport/license-plates",
    makes: "/admin/transport/makes",
    colors: "/admin/transport/color/lookup",
    models: "/admin/transport/models",
  },

  NOTIFICATIONS: {
    list: "/common/notification/list",
    sendNotification: "/common/notification/send-notification",
    sendBatchNotification: "/common/notification/send-batch-notifications",
  },

  DASHBOARD: {
    stats: "/admin/dashboard/stats",
    earnings: "/admin/dashboard/earning",
    ratings: "/admin/dashboard/ratings",
    bookings: "/admin/dashboard/finished-bookings",
    graph: "/admin/dashboard/earning-graph",
    finishedBookings: "/admin/dashboard/finished-bookings",
    filterKeys: ["FromDateTime", "TillDateTime", "ZoneId"],
    graphFilterKeys: ["FromDate", "ToDate", "ZoneId", "ViewMode"],
  },

  MAP: {
    ridesDrivers: "/admin/map/rides-drivers",
    driverCount: "/admin/map/driver-count",
    rides: "/admin/map/rides",
  },
  ROLES: {
    list: "/admin/roles",
    addRole: "/admin/roles",
    assignRoles: "/admin/assign-roles",
    permissions: "/admin/roles/permissions",
    dataKeys: ["name", "description", "permissions"],
  },
  TRANSACTIONS: {
    base: "/admin/payment/transaction-logs",
  },
  REPORTS: {
    base: "/admin/reports/booking-detail",
    downloadReport: "/admin/reports/generate-daily-report",
    filterKeys: [
      "AdvancedSearch.Keyword",
      "FromDateTime",
      "TillDateTime",
      "ZoneId",
      "BookingStatus",
      "RideStatus",
      "DriverId",
      "CustomerId",
      "CorporateId",
    ],
    searchableKeys: [
      "id",
      "customerSupportChatId",
      "driverSupportChatId",
      "driverName",
      "driverPhone",
      "passengerName",
      "passengerPhone",
      "corporateName",
      "createdOn",
      "pickupArrival",
      "pickupDeparture",
      "destinationArrival",
      "completedTime",
      "requestedCarType",
      "actualCarType",
      "pickupAddress",
      "originalDestination",
      "actualDestination",
      "estDistance",
      "estDuration",
      "actualDistance",
      "actualDuration",
      "estimatedBaseFare",
      "surcharge",
      "isAirportFee",
      "tollFee",
      "fleetServices",
      "techFee",
      "taxPercentage",
      "taxAmount",
      "tip",
      "total",
      "discount",
      "transactionId",
      "promoCode",
      "payWithWallet",
      "sendReceipt",
    ],
    driverLogs: "/admin/reports/driver-logs",
    downloadDriverLogs: "/admin/reports/generate-driver-logs-report",
    driverLogsFilterKeys: [
      "FromDate",
      "ToDate",
      "DriverId",
      "PageNumber",
      "PageNumber",
      "Sort",
      "SortOrder",
      "AdvancedSearch.Keyword",
      "Keyword",
    ],
    driverLogsSearchableKeys: [
      "id",
      "customerSupportChatId",
      "driverSupportChatId",
      "driverName",
      "driverPhone",
      "passengerName",
      "passengerPhone",
      "corporateName",
      "createdOn",
      "pickupArrival",
      "pickupDeparture",
      "destinationArrival",
      "completedTime",
      "requestedCarType",
      "actualCarType",
      "pickupAddress",
      "originalDestination",
      "actualDestination",
      "estDistance",
      "estDuration",
      "actualDistance",
      "actualDuration",
      "estimatedBaseFare",
      "surcharge",
      "isAirportFee",
      "tollFee",
      "fleetServices",
      "techFee",
      "taxPercentage",
      "taxAmount",
      "tip",
      "total",
      "discount",
      "transactionId",
      "promoCode",
      "payWithWallet",
      "sendReceipt",
    ],
  },
  RATING: {
    base: "/admin/reviews",
    searchableKeys: [],
  },
  SYSTEM_CONFIG: {
    mail: "/admin/systemconfiguration/mail-configuration",
    sms: "/admin/systemconfiguration/sms-configuration",
    booking: "/admin/systemconfiguration/booking-configuration",
  },
  INVOICES: {
    corporateInvoices: "/admin/directinvoice",
    generatePdf: "/admin/directinvoice/:id/generate-pdf",
    updatePaymentStatus: "/admin/directinvoice/payment-status",
    updatePayPeriod: "/admin/directinvoice/pay-period",
  }
};

export default API_CONSTANTS;
