import {
    useQuery,
    useMutation,
    useQueryClient
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS from "../constants/api";
import { MILISECONDS } from "../constants";

export const useStates = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["states", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.STATE.base}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true,
    })
}

export const useCreateState = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: API_CONSTANTS.STATE.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["states-lookup"])
            queryClient.invalidateQueries(["states"])
        }
    })
}

export const useUpdateState = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Put({
                path: `${API_CONSTANTS.STATE.base}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["states-lookup"])
            queryClient.invalidateQueries(["states"])
        }
    })
}

export const useDeleteState = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.STATE.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["states-lookup"])
            queryClient.invalidateQueries(["states"])
        }
    })
}

export const useStatesLookup = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["states-lookup"],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.STATE.lookup,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,
        keepPreviousData: true,
    })
}