import { useEffect, useMemo, useState } from "react";
import {
  HStack,
  useColorMode,
  useDisclosure,
  chakra,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import {
  useApplyPromoCode,
  useAvailableDrivers,
  useCorporateBookingCards,
  useCustomerBookingCards,
  useGetCustomerById,
  useSearchUserByPhone,
  useSearchVehicleType,
} from "../../../../config/query/transportQuery";
import {
  CUSTOMER_TYPES,
  PAYMENT_TYPES,
  RideType,
} from "../../../../config/constants/enums";
import generateString from "../../../../config/helpers/generateString";
import VehicleCard from "../../../../components/BasicUI/DataBoxes/VehicleCard";
import useDebounce from "../../../../config/hooks/useDebounce";
import { useCorporateOptions } from "../../../../config/query/corporateQuery";
import {
  breakWithCaps,
  removePlusSign,
} from "../../../../config/helpers/stringHelper";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import APP_ICONS from "../../../../config/constants/icons";
import { getPromoCodeStatus } from "../../../../config/helpers/formHelpers/bookingHelper";
import { useCustomerDetailLookup } from "../../../../config/query/customerQuery";
import { formatDateTimeWithoutConvertingToUtc } from "../../../../config/helpers/dateHelper";

function makeVehicleList(resdata) {
  return resdata.reduce((accumulator, quotation) => {
    const vehicles = quotation.vehicles || [];
    accumulator.push(...vehicles);
    return accumulator;
  }, []);
}

export const useBookingInfo = ({
  watch,
  infoChanged,
  setValue,
  enabledFields,
}) => {
  const { colorMode } = useColorMode();
  const searchVehicleType = useSearchVehicleType();

  const [seasons, setSeasons] = useState([]);

  const {
    stops,
    rideType,
    rideTime,
    pickup,
    destination,
    vehicleTypeId,
    tempRideTime,
    userId,
    corporateId,
    paymentMethod,
    promoCodeId,
    quotedFare,
    customerType,
  } = watch();

  const stopsString = JSON.stringify(stops);

  useEffect(() => {
    if (infoChanged) {
      setValue("vehicleTypeId", null);
    }

    const isAllDefined =
      typeof rideType !== "undefined" &&
      (rideType ? typeof rideTime !== "undefined" : true) &&
      typeof pickup !== "undefined" &&
      typeof destination !== "undefined";

    if (isAllDefined) {
      const defaultRideType = rideType ? RideType.SCHEDULED : RideType.NOW;
      const defaultRideTime =
        typeof rideTime !== "undefined"
          ? formatDateTimeWithoutConvertingToUtc(rideTime)
          : formatDateTimeWithoutConvertingToUtc(new Date().toString());

      const rideStops = [
        pickup.latLng,
        ...(stops?.length > 0 ? stops.map((stop) => stop.latLng) : []),
        destination.latLng,
      ];

      if (!rideStops.some((value) => !value)) {
        const value = {
          rideType: defaultRideType,
          rideTime: defaultRideTime,
          rideStops: rideStops,
          customerId: userId,
          promoCodeId,
        };

        let isMounted = true; // To handle cleanup if component unmounts

        searchVehicleType
          .mutateAsync(value)
          .then((res) => {
            if (isMounted) {
              const resdata = res.vehicleQuotations || [];
              if (Array.isArray(resdata) && resdata.length > 0) {
                const allVehicles = makeVehicleList(resdata);
                setSeasons(allVehicles);
              }
            }
          })
          .catch((err) => {
            if (isMounted) {
              console.warn("Error while creating vehicle class:", err);
            }
          });

        // Cleanup function to cancel any pending requests if the component unmounts
        return () => {
          isMounted = false;
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stopsString,
    rideType,
    rideTime,
    pickup,
    destination,
    userId,
    corporateId,
    paymentMethod,
    promoCodeId,
    customerType,
  ]);

  const addStop = () => {
    if (stops?.length >= 3) return;
    const newStops = [
      ...stops,
      {
        address: "",
        latitute: null,
        longitude: null,
        tempId: generateString(10),
      },
    ];
    setValue("stops", newStops);
  };

  const removeStop = (id, current) => {
    //if stop is added on client and not saved yet
    const newStops = stops.filter((item) => {
      if (item.tempId) {
        return item.tempId !== id;
      } else {
        return JSON.stringify(item) !== JSON.stringify(current);
      }
    });
    setValue("stops", newStops);
  };

  const renderVehicleList = (vehicleTypes) => {
    if (
      enabledFields?.everyField ? false : !enabledFields?.fields?.vehicleTypeId
    ) {
      const selectedVehicle = vehicleTypes.find(
        (vehicle) => Number(vehicleTypeId) === Number(vehicle.vehicleTypeId)
      );
      return (
        <VehicleCard
          isActive={true}
          vehicle={selectedVehicle}
          isDisabled={true}
        />
      );
    }

    return vehicleTypes.map((vehicle, index) => {
      if (
        vehicleTypeId === vehicle.vehicleTypeId &&
        quotedFare !== vehicle.fare
      ) {
        setValue("quotedFare", vehicle.fare);
      }
      return (
        <VehicleCard
          isActive={Number(vehicleTypeId) === Number(vehicle.vehicleTypeId)}
          onClick={() => {
            setValue("vehicleTypeId", vehicle.vehicleTypeId);
            setValue("quotedFare", vehicle.fare);
          }}
          vehicle={vehicle}
          key={index}
        />
      );
    });
  };

  return {
    addStop,
    removeStop,
    renderVehicleList,
    seasons,
    colorMode,
    stops,
    rideType,
    tempRideTime,
  };
};

export const usePassengerInfo = ({ watch, setValue }) => {
  const formDisclosure = useDisclosure();
  const driverDisclosure = useDisclosure();
  const { colorMode } = useColorMode();

  const [corporateQuery, setCorporateQuery] = useState("");
  const debouncedCorporateQuery = useDebounce(corporateQuery);

  const [driverQuery, setDriverQuery] = useState({});
  const debouncedDriverQuery = useDebounce(driverQuery);

  const [addNewUsers, setAddNewUsers] = useState(false);
  const [availableDriversData, setAvailableDriversData] = useState([]);
  const corporates = useCorporateOptions(debouncedCorporateQuery);
  const availableDrivers = useAvailableDrivers(debouncedDriverQuery);
  const [customerQuery, setCustomerQuery] = useState({
    Search: "",
    PageSize: 50,
  });
  const debouncedCustomerQuery = useDebounce(customerQuery);
  const customerLookup = useCustomerDetailLookup(debouncedCustomerQuery);

  const {
    localNumber,
    dispatchType,
    pickup,
    vehicleTypeId,
    rideType,
    rideTime,
    destination,
    stops,
  } = watch();
  const fields = watch();

  const stopsString = JSON.stringify(stops);

  const searchUserByPhoneQuery = useSearchUserByPhone(
    removePlusSign(localNumber)
  );
  const getCustomerById = useGetCustomerById();

  const renderAddNewUser = (addNewUsers) => {
    if (addNewUsers === true) {
      return (
        <HStack justify="space-between">
          <HStack spacing={1}>
            <chakra.p
              color={getColor(colorKeys.dimBlue, colorMode)}
              fontSize="13px"
            >
              User was not found registered with this number, Please add new.
            </chakra.p>
            <IconButton
              size={"sm"}
              bg={getColor(colorKeys.primaryButtonFill, colorMode)}
              onClick={() => formDisclosure.onOpen()}
              color={getColor(colorKeys.white, colorMode)}
              aria-label="Search database"
              icon={<Icon boxSize={7} as={APP_ICONS.ADD} />}
            />
          </HStack>
        </HStack>
      );
    }
  };

  const handleAddUser = (data, response) => {
    const phone = response?.phone;
    onUpdateCustomerQuery({ Search: phone });
    setValue("localNumber", phone);
    getUserByPhone();
  };

  const getUserByPhone = () => {
    searchUserByPhoneQuery
      .mutateAsync(removePlusSign(localNumber))
      .then((res) => {
        if (res.data?.length > 0) {
          setCustomerQuery((prev) => ({
            ...prev,
            Search: localNumber,
          }));
          getCustomerById
            .mutateAsync(res.data[0].id)
            .then((res) => {
              setValue("userId", res.id);
              setValue("email", res.email);
              setValue("firstName", res.firstName);
              setValue("lastName", res.lastName);
              setValue("customerId", res.id);
              if (res.corporate?.id) {
                setValue("isCorporatePay", res.isCorporatePay);
                setValue("corporateId", res.corporate?.id);
                setCorporateQuery(res.corporate?.name);
                setValue("customerType", CUSTOMER_TYPES.Corporate);
                setValue(
                  "corporatePaymentType",
                  res?.corporate?.corporatePaymentType?.id
                );
              } else {
                setValue("isCorporatePay", false);
                setValue("corporateId", null);
                setValue("customerType", CUSTOMER_TYPES.Individual);
                setValue("corporatePaymentType", null);
              }
              setAddNewUsers(false);
            })
            .catch((err) => console.warn(err));
        } else {
          setValue("userId", null);
          setValue("email", "");
          setValue("firstName", "");
          setValue("lastName", "");
          setValue("customerId", null);
          setAddNewUsers(true);
        }
      })
      .catch((err) => console.warn(err));
  };

  const availableDriversApi = () => {
    //make bookingStops array
    const stopsValues = stops?.map((item) => item.latLng);
    let bookingStops = [];

    if (pickup) bookingStops.push(pickup.latLng);
    if (stopsValues?.length > 0) bookingStops.push(...stopsValues);
    if (destination) bookingStops.push(destination.latLng);

    //delete anykey with undefined type value
    bookingStops = Object.keys(bookingStops).reduce((acc, key) => {
      if (
        typeof bookingStops[key] !== "undefined" &&
        bookingStops[key] !== null
      ) {
        acc.push({ lat: bookingStops[key].lat, lng: bookingStops[key].lng });
      }
      return acc;
    }, []);

    const payload = {
      rideType: rideType ? RideType.SCHEDULED : RideType.NOW,
      rideTime: rideTime
        ? formatDateTimeWithoutConvertingToUtc(rideTime)
        : formatDateTimeWithoutConvertingToUtc(new Date().toString()),
      pickupLocation: pickup?.latLng,
      bookingStops,
      vehicleTypeId,
      search: debouncedDriverQuery?.search,
    };

    availableDrivers
      .mutateAsync(payload)
      .then((res) => {
        setAvailableDriversData(res?.data);
      })
      .catch((err) => console.warn(err));
  };

  const onUpdateDriverQuery = (updatedQuery) => {
    setDriverQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  const onUpdateCustomerQuery = (updatedQuery) => {
    setCustomerQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  const onUpdateCorporateQuery = (updatedQuery) => {
    setCorporateQuery(updatedQuery);
  };

  useEffect(() => {
    if (typeof localNumber !== "undefined" && localNumber.length > 11) {
      getUserByPhone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localNumber]);

  useEffect(() => {
    if (typeof pickup !== "undefined" && Boolean(dispatchType) === true) {
      if (typeof vehicleTypeId !== "undefined" || vehicleTypeId !== null) {
        onUpdateDriverQuery({ vehicletypeId: vehicleTypeId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickup, dispatchType, vehicleTypeId]);

  useEffect(() => {
    if (pickup && dispatchType && vehicleTypeId && rideType !== undefined) {
      availableDriversApi(pickup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedDriverQuery?.search,
    pickup,
    destination,
    dispatchType,
    rideType,
    rideTime,
    vehicleTypeId,
    stopsString,
  ]);

  return {
    formDisclosure,
    driverDisclosure,
    renderAddNewUser,
    handleAddUser,
    availableDriversData,
    onUpdateDriverQuery,
    onUpdateCorporateQuery,
    corporates,
    addNewUsers,
    colorMode,
    fields,
    corporateQuery,
    localNumber,
    pickup,
    vehicleTypeId,
    rideType,
    availableDrivers,
    onUpdateCustomerQuery,
    customerQuery,
    customerLookup,
    isLoadingCustomer: getCustomerById.isLoading,
  };
};

export const useTipPromo = ({ watch, paymentChanged, setValue, data }) => {
  const { colorMode } = useColorMode();
  const customerCardDisclosure = useDisclosure();
  const corporateCardDisclosure = useDisclosure();
  const {
    pickup,
    destination,
    userId,
    paymentMethod,
    externalWalletType,
    promoCode,
    vehicleTypeId,
    promoCodeId,
    promoCodeData,
    customerType,
    corporateId,
    corporatePaymentType,
  } = watch();

  const customerCardsQuery = useCustomerBookingCards();
  const corporateCardsQuery = useCorporateBookingCards();
  const applyPromoCodeQuery = useApplyPromoCode();
  const [cardDetails, setCardDetails] = useState([]);

  const getCards = () => {
    let externalWalletObj = {};

    if (typeof externalWalletType?.id !== "undefined") {
      externalWalletObj = {
        id: externalWalletType.id,
        brand: breakWithCaps(externalWalletType.name),
        lastFourDigits: "",
        isPrimary: true,
      };
    }

    if (
      Number(customerType) === CUSTOMER_TYPES.Corporate &&
      Number(paymentMethod) === PAYMENT_TYPES.CorporateCard
    ) {
      corporateCardsQuery
        .mutateAsync(corporateId)
        .then((res) => {
          let data = res.data;

          if (
            Array.isArray(data) &&
            typeof externalWalletObj?.id !== "undefined"
          ) {
            data.push(externalWalletObj);
          }
          setCardDetails(data);

          if (paymentChanged) {
            setValue("creditCardId", null);
          }
        })
        .catch((err) => console.warn(err));
    } else {
      customerCardsQuery
        .mutateAsync(userId)
        .then((res) => {
          let data = res.data;

          if (
            Array.isArray(data) &&
            typeof externalWalletObj?.id !== "undefined"
          ) {
            data.push(externalWalletObj);
          }
          setCardDetails(data);

          if (paymentChanged) {
            setValue("creditCardId", null);
          }
        })
        .catch((err) => console.warn(err));
    }
  };

  const onCardFormSuccess = () => {
    setTimeout(() => {
      getCards();
    }, 2000);
  };

  const onAddCard = () => {
    if (
      Number(customerType) === CUSTOMER_TYPES.Corporate &&
      Number(paymentMethod) === PAYMENT_TYPES.CorporateCard
    ) {
      corporateCardDisclosure.onOpen();
    } else {
      customerCardDisclosure.onOpen();
    }
  };

  const applyPromoCode = () => {
    const payload = {
      customerId: userId,
      pickup: pickup?.latLng,
      dropOff: destination?.latLng,
      promoCode: promoCode,
      vehicleTypeId: vehicleTypeId,
      paymentMethod: paymentMethod,
    };
    if (userId !== "undefined" && userId !== null) {
      applyPromoCodeQuery
        .mutateAsync(payload)
        .then((res) => {
          if (res) {
            setValue("promoCodeId", res?.promoCodeId);
            setValue("promoCodeData", {
              id: res?.promoCodeId,
              name: promoCode,
            });
          }
        })
        .catch((error) => console.warn(error));
    }
  };

  const removePromoCode = () => {
    setValue("promoCodeId", null);
    setValue("promoCodeData", null);
  };

  useEffect(() => {
    if (paymentChanged) {
      setValue("creditCardId", null);
    }
    if (
      typeof userId !== "undefined" &&
      userId !== null &&
      typeof paymentMethod !== "undefined" &&
      (Number(paymentMethod) === PAYMENT_TYPES.Card ||
        Number(paymentMethod) === PAYMENT_TYPES.CorporateCard)
    ) {
      getCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, paymentMethod, customerType]);

  const promoCodeStatus = useMemo(() => {
    return getPromoCodeStatus({ promoCode, promoCodeData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCodeId, promoCode, data?.promoCode?.name]);

  return {
    customerCardDisclosure,
    corporateCardDisclosure,
    onCardFormSuccess,
    onAddCard,
    applyPromoCode,
    removePromoCode,
    promoCodeStatus,
    colorMode,
    pickup,
    destination,
    userId,
    paymentMethod,
    externalWalletType,
    promoCode,
    vehicleTypeId,
    customerType,
    corporateId,
    corporatePaymentType,
    cardDetails,
    customerCardsQuery,
    applyPromoCodeQuery,
  };
};
