import { Avatar, Badge, Box, Flex, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { formatDateTimeFromNow } from "../../../config/helpers/dateHelper";
import { trimLargeString } from "../../../config/helpers/stringHelper";

const ChatItem = ({ chatItem, onClick }) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      p="8px"
      w="full"
      cursor={"pointer"}
      role="group"
      onClick={onClick}
      _hover={{ bg: getColor(colorKeys.primaryButtonFillHover, colorMode) }}
      bg={getColor(colorKeys.white, colorMode)}
    >
      <Avatar size="sm" src={chatItem?.chatImage} name={chatItem?.chatTitle} />
      <Box w="full" ml={2}>
        <Flex align={"center"} justify={"space-between"} w="full">
          <Box>
            <Text fontSize={"13px"} fontWeight={"bold"}>
              {chatItem?.chatTitle || "No Name"}
            </Text>
            {chatItem?.thumbnailImageUrl ? (
              <Text fontSize={"13px"}>Image</Text>
            ) : (
              <Text fontSize={"13px"} maxW="full">
                {chatItem?.text
                  ? trimLargeString(chatItem?.text, 40)
                  : "No messages yet"}
              </Text>
            )}
          </Box>
          <Flex flexDir="column" align="end">
            <Text fontSize={"12px"}>
              {formatDateTimeFromNow(chatItem?.createdAt)}
            </Text>
            {chatItem?.unReadCount  ? (
              <Badge
                mt="2px"
                w="20px"
                h="20px"
                rounded="full"
                colorScheme="blue"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {chatItem?.unReadCount}
              </Badge>
            ) : null}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ChatItem;
