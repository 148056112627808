import React from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../../config/constants/appColors";
import FormSearchSelect from "../../../../components/Forms/FormSearchSelect";
import FormInput from "../../../../components/Forms/FormInput";
import { CUSTOMER_TYPES, PAYMENT_TYPES } from "../../../../config/constants/enums";
import FormTextarea from "../../../../components/Forms/FormTextarea";
import { getPaymentMethods } from "../../../../config/helpers/formHelpers/bookingHelper";
import CorporateCardForm from "../../../../views/admin/Corporate/CorporateCards/CardForm";
import CustomerCardForm from "../../../../views/admin/Customers/CustomerForm/CardForm";
import { useTipPromo } from "./bookingFormContainer";

const TipPromo = ({
  control,
  errors,
  watch,
  setValue,
  paymentChanged,
  enabledFields,
  data,
}) => {

  const {
    customerCardDisclosure,
    corporateCardDisclosure,
    onCardFormSuccess,
    onAddCard,
    applyPromoCode,
    removePromoCode,
    promoCodeStatus,
    colorMode,
    pickup,
    destination,
    userId,
    paymentMethod,
    externalWalletType,
    promoCode,
    vehicleTypeId,
    customerType,
    corporateId,
    corporatePaymentType,
    cardDetails,
    customerCardsQuery,
    applyPromoCodeQuery,
  } = useTipPromo({
    watch,
    paymentChanged,
    setValue,
    data
  })

  return (
    <Card>
      <CardHeader
        p="15px 18px 0px"
        bg={getColor(colorKeys.tableBackground, colorMode)}
      >
        <Heading size="md">Payment Info / Notes</Heading>
      </CardHeader>
      <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
        <VStack spacing={5} align="stretch">
          <FormSearchSelect
            id="paymentMethod"
            options={getPaymentMethods({
              corporatePaymentType,
              customerType,
              isCorporatePay: watch("isCorporatePay"),
            })}
            label={"Payment Method"}
            control={control}
            errors={errors}
            required={true}
            placeholder="Select payment method"
            inputProps={{
              size: "sm",
              isDisabled: enabledFields?.everyField
                ? false
                : !enabledFields?.fields?.paymentMethod,
            }}
          />

          {
            typeof paymentMethod !== "undefined" &&
              (Number(paymentMethod) === PAYMENT_TYPES.Card || Number(paymentMethod) === PAYMENT_TYPES.CorporateCard) ? (

              <FormSearchSelect
                // id={externalWalletType === null ? "creditCardId" : "externalWalletTypeId"}
                id={"creditCardId"}
                options={
                  cardDetails.map((item) => ({
                    value: item.id,
                    label: item.brand + " " + item.lastFourDigits,
                  })) || []
                }
                label={Number(paymentMethod) === PAYMENT_TYPES.CorporateCard ? "Corporate Cards" : "Customer Cards"}
                control={control}
                errors={errors}
                required={true}
                placeholder="Select Card"
                inputProps={{
                  size: "sm",
                  // isDisabled: enabledFields?.everyField
                  //   ? false
                  //   : !enabledFields?.fields?.creditCardId,
                  isDisabled: enabledFields?.everyField
                    ? false
                    : !enabledFields?.fields?.paymentMethod,
                }}
                labelExtention={
                  (Number(customerType) === CUSTOMER_TYPES.Corporate && corporateId) || (Number(customerType) === CUSTOMER_TYPES.Individual && userId) ? (
                    <Button
                      // isDisabled={enabledFields?.everyField
                      //   ? false
                      //   : !enabledFields?.fields?.creditCardId}
                      isDisabled={enabledFields?.everyField
                        ? false
                        : !enabledFields?.fields?.paymentMethod}
                      size="xs"
                      onClick={onAddCard}
                    >
                      Add Card
                    </Button>
                  ) : null
                }
                isLoading={customerCardsQuery.isLoading}
              />
            ) : null}

          {userId &&
            pickup?.latLng &&
            destination?.latLng &&
            vehicleTypeId &&
            (paymentMethod || paymentMethod === 0) && (
              <Box>
                <FormInput
                  label={"Add Promo Code"}
                  control={control}
                  errors={errors}
                  id="promoCode"
                  placeholder="Enter promo code"
                  inputProps={{
                    size: "sm",
                    isDisabled: enabledFields?.everyField
                      ? false
                      : !enabledFields?.fields?.promoCode,
                    isReadOnly: !promoCodeStatus && promoCode?.length > 0,
                  }}
                />

                {promoCode?.length > 0 && (
                  <Flex justify="flex-end">
                    <Button
                      isLoading={applyPromoCodeQuery.isLoading}
                      onClick={
                        promoCodeStatus ? applyPromoCode : removePromoCode
                      }
                      size={"sm"}
                      fontSize={"13px"}
                      colorScheme={promoCodeStatus ? "gray" : "red"}
                      isDisabled={
                        enabledFields?.everyField
                          ? false
                          : !enabledFields?.fields?.promoCode
                      }
                    >
                      {promoCodeStatus ? "Apply" : "Remove"}
                    </Button>
                  </Flex>
                )}
              </Box>
            )}

          <FormTextarea
            id="dispatcherNote"
            label={"Dispatcher Notes"}
            control={control}
            errors={errors}
            placeholder="Enter dispatcher notes"
            inputProps={{
              size: "sm",
              isDisabled: enabledFields?.everyField
                ? false
                : !enabledFields?.fields?.dispatcherNote,
            }}
            rules={{
              validate: (value) => {
                if (value) {
                  if (value.length > 250) {
                    return "Max 250 characters allowed"
                  }
                }
              }
            }}
          />

          <FormTextarea
            id="internalNote"
            label={"Internal Notes"}
            control={control}
            errors={errors}
            placeholder="Enter internal notes"
            inputProps={{
              size: "sm",
              isDisabled: enabledFields?.everyField
                ? false
                : !enabledFields?.fields?.internalNote,
            }}
            rules={{
              validate: (value) => {
                if (value) {
                  if (value.length > 250) {
                    return "Max 250 characters allowed"
                  }
                }
              }
            }}
          />
        </VStack>
      </CardBody>
      <CorporateCardForm
        disclosure={corporateCardDisclosure}
        onSuccess={onCardFormSuccess}
        data={{ id: corporateId }}
      />
      <CustomerCardForm
        disclosure={customerCardDisclosure}
        onSuccess={onCardFormSuccess}
        data={{ id: userId }}
      />
    </Card>
  );
};

export default TipPromo;
