import { Box, Checkbox, Icon, Text, useColorMode } from '@chakra-ui/react'
import React from 'react'
import APP_ICONS from '../../../config/constants/icons'
import { colorKeys, getColor } from '../../../config/constants/appColors'

const RideStatsBox = ({ label, iconColor, count, value, onChange }) => {
    const { colorMode } = useColorMode()
    return (
        <Checkbox
            className='ride-stats-checkbox'
            transition={"all .2s ease-in-out"}
            icon={value
                ? <Icon
                    as={APP_ICONS.CHECK}
                    bg={iconColor}
                    boxSize={"4"}
                    rounded={"sm"}
                />
                : <Box
                    bg={iconColor}
                    boxSize={"4"}
                    rounded={"sm"}
                />
            }
            isChecked={value}
            onChange={(e) => onChange(e.target.checked)}
            rounded={"full"}
        >
            <Text mr="2" fontSize="14px" color={getColor(colorKeys.secondaryText, colorMode)}>{label} ({count})</Text>
        </Checkbox>
    )
}

export default RideStatsBox