import { Box, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import CardBox from '../../../../components/Payments/CardBox'
import { useCustomerCards, useDeleteCustomerCard } from '../../../../config/query/customerQuery'
import TableHeaderOptions from '../../../../components/BasicUI/CustomTable/TableHeaderOptions'
import CardForm from './CardForm'
import NoResults from '../../../../components/SVGComponents/NoResults'

const CustomerCards = ({ data }) => {

    const cardFormDisclosure = useDisclosure()

    const customerCardsQuery = useCustomerCards(data?.id)
    const deleteCustomerCard = useDeleteCustomerCard()

    const onDelete = (item) => {
        deleteCustomerCard
            .mutateAsync({ customerId: data?.id, cardId: item.id })
            .then(() => customerCardsQuery.refetch())
            .catch((err) => console.warn(err))
    }

    const onCardFormSuccess = () => {
        setTimeout(() => {
            customerCardsQuery.refetch()
        }, 1000)
    }
    return (
        <Box>
            <TableHeaderOptions
                title={"Manage Customer Cards"}
                actionText={"Add Card"}
                action={cardFormDisclosure.onOpen}
                titleProps={{ fontSize: "18px" }}
                actionProps={{ size: "sm" }}
            />
            <SimpleGrid mt={5} spacing={5} columns={{ base: 1, md: 3 }}>
                {customerCardsQuery.data?.data?.map((item) =>
                    <CardBox key={item.id} onDelete={() => onDelete(item)} data={item} />
                )}
            </SimpleGrid>

            {!customerCardsQuery.data?.data?.length && (
                <Box mt={5} maxW="450px" m="auto">
                    <NoResults />
                </Box>
            )}

            <CardForm disclosure={cardFormDisclosure} data={data} onSuccess={onCardFormSuccess} />
        </Box>
    )
}

export default CustomerCards