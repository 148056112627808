import {
    useQuery,
    useMutation,
    useQueryClient
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS from "../constants/api";
import { MILISECONDS } from "../constants";
import { CACHE_KEYS } from "../constants/cacheKeys";

export const useMakes = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: CACHE_KEYS.MAKES.base(params),
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.MAKES.base}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true,
    })
}

export const useMake = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: CACHE_KEYS.MAKES.single(id),
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.MAKES.base}/${id}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        enabled: Boolean(id)
    })
}

export const useCreateMake = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: API_CONSTANTS.MAKES.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => {
            queryClient.invalidateQueries(CACHE_KEYS.MAKES.index)
            queryClient.invalidateQueries(CACHE_KEYS.MAKES.lookup)
        }
    })
}

export const useUpdateMake = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Put({
                path: `${API_CONSTANTS.MAKES.base}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => {
            queryClient.invalidateQueries(CACHE_KEYS.MAKES.index)
            queryClient.invalidateQueries(CACHE_KEYS.MAKES.lookup)
        }
    })
}

export const useDeleteMake = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.MAKES.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
        onSuccess: () => {
            queryClient.invalidateQueries(CACHE_KEYS.MAKES.index)
            queryClient.invalidateQueries(CACHE_KEYS.MAKES.lookup)
        }
    })
}

export const useMakeLookup = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["makes-lookup"],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.MAKES.lookup,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,
        keepPreviousData: true,
    })
}