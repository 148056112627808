import React from "react";
import { Button, Icon, useColorMode } from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";

const EmailBox = ({ value }) => {
  const { colorMode } = useColorMode();
  return (
    <Button
      fontSize={"14px"}
      fontWeight={"normal"}
      color={getColor(colorKeys.primaryText, colorMode)}
      variant={"link"}
      as={"a"}
      href={`mailto:${value}`}
      leftIcon={<Icon as={APP_ICONS.EMAIL} />}
      target="_blank"
    >
      {value}
    </Button>
  );
};

export default EmailBox;
