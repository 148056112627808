import moment from "moment";
import { colorKeys, getColor } from "../constants/appColors";
import { PARTICIPANT_TYPES } from "../constants/enums";
import { formatDateTimeForMessage } from "./dateHelper"

export const retrieveConnectionInfo = (connectionObj) => {
    connectionObj = JSON.stringify(connectionObj);
    connectionObj = JSON.parse(connectionObj);
    return connectionObj?.connection || false
}

export const prepareMessagePayload = (message, chatId) => {
    return {
        MessageType: 0,
        Text: message,
        ChatId: chatId,
        CreatedAt: formatDateTimeForMessage(),
        tempDateTime: new Date(),
    }
}

export const prepareAttatchmentPayload = (attatchment, chatId) => {
    return {
        MessageType: 0,
        DocumentFile: attatchment,
        ChatId: chatId,
        CreatedAt: formatDateTimeForMessage(),
        tempDateTime: new Date(),
    }
}

export const prepareTemporaryMessageObject = (message, chatId, tempDateTime, userId) => {
    return {
        chatId: chatId,
        _id: null,
        text: message,
        messageType: {
            id: 0,
            name: "Message",
        },
        createdAt: tempDateTime,
        status: null,
        user: {
            _id: userId,
        },
    }
}

// temporary object for attatchment
export const prepareTemporaryAttatchmentObject = (image, chatId, tempDateTime, userId) => {
    return {
        chatId: chatId,
        _id: null,
        text: null,
        messageType: {
            id: 0,
            name: "Message",
        },
        thumbnailImageUrl: URL.createObjectURL(image),
        createdAt: tempDateTime,
        status: null,
        user: {
            _id: userId,
        },
    }
}

// creating payload for group chat post api
export const prepareChatPayload = (values) => {
    let payload = {
        title: values.title,
        description: values.description,
        participants: []
    }

    let drivers = values.driverParticipants?.map((item) => ({ id: item.value, type: PARTICIPANT_TYPES.Driver })) || []
    let admins = values.adminParticipants?.map((item) => ({ id: item.value, type: PARTICIPANT_TYPES.Admin })) || []

    payload.participants = [...drivers, ...admins]

    return payload
}

// preparing participants array to show in memebers list modal
export const getParticipants = (participants = []) => {
    let drivers = []
    let admins = []

    participants?.forEach((item) => {
        const splitted = item._id?.split("_")
        const obj = { label: item.name, value: Number(splitted?.at(splitted?.length - 1)) }
        switch (splitted?.at(0)) {
            case PARTICIPANT_TYPES.Driver:
                drivers.push(obj)
                break;
            case PARTICIPANT_TYPES.Admin:
                admins.push(obj)
                break;
            default:
                break;
        }
    })

    return {
        drivers,
        admins
    }
}

export const getSaveMembersButtonProps = (state, colorMode) => {
    if (!state) {
        return {
            bg: getColor(colorKeys.danger, colorMode),
            _hover: {
                bg: getColor(colorKeys.danger, colorMode),
                opacity: 0.8,
            },
            _active: {
                bg: getColor(colorKeys.danger, colorMode),
                opacity: 0.8,
            },
        }
    }
    return {
        bg: getColor(colorKeys.primaryButtonFill, colorMode),
        _hover: {
            bg: getColor(colorKeys.primaryButtonFill, colorMode),
            opacity: 0.8,
        },
        _active: {
            bg: getColor(colorKeys.primaryButtonFill, colorMode),
            opacity: 0.8,
        },
    }
}

export const isChatOpen = ({ pathname, openedChats }) => {
    if (pathname.includes("chats")) {
        return true
    }
    else {
        return openedChats?.length > 0
    }
}

export const prepareNewChatObject = ({ chatId, chatTitle, imageUrl }) => {
    return {
        "_id": null,
        "chatId": chatId,
        "text": null,
        "chatType": {
            "id": 3,
            "name": "Custom"
        },
        "isMute": false,
        "messageType": null,
        "createdAt": moment().toISOString(),
        "imageUrl": null,
        "thumbnailImageUrl": null,
        "unReadCount": 0,
        "chatTitle": chatTitle,
        "chatImage": imageUrl,
        "rating": null,
        "rideId": null,
        "user": null
    }

}