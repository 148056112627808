import React from "react";
import { Button, Icon, useColorMode } from "@chakra-ui/react";
import { formatPhoneNumberForDisplay } from "../../../config/helpers/stringHelper";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";

const PhoneBox = ({ value }) => {
  const { colorMode } = useColorMode();
  return (
    <Button
      minW="120px"
      fontSize={"14px"}
      fontWeight={"normal"}
      color={getColor(colorKeys.primaryText, colorMode)}
      variant={"link"}
      as={"a"}
      href={`tel:${value}`}
      leftIcon={<Icon as={APP_ICONS.CALL} />}
    >
      {formatPhoneNumberForDisplay(value)}
    </Button>
  );
};

export default PhoneBox;
