import React, { useRef, useEffect } from "react";
import { Box, Card, CardBody, useColorMode, Divider } from "@chakra-ui/react";
import { useParams } from "react-router";
import MessagesList from "../Messages/MessagesList";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import useChat from "../../../config/containers/chats/useChat";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import { useGeneralInboxById } from "../../../config/query/supportQuery";
import { useDispatch, useSelector } from "react-redux";
import { updateChats } from "../../../config/redux/slices/chatSlice";

const Conversation = () => {
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();
  const { chatId } = useParams();
  const messageListRef = useRef();

  const chats = useSelector((state) => state.chat.chats);
  const setChats = (data) => dispatch(updateChats(data));

  const chatQuery = useGeneralInboxById(chatId);

  const {
    handleScroll,
    handleSendImage,
    handleSendMessage,
    setInputMessage,
    inputMessage,
    isFetchingNextPage,
    isLoading,
    messages,
    leaveChatRoom,
  } = useChat({ messageListRef, chatId: Number(chatId) });

  useEffect(() => {
    let UpdatedChatCounts = chats.map((val) => {
      let data = { ...val };
      if (Number(data.chatId) === Number(chatId)) {
        data.unReadCount = 0;
        return data;
      }
      return data;
    });
    setChats(UpdatedChatCounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  return (
    <Box>
      <Card>
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
          <ChatHeader
            leaveChatRoom={leaveChatRoom}
            chatQuery={chatQuery}
            chat={chatQuery.data?.data}
          />
          <Divider />
          <MessagesList
            ref={messageListRef}
            messages={messages}
            isFetchingNextPage={isFetchingNextPage || isLoading}
            handleScroll={handleScroll}
          />
          <Divider />
          <ChatFooter
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleSendMessage={handleSendMessage}
            handleSendImage={handleSendImage}
          />
        </CardBody>
      </Card>
    </Box>
  );
};

export default Conversation;
