import {
    useQuery,
    useMutation
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Post } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS from "../constants/api";
import { CONTENT_TYPE } from "../constants";

export const useNotifications = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["notifications", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.NOTIFICATIONS.list}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useCreateNotification = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.NOTIFICATIONS.sendBatchNotification,
                token,
                body,
                contentType: CONTENT_TYPE.FORM_DATA,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}