import { extendTheme } from '@chakra-ui/react'
import { MultiSelectTheme } from 'chakra-multiselect'

const config = {
    initialColorMode: "light",
    useSystemColorMode: localStorage.getItem('systemColorMode') || false,
};

export const theme = extendTheme({
    config,
    components: {
        MultiSelect: MultiSelectTheme
    }
});