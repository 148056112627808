import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    SimpleGrid,
} from '@chakra-ui/react'
import { usePromoCode } from '../../../config/query/promoCodeQuery'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import { formatDate } from '../../../config/helpers/dateHelper'
import CircularLoading from '../../../components/Skeletons/CircularLoading'
import { formatAmount } from '../../../config/helpers/stringHelper'

const PromoCodeDetails = ({ disclosure, id }) => {
    const promoCodeQuery = usePromoCode(id)
    return (
        <Modal isOpen={disclosure?.isOpen} onClose={disclosure?.onClose} motionPreset='none'>
            <ModalOverlay />
            <ModalContent maxW="50rem">
                <ModalHeader>{promoCodeQuery.data?.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <CircularLoading loading={promoCodeQuery.isLoading} containerProps={{ minH: "260px" }} />
                    {!promoCodeQuery.isLoading && (
                        <SimpleGrid columns={2} spacing={2}>
                            <LabelValuePair label="Code" value={promoCodeQuery.data?.code} />
                            {promoCodeQuery.data?.discountPercentage
                                ? <LabelValuePair
                                    label="Discount Percentage"
                                    value={promoCodeQuery.data?.discountPercentage
                                        ? `${promoCodeQuery.data?.discountPercentage}%`
                                        : false}
                                />
                                : <LabelValuePair label="Discount Amount" value={formatAmount(promoCodeQuery.data?.discountAmount)} />}
                            <LabelValuePair label="Redemption Limit" value={promoCodeQuery.data?.redemptionLimit} />
                            <LabelValuePair label="Max Discount Limit" value={promoCodeQuery.data?.maxDiscountLimit ? formatAmount(promoCodeQuery.data?.maxDiscountLimit) : false} />
                            <LabelValuePair label="Expiry Date" value={formatDate(promoCodeQuery.data?.expiryDate)} />
                            <LabelValuePair label="Zone Name" value={promoCodeQuery.data?.zoneName} />
                            <LabelValuePair label="Promo Code Type" value={promoCodeQuery.data?.promoCodeType?.name} />
                            <LabelValuePair label="Corporate Name" value={promoCodeQuery.data?.corporateName} />
                            <LabelValuePair label="Access" value={promoCodeQuery.data?.access?.name} />
                            <LabelValuePair label="Release Quantity" value={promoCodeQuery.data?.releasedQuantity} />
                            <LabelValuePair label="Payment Method" value={promoCodeQuery.data?.paymentMethod?.name} />
                            <LabelValuePair
                                containerProps={{ mt: 2 }}
                                label="Payment Method"
                                value={promoCodeQuery.data?.paymentMethods?.map((item, index) => (
                                    <span key={index}>{item.name}{index !== promoCodeQuery.data?.paymentMethods?.length - 1 ? ', ' : ''}</span>
                                ))}
                            />
                        </SimpleGrid>
                    )}
                    <LabelValuePair
                        containerProps={{ mt: 2 }}
                        label="Vehicle Types"
                        value={promoCodeQuery.data?.vehicleTypes?.map((item, index) => (
                            <span key={index}>{item.name || "N/A"}{index !== promoCodeQuery.data?.vehicleTypes?.length - 1 ? ', ' : ''}</span>
                        ))}
                    />
                    <LabelValuePair
                        containerProps={{ mt: 2 }}
                        label="Zones"
                        value={promoCodeQuery.data?.zones?.map((item, index) => (
                            <span key={index}>{item.name || "N/A"}{index !== promoCodeQuery.data?.zones?.length - 1 ? ', ' : ''}</span>
                        ))}
                    />
                    <LabelValuePair
                        containerProps={{ mt: 2 }}
                        label="Customers"
                        value={promoCodeQuery.data?.customers?.map((item, index) => (
                            <span key={index}>{item.name || "N/A"}{index !== promoCodeQuery.data?.customers?.length - 1 ? ', ' : ''}</span>
                        ))}
                    />
                    <LabelValuePair
                        containerProps={{ mt: 2 }}
                        label="Corporates"
                        value={promoCodeQuery.data?.corporates?.map((item, index) => (
                            <span key={index}>{item.name || "N/A"}{index !== promoCodeQuery.data?.corporates?.length - 1 ? ', ' : ''}</span>
                        ))}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button variant='outline' onClick={disclosure?.onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default PromoCodeDetails