import { DEFAULT_COUNTRY_CODE } from "../../constants/settings"
import { formatDateForInput, formatDateForSever } from "../dateHelper"
import { formatPhoneNumber } from "../stringHelper"

export const prepareForm = ({
    formValues,
    serverValues
}) => ({
    countryCode: serverValues?.countryCode || DEFAULT_COUNTRY_CODE,
    driverShare: serverValues?.driverShare || 75,
    vehicleId: serverValues?.vehicle?.id,
    ...(formValues?.id && {
        id: formValues?.id,
        ...serverValues,
        countryId: serverValues?.country?.id,
        stateId: serverValues?.state?.id,
        cityId: serverValues?.city?.id,
        operatingCityId: serverValues?.operatingCity?.id,
        dob: formatDateForInput(serverValues?.dob, false),
        licenseExpiry: formatDateForInput(serverValues?.licenseExpiry, false),
        gender: serverValues?.gender?.id,
        stripeStatus: serverValues?.stripeStatus?.id,
        shiftId: serverValues?.shift?.id,
        status: serverValues?.status?.id,
        make: serverValues?.vehicle?.make?.id,
    }),
})

export const preparePayload = (values, id) => {
    let payload = {
        ...values,
        ...(id && { id }),
        gender: Number(values.gender),
        stripeStatus: 1,
        status: Number(values.status),
        localNumber: formatPhoneNumber(values?.countryCode, values?.localNumber),
        dob: formatDateForSever(values?.dob, false),
        licenseExpiry: formatDateForSever(values?.licenseExpiry, false),
        driverShare: Number(values.driverShare),
    }
    return payload
}

export const prepareInitialQuery = (data, vehicle) => {
    return {
        stateQuery: {
            countryId: data?.id ? data?.country?.id : null,
        },
        cityQuery: {
            stateId: data?.id ? data?.state?.id : null,
        },
        modelQuery: {
            makeId: data?.id ? data?.make?.id : null,
        },
        vehicleQuery: {
            Keyword: vehicle?.licensePlate,
            PageNumber: 1,
            PageSize: 20
        },
    }
}

export const prepareDocumentPayload = (recentDocs, documents) => {
    let docuLength = recentDocs?.length || 0
    const formData = new FormData()
    recentDocs?.forEach((item, index) => {
        formData.append(`documents[${index}].id`, item.id)
        if (item.document?.id) {
            formData.append(`documents[${index}].DocumentId`, item.document?.id)
        }
        formData.append(`documents[${index}].title`, item?.title)
    })
    Array.from(documents).forEach((item, index) => {
        const calculatedIndex = docuLength + index
        formData.append(`documents[${calculatedIndex}].Id`, '')
        if (item.title) formData.append(`documents[${calculatedIndex}].title`, item.title)
        else formData.append(`documents[${calculatedIndex}].title`, `Untitled ${calculatedIndex}`)
        if (item.binary) formData.append(`documents[${calculatedIndex}].Document`, item.binary)
        if (item.link) formData.append(`documents[${calculatedIndex}].Link`, item.link)
    })
    return formData
}