import React from "react";
import { Icon, Tooltip } from "@chakra-ui/react";
import APP_ICONS from "../../../config/constants/icons";
import {
  DELIVER_STATUS,
  DELIVER_STATUSES,
} from "../../../config/constants/enums";

const MessageStatus = ({ status }) => {
  let statusIcon = null;
  switch (status) {
    case DELIVER_STATUSES.SENT:
      statusIcon = APP_ICONS.CHECK;
      break;
    case DELIVER_STATUSES.DELIVERED:
      statusIcon = APP_ICONS.DOUBLE_CHECK;
      break;
    case DELIVER_STATUSES.READ:
      statusIcon = APP_ICONS.DOUBLE_CHECK;
      break;
    default:
      statusIcon = APP_ICONS.CLOCK;
      break;
  }
  return (
    <Tooltip
      isDisabled={!DELIVER_STATUS[status]}
      label={DELIVER_STATUS[status]}
    >
      <span>
        <Icon
          color={status === DELIVER_STATUSES.READ && "#00a3ff"}
          as={statusIcon}
        />
      </span>
    </Tooltip>
  );
};

export default MessageStatus;
