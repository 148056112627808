import React, { useCallback, useState, useEffect } from 'react'
import { Divider, VStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import FormModal from '../../../../components/Forms/FormModal'
import FormTextarea from '../../../../components/Forms/FormTextarea'
import { useCreateDriver, useDriver, useUpdateDriver, useUpdateDriverDocuments, useUpdateDriverImage } from '../../../../config/query/driverQuery'
import { useQueryClient } from '@tanstack/react-query'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import { prepareDocumentPayload, prepareForm, prepareInitialQuery, preparePayload } from '../../../../config/helpers/formHelpers/driverHelper'
import { DEFAULT_DEBOUNCE_TIME } from '../../../../config/constants'
import ContactInformation from './ContactInformation'
import VehicleInformation from './VehicleInformation'
import ImageInformation from './ImageInformation'
import DocumentsInformation from './DocumentsInformation'
import generateString from '../../../../config/helpers/generateString'
import moment from 'moment'

const DriverForm = ({ disclosure, data, onSuccess }) => {

    const [query, setQuery] = useState(prepareInitialQuery(data, data?.vehicle))
    const queryClient = useQueryClient()
    const driverQuery = useDriver(data?.id)
    const createDriverQuery = useCreateDriver()
    const updateDriverImage = useUpdateDriverImage()
    const updateDriverQuery = useUpdateDriver()
    const updateDriverDocuments = useUpdateDriverDocuments()

    const [fileData, setFileData] = useState({
        binary: null,
        url: null,
    })

    const [documents, setDocuments] = useState([{
        tempId: generateString(10),
        binary: null,
        url: null,
    }])

    const {
        handleSubmit,
        control,
        setValue,
        reset: resetForm,
        getValues: getFormValues,
        formState: { errors },
        watch,
        clearErrors,
        setError
    } = useForm({
        values: prepareForm({
            formValues: data,
            serverValues: driverQuery.data
        }),
    })

    const fields = watch()
    const localNumber = watch("localNumber")
    const countryCode = watch("countryCode")

    const handleFormChangeEffects = (name, value) => {
        const updatedQuery = { ...query[name], ...value }
        setQuery({ ...query, [name]: updatedQuery })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceQuery = useCallback(_.debounce(handleFormChangeEffects, DEFAULT_DEBOUNCE_TIME), []);

    watch((data, { name, type }) => {
        if (type === "change") {
            if (name === "countryId") {
                handleFormChangeEffects("stateQuery", { countryId: data[name] })
            }
            if (name === "stateId") {
                handleFormChangeEffects("cityQuery", { stateId: data[name] })
            }
            if (name === "make") {
                handleFormChangeEffects("modelQuery", { makeId: data[name] })
            }
        }
    })

    const onSubmit = (values) => {
        values = { ...values, dob: moment(values?.dob).format('YYYY-MM-DD'), licenseExpiry: moment(values?.licenseExpiry).format('YYYY-MM-DD') }
        let formMutate = data?.id ? updateDriverQuery.mutateAsync : createDriverQuery.mutateAsync
        formMutate(preparePayload(values, data?.id))
            .then((res) => {
                disclosure?.onClose()
                if (fileData?.binary) {
                    updateDriverImage
                        .mutateAsync({
                            DriverId: res?.id,
                            DriverImage: fileData?.binary
                        })
                        .then(() => onSuccess && onSuccess())
                        .catch((error) => console.warn(error))
                }
                else {
                    onSuccess && onSuccess()
                }

                // if (documents?.length > 0 || fields?.inspectionDocuments?.length > 0) {
                updateDriverDocuments
                    .mutateAsync({
                        id: res.id,
                        body: prepareDocumentPayload(fields?.inspectionDocuments, documents)
                    })
                    .then(() => onSuccess && onSuccess())
                    .catch((error) => console.warn(error))
                // }
                queryClient.invalidateQueries({ queryKey: ["drivers"] })
                setDocuments([])
            })
            .catch((error) => console.warn(error))
    }

    useEffect(() => {
        if (data?.id && disclosure?.isOpen) {
            setFileData({
                ...fileData,
                url: driverQuery.data?.profilePictureUrl
            })
            setQuery({
                ...query,
                stateQuery: {
                    countryId: driverQuery.data?.country?.id,
                },
                cityQuery: {
                    stateId: driverQuery.data?.state?.id,
                },
                modelQuery: {
                    makeId: driverQuery.data?.vehicle?.make?.id,
                },
            })
        } else {
            resetForm()
            setFileData({
                binary: null,
                url: null,
            })
            setDocuments([])
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id, driverQuery.data?.profilePictureUrl, disclosure?.isOpen])

    return (
        <FormModal
            title={data ? "Edit Driver" : "Add Driver"}
            disclosure={disclosure}
            isSubmitting={createDriverQuery.isLoading || updateDriverQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"85rem"}
            reset={resetForm}
            onCloseFn={() => {
                resetForm()
                setFileData({
                    binary: null,
                    url: null,
                })
            }}
        >
            <VStack spacing={5} align="stretch" divider={<Divider />}>
                <ImageInformation
                    fields={fields}
                    isLoading={driverQuery.isFetching}
                    fileData={fileData}
                    setFileData={setFileData}
                />

                <ContactInformation
                    control={control}
                    errors={errors}
                    fields={fields}
                    getFormValues={getFormValues}
                    isLoading={driverQuery.isFetching}
                    setValue={setValue}
                    query={query}
                    clearErrors={clearErrors}
                    setError={setError}
                    localNumber={localNumber}
                    countryCode={countryCode}
                />


                <VehicleInformation
                    control={control}
                    errors={errors}
                    fields={fields}
                    isLoading={driverQuery.isFetching}
                    query={query}
                    onQueryChange={debounceQuery}
                    watch={watch}
                    data={data}
                />


                <DocumentsInformation
                    control={control}
                    errors={errors}
                    fields={fields}
                    isLoading={driverQuery.isFetching}
                    watch={watch}
                    documents={documents}
                    setDocuments={setDocuments}
                    setValue={setValue}
                />

                <FormFieldsSkeleton columns={1} count={1} fieldHeight={"120px"} loading={driverQuery.isFetching} />
                {!driverQuery.isFetching && <FormTextarea
                    id={"note"}
                    label="Note"
                    placeholder="Write anything you want to note about this driver"
                    errors={errors}
                    control={control}
                />
                }
            </VStack>
        </FormModal>
    )
}

export default DriverForm