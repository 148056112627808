import {
    useMutation
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Post } from "../api";
import API_CONSTANTS from "../constants/api";

export const useLogin = () => {
    return useMutation({
        mutationFn: async (body) => {
            const data = await Post({
                path: API_CONSTANTS.AUTH.login,
                body,
                tenant: body.tenant,
                toastError: true
            })
            return data
        },
    })
}

export const useProfile = () => {
    return useMutation({
        mutationFn: async ({ token, tenant }) => {
            const data = await Get({
                path: API_CONSTANTS.AUTH.profile,
                token,
                tenant,
                toastError: true,
            })
            return data
        },
    })

}

export const useForgotPassword = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Post({
                path: API_CONSTANTS.AUTH.forgotPassword,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}

export const useVerifyOtp = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Post({
                path: API_CONSTANTS.AUTH.verifyOtp,
                token,
                body,
                toastError: true
            })
            return data
        },
    })
}

export const useResetPassword = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Post({
                path: API_CONSTANTS.AUTH.resetPassword,
                token,
                body,
                toastError: true
            })
            return data
        },
    })
}

export const useRegister = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Post({
                path: API_CONSTANTS.AUTH.login,
                token,
                body,
                toastError: true
            })
            return data
        },
    })
}

export const useSetPassword = () => {
    return useMutation({
        mutationFn: async ({ tenant, body }) => {
            const data = await Post({
                path: API_CONSTANTS.AUTH.setPassword,
                body,
                tenant,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}

export const usePermissions = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Get({
                path: API_CONSTANTS.AUTH.permissions,
                token,
                body,
            })
            return data
        },
    })
}

export const useVerifySetPassword = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async ({ body, tenant }) => {
            const data = await Post({
                path: API_CONSTANTS.AUTH.verifySetPassword,
                token,
                body,
                tenant,
                toastError: true,
            })
            return data
        },
    })
}