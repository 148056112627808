import React, { useState } from 'react';
import { DrawingManager, GoogleMap, Polygon, PolygonF } from '@react-google-maps/api';
import { Box } from '@chakra-ui/react';
import { getDefaultCoordinates } from '../../config/helpers/locationHelper';
import { getMapStyles } from './mapConfig';
import { useDrawPolygon } from './drawPolygonContainer';

const DrawPolygon = ({
    polylineCoords,
    setPolylineCoords,
    disabled,
    center = getDefaultCoordinates(),
    containerProps,
    polygonsArray,
    strictBorders
}, ref) => {

    const {
        onLoadMap,
        onEdit,
        onLoad,
        onUnmount,
        handleMapClick,
        polylineOptions,
        googleMapOptions,
        drawablePolygonOptions,
        otherPolygonOptions,
        zoomRef,
        map,
        polylineRef,
    } = useDrawPolygon({ polylineCoords, setPolylineCoords, disabled, center, containerProps, polygonsArray, strictBorders })

    const [drawingManager, setDrawingManager] = useState(null);

    const handleOverlayComplete = (event) => {
        //set polyline array
        event.overlay.setEditable(false);
        setPolylineCoords(event.overlay.getPath().getArray());

        //clear the drawing
        setDrawingManager(null);
        drawingManager.setDrawingMode(null);
        event.overlay.setMap(null);
    };

    return (
        <Box w="full" h="calc(100vh - 250px)" ref={ref} {...containerProps}>
            <GoogleMap
                onLoad={onLoadMap}
                mapContainerStyle={getMapStyles(disabled)}
                center={polylineCoords?.at(0) || center}
                // onClick={handleMapClick}
                options={googleMapOptions}
                onZoomChanged={() => {
                    if (map) zoomRef.current = map.getZoom()
                }}
            >
                {/* Allowed parent zones for the tenant */}
                {strictBorders && strictBorders?.map((border, index) => (
                    <PolygonF
                        onClick={handleMapClick}
                        key={index}
                        path={border}
                        draggable={false}
                        editable={false}
                        onUnmount={onUnmount}
                        options={{ ...polylineOptions, fillColor: "transparent" }}
                    />
                ))}

                {/* Display drawing manager when drawing is 
                not completed or polylineCoords is empty */}
                {map && !polylineCoords?.length && (
                    <DrawingManager
                        drawingMode="polygon"
                        options={{
                            map: {
                            },
                            polylineOptions: drawablePolygonOptions,
                            drawingControl: true,
                            drawingControlOptions: {
                                position: window.google.maps.ControlPosition.TOP_CENTER,
                                drawingModes: ["polygon"],
                            },
                            polygonOptions: {
                                ...drawablePolygonOptions,
                                editable: true,
                            },
                        }}
                        ref={polylineRef}
                        onLoad={(drawingManager) => setDrawingManager(drawingManager)}
                        onOverlayComplete={handleOverlayComplete}
                    />
                )}

                {/* Draw polygon when drawing is completed */}
                {polylineCoords?.length && (
                    <Polygon
                        ref={polylineRef}
                        path={polylineCoords}
                        draggable={false}
                        editable={!disabled}
                        onMouseUp={onEdit}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{ ...drawablePolygonOptions, editable: !disabled }}
                    />
                )}


                {/* Display previously drawn polygons */}
                {polygonsArray && polygonsArray.map((polygon, index) => (
                    <Polygon
                        key={index}
                        path={polygon}
                        draggable={false}
                        editable={false}
                        onUnmount={onUnmount}
                        options={{ ...otherPolygonOptions, editable: false, }}
                    />
                ))}
            </GoogleMap>
        </Box >
    );
}

export default React.forwardRef(DrawPolygon)