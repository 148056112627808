import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS, { DEFAULT_SEARCH_KEY } from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { CONTENT_TYPE } from "../constants";
import { removePlusSign } from "../helpers/stringHelper";

export const useCustomers = (params) => {
  const token = useSelector((state) => state.user.token);
  params = appendQueryParams({ ...params, [DEFAULT_SEARCH_KEY]: API_CONSTANTS.CUSTOMERS.searchableKeys }, false)
  return useQuery({
    queryKey: ["customers", params],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.CUSTOMERS.base}?${params}`,
        token,
      });
      return data;
    },
    keepPreviousData: true,
  });
};

export const useCustomerOptions = (query) => {
  const token = useSelector((state) => state.user.token);
  query = removePlusSign(query)
  return useQuery({
    queryKey: ["customer-lookup", query],
    queryFn: async () => {
      const data = await Get({
        path: API_CONSTANTS.CUSTOMERS.searchUserByPhone.replace(":phoneNumber", query || ""),
        token,
        toastError: false,
      });
      return data;
    },
    keepPreviousData: true,
    enabled: Boolean(query),
  });
}

export const useCustomer = (id) => {
  const token = useSelector((state) => state.user.token);
  return useQuery({
    queryKey: ["customer", id],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.CUSTOMERS.base}/${id}`,
        token,
        toastError: true,
      });
      return data;
    },
    keepPreviousData: true,
    enabled: Boolean(id),
  });
};

export const useCreateCustomer = () => {
  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async (body) => {
      body = prepareData(body, API_CONSTANTS.CUSTOMERS.dataKeys);
      const res = await Post({
        path: API_CONSTANTS.CUSTOMERS.create,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["customers"])
      queryClient.invalidateQueries(["customer-lookup"])
    }
  });
};

export const useUpdateCustomerImage = () => {
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async (body) => {
      const res = await Patch({
        path: API_CONSTANTS.CUSTOMERS.updateImage,
        token,
        body,
        toastError: true,
        toastMessage: false,
        contentType: CONTENT_TYPE.FORM_DATA,
      });
      return res;
    },
  });
};

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async (body) => {
      body = prepareData(body, API_CONSTANTS.CUSTOMERS.dataKeys);
      const res = await Put({
        path: `${API_CONSTANTS.CUSTOMERS.base}`,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["customers"])
      queryClient.invalidateQueries(["customer-lookup"])
    }
  });
};

export const useDeleteCustomer = () => {
  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async (id) => {
      const { data } = await Delete({
        path: `${API_CONSTANTS.CUSTOMERS.base}/${id}`,
        token,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["customers"])
      queryClient.invalidateQueries(["customer-lookup"])
    }
  });
};

export const useCustomerBalance = (id) => {
  const token = useSelector((state) => state.user.token);
  return useQuery({
    queryKey: ["customerBalance", id],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.CUSTOMERS.getBalance.replace(
          ":customerId",
          id
        )}`,
        token,
      });
      return data;
    },
    keepPreviousData: true,
    enabled: Boolean(id),
  });
};

export const useAdjustBalance = () => {
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationFn: async (body) => {
      const res = await Post({
        path: API_CONSTANTS.CUSTOMERS.adjustBalance,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return res;
    },
  });
};

export const useUpdateCustomerStatus = () => {
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationKey: ["customer-status"],
    mutationFn: async (body) => {
      const response = await Patch({
        path: `${API_CONSTANTS.CUSTOMERS.updateStatus.replace(
          ":id",
          body.id
        )}?status=${body.status}`,
        token,
        toastError: true,
        toastMessage: false,
      });
      return response;
    },
  });
};

export const usePrimaryCard = (id) => {
  const token = useSelector((state) => state.user.token);
  return useQuery({
    queryKey: ["set-primary", id],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.CUSTOMERS.getPrimaryCard.replace(
          ":customerId",
          id
        )}`,
        token,
      });
      return data;
    },
    keepPreviousData: true,
    enabled: Boolean(id),
  });
};

export const useCustomerSetupIntent = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const data = await Get({
        path: `${API_CONSTANTS.CUSTOMERS.setupIntent.replace(":id", id)}`,
        toastError: true,
        token,
      });
      return data;
    },
  });
};

export const useCustomerCards = (id) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["customerCards", id],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.CUSTOMERS.cards.replace(":id", id)}`,
        toastError: false,
        token,
      });
      return data;
    },
    enabled: Boolean(id),
  });
};

export const useDeleteCustomerCard = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const data = await Delete({
        path: `${API_CONSTANTS.CUSTOMERS.deleteCard
          .replace(":id", body.customerId)
          .replace(":cardId", body.cardId)}`,
        toastError: false,
        token,
      });
      return data;
    },
  });
};

export const useCustomerDetailLookup = (params) => {
  const token = useSelector((state) => state.user.token);
  return useQuery({
    queryKey: ["customer-detail-lookup", params],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.CUSTOMERS.detailsLookup}?${appendQueryParams(params)}`,
        token,
        toastError: true
      });
      return data;
    },
    keepPreviousData: true,
  });
}