import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarBadge,
  Box,
  Icon,
  IconButton,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormModal from "../../Forms/FormModal";
import FormInput from "../../Forms/FormInput";
import FormTextarea from "../../Forms/FormTextarea";
import {
  useGeneralInboxById,
  useUpadetChatImage,
  useUpdateChat,
} from "../../../config/query/supportQuery";
import FormFileUpload from "../../Forms/FormFileUpload";
import APP_ICONS from "../../../config/constants/icons";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { useQueryClient } from "@tanstack/react-query";

const GroupInfo = ({ disclosure, data, chatId, onSuccess }) => {
  const queryClient = useQueryClient();
  const { colorMode } = useColorMode();

  const [fileData, setFileData] = useState({
    binary: null,
    url: null,
  });

  const chatQuery = useGeneralInboxById(chatId);
  const updateGroupInfoQuery = useUpdateChat();
  const updateChatImageQuery = useUpadetChatImage();

  const {
    handleSubmit,
    control,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    values: {
      title: chatQuery.data?.data?.chatTitle,
      description: chatQuery.data?.data?.description,
    },
  });

  const onChangeImage = (file) => {
    setFileData({
      binary: file,
      url: URL.createObjectURL(file),
    });
  };

  const onSubmit = (values) => {
    updateGroupInfoQuery
      .mutateAsync({ ...values, id: chatId })
      .then(() => {
        if (fileData?.binary) {
          updateChatImageQuery
            .mutateAsync({
              ChatId: chatId,
              ChatImage: fileData?.binary,
            })
            .then(() => {
              onSuccess && onSuccess();
              queryClient.invalidateQueries(["general-inbox"]);
              disclosure.onClose();
            });
        } else {
          onSuccess && onSuccess();
          queryClient.invalidateQueries(["general-inbox"]);
          disclosure.onClose();
        }
      })
      .catch((error) => console.warn(error));
  };

  useEffect(() => {
    if (chatId) {
      setFileData({
        ...fileData,
        url: chatQuery.data?.data?.chatImage,
      });
    } else {
      resetForm();
      setFileData({
        binary: null,
        url: null,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, chatQuery.data?.data?.chatImage]);

  return (
    <FormModal
      title={"Update Group"}
      disclosure={disclosure}
      isSubmitting={
        updateGroupInfoQuery.isLoading || updateChatImageQuery.isLoading
      }
      onSubmit={handleSubmit(onSubmit)}
      maxW={"30rem"}
      reset={resetForm}
      onCloseFn={resetForm}
    >
      <VStack align={"stretch"}>
        <Box m="auto">
          <Avatar size="2xl" name={data?.name} src={fileData?.url}>
            <AvatarBadge boxSize="8">
              <FormFileUpload
                id={"profilePicture"}
                onChange={onChangeImage}
                component={
                  <IconButton
                    variant={"ghost"}
                    icon={
                      <Icon
                        as={APP_ICONS.EDIT}
                        boxSize="7"
                        color={getColor(colorKeys.primary, colorMode)}
                      />
                    }
                  />
                }
                componentProps={{ variant: "ghost" }}
                fileType={"IMAGE"}
              />
            </AvatarBadge>
          </Avatar>
        </Box>

        <FormInput
          label={"Group Title"}
          control={control}
          errors={errors}
          id="title"
          required={true}
          placeholder="Enter group title"
          inputProps={{ size: "sm" }}
        />
        <FormTextarea
          id="description"
          control={control}
          errors={errors}
          label={"Description"}
          placeholder="Enter short description"
          inputProps={{ size: "sm" }}
        />
      </VStack>
    </FormModal>
  );
};

export default GroupInfo;
