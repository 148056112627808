import React, { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useProfile } from "../../../config/query/authQuery";
import { addUser, resetUser } from "../../../config/redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { Flex, Heading, Text } from "@chakra-ui/react";
import CircularLoading from "../../../components/Skeletons/CircularLoading";

const Impersonate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const token = search.get("token");
  const tenant = search.get("tenant");

  const profileQuery = useProfile();

  useEffect(() => {
    dispatch(resetUser());
    localStorage.clear();
    if (token) {
      profileQuery
        .mutateAsync({ token, tenant })
        .then((res) => {
          localStorage.setItem("tenant", tenant);
          let user = { ...res };
          delete user?.message;
          delete user?.token;
          dispatch(addUser({ user, token }));
          navigate("/admin/dashboard");
        })
        .catch((err) => console.warn(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token || !tenant) return <Navigate to="/auth/login" />;

  return (
    <Flex align="center" justify={"center"} h="100vh" w="100vw">
      <Flex justify={"center"} flexDir={"column"}>
        <CircularLoading containerProps={{ minH: "unset" }} loading={true} />
        <Heading mt={5} fontSize="20px">
          Verifying Credentials
        </Heading>
        <Text textAlign={"center"} mt={3} fontSize="14px">
          Please wait...
        </Text>
      </Flex>
    </Flex>
  );
};

export default Impersonate;
