import { Center, CircularProgress, Flex } from '@chakra-ui/react'
import React from 'react'

const CircularLoading = ({ loading, containerProps }) => {
    if (!loading) return null
    return (
        <Flex w="full" justify={"center"} minH="450px" {...containerProps}>
            <Center>
                <CircularProgress isIndeterminate />
            </Center>
        </Flex>
    )
}

export default CircularLoading