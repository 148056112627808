import React from "react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  useColorMode,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_ICONS from "../../../config/constants/icons";

const CustomDateInput = ({
  id,
  placeholder,
  label,
  onChange,
  value,
  minDate,
  maxDate,
  size = "sm",
  hideLabel,
  isDisabled,
  inputProps,
  groupProps,
  containerProps,
  labelContainerProps,
  labelProps,
  iconProps,
}) => {
  const { colorMode } = useColorMode();
  return (
    <FormControl {...containerProps}>
      {!hideLabel && (
        <FormLabel htmlFor={id} fontSize={"13px"} {...labelProps}>
          <Flex {...labelContainerProps}>{label}</Flex>
        </FormLabel>
      )}
      <InputGroup className="date-picker-gc" size={size} {...groupProps}>
        <SingleDatepicker
          name={id}
          date={value ? new Date(value) : undefined}
          onDateChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          usePortal={true}
          disabled={isDisabled}
          propsConfigs={{
            inputProps: {
              placeholder,
              ...inputProps,
            },
            dayOfMonthBtnProps: {
              selectedBtnProps: {
                bg: getColor(colorKeys.primaryButtonFill, colorMode),
                color: getColor(colorKeys.white, colorMode),
              },
              todayBtnProps: {
                bg: getColor(colorKeys.dimBlue, colorMode),
                color: getColor(colorKeys.white, colorMode),
              },
              defaultBtnProps: {
                _hover: {
                  bg: getColor(colorKeys.primaryButtonFill, colorMode),
                  color: getColor(colorKeys.white, colorMode),
                },
                fontWeight: "normal",
              },
            },
            popoverCompProps: {
              style: { zIndex: 999999 },
            },
            calendarPanelProps: {
              zIndex: 9999,
            },
            weekdayLabelProps: {
              fontWeight: "normal",
            },
            dateNavBtnProps: {
              fontWeight: "normal",
            },
          }}
        />
        <InputRightElement h="full">
          {value ? (
            <IconButton
              onClick={() => onChange(undefined)}
              variant={"ghost"}
              rounded={"none"}
              size={size}
              icon={<Icon as={APP_ICONS.CLOSE} />}
            />
          ) : (
            <Icon as={APP_ICONS.CALENDAR} boxSize={5} {...iconProps} />
          )}
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export default CustomDateInput;
