import {
    useQuery,
    useMutation,
    useQueryClient
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";


export const useColors = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["colors"],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.COLOR.base,
                token,
            })
            return data
        },
    })
}


export const useDeleteColors = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.COLOR.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["colors"])
        }
    })
}


export const useUpdateColor = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Put({
                path: `${API_CONSTANTS.COLOR.base}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => {
        queryClient.invalidateQueries(["colors"])
        }
    })
}


export const useCreateColor = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: API_CONSTANTS.COLOR.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["colors"])
        }
    })
}
