import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import CustomTable from "../../../../components/BasicUI/CustomTable";
import { makeInitialQueryObject } from "../../../../config/helpers/queryHelper";
import { useColors, useDeleteColors } from "../../../../config/query/colorQuery";
import useDebounce from "../../../../config/hooks/useDebounce";
import { APP_MODULES } from "../../../../config/constants";
import ColorForm from "./ColorForm";
const Color = ({
  selectedRow,
  setSelectedRow,
  disclosure,
  setIsRefreshing,
  isRefreshing,
}) => {

  const [query, setQuery] = useState(makeInitialQueryObject());
  const debouncedQuery = useDebounce(query);

  const colorQuery = useColors(debouncedQuery)
  const deleteColorQuery = useDeleteColors();

  const onEditModal = (item) => {
    setSelectedRow(item);
    disclosure.onOpen();
  };

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  const handleDelete = (id) => {
    deleteColorQuery
      .mutateAsync(id)
      .catch((error) => console.warn(error));
  };

  useEffect(() => {
    if (isRefreshing) colorQuery.refetch().then(() => setIsRefreshing(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing]);

  return (
    <Box>
      <CustomTable
        permissionKey={APP_MODULES.City}
        tableFor={"color"}
        tableWrapperProps={{ padding: 0, h: "calc(100vh - 315px)" }}
        hideFilterBar={true}
        containerProps={{ mt: 4, h: "calc(100vh - 266px)" }}
        head={[
          { title: "Name", extractor: "name", align: "left" },
          { title: "Action", extractor: "actions", align: "center" },
        ]}
        data={colorQuery?.data?.data}
        loading={colorQuery?.isLoading}
        totalResults={colorQuery?.data?.meta?.totalCount}
        totalPages={colorQuery?.data?.meta?.totalPages}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => colorQuery.refetch()}
        isRefreshing={colorQuery?.isFetching}
        onEdit={onEditModal}
        onDelete={handleDelete}
      />
      <ColorForm data={selectedRow} disclosure={disclosure}/>
    </Box>
  );
};

export default Color;
