import React from "react";
import TableInfoPopover from "./TableInfoPopover";
import { Link } from "react-router-dom";
import LabelValuePair from "../LabelValuePair";
import StatusBadge from "../Badges/StatusBadge";
import TripBox from "../DataBoxes/TripBox";
import { formatDateTime } from "../../../config/helpers/dateHelper";
import DualTimeBox from "../DataBoxes/DualTimeBox";

const BookingTablePopover = ({ data, titleKey = "id", route = "bookings" }) => {
  return (
    <TableInfoPopover
      data={data}
      titleKey={titleKey}
      fallBackText={"N/A"}
      triggerProps={{
        as: Link,
        to: `/admin/${route}/${data[titleKey]}`,
      }}
    >
      <LabelValuePair
        label="Status"
        value={true}
        valueComponent={
          <StatusBadge value={data.status?.id} bookingStatus={true} />
        }
      />

      <LabelValuePair
        flexDir="column"
        label="Pickup"
        valueComponent={
          <DualTimeBox
            data={data?.pickup}
            timeZoneOffSet={data?.timeZoneOffSet}
          />
        }
        value={data?.pickup}
      />

      <LabelValuePair
        flexDir="column"
        label="Dropoff"
        valueComponent={
          <DualTimeBox
            data={data?.dropoff}
            timeZoneOffSet={data?.timeZoneOffSet}
          />
        }
        value={data?.dropoff}
      />

      <TripBox
        trip={[
          {
            label: "Pickup From",
            value: data.pickupAddress,
          },
          {
            label: "Drop-off",
            value: data.dropoffAddress,
          },
        ]}
      />

      <LabelValuePair label="Created" value={formatDateTime(data.createdOn)} />
      <LabelValuePair
        label="Last Updated"
        value={formatDateTime(data.lastModifiedOn)}
      />
    </TableInfoPopover>
  );
};

export default BookingTablePopover;
