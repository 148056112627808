import { Card, chakra, Flex, Box, Text, useColorMode, Image } from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from "yup"
import { yupResolver } from '@hookform/resolvers/yup'
import { Navigate, useNavigate } from 'react-router-dom'
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import { getColor, colorKeys } from "../../../config/constants/appColors"
import FormInput from "../../../components/Forms/FormInput"
import { addUser, resetTemp } from '../../../config/redux/slices/userSlice'
import { useResetPassword } from '../../../config/query/authQuery'
import IMAGES from '../../../config/constants/images'
import COLOR_MODES from '../../../config/constants/colorModes'
import WhiteLogo from '../../../components/SVGComponents/WhiteLogo'

const Reset = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()
    const tenant = useSelector((state) => state.tenant?.tenant)
    const { reset } = useSelector(state => state.user)

    const resetPasswordQuery = useResetPassword()

    const formSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required("Password is required")
            .min(4, "Password length should be at least 4 characters")
            .max(12, "Password cannot exceed more than 12 characters"),
        confirmPassword: Yup.string()
            .required("Confirm Password is required")
            .min(4, "Password length should be at least 4 characters")
            .max(12, "Password cannot exceed more than 12 characters")
            .oneOf([Yup.ref("newPassword")], "Passwords do not match")
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formSchema)
    })

    function onSubmit(values) {
        resetPasswordQuery.mutateAsync({ password: values?.newPassword, email: reset?.tempEmail, token: reset?.tempToken })
            .then((response) => {
                dispatch(addUser(response))
                dispatch(resetTemp())
                navigate("/admin")
            })
            .catch((error) => {
                console.warn(error, "error")
            })
    }

    if (!reset?.tempEmail && !reset?.tempToken) {
        return <Navigate to="/auth/forgot-password" />
    }

    return (
        <Flex height={"100vh"} justify="center" align="center" bg={getColor(colorKeys.white, colorMode)} background={`url(${tenant?.backgroundImageUrl})`} backgroundRepeat={"no-repeat"} backgroundSize={"cover"} backgroundPosition={"center"}>
            <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
                <Card w="full" maxW="480px">
                    <Box w="full" maxW="480px">

                        <Flex px="5" justify={"space-between"} bg={getColor(colorKeys.dimmerBlue, colorMode)}>
                            <Box>
                                <Text fontSize="16px" color={getColor(colorKeys.white, colorMode)} mt={5}>
                                    Reset Password
                                </Text>
                                <Text fontSize="13px" color={getColor(colorKeys.white, colorMode)} >
                                    Reset your password
                                </Text>
                            </Box>

                            <Box background={`url(${IMAGES.AUTH_MIN})`} w="173px" h="112px" />
                        </Flex>

                        <Box
                            pos="absolute"
                            top="75px"
                            left="25px"
                            w="90px"
                            m="auto"
                            bg={getColor(colorKeys.white, colorMode)}
                            rounded="full"
                        >
                            {colorMode === COLOR_MODES.LIGHT ?
                                <Image w="full" h="full" src={tenant?.logoUrl} alt="logo" />
                                : <WhiteLogo containerStyles={{ margin: "auto" }} />}
                        </Box>

                        <Box p="10">

                            <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">

                                <FormInput
                                    id={"newPassword"}
                                    label={"New Password"}
                                    placeholder="Create new password"
                                    required={true}
                                    errors={errors}
                                    control={control}
                                    secure={true}
                                    inputProps={{ fontSize: "15px" }}
                                />

                                <FormInput
                                    id={"confirmPassword"}
                                    label={"Confirm New Password"}
                                    placeholder="Confirm new password"
                                    required={true}
                                    errors={errors}
                                    control={control}
                                    secure={true}
                                    inputProps={{ fontSize: "15px" }}
                                    containerStyles={{ mt: 5 }}
                                />

                                <PrimaryButton
                                    isLoading={resetPasswordQuery.isLoading}
                                    mt={5}
                                    type="submit"
                                    p="18px 33px"
                                    bg={getColor(colorKeys.spanishYellow, colorMode)}
                                    w="full"
                                    rounded="md"
                                    fontWeight={"bold"}
                                    color="white"
                                >
                                    Reset
                                </PrimaryButton>


                            </chakra.form>

                        </Box>

                    </Box>


                </Card>

            </Flex>
        </Flex>
    )
}

export default Reset