import { APP_PERMISSION_ACTIONS, permissionOptions } from "../../constants";
import { preparePermissionsArray } from "../permissionHelper";
import { capitalizeFirstLetter, extractTextInSquareBrackets } from "../stringHelper";

export const preparePayload = (values, id) => {
  let payload = {
    ...(id && { id }),
    name: values.name,
    description: values.description,
    permissions: preparePermissionsArray(values.permissions, values.additionalActions),
  };
  return payload;
};

export const resolveDeps = ({ name, data, getValues, setValue }) => {

  const moduleName = extractTextInSquareBrackets(name)
  const permissions = data['permissions'][moduleName]
  const module = permissionOptions.find(item => item.module.name === moduleName)

  if (Array.isArray(permissions) && permissions?.length !== 0) {
    permissions?.forEach((item) => {
      const currentDependentPermission = module.dependentPermissions?.[`${moduleName}.${capitalizeFirstLetter(item)}`]
      currentDependentPermission?.forEach((item) => {
        const dep = item.split(".")

        let depValues = getValues(`permissions[${dep?.at(0)}]`)
        if (!depValues) {
          depValues = []
        }
        if (!depValues.includes(dep?.at(1)?.toLowerCase())) {
          depValues.push(dep?.at(1)?.toLowerCase())
        }
        setValue(`permissions[${dep?.at(0)}]`, depValues)
      })
    })
  } else if (typeof permissions === "boolean") {
    const currentDependentPermission = module.dependentPermissions?.[`${moduleName}.${capitalizeFirstLetter(APP_PERMISSION_ACTIONS.VIEW)}`]
    currentDependentPermission?.forEach((item) => {
      const dep = item.split(".")

      let depValues = getValues(`permissions[${dep?.at(0)}]`)
      if (!depValues) {
        depValues = []
      }
      if (!depValues.includes(APP_PERMISSION_ACTIONS.VIEW?.toLowerCase())) {
        depValues.push(APP_PERMISSION_ACTIONS.VIEW?.toLowerCase())
      }
      setValue(`permissions[${dep?.at(0)}]`, depValues)
    })
  }
}

export const watchRoleForm = ({ type, data, name, getValues, setValue }) => {
  if (type === "change") {
    if (name.match("permission")) {
      resolveDeps({ name, data, getValues, setValue })
    }
  }
}



