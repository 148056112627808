import React from 'react'
import { Button, Flex, HStack, Heading, Icon, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import APP_ICONS from '../../../../config/constants/icons'
import BookingRatings from './BookingRatings'
import CostBreakdown from './CostBreakdown'
import CancelBooking from './CancelBooking'
import { BOOKINGS_STATUSES, TIME_SWITCH, TIME_SWITCHES } from '../../../../config/constants/enums'
import { useTrackBooking } from '../../../../config/query/bookingsQuery'
import DispatchLogs from './DispatchLogs'
import BookingFormModal from '../BookingForm/BookingFormModal'
import CompleteBooking from './CompleteBooking'
import { getBookingActions } from '../../../../config/helpers/formHelpers/bookingHelper'
import Transactions from './Transactions'
import MenuSelect from '../../../../components/Forms/MenuSelect'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'

const BookingActions = ({ bookingQuery, timeZoneDisclosure }) => {
    const permissions = useSelector((state) => state?.user?.permissions)
    const isGlobal = useSelector((state) => state?.user?.user?.isGlobal)
    const ratingsDisclosure = useDisclosure()
    const dispatchLogsDisclosure = useDisclosure()
    const completeDisclosure = useDisclosure()
    const costBreakdownDisclosure = useDisclosure()
    const bookingFormDisclosure = useDisclosure()
    const transactionsDisclosure = useDisclosure()

    const { bookingId } = useParams();

    const trackBookingQuery = useTrackBooking(bookingId)

    const handleTrackBooking = () => {
        trackBookingQuery
            .mutateAsync()
            .then((res) => window.open(res?.linkUrl))
            .catch((err) => console.warn(err))
    }

    const isActive = () => {
        return bookingQuery?.data?.bookingStatus?.id === BOOKINGS_STATUSES.Active
            || bookingQuery?.data?.bookingStatus?.id === BOOKINGS_STATUSES.Scheduled;
    }

    const bookingActions = getBookingActions({
        transactionsDisclosure,
        ratingsDisclosure,
        bookingData: bookingQuery?.data,
        bookingFormDisclosure,
        completeDisclosure,
        costBreakdownDisclosure,
        dispatchLogsDisclosure,
        trackLoading: trackBookingQuery.isLoading,
        handleTrackBooking,
        permissions,
        isGlobal
    })

    const onTimeSwitch = (e) => {
        if (e?.value === TIME_SWITCHES.BOOKING_ZONE) {
            timeZoneDisclosure.onClose()
        }
        else {
            timeZoneDisclosure.onOpen()
        }
    }

    return (
        <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Heading fontSize="16px">
                Booking Details
            </Heading>

            <HStack spacing={1}>

                <MenuSelect
                    value={timeZoneDisclosure.isOpen ? TIME_SWITCH.LOCAL : TIME_SWITCH.BOOKING_ZONE}
                    onChange={onTimeSwitch}
                    options={makeSelectList(TIME_SWITCH, "value", "label", false)}
                    buttonProps={{ size: "sm", fontWeight: "bold", fontSize: "13px", w: "auto" }}
                />

                <Menu size={"sm"}>
                    <MenuButton fontSize="13px" as={Button} size="sm" rightIcon={<Icon as={APP_ICONS.DownChevron} boxSize="5" />}>
                        Options
                    </MenuButton>
                    <MenuList>
                        {bookingActions.map((item, index) => {
                            if (!item.render) return null
                            return (
                                <MenuItem fontSize={"14px"} key={index} onClick={item.action}>{item.name}</MenuItem>
                            )
                        })}
                    </MenuList>
                </Menu>

                <Button
                    isLoading={bookingQuery.isFetching}
                    onClick={bookingQuery.refetch}
                    size="sm"
                    fontSize="13px"
                >
                    Refresh
                </Button>

                {isActive() &&
                    <CancelBooking
                        bookingId={bookingId}
                        refresh={bookingQuery.refetch}
                    />
                }

            </HStack>

            <DispatchLogs
                isOpen={dispatchLogsDisclosure.isOpen}
                onClose={dispatchLogsDisclosure.onClose}
                timeZoneOffSet={bookingQuery?.data?.timeZoneOffSet}
                timeZoneDisclosure={timeZoneDisclosure}
            />
            <BookingRatings data={bookingQuery?.data} disclosure={ratingsDisclosure} />
            <CostBreakdown disclosure={costBreakdownDisclosure} />
            {bookingFormDisclosure.isOpen && <BookingFormModal disclosure={bookingFormDisclosure} data={bookingQuery?.data} onSuccess={bookingQuery?.refetch} />}
            <CompleteBooking bookingData={bookingQuery.data} disclosure={completeDisclosure} onSuccess={bookingQuery?.refetch} />
            <Transactions isOpen={transactionsDisclosure.isOpen} onClose={transactionsDisclosure.onClose} />
        </Flex>

    )
}

export default BookingActions