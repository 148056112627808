import React, { useEffect } from 'react'
import { Box, SimpleGrid, VStack } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import FormModal from '../../../components/Forms/FormModal'
import FormInput from '../../../components/Forms/FormInput'
import { EMAIL_REGEX, } from '../../../config/constants/regexConstants'
import { useTenant, useCreateTenant, useUpdateTenant } from '../../../config/query/tenantQuery'
import FormFieldsSkeleton from '../../../components/Skeletons/FormFieldsSkeleton'
import { prepareForm, preparePayload } from '../../../config/helpers/formHelpers/tenantHelper'
import FormSwitch from '../../../components/Forms/FormSwitch'
import FormDateInput from '../../../components/Forms/FormDateInput'
import moment from 'moment'

const TenantForm = ({ disclosure, data }) => {

    const queryClient = useQueryClient()

    const tenantQuery = useTenant(data?.id)
    const createTenantQuery = useCreateTenant()
    const updateTenantQuery = useUpdateTenant()

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: { errors },
    } = useForm({
        values: prepareForm({
            formValues: data,
            serverValues: tenantQuery.data
        })
    })

    const onSubmit = (values) => {
        let formMutate = data?.id ? updateTenantQuery.mutateAsync : createTenantQuery.mutateAsync
        formMutate(preparePayload(values, data?.id))
            .then((res) => {
                disclosure.onClose()
                queryClient.invalidateQueries(["tenants"])
                queryClient.invalidateQueries(["tenant"])
            })
            .catch((error) => {
                console.warn(error)
            })
    }


    useEffect(() => {
        if (!data?.id) resetForm()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id])

    return (
        <FormModal
            title={data?.id ? "Edit Tenant" : "Add Tenant"}
            disclosure={disclosure}
            isSubmitting={createTenantQuery.isLoading || updateTenantQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"75rem"}
            reset={resetForm}
            onCloseFn={() => {
                resetForm()
            }}
        >
            <VStack spacing={5} align="stretch">

                <Box>

                    <FormFieldsSkeleton count={7} loading={tenantQuery.isFetching} />

                    {!tenantQuery.isFetching &&
                        <VStack spacing={5} align="stretch">

                            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

                                <FormInput
                                    label={"Name"}
                                    control={control}
                                    errors={errors}
                                    id="name"
                                    required={true}
                                    placeholder="Enter tenant name"
                                    inputProps={{ size: "sm" }}
                                />

                                <FormInput
                                    id={"adminEmail"}
                                    label="Admin Email"
                                    placeholder="someone@example.com"
                                    required={true}
                                    errors={errors}
                                    control={control}
                                    rules={{
                                        pattern: {
                                            value: EMAIL_REGEX,
                                            message: "Invalid email address"
                                        }
                                    }}
                                    inputProps={{ size: "sm" }}
                                />

                                <FormDateInput
                                    label={"Valid Upto"}
                                    control={control}
                                    errors={errors}
                                    id="validUpto"
                                    required={true}
                                    placeholder={"Select expiry date of tenant"}
                                    inputProps={{ size: "sm" }}
                                    minDate={new Date(moment().format("YYYY-MM-DD"))}
                                    rules={{
                                        validate: {
                                            minExpiry: (value) => {
                                                //min expiry date is 1 day from today
                                                if (value && moment().diff(value, 'days') > -1) {
                                                    return "Expiry date must be at least 1 day from today"
                                                }
                                                return true
                                            },
                                        }
                                    }}
                                />

                                <FormInput
                                    label={"Issuer Name"}
                                    control={control}
                                    errors={errors}
                                    id="issuer"
                                    required={true}
                                    placeholder="Enter issuer name"
                                    inputProps={{ size: "sm" }}
                                />

                                <FormSwitch
                                    control={control}
                                    errors={errors}
                                    id={"isActive"}
                                    label={"Status"}
                                    inputProps={{ size: "sm" }}
                                />


                            </SimpleGrid>

                        </VStack>
                    }

                </Box>

            </VStack>

        </FormModal>
    )
}

export default TenantForm