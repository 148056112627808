import React from "react";
import { Outlet, useParams } from "react-router";
import { Box, Flex } from "@chakra-ui/react";
import ChatList from "./ChatList";
import NoResults from "../../../components/SVGComponents/NoResults";
import { colorKeys } from "../../../config/constants/appColors";

const Chats = () => {
  const { chatId } = useParams();
  return (
    <Flex w="full">
      <Box maxW={{ base: "320px", lg: "375px" }} w="full">
        <ChatList />
      </Box>

      {chatId ? (
        <Box w="full">
          <Outlet />
        </Box>
      ) : (
        <Flex
          w="full"
          justify={"center"}
          align={"center"}
          maxW={"550px"}
          m="auto"
          h="calc(100vh - 250px)"
        >
          <NoResults
            empty="Open any chat to continue"
            containerProps={{ maxW: "250px" }}
            textColor={colorKeys.dimText}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Chats;
