import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import CustomTable from "../../../../components/BasicUI/CustomTable";
import { makeInitialQueryObject } from "../../../../config/helpers/queryHelper";
import CityForm from "./CityForm";
import { useCities, useDeleteCity } from "../../../../config/query/cityQuery";
import useDebounce from "../../../../config/hooks/useDebounce";
import { APP_MODULES } from "../../../../config/constants";
import Chronology from "../../../../components/BasicUI/DataBoxes/Chronology";

const City = ({
  selectedRow,
  setSelectedRow,
  disclosure,
  setIsRefreshing,
  isRefreshing,
}) => {

  const [query, setQuery] = useState(makeInitialQueryObject());
  const debouncedQuery = useDebounce(query);

  const cityQuery = useCities(debouncedQuery);
  const deleteCityQuery = useDeleteCity();

  const onEditModal = (item) => {
    setSelectedRow(item);
    disclosure.onOpen();
  };

  const onQueryChange = (updatedQuery) => {
    setQuery((prev) => ({ ...prev, ...updatedQuery }));
  };

  const handleDelete = (id) => {
    deleteCityQuery
      .mutateAsync(id)
      .catch((error) => console.warn(error));
  };

  useEffect(() => {
    if (isRefreshing) cityQuery.refetch().then(() => setIsRefreshing(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing]);

  return (
    <Box>
      <CustomTable
        permissionKey={APP_MODULES.City}
        tableFor={"city"}
        tableWrapperProps={{ padding: 0, h: "calc(100vh - 315px)" }}
        hideFilterBar={true}
        containerProps={{ mt: 4, h: "calc(100vh - 266px)" }}
        head={[
          { title: "Name", extractor: "name", align: "left" },
          { title: "State", extractor: "state", align: "left", component: (item) => item?.state?.name, },
          { title: "Country", extractor: "country", align: "left", component: (item) => item?.country?.name, },
          { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
          { title: "Action", extractor: "actions", align: "center" },
        ]}
        data={cityQuery?.data?.data}
        loading={cityQuery?.isLoading}
        totalResults={cityQuery?.data?.meta?.totalCount}
        totalPages={cityQuery?.data?.meta?.totalPages}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => cityQuery.refetch()}
        isRefreshing={cityQuery?.isFetching}
        onEdit={onEditModal}
        onDelete={handleDelete}
      />

      <CityForm data={selectedRow} disclosure={disclosure} />
    </Box>
  );
};

export default City;
