import {
    useQuery,
    useMutation,
    useQueryClient
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS, { DEFAULT_SEARCH_KEY } from "../constants/api";

export const usePromoCodes = (params) => {
    const token = useSelector((state) => state.user?.token)
    params = appendQueryParams({ ...params, [DEFAULT_SEARCH_KEY]: API_CONSTANTS.PROMO_CODES.searchableKeys }, false)
    return useQuery({
        queryKey: [API_CONSTANTS.PROMO_CODES.base, params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PROMO_CODES.base}?${params}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const usePromoCode = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: [API_CONSTANTS.PROMO_CODES.base, id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.PROMO_CODES.base}/${id}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        enabled: !!id
    })
}


export const useCreatePromoCode = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.PROMO_CODES.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return data
        },
        onSuccess: () => queryClient.invalidateQueries([API_CONSTANTS.PROMO_CODES.base])
    })
}

export const useUpdatePromoCode = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: API_CONSTANTS.PROMO_CODES.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return data
        },
        onSuccess: () => queryClient.invalidateQueries([API_CONSTANTS.PROMO_CODES.base])
    })
}

export const useDeletePromoCode = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.PROMO_CODES.base}/${id}`,
                token,
                toastMessage: true,
                toastError: true,
            })
            return data
        },
    })
}


export const useUpdatePromoCodeStatus = () => {
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationKey: ["promo-code-status"],
        mutationFn: async (body) => {
            const response = await Patch({
                path: `${API_CONSTANTS.PROMO_CODES.updateStatus.replace(
                    ":id",
                    body.id
                )}?status=${body.status}`,
                token,
                toastError: true,
                toastMessage: false,
            });
            return response;
        },
    });
};
