import React from 'react'

const WhiteLogo = ({ w = 52, h = 52, containerStyles }) => {
    return (
        <svg
            width={w}
            height={h}
            viewBox={`0 0 ${w} ${h}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={containerStyles}
        >
            <path
                d="M33.0925 16.724C33.0704 17.4098 33.1442 18.2654 33.0557 19.1061C32.8344 21.3113 31.8019 23.0519 30.0023 24.3278C29.3533 24.7924 28.6379 25.1169 27.8635 25.3234C27.7455 25.3529 27.716 25.4045 27.716 25.5225C27.7234 26.7468 27.716 27.9637 27.716 29.188C27.716 30.191 26.9564 30.9802 25.9829 30.9802C24.9651 30.9802 24.1833 30.2132 24.1759 29.1954C24.1759 27.9785 24.1686 26.7616 24.1759 25.5447C24.1759 25.3972 24.1317 25.3529 23.9989 25.3087C21.1964 24.4163 19.5001 22.5282 18.9027 19.6445C18.8216 19.2536 18.7994 18.8554 18.7994 18.4571C18.7994 17.1148 18.7994 15.7652 18.7994 14.4229C18.7994 13.5674 19.3157 12.8815 20.0975 12.6602C21.2554 12.3431 22.3469 13.1912 22.3469 14.4303C22.3543 15.7799 22.3469 17.1296 22.3469 18.4866C22.3469 20.3378 23.8293 21.9161 25.6805 22.0341C27.8045 22.1668 29.5377 20.5369 29.5377 18.3981C29.5377 17.0558 29.5377 15.7209 29.5377 14.3786C29.5377 13.3904 30.3268 12.5938 31.3077 12.5938C32.2886 12.5938 33.0704 13.3904 33.0704 14.386C33.0925 15.1162 33.0925 15.8389 33.0925 16.724Z"
                fill="white"
            />
            <path
                d="M27.7456 0.889455C27.7456 0.358442 28.1955 -0.0471931 28.7265 0.0044332C30.7989 0.225689 32.8197 0.690325 34.7815 1.39097C37.3481 2.31287 39.7229 3.6109 41.8912 5.27769C44.8855 7.57875 47.2603 10.4034 49.0378 13.7296C50.3284 16.1487 51.2061 18.7153 51.6928 21.4146C51.7813 21.894 51.8329 22.3734 51.9067 22.8527C51.9731 23.31 51.6338 23.7746 51.1913 23.8336C50.6677 23.9074 50.2252 23.5903 50.1588 23.0961C50.026 22.012 49.8269 20.9426 49.5466 19.8879C48.6911 16.6281 47.2235 13.6706 45.1363 11.0303C42.2452 7.36486 38.6092 4.7098 34.221 3.08726C32.5911 2.48249 30.9022 2.08423 29.1838 1.84085C28.9625 1.81135 28.7339 1.78923 28.5052 1.75973C28.0627 1.7081 27.7456 1.33934 27.7456 0.889455Z"
                fill="white"
            />
            <path
                d="M28.6601 51.9995C28.1954 52.0142 27.7972 51.6823 27.7529 51.2398C27.7013 50.7457 27.9963 50.3179 28.483 50.2589C29.2943 50.1483 30.113 50.0377 30.9169 49.868C34.1472 49.1895 37.1194 47.8989 39.8261 46.0108C43.2703 43.5991 45.9401 40.5089 47.8208 36.7476C48.9418 34.4982 49.6941 32.1307 50.0555 29.6453C50.0923 29.4019 50.1218 29.1585 50.1587 28.9151C50.2251 28.4431 50.6602 28.1039 51.1249 28.1555C51.6116 28.2145 51.9582 28.6423 51.9066 29.1217C51.5821 31.9242 50.8003 34.6014 49.5982 37.1532C48.2338 40.0369 46.4121 42.5961 44.1111 44.8087C41.0725 47.7292 37.5324 49.7943 33.5055 51.0259C31.9863 51.4906 30.4301 51.8003 28.8444 51.9847C28.7854 51.9921 28.7264 51.9921 28.6601 51.9995Z"
                fill="white"
            />
            <path
                d="M0.000113106 22.9044C0.0591146 22.3365 0.154992 21.7391 0.26562 21.1417C0.715506 18.7448 1.4899 16.4511 2.58143 14.268C3.69508 12.0555 5.08899 10.0421 6.77053 8.2204C8.91671 5.90459 11.4021 4.03867 14.2268 2.62263C16.6385 1.4131 19.183 0.609208 21.8454 0.181447C22.0224 0.151946 22.1994 0.129821 22.3764 0.107695C22.9148 0.0339433 23.3573 0.351076 23.4311 0.867339C23.4974 1.36148 23.1508 1.79661 22.6198 1.86299C21.6315 1.99574 20.6506 2.19487 19.6845 2.44563C16.3066 3.33802 13.2607 4.88681 10.5614 7.09199C6.84428 10.1379 4.24822 13.9509 2.75843 18.5161C2.27904 19.9912 1.94716 21.5105 1.77016 23.0519C1.71116 23.5313 1.32027 23.8779 0.855634 23.8484C0.346747 23.8189 -0.00726208 23.4428 0.000113106 22.9044Z"
                fill="white"
            />
            <path
                d="M0.00012207 29.0774C0.00012207 28.5759 0.361506 28.185 0.833518 28.1555C1.30553 28.126 1.71116 28.4652 1.76279 28.9446C2.09467 31.7251 2.88382 34.3654 4.12285 36.873C5.08162 38.8052 6.2764 40.5753 7.70719 42.1831C9.82386 44.5726 12.3167 46.4754 15.1856 47.8841C17.3318 48.9387 19.5886 49.6615 21.956 50.0524C22.1847 50.0893 22.4133 50.1335 22.6493 50.1557C23.1434 50.2147 23.4974 50.6498 23.4458 51.1292C23.3942 51.6233 22.9443 51.9773 22.4428 51.9109C19.6624 51.5274 17.0073 50.7383 14.485 49.5066C12.0438 48.3119 9.83861 46.7852 7.88419 44.9045C4.0786 41.2391 1.60054 36.8361 0.442633 31.6882C0.258253 30.8548 0.118125 30.014 0.00749725 29.1733C0.00012207 29.129 0.00012207 29.0848 0.00012207 29.0774Z"
                fill="white"
            />
            <path
                d="M25.3265 33.3624C25.3265 33.2075 25.3265 33.0526 25.3265 32.9051C25.3265 32.824 25.3487 32.7945 25.4298 32.7945C25.71 32.7945 25.9977 32.7945 26.2779 32.7945C26.3591 32.7945 26.3886 32.824 26.3812 32.9051C26.3812 33.2149 26.3738 33.532 26.3812 33.8418C26.3812 33.9376 26.3369 33.9524 26.2558 33.9524C25.9829 33.9524 25.7174 33.945 25.4445 33.9524C25.3487 33.9524 25.3118 33.9229 25.3192 33.827C25.3265 33.6721 25.3265 33.5173 25.3265 33.3624Z"
                fill="white"
            />
            <path
                d="M18.556 33.0157C17.7005 32.9789 16.8376 33.0084 15.9747 32.9936C15.8125 32.9936 15.783 33.0452 15.7903 33.1928C15.7977 34.2474 15.7903 35.3021 15.7903 36.3641C15.7903 37.4261 15.7903 38.4881 15.7903 39.5502C15.7903 39.6829 15.8125 39.7345 15.96 39.7272C16.1665 39.7124 16.3803 39.7272 16.5869 39.7198C17.3096 39.705 18.0398 39.7788 18.7552 39.6829C19.8024 39.5428 20.5989 39.0265 21.0857 38.0677C21.4692 37.3081 21.5208 36.5042 21.4102 35.6782C21.2111 34.181 20.0606 33.0821 18.556 33.0157ZM19.9204 37.9276C19.5 38.5398 18.8805 38.7758 18.1578 38.7758C17.7816 38.7758 17.4055 38.7684 17.0367 38.7758C16.904 38.7758 16.8597 38.7463 16.8597 38.6061C16.8671 37.8539 16.8597 37.0942 16.8597 36.342C16.8597 35.5897 16.8597 34.8448 16.8597 34.0925C16.8597 33.9671 16.8819 33.9229 17.022 33.9303C17.5087 33.945 17.9955 33.8934 18.4749 33.9598C19.4337 34.0851 20.0606 34.6383 20.2818 35.5897C20.4736 36.4083 20.4146 37.2122 19.9204 37.9276Z"
                fill="white"
            />
            <path
                d="M36.8022 36.6813C36.5367 35.4349 35.5779 34.6752 34.2873 34.6752C33.2252 34.6752 32.3476 35.2578 31.9493 36.2166C31.5437 37.1901 31.7428 38.3554 32.4582 39.0708C33.181 39.7936 34.0586 39.9558 35.0248 39.7346C35.6517 39.5945 36.1532 39.2404 36.4851 38.6726C36.5367 38.5767 36.5441 38.5324 36.4187 38.4956C36.1901 38.4439 35.9688 38.3775 35.7476 38.3038C35.6738 38.2817 35.6443 38.2964 35.6074 38.3628C35.2682 38.8938 34.6634 39.1372 34.0513 39.0118C33.4613 38.8864 32.9745 38.348 32.886 37.7507C32.8639 37.6253 32.8934 37.581 33.0335 37.5884C33.6383 37.5958 34.2504 37.5884 34.8552 37.5884C35.4673 37.5884 36.0794 37.5884 36.699 37.5884C36.8096 37.5884 36.8465 37.5589 36.8612 37.4483C36.8612 37.1975 36.8538 36.9394 36.8022 36.6813ZM35.6296 36.873C35.187 36.8583 34.7445 36.8656 34.302 36.8656C33.8595 36.8656 33.417 36.8583 32.9745 36.8656C32.827 36.8656 32.8122 36.814 32.8344 36.696C32.9671 35.9659 33.5645 35.457 34.2873 35.4496C35.0174 35.4422 35.6369 35.9511 35.7771 36.6739C35.8066 36.8066 35.7992 36.873 35.6296 36.873Z"
                fill="white"
            />
            <path
                d="M31.5585 34.7637C31.3372 34.771 31.116 34.7784 30.8947 34.7637C30.7251 34.7489 30.6661 34.8227 30.6145 34.9702C30.172 36.2387 29.7294 37.5146 29.2648 38.8274C29.2279 38.7168 29.2058 38.6504 29.1837 38.5914C28.7559 37.3671 28.3282 36.1428 27.9078 34.9112C27.8709 34.8079 27.834 34.7637 27.716 34.7637C27.4505 34.771 27.185 34.771 26.9269 34.7637C26.7794 34.7563 26.7794 34.8079 26.8236 34.9259C26.949 35.2431 27.0744 35.5676 27.1924 35.8847C27.6644 37.1164 28.129 38.3406 28.601 39.5723C28.6305 39.646 28.6379 39.7345 28.7633 39.7272C29.0731 39.7198 29.3754 39.7198 29.6852 39.7272C29.7663 39.7272 29.8032 39.6977 29.8327 39.6239C30.4375 38.0456 31.0349 36.4673 31.6396 34.889C31.6839 34.7932 31.6765 34.7563 31.5585 34.7637Z"
                fill="white"
            />
            <path
                d="M24.9135 34.8153C24.9135 34.7342 24.884 34.7121 24.8029 34.7121C24.4488 34.6973 24.1243 34.7784 23.8293 34.9849C23.5343 35.1988 23.3131 35.4717 23.0992 35.7741C23.0992 35.4791 23.0918 35.1915 23.0992 34.8964C23.0992 34.7932 23.0697 34.7563 22.9664 34.7563C22.7304 34.7637 22.487 34.7637 22.251 34.7563C22.1478 34.7563 22.1183 34.7858 22.1183 34.8891C22.1183 36.4526 22.1183 38.0235 22.1183 39.5871C22.1183 39.6829 22.1404 39.7124 22.2437 39.7124C22.4944 39.7051 22.7452 39.7051 22.9959 39.7124C23.1361 39.7198 23.1803 39.6829 23.1803 39.5354C23.1729 38.702 23.1951 37.8613 23.1656 37.0279C23.1508 36.6001 23.3426 36.3125 23.6449 36.0765C23.9768 35.811 24.3751 35.7077 24.7881 35.6635C24.8619 35.6561 24.9135 35.6635 24.9135 35.5602C24.9061 35.3242 24.9135 35.0661 24.9135 34.8153Z"
                fill="white"
            />
            <path
                d="M26.3811 37.2344C26.3811 36.46 26.3811 35.6856 26.3811 34.9186C26.3811 34.8153 26.3664 34.7637 26.241 34.7637C25.9755 34.7711 25.71 34.7711 25.4518 34.7637C25.3412 34.7637 25.3191 34.8006 25.3191 34.9038C25.3191 36.4674 25.3191 38.0309 25.3191 39.5944C25.3191 39.7051 25.356 39.7272 25.4592 39.7272C25.71 39.7198 25.9607 39.7124 26.2115 39.7272C26.3516 39.7346 26.3811 39.6903 26.3811 39.5502C26.3811 38.7758 26.3811 38.0014 26.3811 37.2344Z"
                fill="white"
            />
        </svg>

    )
}

export default WhiteLogo