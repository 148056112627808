import { useState } from 'react';
import CustomTable from '../../../components/BasicUI/CustomTable'
import TableInfoPopover from '../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'
import { formatDateTime } from '../../../config/helpers/dateHelper'
import { Box, Button, useDisclosure } from '@chakra-ui/react'
import TableHeaderOptions from '../../../components/BasicUI/CustomTable/TableHeaderOptions'
import APP_ICONS from '../../../config/constants/icons'
import SubAdminForm from './SubAdminForm'
import { useSubAdmins, useUpdateAdminStatus, useResendSubAdminEmail } from '../../../config/query/subAdminQuery'
import { getFilters } from '../../../config/helpers/filterHelper'
import { STATUS } from '../../../config/constants/enums';
import { makeInitialQueryObject, queryStringToObject, updateUrl } from '../../../config/helpers/queryHelper'
import useDebounce from '../../../config/hooks/useDebounce'
import appendQueryParams from '../../../config/helpers/appendQueryParams'
import { APP_MODULES } from '../../../config/constants'
import StatusSwitch from '../../../components/BasicUI/DataBoxes/StatusSwitch'
import BreakText from '../../../components/BasicUI/DataBoxes/BreakText'
import { getPermission } from '../../../config/helpers/permissionHelper'
import { useSelector } from 'react-redux'
import { trimRole } from '../../../config/helpers/stringHelper'

const SubAdmin = () => {
    const user = useSelector((state) => state.user?.user);
    const formDisclosure = useDisclosure()
    const [selectedSubAdmin, setSelectedSubAdmin] = useState(null)

    const permissions = useSelector((state) => state?.user?.permissions)
    const subAdminPermissions = getPermission({ permissionKey: APP_MODULES.SubAdmin, permissions })

    const [query, setQuery] = useState(makeInitialQueryObject(queryStringToObject()))
    const debouncedQuery = useDebounce(query)

    const subAdminsQuery = useSubAdmins(debouncedQuery)
    const subAdminStatus = useUpdateAdminStatus()
    const resendEmail = useResendSubAdminEmail()

    const onEditModal = (item) => {
        setSelectedSubAdmin(item)
        formDisclosure.onOpen()
    }

    const onQueryChange = (updatedQuery) => {
        let temp = {}
        setQuery((prev) => {
            temp = { ...prev, ...updatedQuery }
            return temp
        })
        updateUrl({ pageTitle: "Sub Admins", queryString: appendQueryParams(temp) })
    }

    const handleStatusChange = (status, id) => {
        subAdminStatus.mutate({ status: Number(status), id });
    };

    const onResendEmail = (id) => {
        resendEmail
            .mutateAsync(id)
            .catch((err) => console.warn(err))
    }

    return (
        <Box>
            <TableHeaderOptions
                title={"Sub Admin"}
                actionText={"Add New Sub Admin"}
                action={() => {
                    setSelectedSubAdmin(null)
                    formDisclosure.onOpen()
                }}
                icon={APP_ICONS.ADD}
                permissionKey={APP_MODULES.SubAdmin}
            />
            <CustomTable
                permissionKey={APP_MODULES.SubAdmin}
                tableFor={"sub admin"}
                searchPlaceholder="Search sub admins"
                filters={getFilters({
                    Status: Object.keys(STATUS).map((item) => ({
                        key: item,
                        value: STATUS[item],
                    }))
                })}
                head={[
                    {
                        title: "Name",
                        extractor: "name",
                        align: "left",
                        isSortable: true,
                        component: (item) => (
                            <TableInfoPopover
                                data={item}
                                onEdit={(item) => onEditModal(item)}
                                triggerOnClick={() => onEditModal(item)}
                                moreActions={subAdminPermissions?.additionalActions?.includes("ResendVerificationEmail") && <Button
                                    size="sm"
                                    isLoading={resendEmail.isLoading}
                                    onClick={() => onResendEmail(item.id)}
                                >
                                    Resend Email
                                </Button>}
                            >
                                <LabelValuePair label="Description" value={item.description} />
                                <LabelValuePair
                                    label="Status"
                                    value={true}
                                    valueComponent={
                                        <StatusBadge value={item.status} />
                                    }
                                />
                                <LabelValuePair label="Created" value={formatDateTime(item.createdOn)} />
                                <LabelValuePair label="Last Updated" value={formatDateTime(item.lastModifiedOn)} />
                            </TableInfoPopover>
                        )
                    },
                    { title: "Phone", extractor: "phone", isSortable: true },
                    { title: "Email", extractor: "email", isSortable: true, },
                    { title: "Role", extractor: "roles", component: (item) => <BreakText value={item.roles?.map((item) => item.name).join(", ")} /> },
                    {
                        title: "Status",
                        extractor: "status",
                        align: "center",
                        isSortable: true,
                        component: (item) => (
                            <StatusSwitch
                                key={item.id}
                                value={item.status?.id}
                                onChange={(e) => handleStatusChange(e.target.checked, item.id)}
                                isDisabled={trimRole(user?.id) === item?.id}
                            />
                        ),
                    },
                    { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
                    { title: "Actions", extractor: "actions", align: "center" }
                ]}
                data={subAdminsQuery?.data?.data}
                loading={subAdminsQuery?.isLoading}
                totalResults={subAdminsQuery?.data?.meta?.totalCount}
                totalPages={subAdminsQuery?.data?.meta?.totalPages}
                pageNo={query?.PageNumber}
                pageSize={query?.PageSize}
                onQueryChange={onQueryChange}
                query={query}
                onRefresh={subAdminsQuery.refetch}
                isRefreshing={subAdminsQuery?.isFetching}

                onEdit={onEditModal}
            />

            <SubAdminForm disclosure={formDisclosure} data={selectedSubAdmin} />
        </Box>

    )
}

export default SubAdmin