var Config = {
    ENVIRONMENT: "PRODUCTION", //current selected environment
    ENVIRONMENTS: {
        DEVELOPMENT: {
            BASE_URL: "https://dev-ydrive-as.azurewebsites.net/api",
            PERSIST_SECRET_KEY: "83jd9mjoaiskdp9kDASJDO#)@IDK)SAKDOFAKSdlaskf032kdaLASMCAXMCKL#@)DQsmaW_D{L#PKDOSJdaFAJLSKCLAMLK_@_@#)I@KMLDJSALDJLASROW",
            GOOGLE_MAP_API_KEY: "AIzaSyDLtrI9p7sQmgFjzQAhH48ko-de_Kc6mQM",
            STRIPE_PUBLISHABLE_KEY: 'pk_test_51N8NQjKe3xZKOjlDVHw3ddXdLEvROhpAehAlCtnVDk4YYLdQ32Z88W4nvsWHe0szDq6QxXkKZCaA3SXPq4UUl5Ii00fkyKBf1V',
            FCM_KEY: "AAAAwcMv6aQ:APA91bEkhewdekPn9ZpCdnLKAORZvtOhpJkRjLOQRhm8JflbGPcA30E6ChRY_ELk2B9EnCnc9bHxNlqUZbdn1grUBg78K2_HtH0nZcsHIFS6EZhAdJGlPxO72pbItHp7_mRfAnvxdsed"
        },
        STAGING: {
            BASE_URL: "https://stg-ydrive-as.azurewebsites.net/api",
            PERSIST_SECRET_KEY: "83jd9mjoaiskdp9kDASJDO#)@IDK)SAKDOFAKSdlaskf032kdaLASMCAXMCKL#@)DQsmaW_D{L#PKDOSJdaFAJLSKCLAMLK_@_@#)I@KMLDJSALDJLASROW",
            GOOGLE_MAP_API_KEY: "AIzaSyDLtrI9p7sQmgFjzQAhH48ko-de_Kc6mQM",
            STRIPE_PUBLISHABLE_KEY: 'pk_test_51NkqSwFaU5y50XiPIBCI748a2IHs5iUoGdTjna95drOpSRp1vHI7TigculEz7c03MwNtMsdNuWsAlWIeVUV3EiBh00oC3laHJO',
            FCM_KEY: "AAAAwcMv6aQ:APA91bEkhewdekPn9ZpCdnLKAORZvtOhpJkRjLOQRhm8JflbGPcA30E6ChRY_ELk2B9EnCnc9bHxNlqUZbdn1grUBg78K2_HtH0nZcsHIFS6EZhAdJGlPxO72pbItHp7_mRfAnvxdsed"
        },
        PRODUCTION: {
            BASE_URL: "https://api.ridemanagementsoftware.com/api",
            PERSIST_SECRET_KEY: "83jd9mjoaiskdp9kDASJDO#)@IDK)SAKDOFAKSdlaskf032kdaLASMCAXMCKL#@)DQsmaW_D{L#PKDOSJdaFAJLSKCLAMLK_@_@#)I@KMLDJSALDJLASROW",
            GOOGLE_MAP_API_KEY: "AIzaSyBZlU2NPuHvbtBARx1R4oQx46pMG417qZw",
            STRIPE_PUBLISHABLE_KEY: 'pk_live_51NkqSwFaU5y50XiPDJFGBmCKxAhI8PXXSohQvmVBPJW4rySdjJIGLIuTm5a9nQBLjI0CIQA1xpk9aIKTjHhBZA8F00Z2CGaNQ2',
            APP_NAME: "YDrive Admin",
            FCM_KEY: "AAAAwcMv6aQ:APA91bEkhewdekPn9ZpCdnLKAORZvtOhpJkRjLOQRhm8JflbGPcA30E6ChRY_ELk2B9EnCnc9bHxNlqUZbdn1grUBg78K2_HtH0nZcsHIFS6EZhAdJGlPxO72pbItHp7_mRfAnvxdsed"
        }
    }
};
Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};
export default Config;