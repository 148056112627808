import { CORPORATE_CUSTOMER_STATUSES } from "../constants/enums"

export const makeSelectList = (array, value = "id", label = "name", parseEnum = true, labelHof) => {
    if (!array) return []
    if (!Array.isArray(array))
        return Object.keys(array).map((key) => {
            if (parseEnum) {
                return {
                    value: Number(key),
                    label: array[key],
                }
            }
            else {
                return {
                    value: key,
                    label: labelHof ? labelHof(array[key]) : array[key],
                }
            }
        })

    return array?.map((item) => {
        return {
            value: parseEnum ? Number(item[value]) : item[value],
            label: item[label],
        }
    })
}

export const getDisabledCorporateUserStatus = (status) => {
    const disabledOptions = [
        status,
    ]

    if (status === CORPORATE_CUSTOMER_STATUSES.Pending) {
        disabledOptions.push(CORPORATE_CUSTOMER_STATUSES.Blocked)
    }
    return disabledOptions
}