import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Box, Button, Card, CardBody, Flex, HStack, Heading, Icon, IconButton, SimpleGrid, Text, VStack, chakra, useColorMode, useDisclosure } from '@chakra-ui/react'
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton"
import { getColor, colorKeys } from '../../../config/constants/appColors'
import FormInput from '../../../components/Forms/FormInput'
import FormButton from '../../../components/Forms/FormButton'
import FormPhoneInput from '../../../components/Forms/FormPhoneInput'
import FormFileUpload from '../../../components/Forms/FormFileUpload'
import { updateProfile } from '../../../config/redux/slices/userSlice'
import { useProfile, useUpdateProfile, useUpdateProfileImage } from '../../../config/query/profileQuery'
import APP_ICONS from '../../../config/constants/icons'
import { DEFAULT_COUNTRY_CODE } from '../../../config/constants/settings'
import { formatPhoneNumber } from '../../../config/helpers/stringHelper'

const Profile = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user?.user)
  const editModeDisclosure = useDisclosure()
  const [profilePicture, setProfilePicrture] = useState(user?.profilePictureUrl || user?.profilePicture)
  const { colorMode } = useColorMode()

  const profileQuery = useProfile()
  const updateProfileQuery = useUpdateProfile()
  const updateImageQuery = useUpdateProfileImage()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    setError,
    watch
  } = useForm({
    values: { countryCode: DEFAULT_COUNTRY_CODE, ...user }
  })

  function onSubmit(values) {
    const payload = {
      ...values,
      profilePictureURL: user?.profilePictureUrl,
      email: user?.email,
      localNumber: formatPhoneNumber(values?.countryCode, values?.localNumber)
    }

    updateProfileQuery
      .mutateAsync(payload)
      .then((updatedUser) => {
        dispatch(updateProfile({
          email: updatedUser?.email,
          name: updatedUser?.name,
          phone: updatedUser?.phone,
          localNumber: updatedUser?.localNumber,
          countryCode: updatedUser?.countryCode,
        }))
        editModeDisclosure.onClose()
      })
      .catch((err) => console.warn(err))
  }

  const onChangeImage = (file) => {
    setProfilePicrture(URL.createObjectURL(file))
    updateImageQuery.mutateAsync({ AdminImage: file })
      .then(() => {
        profileQuery.refetch().then((response) => {
          dispatch(updateProfile({
            profilePictureUrl: response?.data?.profilePictureUrl,
          }))
        })
      })
      .catch((error) => console.warn(error))
  }

  const onCancel = () => {
    editModeDisclosure.onClose()
    reset()
  }

  const localNumber = watch("localNumber")
  const countryCode = watch("countryCode")

  return (
    <Box>

      <Box p="14px 20px" bg={getColor(colorKeys.tableBackground, colorMode)}>
        <HStack spacing={5}>
          <Avatar bg={getColor(colorKeys.avatarBg, colorMode)} boxSize={"80px"} name={user?.name} src={user?.profilePictureUrl || user?.profilePicture} />
          <Box>
            <Heading fontSize="16px">{user?.name}</Heading>
            <Text fontSize="13px" color={getColor(colorKeys.secondaryText, colorMode)}>{user?.email}</Text>
            <Text fontSize="13px" color={getColor(colorKeys.secondaryText, colorMode)}>{user?.id}</Text>
          </Box>
        </HStack>
      </Box>
      <Card mt={4}>
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} p={{ base: "0", md: "20px 0" }}>

            <chakra.form onSubmit={handleSubmit(onSubmit)}>
              <Flex maxW="500px" justify="space-between" align="center">
                <Heading minW="fit-content" fontSize={"20px"} mb={2}>Personal Information</Heading>
                {editModeDisclosure.isOpen
                  ? <HStack spacing={2}>
                    <FormButton
                      mr="auto"
                      isLoading={updateProfileQuery.isLoading}
                      type="submit"
                      size="sm"
                      containerStyles={{ textAlignLast: "right" }}
                    >
                      Update Profile
                    </FormButton>
                    <IconButton size="sm" onClick={onCancel} icon={<Icon as={APP_ICONS.CLOSE} boxSize="3" />} />
                  </HStack>
                  : <Button size="sm" onClick={() => editModeDisclosure.onOpen()} leftIcon={<Icon as={APP_ICONS.EDIT} />}>Edit</Button>
                }
              </Flex>
              <Box maxW="500px">

                <VStack spacing={4}>

                  <FormInput
                    id={"name"}
                    label="Name"
                    placeholder="Enter your name"
                    required={true}
                    errors={errors}
                    control={control}
                    inputProps={{
                      isReadOnly: !editModeDisclosure.isOpen
                    }}
                  />

                  <FormPhoneInput
                    id={"localNumber"}
                    label="Phone Number"
                    type="tel"
                    placeholder="Enter your phone number"
                    required={true}
                    errors={editModeDisclosure.isOpen ? errors : {}}
                    control={control}
                    setCountryCodeValue={(value) => {
                      setValue("countryCode", value)
                    }}
                    countryCodeValue={countryCode}
                    inputProps={{
                      isReadOnly: !editModeDisclosure.isOpen
                    }}
                    countryCodeSelectProps={{
                      isReadOnly: !editModeDisclosure.isOpen
                    }}
                    phoneNumberValue={localNumber}
                    setPhoneNumberValue={(value) => {
                      setValue("localNumber", value)
                    }}
                    setError={setError}
                    clearErrors={clearErrors}

                  />
                </VStack>

              </Box>

            </chakra.form>

            <Box>
              <Heading fontSize={"20px"}>Profile Picture</Heading>
              <Flex mt={4} align={"center"}>
                <Box>
                  <Avatar
                    size="2xl"
                    name={user?.name}
                    src={profilePicture}
                  />
                </Box>
                <Flex flexDir={"column"} mx={8}>
                  <FormFileUpload
                    id={"profilePicture"}
                    onChange={onChangeImage}
                    component={<PrimaryButton my={1}
                      isLoading={updateImageQuery.isLoading}
                    >
                      Upload Image
                    </PrimaryButton>}
                    componentProps={{ variant: "ghost" }}
                    fileType={"IMAGE"}
                  />
                </Flex>
              </Flex>
              <Text mt={3} color={getColor(colorKeys.gray, colorMode)} fontStyle={"italic"} fontSize={"14px"}>Profile picture with 250x300 pixels fits best to your profile</Text>
            </Box>

          </SimpleGrid>

        </CardBody>

      </Card>
    </Box>
  )
}

export default Profile