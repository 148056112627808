import React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
} from "@chakra-ui/react";
import { colorKeys, getColor } from "../../../config/constants/appColors";

const MenuSelect = ({
  options,
  onChange,
  value,
  buttonProps,
  hideIcon,
  disabledOptions = [],
  hiddenOptions = [],
}) => {
  const { colorMode } = useColorMode();
  return (
    <Menu size="sm">
      <MenuButton
        fontSize="12px"
        fontWeight={"normal"}
        rounded="md"
        textAlign={"left"}
        bg={getColor(colorKeys.lightestGray, colorMode)}
        color={getColor(colorKeys.primaryText, colorMode)}
        h="30px"
        w="150px"
        as={Button}
        size="sm"
        rightIcon={!hideIcon ? <ChevronDownIcon /> : undefined}
        {...buttonProps}
      >
        {value}
      </MenuButton>
      <MenuList>
        {options?.map((item, index) => {
          if (hiddenOptions.includes(item.value)) return null;
          return (
            <MenuItem
              fontSize="12px"
              fontWeight={"normal"}
              onClick={() => onChange(item)}
              key={index}
              pointerEvents={
                disabledOptions.includes(item?.value) ? "none" : "unset"
              }
              opacity={disabledOptions.includes(item?.value) ? "0.6" : "unset"}
            >
              {item?.label || item}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default MenuSelect;
