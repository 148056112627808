import { useState } from "react";

export const useForceUpdate = () => {
    const [, setForceUpdate] = useState(0);

    const forceUpdate = () => {
        setForceUpdate((prev) => prev + 1);
    };

    return forceUpdate;
};
