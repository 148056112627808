import { SimpleGrid, VStack } from "@chakra-ui/react";
import React from "react";
import FormModal from "../../../../components/Forms/FormModal";
import { useForm } from "react-hook-form";
import FormInput from "../../../../components/Forms/FormInput";
import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateModel,
  useUpdateModel,
} from "../../../../config/query/modelQuery";
import { useMakeLookup } from "../../../../config/query/makeQuery";
import FormSearchSelect from "../../../../components/Forms/FormSearchSelect";
import { makeSelectList } from "../../../../config/helpers/selectListHelper";

const ModelForm = ({ disclosure, data }) => {
  const queryClient = useQueryClient();
  const makes = useMakeLookup();
  const createModelQuery = useCreateModel();
  const updateModelQuery = useUpdateModel();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm({
    values: {
      ...(data?.id && { id: data?.id }),
      name: data?.name,
      makeId: data?.make?.id,
    },
  });

  const onSubmit = (values) => {

    const payload = {
      id: data?.id,
      ...values,
    };

    let formMutate = data?.id
      ? updateModelQuery.mutateAsync
      : createModelQuery.mutateAsync;
    formMutate(payload)
      .then(() => {
        disclosure.onClose();
        resetForm();
        queryClient.invalidateQueries(["models"]);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return (
    <FormModal
      title={data?.id ? "Edit Model" : "Add Model"}
      disclosure={disclosure}
      isSubmitting={createModelQuery.isLoading || updateModelQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"50rem"}
      saveButtonText={"Save"}
      reset={resetForm}
      onCloseFn={() => resetForm()}
    >
      <VStack spacing={5} align="stretch">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
          <FormSearchSelect
            id="makeId"
            label="Make"
            placeholder={"Select Make"}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(makes.data?.data)}
            inputProps={{ size: "sm" }}
          />

          <FormInput
            label={"Name"}
            control={control}
            errors={errors}
            id="name"
            placeholder="Enter name"
            required={true}
            type="text"
            inputProps={{ size: "sm" }}
          />
        </SimpleGrid>
      </VStack>
    </FormModal>
  );
};

export default ModelForm;
