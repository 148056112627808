import { Box, Card, CardBody, Flex, Heading, Text, VStack, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor } from '../../../../config/constants/appColors'
import { formatDateTime, formatDateTimeToZone, guessTimeZone } from '../../../../config/helpers/dateHelper';
import StatusBadge from '../../../../components/BasicUI/Badges/StatusBadge';
import { EXTERNAL_WALLET_TYPE, PAYMENT_TYPE } from '../../../../config/constants/enums';
import LabelValueRow from '../../../../components/BasicUI/DataBoxes/LabelValueRow';
import { DEFAULT_DATE_TIME_WITH_ZONE } from '../../../../config/constants';
import { TIME_ZONES } from '../../../../config/constants/date';
import { breakWithCaps, concatStrings } from "../../../../config/helpers/stringHelper";
import { Link } from 'react-router-dom';

const BookingData = ({ bookingId, data, isLoading, timeZoneDisclosure }) => {
    const { colorMode } = useColorMode();
    return (
        <VStack spacing={5} align="stretch">
            <Card w="full" mt={5}>
                <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
                    <Flex flexWrap={"wrap"} w="full">
                        {[
                            {
                                label: "Booking #",
                                value: bookingId,
                            },
                            {
                                label: "Booking Type",
                                value: <VStack align="stretch" spacing={2}>
                                    <Text>{data?.rideType?.name}</Text>
                                    {timeZoneDisclosure?.isOpen
                                        ? <Text>{data?.rideTime ? `${formatDateTime(data?.rideTime, DEFAULT_DATE_TIME_WITH_ZONE)} ${TIME_ZONES[guessTimeZone()]})` : "N/A"}</Text>
                                        : <Text>{data?.rideTime ? formatDateTimeToZone(data?.rideTime, data?.timeZoneOffSet, DEFAULT_DATE_TIME_WITH_ZONE) : "N/A"}</Text>}
                                </VStack>,
                            },
                            {
                                label: "Pickup / Dropoff Time",
                                value: <VStack align="stretch" spacing={2}>
                                    {timeZoneDisclosure?.isOpen
                                        ? <>
                                            <Text>{data?.pickupTime ? `${formatDateTime(data?.pickupTime, DEFAULT_DATE_TIME_WITH_ZONE)} ${TIME_ZONES[guessTimeZone()]})` : "N/A"}</Text>
                                            <Text>{data?.dropOffTime ? `${formatDateTime(data?.dropOffTime, DEFAULT_DATE_TIME_WITH_ZONE)} ${TIME_ZONES[guessTimeZone()]})` : "N/A"}</Text>
                                        </>
                                        : <>
                                            <Text>{data?.pickupTime ? formatDateTimeToZone(data?.pickupTime, data?.timeZoneOffSet, DEFAULT_DATE_TIME_WITH_ZONE) : "N/A"}</Text>
                                            <Text>{data?.dropOffTime ? formatDateTimeToZone(data?.dropOffTime, data?.timeZoneOffSet, DEFAULT_DATE_TIME_WITH_ZONE) : "N/A"}</Text>
                                        </>}
                                </VStack>,
                            },
                            {
                                label: "Customer",
                                value: <VStack align="stretch" spacing={2}>
                                    <Text _hover={{ textDecor: "underline" }} as={Link} to={`/admin/customers/${data?.customerId}`}>{data?.customerName || "N/A"}</Text>
                                    <Text>{data?.customerPhone || "N/A"}</Text>
                                </VStack>,
                            },
                            {
                                label: "Driver",
                                value: <VStack align="stretch" spacing={2}>
                                    <Text _hover={{ textDecor: "underline" }} as={Link} to={`/admin/drivers/${data?.driverId}`}>{data?.driverName || "N/A"}</Text>
                                    {/* <Text>{(data?.driverPhoneNo || data?.vehicle || data?.vehicleType?.name) ? `${data?.driverPhoneNo} / ${data?.vehicle}` : "N/A"}</Text> */}
                                    <Text>{concatStrings("/", data?.driverPhoneNo, data?.vehicle, data?.vehicleType?.name) ? concatStrings("/", data?.driverPhoneNo, data?.vehicle, data?.vehicleType?.name) : "N/A"}</Text>
                                </VStack>,
                            },
                            {
                                label: "Cancellation Reason",
                                value: data?.cancellationReason || data?.cancellationType?.name,
                            },
                            {
                                label: "Payment Method",
                                value: <VStack align="stretch" spacing={2}>
                                    <Text>{breakWithCaps((data?.externalWalletType?.id !== undefined) ? EXTERNAL_WALLET_TYPE[data?.externalWalletType?.id] : PAYMENT_TYPE[data?.paymentMethod?.id])}</Text>
                                    {data?.card?.name && <Text>**** **** **** {data?.card?.name}</Text>}
                                </VStack>
                            },
                            {
                                label: "Status",
                                value: data?.status,
                                render: (item, key) => (
                                    <Box key={key} p="5px 10px" minW="250px">
                                        <Heading
                                            color={getColor(colorKeys.primaryText, colorMode)}
                                            fontWeight={"500"}
                                            fontSize={"16px"}
                                        >
                                            {item?.label}
                                        </Heading>
                                        <StatusBadge isLoading={isLoading} value={data?.status?.id} bookingStatus={true} />
                                    </Box>
                                ),
                            },
                        ].map((item, index) =>
                            item.render ? (
                                item.render(item, index)
                            ) : (
                                <Box mb={4} key={index} p="5px 10px" minW="250px">
                                    <Heading
                                        color={getColor(colorKeys.primaryText, colorMode)}
                                        fontWeight={"500"}
                                        fontSize={"16px"}
                                    >
                                        {item.label}
                                    </Heading>
                                    <Text maxW="350px" mt={2} fontSize="13px">
                                        {item.value || "N/A"}
                                    </Text>
                                </Box>
                            )
                        )}
                    </Flex>
                </CardBody>
            </Card>

            <Heading fontSize="16px">
                Notes
            </Heading>
            <Card w="full">
                <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
                    <VStack align="stretch" w="full">
                        {[
                            {
                                label: "Dispatcher Notes",
                                value: data?.dispatcherNote,
                            },
                            {
                                label: "Customer Notes",
                                value: data?.customerNote,
                            },
                            {
                                label: "Internal Notes",
                                value: data?.internalNote,
                            },
                        ].map((item, index) => (
                            <LabelValueRow labelProps={{ w: "250px" }} key={index} label={item.label} value={item.value || "-"} />
                        ))}
                    </VStack>
                </CardBody>
            </Card>
        </VStack>
    )
}

export default BookingData