import React from "react";
import { Box, Wrap, WrapItem, useDisclosure } from "@chakra-ui/react";
import CardBox from "../../../../components/Payments/CardBox";
import TableHeaderOptions from "../../../../components/BasicUI/CustomTable/TableHeaderOptions";
import NoResults from "../../../../components/SVGComponents/NoResults";
import CardForm from "./CardForm";
import {
  useCorporateCards,
  useDeleteCorporateCard,
} from "../../../../config/query/corporateQuery";

const CorporateCards = ({ data }) => {
  const cardFormDisclosure = useDisclosure();

  const corporateCardsQuery = useCorporateCards(data?.id);
  const deleteCorporateCard = useDeleteCorporateCard();

  const onDelete = (item) => {
    deleteCorporateCard
      .mutateAsync({ corporateId: data?.id, cardId: item.id })
      .then(() => corporateCardsQuery.refetch())
      .catch((err) => console.warn(err));
  };

  const onCardFormSuccess = () => {
    setTimeout(() => {
      corporateCardsQuery.refetch();
    }, 1000);
  };
  return (
    <Box px={2} pt={5}>
      <TableHeaderOptions
        title={"Manage Corporate Cards"}
        actionText={"Add Card"}
        action={() => cardFormDisclosure.onOpen()}
        disclosure={cardFormDisclosure}
        titleProps={{ fontSize: "18px" }}
        actionProps={{ size: "sm" }}
      />
      <Wrap mt={5} spacing={5}>
        {corporateCardsQuery.data?.data?.map((item) => (
          <WrapItem>
            <CardBox
              key={item.id}
              onDelete={() => onDelete(item)}
              data={item}
            />
          </WrapItem>
        ))}
      </Wrap>

      {!corporateCardsQuery.data?.data?.length && (
        <Box mt={5} maxW="450px" minH="calc(100vh - 450px)" m="auto">
          <NoResults />
        </Box>
      )}

      <CardForm
        disclosure={cardFormDisclosure}
        data={data}
        onSuccess={onCardFormSuccess}
      />
    </Box>
  );
};

export default CorporateCards;
