import React from "react";
import {
  useBookings,
  useDeleteBooking,
} from "../../../config/query/bookingsQuery";
import { Box, Card, CardBody, Heading, useColorMode } from "@chakra-ui/react";
import CustomTable from "../../../components/BasicUI/CustomTable";
import StatusBadge from "../../../components/BasicUI/Badges/StatusBadge";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import { useNavigate } from "react-router";
import sortOrders from "../../../config/constants/sortOrders";
import { makeInitialQueryObject } from "../../../config/helpers/queryHelper";
import BreakText from "../../../components/BasicUI/DataBoxes/BreakText";
import { APP_MODULES } from "../../../config/constants";
import BookingTablePopover from "../../../components/BasicUI/Popovers/BookingTablePopover";

const LatestBookings = () => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const bookingsQuery = useBookings(
    makeInitialQueryObject({
      SortOrder: sortOrders.DESC,
    })
  );
  const deleteBookingQuery = useDeleteBooking();

  const onView = (item) => {
    navigate(`/admin/bookings/${item.id}`);
  };

  const onDelete = (id) => {
    deleteBookingQuery
      .mutateAsync(id)
      .catch((error) => console.warn(error))
  };

  return (
    <Card mt={5} bg={getColor(colorKeys.tableBackground, colorMode)}>
      <CardBody>
        <Heading as="h2" size={"md"} fontSize="15px" fontWeight={"600"}>
          Latest Bookings
        </Heading>
        <Box mt={2}>
          <CustomTable
            permissionKey={APP_MODULES.Booking}
            tableFor="booking"
            hideFilterBar={true}
            tableWrapperProps={{ padding: 0 }}
            head={[
              {
                title: "Booking #",
                extractor: "id",
                align: "left",
                isSortable: true,
                component: (item) => <BookingTablePopover data={item} />
              },
              { title: "Pickup", extractor: "pickupDropoff", align: "left" },
              { title: "Drop-off", extractor: "city", align: "left" },
              {
                title: "Driver Name",
                extractor: "driverName",
                align: "left",
                isSortable: true,
              },
              {
                title: "Passenger Name",
                extractor: "passengerName",
                align: "left",
                isSortable: true,
              },
              {
                title: "Pickup Address",
                extractor: "pickupAddress",
                align: "left",
                isSortable: true,
                component: (item) => <BreakText value={item.pickupAddress} />,
              },
              { title: "Flight Number", extractor: "flightNumber", align: "left" },
              { title: "Gate Number", extractor: "gateNumber", align: "left" },
              {
                title: "Drop-off Address",
                extractor: "dropoffAddress",
                align: "left",
                isSortable: true,
                component: (item) => <BreakText value={item.dropoffAddress} />,
              },

              {
                title: "Status",
                extractor: "status",
                component: (item) => <StatusBadge value={item.status?.id} bookingStatus={true} />,
                align: "center"
              },
              { title: "Actions", extractor: "actions", align: "center" },
            ]}
            data={bookingsQuery.data?.data}
            loading={bookingsQuery?.isLoading}
            totalResults={bookingsQuery?.data?.totalResults}
            totalPages={bookingsQuery?.data?.totalPages}
            onRefresh={bookingsQuery.refetch}
            isRefreshing={bookingsQuery?.isFetching}
            theadRowProps={{
              className: "active-bookings-table-header"
            }}
            colorRows={true}
            onView={onView}
            onDelete={onDelete}
          />
        </Box>
      </CardBody>
    </Card>
  );
};

export default LatestBookings;
