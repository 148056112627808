import React, { useState } from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import CustomTable from "../../../components/BasicUI/CustomTable";
import TableInfoPopover from "../../../components/BasicUI/Popovers/TableInfoPopover";
import LabelValuePair from "../../../components/BasicUI/LabelValuePair";
import { formatDateTime } from "../../../config/helpers/dateHelper";
import TableHeaderOptions from "../../../components/BasicUI/CustomTable/TableHeaderOptions";
import APP_ICONS from "../../../config/constants/icons";
import RoleForm from "./RoleForm";
import { useRoles, useDeleteRole } from "../../../config/query/rolesQuery";
import useDebounce from "../../../config/hooks/useDebounce";
import { queryStringToObject, updateUrl } from "../../../config/helpers/queryHelper";
import appendQueryParams from "../../../config/helpers/appendQueryParams";
import { APP_MODULES } from "../../../config/constants";
import sortOrders from "../../../config/constants/sortOrders";
import Chronology from "../../../components/BasicUI/DataBoxes/Chronology";

const Roles = () => {
  const formDisclosure = useDisclosure();
  const [selectedRole, setSelectedRole] = React.useState(null);
  const [query, setQuery] = useState({
    PageNumber: 1,
    PageSize: 20,
    Sort: "lastModifiedOn",
    SortOrder: sortOrders.DESC,
    ...queryStringToObject()
  });
  const debouncedQuery = useDebounce(query);

  const rolesQuery = useRoles(debouncedQuery);
  const deleteRoleQuery = useDeleteRole();

  const onEditModal = (item) => {
    setSelectedRole(item);
    formDisclosure.onOpen();
  };

  const onQueryChange = (updatedQuery) => {
    let temp = {}
    setQuery((prev) => {
      temp = { ...prev, ...updatedQuery }
      return temp
    })
    updateUrl({ pageTitle: "Roles", queryString: appendQueryParams(temp) })
  }

  const handleDelete = (id) => {
    deleteRoleQuery
      .mutateAsync(id)
      .then(() => rolesQuery.refetch())
      .catch((error) => console.warn(error));
  };

  return (
    <Box>
      <TableHeaderOptions
        title={"Roles"}
        actionText={"Add New Role"}
        action={() => {
          setSelectedRole(null);
          formDisclosure.onOpen();
        }}
        icon={APP_ICONS.ADD}
        permissionKey={APP_MODULES.Roles}
      />
      <CustomTable
        tableFor="roles"
        searchPlaceholder="Search roles"
        permissionKey={APP_MODULES.Roles}
        head={[
          {
            title: "Name",
            extractor: "name",
            align: "left",
            isSortable: true,
            component: (item) => (
              <TableInfoPopover
                data={item}
                onEdit={(item) => onEditModal(item)}
                fallBackText={"N/A"}
                triggerText={item.name}
              // triggerOnClick={() => onView(item)}
              >
                <LabelValuePair label="Description" value={item.description} />

                <LabelValuePair
                  label="Created"
                  value={formatDateTime(item.createdOn)}
                />
              </TableInfoPopover>
            ),
          },
          {
            title: "Description",
            extractor: "description",
            isSortable: true,
          },
          { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
          {
            title: "Actions",
            extractor: "actions",
            align: "center",
          },
        ]}
        data={rolesQuery?.data?.data}
        loading={rolesQuery?.isLoading}
        totalResults={rolesQuery?.data?.meta?.totalCount}
        totalPages={rolesQuery?.data?.meta?.totalPages}
        pageNo={query?.PageNumber}
        pageSize={query?.PageSize}
        onQueryChange={onQueryChange}
        query={query}
        onRefresh={() => rolesQuery?.refetch()}
        isRefreshing={rolesQuery?.isFetching}
        onDelete={handleDelete}
        onEdit={(item) => onEditModal(item)}
      />

      <RoleForm disclosure={formDisclosure} data={selectedRole} />
    </Box>
  );
};

export default Roles;
