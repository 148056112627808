import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS, { DEFAULT_SEARCH_KEY } from "../constants/api";

export const useRatings = (params) => {
    const token = useSelector((state) => state.user?.token)
    params = appendQueryParams({ ...params, [DEFAULT_SEARCH_KEY]: API_CONSTANTS.RATING.searchableKeys }, false)
    return useQuery({
        queryKey: ["ratings-list", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.RATING.base}?${params}`,
                token,
            })
            return data
        },
        keepPreviousData: true
    })
}
