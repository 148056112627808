import { APP_MODULES } from "."

const APP_SCREENS = [
    {
        name: "Bookings",
        description: "View all bookings",
        link: "/admin/bookings",
        permission: APP_MODULES.Booking
    },
    {
        name: "Active Bookings",
        description: "View all currently active bookings",
        link: "/admin/active-bookings",
        permission: APP_MODULES.Booking
    },
    {
        name: "Dashboard",
        description: "View charts and statistics for all bookings and drivers",
        link: "/admin/dashboard",
        permission: APP_MODULES.Dashboard
    },
    {
        name: "Support",
        description: "Support chats with customers and drivers",
        link: "/admin/chats",
        permission: APP_MODULES.ChatAndMessaging
    },
    {
        name: "Disputes",
        description: "View all disputes, chat with customers and drivers to resolve them",
        link: "/admin/chats/disputes",
        permission: APP_MODULES.ChatAndMessaging
    },
    {
        name: "Groups",
        description: "Group chats with customers and drivers",
        link: "/admin/chats/groups",
        permission: APP_MODULES.ChatAndMessaging
    },
    {
        name: "Create Booking",
        description: "Create a new booking for a customer",
        link: "/admin/bookings/create",
        permission: APP_MODULES.Booking
    },
    {
        name: "Live Map",
        description: "Track all bookings and drivers on a live map",
        link: "/admin/map",
        permission: APP_MODULES.Map
    },
    {
        name: "Corporate Management",
        description: "View all corporate accounts and manage them",
        link: "/admin/corporate",
        permission: APP_MODULES.Corporate
    },
    {
        name: "Customer Management",
        description: "View all customer accounts and manage them",
        link: "/admin/customers",
        permission: APP_MODULES.Customer
    },
    {
        name: "Driver Management",
        description: "View all driver accounts and manage them",
        link: "/admin/drivers",
        permission: APP_MODULES.Driver
    },
    {
        name: "Vehicle Management",
        description: "View all registered vehicles and manage them",
        link: "/admin/vehicle-management",
        permission: APP_MODULES.VehicleManagement
    },
    {
        name: "Service Types",
        description: "View all service types and manage them",
        link: "/admin/vehicle-management/service-types",
        permission: APP_MODULES.VehicleManagement
    },
    {
        name: "Models",
        description: "Manage all vehicle models",
        link: "/admin/vehicle-management/model",
        permission: APP_MODULES.VehicleManagement
    },
    {
        name: "Makes",
        description: "Manage all vehicle makes",
        link: "/admin/vehicle-management/make",
        permission: APP_MODULES.VehicleManagement
    },
    {
        name: "Zones",
        description: "View all zones and manage them",
        link: "/admin/zones",
        permission: APP_MODULES.Zone
    },
    {
        name: "Create Zone",
        description: "Create a new zone",
        link: "/admin/zones/create",
        permission: APP_MODULES.Zone
    },
    {
        name: "Pricing",
        description: "View all pricing and manage them",
        link: "/admin/pricing",
        permission: APP_MODULES.Pricing
    },
    {
        name: "Default Rates",
        description: "View all default rates pricing and manage them",
        link: "/admin/pricing",
        permission: APP_MODULES.Pricing,
    },
    {
        name: "Zone Wise Rates",
        description: "Manage and update zone wise rates",
        link: "/admin/pricing/zone-wise-rates",
        permission: APP_MODULES.Pricing,
    },
    {
        name: "Corporate Rates",
        description: "Manage and update corporate rates",
        link: "/admin/pricing/corporate-pricing",
        permission: APP_MODULES.Pricing,
    },
    {
        name: "Promo Codes",
        description: "View all promo codes and manage them",
        link: "/admin/promo-codes",
        permission: APP_MODULES.PromoCode
    },
    {
        name: "Notifications",
        description: "Create and send notifications to customers, drivers and corporates",
        link: "/admin/notifications",
        permission: APP_MODULES.Notifications
    },
    {
        name: "Sub Admin",
        description: "View all sub admin accounts and manage them",
        link: "/admin/sub-admin",
        permission: APP_MODULES.SubAdmin
    },
    {
        name: "Roles",
        description: "Create roles to manage permissions for sub admin accounts",
        link: "/admin/roles",
        permission: APP_MODULES.Roles
    },
    {
        name: "Reviews",
        description: "List of all rides reviews submitted by customers and drivers",
        link: "/admin/reviews",
        permission: APP_MODULES.Reviews
    },
    {
        name: "Booking Reports",
        description: "Generate booking reports with various filters",
        link: "/admin/reports/booking-details",
        permission: APP_MODULES.Reports
    },
    {
        name: "Transaction Reports",
        description: "Generate transaction reports with various filters",
        link: "/admin/reports/transactions",
        permission: APP_MODULES.Reports
    },
    {
        name: "Driver Reports",
        description: "Generate and download driver reports with date range and zone filters",
        link: "/admin/reports",
        permission: APP_MODULES.Reports
    },
    {
        name: "Profile",
        description: "View your profile and update information",
        link: "/admin/profile",
        permission: APP_MODULES.Profile
    },
    {
        name: "Configurations",
        description: "Override system default configurations for various modules",
        link: "/admin/settings/configurations",
        permission: APP_MODULES.SystemConfigurations
    },
    {
        name: "Integrations",
        description: "Update integration settings for mail and sms services",
        link: "/admin/settings/integrations",
        permission: APP_MODULES.SystemIntegrations
    },
    {
        name: "Shifts",
        description: "Add or update driver shifts",
        link: "/admin/settings/shifts",
        permission: APP_MODULES.Shift
    },
    {
        name: "SMTP Settings",
        description: "Update integration settings for mail services",
        link: "/admin/settings/integrations",
        permission: APP_MODULES.SystemIntegrations
    },
    {
        name: "SMS Settings",
        description: "Update integration settings for sms services",
        link: "/admin/settings/integrations",
        permission: APP_MODULES.SystemIntegrations
    },
    {
        name: "City Management",
        description: "View all cities, create new and manage them",
        link: "/admin/settings/cities",
        permission: APP_MODULES.City
    },
    {
        name: "State Management",
        description: "View all states, create new and manage them",
        link: "/admin/settings/state",
        permission: APP_MODULES.State
    },
    {
        name: "Country Management",
        description: "View all countries, create new and manage them",
        link: "/admin/settings/country",
        permission: APP_MODULES.Country
    },
    {
        name: "Change Password",
        description: "Change your account password",
        link: "/admin/change-password",
        permission: APP_MODULES.Profile
    },
    {
        name: "Files",
        description: "Update privacy policy, terms and conditions and other files",
        link: "/admin/settings/files",
        permission: APP_MODULES.SystemConfigurations
    },
]

export default APP_SCREENS