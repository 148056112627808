import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS, { DEFAULT_SEARCH_KEY } from "../constants/api";
import { CACHE_KEYS } from "../constants/cacheKeys";

export const useBookings = (params) => {
  const token = useSelector((state) => state.user?.token);

  if (Number(params?.RideStatus) !== 0) {
    delete params.CancellationReasonType
  }

  params = appendQueryParams({
    ...params, [DEFAULT_SEARCH_KEY]: API_CONSTANTS.BOOKINGS.searchableKeys
  }, false);

  return useQuery({
    queryKey: CACHE_KEYS.BOOKINGS.base(params),
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.BOOKINGS.base}?${params}`,
        token,
      });
      return data;
    },
    keepPreviousData: true,
  });
};

export const useBooking = (id) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: CACHE_KEYS.BOOKINGS.single(id),
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.BOOKINGS.base}/${id}`,
        token,
        toastError: true,
      });
      return data;
    },
    enabled: Boolean(id),
  });
};

export const useTrackBooking = (id) => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async () => {
      const data = await Get({
        path: API_CONSTANTS.BOOKINGS.trackBooking.replace(":id", id),
        token,
        toastError: true,
      });
      return data;
    },
  });
};

export const useCreateBooking = () => {
  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const { data } = await Post({
        path: API_CONSTANTS.BOOKINGS.base,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
    onSuccess: () => queryClient.invalidateQueries(CACHE_KEYS.BOOKINGS.index)
  });
};

export const useUpdateBooking = () => {
  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async ({ id, body }) => {
      const { data } = await Put({
        path: API_CONSTANTS.BOOKINGS.base,
        token,
        body: {
          rideId: id,
          ...body,
        },
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
    onSuccess: () => queryClient.invalidateQueries(CACHE_KEYS.BOOKINGS.index)
  })
}

export const useDeleteBooking = () => {
  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const { data } = await Delete({
        path: `${API_CONSTANTS.BOOKINGS.base}/${id}`,
        token,
        toastMessage: true,
      });
      return data;
    },
    onSuccess: () => queryClient.invalidateQueries(CACHE_KEYS.BOOKINGS.index)
  });
};

export const useCancelBooking = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async ({ id, body }) => {
      const { data } = await Patch({
        path: API_CONSTANTS.BOOKINGS.cancelBooking.replace(":id", id),
        token,
        body,
        toastMessage: true,
        toastError: true,
      });
      return data;
    },
  });
}

export const useCostBreakdown = (id) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["costBreakdown", id],
    queryFn: async () => {
      const data = await Get({
        path: API_CONSTANTS.BOOKINGS.costBreakdown.replace(":id", id),
        token,
        toastError: true,
      });
      return data;
    },
    enabled: Boolean(id),
  });
}

export const useDispatcherLogs = (params) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["dispatcherLogs", params],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.BOOKINGS.dispatchLogs}?${appendQueryParams(params)}`,
        token,
        toastError: true,
      });
      return data;
    },
    enabled: Boolean(params.RideId),
  });
}

export const useBookingConfigurations = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationKey: ["bookingConfigurations"],
    mutationFn: async () => {
      const data = await Get({
        path: API_CONSTANTS.SYSTEM_CONFIG.booking,
        token,
        toastError: true,
      });
      return data;
    },
  });
}

export const useUpdateBookingConfiguration = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const { data } = await Put({
        path: API_CONSTANTS.SYSTEM_CONFIG.booking,
        token,
        body,
        toastMessage: true,
      });
      return data;
    },
  });
}

export const useCompleteBooking = () => {
  const queryClient = useQueryClient()
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async ({ rideId, body }) => {
      const { data } = await Patch({
        path: API_CONSTANTS.BOOKINGS.completeBooking.replace(":bookingId", rideId),
        token,
        toastMessage: true,
        toastError: true,
        body,
      });
      return data;
    },
    onSuccess: () => queryClient.invalidateQueries(["bookings"])
  });
}

export const useEstimatedFare = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const data = await Post({
        path: API_CONSTANTS.BOOKINGS.getEstimatedFare.replace(":bookingId", id),
        token,
        toastError: true,
      });
      return data;
    },
  });
}