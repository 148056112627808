import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { Box, Text } from '@chakra-ui/react'
import { makeInitialQueryObject } from '../../../../config/helpers/queryHelper'
import { useTransactions } from '../../../../config/query/transactionQuery'
import useDebounce from '../../../../config/hooks/useDebounce'
import { formatAmount } from '../../../../config/helpers/stringHelper'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import { Link } from 'react-router-dom'

const Transactions = ({ setIsRefreshing, isRefreshing }) => {

    const [query, setQuery] = useState(makeInitialQueryObject())
    const debouncedQuery = useDebounce(query)

    const transactionsQuery = useTransactions(debouncedQuery)

    const onQueryChange = (updatedQuery) => {
        setQuery((prev) => ({ ...prev, ...updatedQuery }))
    }

    useEffect(() => {
        if (isRefreshing) transactionsQuery.refetch().then(() => setIsRefreshing(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshing])

    return (
        <Box>
            <CustomTable
                fixedHeight={true}
                tableWrapperProps={{ padding: 0, h: "calc(100vh - 200px)" }}
                containerProps={{ mt: 4 }}
                tableHeadTextProps={{ paddingRight: "30px" }}
                head={[
                    { title: "TRN ID#", extractor: "transactionId" },
                    { title: "Transaction Type", extractor: "vehicleType", component: (item) => item?.transactionType?.name },
                    { title: "Transaction Amount", extractor: "transactionAmount", component: (item) => formatAmount(item?.transactionAmount) },
                    { title: "Description", extractor: "description" },
                    { title: "Status", extractor: "status" },
                    { title: "Role", extractor: "role" },
                    { title: "Profile ID", extractor: "profileId" },
                    { title: "Ride ID", extractor: "rideId", component: (item) => <Text _hover={{ textDecor: "underline" }} as={Link} to={`/admin/bookings/${item.rideId}`}>{item.rideId}</Text> },
                    { title: "Date", extractor: "date", component: (item) => <Chronology data={item} /> },
                ]}
                data={transactionsQuery.data?.data}
                loading={transactionsQuery?.isLoading}
                totalResults={transactionsQuery?.data?.meta?.totalCount}
                totalPages={transactionsQuery?.data?.meta?.totalPages}
                pageNo={query?.PageNumber}
                pageSize={query?.PageSize}
                onQueryChange={onQueryChange}
                query={query}
                onRefresh={transactionsQuery.refetch}
                isRefreshing={transactionsQuery?.isFetching}
            />
        </Box>
    )
}

export default Transactions