import React, { useMemo, useState } from "react";
import {
  Input,
  List,
  ListItem,
  Box,
  useOutsideClick,
  VStack,
  InputGroup,
  Text,
  useColorMode,
  Divider,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import APP_SCREENS from "../../../config/constants/screens";
import { useSelector } from "react-redux";
import { getPermission } from "../../../config/helpers/permissionHelper";
import IMAGES from "../../../config/constants/images";
import APP_ICONS from "../../../config/constants/icons";

const ScreenSearch = () => {
  const [query, setQuery] = useState("");
  const { colorMode } = useColorMode();
  const [isListOpen, setIsListOpen] = useState(false);
  const permissions = useSelector((state) => state.user?.permissions);
  const isGlobal = useSelector((state) => state.user?.user?.isGlobal);
  const ref = React.useRef();

  useOutsideClick({
    ref: ref,
    handler: () => setIsListOpen(false),
  });

  const screens = useMemo(() => {
    const input = query?.trim()?.toLowerCase();
    return APP_SCREENS?.filter((item) => {
      return (
        item?.name?.toLowerCase().includes(input) ||
        item?.description?.toLowerCase().includes(input)
      );
    });
  }, [query]);

  return (
    <Box ref={ref}>
      <InputGroup size="sm">
        <Input
          rounded="sm"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => setIsListOpen(true)}
          size="sm"
          w="full"
          minW="320px"
          type="search"
          placeholder="Search"
          _focus={{ shadow: "none" }}
        />
        <InputRightElement children={<Icon as={APP_ICONS.SEARCH} />} />
      </InputGroup>
      {isListOpen && (
        <List
          minH="250px"
          w="320px"
          pos="absolute"
          bg={getColor(colorKeys.tableBackground, colorMode)}
          as={VStack}
          align="stretch"
          maxH="220px"
          overflowY="auto"
          shadow="md"
          _empty={{
            bgImage: `url(${IMAGES.EMPTY_BOX})`,
            bgRepeat: "no-repeat",
            bgSize: "40%",
            bgPosition: "center",
          }}
        >
          {screens?.map((item, index) => {
            const isPermitted = getPermission({
              permissions,
              permissionKey: item.permission,
              isGlobal,
            });
            if (!isPermitted) return null;
            return (
              <>
                <Divider />
                <ListItem
                  key={index}
                  cursor="pointer"
                  as={Link}
                  to={item?.link}
                  onClick={() => {
                    setIsListOpen(false);
                  }}
                  display={"flex"}
                >
                  <Box px={1}>
                    <Text fontWeight={"600"} ml={2} fontSize="14px">
                      {item?.name}
                    </Text>
                    <Text ml={2} fontSize="13px">
                      {item?.description}
                    </Text>
                  </Box>
                </ListItem>
              </>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default ScreenSearch;
