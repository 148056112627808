import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardBody,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router";
import TableHeaderOptions from "../../../components/BasicUI/CustomTable/TableHeaderOptions";
import CustomTabs from "../../../components/BasicUI/CustomTabs";
import { colorKeys, getColor } from "../../../config/constants/appColors";
import DefaultRates from "./DefaultRates";
import ZoneWiseRates from "./ZoneWiseRates";
import CorporatePricing from "./CorporatePricing";
import APP_ICONS from "../../../config/constants/icons";
import { APP_MODULES } from "../../../config/constants";

const Pricing = () => {
  const { colorMode } = useColorMode();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const defaultRateDisclosure = useDisclosure();
  const zoneWiseRateDisclosure = useDisclosure();
  const corporatePricingDisclosure = useDisclosure();

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const { pathname } = useLocation();

  const tabs = [
    {
      index: 0,
      addButtonText: "Add New Default Rate",
      head: "Default Rates",
      key: "default-rates",
      body: (
        <DefaultRates
          disclosure={defaultRateDisclosure}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      ),
      disclosure: defaultRateDisclosure,
    },
    {
      index: 1,
      addButtonText: false,
      head: "Zone Wise Rates",
      key: "zone-wise-rates",
      body: (
        <ZoneWiseRates
          disclosure={zoneWiseRateDisclosure}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      ),
      disclosure: zoneWiseRateDisclosure,
    },
    {
      index: 2,
      addButtonText: false,
      head: "Corporate Pricing",
      key: "corporate-pricing",
      body: (
        <CorporatePricing
          disclosure={corporatePricingDisclosure}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      ),
      disclosure: corporatePricingDisclosure,
    },
  ];

  const [selectedTab, setSelectedTab] = React.useState(
    tabs.find((tab) => pathname.includes(tab.key)) || tabs[0]
  );

  const onTabChange = (index) => {
    navigate(`/admin/pricing/${tabs[index].key}`);
    setSelectedTab(tabs[index]);
    setSelectedRow(null);
    setIsRefreshing(false);
  };

  useEffect(() => {
    setSelectedTab(tabs.find((tab) => pathname.includes(tab.key)) || tabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box>
      <TableHeaderOptions
        title={"Pricing"}
        actionText={selectedTab?.addButtonText}
        icon={APP_ICONS.ADD}
        action={() => {
          setSelectedRow(null);
          selectedTab?.disclosure?.onOpen();
        }}
        actionProps={{ minW: 200 }}
        containerProps={{ mt: 3 }}
        permissionKey={APP_MODULES.Pricing}
      />

      <Card mt={4}>
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
          <CustomTabs
            tabPanelProps={{
              p: 0,
            }}
            containerProps={{
              index: selectedTab?.index,
              onChange: onTabChange,
              isLazy: true,
            }}
            tabs={tabs}
            tabEndAction={{
              label: "Refresh",
              action: () => {
                setIsRefreshing(true);
                queryClient.refetchQueries(["pricings"]);
              },
              isLoading: isRefreshing,
            }}
          />
        </CardBody>
      </Card>
    </Box>
  );
};

export default Pricing;
