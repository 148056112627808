import { Box, Card, CardBody, VStack, useColorMode, useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router'
import { useDriver } from '../../../config/query/driverQuery'
import DriverForm from './DriverForm'
import UpdateCreditForm from './UpdateCreditForm'
import DriverBookings from './DriverBookings'
import { useSelector } from 'react-redux'
import { getPermission } from '../../../config/helpers/permissionHelper'
import { APP_MODULES } from '../../../config/constants'
import DriverData from './DriverData'
import CustomTabs from '../../../components/BasicUI/CustomTabs';
import { colorKeys, getColor } from '../../../config/constants/appColors';
import DriverDispatchLogs from './DriverDispatchLogs';

const DriverDetails = () => {
    const { colorMode } = useColorMode()
    const creditFormDisclosure = useDisclosure()
    const driverFormDisclosure = useDisclosure()
    const { driverId } = useParams()
    const driverQuery = useDriver(Number(driverId))
    const permissions = useSelector((state) => state?.user?.permissions)
    const driverPermissions = getPermission({ permissionKey: APP_MODULES.Driver, permissions })

    return (
        <Box>
            <VStack spacing={3}>
                <DriverData
                    driverQuery={driverQuery}
                    creditFormDisclosure={creditFormDisclosure}
                    driverFormDisclosure={driverFormDisclosure}
                />
                <Box w="full">
                    <Card w="full" bg={getColor(colorKeys.tableBackground, colorMode)}>
                        <CardBody>
                            <CustomTabs
                                tabPanelProps={{ p: 0 }}
                                tabs={[
                                    {
                                        head: "Bookings",
                                        body: <DriverBookings />
                                    },
                                    {
                                        head: "Dispatch Logs",
                                        body: <DriverDispatchLogs />
                                    },
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Box>
            </VStack >
            {driverPermissions?.additionalActions?.includes("UpdateCreditBalance") &&
                <UpdateCreditForm data={driverQuery.data} disclosure={creditFormDisclosure} />
            }
            <DriverForm disclosure={driverFormDisclosure} data={driverQuery.data} onSuccess={driverQuery.refetch} />
        </Box >
    )
}

export default DriverDetails   