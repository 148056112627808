import axios from "axios";
import { BASE_URL } from "../constants/api";
import { CONTENT_TYPE } from "../constants";
import errorHandler from "../helpers/errorHandler";
import { isUnauthorized } from "../helpers/apiHelper";
import { TOAST_TYPES, makeToast } from "../helpers/toastHelper";

async function Post({ path, token, body, toastError, toastMessage, contentType = CONTENT_TYPE.JSON, tenant }) {
  try {
    let url = BASE_URL + path;
    tenant = tenant || localStorage.getItem("tenant")

    const headers = { "Content-Type": contentType, tenant }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const response = await axios.post(url, body, { headers });

    if (toastMessage) {
      let message = response.data.message?.message || "Success!"
      makeToast({ type: TOAST_TYPES.SUCCESS, message })

    }
    return response.data;
  } catch (error) {
    if (toastError) {
      const message = errorHandler(error?.response?.data?.message)
      makeToast({ type: TOAST_TYPES.ERROR, message })
    }
    if (isUnauthorized(error)) {
      localStorage.clear();
      window.location.reload();
      return;
    }
    console.warn("Error in Post.js: ", error)
    throw new Error(error)
  }
}

export { Post };