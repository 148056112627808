import React, { useState, useEffect } from 'react'
import { Avatar, AvatarBadge, Box, Button, HStack, Icon, IconButton, SimpleGrid, SkeletonCircle, Text, VStack, useColorMode } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import FormFileUpload from '../../../../components/Forms/FormFileUpload'
import APP_ICONS from '../../../../config/constants/icons'
import { colorKeys, getColor } from '../../../../config/constants/appColors'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import FormInput from '../../../../components/Forms/FormInput'
import FormPhoneInput from '../../../../components/Forms/FormPhoneInput'
import { EMAIL_REGEX } from '../../../../config/constants/regexConstants'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'
import FormTextarea from '../../../../components/Forms/FormTextarea'
import { useCities, useLanguages } from '../../../../config/query/preferencesQuery'
import { useCreateCustomer, useCustomer, useUpdateCustomer, useUpdateCustomerImage } from '../../../../config/query/customerQuery'
import { prepareForm, preparePayload } from '../../../../config/helpers/formHelpers/customerHelper'
import FormButton from '../../../../components/Forms/FormButton'

const CustomerInfo = ({ data, disclosure, onSuccess }) => {
    const { colorMode } = useColorMode()

    const cities = useCities()
    const languages = useLanguages()

    const queryClient = useQueryClient()

    const customerQuery = useCustomer(data?.id)
    const createCustomerQuery = useCreateCustomer()
    const updateCustomerImage = useUpdateCustomerImage()
    const updateCustomerQuery = useUpdateCustomer()


    const [fileData, setFileData] = useState({
        binary: null,
        url: null,
    })

    const {
        handleSubmit,
        control,
        setValue,
        reset: resetForm,
        formState: { errors },
        setError,
        clearErrors,
        watch,
    } = useForm({
        values: prepareForm({
            formValues: data,
            serverValues: customerQuery.data
        })
    })

    const { name, countryCode, localNumber } = watch()

    const onSubmit = (values) => {
        let formMutate = data?.id ? updateCustomerQuery.mutateAsync : createCustomerQuery.mutateAsync
        formMutate(preparePayload(values, data?.id))
            .then((res) => {
                if (fileData?.binary) {
                    updateCustomerImage.mutate({
                        CustomerId: res?.id,
                        CustomerImage: fileData?.binary
                    })
                }
                if (onSuccess) {
                    onSuccess(res)
                }
                else {
                    disclosure.onClose()
                    resetForm()
                    queryClient.invalidateQueries(["customers"])
                    queryClient.invalidateQueries(["customer"])
                }
            })
            .catch((error) => {
                console.warn(error)
            })
    }

    useEffect(() => {
        if (data?.id) {
            setFileData({
                ...fileData,
                url: customerQuery.data?.profilePictureUrl
            })
        } else {
            resetForm()
            setFileData({
                binary: null,
                url: null,
            })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id, customerQuery.data?.profilePictureUrl])

    const onChangeImage = (file) => {
        setFileData({
            binary: file,
            url: URL.createObjectURL(file)
        })
    }

    const onCloseFn = () => {
        resetForm()
        setFileData({
            binary: null,
            url: null,
        })
    }

    useEffect(() => {
        if (!disclosure?.isOpen) {
            onCloseFn()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disclosure?.isOpen])

    return (
        <VStack spacing={5} align="stretch">

            <Box m="auto">
                {customerQuery?.isFetching ? <SkeletonCircle size='130' /> : (
                    <Avatar
                        size="2xl"
                        name={name}
                        src={fileData?.url}

                    >
                        <AvatarBadge boxSize='8'>
                            <FormFileUpload
                                id={"profilePicture"}
                                onChange={onChangeImage}
                                component={<IconButton
                                    variant={"ghost"}
                                    icon={<Icon as={APP_ICONS.EDIT} boxSize="7" color={getColor(colorKeys.primary, colorMode)} />}
                                />}
                                componentProps={{ variant: "ghost" }}
                                fileType={"IMAGE"}
                            />
                        </AvatarBadge>
                    </Avatar>
                )}
            </Box>

            <Box>
                <Text fontSize="13px" fontWeight={"bold"} my={3} w="full" textTransform={"uppercase"}>Contact Info</Text>
                <FormFieldsSkeleton count={6} loading={customerQuery?.isFetching} />
                {!customerQuery?.isFetching && <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

                    <FormInput
                        label={"First Name"}
                        control={control}
                        errors={errors}
                        id="firstName"
                        required={true}
                        placeholder="Enter first name"
                        inputProps={{ size: "sm" }}
                    />

                    <FormInput
                        label={"Last Name"}
                        control={control}
                        errors={errors}
                        id="lastName"
                        required={true}
                        placeholder="Enter last name"
                        inputProps={{ size: "sm" }}
                    />

                    <FormPhoneInput
                        id={"localNumber"}
                        label="Phone Number"
                        type="tel"
                        placeholder="Enter your phone number"
                        required={true}
                        errors={errors}
                        control={control}
                        setCountryCodeValue={(value) => {
                            setValue("countryCode", value)
                        }}
                        countryCodeValue={countryCode}
                        phoneNumberValue={localNumber}
                        setPhoneNumberValue={(value) => {
                            setValue("localNumber", value)
                        }}
                        setError={setError}
                        clearErrors={clearErrors}
                        inputProps={{ size: "sm" }}
                        size="sm"
                    />

                    <FormInput
                        id={"email"}
                        label="Email"
                        placeholder="someone@example.com"
                        required={true}
                        errors={errors}
                        control={control}
                        rules={{
                            pattern: {
                                value: EMAIL_REGEX,
                                message: "Invalid email address"
                            }
                        }}
                        inputProps={{ size: "sm" }}
                    />

                    <FormSearchSelect
                        id="cityId"
                        label="City"
                        placeholder={'Select City'}
                        required={true}
                        errors={errors}
                        control={control}
                        options={makeSelectList(cities.data?.data)}
                        inputProps={{ size: "sm" }}
                    />


                    <FormSearchSelect
                        id="languageId"
                        label="Language"
                        placeholder={'Select Language'}
                        required={true}
                        errors={errors}
                        control={control}
                        options={makeSelectList(languages.data?.data)}
                        inputProps={{ size: "sm" }}
                    />

                </SimpleGrid>
                }
            </Box>

            <FormFieldsSkeleton columns={1} count={1} fieldHeight={"120px"} loading={customerQuery?.isFetching} />
            {!customerQuery?.isFetching &&
                <FormTextarea
                    id={"note"}
                    label="Note"
                    placeholder="Write anything you want to note about this customer"
                    errors={errors}
                    control={control}
                />}

            <HStack spacing={1} mt={5}>
                <Button variant='outline' mr={3} onClick={() => disclosure?.onClose()}>
                    Cancel
                </Button>
                <FormButton
                    type='submit'
                    colorScheme='blue'
                    isLoading={createCustomerQuery.isLoading || updateCustomerQuery.isLoading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </FormButton>
            </HStack>

        </VStack>
    )
}

export default CustomerInfo