import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    VStack,
    Flex,
    Box,
    useColorMode,
    HStack,
    Heading,
    IconButton,
    Icon,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { useDispatcherLogs } from '../../../../config/query/bookingsQuery'
import { formatDateTimeToZone, formatDateTimeWithSeconds } from '../../../../config/helpers/dateHelper';
import { colorKeys, getColor } from '../../../../config/constants/appColors';
import CircularLoading from '../../../../components/Skeletons/CircularLoading';
import APP_ICONS from '../../../../config/constants/icons';
import { DEFAULT_DATE_TIME_WITH_ZONE } from '../../../../config/constants';
import MenuSelect from '../../../../components/Forms/MenuSelect';
import { makeSelectList } from '../../../../config/helpers/selectListHelper';
import { TIME_SWITCH, TIME_SWITCHES } from '../../../../config/constants/enums';
import { getLatLng } from '../../../../config/helpers/stringHelper';
import { openCoordinatesOnGoogleMaps } from '../../../../config/helpers/urlHelper';

const DispatchLogs = ({ isOpen, onClose, timeZoneOffSet, timeZoneDisclosure }) => {
    const { colorMode } = useColorMode();
    const { bookingId } = useParams();
    const dispatchLogsQuery = useDispatcherLogs({ RideId: bookingId })

    const onTimeSwitch = (e) => {
        if (e?.value === TIME_SWITCHES.BOOKING_ZONE) {
            timeZoneDisclosure.onClose()
        }
        else {
            timeZoneDisclosure.onOpen()
        }
    }

    useEffect(() => {
        if (isOpen) {
            dispatchLogsQuery.refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} onClose={onClose} isLazy motionPreset='none'>
            <ModalOverlay />
            <ModalContent maxW="70rem">
                <Flex justify={"space-between"} p="10px 20px">
                    <Heading fontSize="20px" p="2">Dispatch Logs</Heading>
                    <HStack>
                        <MenuSelect
                            value={timeZoneDisclosure.isOpen ? TIME_SWITCH.LOCAL : TIME_SWITCH.BOOKING_ZONE}
                            onChange={onTimeSwitch}
                            options={makeSelectList(TIME_SWITCH, "value", "label", false)}
                            buttonProps={{ size: "sm", fontWeight: "bold", fontSize: "13px", w: "auto" }}
                        />
                        <Button isLoading={dispatchLogsQuery.isFetching} size="sm" onClick={dispatchLogsQuery.refetch} leftIcon={<Icon as={APP_ICONS.REFRESH} />}>Refresh</Button>
                        <IconButton size="sm" onClick={onClose} icon={<Icon as={APP_ICONS.CLOSE} />} />
                    </HStack>
                </Flex>
                <ModalBody>
                    <CircularLoading loading={dispatchLogsQuery.isLoading} />
                    <VStack spacing={0} align="stretch" h="calc(100vh - 320px)" overflow={"auto"}>
                        <Flex flexWrap={"nowrap"} pos="sticky" top={0} justify={"space-between"} py="2" px="2" _odd={{ backgroundColor: getColor(colorKeys.tableStripedRowBackground, colorMode) }}>
                            <Box w="100px" fontSize="14px" fontWeight={"bold"}>Performer</Box>
                            <Box w="100px" fontSize="14px" fontWeight={"bold"}>Name</Box>
                            <Box w="100px" fontSize="14px" fontWeight={"bold"}>Ride Status</Box>
                            <Box w="180px" fontSize="14px" fontWeight={"bold"}>Driver Location</Box>
                            <Box w="180px" fontSize="14px" fontWeight={"bold"}>Vehicle</Box>
                            <Box w="180px" fontSize="14px" fontWeight={"bold"}>Date Time</Box>
                        </Flex>
                        {!dispatchLogsQuery.isLoading && dispatchLogsQuery.data?.data?.map((item, index) => {
                            const latLng = getLatLng(item.driverLocation);
                            return <Flex key={index} justify={"space-between"} flexWrap={"nowrap"} py="2" px="2" _odd={{ backgroundColor: getColor(colorKeys.tableStripedRowBackground, colorMode) }}>
                                <Box w="100px" fontSize="14px">{item?.performer || "N/A"}</Box>
                                <Box w="100px" fontSize="14px">{item?.name || "N/A"}</Box>
                                <Box w="100px" fontSize="14px">{item?.rideStatus || "N/A"}</Box>
                                <Box w="180px" fontSize="14px">
                                    {(!latLng.lat && !latLng.lng)
                                        ? "-"
                                        : <a
                                            rel="noreferrer"
                                            className="hover-underline"
                                            target="_blank"
                                            href={openCoordinatesOnGoogleMaps(latLng)}
                                        >
                                            {item.driverLocation}
                                        </a>
                                    }
                                </Box>
                                <Box w="180px" fontSize="14px">{item?.vehicleToPickupLocationEstimate || "N/A"}</Box>
                                {timeZoneDisclosure.isOpen
                                    ? <Box maxW="180px" fontSize="14px">{formatDateTimeWithSeconds(item?.date)}</Box>
                                    : <Box maxW="180px" fontSize="14px">{formatDateTimeToZone(item?.date, timeZoneOffSet, DEFAULT_DATE_TIME_WITH_ZONE)}</Box>}
                            </Flex>
                        })}
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DispatchLogs