import React from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Text,
  VStack,
  chakra,
  useColorMode,
} from "@chakra-ui/react";
import FormFileUpload from "../../../../components/Forms/FormFileUpload";
import PrimaryButton from "../../../../components/BasicUI/Buttons/PrimaryButton";
import { FILE_TYPES } from "../../../../config/constants/allowedFileTypes";
import APP_ICONS from "../../../../config/constants/icons";
import generateString from "../../../../config/helpers/generateString";
import { colorKeys, getColor } from "../../../../config/constants/appColors";

const DocumentsInformation = ({
  documents,
  setDocuments,
  fields,
  setValue,
}) => {
  const { colorMode } = useColorMode();
  const onChangeImage = (file, id) => {
    setDocuments(
      documents.map((document) => {
        if (document.id === id || document.tempId === id) {
          document.binary = file;
        }
        return document;
      })
    );
  };

  const onAddNew = () => {
    setDocuments([
      ...documents,
      {
        tempId: generateString(10),
        binary: null,
        url: null,
      },
    ]);
  };

  const onRemove = (id) => {
    setDocuments(documents.filter((document) => document.tempId !== id));
  };

  return (
    <Box>
      <Text
        fontSize="15px"
        fontWeight={"bold"}
        my={3}
        w="full"
        textAlign={"center"}
        textTransform={"uppercase"}
      >
        For Inspector
      </Text>
      <VStack spacing={5} align="stretch">
        {fields?.inspectionDocuments?.map((item) => (
          <Flex
            justify={"space-between"}
            align="center"
            bg="#e0e0e0"
            p="10px"
            rounded="sm"
          >
            <chakra.a
              maxW="90%"
              textDecor={"underline"}
              as="a"
              href={item?.document?.accessURL || item?.link}
              target="_blank"
              bg={getColor(colorKeys.imageText, colorMode)}
            >
              {item?.title || item?.document?.originalFileName || item?.link}
            </chakra.a>
            <IconButton
              size="sm"
              icon={<Icon as={APP_ICONS.BIN} />}
              onClick={() => {
                setValue(
                  "inspectionDocuments",
                  fields?.inspectionDocuments?.filter(
                    (document) => document.id !== item.id
                  )
                );
              }}
            />
          </Flex>
        ))}

        {documents?.map((document) => {
          return (
            <SimpleGrid
              key={document.tempId}
              columns={{ base: 1, md: 3 }}
              spacing={3}
              w="full"
            >
              <Box>
                <Text fontSize="14px" mb={2}>
                  Document Title
                </Text>
                <Input
                  size="sm"
                  required={true}
                  defaultValue={document.title}
                  onBlur={(e) =>
                    setDocuments(
                      documents.map((doc) => {
                        if (doc.tempId === document.tempId) {
                          doc.title = e.target.value;
                        }
                        return doc;
                      })
                    )
                  }
                  placeholder="Enter document title"
                />
              </Box>
              <Box>
                <Text fontSize="14px" mb={2}>
                  Document File
                </Text>
                <FormFileUpload
                  id={"documentHolder"}
                  onChange={(file) => onChangeImage(file, document.tempId)}
                  isDisabled={Boolean(document.link)}
                  component={
                    <InputGroup size="sm">
                      <Input
                        size="sm"
                        isReadOnly={true}
                        value={document?.binary?.name || "Select File"}
                      />
                      <InputRightAddon
                        p="0"
                        children={
                          <PrimaryButton size="sm" roundedLeft="none" w="full">
                            Upload
                          </PrimaryButton>
                        }
                      />
                    </InputGroup>
                  }
                  componentProps={{ variant: "ghost", w: "full" }}
                  fileType={FILE_TYPES.ANY}
                />
              </Box>
              <HStack spacing={1} align="end">
                <Input
                  size="sm"
                  defaultValue={document.link || document?.document?.accessURL}
                  onBlur={(e) =>
                    setDocuments(
                      documents.map((doc) => {
                        if (doc.tempId === document.tempId) {
                          doc.link = e.target.value;
                        }
                        return doc;
                      })
                    )
                  }
                  placeholder="Enter link to document"
                  isDisabled={Boolean(document.binary)}
                />
                <IconButton
                  onClick={() => onRemove(document.tempId)}
                  icon={<Icon as={APP_ICONS.CLOSE} />}
                  size="sm"
                  variant={"ghost"}
                />
              </HStack>
            </SimpleGrid>
          );
        })}

        <Flex justify="center" w="full">
          <Button
            onClick={onAddNew}
            w="fit-content"
            leftIcon={<Icon as={APP_ICONS.ADD} />}
            size="sm"
            colorScheme="blue"
            bg={getColor(colorKeys.primaryButtonFill, colorMode)}
            _hover={{
              bg: getColor(colorKeys.primaryButtonFillHover, colorMode),
            }}
          >
            Add New
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default DocumentsInformation;
