import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tenant: {},
}

export const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        updateTenant: (state, { payload }) => {
            state.tenant = payload
        },
    },
})

export const {
    updateTenant
} = tenantSlice.actions

export default tenantSlice.reducer