import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  VStack,
  useColorMode,
  chakra,
} from "@chakra-ui/react";
import LabelValuePair from "../../../components/BasicUI/LabelValuePair";
import { getColor, colorKeys } from "../../../config/constants/appColors";
import PrimaryButton from "../../../components/BasicUI/Buttons/PrimaryButton";
import CollapsableBox from "../../../components/BasicUI/DataBoxes/CollapsibleBox";
import {
  formatAmount,
  formatPhoneNumberForDisplay,
} from "../../../config/helpers/stringHelper";
import StarRating from "../../../components/BasicUI/DataBoxes/StarRating";
import { useSelector } from "react-redux";
import { getPermission } from "../../../config/helpers/permissionHelper";
import { APP_MODULES } from "../../../config/constants";

const DriverData = ({
  driverQuery,
  creditFormDisclosure,
  driverFormDisclosure,
}) => {
  const { colorMode } = useColorMode();
  const permissions = useSelector((state) => state?.user?.permissions);
  const driverPermissions = getPermission({
    permissionKey: APP_MODULES.Driver,
    permissions,
  });
  return (
    <Card w="full">
      <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
        <SimpleGrid columns={{ base: 1, md: 2 }} w="full" alignItems={"center"}>
          <Flex align="center">
            <Avatar size="xl" src={driverQuery.data?.profilePictureUrl} />
            <VStack align="stretch" spacing={1} ml={3} maxW="450px">
              <Box>
                <Text fontWeight={"bold"} fontSize="16px">
                  {driverQuery.data?.name || "N/A"}
                </Text>
                {driverQuery.data?.phone && (
                  <Text fontSize="14px">
                    {formatPhoneNumberForDisplay(driverQuery.data?.phone)}
                  </Text>
                )}
              </Box>
              <StarRating
                count={5}
                value={driverQuery.data?.overallRating}
                activeColor={getColor(colorKeys.spanishYellow, colorMode)}
                inactiveColor="transparent"
                containerProps={{ justifyContent: "left" }}
                isLoading={driverQuery.isLoading}
              />
              {driverQuery.data?.note && (
                <Box>
                  <Text fontSize="13px">
                    <chakra.span fontWeight="bold">Note :</chakra.span>
                  </Text>
                  <CollapsableBox>
                    <Text
                      fontSize="13px"
                      color={getColor(colorKeys.dark, colorMode)}
                    >
                      {driverQuery.data?.note}
                    </Text>
                  </CollapsableBox>
                </Box>
              )}
            </VStack>
          </Flex>

          <Flex flexDir={"column"} alignItems="self-end">
            <LabelValuePair
              label={"Total Earnings"}
              value={formatAmount(driverQuery.data?.totalEarnings)}
              headingProps={{
                fontSize: "15px",
                color: getColor(colorKeys.secondaryText, colorMode),
                fontWeight: "normal",
              }}
              valueProps={{
                fontSize: "15px",
                color: getColor(colorKeys.dimText, colorMode),
                fontWeight: "bold",
                textAlign: "center",
              }}
              isLoading={driverQuery.isLoading}
              loadingProps={{ w: "100px", h: "25px", mt: 2 }}
            />
            <LabelValuePair
              label={"Total Tip"}
              value={formatAmount(driverQuery.data?.totalTip)}
              headingProps={{
                fontSize: "16px",
                color: getColor(colorKeys.secondaryText, colorMode),
                fontWeight: "normal",
              }}
              valueProps={{
                fontSize: "16px",
                color: getColor(colorKeys.dimText, colorMode),
                fontWeight: "bold",
                textAlign: "center",
              }}
              isLoading={driverQuery.isLoading}
              loadingProps={{ w: "100px", h: "25px", mt: 2 }}
            />
            <LabelValuePair
              label={"Zone"}
              value={driverQuery.data?.vehicle?.zone?.name}
              colon={false}
              flexDir="column"
              headingProps={{
                fontSize: "16px",
                color: getColor(colorKeys.secondaryText, colorMode),
                fontWeight: "normal",
                textAlign: "center",
              }}
              valueProps={{
                fontSize: "16px",
                color: getColor(colorKeys.dimText, colorMode),
                fontWeight: "bold",
                textAlign: "center",
              }}
              isLoading={driverQuery.isLoading}
              loadingProps={{ w: "100px", h: "25px", mt: 2 }}
            />
            <HStack spacing={3} mt={3}>
              <PrimaryButton size={"sm"} onClick={driverFormDisclosure.onOpen}>
                Update Profile
              </PrimaryButton>
              {driverPermissions?.additionalActions?.includes(
                "UpdateCreditBalance"
              ) && (
                <PrimaryButton
                  isDisabled={!driverQuery.data?.stripeAccountId}
                  size={"sm"}
                  onClick={creditFormDisclosure.onOpen}
                  title={
                    !driverQuery.data?.stripeAccountId &&
                    "Disabled as stripe driver id is not available"
                  }
                >
                  Update Credit Balance
                </PrimaryButton>
              )}
            </HStack>
          </Flex>
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};

export default DriverData;
