import { useContext, useMemo, useRef, useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { SignalRContext, useSignalREffectAlt } from "../../sockets/SignalRContext";
import { useReadLastMessage, useSendMessage, useSupportChatList } from "../../query/supportQuery";
import { scrollToBottom, scrollToId } from "../../helpers/scrollHelper";
import { SOCKET_SERVER } from "../../constants/chat";
import {
    prepareAttatchmentPayload,
    prepareMessagePayload,
    prepareTemporaryAttatchmentObject,
    prepareTemporaryMessageObject
} from "../../helpers/chatHelpers";

const useChat = ({ messageListRef, chatId }) => {
    const hubConnection = useContext(SignalRContext)

    const latestChat = useRef(null);

    const user = useSelector((state) => state.user?.user)
    const [messages, setMessages] = useState([]);
    const [newMessages, setNewMessages] = useState([])
    const [inputMessage, setInputMessage] = useState("");

    const sendMessageQuery = useSendMessage();
    const readLastMessageQuery = useReadLastMessage()

    const {
        data: inboxData,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isInitialLoading,
        remove: removeMessages,
    } = useSupportChatList({
        PageSize: 25,
        ChatId: chatId,
        BeforeCreatedAt: null
    })

    const secondLastPage = useMemo(() => {
        return inboxData?.pages?.at(inboxData?.pages?.length - 2);
    }, [inboxData?.pages]);

    const handleFetchNextPage = () => {
        if (hasNextPage) {
            fetchNextPage();
        }
    };

    const handleSendMessage = async () => {
        if (!inputMessage.trim().length) {
            return;
        }
        const messageObject = prepareMessagePayload(inputMessage, chatId);
        const temporaryMessageObject = prepareTemporaryMessageObject(
            inputMessage,
            chatId,
            messageObject.CreatedAt,
            user?.id
        );
        setInputMessage("");
        latestChat.current = [...messages, temporaryMessageObject]
        setMessages((old) => [...old, temporaryMessageObject]);
        delete messageObject.tempDateTime;
        sendMessageQuery.mutate(messageObject)

        setTimeout(() => {
            scrollToBottom(messageListRef);
        }, 0);
    };

    const handleSendImage = async (image) => {
        if (!image) {
            return;
        }
        const messageObject = prepareAttatchmentPayload(image, chatId);
        const temporaryMessageObject = prepareTemporaryAttatchmentObject(
            image,
            chatId,
            messageObject.CreatedAt,
            user?.id
        );
        setInputMessage("");
        latestChat.current = [...messages, temporaryMessageObject]
        setMessages((old) => [...old, temporaryMessageObject]);
        delete messageObject.tempDateTime;
        sendMessageQuery.mutate(messageObject)

        setTimeout(() => {
            scrollToBottom(messageListRef);
        }, 0);
    };

    const onRecieveMessage = (message) => {
        if (chatId !== message?.chatId) return
        latestChat.current = messages
        setNewMessages([...newMessages, message])
        if (message?.user?._id === user?.id) {
            const index = latestChat.current.findIndex((m, i) => {
                return moment(m.createdAt).isSame(message.createdAt)
            });
            if (index !== -1) {
                latestChat.current[index] = message;
                setMessages([...latestChat.current]);
                return;
            }
            else {
                latestChat.current.push(message)
                setMessages([...latestChat.current])
            }
        }
        else {
            const chatList = latestChat.current
            chatList.push(message)
            setMessages([...chatList])
        }

        setTimeout(() => {
            scrollToBottom(messageListRef);
        }, 0);
    }

    const onChatUpdate = (message) => {
        if (chatId !== message?.chatId) return
        latestChat.current = messages
        const index = latestChat.current.findIndex((m, i) => {
            return m._id === message._id
        });
        if (index !== -1) {
            latestChat.current[index].deliveryStatus = message.deliveryStatus;
            setMessages([...latestChat.current]);
            return;
        }
    }

    const handleScroll = () => {
        if (messageListRef.current) {
            const { scrollTop } = messageListRef.current;
            if (scrollTop === 0 && !isFetchingNextPage) {
                handleFetchNextPage();
            }
        }
    };

    const joinChatRoom = () => {
        if (hubConnection) {
            hubConnection
                .invoke(SOCKET_SERVER.JoinChatRoom, chatId)
                .then(() => console.info("Joined chat room: ", chatId))
                .catch((err) => {
                    console.warn(`Error joining chat room ${chatId}: ${err}`);
                });
        }
    }

    useSignalREffectAlt(SOCKET_SERVER.ChatMessage, onRecieveMessage)
    useSignalREffectAlt(SOCKET_SERVER.ChatUpdate, onChatUpdate)

    useEffect(() => {
        joinChatRoom()
        return () => {
            if (hubConnection) {
                hubConnection
                    .invoke(SOCKET_SERVER.LeaveChatRoom, chatId)
                    .then(() => console.info("Left chat room: ", chatId))
                    .catch((err) => {
                        console.warn(`Error leaving chat room ${chatId}: ${err}`);
                    });
            }
            removeMessages();
            setNewMessages([])
            setMessages([])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId]);

    useEffect(() => {
        //settings messages in messages state to render chat data
        if (inboxData?.pages?.length > 0) {
            const flatMessages = inboxData?.pages.flatMap(
                (page) => page?.data
            );
            setMessages(() => [...[...flatMessages].reverse(), ...newMessages]);
        }

        //scrolling to the last message where scroll was triggered.
        if (inboxData?.pages?.length > 1) {
            setTimeout(() => {
                scrollToId(
                    messageListRef,
                    secondLastPage?.data?.at(secondLastPage?.data?.length - 1)?._id,
                    { subtract: 34 }
                );
            }, 0);
        }

        //scrolling to bottom once first page of
        //messages is fetched
        if (inboxData?.pages?.length === 1) {
            setTimeout(() => {
                scrollToBottom(messageListRef);
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inboxData?.pages]);

    useEffect(() => {
        //send read message api to last message in inboxData to mark it as read
        if (inboxData?.pages?.length > 0) {
            const lastMessage = inboxData?.pages?.at(inboxData?.pages?.length - 1)?.data?.at(0);
            const payload = {
                id: chatId,
                messageId: lastMessage?._id,
            };

            if (lastMessage?._id) readLastMessageQuery.mutateAsync(payload).catch((err) => console.warn(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId, inboxData?.pages?.length])


    return {
        messages,
        inputMessage,
        setInputMessage,
        handleSendMessage,
        handleScroll,
        handleSendImage,
        messageListRef,
        isLoading: isLoading || isInitialLoading,
        isFetchingNextPage,
        joinChatRoom
    }
}

export default useChat