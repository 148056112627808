import { Box, Card, CardBody, Flex, Heading, Text, useColorMode, Image, Button } from '@chakra-ui/react';
import React from 'react'
import { colorKeys, getColor } from '../../../../config/constants/appColors';
import PolygonBox from '../../../../components/Maps/PolygonBox';
import PickUpLocationIcon from "../../../../assets/icons/PickUpLocationIcon.svg";
import DropOffLocationIcon from "../../../../assets/icons/DropOffLocationIcon.svg";
import StopsLocationSvg from "../../../../assets/icons/StopsLocationSvg.svg";
import IMAGES from '../../../../config/constants/images';
import { getDirectionUrl } from '../../../../config/helpers/urlHelper';
import { breakWithCaps } from '../../../../config/helpers/stringHelper';
import { metersToKm } from '../../../../config/helpers/numbersUtils';

const TripData = ({ data }) => {
    const { colorMode } = useColorMode();

    const getTripData = (index, arr) => {
        const icon = index === 0 ? PickUpLocationIcon : index === arr.length - 1 ? DropOffLocationIcon : StopsLocationSvg;
        const color = index === 0 ? colorKeys.primaryButtonFill : index === arr.length - 1 ? colorKeys.primaryButtonFill : colorKeys.gray;
        const label = index === 0 ? "Pickup" : index === arr.length - 1 ? "Dropoff" : "Stop";
        return {
            icon,
            color,
            label
        }
    }

    const routeDetail = data?.routeDetail;
    const distaceMetrixKeys = [
        { label: "Est. Distance", value: data?.estDistance ? metersToKm(data?.estDistance) : "N/A" },
        { label: "Est. Duration", value: data?.estDuration ? `${data?.estDuration} minutes` : "N/A" },
        { label: "Actual Distance", value: data?.actualDistance ? metersToKm(data?.actualDistance) : "N/A" },
        { label: "Actual Duration", value: data?.actualDuration ? `${data?.actualDuration} minutes` : "N/A" },
    ]
    const directionUrl = getDirectionUrl(data?.rideStops)

    return (
        <Box w="full">
            <Heading fontSize="16px">Destination</Heading>
            <Card w="full" mt={5}>
                <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
                    <Flex flexWrap={"wrap"} w="full" justify={"space-between"} flexDir={{ base: "column", lg: "row" }}>
                        <Box maxW={{ base: "full", md: "50%" }}>

                            <Box>
                                <Heading fontSize="14px" mb={2}>Stops</Heading>
                                {data?.rideStops?.map((item, index, arr) => {
                                    const { icon, color, label } = getTripData(index, arr)
                                    return (
                                        <Box key={index} p="5px 10px" minW="250px">
                                            <Flex>
                                                <Image w={22} src={icon} mt={-27} />
                                                <Box>
                                                    <Heading
                                                        color={getColor(color, colorMode)}
                                                        fontWeight={"500"}
                                                        fontSize={"13px"}
                                                    >
                                                        {label}
                                                    </Heading>
                                                    <Text
                                                        {...(index !== arr.length - 1 && {
                                                            borderLeft: `1px dashed ${getColor(
                                                                colorKeys.gray,
                                                                colorMode
                                                            )}`,
                                                            pos: "relative",
                                                            left: "-14px",
                                                            pl: "17px",
                                                        })}
                                                        mt={2}
                                                        fontSize="13px"
                                                        pb={0}
                                                    >
                                                        {item?.address}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    );
                                })}

                                <Heading pt={3} mt={5} fontSize="14px" mb={2}>Distance Metrix</Heading>

                                {distaceMetrixKeys.map((item, index) =>
                                    <Flex align={"center"} gap={3} mb={4} key={index} minW="250px">
                                        <Heading
                                            color={getColor(colorKeys.primaryText, colorMode)}
                                            fontWeight={"500"}
                                            fontSize={"14px"}
                                            textTransform={"capitalize"}
                                        >
                                            {item?.label}:
                                        </Heading>
                                        <Text maxW="350px" fontSize="13px">
                                            {item?.value || "N/A"}
                                        </Text>
                                    </Flex>
                                )}
                            </Box>

                            {directionUrl && <Button mt={5} as={"a"} size="sm" target='_blank' href={getDirectionUrl(data?.rideStops)}>
                                <Image ml="-10px" w="30px" h="30px" src={IMAGES.GMAP} />
                                View Directions on Google Maps
                            </Button>}
                        </Box>
                        <Box minH="450px" minW={{ base: "100%", md: "50%" }}>
                            <PolygonBox
                                disabled={true}
                                polylineCoords={routeDetail || []}
                                polylineLastCoords={data?.rideStops[data?.rideStops.length - 1]?.location}
                                center={routeDetail?.at(0) || data?.rideStops[data?.rideStops.length - 1]?.location}
                                markers={data?.rideStops}
                                setPolylineCoords={() => true}
                                isPolyline={true}
                            />
                        </Box>
                    </Flex>
                </CardBody>
            </Card>
        </Box>
    )
}

export default TripData