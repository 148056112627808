import React, { useState, useEffect } from 'react';
import { Flex, FormControl, FormErrorMessage, FormLabel, Icon, IconButton, InputGroup, chakra, useColorMode } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import APP_ICONS from '../../config/constants/icons';
import { accessValue } from '../../config/helpers/stringHelper';
import { Select } from 'chakra-react-select';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { colorKeys, getColor } from '../../config/constants/appColors';
import _ from 'lodash';
import { getShortAddressFromAddressComponents } from '../../config/helpers/locationHelper';
// import { isProd } from '../../config/helpers/envHelper';

const LocationSearchSelect = ({ label, placeholder, errors, id, control, required = false, rules, containerStyles, onRefresh, inputProps, additionalObj, size, leftAddon, hideLabel, labelProps }) => {
    const { colorMode } = useColorMode();
    if (required) {
        required = `${label} is required`;
    }

    const [autocompleteService, setAutocompleteService] = useState(null);
    const [predictions, setPredictions] = useState([]);

    useEffect(() => {
        const autocomplete = new window.google.maps.Geocoder();
        setAutocompleteService(autocomplete);
    }, []);

    const handleSearch = _.debounce((value) => {
        if (autocompleteService) {
            autocompleteService.geocode({
                address: value,
                // componentRestrictions: !isProd() && {
                //     country: 'CA'
                // }
            }, (predictionss, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    setPredictions(predictionss);
                }
                else {
                    setPredictions([]);
                }
            });
        }
    }, 300);


    const options = predictions.map((prediction) => ({
        value: prediction.place_id,
        label: prediction.formatted_address
    }))

    return (
        <Controller
            control={control}
            name={id}
            rules={{
                required: required,
                ...rules
            }}
            render={({ field: { onChange, onBlur, value, ref, ...rest } }) => {
                return (
                    <Flex align="end" w="full">

                        <FormControl isInvalid={errors[id] || accessValue(errors, `${id}.message`)} {...containerStyles}>
                            {!hideLabel && (<FormLabel htmlFor={id} fontSize={'13px'} {...labelProps}>
                                {label}
                                {required && <chakra.span color={getColor(colorKeys.danger, colorMode)}>*</chakra.span>}
                            </FormLabel>)}
                            <InputGroup
                                className={value?.label?.length > 50 ? 'location-select-group' : 'location-select-group-no-value'}
                                size={size}
                                w="full"
                                role="group"
                                _focus={{ zIndex: 999 }}
                                _focusVisible={{ zIndex: 999 }}
                                _focusWithin={{ zIndex: 999 }}
                                _groupFocus={{ zIndex: 999 }}
                            >
                                {leftAddon}
                                <Select
                                    // isLoading={isLoading}
                                    onInputChange={(query) => {
                                        const newValue = query;
                                        handleSearch(newValue);
                                    }}
                                    // allowClear={true}
                                    isClearable={false}
                                    onChange={async (value) => {
                                        if (value) {
                                            const results = await geocodeByAddress(value.label);
                                            const latLng = await getLatLng(results[0]);
                                            const name = getShortAddressFromAddressComponents(results[0].formatted_address, results[0].address_components)

                                            let updatedValue = { ...value, name, latLng }
                                            if (additionalObj) {
                                                updatedValue = { ...updatedValue, ...additionalObj }
                                            }

                                            onChange(updatedValue)
                                        }
                                    }}
                                    value={value}
                                    ref={ref}
                                    placeholder={placeholder}
                                    filterOption={() => true}
                                    options={options}
                                    defaultInputValue=''
                                    defaultValue={null}
                                    menuPosition='fixed'
                                    classNamePrefix={"location-select"}
                                    className='location-select'
                                    id={id}
                                    {...rest}
                                    {...inputProps}
                                />
                            </InputGroup>
                            <FormErrorMessage>{accessValue(errors, `${id}.message`)}</FormErrorMessage>
                        </FormControl>
                        {onRefresh && (
                            <IconButton onClick={onRefresh} ml="1em" aria-label="Search database" icon={<Icon boxSize={7} as={APP_ICONS.REFRESH} />} />
                        )}
                    </Flex>
                )
            }}
        />
    );
};

export default LocationSearchSelect