import { Box, Button, HStack, SimpleGrid, Text, VStack, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'
import { formatDate } from '../../../../config/helpers/dateHelper'
import { removeUndefinedValues } from '../../../../config/helpers/stringHelper'
import { useVehicle, useLicencePlates } from '../../../../config/query/transportQuery'
import VehicleForm from '../../Transport/Vehicles/VehicleForm'

const VehicleInformation = ({ control, errors, isLoading, query, onQueryChange, fields,data }) => {

    const vehicleFormDisclosure = useDisclosure()
    const editVehicleDisclosure = useDisclosure()

    const licencePlates = useLicencePlates(data?.id ? { Keyword: data?.vehicle?.licensePlate } : query?.vehicleQuery)
    const vehicle = useVehicle(fields?.vehicleId)

    return (

        <Box>
            <Text fontSize="15px" fontWeight={"bold"} my={3} w="full" textAlign={"center"} textTransform={"uppercase"}>Vehicle Information</Text>
            <FormFieldsSkeleton count={6} loading={isLoading} />
            {!isLoading &&
                <VStack spcaing={10} align="stretch">
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                        <FormSearchSelect
                            id="vehicleId"
                            label={"Licence Plate"}
                            placeholder={'Search Licence Plate'}
                            control={control}
                            errors={errors}
                            required={true}
                            inputProps={{ size: "sm" }}
                            searchFn={(value) => {
                                onQueryChange("vehicleQuery", { Keyword: value })
                            }}
                            isLoading={licencePlates.isLoading}
                            options={makeSelectList(licencePlates.data?.data) || []}
                            labelExtention={
                                <HStack>
                                    <Button
                                        size="xs"
                                        onClick={() => {
                                            editVehicleDisclosure.onClose()
                                            vehicleFormDisclosure.onOpen()
                                        }}
                                    >
                                        Add New
                                    </Button>
                                    {vehicle.data?.id && (
                                        <Button
                                            size="xs"
                                            onClick={() => {
                                                editVehicleDisclosure.onOpen()
                                                vehicleFormDisclosure.onOpen()
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </HStack>
                            }
                        />
                    </SimpleGrid>


                    {vehicle.data?.id && (
                        <Box>
                            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} mt={5}>
                                <LabelValuePair
                                    label={"Car Details"}
                                    flexDir='column'
                                    headingProps={{ fontSize: "18px" }}
                                    value={true}
                                    valueComponent={<Box >
                                        <Text fontSize="14px">{(vehicle.data?.color?.name || vehicle.data?.make?.name || vehicle.data?.model?.name)
                                            ? removeUndefinedValues([vehicle.data?.make?.name, vehicle.data?.model?.name, vehicle.data?.color?.name], " - ")
                                            : "N/A"}</Text>
                                        <Text fontSize="14px">Year: {vehicle.data?.year || "N/A"}</Text>
                                    </Box>}
                                />
                                <LabelValuePair
                                    label={"Insurance"}
                                    value={true}
                                    headingProps={{ fontSize: "18px" }}
                                    flexDir='column'
                                    valueComponent={<Box >
                                        <Text fontSize="14px">Number: {vehicle?.data?.insurancePolicyNo || "N/A"}</Text>
                                        <Text fontSize="14px">Valid from {formatDate(vehicle?.data?.insuranceEffectiveDate)} to {formatDate(vehicle?.data?.insuranceExpiryDate)}</Text>
                                    </Box>}
                                />
                                <LabelValuePair
                                    label={"Capacity"}
                                    value={true}
                                    headingProps={{ fontSize: "18px" }}
                                    flexDir='column'
                                    valueComponent={<Box >
                                        <Text fontSize="14px">{vehicle?.data?.seatingCapacity ? `${vehicle?.data?.seatingCapacity} people` : "N/A"}</Text>
                                        <Text fontSize="14px">{vehicle.data?.hasLuggageSpace
                                            ? "Has luggage space"
                                            : "No luggage space"
                                        }</Text>
                                    </Box>}
                                />
                                <LabelValuePair
                                    label={"Ownership"}
                                    value={true}
                                    headingProps={{ fontSize: "18px" }}
                                    flexDir='column'
                                    valueComponent={<Box >
                                        {vehicle?.data?.ownerName && <Text fontSize="14px">Belongs to {vehicle?.data?.ownerName}</Text>}
                                        {vehicle.data?.licensePlate && <Text fontSize="14px">Licence Plate: {vehicle.data?.licensePlate}</Text>}
                                    </Box>}
                                />
                                <LabelValuePair
                                    label={"Vehicle Type(s)"}
                                    value={vehicle.data?.vehicleTypes?.map((item) => {
                                        if (!item.vehicleTypeName) return ''
                                        if (!item.isEnabled) return `${item.vehicleTypeName} (Disabled by Driver)`
                                        if (item.isDefault) return `${item.vehicleTypeName} (Default)`
                                        return item.vehicleTypeName
                                    })?.filter(Boolean)?.join(", ") || "N/A"}
                                    headingProps={{ fontSize: "18px" }}
                                    flexDir='column'
                                />
                                <LabelValuePair
                                    label={"Zone(s)"}
                                    value={vehicle.data?.zones?.map((item) => item.name)?.join(", ") || "N/A"}
                                    headingProps={{ fontSize: "18px" }}
                                    flexDir='column'
                                />
                            </SimpleGrid>
                        </Box>
                    )}
                </VStack>
            }

            <VehicleForm
                disclosure={vehicleFormDisclosure}
                data={editVehicleDisclosure.isOpen ? vehicle.data : {}}
            />
        </Box>
    )
}

export default VehicleInformation