import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useDisclosure, useColorMode } from '@chakra-ui/react';
import Sider from './Sider';
import Header from './Header';
import { getColor, colorKeys } from '../../../config/constants/appColors';
import BottomChatDrawer from '../../Chat/ChatDrawers/BottomChatDrawer';
import { usePermissions } from '../../../config/query/authQuery';
import { updatePermissions } from '../../../config/redux/slices/userSlice';
import { getPermission, preparePermissionsMap } from '../../../config/helpers/permissionHelper';
import { APP_MODULES } from '../../../config/constants';

const AdminLayout = () => {
  const dispatch = useDispatch()
  const sidebar = useDisclosure();
  const { pathname } = useLocation()
  const desktopDisclosure = useDisclosure({ defaultIsOpen: false });
  const token = useSelector((state) => state.user?.token)
  const { colorMode } = useColorMode()
  const permissionsQuery = usePermissions()
  const permissions = useSelector((state) => state.user?.permissions)
  const isGlobal = useSelector((state) => state.user?.user?.isGlobal)

  useEffect(() => {
    permissionsQuery
      .mutateAsync()
      .then((response) => {
        dispatch(updatePermissions(preparePermissionsMap(response?.data)))
      })
      .catch((error) => console.warn(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  if (!token) {
    return <Navigate to="/auth/login" replace={true} />
  }

  return (
    <Box
      as="section"
      minH="100vh"
    >
      <Sider disclosure={sidebar} desktopDisclosure={desktopDisclosure} />
      <Box
        ml={{
          base: 0,
          lg: desktopDisclosure.isOpen ? "70px" : "255px",
        }}
        transition=".3s ease"
      >
        <Header disclosure={sidebar} desktopDisclosure={desktopDisclosure} />
        <Box
          as="main"
          p="15px"
          bg={getColor(colorKeys.layoutBoxBackground, colorMode)}
          // bg={{ base: "red", sm: "blue", md: "green", lg: "yellow", xl: "purple" }}
          minH="calc(100vh - 70px)"

        >
          <Outlet />
        </Box>
      </Box>

      {getPermission({
        permissions,
        permissionKey: APP_MODULES.ChatAndMessaging,
        isGlobal
      }) && !pathname.includes('/admin/chats')
        ? <BottomChatDrawer />
        : null}
    </Box>
  )
}

export default React.memo(AdminLayout)