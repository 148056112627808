export const makeUrl = (url) => {
    if (!url) return ""
    url = url.replace("+", "%2B")
    return url
}

export const openCoordinatesOnGoogleMaps = ({ lat, lng }) => {
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
}

export const getDirectionUrl = (stops) => {
    if(!stops) return ""
    let url = "https://www.google.com/maps/dir/"
    console.log(stops, "stopsstopsstops")
    stops?.forEach((stop) => {
        url += `${stop?.location?.lat},${stop?.location?.lng}/`
    })
    return url
}