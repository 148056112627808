import moment from "moment"
import { REPEAT_TYPES } from "../../constants/enums"
import { truncateTime } from "../dateHelper"

export const prepareForm = (data) => {
    return {
        name: data?.name,
        daysOfWeek: data?.daysOfWeek?.map((item) => item.id),
        endTime: data?.endTime ? moment(data.endTime, "HH:mm:ss.SSSSSSS").format("HH:mm") : "00:00",
        startTime: data?.startTime ? moment(data.startTime, "HH:mm:ss.SSSSSSS").format("HH:mm") : "00:00",
        repeatType: data?.repeatType?.id,
        surcharge: data?.surcharge,
        date: data?.date,
        id: data?.id
    }
}

export const preparePayload = (values, id, zoneId) => {
    if (values.repeatType === REPEAT_TYPES.DaysOfWeek) {
        delete values.date
    }
    else {
        values.date = truncateTime(values.date)
        delete values.daysOfWeek
    }

    values.startTime = moment(values.startTime, "HH:mm").format("HH:mm")
    values.endTime = moment(values.endTime, "HH:mm").format("HH:mm")

    const payload = {
        ...values,
        id,
        zoneId,
        surcharge: Number(values.surcharge),
    };

    return payload
}