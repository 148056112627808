import React from "react";
import { Box } from "@chakra-ui/react";
import { PaymentElement } from "@stripe/react-stripe-js";

const StripeConnect = ({ errorMessage }) => {
    return (
        <Box>
            <PaymentElement className="stripe-connect-form" />
            {errorMessage && <div>{errorMessage}</div>}
        </Box>
    );
};

export default StripeConnect;
