import {
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { CONTENT_TYPE, MILISECONDS } from "../constants";
import appendQueryParams from "../helpers/appendQueryParams";

export const useColors = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["colors"],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.TRANSPORT.colors,
                token,
            })
            return data
        },
        staleTime: MILISECONDS.MAX,
        cacheTime: MILISECONDS.MAX,

    })
}

export const useVehicleTypeOptions = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["types-options"],
        queryFn: async () => {
            const data = await Get({
                path: API_CONSTANTS.TRANSPORT.typesLookup,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
    })
}

export const useVehicleTypes = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["types", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.TRANSPORT.types}?${appendQueryParams(params)}`,
                token,
                toastMessage: false
            })
            return data
        },
    })
}

export const useVehicleType = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["types", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.TRANSPORT.vehicleTypeById.replace(":id", id)}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        enabled: Boolean(id),
    })
}

export const useSearchVehicleType = () => {
    const token = useSelector((state) => state.user.token)
    return useMutation({
        mutationKey: ["search-vechical-type"],
        mutationFn: async (body) => {
            const response = await Post({
                path: `${API_CONSTANTS.BOOKINGS.searchVehicleType}`,
                token,
                body,
                toastError: true,
                toastMessage: false
            })
            return response
        }
    })
}

export const useAvailableDrivers = () => {
    const token = useSelector((state) => state.user.token);
    return useMutation({
        mutationKey: ['available-drivers'],
        mutationFn: async (body ) => {
            const response = await Post({
                path: API_CONSTANTS.BOOKINGS.availableDrivers,
                token,
                body,
                toastError: true,
                toastMessage: false,
            });
            return response;
        },
    });
};

export const useCustomerBookingCards = () => {
    const token = useSelector((state) => state.user.token)
    return useMutation({
        mutationKey: ["id"],
        mutationFn: async (id) => {
            const response = await Get({
                path: `${API_CONSTANTS.CUSTOMERS.cards.replace(":id", id)}`,
                token,

                toastError: true,
                toastMessage: false
            })
            return response
        },

    })
}

export const useCorporateBookingCards = () => {
    const token = useSelector((state) => state.user.token)
    return useMutation({
        mutationKey: ["id"],
        mutationFn: async (id) => {
            const response = await Get({
                path: `${API_CONSTANTS.CORPORATES.cards.replace(":id", id)}`,
                token,

                toastError: true,
                toastMessage: false
            })
            return response
        },

    })
}

export const useSearchUserByPhone = (phone) => {
    const token = useSelector((state) => state.user.token)
    return useMutation({
        mutationKey: ["phoneNumber"],
        mutationFn: async () => {
            const response = await Get({
                path: API_CONSTANTS.CUSTOMERS.searchUserByPhone.replace(":phoneNumber", phone),
                token,

                toastError: true,
                toastMessage: false
            })
            return response
        },

    })
}

export const useSearchUserByPhoneAndCorporateId = () => {
    const token = useSelector((state) => state.user.token)
    return useMutation({
        mutationKey: ["phoneNumber", 'corporateId'],
        mutationFn: async (data) => {

            const response = await Get({
                path: `${API_CONSTANTS.CUSTOMERS.searchUserByPhoneAndCorporateId.replace(":id", data.corporateId)}/${data.phone}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return response
        },

    })
}

export const useGetCustomerById = () => {
    const token = useSelector((state) => state.user.token)
    return useMutation({
        mutationKey: ["id"],
        mutationFn: async (id) => {
            const response = await Get({
                path: API_CONSTANTS.CUSTOMERS.getCustomerById.replace(":id", id),
                token,

                toastError: true,
                toastMessage: false
            })
            return response
        },

    })
}


export const useCreateVehicleType = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["types-create"],
        mutationFn: async (body) => {
            const response = await Post({
                path: `${API_CONSTANTS.TRANSPORT.saveType}`,
                token,
                body,
                toastError: true,
                toastMessage: false
            })
            return response
        }
    })
}

export const useUpdateVehicleType = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["types-update"],
        mutationFn: async (body) => {
            const response = await Put({
                path: `${API_CONSTANTS.TRANSPORT.saveType}`,
                token,
                body,
                toastError: true,
                toastMessage: false
            })
            return response
        }
    })
}

export const useUpdateVehicleTypeStatus = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["types-status"],
        mutationFn: async (body) => {
            const response = await Patch({
                path: `${API_CONSTANTS.TRANSPORT.updateVehicleTypeStatus.replace(":id", body.id)}?status=${body.status}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return response
        }
    })
}

export const useUpdateVehicleTypeImage = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["types-vehicle-image"],
        mutationFn: async (body) => {
            const response = await Patch({
                path: API_CONSTANTS.TRANSPORT.updateVehicleTypeImage,
                token,
                body,
                toastError: true,
                toastMessage: false,
                contentType: CONTENT_TYPE.FORM_DATA,
            })
            return response
        }
    })
}

export const useDeleteVehicleType = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["types-delete"],
        mutationFn: async (id) => {
            const response = await Delete({
                path: `${API_CONSTANTS.TRANSPORT.deleteVehicleType.replace(":id", id)}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return response
        }
    })
}

export const useVehicles = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["vehicles", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.TRANSPORT.vehicles}?${appendQueryParams(params)}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
    })
}

export const useVehicle = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["vehicles", id],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.TRANSPORT.vehicles}/${id}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        enabled: Boolean(id),
    })
}

export const useCreateVehicle = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["vehicles-create"],
        mutationFn: async (body) => {
            const response = await Post({
                path: `${API_CONSTANTS.TRANSPORT.vehicles}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return response
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["vehicles"])
            queryClient.invalidateQueries(["licencePlates"])
        }
    })
}

export const useUpdateVehicle = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["vehicles-update"],
        mutationFn: async (body) => {
            const response = await Put({
                path: API_CONSTANTS.TRANSPORT.vehicles,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return response
        }
    })
}

export const useDeleteVehicle = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationKey: ["vehicles-delete"],
        mutationFn: async (id) => {
            const response = await Delete({
                path: `${API_CONSTANTS.TRANSPORT.vehicles}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return response
        }
    })
}

export const useLicencePlates = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["licencePlates", params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.TRANSPORT.licencePlates}?${appendQueryParams(params)}`,
                token,
                toastError: true,
                toastMessage: false
            })
            return data
        },
        keepPreviousData: true
    })
}

//Apply Promo Code
export const useApplyPromoCode = () => {
    const token = useSelector((state) => state.user?.token);
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: API_CONSTANTS.BOOKINGS.applyPromoCode,
                token,
                body: body,
                toastError: true,
                toastMessage: true,
            });
            return res;
        },
    });
};