import CANCELLED from "../../assets/icons/map-markers/cancelled.svg"
import DISPATCHING from "../../assets/icons/map-markers/dispatching.svg"
import ACCEPTED from "../../assets/icons/map-markers/accepted.svg"
import AVAILABLE from "../../assets/icons/map-markers/available.svg"
import ON_THE_WAY from "../../assets/icons/map-markers/on-the-way.svg"
import ARRIVED_AND_WAITING from "../../assets/icons/map-markers/arrived-and-waiting.svg"
import POB from "../../assets/icons/map-markers/pob.svg"
import DROPPED_OFF from "../../assets/icons/map-markers/dropped-off.svg"
import COMPLETED from "../../assets/icons/map-markers/completed.svg"
import NOSHOWS from "../../assets/icons/map-markers/now-shows.svg"
import INCIDENTS from "../../assets/icons/map-markers/incidents.svg"
import UNAVAILABLE from "../../assets/icons/map-markers/unavailable.svg"
import PARKING_LOT from "../../assets/icons/map-markers/parking-lot.svg"
import DRIVER from "../../assets/icons/driver.png"
import CAR from "../../assets/icons/car.png"
import PASSENGER from "../../assets/icons/passenger.png"
import EMPTY_BOX from "../../assets/images/empty-box.png"
import GMAP from "../../assets/images/g-map.png"

const IMAGES = {
    LOGO: require('../../assets/images/logo.png'),
    ERROR: require('../../assets/images/error.png'),
    AUTH_HERO: require('../../assets/images/auth-hero.jpg'),
    DUMMY_MAP: require('../../assets/images/map.png'),
    AUTH_MIN: require('../../assets/images/auth-min.png'),
    DUMMY_USER: require('../../assets/images/dummy-user.png'),
    DUMMY_USER_MD: require('../../assets/images/dummy-user-md.png'),
    VISA: require('../../assets/images/visa.jpg'),
    DUMMY_IMAGE: require('../../assets/images/dummy-image.png'),
    PICKUP_MARKER: require("../../assets/icons/pickup-marker.svg"),
    DRIVER,
    CAR,
    EMPTY_BOX,
    PASSENGER,
    GMAP
}

export const MAP_MARKERS = {
    CANCELLED,
    DISPATCHING,
    ACCEPTED,
    AVAILABLE,
    ON_THE_WAY,
    ARRIVED_AND_WAITING,
    POB,
    DROPPED_OFF,
    COMPLETED,
    NOSHOWS,
    INCIDENTS,
    UNAVAILABLE,
    PARKING_LOT,
}

export default IMAGES