import { useQuery, useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put, Patch } from "../api";
import API_CONSTANTS from "../constants/api";
import appendQueryParams from "../helpers/appendQueryParams";

export const useZones = (params) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["zones", params],
    queryFn: async () => {
      const { data } = await Get({
        path: `${API_CONSTANTS.ZONES.base}?${appendQueryParams(params)}`,
        token,
      });
      return data;
    },
    keepPreviousData: true,
  });
};

export const useParentZones = (params) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["parent-zones", params],
    queryFn: async () => {
      const data = await Get({
        path: API_CONSTANTS.ZONES.parentZones,
        token,
      });
      return data;
    },
  });
};

export const useZoneOptions = () => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["zoneOptions"],
    queryFn: async () => {
      const { data } = await Get({
        path: API_CONSTANTS.ZONES.lookup,
        token,
        toastError: true,
      });
      return data;
    },
    keepPreviousData: true,
  });
};

export const useCreateZone = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const { data } = await Post({
        path: API_CONSTANTS.ZONES.base,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return data;
    },
  });
};

export const useDeleteZone = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (id) => {
      const { data } = await Delete({
        path: `${API_CONSTANTS.ZONES.base}/${id}`,
        token,
        toastError: true,
      });
      return data;
    },
  });
};

export const useZone = (id) => {
  const token = useSelector((state) => state.user?.token);
  return useQuery({
    queryKey: ["zone", id],
    queryFn: async () => {
      const data = await Get({
        path: `${API_CONSTANTS.ZONES.base}/${id}`,
        token,
        toastError: true,
      });
      return data;
    },
    enabled: Boolean(id),
  });
};

export const useUpdateZone = () => {
  const token = useSelector((state) => state.user?.token);
  return useMutation({
    mutationFn: async (body) => {
      const res = await Put({
        path: `${API_CONSTANTS.ZONES.base}`,
        token,
        body,
        toastError: true,
        toastMessage: true,
      });
      return res;
    },
  });
};

export const useUpdateZoneStatus = () => {
  const token = useSelector((state) => state.user.token);
  return useMutation({
    mutationKey: ["zone-status"],
    mutationFn: async (body) => {
      const response = await Patch({
        path: `${API_CONSTANTS.ZONES.updateStatus.replace(
          ":id",
          body.id
        )}?status=${body.status}`,
        token,
        toastError: true,
        toastMessage: false,
      });
      return response;
    },
  });
};
