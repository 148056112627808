import React, { useEffect } from 'react'
import { Divider, Heading, InputLeftAddon, SimpleGrid, chakra, VStack, useColorMode, Button, useDisclosure, HStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import FormModal from '../../../../../components/Forms/FormModal'
import { useCompleteBooking, useEstimatedFare } from '../../../../../config/query/bookingsQuery'
import LocationSearchSelect from '../../../../../components/Maps/LocationSearchSelect'
import LabelValuePair from '../../../../../components/BasicUI/LabelValuePair'
import FormInput from '../../../../../components/Forms/FormInput'
import { formatAmount } from '../../../../../config/helpers/stringHelper'
import { colorKeys, getColor } from '../../../../../config/constants/appColors'
import { useApplyPromoCode } from '../../../../../config/query/transportQuery'
import { getRideTotal } from '../../../../../config/helpers/formHelpers/bookingHelper'

const CompleteBooking = ({ disclosure, bookingData, onSuccess }) => {

    const { colorMode } = useColorMode()
    const promoCodeDisclosure = useDisclosure()

    const estimatedFare = useEstimatedFare()
    const applyPromoCode = useApplyPromoCode()
    const completeBooking = useCompleteBooking()

    const {
        handleSubmit,
        reset: resetForm,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm({
        values: {
            destination: {
                name: bookingData?.rideStops?.at(bookingData?.rideStops?.length - 1)?.addressName,
                label: bookingData?.rideStops?.at(bookingData?.rideStops?.length - 1)?.address,
                latLng: bookingData?.rideStops?.at(bookingData?.rideStops?.length - 1)?.location,
            },
            extras: {}
        },
    });

    const onSubmit = (values) => {

        let extrasValues = { ...extras }

        for (let key in extrasValues) {
            extrasValues[key] = Number(extrasValues[key]);
        }

        const payload = {
            destination: {
                name: values?.destination?.name,
                address: values?.destination?.label,
                location: values?.destination?.latLng,
            },
            ...extrasValues,
            promoCodeId: values?.promoCodeId || null,
        }

        completeBooking
            .mutateAsync({ rideId: bookingData?.id, body: payload })
            .then(() => {
                disclosure?.onClose()
                onSuccess && onSuccess()
            })
            .catch((error) => console.warn(error))
    }


    const onApplyPromoCode = () => {
        const payload = {
            customerId: bookingData?.customerId,
            pickUp: {
                lat: bookingData?.rideStops?.at(0)?.location?.lat,
                lng: bookingData?.rideStops?.at(0)?.location?.lng,
            },
            dropOff: {
                lat: bookingData?.rideStops?.at(bookingData?.rideStops?.length - 1)?.location?.lat,
                lng: bookingData?.rideStops?.at(bookingData?.rideStops?.length - 1)?.location?.lng,
            },
            promoCode: watch("promoCode"),
            vehicleTypeId: bookingData?.vehicleType?.id,
            paymentMethod: bookingData?.paymentMethod?.id,
            baseFare: Number(watch("extras[baseFare]")) || 0
            // fare: {
            //     baseFare: Number(watch("extras[baseFare]")) || 0,
            //     taxAmount: Number(watch("extras[taxes]")) || 0,
            //     techFee: Number(watch("extras[techFee]")) || 0,
            //     platformFee: Number(watch("extras[platformFee]")) || 0,
            // }
        }
        applyPromoCode
            .mutateAsync(payload)
            .then((res) => {
                setValue("extras.discount", res?.discount)
                setValue("promoCodeId", res?.promoCodeId)
                promoCodeDisclosure.onOpen()
            })
            .catch((error) => console.warn(error))
    }

    const onRemovePromoCode = () => {
        setValue("promoCode", "")
        setValue("extras.discount", 0)
        setValue("promoCodeId", null)
        promoCodeDisclosure.onClose()
    }

    useEffect(() => {
        if (disclosure?.isOpen) {
            const destination = { ...bookingData?.rideStops?.at(bookingData?.rideStops?.length - 1) }
            destination.name = destination.addressName
            delete destination.addressName
            delete destination.stopSequence
            estimatedFare
                .mutateAsync(bookingData?.id)
                .then((res) => setValue("extras", res.data))
                .catch((error) => console.warn(error))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disclosure?.isOpen])

    watch((data, { name, type }) => {
        if (type === "change") {
            if (name !== "tax") {
                const totalAmt = getRideTotal(extras)
                const taxRate = Number(estimatedFare.data?.data?.taxPercent) / 100

                const tax = totalAmt * taxRate;
                setValue("extras.tax", tax)
            }
            if (name === "promoCode") {
                promoCodeDisclosure.onClose()
                if (data[name]?.length === 0) {
                    setValue("extras.discount", 0)
                    setValue("promoCodeId", null)
                }
            }
        }
    })

    const extras = watch("extras")

    return (
        <FormModal
            title={"Complete Booking"}
            disclosure={disclosure}
            isSubmitting={completeBooking.isLoading || estimatedFare.isLoading || applyPromoCode.isLoading}
            isSubmitDisabled={completeBooking.isLoading || estimatedFare.isLoading || applyPromoCode.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"75rem"}
            saveButtonText={"Complete Booking"}
            reset={resetForm}
            onCloseFn={resetForm}
        >

            <VStack align="stretch" spacing={5} divider={<Divider />}>
                <VStack spacing={5} align="stretch">
                    {bookingData?.rideStops?.map((item, index, arr) => {
                        if (index === arr.length - 1) return null
                        return <LabelValuePair
                            key={index}
                            label={index === 0 ? "Pickup" : "Stop"}
                            value={item.addressName}
                        />
                    })}

                    <VStack align={"stretch"} maxW="500px">
                        <Heading size="sm" >Destination <chakra.span fontWeight={"normal"} color={getColor(colorKeys.danger, colorMode)}>*</chakra.span></Heading>
                        <LocationSearchSelect
                            control={control}
                            errors={errors}
                            id={"destination"}
                            label={"Destination Location"}
                            placeholder={"Enter destination location"}
                            required={true}
                            inputProps={{ size: "sm" }}
                            size="sm"
                            hideLabel={true}
                        />
                    </VStack>
                </VStack>

                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

                    {/* {Object.keys(extras).map((key, index) => {
                        if (key?.toLowerCase() === "tax" || key?.toLowerCase() === "discount" || key?.toLowerCase() === "promocodeid" || key?.toLowerCase() === "taxpercent" || key?.toLowerCase() === "iscorporateride") return null
                        if (key?.toLowerCase() === "corporateamount" && extras["isCorporateRide"] === false) return null
                        return <FormInput
                            key={index}
                            label={capitalizeFirstLetter(breakWithCaps(key))}
                            control={control}
                            errors={errors}
                            id={`extras[${key}]`}
                            type={"number"}
                            required={true}
                            placeholder={`Enter ${key}`}
                            inputProps={{ size: "sm" }}
                            leftAddon={<InputLeftAddon children={"$"} />}
                            size='sm'
                        />
                    })} */}

                    <FormInput
                        label={"Base Fare"}
                        control={control}
                        errors={errors}
                        id={`extras.baseFare`}
                        type={"number"}
                        required={true}
                        placeholder={`Enter base fare`}
                        inputProps={{ size: "sm" }}
                        leftAddon={<InputLeftAddon children={"$"} />}
                        size='sm'
                    />

                    <FormInput
                        label={"Tech Fee"}
                        control={control}
                        errors={errors}
                        id={`extras.techFee`}
                        type={"number"}
                        required={true}
                        placeholder={`Enter tech fee`}
                        inputProps={{ size: "sm" }}
                        leftAddon={<InputLeftAddon children={"$"} />}
                        size='sm'
                    />

                    <FormInput
                        label={"Other Fares"}
                        control={control}
                        errors={errors}
                        id={`extras.otherFares`}
                        type={"number"}
                        required={true}
                        placeholder={`Enter other fares`}
                        inputProps={{ size: "sm" }}
                        leftAddon={<InputLeftAddon children={"$"} />}
                        size='sm'
                    />

                    <FormInput
                        label={"Toll Fee"}
                        control={control}
                        errors={errors}
                        id={`extras.tollFee`}
                        type={"number"}
                        required={true}
                        placeholder={`Enter toll fee`}
                        inputProps={{ size: "sm" }}
                        leftAddon={<InputLeftAddon children={"$"} />}
                        size='sm'
                    />

                    <FormInput
                        label={"Tip"}
                        control={control}
                        errors={errors}
                        id={`extras.tip`}
                        type={"number"}
                        required={true}
                        placeholder={`Enter tip`}
                        inputProps={{ size: "sm" }}
                        leftAddon={<InputLeftAddon children={"$"} />}
                        size='sm'
                    />

                    {extras?.isCorporateRide &&
                        <FormInput
                            label={"Corporate Amount"}
                            control={control}
                            errors={errors}
                            id={`extras.corporateAmount`}
                            type={"number"}
                            required={true}
                            placeholder={`Enter corporate amount`}
                            inputProps={{ size: "sm" }}
                            leftAddon={<InputLeftAddon children={"$"} />}
                            size='sm'
                        />}

                </SimpleGrid>

                <HStack spacing={1}>
                    <FormInput
                        label={"Promo Code"}
                        control={control}
                        errors={errors}
                        id="promoCode"
                        placeholder="Enter promo code"
                        inputProps={{ size: "sm" }}
                        containerStyles={{ maxW: "220px" }}
                        size='sm'
                    />

                    {watch("promoCode")?.length > 0 &&
                        <Button
                            mt="19px"
                            size={"sm"}
                            onClick={promoCodeDisclosure.isOpen ? onRemovePromoCode : onApplyPromoCode}
                            isLoading={applyPromoCode.isLoading}
                            fontWeight={"normal"}
                            children={promoCodeDisclosure.isOpen ? "Remove" : "Apply"}
                            background={promoCodeDisclosure.isOpen ? getColor(colorKeys.danger, colorMode) : getColor(colorKeys.primaryButtonFill, colorMode)}
                            color={"white"}
                            cursor={"pointer"}
                        />}
                </HStack>

                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

                    <LabelValuePair
                        label={"Taxes"}
                        value={formatAmount(extras?.tax)}
                    />

                    <LabelValuePair
                        label={"Promo"}
                        value={formatAmount(watch("extras.discount"))}
                    />
                    <LabelValuePair
                        label={"Total"}
                        value={formatAmount(getRideTotal(extras))}
                    />
                </SimpleGrid>

            </VStack>
        </FormModal>
    )
}

export default CompleteBooking