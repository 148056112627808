import React from "react";
import CancelBookingPopover from "../../../../components/BasicUI/Popovers/CancelBookingPopover";
import { useForm } from "react-hook-form";
import { Box, Icon, useDisclosure } from "@chakra-ui/react";
import {
  CANCEL_BOOKING_REASON,
  CANCEL_BOOKING_TYPE,
} from "../../../../config/constants/enums";
import FormRadio from "../../../../components/Forms/FormRadio";
import { makeSelectList } from "../../../../config/helpers/selectListHelper";
import APP_ICONS from "../../../../config/constants/icons";
import { useCancelBooking } from "../../../../config/query/bookingsQuery";
import FormTextarea from "../../../../components/Forms/FormTextarea";
import { breakWithCaps } from "../../../../config/helpers/stringHelper";
// import { useSelector } from "react-redux";
// import { APP_PERMISSIONS, APP_PERMISSION_ACTIONS } from "../../../../config/constants";
// import { getPermission } from "../../../../config/helpers/permissionHelper";

const CancelBooking = ({ bookingId, refresh }) => {
  // const permissions = useSelector((state) => state?.user?.permissions)
  // const isGlobal = useSelector((state) => state?.user?.user?.isGlobal)
  const disclosure = useDisclosure();
  const cancelBookingQuery = useCancelBooking();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const reason = watch("reason");
  const chargeType = watch("chargeType");

  const onCancelBooking = (values) => {
    cancelBookingQuery
      .mutateAsync({
        id: bookingId,
        body: { ...values, reason: Number(values.reason) },
      })
      .then(() => refresh())
      .catch((err) => console.warn(err));
  };

  // cancel booking permission was removed from BE
  // const modulePermissions = getPermission({ isGlobal, permissionKey: APP_PERMISSIONS.BOOKING, permissions })
  // if (!(modulePermissions?.additionalActions?.includes("DispatchLogs") || modulePermissions === true)) {
  //   return null
  // }

  return (
    <CancelBookingPopover
      onSubmit={handleSubmit(onCancelBooking)}
      disclosure={disclosure}
      triggerProps={{
        colorScheme: "red",
        size: "sm",
        fontSize: "14px",
        leftIcon: <Icon boxSize={5} as={APP_ICONS.CANCEL} />,
      }}
      heading="Cancel Booking"
      label="Cancel Booking"
      isSubmitting={cancelBookingQuery.isLoading}
      setValue={setValue}
      chargeType={chargeType}
    >
      <Box as="form">
        <FormRadio
          id={"reason"}
          label={"Specify Reason"}
          errors={errors}
          required={true}
          options={makeSelectList(CANCEL_BOOKING_TYPE, "name", "id", false, breakWithCaps)}
          control={control}
          stackProps={{
            direction: "column",
          }}
          labelProps={{ fontWeight: "bold" }}
        />
        {Number(reason) === CANCEL_BOOKING_REASON.Other && (
          <FormTextarea
            id="otherReason"
            control={control}
            errors={errors}
            placeholder="Specify your reason"
            inputProps={{ size: "sm" }}
          />
        )}
      </Box>
    </CancelBookingPopover>
  );
};

export default CancelBooking;
