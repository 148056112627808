import { Card, chakra, Flex, Box, Text, useColorMode, Image, } from '@chakra-ui/react'
import React from 'react'
import { getColor, colorKeys } from "../../../config/constants/appColors"
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'
import { useVerifyOtp } from '../../../config/query/authQuery'
import IMAGES from '../../../config/constants/images'
import COLOR_MODES from '../../../config/constants/colorModes'
import WhiteLogo from '../../../components/SVGComponents/WhiteLogo'
import FormPinInput from '../../../components/Forms/FormPinInput'
import { useDispatch, useSelector } from 'react-redux'
import { addTempToken } from '../../../config/redux/slices/userSlice'

const Verify = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()
    const tenant = useSelector((state) => state.tenant?.tenant)
    const { reset } = useSelector(state => state.user)
    const verifyOtpQuery = useVerifyOtp()

    const {
        handleSubmit,
        control,
        setValue,
        reset: resetForm,
        formState: { errors },
    } = useForm()

    function onSubmit(values) {
        verifyOtpQuery.mutateAsync({ ...values, email: reset?.tempEmail })
            .then((response) => {
                resetForm()
                dispatch(addTempToken(response.token))
                navigate(`/auth/reset-password`)
            })
            .catch((error) => {
                setValue("token", "")
                console.warn(error, "error")
            })
    }

    if (!reset?.tempEmail) {
        return <Navigate to="/auth/forgot-password" />
    }


    return (
        <Flex height={"100vh"} justify="center" align="center" bg={getColor(colorKeys.white, colorMode)} background={`url(${tenant?.backgroundImageUrl})`} backgroundRepeat={"no-repeat"} backgroundSize={"cover"} backgroundPosition={"center"}>
            <Flex w="full" justify={"center"} align={"center"} flexDir={"column"}>
                <Card w="full" maxW="480px">
                    <Box w="full" maxW="480px">

                        <Flex px="5" justify={"space-between"} bg={getColor(colorKeys.dimmerBlue, colorMode)}>
                            <Box>
                                <Text fontSize="16px" color={getColor(colorKeys.white, colorMode)} mt={5}>
                                    OTP
                                </Text>
                                <Text fontSize="13px" color={getColor(colorKeys.white, colorMode)} >
                                    Enter OTP
                                </Text>
                            </Box>

                            <Box background={`url(${IMAGES.AUTH_MIN})`} w="173px" h="112px" />
                        </Flex>

                        <Box
                            pos="absolute"
                            top="75px"
                            left="25px"
                            w="90px"
                            m="auto"
                            bg={getColor(colorKeys.white, colorMode)}
                            rounded="full"
                        >
                            {colorMode === COLOR_MODES.LIGHT ?
                                <Image w="full" h="full" src={tenant?.logoUrl} alt="logo" />
                                : <WhiteLogo containerStyles={{ margin: "auto" }} />}
                        </Box>

                        <Box p="10">

                            <chakra.form onSubmit={handleSubmit(onSubmit)} m="30px 0">

                                <FormPinInput
                                    control={control}
                                    id={"token"}
                                    errors={errors}
                                    rules={{
                                        required: "OTP is required",
                                        minLength: {
                                            value: 6,
                                            message: "Minimum length should be 6",
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: "Maximum length should be 6",
                                        },
                                        pattern: {
                                            value: /^[0-9]*$/,
                                            message: "Only numbers are allowed",
                                        },
                                    }}
                                />

                                <PrimaryButton
                                    isLoading={verifyOtpQuery.isLoading}
                                    mt={5}
                                    type="submit"
                                    p="18px 33px"
                                    bg={getColor(colorKeys.spanishYellow, colorMode)}
                                    w="full"
                                    rounded="md"
                                    fontWeight={"bold"}
                                    color="white"
                                >
                                    Submit
                                </PrimaryButton>


                            </chakra.form>

                        </Box>

                    </Box>


                </Card>

            </Flex>
        </Flex>
    )
}

export default Verify