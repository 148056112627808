import React, { useEffect } from 'react'
import { Badge, Box, Wrap, WrapItem, Tooltip } from '@chakra-ui/react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import ZoneWiseRateForm from './ZoneWiseRateForm'
import { APP_MODULES } from '../../../../config/constants'
import { useZoneRates } from '../../../../config/query/pricingQuery'
import { formatPercentage } from '../../../../config/helpers/stringHelper'

const ZoneWiseRates = ({ selectedRow, setSelectedRow, disclosure, setIsRefreshing, isRefreshing }) => {

    const onEditModal = (item) => {
        setSelectedRow(item)
        disclosure.onOpen()
    }

    const zonesQuery = useZoneRates()

    useEffect(() => {
        if (isRefreshing) zonesQuery.refetch().then(() => setIsRefreshing(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshing])

    return (
        <Box>
            <CustomTable
                permissionKey={APP_MODULES.Zone}
                tableFor={"zone"}
                fixedHeight={false}
                tableWrapperProps={{ padding: 0, h: "auto" }}
                hideFilterBar={true}
                containerProps={{ mt: 4 }}
                head={[
                    { title: "Zone Names", extractor: "zoneName" },
                    { title: "Zone Tax", extractor: "zoneTax", component: (item) => formatPercentage(item.zoneTax) },
                    {
                        title: "Zone Rides",
                        extractor: "zoneRides",
                        component: (item) =>
                            item.zoneRides?.length > 0
                                ? <Wrap px="0" maxW="550px">
                                    {item.zoneRides?.map((ride, index) =>
                                        <WrapItem key={index}>
                                            <Tooltip label={`Vehicle Type: ${ride.vehicleType} - Pricing: ${ride.pricing}`}>
                                                <Badge>{`${ride.vehicleType} - ${ride.pricing}`}</Badge>
                                            </Tooltip>
                                        </WrapItem>
                                    )}
                                </Wrap>
                                : <Badge>No Rides</Badge>
                    },
                    { title: "Last Modified", align: "center", extractor: "lastModifiedOn", component: (item) => <Chronology data={item} /> },
                    { title: "Action", extractor: "action", align: "center", component: (item) => <PrimaryButton size="xs" rounded="lg" onClick={() => onEditModal(item)}>View Details</PrimaryButton> },
                ]}
                data={zonesQuery.data?.data}
                loading={zonesQuery?.isLoading}
                onRefresh={() => zonesQuery.refetch()}
                isRefreshing={zonesQuery?.isFetching}
            />
            <ZoneWiseRateForm data={selectedRow} disclosure={disclosure} />
        </Box>
    )
}

export default ZoneWiseRates