import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error";

import AuthLayout from "../../components/BasicUI/AuthLayout";
import AdminLayout from "../../components/BasicUI/AdminLayout";

import Login from "../../views/auth/Login";
import Signup from "../../views/auth/Signup";

import LiveMap from "../../views/admin/LiveMap";
import Customers from "../../views/admin/Customers";
import Drivers from "../../views/admin/Drivers";
import Corporate from "../../views/admin/Corporate";
import Zone from "../../views/admin/Settings/Zone";
import ForgotPassword from "../../views/auth/ForgotPassword";
import Reset from "../../views/auth/Reset";
import Verify from "../../views/auth/Verify";
import Dashboard from "../../views/admin/Dashboard";
import Bookings from "../../views/admin/Bookings";
import Profile from "../../views/admin/Profile";
import ChangePassword from "../../views/admin/ChangePassword";
import BookingDetails from "../../views/admin/Bookings/BookingDetails";
import Reports from "../../views/admin/Reports";
import Pricing from "../../views/admin/Pricing";
import SubAdmin from "../../views/admin/SubAdmin";
import CustomerDetails from "../../views/admin/Customers/CustomerDetails";
import DriverDetails from "../../views/admin/Drivers/DriverDetails";
import CorporateDetails from "../../views/admin/Corporate/CorporateDetails";
import ZoneForm from "../../views/admin/Settings/Zone/ZoneForm";
import PromoCodes from "../../views/admin/PromoCodes";
import Notifications from "../../views/admin/Notifications";
import Transport from "../../views/admin/Transport";
import Tenants from "../../views/admin/Tenants";
import BookingForm from "../../views/admin/Bookings/BookingForm";
import Roles from "../../views/admin/Roles";
import Settings from "../../views/admin/Settings";
import SetPassword from "../../views/auth/SetPassword";
import ActiveBookings from "../../views/admin/ActiveBookings";
import Reviews from "../../views/admin/Reviews";
import Chats from "../../views/admin/Chats";
import Conversation from "../../components/Chat/ChatLayout/Conversation";
import Impersonate from "../../views/auth/Impersonate";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={"/auth/login"} replace />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={"/auth/login"} replace />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <Reset />,
      },
      {
        path: "verify",
        element: <Verify />,
      },
      {
        path: "set-password",
        element: <SetPassword />,
      },
      {
        path: "impersonate",
        element: <Impersonate />
      }
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={"/admin/dashboard"} replace />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "bookings",
        element: <Bookings />,
      },
      {
        path: "bookings/create",
        element: <BookingForm />,
      },
      {
        path: "bookings/:bookingId",
        element: <BookingDetails />,
      },
      {
        path: "active-bookings",
        element: <ActiveBookings />,
      },
      {
        path: "active-bookings/:bookingId",
        element: <BookingDetails />,
      },
      {
        path: "parking-lot/:bookingId",
        element: <BookingDetails />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "zones",
        element: <Zone />,
      },
      {
        path: "zones/create",
        element: <ZoneForm />,
      },
      {
        path: "zones/:id",
        element: <ZoneForm />,
      },
      {
        path: "map",
        element: <LiveMap />,
      },
      {
        path: "reports",
        element: <Navigate to="/admin/reports/booking-details" />,
      },
      {
        path: "reports/booking-details",
        element: <Reports />,
      },
      {
        path: "reports/transactions",
        element: <Reports />,
      },
      {
        path: "reports/drivers",
        element: <Reports />,
      },
      {
        path: "reports/driver-logs",
        element: <Reports/>,
      },
      {
        path: "pricing",
        element: <Navigate to="/admin/pricing/default-rates" />,
      },
      {
        path: "pricing/default-rates",
        element: <Pricing />,
      },
      {
        path: "pricing/zone-wise-rates",
        element: <Pricing />,
      },
      {
        path: "pricing/corporate-pricing",
        element: <Pricing />,
      },
      {
        path: "sub-admin",
        element: <SubAdmin />,
      },
      {
        path: "drivers",
        element: <Drivers />,
      },
      {
        path: "drivers/:driverId",
        element: <DriverDetails />,
      },
      {
        path: "vehicle-management",
        element: <Transport />,
      },
      {
        path: "vehicle-management/service-types",
        element: <Transport />,
      },
      {
        path: "vehicle-management/vehicles",
        element: <Transport />,
      },
      {
        path: "vehicle-management/make",
        element: <Transport />,
      },
      {
        path: "vehicle-management/model",
        element: <Transport />,
      },
      {
        path: "customers",
        element: <Customers />,
      },
      {
        path: "customers/:customerId",
        element: <CustomerDetails />,
      },
      {
        path: "corporate",
        element: <Corporate />,
      },
      {
        path: "corporate/:corporateId",
        element: <CorporateDetails />,
      },
      {
        path: "promo-codes",
        element: <PromoCodes />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "tenants",
        element: <Tenants />,
      },
      {
        path: "roles",
        element: <Roles />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "settings/shifts",
        element: <Settings />,
      },
      {
        path: "settings/configurations",
        element: <Settings />,
      },
      {
        path: "settings/integrations",
        element: <Settings />,
      },
      {
        path: "settings/cities",
        element: <Settings />,
      },
      {
        path: "settings/state",
        element: <Settings />,
      },
      {
        path: "settings/country",
        element: <Settings />,
      },
      {
        path: "settings/color",
        element: <Settings />,
      },
      // {
      //   path: "settings/files",
      //   element: <Settings />,
      // },
      {
        path: "reviews",
        element: <Reviews />,
      },
      {
        path: "chats",
        element: <Chats />,
        children: [
          {
            path: ":chatId",
            element: <Conversation />
          }
        ]
      },
      {
        path: "chats/support",
        element: <Chats />,
        children: [
          {
            path: ":chatId",
            element: <Conversation />
          }
        ]
      },
      {
        path: "chats/disputes",
        element: <Chats />,
        children: [
          {
            path: ":chatId",
            element: <Conversation />
          }
        ]
      },
      {
        path: "chats/groups",
        element: <Chats />,
        children: [
          {
            path: ":chatId",
            element: <Conversation />
          }
        ]
      },
    ],
  },
]);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
