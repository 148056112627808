import React from "react";
import { Box, SimpleGrid, VStack } from "@chakra-ui/react";
import FormModal from "../../../components/Forms/FormModal";
import FormInput from "../../../components/Forms/FormInput";
import { useRoleForm } from "./roleContainer";
import PermissionsGroup from "./PermissionsGroup";

const RoleForm = ({ disclosure, data }) => {

  const {
    handleSubmit,
    control,
    errors,
    onSubmit,
    resetForm,
    permissionsQuery,
    isSubmitting
  } = useRoleForm({ data, disclosure })

  return (
    <FormModal
      title={data ? "Edit Role" : "Add Role"}
      disclosure={disclosure}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"75rem"}
      reset={resetForm}
      onCloseFn={resetForm}
    >
      <VStack spacing={5} align="stretch">
        <Box>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
            <FormInput
              label={"First Name"}
              control={control}
              errors={errors}
              id="name"
              required={true}
              placeholder="Enter first name"
              inputProps={{ size: "sm" }}
            />
            <FormInput
              label={"Description"}
              control={control}
              errors={errors}
              id="description"
              required={true}
              placeholder="Description"
              inputProps={{ size: "sm" }}
            />
          </SimpleGrid>
        </Box>

        <PermissionsGroup
          control={control}
          permissions={permissionsQuery.data?.data}
          errors={errors}
        />
      </VStack>
    </FormModal>
  );
};

export default RoleForm;
