import React from 'react'
import TableInfoPopover from '../Popovers/TableInfoPopover'
import LabelValuePair from '../LabelValuePair'
import { formatDateTime, formatDateTimeFromNow } from '../../../config/helpers/dateHelper'

const Chronology = ({ data, updatedAt, createdAt }) => {
    return (
        <TableInfoPopover
            data={data}
            triggerText={updatedAt ? formatDateTimeFromNow(updatedAt) : formatDateTimeFromNow(data.lastModifiedOn)}
            title={"Chronology"}
            triggerAction='hover'
            triggerProps={{ m: "auto", fontSize: "14px" }}
        >
            <LabelValuePair
                label="Updated at"
                value={updatedAt ? formatDateTimeFromNow(updatedAt) : formatDateTime(data.lastModifiedOn)}
            />

            <LabelValuePair
                label="Created at"
                value={createdAt ? formatDateTime(createdAt) : formatDateTime(data.createdOn)}
            />
        </TableInfoPopover>
    )
}

export default Chronology