import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { Box, Image } from '@chakra-ui/react'
import { useDeleteVehicleType, useUpdateVehicleTypeStatus, useVehicleTypes } from '../../../../config/query/transportQuery'
import BreakText from '../../../../components/BasicUI/DataBoxes/BreakText'
import StatusSwitch from '../../../../components/BasicUI/DataBoxes/StatusSwitch'
import VehicleTypeForm from './VehicleTypeForm'
import IMAGES from '../../../../config/constants/images'
import { makeInitialQueryObject } from '../../../../config/helpers/queryHelper'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import useDebounce from '../../../../config/hooks/useDebounce'
import { APP_MODULES } from '../../../../config/constants'

const VehicleTypes = ({ selectedRow, setSelectedRow, disclosure, setIsRefreshing, isRefreshing }) => {

    const [query, setQuery] = useState(makeInitialQueryObject())
    const debouncedQuery = useDebounce(query)

    const onEditModal = (item) => {
        setSelectedRow(item)
        disclosure.onOpen()
    }

    const vehicleTypesQuery = useVehicleTypes(debouncedQuery)
    const deleteVehicleType = useDeleteVehicleType()
    const vehicleTypeStatus = useUpdateVehicleTypeStatus()

    const onQueryChange = (updatedQuery) => {
        setQuery((prev) => ({ ...prev, ...updatedQuery }))
    }

    const handleStatusChange = (status, id) => {
        vehicleTypeStatus.mutate({ status: Number(status), id })
    }

    const onDelete = (id) => {
        deleteVehicleType
            .mutateAsync(id)
            .then(() => vehicleTypesQuery.refetch())
            .catch((err) => console.warn(err))
    }

    useEffect(() => {
        if (isRefreshing) vehicleTypesQuery.refetch().then(() => setIsRefreshing(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshing])

    const onFormSuccess = () => {
        vehicleTypesQuery.refetch()
        setSelectedRow(null)
    }

    return (
        <Box>
            <CustomTable
                permissionKey={APP_MODULES.VehicleManagement}
                tableFor={"service type"}
                fixedHeight={false}
                tableWrapperProps={{ padding: 0, h: "auto" }}
                hideFilterBar={true}
                containerProps={{ mt: 4 }}
                head={[
                    { title: "Image", extractor: "imageUrl", component: (item) => <Image src={item.imageUrl} boxSize="35px" objectFit={"contain"} fallbackSrc={IMAGES.DUMMY_IMAGE} /> },
                    { title: "Vehicle Name", extractor: "vehicleName", isSortable: true },
                    { title: "Vehicle Class", extractor: "vehicleClass", isSortable: true, component: (item) => <BreakText value={item.vehicleClass.name} /> },
                    { title: "Seating Capacity", extractor: "seatingCapacity", align: "center", isSortable: true },
                    { title: "Status", extractor: "status", align: "center", isSortable: true, component: (item) => <StatusSwitch key={item.id} value={item.status?.id} onChange={(e) => handleStatusChange(e.target.checked, item.id)} /> },
                    { title: "Last Modified", align: "center", extractor: "lastModifiedOn", isSortable: true, component: (item) => <Chronology data={item} /> },
                    { title: "Action", extractor: "actions", align: "center" },
                ]}
                data={vehicleTypesQuery.data?.data}
                loading={vehicleTypesQuery?.isLoading}
                totalResults={vehicleTypesQuery?.data?.totalCount}
                totalPages={vehicleTypesQuery?.data?.totalPages}
                pageNo={query?.PageNumber}
                pageSize={query?.PageSize}
                onQueryChange={onQueryChange}
                query={query}

                onEdit={onEditModal}
                onDelete={onDelete}
            />
            <VehicleTypeForm data={selectedRow} disclosure={disclosure} onSuccess={onFormSuccess} />
        </Box>
    )
}

export default VehicleTypes