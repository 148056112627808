import { FormControl, chakra, FormErrorMessage, FormLabel, useColorMode, Flex } from '@chakra-ui/react'
import React from 'react'
import { useController } from 'react-hook-form'
import { getColor, colorKeys } from '../../../config/constants/appColors'
import DrawPolygon from '../../Maps/DrawPolygon'

const FormMap = ({ label, id, required = false, errors = {}, control, rules, disabled, polygonsArray, containerProps, formControlProps, labelExtention, center, currentScreen, strictBorders = [] }) => {
    const { colorMode } = useColorMode()

    if (required) {
        required = `${label} is required`
    }

    const { field } = useController({
        name: id,
        control: control,
        defaultValue: [],
        rules: {
            required: required,
            ...rules
        },
    })

    return (
        <FormControl isInvalid={errors[id]} {...formControlProps}>
            <Flex align="end" justify={"space-between"}>
                <Flex align={"center"} my={3}>
                    <FormLabel htmlFor={id} fontSize={"13px"} m="0">
                        {label}
                        {required && <chakra.span color={getColor(colorKeys.danger, colorMode)}>*</chakra.span>}
                    </FormLabel>
                    <FormErrorMessage m="0" ml={2}>
                        {errors[id] && `(${errors[id].message})`}
                    </FormErrorMessage>
                </Flex>

                {labelExtention}
            </Flex>
            <DrawPolygon
                ref={field.ref}
                disabled={disabled}
                currentScreen={currentScreen}
                polylineCoords={field.value || []}
                setPolylineCoords={(value) => {
                    field.onChange(value)
                }}
                drawPolygon={true}
                polygonsArray={polygonsArray}
                containerProps={containerProps}
                center={center}
                disableZoom={true}
                customZoom={5}
                strictBorders={strictBorders}
            />

        </FormControl>
    )
}

export default FormMap