import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Put } from "../api";
import appendQueryParams from "../helpers/appendQueryParams";
import API_CONSTANTS from "../constants/api";

export const useDirectInvoices = ({ corporateId, params }) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ['direct-invoices', corporateId, params],
        queryFn: async () => {
            const data = await Get({
                path: `${API_CONSTANTS.INVOICES.corporateInvoices}/${corporateId}?${appendQueryParams(params)}`,
                token,
            })
            return data
        },
        keepPreviousData: true,
        enabled: !!corporateId
    })
}

export const useUpdatePayPeriod = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Put({
                path: API_CONSTANTS.INVOICES.updatePayPeriod,
                token,
                body,
                toastError: true,
                toastMessage: true,
            })
            return data
        }
    })
}

export const useUpdatePaymentStatus = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const data = await Put({
                path: API_CONSTANTS.INVOICES.updatePaymentStatus,
                token,
                body,
                toastError: true,
                toastMessage: true,
            })
            return data
        }
    })
}

export const useGeneratePDF = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (invoiceId) => {
            const data = await Get({
                path: API_CONSTANTS.INVOICES.generatePdf.replace(":id", invoiceId),
                token,
                toastError: true,
                toastMessage: true,
            })
            return data
        }
    })
}