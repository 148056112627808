import React from "react";
import FormModal from "../../../../components/Forms/FormModal";
import { useForm } from "react-hook-form";
import FormInput from "../../../../components/Forms/FormInput";
import {
  useCreateCountry,
  useUpdateCountry,
} from "../../../../config/query/countryQuery";

const CountryForm = ({ disclosure, data }) => {
  const createCountryQuery = useCreateCountry();
  const updateCountryQuery = useUpdateCountry();

  const {
    handleSubmit,
    reset: resetForm,
    control,
    formState: { errors },
  } = useForm({
    values: {
      name: data?.name,
    },
  });

  const onSubmit = (values) => {
    const payload = {
      id: data?.id,
      ...values,
    };

    let formMutate = data?.id
      ? updateCountryQuery.mutateAsync
      : createCountryQuery.mutateAsync;
    formMutate(payload)
      .then(() => {
        disclosure.onClose();
        resetForm();
      })
      .catch((error) => console.warn(error));
  };

  return (
    <FormModal
      title={data?.id ? "Edit Country" : "Add Country"}
      disclosure={disclosure}
      isSubmitting={createCountryQuery.isLoading || updateCountryQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      maxW={"30rem"}
      saveButtonText={"Save"}
      reset={resetForm}
      onCloseFn={() => resetForm()}
    >
      <FormInput
        label={"Name"}
        control={control}
        errors={errors}
        id="name"
        required={true}
        placeholder="Enter name"
        inputProps={{ size: "sm" }}
      />
    </FormModal>
  );
};

export default CountryForm;
