import { Box, Card, CardBody, Heading, VStack, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import RatingBox from './RatingBox'
import { useDashboardRatings } from '../../../config/query/dashboardQuery'

const OverallRatings = ({ query }) => {
    const { colorMode } = useColorMode()
    const { data } = useDashboardRatings(query)
    return (
        <Card h="full" bg={getColor(colorKeys.tableBackground, colorMode)}>
            <CardBody>
                <Heading as="h2" size={"md"} fontSize="15px" fontWeight={"600"}>Over All Ratings</Heading>
                <Box mt={5}>
                    <VStack spacing={5} overflowY="auto">
                        <RatingBox item={data?.data?.[5]} count={5} />
                        <RatingBox item={data?.data?.[4]} count={4} />
                        <RatingBox item={data?.data?.[3]} count={3} />
                        <RatingBox item={data?.data?.[2]} count={2} />
                        <RatingBox item={data?.data?.[1]} count={1} />
                        <RatingBox item={data?.data?.[0]} count={0} />
                    </VStack>
                </Box>
            </CardBody>
        </Card>
    )
}

export default OverallRatings