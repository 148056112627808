import React, { useState, useEffect, useCallback } from "react";
import {
    Flex,
    Input,
    Select,
    InputGroup,
    InputLeftElement,
    chakra,
    FormControl,
    FormLabel,
    useColorMode,
    Text
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { parsePhoneNumber } from "libphonenumber-js";
import { debounce } from "lodash";
import { COUNTRIES } from "./countries";
import { getColor, colorKeys } from "../../../config/constants/appColors";
import { accessValue } from "../../../config/helpers/stringHelper";
import { DEFAULT_DEBOUNCE_TIME } from "../../../config/constants";

const FormPhoneInput = ({
    size = "md",
    label,
    placeholder,
    id,
    required = false,
    errors = {},
    control,
    rules,
    containerStyles,
    countryCodeValue,
    setCountryCodeValue,
    countryCodeSelectProps,
    inputLeftElementProps,
    inputProps,
    phoneNumberValue,
    setPhoneNumberValue,
    setError,
    clearErrors,
}) => {

    const { colorMode } = useColorMode();
    let [countryCode, setCountryCode] = useState("");

    const countryOptions = COUNTRIES.map(({ name, iso, prefix }) => ({
        label: `${name} (${prefix})`,
        value: prefix
    }));

    if (required) {
        required = `${label} is required`
    }

    const onCountryChange = e => {
        try {
            let value = e.target.value;
            setCountryCodeValue(value);
            setCountryCode(value);
            let parsedNumber = parsePhoneNumber(`${e.target.value}${phoneNumberValue}`)
            setPhoneNumberValue(parsedNumber.nationalNumber);

            if (!parsedNumber.isValid()) {
                if (phoneNumberValue?.length > 0)
                    setError(id, {
                        type: "manual",
                        message: "Invalid phone number"
                    })
            } else {
                clearErrors(id)
            }
        } catch (error) {
            console.warn(error)
            if (phoneNumberValue?.length > 0)
                setError(id, {
                    type: "manual",
                    message: "Invalid phone number"
                })
        }
    };

    const handlePhoneChange = ({ phone, country }) => {
        try {
            let parsedNumber = parsePhoneNumber(`${country}${phone}`)

            if (!countryCodeValue?.length) {
                setCountryCodeValue(parsedNumber.countryCallingCode);
                setCountryCode(parsedNumber.countryCallingCode);
            }

            if (!parsedNumber.isValid()) {
                if (phone?.length > 0)
                    setError(id, {
                        type: "manual",
                        message: "Invalid phone number"
                    })
            } else {
                clearErrors(id)
            }
        } catch (error) {
            if (phone?.length > 0)
                setError(id, {
                    type: "manual",
                    message: "Invalid phone number"
                })
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncePhoneChange = useCallback(debounce(handlePhoneChange, DEFAULT_DEBOUNCE_TIME), []);

    useEffect(() => {
        debouncePhoneChange({ phone: phoneNumberValue, country: countryCodeValue })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneNumberValue])

    useEffect(() => {
        if (!countryCodeValue?.length) {
            setCountryCodeValue("+1")
            setCountryCode("+1")
            return
        }
        setCountryCode(countryCodeValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCodeValue])

    const blurEffect = (value) => {
        try {
            let parsedNumber = parsePhoneNumber(`${countryCodeValue}${value}`)
            setPhoneNumberValue(parsedNumber.formatNational());
        } catch (error) {
            if (phoneNumberValue?.length > 0)
                setError(id, {
                    type: "manual",
                    message: "Invalid phone number"
                })
        }
    }

    return (
        <Controller
            control={control}
            name={id}
            rules={{
                required: required,
                ...rules
            }}
            render={({ field: { onChange, onBlur, value, ref, ...rest } }) => {
                return (
                    <FormControl isInvalid={errors[id] || accessValue(errors, `${id}.message`)} {...containerStyles}>
                        <FormLabel htmlFor={id} fontSize={"13px"}>
                            {label}
                            {required && <chakra.span color={getColor(colorKeys.danger, colorMode)}>*</chakra.span>}
                        </FormLabel>
                        <InputGroup size={size} {...rest}>
                            <InputLeftElement
                                pr="30px"
                                left="1px"
                                top="1px"
                                h="94%"
                                borderTopStartRadius={size}
                                borderBottomStartRadius={size}
                                bg={getColor(colorKeys.lightGray, colorMode)}
                                width="5rem"
                                className="phone-input-dropdown"
                                size={size}
                                {...inputLeftElementProps}
                            >
                                <Select
                                    name="countryCode"
                                    top="0"
                                    left="0"
                                    zIndex={1}
                                    bottom={0}
                                    opacity={0}
                                    height="100%"
                                    position="absolute"
                                    size={size}
                                    value={countryCode || "+1"}
                                    onChange={onCountryChange}
                                    {...countryCodeSelectProps}
                                >
                                    {countryOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </Select>
                                <Flex pl={3} width="120%" alignItems="center">
                                    {countryCode || "+1"}
                                </Flex>
                            </InputLeftElement>
                            <Input
                                ref={ref}
                                pl="6rem"
                                type="tel"
                                value={value}
                                placeholder={placeholder}
                                onChange={onChange}
                                onBlur={(e) => {
                                    blurEffect(value)
                                    onBlur(e)
                                }}
                                {...rest}
                                {...inputProps}
                            />
                        </InputGroup>
                        <Text mt={1} color="#FC8181" fontSize={"14px"}>
                            {accessValue(errors, `${id}.message`)}
                        </Text>
                    </FormControl>
                )
            }}
        />
    );
}

export default FormPhoneInput