import React from 'react'
import FormModal from '../../../../components/Forms/FormModal'
import { useForm } from 'react-hook-form'
import FormInput from '../../../../components/Forms/FormInput'
import { useQueryClient } from '@tanstack/react-query'
import { useUpdateCorporateRate } from '../../../../config/query/pricingQuery'
import { InputRightAddon } from '@chakra-ui/input'

const CoporatePricingForm = ({ disclosure, data }) => {

    const queryClient = useQueryClient()
    const updateCorporateRate = useUpdateCorporateRate()

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: { errors },
    } = useForm({
        values: {
            corporateRate: data?.corporateRate,
            corporateId: data?.corporate?.id
        }
    })

    const onSubmit = (values) => {
        updateCorporateRate.mutateAsync({
            ...values,
            corporateRate: Number(values.corporateRate),
        })
            .then(() => {
                disclosure.onClose()
                queryClient.invalidateQueries(["corporateRates"])
                resetForm()
            })
            .catch(error => console.warn(error))
    }

    return (
        <FormModal
            title={"Update Corporate Pricing"}
            disclosure={disclosure}
            isSubmitting={updateCorporateRate.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"40rem"}
            onCloseFn={() => resetForm()}
        >
            <FormInput
                label={"Corporate Rate"}
                control={control}
                errors={errors}
                id="corporateRate"
                required={true}
                placeholder="Enter corporate rate"
                inputProps={{ size: "sm" }}
                size='sm'
                rightAddon={<InputRightAddon children={"%"} />}
                type="number"
                feedbackMessage={"Corporate rate can also contain negative values"}
                rules={{
                    required: "Corporate rate is required",
                    min: {
                        value: -100,
                        message: "Corporate rate must be greater than -101",
                    },
                    max: {
                        value: 100,
                        message: "Corporate rate must be less than 101"
                    }
                }}
            />
        </FormModal>
    )
}

export default CoporatePricingForm