import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, Heading, Icon } from '@chakra-ui/react'
import PrimaryButton from '../Buttons/PrimaryButton'
import { getPermission } from '../../../config/helpers/permissionHelper'

const TableHeaderOptions = ({ title, actionText, action, icon, actionProps, containerProps, titleProps, permissionKey }) => {
    const permissions = useSelector((state) => state.user?.permissions)
    const isGlobal = useSelector((state) => state.user?.user?.isGlobal)
    const modulePermission = getPermission({ isGlobal, permissionKey, permissions })
    return (
        <Flex w="full" justify="space-between" align={"center"} {...containerProps} flexWrap={"wrap"}>
            {title && <Heading as="h2" fontSize={26} fontWeight="bold" {...titleProps}>{title}</Heading>}
            {(actionText && action) && (modulePermission?.create || modulePermission === true || !permissionKey) && (
                <PrimaryButton
                    onClick={action}
                    leftIcon={icon && <Icon boxSize={6} as={icon} />}
                    {...actionProps}
                >
                    {actionText}
                </PrimaryButton>
            )}
        </Flex>
    )
}

export default TableHeaderOptions

