import { Box, Flex, Text, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor } from '../../../config/constants/appColors'

const PieStatsBox = ({ label, iconColor, count }) => {
    const { colorMode } = useColorMode()
    return (
        <Flex>
            <Box rounded="full" background={iconColor} fill={"#fff"} mt="3px" mr="5px" boxSize={"3"} />
            <Box>
                <Text fontSize="14px" color={getColor(colorKeys.secondaryText, colorMode)}>{label}</Text>
                <Text fontSize="14px">{count}</Text>
            </Box>
        </Flex>
    )
}

export default PieStatsBox