import React, { useState } from "react";
import FormModal from "../../../../components/Forms/FormModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import Config from "../../../../config";
import { customStripeTheme } from "../../../../config/constants/stripe";
import StripeConnect from "../../../../components/Payments/StripeConnect";
import { useCustomerSetupIntent } from "../../../../config/query/customerQuery";

const stripePromise = loadStripe(Config.env().STRIPE_PUBLISHABLE_KEY);

const Wrapper = (props) => (
    <Elements
        stripe={stripePromise}
        options={{
            mode: "setup",
            currency: "usd",
            appearance: customStripeTheme,
            payment_method_types: ["card"],
            paymentMethodTypes: ["card"],
            
            
        }}
    >
        <CardForm {...props} />
    </Elements>
);

const CardForm = ({ disclosure, data, onSuccess }) => {
    const stripe = useStripe();
    const elements = useElements()
    const [loading, setLoading] = useState(false);

    const setupIntent = useCustomerSetupIntent();

    const [count, setCount] = useState(0);

    const handleError = (error) => {
        setLoading(false);
    };

    const handleSubmit = async (event) => {
        setCount(count + 1);
        event.preventDefault();
        if (!stripe) {
            return;
        }

        setLoading(true);
        await elements.submit();
        setupIntent
            .mutateAsync(data?.id)
            .then((response) => {
                stripe
                    .confirmSetup({
                        elements,
                        clientSecret: response?.clientSecret,
                        redirect: "if_required"
                    })
                    .then((res) => {
                        if (res?.error) {
                            handleError(res?.error);
                        } else {
                            onSuccess && onSuccess()
                            setLoading(false)
                            disclosure?.onClose();
                        }
                    });
            })
            .catch((error) => console.warn(error, "error"))
    };

    return (
        <FormModal
            title={"Add Customer Card"}
            disclosure={disclosure}
            onSubmit={handleSubmit}
            maxW={"50rem"}
            onCloseFn={() => { }}
            containerProps={{ as: "form" }}
            isSubmitting={loading || setupIntent.isLoading}
            disabled={!stripe || loading || setupIntent.isLoading}
        >
            <StripeConnect />
        </FormModal>
    );
};

export default Wrapper;
