import { HStack, Icon, Skeleton } from '@chakra-ui/react'
import React from 'react'
import APP_ICONS from '../../../config/constants/icons'

const StarRating = ({ count, size = 5, value, activeColor, isLoading, containerProps, fallBackText = "N/A", forceRenderStars = false }) => {
    if (!value && !forceRenderStars) return fallBackText
    if (isLoading) return (
        <HStack w="full" justifyContent="center" {...containerProps}>
            <Skeleton w={"140px"} h={"20px"} />
        </HStack>
    )
    return (
        <HStack w="full" justifyContent="center" {...containerProps}>
            {Array(count).fill().map((_, i) => {
                let icon = APP_ICONS.STAR
                const starValue = i + 1
                const isHalf = value < starValue && value > starValue - 1
                const isFloat = value % 1 !== 0
                if (isHalf && isFloat) {
                    icon = APP_ICONS.STAR_HALF
                } else if (value >= starValue) {
                    icon = APP_ICONS.STAR_FILL
                }
                return (
                    <Icon
                        key={i}
                        as={icon}
                        color={activeColor}
                        boxSize={size}
                    />
                )
            })}
        </HStack>
    )
}

export default StarRating