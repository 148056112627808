import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";

export const useZoneSurcharge = (zoneId) => {
    const token = useSelector((state) => state.user?.token);
    return useQuery({
        queryKey: ["zones-surcharge", zoneId],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.ZONE_SURCHARGE.getByZoneId.replace(":zoneId", zoneId),
                token,
            });
            return data;
        },
        keepPreviousData: true,
    });
};


export const useCreateZoneSurcharge = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Post({
                path: API_CONSTANTS.ZONE_SURCHARGE.save,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => queryClient.invalidateQueries(["zones-surcharge"])
    })
}

export const useUpdateZoneSurcharge = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const res = await Put({
                path: API_CONSTANTS.ZONE_SURCHARGE.save,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return res
        },
        onSuccess: () => queryClient.invalidateQueries(["zones-surcharge"])
    })
}

export const useDeleteZoneSurcharge = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: API_CONSTANTS.ZONE_SURCHARGE.delete.replace(":id", id),
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
        onSuccess: () => queryClient.invalidateQueries(["zones-surcharge"])
    })
}