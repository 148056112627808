import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomDateInput from "../Forms/CustomDateInput";
import moment from "moment";

const DateRangeInput = ({
  query = {},
  onQueryChange,
  fromDateKey = "FromDateTime",
  toDateKey = "ToDateTime",
}) => {
  return (
    <Flex gap={3} justify={"space-between"}>
      <CustomDateInput
        containerProps={{ maxW: "210px" }}
        groupProps={{ size: "sm" }}
        hideLabel={true}
        value={query?.[fromDateKey]}
        maxDate={
          query?.[toDateKey]
            ? new Date(moment(query?.[toDateKey]).format("YYYY-MM-DD"))
            : new Date()
        }
        onChange={(e) =>
          onQueryChange({
            [fromDateKey]: e ? moment(e).format("YYYY-MM-DD") : "",
          })
        }
        inputProps={{ size: "sm", fontSize: "12px" }}
        placeholder={"From Date"}
      />
      <CustomDateInput
        containerProps={{ maxW: "210px" }}
        groupProps={{ size: "sm" }}
        hideLabel={true}
        value={query?.[toDateKey]}
        maxDate={new Date()}
        minDate={
          query?.[fromDateKey]
            ? new Date(
                moment(query?.[fromDateKey])
                  .subtract(1, "day")
                  .format("YYYY-MM-DD")
              )
            : new Date()
        }
        onChange={(e) =>
          onQueryChange({
            [toDateKey]: e ? moment(e).format("YYYY-MM-DD") : "",
          })
        }
        inputProps={{ size: "sm", fontSize: "12px" }}
        placeholder={"To Date"}
      />
    </Flex>
  );
};

export default DateRangeInput;
