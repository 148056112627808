import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Checkbox, Divider, Flex, HStack, SimpleGrid, Text, VStack, useColorMode, useDisclosure } from '@chakra-ui/react'
import FormModal from '../../../../components/Forms/FormModal'
import FormInput from '../../../../components/Forms/FormInput'
import { useColors, useCreateVehicle, useUpdateVehicle, useVehicleTypeOptions, useVehicle } from '../../../../config/query/transportQuery'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import { prepareForm, preparePayload } from '../../../../config/helpers/formHelpers/vehicleHelper'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useZoneOptions } from '../../../../config/query/zoneQuery'
import { NUMERIC_REGEX, YEAR_REGEX } from '../../../../config/constants/regexConstants'
import { useMakeLookup } from '../../../../config/query/makeQuery'
import { useModelLookup } from '../../../../config/query/modelQuery'
import VehicleTypeForm from '../VehicleTypes/VehicleTypeForm'
import useDebounce from '../../../../config/hooks/useDebounce'
import FormDateInput from '../../../../components/Forms/FormDateInput'
import { colorKeys, getColor } from '../../../../config/constants/appColors'

const VehicleForm = ({ disclosure, data, onSuccess }) => {
    const { colorMode } = useColorMode()
    const vehicleTypeFormDisclosure = useDisclosure()
    const [modelQuery, setModelQuery] = useState({})
    const debouncedModelQuery = useDebounce(modelQuery)

    const vehicleQuery = useVehicle(data?.id)
    const createVehileType = useCreateVehicle()
    const updateVehicleType = useUpdateVehicle(data?.id)

    const {
        handleSubmit,
        control,
        reset: resetForm,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        values: prepareForm({
            formValues: data,
            serverValues: vehicleQuery.data
        })
    })

    const makes = useMakeLookup()
    const vehicleTypesOptions = useVehicleTypeOptions()
    const models = useModelLookup(debouncedModelQuery)
    const zones = useZoneOptions()
    const colors = useColors()

    const { make, defaultVehicleId, vehicleStatus, vehicleTypes, insuranceEffectiveDate } = watch()

    const onSubmit = (values) => {
        const payload = preparePayload(values, data?.id)
        let formMutate = data?.id ? updateVehicleType.mutateAsync : createVehileType.mutateAsync
        formMutate(payload)
            .then(() => {
                disclosure.onClose()
                onSuccess && onSuccess()
                resetForm()
            })
            .catch((error) => console.warn("Error while creating vehicle class:", error))
    }

    const onModelQueryChange = (updatedQuery) => {
        setModelQuery((prev) => ({ ...prev, ...updatedQuery }))
    }

    const onSelectDefaultVehicle = (e) => {
        if (e.target.checked) {
            setValue("defaultVehicleId", e.target.value)
        } else {
            setValue("defaultVehicleId", null)
        }
    }

    watch((data, { name, type }) => {
        if (type === "change") {
            if (name === "make") {
                onModelQueryChange({ makeId: data[name] })
                if (data?.id) {
                    setValue("modelId", data?.model?.id)
                }
            }
            if (name === "vehicleTypes") {
                if (Array.isArray(vehicleTypes) && vehicleTypes.length) {
                    if (vehicleTypes?.includes(Number(defaultVehicleId))) return
                    setValue("defaultVehicleId", null)
                }
            }
        }
    })

    useEffect(() => {
        if (!data?.id) resetForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id])

    useEffect(() => {
        if (data?.id) {
            onModelQueryChange({ makeId: make || data?.make?.id })
            setValue("modelId", data?.model?.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id, models.data?.data, make])

    return (
        <FormModal
            title={data?.id ? "Edit Vehicle" : "Add Vehicle"}
            disclosure={disclosure}
            isSubmitting={createVehileType.isLoading || updateVehicleType.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW={"75rem"}
            onCloseFn={() => {
                resetForm()
            }}
            reset={resetForm}
        >

            <FormFieldsSkeleton count={15} loading={vehicleQuery.isFetching} />

            {!vehicleQuery.isFetching &&
                <VStack spacing={5} align="stretch">

                    <Box>
                        <Text fontSize="15px" fontWeight={"bold"} mb={2} w="full" textTransform={"uppercase"}>Vehicle Information</Text>
                        <Divider />
                    </Box>

                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

                        <FormInput
                            label={"Licence Plate"}
                            control={control}
                            errors={errors}
                            id="licensePlate"
                            required={true}
                            placeholder="Enter license plate number"
                            inputProps={{ size: "sm" }}
                        />

                        <FormSearchSelect
                            id="make"
                            label="Make"
                            placeholder={'Select Make'}
                            required={true}
                            errors={errors}
                            control={control}
                            options={makeSelectList(makes.data?.data)}
                            inputProps={{ size: "sm" }}
                        />

                        <FormSearchSelect
                            id="modelId"
                            label="Model"
                            placeholder={'Select model'}
                            required={true}
                            errors={errors}
                            control={control}
                            options={makeSelectList(models.data?.data)}
                            inputProps={{ size: "sm", isDisabled: !watch("make") || vehicleStatus }}
                            isLoading={models.isLoading}

                        />

                        <FormInput
                            label={"Name of Vehicle Owner"}
                            control={control}
                            errors={errors}
                            id="ownerName"
                            required={true}
                            placeholder="Enter name of vehicle owner"
                            inputProps={{ size: "sm" }}
                        />



                        <FormInput
                            label={"Year"}
                            control={control}
                            errors={errors}
                            id="year"
                            type={"number"}
                            required={true}
                            placeholder="Enter year"
                            inputProps={{ size: "sm" }}
                            rules={{
                                pattern: {
                                    value: YEAR_REGEX,
                                    message: "Invalid year"
                                }
                            }}
                        />

                        <FormSearchSelect
                            id="colorId"
                            label="Color"
                            placeholder={'Select Color'}
                            required={true}
                            errors={errors}
                            control={control}
                            options={makeSelectList(colors.data?.data)}
                            inputProps={{ size: "sm" }}
                        />

                        <FormInput
                            label={"Passenger Capacity"}
                            control={control}
                            errors={errors}
                            id="seatingCapacity"
                            required={true}
                            placeholder="Enter passenger capacity"
                            inputProps={{ size: "sm" }}
                            rules={{
                                pattern: {
                                    value: NUMERIC_REGEX,
                                    message: "Invalid passenger capacity"
                                }
                            }}
                        />

                        <FormSearchSelect
                            id="zoneIds"
                            label="Zones"
                            placeholder={'Select Zones'}
                            required={true}
                            multiple={true}
                            errors={errors}
                            control={control}
                            options={makeSelectList(zones.data)}
                            inputProps={{ size: "sm" }}
                        />

                        <FormSwitch
                            label={"Has Luggage Space?"}
                            control={control}
                            errors={errors}
                            id="hasLuggageSpace"
                        />

                    </SimpleGrid>

                    <Box>
                        <Text fontSize="15px" fontWeight={"bold"} mb={2} w="full" textTransform={"uppercase"}>Vehicle Information</Text>
                        <Divider />
                    </Box>

                    <VStack spacing={5} align={"stretch"}>

                        <FormSearchSelect
                            id="vehicleTypes"
                            label="Service Type"
                            placeholder={'Select service type'}
                            required={true}
                            errors={errors}
                            control={control}
                            multiple={true}
                            options={makeSelectList(vehicleTypesOptions.data?.data)}
                            inputProps={{ size: "sm" }}
                            containerStyles={{ w: "fit-content", minW: "350px" }}
                            labelExtention={
                                <Button size="xs" onClick={vehicleTypeFormDisclosure.onOpen}>Add New</Button>
                            }
                        />


                        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={5}>

                            {vehicleTypes?.length ? vehicleTypesOptions.data?.data?.map((vehicleType, index) => {
                                if (!vehicleTypes?.includes(vehicleType.id)) return null
                                const isDefaultDisabled = defaultVehicleId && Number(defaultVehicleId) !== vehicleType.id
                                return (
                                    <Flex px="2" py="1" border={`1px solid ${getColor(colorKeys.tableFilterBorder, colorMode)}`} justify={"space-between"} align="center" key={index} alignItems="center">
                                        <Text fontSize="14px">{vehicleType.name}</Text>
                                        <HStack align={"center"}>
                                            <Text fontSize="14px" opacity={isDefaultDisabled ? "0.5" : "1"}>Default</Text>
                                            <Checkbox
                                                isChecked={Number(defaultVehicleId) === vehicleType.id}
                                                isDisabled={isDefaultDisabled}
                                                onChange={onSelectDefaultVehicle}
                                                value={vehicleType.id}
                                            />
                                        </HStack>
                                    </Flex>
                                )
                            }) : null}


                        </SimpleGrid>

                    </VStack>


                    <Box>
                        <Text fontSize="15px" fontWeight={"bold"} mb={2} w="full" textTransform={"uppercase"}>Insurance Information</Text>
                        <Divider />
                    </Box>

                    <Box>
                        <FormFieldsSkeleton count={3} loading={vehicleQuery.isFetching} />
                        {!vehicleQuery.isFetching && <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

                            <FormInput
                                label={"Policy Number"}
                                control={control}
                                errors={errors}
                                id="insurancePolicyNo"
                                required={true}
                                placeholder="Enter policy number"
                                inputProps={{ size: "sm" }}
                            />

                            <FormDateInput
                                id={"insuranceEffectiveDate"}
                                control={control}
                                errors={errors}
                                required={true}
                                label={"Effective Date"}
                                placeholder={"Select effective date"}
                                inputProps={{ size: "sm" }}
                                maxDate={new Date()}
                                rules={{
                                    deps: ['insuranceExpiryDate'],
                                }}
                            />

                            <FormDateInput
                                id={"insuranceExpiryDate"}
                                control={control}
                                errors={errors}
                                required={true}
                                label={"Expiration Date"}
                                placeholder={"Select expiry date"}
                                inputProps={{ size: "sm" }}
                                minDate={insuranceEffectiveDate}
                                rules={{
                                    validate: (value) => {
                                        if (new Date(value) < new Date(insuranceEffectiveDate))
                                            return "Expiration date must be greater than effective date"
                                    },
                                }}
                            />

                        </SimpleGrid>
                        }
                    </Box>
                </VStack>
            }

            <VehicleTypeForm data={{}} disclosure={vehicleTypeFormDisclosure} onSuccess={vehicleTypesOptions.refetch} />

        </FormModal >
    )
}

export default VehicleForm