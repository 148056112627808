import { SEND_TO_ALLS } from "../../constants/enums"

export const preparePayload = (values, fileData) => {
    if (values?.CorporateIds?.length) {
        values.CorporateIds = values.CorporateIds.map(item => item.value)
    }
    if (values?.CustomerIds?.length) {
        values.CustomerIds = values.CustomerIds.map(item => item.value)
    }
    if (values?.DriverIds?.length) {
        values.DriverIds = values.DriverIds.map(item => item.value)
    }
    if (!values?.CorporateIds?.length || values?.SendTo?.includes(SEND_TO_ALLS.AllCorporates)) {
        delete values.CorporateIds
    }
    if (!values?.CustomerIds?.length || values?.SendTo?.includes(SEND_TO_ALLS.AllCustomers)) {
        delete values.CustomerIds
    }
    if (!values?.DriverIds?.length || values?.SendTo?.includes(SEND_TO_ALLS.AllDrivers)) {
        delete values.DriverIds
    }
    values.AllDrivers = values.SendTo?.includes(SEND_TO_ALLS.AllDrivers)
    values.AllCustomers = values.SendTo?.includes(SEND_TO_ALLS.AllCustomers)
    values.AllCorporates = values.SendTo?.includes(SEND_TO_ALLS.AllCorporates)

    delete values.SendTo

    // return values

    const formData = new FormData()
    Object.keys(values).forEach((key) => {
        if (values[key] === null || values[key] === undefined) return

        if (key === "DriverIds" || key === "CustomerIds" || key === "CorporateIds") {
            values[key]?.forEach((item, index) => {
                formData.append(`${key}[${index}]`, item)
            })
            return;
        }

        formData.append(key, values[key])
    })
    formData.append("Image", fileData)
    return formData
}

export const getSentToItem = (item) => {
    let sentTo = []
    if (item.userType?.length) {
        item.userType?.forEach((item) => sentTo.push(`All ${item?.name}`))
    }
    if (item.corporates) {
        sentTo.push(`${item.corporates?.length} Specific Corporate${item.corporates?.length > 1 ? 's' : ''}`)
    }
    if (item.customers) {
        sentTo.push(`${item.customers?.length} Specific Customer${item.customers?.length > 1 ? 's' : ''}`)
    }
    if (item.drivers) {
        sentTo.push(`${item.drivers?.length} Specific Driver${item.drivers?.length > 1 ? 's' : ''}`)
    }
    if (sentTo.length === 0) {
        return "N/A"
    }
    else {
        return sentTo.join(", ")
    }
}

export const getSentTo = (item) => {
    let sentTo = []
    if (item?.userType?.length) {
        item?.userType?.forEach((item) => sentTo.push(`All ${item?.name}`))
    }
    if (item?.corporates?.length) {
        item?.corporates?.forEach((item) => sentTo.push(`${item?.name} (Corporate)`))
    }
    if (item?.customers?.length) {
        item?.customers?.forEach((item) => sentTo.push(`${item?.name} (Customer)`))
    }
    if (item?.drivers?.length) {
        item?.drivers?.forEach((item) => sentTo.push(`${item?.name} (Driver)`))
    }
    if (sentTo.length === 0) {
        return "N/A"
    }
    else {
        return sentTo.join(", ")
    }
}