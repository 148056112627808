import React from "react";
import {
  useMailConfig,
  useUpdateMailConfig,
} from "../../../../../config/query/configQuery";
import { Box, SimpleGrid } from "@chakra-ui/react";
import ConfigCard from "../../Configuration/ConfigCard";
import { useState } from "react";
import { useEffect } from "react";

const Mail = () => {
  const [configs, setConfigs] = useState();

  const mailConfigQuery = useMailConfig();
  const updateMailConfigQuery = useUpdateMailConfig();

  const handleChange = (e) => {
    setConfigs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    mailConfigQuery
      .mutateAsync()
      .then((res) => {
        setConfigs(res);
      })
      .catch((err) => console.warn(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async () => {
    updateMailConfigQuery
      .mutateAsync(configs)
      .then((res) => console.info(res))
      .catch((err) => console.warn(err));
  };

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} mt={6}>
        <ConfigCard
          title={"Host"}
          id="host"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={true}
        />
        <ConfigCard
          title={"Port"}
          id="port"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={true}
        />
        <ConfigCard
          title={"From"}
          id="from"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          type={"email"}
          isEditable={true}
        />
        <ConfigCard
          title={"Username"}
          id="userName"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={true}
        />
        <ConfigCard
          title={"Password"}
          id="password"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          type={"password"}
          isEditable={true}
        />
        <ConfigCard
          title={"Display Name"}
          id="displayName"
          onChange={handleChange}
          data={configs}
          onSave={onSave}
          isEditable={true}
        />
      </SimpleGrid>
    </Box>
  );
};

export default Mail;
