import * as signalR from '@microsoft/signalr';
import { SOCKET_SERVER } from "../constants/chat"

export const onInitSignalR = (hubConnection, openedChats) => {
    if (hubConnection?.state === signalR.HubConnectionState.Connected) {
        hubConnection?.invoke(SOCKET_SERVER.JoinAdminInboxRoom)
        const urlPath = window.location.pathname
        if (urlPath === '/admin/map' || urlPath.includes('/admin/active-bookings') || urlPath.includes('/admin/bookings')) {
            hubConnection?.invoke(SOCKET_SERVER.JoinAdminMapRoom)
        }
        if (urlPath.includes("/admin/chats")) {
            const splitted = urlPath.split('/')
            if (splitted.length === 5) {
                const chatId = splitted[splitted.length - 1]
                if (chatId) {
                    hubConnection?.invoke(SOCKET_SERVER.JoinChatRoom, splitted[splitted.length - 1])
                }
            }
        }
        if (openedChats?.length > 0) {
            openedChats?.forEach((item) => {
                hubConnection?.invoke(SOCKET_SERVER.JoinChatRoom, item.chatId)
            })
        }
    }
}